/**
 * クラス名：親部品見積
 * 説明：親部品タブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Form, Row, Col, Image, Tabs, List, Tooltip } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

import ParentEstimateDetail from './ParentEstimateDetail';
import ParentKouteiCalculate from './ParentKouteiCalculate';
import ParentKouteiInput from './ParentKouteiInput';
import ParentKouteiSentaku from './ParentKouteiSentaku';
import ParentPurchase from './ParentPurchase';
import KouteiSuryouBetsuBtn from './KouteiSuryouBetsuBtn';
import child_delete from '../../assets/images/child_delete.png';
import { confirmSaveContent, confirmModalTitle, commonModal, commonFooter } from '../common/CommonModal';
import { ClientType, ParamCalType, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums.js';
import { updateEstimateInfoByReCal } from '../common/Common';
import { act } from 'react-dom/test-utils';
import { parentPurchaseTitle } from '../common/Constant.js';
import { OverlayTrigger } from 'react-bootstrap';

const tempFileData = [];
for (let i = 1; i < 4; i++) {
  tempFileData.push({
    fileNm: `送付ファイル${i}?.xlsx`,
    fileSize: '2KB',
    updated: '2023/03/23',
    deleted: <Image preview={false} width={18} height={18} src={child_delete} style={{ marginLeft: '0px' }}></Image>,
    download: <DownloadOutlined style={{ fontSize: '22px', color: '#1063aa' }} />,
  });
}

const ParentEstimate = forwardRef((props, ref) => {
  const location = useLocation();
  const [editMode, setEditMode] = useState(true);
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [discardMode, setDiscardMode] = useState(false);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [selectedEstimateDataTemp, setSelectedEstimateDataTemp] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [selectedTabKey, setSelectedTabKey] = useState(false);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [processPattern, setProcessPattern] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  // お客様情報リスト
  const [clientInfo, setClientInfo] = useState([]);
  const [purchasesData, setPurchasesData] = useState([]);
  const [purchaseCategoryData, setPurchaseCategoryData] = useState([]);
  const [userSettingData, setUserSettingData] = useState([]);
  // 担当者情報リスト
  const [staffList, setStaffList] = useState([]);
  // 案件状態情報
  const [estimateStatusInfo, setEstimateStatusInfo] = useState([]);

  const [discardModeKouteiCal, setDiscardModeKouteiCal] = useState(false);

  const [createConfirm, setCreateConfirm] = useState(false);

  const [quantity, setQuantity] = useState(1);

  // 基本情報
  let parentEstimateDetailRef = useRef();

  // 工程選択
  let parentKouteiSentakuRef = useRef();

  // 工程計算
  let parentKouteiCalRef = useRef();

  // 工程入力
  let parentKouteiInputRef = useRef();

  // 購入品
  let parentPurchaseRef = useRef();

  //数量別
  let byQuantitiesRef = useRef();

  const currentRoute = location.pathname;

  useImperativeHandle(ref, () => ({
    getParentEditMode: () => {
      return editMode;
    },
    getInitParentEstimateInfo: () => {
      return selectedEstimateDataTemp;
    },
    getParentEstimateDetailRef: () => {
      return parentEstimateDetailRef;
    },
    getParentEstimateInfo: getParentEstimateInfo,
    discardConfirmOk: discardConfirmOk,
    kihonFormValidation: parentEstimateDetailRef?.current?.formValidation,
    getParentQuantity: parentEstimateDetailRef?.current?.getParentQuantity, // 入力した数量情報を得る
  }));

  // タブ切替イベント
  const onChange = (key) => {
    setSelectedTabKey(true);
    setActiveTabKey(key);
    props?.updateParentActiveTabKey(key);
  };

  useEffect(() => {
    // props?.loading(true); // Waitingダイアログを表示にする
    if (selectedTabKey) {
      let estimateInfo = getParentEstimateInfo();
      let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo);
      props.updateEstimateInfo(calEstimateInfo);
      props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
      props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
      setSelectedTabKey(false);
    }
    // props?.loading(false); // Waitingダイアログを非表示にする
  }, [activeTabKey]);

  useEffect(() => {
    if (props.clickMenu !== '') {
      let estimateInfo = getParentEstimateInfo();
      let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo);
      props.updateEstimateInfo(calEstimateInfo);
      props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
      props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
      props.updateClickMenu();
    }
    setSelectedTabKey(false);
  }, [props.activePartMenu, props.clickMenu]);

  useEffect(() => {
    if (props?.parentActiveTabKey) {
      setActiveTabKey(props?.parentActiveTabKey);
    }
  }, [props?.parentActiveTabKey]);

  useEffect(() => {
    if (props?.selectedEstimateData?.esIq3Info && props?.selectedEstimateData?.esIq3Info?.length > 0) {
      let estimateInfo = getParentEstimateInfo();
      let calEstimateInfo = updateEstimateInfoByReCal(estimateInfo);
      props.updateEstimateInfo(calEstimateInfo);
      props.selectedEstimateData.estimateProducts = calEstimateInfo.estimateProducts;
      props.selectedEstimateData.esIq3Info = calEstimateInfo.esIq3Info;
      props.updateClickMenu();
    }
  }, [props?.selectedEstimateData?.esIq3Info]);

  const discardConfirmOk = () => {
    setDiscardModeKouteiCal(false);
    setEditModeCancelConfirm(false);
    props.updateMainEstimateEditMode(false);
  };

  //　工程積算と数量別の最新情報取得
  // const getParentEstimateInfo = () => {
  //   let estimateData = props.selectedEstimateData;
  //   //　基本情報タブの入力情報を取得
  //   if (parentEstimateDetailRef?.current) {
  //     const estimateInfo = parentEstimateDetailRef?.current?.getParentEstimateDetail(estimateData);
  //     if (estimateInfo) {
  //       estimateData.estimateProducts = estimateInfo?.estimateProducts;
  //       estimateData.epCustomCosts = estimateInfo?.epCustomCosts;
  //       estimateData.epCustomLabels = estimateInfo?.epCustomLabels;
  //       estimateData.epCustomMemos = estimateInfo?.epCustomMemos;
  //       estimateData.epCustomDates = estimateInfo?.epCustomDates;
  //     }
  //   }

  //   //　工程選択タブの入力情報を取得
  //   if (parentKouteiSentakuRef?.current) {
  //     const patternNo = parentKouteiSentakuRef?.current?.getKouteiSentakuPatternNo();
  //     const selectedPattern = parentKouteiSentakuRef?.current?.getProcessSelectedData();
  //     if (selectedPattern) {
  //       estimateData.estimateProducts = {
  //         ...estimateData.estimateProducts,
  //         processSelect: {
  //           id: patternNo,
  //           name: selectedPattern?.name,
  //           details: selectedPattern?.details,
  //           modified: selectedPattern?.modified,
  //         },
  //       };
  //     }
  //   }

  //   // 工程入力
  //   if (parentKouteiInputRef?.current) {
  //     const kouteiData = parentKouteiInputRef?.current?.getKouteiInputInfo();
  //     if (kouteiData) {
  //       estimateData.estimateProducts = {
  //         ...estimateData.estimateProducts,
  //         processInput: kouteiData,
  //       };
  //       // estimateData.estimateProducts.processInput = kouteiData;
  //     }
  //   }

  //   // 購入品
  //   if (parentPurchaseRef?.current) {
  //     const parentPurchase = parentPurchaseRef?.current?.getParentPurchaseInfo();
  //     if (parentPurchase) estimateData.estimateProducts.purchases = parentPurchase;
  //   }

  //   //　工程積算タブの入力情報を取得
  //   if (parentKouteiCalRef?.current) {
  //     const calEstimate = parentKouteiCalRef?.current?.getKouteiCalInfo();
  //     //工程パターンでチェック外した工程を工程積算から削除
  //     let orgAdditions = calEstimate;
  //     let updatedAdditions = [];
  //     for (let i = 0; i < estimateData?.estimateProducts?.processSelect?.details?.length; i++) {
  //       let targetAddition = orgAdditions?.filter(
  //         (item) =>
  //           item.processId === estimateData?.estimateProducts?.processSelect?.details[i].processId &&
  //           estimateData?.estimateProducts?.processSelect?.details[i].isUsedForCalc
  //       );

  //       if (targetAddition?.length > 0) {
  //         //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
  //         if (targetAddition?.length > 1) {
  //           for (let o = 0; o < orgAdditions?.length; o++) {
  //             let customProc = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
  //               (item) => item.id === orgAdditions[o]?.processId
  //             );
  //             let customType = customProc ? customProc[0]?.workType : 0;
  //             if (
  //               estimateData?.estimateProducts?.processSelect?.details[i]?.multiprocesses?.[0]?.processDetailTypeId ===
  //                 orgAdditions[o]?.dataItems?.[0]?.processDetailTypeNo &&
  //               customType === WorkType.PaCustom
  //             ) {
  //               updatedAdditions?.push(orgAdditions?.[o]);
  //               break;
  //             }
  //           }
  //         } else {
  //           updatedAdditions?.push(targetAddition?.[0]);
  //         }
  //       }
  //     }
  //     if (estimateData?.estimateProducts?.processSelect?.details?.length > 0 && updatedAdditions?.length > 0) {
  //       estimateData.estimateProducts.additions = updatedAdditions;
  //     }
  //   }
  //   // 数量別
  //   // if (byQuantitiesRef?.current) {
  //   //   const byQuantitiesInfo = byQuantitiesRef?.current?.getQuantitiesInfo();
  //   //   estimateData.estimateProducts.byQuantities = byQuantitiesInfo;
  //   // }
  //   setSelectedEstimateData(estimateData);
  //   return estimateData;
  // };

  const getParentEstimateInfo = () => {
    let estimateData = props.selectedEstimateData;

    //工程パターンでチェック外した工程を工程積算から削除
    let orgAdditions = estimateData?.estimateProducts?.additions;
    let updatedAdditions = [];
    for (let i = 0; i < estimateData?.estimateProducts?.processSelect?.details?.length; i++) {
      let targetAddition = orgAdditions?.filter(
        (item) =>
          item.processId === estimateData?.estimateProducts?.processSelect?.details[i].processId &&
          estimateData?.estimateProducts?.processSelect?.details[i].isUsedForCalc
      );

      if (targetAddition?.length > 0) {
        //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
        if (targetAddition?.length > 1) {
          for (let o = 0; o < orgAdditions?.length; o++) {
            let customProc = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
              (item) => item.id === orgAdditions[o]?.processId
            );
            let customType = customProc ? customProc[0]?.workType : 0;
            if (
              estimateData?.estimateProducts?.processSelect?.details[i]?.multiprocesses?.[0]?.processDetailTypeId ===
                orgAdditions[o]?.dataItems?.[0]?.processDetailTypeNo &&
              estimateData?.estimateProducts?.processSelect?.details[i]?.processId === orgAdditions[o]?.processId &&
              customType === WorkType.PaCustom
            ) {
              updatedAdditions?.push(orgAdditions?.[o]);
              break;
            }
          }
        } else {
          updatedAdditions?.push(targetAddition?.[0]);
        }
      }
    }
    if (estimateData?.estimateProducts?.processSelect?.details?.length > 0) {
      //工程選択から外した場合、見積データに外した情報を更新するため、updatedAdditionsのNullチェックを削除
      props.selectedEstimateData.estimateProducts.additions = updatedAdditions;
    }

    setSelectedEstimateData(estimateData);
    return estimateData;
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
  };

  const createCancel = () => {
    setCreateConfirm(false);
  };

  const updateSelectedEstimateData = (estimateData) => {
    if (estimateData) {
      setSelectedEstimateData(estimateData);
    }
  };

  const updateEstimateInfo = (updateEstimateData) => {
    props.updateEstimateInfo(updateEstimateData);
  };

  const onChangePurchase = (purchases) => {
    setPurchasesData(purchases);
  };

  const tempFileComponent = [
    <List
      itemLayout="horizontal"
      dataSource={tempFileData}
      renderItem={(item, index) => (
        <List.Item>
          <Row style={{ textAlign: 'center', height: '10px' }}>
            <Col style={{ width: '200px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.fileNm}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.fileSize}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.updated}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', borderRight: '1px dashed rgb(181, 178, 174)', padding: 5 }}>
              {item.deleted}
            </Col>
            <Col style={{ width: '100px', fontSize: '13px', padding: 5 }}>{item.download}</Col>
          </Row>
        </List.Item>
      )}
    />,
  ];

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
      children: '',
    },

    {
      key: '2',
      label: `工程選択`,
      children: '',
    },
    {
      key: '3',
      label: `工程入力`,
      children: '',
    },
    {
      key: '4',
      label: `工程積算`,
      children: '',
    },
    {
      key: '5',
      label: (
        <Tooltip title={parentPurchaseTitle} placement="top" overlayClassName="tooltip-text purchaseTooltip">
          購入品
        </Tooltip>
      ),
      children: '',
    },
    // {
    //   key: '6',
    //   label: `添付ファイル`,
    //   children: (
    //     <div style={{ marginTop: '-15px', marginLeft: '-34px', width: '100%' }}>
    //       <Row>
    //         <Col style={{ width: '90%' }}>{tempFileComponent}</Col>
    //         <Col style={{ width: '10%', marginTop: '20px' }}>
    //           <Button key="tempFileAdd" type="primary" className="editAllButton" style={{ marginLeft: 0, width: 120 }}>
    //             添付ファイルを追加
    //           </Button>
    //         </Col>
    //       </Row>
    //     </div>
    //   ),
    // },
    // {
    //   key: '7',
    //   label: `フリーボード`,
    //   children: (
    //     <div style={{ marginTop: '5px', marginLeft: '0px', width: '100%' }}>{selectedEstimateData?.comment}</div>
    //   ),
    // },
  ];

  return (
    <>
      <Form>
        {/* 部品プロパティアリア */}
        <div
          style={{
            // display: 'flex',
            height: '85.9vh',
            marginLeft: '6px',
            marginRight: '6px',
          }}
        >
          <Tabs
            // className="estimate-detail-tab"
            className="iq3-detail-tab"
            type="card"
            defaultActiveKey="1"
            activeKey={activeTabKey}
            items={tabItems}
            onChange={onChange}
          />
          <div style={{ display: activeTabKey == '1' ? 'block' : 'none', marginTop: 7 }}>
            <ParentEstimateDetail
              ref={parentEstimateDetailRef}
              selectedDataDetail={props.selectedEstimateData}
              editMode={editMode}
              updateSelectedEstimateData={updateSelectedEstimateData}
              activeTabKey={activeTabKey}
              activePartMenu={props.activePartMenu}
              isReCal={props.isReCal}
              newMode={props.newMode}
              updateEstimateInfo={updateEstimateInfo}
            />
          </div>
          <div style={{ display: activeTabKey == '2' ? 'block' : 'none', marginTop: 7 }}>
            <ParentKouteiSentaku
              type={'list'}
              ref={parentKouteiSentakuRef}
              editMode={editMode}
              selectedDataDetail={props.selectedEstimateData}
              activeTabKey={activeTabKey}
              isReCal={props.isReCal}
              newMode={props.newMode}
              updateEstimateInfo={updateEstimateInfo}
            />
          </div>
          <div style={{ display: activeTabKey == '3' ? 'block' : 'none', marginTop: 7 }}>
            <ParentKouteiInput
              ref={parentKouteiInputRef}
              selectedDataDetail={props.selectedEstimateData}
              editMode={editMode}
              activeTabKey={activeTabKey}
              isReCal={props.isReCal}
              newMode={props.newMode}
              updateEstimateInfo={updateEstimateInfo}
            />
          </div>
          <div style={{ display: activeTabKey == '4' ? 'block' : 'none', marginTop: 7 }}>
            <ParentKouteiCalculate
              ref={parentKouteiCalRef}
              selectedDataDetail={props.selectedEstimateData}
              editMode={editMode}
              pmxMode={false}
              discardModeKouteiCal={discardModeKouteiCal}
              clientInfo={clientInfo}
              isReCal={props.isReCal}
              updateSelectedEstimateData={updateSelectedEstimateData}
              activeTabKey={activeTabKey}
              newMode={props.newMode}
              updateEstimateInfo={updateEstimateInfo}
              purchasesData={purchasesData}
              activePartMenu={props.activePartMenu}
            />
          </div>
          <div style={{ display: activeTabKey == '5' ? 'block' : 'none', marginTop: 7 }}>
            <ParentPurchase
              ref={parentPurchaseRef}
              selectedDataDetail={props.selectedEstimateData}
              editMode={editMode}
              isChildPart={false}
              activeTabKey={activeTabKey}
              newMode={props.newMode}
              updateEstimateInfo={updateEstimateInfo}
              onChangePurchase={onChangePurchase}
              activePartMenu={props.activePartMenu}
            />
          </div>
        </div>
        {/** 数量別見積確認 */}
        <div style={{ marginLeft: '-620px', display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              marginTop: '6px',
              position: 'absolute',
              top: '-36px',
              right: 0,
            }}
          >
            <KouteiSuryouBetsuBtn
              ref={byQuantitiesRef}
              selectedEstimateData={props.selectedEstimateData}
              getParentQuantity={props?.getParentQuantity}
              isReCal={props.isReCal}
              loading={props?.loading}
              isPMXEvent={props?.isPMXEvent}
            />
          </div>
        </div>
      </Form>

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(createOk, createCancel),
            null,
            400,
            createOk,
            createCancel,
            confirmSaveContent,
            null,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ParentEstimate;
