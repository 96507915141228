/**
 * クラス名：お客様・仕入先パラメータ
 * 説明：お客様・仕入先情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Row, Col, Divider, Button, Radio, Image, Form, DatePicker, Input, Checkbox } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { WithRouter } from '../../../../components/WithRouter';
import checked from '../../../../assets/images/checked.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  today,
  TextArea,
  formatDate,
  formValidatorMode,
  isEmail,
  isDigit,
  isTelAndFax,
  isDecimal,
  toDecimal,
  isCustomer,
  isSupplier,
  isContractor,
  handleKeyPress,
  isNumber,
  regexExp,
  toHalfWidth,
} from '../../../common/Common';
import { ClientType } from '../../../common/enums';
import { ErrorMessage } from '../../../common/Message';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const CustomerSupplierDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
    formValidation: formValidation,
  }));

  const inputItemRef = {};

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');
  const [customerFlg, setCustomerFlg] = useState(false);
  const [supplierFlg, setSupplierFlg] = useState(false);
  const [contractorFlg, setContractorFlg] = useState(false);
  const [customerCoefficients, setCustomerCoefficients] = useState('');
  const [supplierCoefficients, setSupplierCoefficients] = useState('');
  const [contractorCoefficients, setContractorCoefficients] = useState('');

  const [isUsed, setIsUsed] = useState();
  const [branchNum, setBranchNum] = useState();
  const [nameKana, setNameKana] = useState('');
  const [tel, setTel] = useState('');
  const [fax, setFax] = useState('');
  const [contactParson, setContactParson] = useState('');
  const [mail, setMail] = useState('');
  const [zip, setZip] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState();

  const [activePartMenu, setActivePartMenu] = useState('');
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNoData, setIsNoData] = useState(false);

  // 選択した行の情報を設定
  useEffect(() => {
    let selectedRowData = props.selectedData;
    if (selectedRowData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(false);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (activePartMenu !== props?.activePartMenu || id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      resetDetailInfo();
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
  }, [props.selectedData]);

  // お客様・仕入先タブの切り替え
  useEffect(() => {
    setActivePartMenu(props?.activePartMenu);
  }, [props.activePartMenu]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setCreated('');
    setModified('');
    setModifier('');
    setCode('');
    setName('');
    setCustomerFlg(false);
    setSupplierFlg(false);
    setContractorFlg(false);
    setCustomerCoefficients('');
    setSupplierCoefficients('');
    setContractorCoefficients('');

    setIsUsed('');
    setBranchNum();
    setNameKana('');
    setTel('');
    setFax('');
    setContactParson('');
    setMail('');
    setZip('');
    setPrefecture('');
    setAddress('');
    setRemarks('');
    setSortNum('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    resetDetailInfo();
    setID(data?.id);
    setName(data?.name);
    setCode(data?.code);
    setCreated(data?.created);
    setModified(data?.modified);
    setModifier(data?.modifier);
    if (isCustomer(data?.clientType)) {
      setCustomerFlg(true);
      let coeff = data.coeffClients?.filter((record) => record.clientType === ClientType.Customer);
      if (coeff?.length > 0) setCustomerCoefficients(toDecimal(coeff?.[0].coeff, 2));
    }
    if (isSupplier(data.clientType)) {
      setSupplierFlg(true);
      let coeff = data.coeffClients?.filter((record) => record.clientType === ClientType.Supplier);
      if (coeff?.length > 0) setSupplierCoefficients(toDecimal(coeff?.[0].coeff, 2));
    }
    if (isContractor(data.clientType)) {
      setContractorFlg(true);
      let coeff = data.coeffClients?.filter((record) => record.clientType === ClientType.Contractor);
      if (coeff?.length > 0) setContractorCoefficients(toDecimal(coeff?.[0].coeff, 2));
    }
    setIsUsed(data?.info?.isUsed);
    setBranchNum(data?.info?.branchNum);
    setNameKana(data?.info?.nameKana);
    setTel(data?.info?.tel);
    setFax(data?.info?.fax);
    setContactParson(data?.info?.contactParson);
    setMail(data?.info?.mail);
    setZip(data?.info?.zip);
    setPrefecture(data?.info?.prefecture);
    setAddress(data?.info?.address);
    setRemarks(data?.info?.remarks);
    setSortNum(data?.info?.sortNum);
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 枝番
    input = inputItems['branchNum']?.input;
    input?.classList?.remove('input-error');
    const updatedBranch = input?.value?.trim() === '' ? 0 : input?.value;
    if (updatedBranch?.length > 0 && !isDigit(updatedBranch?.replace(/ /g, ''))) {
      message.push(<li>{ErrorMessage('枝番').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // お客様係数
    if (inputItems['customerFlg']?.input?.checked) {
      input = inputItems['customerCoefficients']?.input;
      input?.classList?.remove('input-error');

      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('お客様係数').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      if (input?.value?.length > 0 && !isDecimal(input?.value)) {
        message.push(<li>{ErrorMessage('お客様係数').E014}</li>);
        input.classList.add('input-error');
        ret = false;
      }
    }

    // 仕入先係数
    if (inputItems['supplierFlg']?.input?.checked) {
      input = inputItems['supplierCoefficients']?.input;
      input?.classList?.remove('input-error');

      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('仕入先係数').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      if (input?.value?.length > 0 && !isDecimal(input?.value)) {
        message.push(<li>{ErrorMessage('仕入先係数').E014}</li>);
        input.classList.add('input-error');
        ret = false;
      }
    }

    // 委託先係数
    if (inputItems['contractorFlg']?.input?.checked) {
      input = inputItems['contractorCoefficients']?.input;
      input?.classList?.remove('input-error');

      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('委託先係数').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      if (input?.value?.length > 0 && !isDecimal(input?.value)) {
        message.push(<li>{ErrorMessage('委託先係数').E014}</li>);
        input.classList.add('input-error');
        ret = false;
      }
    }

    // Tel
    input = inputItems['tel']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isTelAndFax(input?.value)) {
      message.push(<li>{ErrorMessage('Tel').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // Fax
    input = inputItems['fax']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isTelAndFax(input?.value)) {
      message.push(<li>{ErrorMessage('Fax').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // メール
    input = inputItems['mail']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isEmail(input?.value)) {
      message.push(<li>{ErrorMessage('メール').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 郵便番号
    input = inputItems['zip']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isTelAndFax(input?.value)) {
      message.push(<li>{ErrorMessage('郵便番号').E008}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // ソート番号
    input = inputItems['sortNum']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('ソート番号').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  // 破壊ボタンの押下
  const discardData = () => {
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = (e) => {
    setUpdateConfirm(false);
    // 画面の入力チェック
    if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
      return;
    }
    if (isUpdate) {
      props.updateEditMode(false);
      setIsUpdate(false);
      submitData();
    }
  };

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    resetDetailInfo();
    displayDetailInfo(props.selectedData);
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      props.selectedData.code = code;
      props.selectedData.name = name;
      props.selectedData.created = created;
      props.selectedData.modified = today;
      let clientType = 0;
      if (customerFlg === true) {
        clientType = ClientType.Customer;
        let index = props.selectedData?.coeffClients?.findIndex((record) => record.clientType === ClientType.Customer);
        if (index !== -1) {
          props.selectedData.coeffClients[index].coeff = customerCoefficients;
        } else {
          props.selectedData.coeffClients.push({
            clientType: ClientType.Customer,
            coeff: customerCoefficients ? parseFloat(customerCoefficients) : 0.0,
          });
        }
      }
      if (supplierFlg === true) {
        clientType = clientType + ClientType.Supplier;
        let index = props.selectedData.coeffClients?.findIndex((record) => record.clientType === ClientType.Supplier);
        if (index !== -1) {
          props.selectedData.coeffClients[index].coeff = supplierCoefficients;
        } else {
          props.selectedData.coeffClients.push({
            clientType: ClientType.Supplier,
            coeff: supplierCoefficients ? parseFloat(supplierCoefficients) : 0.0,
          });
        }
      }
      if (contractorFlg === true) {
        clientType = clientType + ClientType.Contractor;
        let index = props.selectedData?.coeffClients?.findIndex(
          (record) => record.clientType === ClientType.Contractor
        );
        if (index !== -1) {
          props.selectedData.coeffClients[index].coeff = contractorCoefficients;
        } else {
          props.selectedData.coeffClients.push({
            clientType: ClientType.Contractor,
            coeff: contractorCoefficients ? parseFloat(contractorCoefficients) : 0.0,
          });
        }
      }
      props.selectedData.clientType = clientType;
      // props.selectedData.coeffClients?.map((record) => {
      //   if (record.clientType === ClientType.Customer) {
      //     record.coeff = customerCoefficients;
      //   } else if (record.clientType === ClientType.Supplier) {
      //     record.coeff = supplierCoefficients;
      //   } else if (record.clientType === ClientType.Contractor) {
      //     record.coeff = contractorCoefficients;
      //   }
      // });
      const updatedBranch = branchNum
        ? typeof branchNum === 'string'
          ? branchNum?.trim() === ''
            ? 0
            : branchNum?.replace(/ /g, '')
          : branchNum
        : 0;

      props.selectedData.info.isUsed = isUsed;
      props.selectedData.info.branchNum = updatedBranch;
      props.selectedData.info.nameKana = nameKana;
      props.selectedData.info.tel = tel;
      props.selectedData.info.fax = fax;
      props.selectedData.info.contactParson = contactParson;
      props.selectedData.info.mail = mail;
      props.selectedData.info.zip = zip;
      props.selectedData.info.prefecture = prefecture;
      props.selectedData.info.address = address;
      props.selectedData.info.remarks = remarks;
      props.selectedData.info.sortNum = sortNum;
      setModified(today);
      props.submitData(props.selectedData);
    }
  };

  // 係数を変更した場合、
  const onChangeCoeff = (value, clientType, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (isCheckZenkaku && value === '') value = 1;
    if (isCheckZenkaku) value = toDecimal(value, 2);
    if (clientType === ClientType.Customer) {
      setCustomerCoefficients(value);
    } else if (clientType === ClientType.Supplier) {
      setSupplierCoefficients(value);
    } else if (clientType === ClientType.Contractor) {
      setContractorCoefficients(value);
    }
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}
        <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
          <div id="showData">
            <Table id="param_detail_tbl" className="propertiesTbl">
              <thead>
                {/* コード */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">コード</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="code"
                      id="code"
                      value={code}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['code'] = ref;
                      }}
                      maxLength={50}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 枝番 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">枝番</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="branchNum"
                      id="branchNum"
                      value={branchNum}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['branchNum'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      onChange={(e) => setBranchNum(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 名称 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">名称</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="name"
                      id="name"
                      value={name}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['name'] = ref;
                      }}
                      maxLength={150}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </td>
                </tr>
                {/* カナ */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">カナ</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="nameKana"
                      id="nameKana"
                      value={nameKana}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['nameKana'] = ref;
                      }}
                      maxLength={150}
                      onChange={(e) => setNameKana(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 係数 */}
                {/* <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="coefficients"
                      id="coefficients"
                      value={coefficients}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['coefficients'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      onChange={(e) => setCoefficients(e.target.value)}
                      onBlur={(e) => setCoefficients(toDecimal(e.target.value))}
                    />
                  </td>
                </tr> */}
                {/* お客様 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">お客様/係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td
                            style={{
                              width: '40px',
                              height: '44px',
                              padding: '0 7px',

                              textAlign: 'center',
                            }}
                          >
                            {editMode ? (
                              <Checkbox
                                checked={customerFlg}
                                ref={(ref) => {
                                  inputItemRef['customerFlg'] = ref;
                                }}
                                onChange={(e) => setCustomerFlg(e.target.checked)}
                              ></Checkbox>
                            ) : (
                              customerFlg && (
                                <Image
                                  preview={false}
                                  width={13}
                                  src={checked}
                                  // style={{ marginLeft: '5px' }}
                                  id="imgOutsourcingFg"
                                />
                              )
                            )}
                          </td>
                          <td>
                            <Input
                              className={editMode ? 'input-editable' : 'input-non-editable'}
                              name="customerCoefficients"
                              id="customerCoefficients"
                              value={customerCoefficients}
                              type="text"
                              ref={(ref) => {
                                inputItemRef['customerCoefficients'] = ref;
                              }}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              onPressEnter={(e) => onChangeCoeff(e.target.value, ClientType.Customer)}
                              onBlur={(e) => onChangeCoeff(e.target.value, ClientType.Customer)}
                              onChange={(e) => onChangeCoeff(e.target.value, ClientType.Customer, false)}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                            />
                          </td>
                        </tr>
                      </table>

                      /* customerFlg ? (
                        <Image
                          preview={false}
                          width={13}
                          src={checked}
                          // style={{ marginLeft: '5px' }}
                          id="imgOutsourcingFg"
                        />
                      ) : (
                        <></>
                      ) */
                    }
                  </td>
                </tr>
                {/* 仕入先 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">仕入先/係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td
                            style={{
                              width: '40px',
                              height: '44px',
                              padding: '0 7px',

                              textAlign: 'center',
                            }}
                          >
                            {editMode ? (
                              <Checkbox
                                checked={supplierFlg}
                                ref={(ref) => {
                                  inputItemRef['supplierFlg'] = ref;
                                }}
                                onChange={(e) => setSupplierFlg(e.target.checked)}
                              ></Checkbox>
                            ) : (
                              supplierFlg && (
                                <Image
                                  preview={false}
                                  width={13}
                                  src={checked}
                                  // style={{ marginLeft: '5px' }}
                                  id="imgOutsourcingFg"
                                />
                              )
                            )}
                          </td>
                          <td>
                            <Input
                              className={editMode ? 'input-editable' : 'input-non-editable'}
                              name="supplierCoefficients"
                              id="supplierCoefficients"
                              value={supplierCoefficients}
                              type="text"
                              ref={(ref) => {
                                inputItemRef['supplierCoefficients'] = ref;
                              }}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              onPressEnter={(e) => onChangeCoeff(e.target.value, ClientType.Supplier)}
                              onBlur={(e) => onChangeCoeff(e.target.value, ClientType.Supplier)}
                              onChange={(e) => onChangeCoeff(e.target.value, ClientType.Supplier, false)}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                            />
                          </td>
                        </tr>
                      </table>

                      /* supplierFlg ? (
                        <Image
                          preview={false}
                          width={13}
                          src={checked}
                          // style={{ marginLeft: '5px' }}
                          id="imgOutsourcingFg"
                        />
                      ) : (
                        <></>
                      ) */
                    }
                  </td>
                </tr>
                {/* 委託先 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">委託先/係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {
                      <table style={{ width: '100%' }}>
                        <tr>
                          <td
                            style={{
                              width: '40px',
                              height: '44px',
                              padding: '0 7px',

                              textAlign: 'center',
                            }}
                          >
                            {editMode ? (
                              <Checkbox
                                checked={contractorFlg}
                                ref={(ref) => {
                                  inputItemRef['contractorFlg'] = ref;
                                }}
                                onChange={(e) => setContractorFlg(e.target.checked)}
                              ></Checkbox>
                            ) : (
                              contractorFlg && (
                                <Image
                                  preview={false}
                                  width={13}
                                  src={checked}
                                  // style={{ marginLeft: '5px' }}
                                  id="imgOutsourcingFg"
                                />
                              )
                            )}
                          </td>
                          <td>
                            <Input
                              className={editMode ? 'input-editable' : 'input-non-editable'}
                              name="contractorCoefficients"
                              id="contractorCoefficients"
                              value={contractorCoefficients}
                              type="text"
                              ref={(ref) => {
                                inputItemRef['contractorCoefficients'] = ref;
                              }}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              onPressEnter={(e) => onChangeCoeff(e.target.value, ClientType.Contractor)}
                              onBlur={(e) => onChangeCoeff(e.target.value, ClientType.Contractor)}
                              onChange={(e) => onChangeCoeff(e.target.value, ClientType.Contractor, false)}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                            />
                          </td>
                        </tr>
                      </table>

                      /* contractorFlg ? (
                        <Image
                          preview={false}
                          width={13}
                          src={checked}
                          // style={{ marginLeft: '5px' }}
                          id="imgOutsourcingFg"
                        />
                      ) : (
                        <></>
                      ) */
                    }
                  </td>
                </tr>
                {/* Tel */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">Tel</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="tel"
                      id="tel"
                      value={tel}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['tel'] = ref;
                      }}
                      maxLength={20}
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </td>
                </tr>
                {/* Fax */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">Fax</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="fax"
                      id="fax"
                      value={fax}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['fax'] = ref;
                      }}
                      maxLength={20}
                      onChange={(e) => setFax(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 担当者 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">担当者</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="contactParson"
                      id="contactParson"
                      value={contactParson}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['contactParson'] = ref;
                      }}
                      maxLength={50}
                      onChange={(e) => setContactParson(e.target.value)}
                    />
                  </td>
                </tr>
                {/* メール */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">メール</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="mail"
                      id="mail"
                      value={mail}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['mail'] = ref;
                      }}
                      maxLength={100}
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 郵便番号 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">郵便番号</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="zip"
                      id="zip"
                      value={zip}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['zip'] = ref;
                      }}
                      maxLength={10}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 都道府県 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">都道府県</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="prefecture"
                      id="prefecture"
                      value={prefecture}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['prefecture'] = ref;
                      }}
                      maxLength={10}
                      onChange={(e) => setPrefecture(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 住所 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">住所</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="address"
                      id="address"
                      value={address}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['address'] = ref;
                      }}
                      maxLength={250}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </td>
                </tr>
                {/* ソート番号 */}
                {/*<tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">ソート番号</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="sortNum"
                      id="sortNum"
                      value={sortNum}
                      type="text"
                      ref={(ref) => {
                        inputItemRef['sortNum'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      onChange={(e) => setSortNum(e.target.value)}
                    />
                  </td>
                </tr> */}
                {/* 作成日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">作成日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{created ? formatDate(created) : ''}</label>
                  </td>
                </tr>
                {/* 更新日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{modified ? formatDate(modified) : ''}</label>
                  </td>
                </tr>
                {/* 更新者 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新者</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{modifier}</label>
                  </td>
                </tr>
                {/* 備考 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="remarks"
                      id="remarks"
                      value={remarks}
                      type="text"
                      maxLength={2048}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 表示 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <RadioGroup
                        name="isUsed"
                        defaultValue={isUsed}
                        className="radioCustomerGpEdit"
                        onChange={(e) => setIsUsed(e.target.value)}
                        value={isUsed}
                      >
                        <Radio value={true}>オン</Radio>
                        <Radio value={false}>オフ</Radio>
                      </RadioGroup>
                    ) : isUsed ? (
                      <Image
                        preview={false}
                        width={13}
                        src={checked}
                        // style={{ marginLeft: '5px' }}
                        id="imgOutsourcingFg"
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </Form>
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default CustomerSupplierDetails;
