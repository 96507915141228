/**
 * クラス名：追加項目パラメータ
 * 説明：追加項目情報詳細画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import React, { Component, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Modal, Row, Col, Divider, Button, Radio, Image, Form, DatePicker, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { WithRouter } from '../../../../components/WithRouter';
import checked from '../../../../assets/images/checked.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import { today, TextArea, formValidatorMode } from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';

const RadioGroup = Radio.Group;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const AdditionalItemsDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    displayDetailInfo: displayDetailInfo,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [id, setID] = useState('');
  const [display, setDisplay] = useState();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [remarks, setRemarks] = useState('');
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState('');
  const [tableNo, setTabelNo] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);

  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  useEffect(() => {
    let selectedRowData = props.selectedData;
    if (selectedRowData == null) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      setIsNoData(false);
    } else if (props.selectedData?.length === 0) {
      // 一覧の検索結果が無い場合、
      resetDetailInfo();
      setIsNoData(true);
      props.updateEditMode(false);
    } else if (tableNo !== props.tableNo || id !== selectedRowData?.id) {
      // 一覧の検索結果がある場合、
      displayDetailInfo(selectedRowData);
      setIsNoData(false);
      props.updateEditMode(false);
    }
    setTabelNo(props.tableNo);
  }, [props.selectedData]);

  // 編集モードより、「更新、破壊、編集」ボタンの表示・非表示を判断する
  useEffect(() => {
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  // 詳細情報をリセットする。
  const resetDetailInfo = () => {
    setID('');
    setDisplay('');
    setCode('');
    setName('');
    setRemarks('');
    setCreated('');
    setUpdated('');
    setTabelNo('');
  };

  // 詳細情報を表示する
  const displayDetailInfo = (data) => {
    setID(data?.id);
    setDisplay(data?.isUsed);
    setCode(data?.code);
    setName(data?.name);
    setRemarks(data?.remarks);
    setCreated(data?.created?.split('T')[0]);
    setUpdated(data?.modified?.split('T')[0]);
    /* setTabelNo(data.tableNo); */
  };

  // 編集ボタンの押下
  const editData = () => {
    props.updateEditMode(true);
  };

  // 更新ボタンの押下
  const updateData = () => {
    //setUpdateConfirm(true);
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
  };

  // 破壊ボタンの押下
  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 更新確認ポップアップのOKボタン押下
  const updConfirmOk = (e) => {
    setUpdateConfirm(false);
    if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
      return;
    }
    props.updateEditMode(false);
    submitData();
  };

  // 更新確認ポップアップのCancelボタン押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    props.updateEditMode(false);
    displayDetailInfo(props.selectedData);
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 入力された情報を更新する
  const submitData = () => {
    if (props.selectedData && props.selectedData.id === id) {
      props.selectedData.isUsed = display;
      props.selectedData.code = code;
      props.selectedData.name = name;
      props.selectedData.remarks = remarks;
      props.selectedData.created = created;
      props.selectedData.updated = today;
      //props.selectedData.tableNo = tableNo;
      props.submitData(props.selectedData, tableNo);
    }
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  return (
    <>
      <Form>
        {/* ボタン部分 */}
        <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
          <div>
            <Button className="mainButton" style={{ marginLeft: 10, display: 'none' }} id="update" onClick={updateData}>
              更新
            </Button>
          </div>
          <div>
            <Button
              className="cancelButton"
              style={{ marginLeft: 10, display: 'none' }}
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
              編集
            </Button>
          </div>
        </div>
        {/* プロパティ表示 */}
        <div className="overflow-auto" style={{ height: 760, marginTop: 10 }}>
          <div id="showData">
            <Table id="param_detail_tbl" className="propertiesTbl">
              <thead>
                {/* コード */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">コード</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="code"
                      id="code"
                      value={code}
                      type="text"
                      onChange={(e) => setCode(e.target.value)}
                      ref={(ref) => {
                        inputItemRef['code'] = ref;
                      }}
                    />
                  </td>
                </tr>
                {/* 名称 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">名称</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="name"
                      id="name"
                      value={name}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      ref={(ref) => {
                        inputItemRef['name'] = ref;
                      }}
                    />
                  </td>
                </tr>

                {/* 作成日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">作成日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{created}</label>
                  </td>
                </tr>
                {/* 更新日 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">更新日</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <label className="param_detail-title-label">{updated}</label>
                  </td>
                </tr>
                {/* 備考 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="remarks"
                      id="remarks"
                      value={remarks}
                      type="text"
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      //  autoSize={{ minRows: 3, maxRows: 3 }}
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </td>
                </tr>
                {/* 表示 */}
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {editMode ? (
                      <RadioGroup
                        name="display"
                        defaultValue={display}
                        className="radioCustomerGpEdit"
                        onChange={(e) => setDisplay(e.target.value)}
                        value={display}
                      >
                        <Radio value={true}>オン</Radio>
                        <Radio value={false}>オフ</Radio>
                      </RadioGroup>
                    ) : display ? (
                      <Image preview={false} width={13} src={checked} id="imgOutsourcingFg" />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </Form>
      {/* 更新確認_Modal */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/* 破棄確認_Modal */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default AdditionalItemsDetails;
