import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { WorkType, DataType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_Kensa = forwardRef((props, ref) => {
  const [isKensadetail, setIKensadetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);

  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);

  const [kensaAmt, setKensaAmt] = useState(0);
  const [kensaTime, setKensaTime] = useState();

  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [patternInfo, setPatternInfo] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  /* const handleChangeCheckData = (e, i) => {
    setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e);
  }; */

  const settingEditData = (arr) => {
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let childSumArr = [];
    let dandoriAmtSum = 0;
    let dandoriTimeSumSec = 0;

    if (arr[0] == undefined) return;

    dandoriAmtSum = arr[0].dandoriAmt;
    dandoriTimeSumSec = arr[0].dandoriTime;

    for (let i = 0; i < arr?.length; i++) {
      sagyoAmt += arr[i].sagyoAmt;
      sagyoTimeSec += arr[i].sagyoTime;

      childSumArr.push({
        id: i,
        processName: arr[i].processName,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        dandoriTimeSec: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        sagyoTimeSec: arr[i].sagyoTime,
        processCount: arr[i].processCount,
        processTime: arr[i].processTime,
      });
    }
    return childSumArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      sagyoTimeSumSec += element.sagyoTimeSec;
    });

    let totalSum = sagyoAmtSum + editDataArr[0].dandoriAmt;
    let totalTimeSum = sagyoTimeSumSec + editDataArr[0].dandoriTimeSec;
    let sagyoTimeSum = sagyoTimeSumSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  useEffect(() => {
    let editData = [];
    let totals = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    setKensaAmt(0);
    setKensaTime(0);
    setTotalSagyoAmt(0);
    setTotalSagyoTime(0);
    setTotalAmt(0);
    setTotalTime(0);
    setTotalDandoriAmt(0);
    setTotalDandoriTime(0);
    setTotalTimeSum(0);
    setDandoriTimeSum(0);
    setDandoriAmtSum(0);
    setSagyoTimeSum(0);
    setTotalSum(0);
    setSagyoAmtSum(0);
    setTotalTimeSum(0);

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.SmInspection)[0]
      : [];
    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    let kensaAdditions = props?.selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];

    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let kensaAdditionEditData = kensaAdditions?.editItems;
    let editDataFlg = kensaAdditions?.editDataFlg;
    // 検査工程入力情報取得
    let kensaProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 新規の場合
    // if (kensaAdditions?.dataItems?.length == 0 || kensaAdditions == undefined) {
    let kensaAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '検査金額/検査時間';
    let processTime = 0;

    // 検査詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      processCount: Number(kensaProcessInput?.details?.[0]?.numOfCuts),
      processTime: processTime,
      kihon: true,
      formula: '',
    });

    kensaAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: kensaAdditions?.dataType ? kensaAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: kensaAdditions?.editItems ? kensaAdditions.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    kensaAdditions = kensaAddition;
    // }

    if (kensaAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(kensaAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;

        // 面積
        let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);

        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          /** 段取金額/時間 */
          // 段取時間設定
          kensaAdditions.dataItems[0].dandoriTime = 0;
          orgData[0].dandoriTime = 0;
          setDandoriTimeSum(0);

          // 段取金額設定
          kensaAdditions.dataItems[0].dandoriAmt = 0;
          orgData[0].dandoriAmt = 0;
          setDandoriAmtSum(0);

          /** 作業金額/時間 */
          // 作業時間
          // 1検査時間＊検査箇所＊サイズ係数
          kensaAdditions.dataItems[0].processCount = 0;
          orgData[0].processCount = 0;

          kensaAdditions.dataItems[0].sagyoTime = 0;
          orgData[0].sagyoTime = 0;
          setSagyoTimeSum(0);

          // 1検査時間
          kensaAdditions.dataItems[0].processTime = 0;
          orgData[0].processTime = 0;

          // 作業金額
          kensaAdditions.dataItems[0].sagyoAmt = 0;
          orgData[0].sagyoAmt = 0;

          setTotalSum(0);
          kensaAdditions.totalDataPrice = 0;
          kensaAdditions.totalDandori.dataItemsAmt = 0;
          kensaAdditions.totalSagyo.dataItemsAmt = 0;
          setSagyoAmtSum(0);
          setTotalTimeSum(0);
          kensaAdditions.totalDataTime = 0;
          kensaAdditions.totalDandori.dataItemsTime = 0;
          kensaAdditions.totalSagyo.dataItemsTime = 0;

          //editData = settingEditData(JSON.parse(JSON.stringify(orgData)));
        } else {
          //検査箇所
          let processCount = Number(kensaProcessInput?.details?.[0]?.numOfCuts);
          let dandoriTimeSec = 0;
          let dandoriTimeHms = 0;
          let dandoriAmt = 0;
          // 検査箇所が０の場合、段取計算しない。（IQX_WEBEST-63）
          if (processCount > 0) {
            /** 段取金額/時間 */
            // 段取時間設定
            //　基本段取時間＊サイズ係数
            dandoriTimeSec = calDandoriTime(deviceMst, weight, area);
            dandoriTimeHms = dandoriTimeSec;
            kensaAdditions.dataItems[0].dandoriTime = dandoriTimeHms;
            orgData[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;
            setDandoriTimeSum(dandoriTimeHms);

            // 段取金額設定
            //段階時間＊時間チャージ
            dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
            kensaAdditions.dataItems[0].dandoriAmt = dandoriAmt;
            orgData[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
            setDandoriAmtSum(dandoriAmt);
          } else {
            kensaAdditions.dataItems[0].dandoriTime = 0;
            orgData[0].dandoriTime = 0;
            setDandoriAmtSum(0);
            kensaAdditions.dataItems[0].dandoriAmt = 0;
            orgData[0].dandoriAmt = 0;
            setDandoriTimeSum(0);
          }
          /** 作業金額/時間 */
          // 作業時間
          // 1検査時間＊検査箇所＊サイズ係数
          kensaAdditions.dataItems[0].processCount = processCount;
          orgData[0].processCount = processCount ? processCount : 0;

          let cSagyoTimeSec = calSagyoTime(deviceMst, weight, area, processCount);
          let cSagyoTime = cSagyoTimeSec;
          kensaAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime ? cSagyoTime : 0;
          setSagyoTimeSum(cSagyoTime);

          // 1検査時間
          kensaAdditions.dataItems[0].processTime = deviceMst?.details?.inspectionWorkTime;
          orgData[0].processTime = deviceMst?.details?.inspectionWorkTime;

          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          kensaAdditions.dataItems[0].sagyoAmt = cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt ? cSagyoAmt : 0;

          let totalSum = dandoriAmt + cSagyoAmt;
          setTotalSum(totalSum);
          kensaAdditions.totalDataPrice = totalSum;
          kensaAdditions.totalDandori.dataItemsAmt = dandoriAmt;
          kensaAdditions.totalSagyo.dataItemsAmt = cSagyoAmt;
          setSagyoAmtSum(cSagyoAmt);

          let totalTimeSec = dandoriTimeSec + cSagyoTimeSec;
          setTotalTimeSum(totalTimeSec);
          kensaAdditions.totalDataTime = totalTimeSec;
          kensaAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
          kensaAdditions.totalSagyo.dataItemsTime = cSagyoTimeSec;
        }
        setProcessArr(orgData);

        // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
        if (kensaAdditionEditData?.length > 0 && editDataFlg) {
          editData = settingEditData(JSON.parse(JSON.stringify(kensaAdditionEditData)));
        } else {
          editData = settingEditData(JSON.parse(JSON.stringify(orgData)));
        }

        if (editData != undefined && editData?.length > 0) {
          kensaAdditions.editItems = editData;
          setEditedData(editData);
          setOldEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);
          kensaAdditions.totalEditPrice = totals[0]?.totalSum;
          kensaAdditions.totalDandori.editItemsAmt = editData[0].dandoriAmt;
          kensaAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          kensaAdditions.totalEditTime = totals[0]?.totalTimeSum;
          kensaAdditions.totalDandori.editItemsTime = editData[0].dandoriTime;
          kensaAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);

          setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum);
        }
        setKensaAmt(
          kensaAdditions?.dataType == DataType.Data ? kensaAdditions.totalDataPrice : kensaAdditions.totalEditPrice
        );
        setUpdateEditedData(kensaAdditions);
        props?.updateIQ3KouteiCal(kensaAdditions, WorkType.SmInspection);
      }
    }
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setKensaAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmInspection);
  };

  const calDandoriTime = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0].no && !item?.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoTime = (deviceMst, weight, area, processCount) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0].no && !item?.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊検査数＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.inspectionWorkTime * processCount * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = Number.isFinite(cSagyoAmtKonpo) ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const kensa = () => {
    if (isKensadetail == true) {
      setIKensadetail(false);
    } else {
      setIKensadetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('kensaProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('kensaProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownKensaIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpKensaIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('kensaProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownKensaIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpKensaIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setKensaAmt(updateEditedData.totalDataPrice);
    } else {
      setKensaAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmInspection);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    // 重量
    let weight = props?.selectedIQ3DataDetail?.weight;

    // 面積
    let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);

    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );

    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0].no && !item?.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let oldValue = editedData[index][objId];
    newState[index][objId] = Number(value);
    // let deviceMst = [];
    value = value ? Number(value) : 0;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'KensaDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'KensaDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          }
          // checkInputWithColor(false, '', index + 'dandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      if (document.getElementById(index + 'KensaSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'KensaSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
          }
          // checkInputWithColor(false, '', index + 'sagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'handelingTime') {
      if (document.getElementById('KensaHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('KensaHandlingAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false,'','KensaHandlingAmt', newState[0].handelingAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          } else {
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          }
          /* checkInputWithColor(false,'','KensaHandlingAmt', newState[0].handelingAmt, 'green');*/
        }
      }
    } else if (objId === 'processCount') {
      // 1検査時間＊検査箇所＊サイズ係数
      let processCount = Number(value);

      let cSagyoTimePerSec = calSagyoTime(deviceMst, weight, area, processCount);
      //let cSagyoTimePerSec = 300;
      let cSagyoTimeSec = cSagyoTimePerSec;
      let cSagyoTime = cSagyoTimeSec;
      newState[index].sagyoTime = cSagyoTime;
      updateEditedData.editItems[index].sagyoTime = cSagyoTime;

      // 作業金額
      //　作業時間＊時間チャージ
      if (document.getElementById(index + 'KensaSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'KensaSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          newState[index].sagyoAmt = cSagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = cSagyoAmt;
        }
      }
    } else if (objId === 'processTime') {
      // 入力１検査時間＊検査箇所＊サイズ係数
      let cSagyoTimeSecIn = Number(value);
      newState[index].processTime = cSagyoTimeSecIn ? cSagyoTimeSecIn : 0;
      updateEditedData.editItems[index].processTime = cSagyoTimeSecIn ? cSagyoTimeSecIn : 0;

      let cSagyoTimeSec = cSagyoTimeSecIn * newState[index].processCount * partsCoeff;

      let cSagyoTime = cSagyoTimeSec;
      newState[index].sagyoTime = cSagyoTime;
      updateEditedData.editItems[index].sagyoTime = cSagyoTimeSec;

      // 作業金額
      //　作業時間＊時間チャージ
      if (document.getElementById(index + 'KensaSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'KensaSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          newState[index].sagyoAmt = cSagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = cSagyoAmt;
        }
      }
    }
    // else if (objId === 'kensaTime') {
    //   if (document.getElementById('KensaKensaAmtCu').style.display !== undefined) {
    //     let showHideCu = document.getElementById('KensaKensaAmtCu').style.display;
    //     if (showHideCu === 'block') {
    //       //checkInputWithColor(false,'','KensaKensaAmt', newState[0].kensaAmt, 'red');
    //     } else {
    //       if (timeAct === 'input') {
    //         let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
    //         newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //       } else {
    //         newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //       }
    //       checkInputWithColor(false, '', 'KensaKensaAmt', newState[0].kensaAmt, '#4cc12f');
    //     }
    //   }
    // }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += Number(element.sagyoAmt);
      newDandoriSum += Number(element.dandoriAmt);
      newSagyoTimeSec += element.sagyoTime;
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += Number(newState[0].handelingAmt);
    Number(newState[0].processCount) * setTimetoSec(newState[0].processTime)  + 
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime);*/
    newSum = (Number(newSagyoSum) + Number(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let kensaTimeSec = setTimetoSec(newState[0].kensaTime);
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    /* checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    /* setEditedHandelingAmt(newState[0].handelingAmt);
    setEditedHandelingTime(newState[0].handelingTime); */
    // setEditedKensaAmt(newState[0].kensaAmt);
    // setEditedKensaTime(newState[0].kensaTime);
    setEditedData(newState);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setKensaAmt(updateEditedData.totalDataPrice);
    } else {
      setKensaAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmInspection);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let deviceMst = [];
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={kensa}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isKensadetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{kensaAmt ? JPYs.format(Math.round(kensaAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isKensadetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtIn" style={{ color: totalSum == totalAmt ? 'black' : '#4cc12f' }}>
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeIn" style={{ color: totalTimeSum == totalTime ? 'black' : '#4cc12f' }}>
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm}>時間</Popover>
          </Col>
        </Row>
        {/* 段取金額/時間 */}
        {/* <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('KensaDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpKensaDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownKensaDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{JPYs.format(dandoriAmtSum)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtIn" className="resetColor">
             
              {JPYs.format(totalDandoriAmt)}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeIn" className="resetColor">
              {totalDandoriTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row> */}

        {(() => {
          const arr = [];
          for (let j = 0; j < processArr?.length; j++) {
            arr.push(
              <div>
                {/* ○○_標準 */}
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <div style={{ display: 'flex' }}>
                      <span>段取金額/時間</span>
                    </div>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'KensaDandoriAmt'}>
                      {processArr[0]?.dandoriAmt ? JPYs.format(Math.round(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    {
                      <label id={j + 'KensaDndoriTime'}>
                        {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                      </label>
                    }
                  </Col>

                  <Col span={4} className="input-col">
                    <CurrencyInput
                      id={j + 'KensaDandoriAmtIn'}
                      value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                      defaultValue={0}
                      style={{
                        border: 'none',
                        color:
                          Number(processArr[j]?.dandoriAmt) === Number(editedData[j]?.dandoriAmt)
                            ? 'black'
                            : checkShowHideIcon(j + 'KensaDandoriAmtCu') === 'none'
                            ? '#4cc12f'
                            : 'red',
                      }}
                      onValueChange={(e) => {
                        //checkRegex(e.target.value);

                        if (editedData[j]?.dandoriAmt != e) {
                          let value = e; /* ? parseInt(e) : 0; */
                          settingNewDandoriAmtInArr(value, j, 'dandoriAmt', '', '');
                          checkInputWithColor(false, '', j + 'KensaDandoriAmt', value, 'amt');
                        }
                      }}
                      className={
                        editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                      }
                      prefix="¥"
                      decimalsLimit={100}
                    />
                    <div id={j + 'KensaDandoriAmtCu'} style={{ display: 'none' }}>
                      <div className="currencyIcon">
                        <BsUsbC style={{ fontSize: '25' }} />
                        <BsX style={{ margin: '-8px 0 0 16px' }} />
                        <span class="tooltiptext">
                          金額に変更がありましたので、以降時間と連携して自動計算されない。
                        </span>
                      </div>
                    </div>
                  </Col>

                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                          id={j + 'KensaDandoriTimeIn'}
                          style={{
                            width: '100%',
                            color:
                              processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                ? handleShowHide(
                                    Number(editedData[j]?.dandoriAmt),
                                    processArr[j]?.dandoriAmt,
                                    j + 'KensaDandoriAmtCu'
                                  )
                                : 'red',
                          }}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          onChange={(e) => {
                            settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                          }}
                        ></Input>
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'KensaDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={2} className="formula-column formula-column-right-border">
                    <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                  </Col>
                  <Col span={2} className="formula-column">
                    <Popover content={helpCmt.setupTm_p6}>時間</Popover>
                  </Col>
                </Row>
              </div>
            );
          }
          return arr;
        })()}

        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KensaSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpKensaSagyoTotal'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownKensaSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtIn" style={{ color: sagyoAmtSum == totalSagyoAmt ? 'black' : '#4cc12f' }}>
              {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" style={{ color: sagyoTimeSum == totalSagyoTime ? 'black' : '#4cc12f' }}>
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.workAmt_p2}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.workTm_kensa}>時間</Popover>
          </Col>
        </Row>
        <div id={'divKensaSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < editedData?.length; i++) {
              arr.push(
                <div>
                  {/* 検査金額/検査時間 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <span className="ddl_2_noImg">検査金額/検査時間</span>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'KensaSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'KensaSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'KensaSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            Number(processArr[i]?.sagyoAmt) === Number(editedData[i]?.sagyoAmt)
                              ? 'black'
                              : checkShowHideIcon(i + 'KensaSagyoAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);

                          if (editedData[i]?.sagyoAmt != e) {
                            let value = e ? parseInt(e) : 0;
                            settingNewDandoriAmtInArr(value, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'KensaSagyoAmt', value, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={i + 'KensaSagyoAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                            id={i + 'KensaSagyoTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                  ? 'black'
                                  : oldEditedData[i]?.processCount !== editedData[i]?.processCount ||
                                    oldEditedData[i]?.processTime !== editedData[i]?.processTime
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'KensaSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={4} className="formula-column"></Col>
                  </Row>
                  {/* 検査箇所/1検査時間 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <span className="ddl_2_noImg">検査箇所/１検査時間</span>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'processCount'}>{processArr[i]?.processCount}</label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {processArr[i]?.processTime ? secondsToHms(processArr[i]?.processTime) : timeHmsStr}
                    </Col>

                    <Col span={4} className="input-col">
                      <Input
                        value={editedData[i]?.processCount}
                        id={i + 'processCountIn'}
                        min={0}
                        className={editMode ? 'input-editable' : 'input-non-editable '}
                        controls={false}
                        style={{
                          width: '45%',
                          border: 'none',
                          color: editedData[i]?.processCount === processArr[i]?.processCount ? 'black' : 'red',
                        }}
                        onKeyPress={(e) => {
                          handleKeyPress(e);
                        }}
                        onChange={(e) =>
                          settingNewDandoriAmtInArr(
                            e.target.value ? parseInt(e.target.value) : 0,
                            i,
                            'processCount',
                            '',
                            ''
                          )
                        }
                      />
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.processTime ? secondsToHms(editedData[i]?.processTime) : timeHmsStr}
                            id="processTimeIn"
                            style={{
                              width: '100%',
                              color: editedData[i]?.processTime === processArr[i]?.processTime ? 'black' : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'processTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.processTime,
                                    '10min',
                                    'processTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.processTime,
                                    '10min',
                                    'processTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(editedData[i]?.processTime, 'min', 'processTime');
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(editedData[i]?.processTime, 'min', 'processTime');
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(editedData[i]?.processTime, 'sec', 'processTime');
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(editedData[i]?.processTime, 'sec', 'processTime');
                                  settingNewDandoriAmtInArr(editedTime, i, 'processTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={4} className="formula-column"></Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>

        {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            <span style={{ marginLeft: '5px' }}>工程内検査金額/時間</span>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
           
            <label id="KensaKensaAmt">{JPYs.format(kensaAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="KensaKensaTime">{kensaTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id="KensaKensaAmtIn"
              value={editedData[0]?.kensaAmt}
              defaultValue={0}
              onValueChange={(e) => {
                if (editedData[0]?.kensaAmt != e) {
                  settingNewDandoriAmtInArr(e, 0, 'kensaAmt', '', '');
                  checkInputWithColor(false,'','KensaKensaAmt', e, 'amt');
                }
              }}
              style={{
                border: 'none',
                color:
                  Number(editedData[0]?.kensaAmt) === kensaAmt
                    ? 'black'
                    : checkShowHideIcon('KensaKensaAmtCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id="KensaKensaAmtCu" style={{ display: 'none' }} className="currencyReset">
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={editedData[0]?.kensaTime}
                  id="KensaKensaTimeIn"
                  style={{
                    width: '100%',
                    color:
                      editedData[0]?.kensaTime === kensaTime
                        ? handleShowHide(Number(kensaAmt), Number(editedData[0]?.kensaAmt), 'KensaKensaAmtCu')
                        : 'red',
                  }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    settingNewDandoriAmtInArr(e.target.value, 0, 'kensaTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, '10min', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, '10min', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'min', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'min', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'sec', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'sec', 'kensaKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row> */}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Kensa;
