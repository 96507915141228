/**
 * クラス名：材質名称一覧画面
 * 説明：iQ3板金に使用の材質名称一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import MaterialClassNameDetail from './MaterialClassNameDetail';
import 'split-pane-react/esm/themes/default.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SplitPane, { Pane } from 'split-pane-react';
import { Table as RTable } from 'react-bootstrap';
import { StatusCodes } from 'http-status-codes';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  getMaterialTypeList,
  actionArr,
  dataStateList,
  formatDate,
  updateAccessToken,
  getAccessToken,
  toDecimal,
  defaultValue,
  formValidatorMode,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDecimal,
} from '../../../common/Common.js';
import { createMaterialTypeCSV, createMaterialTypeIq3CSV, getMaterialTypeIQ3Info } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import { pageSizes } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);

const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialClassNameList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const csvExportFileName = `材質名称＊（名称毎比重、材料粗利係数）_${formatDateString(todayTime())}.csv`;

  const detailRef = useRef();
  const messageRef = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材質名称＊（名称毎比重、材料粗利係数）');
  const [paramType, setParamType] = useState('iq3');
  const [materialClassNameData, setMaterialClassNameData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [checkedRowId, setCheckedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();

  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [gravityKeyword, setGravityKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(false);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // pagination
  const [pageSize, setPageSize] = useState(100);

  const inputItemRef = {};

  useEffect(() => {
    getMaterialTypeIQ3Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 板金子部品材質マスタ情報を取得する
  const getMaterialTypeIQ3Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    let materialClassNameData = await getMaterialTypeIQ3Info();
    setMaterialClassNameData(materialClassNameData);
    setFilteredData(materialClassNameData);
    setTempMaterialClass(materialClassNameData);
    setSelectedRowId(materialClassNameData[0]?.id);
    setSelectedData(materialClassNameData[0]);
    props.setParam(paramType, paramSelected);
    setIsFilter(true);
    let materialTypeList = await getMaterialTypeList();
    setMaterialTypeList(materialTypeList);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
      sorter: (a, b) => a.code?.toUpperCase()?.localeCompare(b.code?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'name',
      title: '材質名称',
      dataIndex: 'name',
      width: 150,
      sorter: (a, b) => a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'materialTypeName',
      title: '材質区分名',
      dataIndex: 'materialTypeId',
      width: 150,
      render: (materialTypeId) => getMaterilTypeName(materialTypeId),
      sorter: (a, b) =>
        getMaterilTypeName(a.materialTypeId)
          ?.toUpperCase()
          ?.localeCompare(getMaterilTypeName(b.materialTypeId)?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'gravity',
      title: '比重',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      render: (info) => toDecimal(info.gravity, 2),
      sorter: (a, b) => a.info.gravity - b.info.gravity,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'lossCoeff',
      title: '材料粗利係数',
      dataIndex: 'info',
      width: 120,
      className: 'cm-a-right',
      render: (info) => toDecimal(info.lossCoeff, 2),
      sorter: (a, b) => a.info.lossCoeff - b.info.lossCoeff,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => a.modifier?.toUpperCase()?.localeCompare(b.modifier?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      width: 200,
      render: (info) => info.remarks,
      sorter: (a, b) => a.info.remarks?.toUpperCase()?.localeCompare(b.info.remarks?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  // 材質区分名をIDで取得する
  const getMaterilTypeName = (materialTypeId) => {
    return materialTypeList?.filter((item) => item.value === materialTypeId)?.[0]?.label;
  };

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (name === 'gravity') {
      if (isCheckZenkaku) value = toDecimal(value, 2);
      setTempMaterialClass((prevData) => ({
        ...prevData,
        info: { ...prevData.info, gravity: value },
      }));
    } else {
      if (isCheckZenkaku) value = toDecimal(value === '' ? 1 : value, 2);
      setTempMaterialClass((prevData) => ({
        ...prevData,
        info: { ...prevData.info, lossCoeff: value },
      }));
    }
  };

  const addModalContent = (
    <div id="addNewMaterialClass" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label>{tempMaterialClass.no}</label>
            </td>
          </tr> */}

          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="code"
                className={'input-editable'}
                value={tempMaterialClass?.code}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, code: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialClass?.name}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, name: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質区分名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '60%' }}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, materialTypeId: e });
                  }
                }}
                id="materialTypeId"
                name="materialTypeId"
                defaultValue={defaultValue.materialType}
              >
                <Select.Option id="MaterialType" value={defaultValue.materialType}>
                  {defaultValue.materialType}
                </Select.Option>
                {materialTypeList?.map((item, index) => (
                  <Select.Option key={index} id={'MaterialType' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">比重</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                name="gravity"
                data-name="gravity"
                className={'input-editable'}
                value={tempMaterialClass?.info?.gravity}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['gravity'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材料粗利係数</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="lossCoeff"
                className={'input-editable'}
                value={tempMaterialClass?.info?.lossCoeff}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['lossCoeff'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">作成日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">{tempMaterialClass.created}</label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">更新日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">{tempMaterialClass.modified}</label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                type="text"
                data-name="remarks"
                className={'input-editable'}
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                value={tempMaterialClass?.info?.remarks}
                onChange={(e) => {
                  {
                    setTempMaterialClass((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, remarks: e.target.value },
                    }));
                  }
                }}
                maxLength={2048}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialClass((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, isUsed: e.target.value },
                    }));
                  }
                }}
                value={tempMaterialClass?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  const onSelectedRowIdChange = (checked, record, index) => {
    let selectedRowId = [...checked];
    setCheckedRowId(selectedRowId);
    setChkRowsCount(selectedRowId?.length);
  };

  const rowSelection = {
    onChange: onSelectedRowIdChange,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '58vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
          />
        </Col>
        <Col span="11">
          <div
            id="editGpDataTb"
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              // overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable id="param_detail_tbl" className="propertiesTbl">
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">材質区分名</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Select
                      className={'search-select'}
                      style={{ width: '35%' }}
                      onChange={(e) => {
                        {
                          setTempMaterialClass({ ...tempMaterialClass, materialTypeId: e });
                        }
                      }}
                      id="materialTypeId"
                      name="materialTypeId"
                      defaultValue={defaultValue.materialType}
                    >
                      <Select.Option id="MaterialType" value={defaultValue.materialType}>
                        {defaultValue.materialType}
                      </Select.Option>
                      {materialTypeList?.map((item, index) => (
                        <Select.Option key={index} id={'MaterialType' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">材料粗利係数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      data-name="lossCoeff"
                      className={'input-editable'}
                      value={tempMaterialClass?.info?.lossCoeff}
                      onChange={(e) => {
                        {
                          setTempMaterialClass((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, lossCoeff: e.target.value },
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        {
                          setTempMaterialClass((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, lossCoeff: toDecimal(e.target.value) },
                          }));
                        }
                      }}
                      ref={(ref) => {
                        inputItemRef['lossCoeff'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      type="text"
                      data-name="remarks"
                      className={'input-editable'}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      value={tempMaterialClass?.info?.remarks}
                      onChange={(e) => {
                        {
                          setTempMaterialClass((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, remarks: e.target.value },
                          }));
                        }
                      }}
                      maxLength={2048}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="isUsed"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialClass((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, isUsed: e.target.value },
                          }));
                        }
                      }}
                      value={tempMaterialClass?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button
          /* style={{ marginLeft: 10, color: '#000000', fontSize: '13.5px' }} */ className="cancelButton"
          onClick={groupEditCancel}
        >
          破棄
        </Button>
      </Row>
    </Form>
  );

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const onRowSelect = (record, index) => {
    setChangeRecordId(record.id);
    // setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      //setEditModeCancelConfirm(true);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq3.materialClassName');
      }
    } else {
      setSelectedRowId(record.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialClassName');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    //setCancelConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    // setVisibleCSVOutput(false);
    // setVisibleAddNewModal(false);
    // setVisibleCSVInput(false);
    // setVisibleXMLInput(false);
    setVisibleAddNewModal(false);
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '' || newObj[objKey] === defaultValue.materialType) {
      return oldObj[objKey];
    } else if (newObj.info[objKey] === '') {
      return oldObj.info[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey] || newObj.info[objKey] === oldObj.info[objKey]);
      return Object.keys(newObj?.info)?.filter((v) => v === objKey)?.length > 0 ? newObj.info[objKey] : newObj[objKey];
    }
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialClassNameData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false, materialClassNameData)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdData = await createMaterialTypeIq3Data(tempMaterialClass);
      stateData.push(createdData);
      setTmpFilteredData(stateData);
      setMaterialClassNameData(stateData);
      //onRowSelect(tempMaterialClass);
      if (createdData?.length !== 0) {
        resetAddModal(stateData?.length + 1);
        setSelectedRowId(createdData?.id);
        setSelectedData(createdData);
        setItemNo(stateData?.length);
        setCurrentPage(Math.ceil(stateData?.length / 25));
        setVisibleAddNewModal(false);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (visibleGroupEditModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      if (checkedRowId?.length > 0) {
        const updatedData = stateData?.map((obj) => {
          if (checkedRowId?.includes(obj.id)) {
            return {
              ...obj,
              name: gpEditCheck(tempMaterialClass, obj, 'name'),
              materialTypeId: gpEditCheck(tempMaterialClass, obj, 'materialTypeId'),
              info: {
                ...obj.info,
                isUsed: gpEditCheck(tempMaterialClass, obj, 'isUsed'),
                lossCoeff: gpEditCheck(tempMaterialClass, obj, 'lossCoeff'),
                remarks: gpEditCheck(tempMaterialClass, obj, 'remarks'),
              },
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        for (var i = 0; i <= updatedData?.length - 1; i++) {
          updatedData[i] = await updateMaterialTypeIq3Data(updatedData[i]);
        }
        setMaterialClassNameData(updatedData);
        setTmpFilteredData(updatedData);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
      setVisibleGroupEditModal(false);
    }
    /* setUpdateConfirm(false); */
    setIsFilter(true);
  };

  // 板金子部品材質マスタ情報をDBに新規登録する
  async function createMaterialTypeIq3Data(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          materialTypeId: createData.materialTypeId,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data.validationErrors?.length > 0) {
              let errorMsg = '';
              if (data.validationErrors[0].propertyName == 'Name') {
                errorMsg = ErrorMessage('材質名称')[data.validationErrors[0].errorCode];
              } else {
                errorMsg = ErrorMessage('')[data.validationErrors[0].errorCode];
              }
              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
            }
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
    /*  setEditModeCancelConfirm(showEdtConfirmCancle);
     if (!showEdtConfirmCancle) {
       detailRef.current.discardConfirmCancel();
     } */
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
      setChkRowsCount(0);
    }
  };

  const updConfirmCancel = () => {
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[1]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      name: '',
      materialTypeId: defaultValue.materialType,
      info: { isUsed: '', gravity: '', lossCoeff: '', remarks: '', sortNum: 0 },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
      setVisibleDeleteModal(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit();
      }
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    setTempMaterialClass({
      code: '',
      name: '',
      materialTypeId: defaultValue.materialType,
      info: { isUsed: true, gravity: '', lossCoeff: '', remarks: '', sortNum: 0 },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    // setUpdateCancelConfirm(true);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteMaterialTypeIq3Data(selectedData.id);

    let data = [...materialClassNameData];
    data = data?.filter((item) => item.id != deletedData?.id);

    setMaterialClassNameData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    setSelectedRowId(data?.length > 0 ? data[0].id : -1);
    setSelectedData(data?.length > 0 ? data[0] : []);
    setIsFilter(true);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 板金子部品材質マスタ情報をDBに削除する
  async function deleteMaterialTypeIq3Data(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTempMaterialClass({
      name: '',
      materialTypeId: defaultValue.materialType,
      info: { isUsed: true, gravity: '', lossCoeff: '', remarks: '', sortNum: 0 },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, gravityKeyword, dataState, showFlag, tmpFilteredData, materialClassNameData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (itemName, e) => {
    {
      let action;
      switch (itemName) {
        case 'name':
        case 'gravity':
          action = actionArr[1];
          break;
        case 'materialType':
          action = actionArr[4];
          break;
        case 'showFlag':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 材質区分の押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'gravity':
        gravityKeyword === e.target.value ? getDataByFilter() : setGravityKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialClassNameData?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let gKeyword = gravityKeyword;
    let currentData = [...materialClassNameData];
    let flag;
    let materialFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    }
    // 比重のキーワードで絞り込み
    if (gKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.info.gravity?.toString().indexOf(gKeyword) >= 0);
    }

    // 表示・非表示フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData?.filter((item) => item.info?.isUsed === flag);
    }

    // 参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData?.filter((item) => item.materialTypeId === dataState);
    }
    /*  let index = materialFilterData?.findIndex((item) => item.no === selectedRowId);

    if (index === -1) {
      if (editMode) {
        
        materialFilterData?.length > 0 && setSelectedRowId(materialFilterData[materialFilterData?.length - 1].no);
        setFilteredData(materialFilterData);
        return;
      }
      // 検索結果がある場合、
      if (materialFilterData?.length > 0) {
        index = materialFilterData?.length - 1;
        setSelectedRowId(materialFilterData[index]?.no);
        setSelectedData(materialFilterData[index]);
      }
    }
    if (materialFilterData?.length > 0 && isFilter) {
      
      setSelectedRowId(materialFilterData[index].no);
     
    } else if (materialFilterData?.length <= 0) {
      setSelectedData([]);
    } */
    setFilteredData(materialFilterData);
    setCurrentPage(1);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...materialClassNameData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateMaterialTypeIq3Data(updateData);
    var index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
    if (index !== -1) {
      tmpData[index] = updatedInfo;
      setMaterialClassNameData(tmpData);
      setTmpFilteredData(tmpData);
      setSelectedRowId(updatedInfo?.id);
      setSelectedData(updatedInfo);
      setIsFilter(false);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 板金子部品材質マスタ情報をDBに更新する
  async function updateMaterialTypeIq3Data(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          materialTypeId: updateData.materialTypeId,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data.validationErrors?.length > 0) {
              let errorMsg = '';
              if (data.validationErrors[0].propertyName == 'Name') {
                errorMsg = ErrorMessage('材質名称')[data.validationErrors[0].errorCode];
                //  inputItemRef['name'].classList.add('input-error');
              } else {
                errorMsg = ErrorMessage('')[data.validationErrors[0].errorCode];
              }
              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
              return;
            }
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        getMaterilTypeName(data.materialTypeId) +
        ',' +
        data.info.gravity +
        ',' +
        data.info.lossCoeff +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.modifier +
        ',' +
        data.info.remarks +
        ',' +
        data.info.isUsed;
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          code: '',
          name: '',
          materialTypeId: '',
          info: { isUsed: true, gravity: '', lossCoeff: '', remarks: '', sortNum: 0 },
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        tempData.id = data?.[0];
        tempData.code = data?.[2];
        tempData.name = data?.[3];
        tempData.materialTypeId = materialTypeList?.find((item) => item.label === data?.[4])?.value;
        tempData.info.gravity = data?.[5];
        tempData.info.lossCoeff = data?.[6];
        tempData.info.remarks = data?.[10];
        tempData.info.isUsed = data?.[11] ? JSON.parse(data?.[11]) : true;
        tempDatas.push(tempData);
      }
      createdDatas = await createMaterialTypeIq3CSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setMaterialClassNameData(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedRowId(createdDatas?.[0]?.id);
          setSelectedData(createdDatas?.[0]);
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // コード
    let input = data?.[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('コード').E007}</li>);
      return false;
    }

    // 材質名称
    input = data?.[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E007}</li>);
      return false;
    }

    // if (input?.length > 0 && materialClassNameData?.findIndex((item) => item.name === input) > -1) {
    //   // 材質名称の重複チェック
    //   messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E020}</li>);
    //   return false;
    // }

    // 材質区分名
    input = data?.[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分名').E007}</li>);
      return false;
    }

    if (input?.length > 0 && materialTypeList?.findIndex((item) => item.label === input) === -1) {
      // 不正な材質区分名
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分名').E016}</li>);
      return false;
    }

    // 比重
    input = data?.[5];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('比重').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('比重').E014}</li>);
      return false;
    }

    // 材料粗利係数
    input = data?.[6];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材料粗利係数').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('材料粗利係数').E014}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Input
                    className="input-editable search-select-input"
                    placeholder="材質名称"
                    allowClear
                    onPressEnter={(e) => beforeSearch('name', e)}
                    onBlur={(e) => beforeSearch('name', e)}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch('name', e);
                      }
                    }}
                    name="name"
                    id="name"
                  />

                  <Select
                    style={{ width: 170 }}
                    onChange={(e) => beforeSearch('materialType', e)}
                    id="materialType"
                    name="materialType"
                    value={dataState}
                    defaultValue={'全て'}
                  >
                    <Select.Option id="allOption" value={'全て'}>
                      {'全ての材質区分名'}
                    </Select.Option>
                    {materialTypeList?.map((item, index) => (
                      <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <Input
                    className="input-editable search-select-input"
                    placeholder="比重"
                    allowClear
                    onPressEnter={(e) => beforeSearch('gravity', e)}
                    onBlur={(e) => beforeSearch('gravity', e)}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch('gravity', e);
                      }
                    }}
                    name="gravity"
                    id="gravity"
                  />
                  <Select
                    style={{ width: 90 }}
                    onChange={(e) => beforeSearch('showFlag', e)}
                    placeholder="表示・非表示"
                    id="showFlag"
                    name="showFlag"
                    value={showFlag}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '50vw' }}
                    rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                    rowKey={(record) => record.id}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record?.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      // defaultPageSize: 25,
                      defaultCurrent: 1,
                      // pageSize: 25,
                      current: currentPage,
                      pageSize: pageSize,
                      pageSizeOptions: pageSizes,
                      showSizeChanger: true,
                      onShowSizeChange: handlePageSizeChange,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, index);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialClassName"
                element={
                  <MaterialClassNameDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialClassNameData?.find((element) => element.id === selectedRowId)}
                    materialClassNameData={materialClassNameData}
                    itemNo={itemNo}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    materialTypeList={materialTypeList}
                    getMaterilTypeName={getMaterilTypeName}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質名称（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質名称（選択したデータをまとめて編集）', 1790, -24),
          /* commonActionFooter(groupEditOK, groupEditCancel, '更新', '破棄'), */
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'updateModalStyle',
          null,
          true
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialClassNameList;
