/**
 * クラス名：板金のブランクの材料配置画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
import { handleKeyPress } from '../../../common/Common';
const Device_MaterialArrangement = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [materialArrangementValues, setMaterialArrangementValues] = useState();

  // 材料配置
  // const [pierWidth1, setPierWidth1] = useState('');
  // const [pierWidth2, setPierWidth2] = useState('');
  // const [grabAllowance1, setGrabAllowance1] = useState('');
  // const [grabAllowance2, setGrabAllowance2] = useState('');
  // const [processRange1, setProcessRange1] = useState('');
  // const [processRange2, setProcessRange2] = useState('');
  // const [pickupTime, setPickupTime] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    if (props?.detailsData?.materialArrange) {
      setMaterialArrangementValues(props?.detailsData?.materialArrange);
    }

    // setPierWidth1(props.pierWidth1);
    // setPierWidth2(props.pierWidth2);
    // setGrabAllowance1(props.grabAllowance1);
    // setGrabAllowance2(props.grabAllowance2);
    // setProcessRange1(props.processRange1);
    // setProcessRange2(props.processRange2);
    // setPickupTime(props.pickupTime);
  }, [
    props.editMode,
    props.detailsData,
    // props.pierWidth1,
    // props.pierWidth2,
    // props.grabAllowance1,
    // props.grabAllowance2,
    // props.processRange1,
    // props.processRange2,
    // props.pickupTime,
  ]);

  const changeValue = (id, value) => {
    materialArrangementValues[id] = value;
    setMaterialArrangementValues(materialArrangementValues);
    props.updateMaterialArrangement(materialArrangementValues);
  };
  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table style={{ width: '80%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center', verticalAlign: 'middle', height: 30 }}>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                掴み代X,Y(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                桟幅X,Y(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                部品加工範囲X,Y(mm)
              </th>
              <th style={{ width: '20%' }} colSpan={2} className="tbl-header">
                範囲超加算時間(sec)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ textAlign: 'center' }}>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.graspAllowanceX}
                  onChange={(event) => changeValue('graspAllowanceX', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.graspAllowanceY}
                  onChange={(event) => changeValue('graspAllowanceY', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.xWidth}
                  onChange={(event) => changeValue('xWidth', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.yWidth}
                  onChange={(event) => changeValue('yWidth', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.workRangeX}
                  onChange={(event) => changeValue('workRangeX', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.workRangeY}
                  onChange={(event) => changeValue('workRangeY', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td colSpan={2}>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={materialArrangementValues?.rangeAdditionalTime}
                  onChange={(event) => changeValue('rangeAdditionalTime', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default Device_MaterialArrangement;
