/**
 * クラス名：板金の装置設定の作業・加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  getProcessDetailTypeName,
  secondsToHms,
  setTimetoSec,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
  handleKeyPress,
  regexExp,
  toHalfWidth,
} from '../../../common/Common';

const Device_Bending = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);

  // 板厚
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  const [itaatsuData, setItaatsuData] = useState([]);
  const [itaaatsuList, setItaaatsuList] = useState([]);

  const [processMstRecord, setProcessMstRecord] = useState();
  // 入力チェック
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);

    setItaatsuData(props?.detailsData?.workTimeThickThMaster);
    setItaatsuTemp(props?.detailsData?.workTimeThickThMaster);
    setItaaatsuList(props?.detailsData?.workTimeThickItems);

    if (props?.processMstRecord) {
      setProcessMstRecord(props.processMstRecord);
    }
  }, [props.processName, props.editMode, props.itaatsuData, props.itaatsuLst, props?.detailsData]);

  const addMagesuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };

  // 板厚マスタの行追加を押下
  const addYousetsuSencho = (no, index) => {
    inputRef.current[index].focus();
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: itaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };

  // 板厚マスタの行削除を押下
  const deleteYousetsuSenchou = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (itaatsuTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = itaatsuTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 板厚マスタの値を変更した場合、
  const handleYousetsuSenchouChange = (event, no, field) => {
    const updatedData = itaatsuTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };

  const cancelYousetsuSenchoMaster = () => {
    setItaatsuTemp(itaatsuData);
    setIsItaatsu(false);
  };

  // 板厚マスタのOKボタン押下
  const okYousetsuSenchoMaster = () => {
    setIsItaatsu(false);

    const deletedId = [];

    itaatsuData?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.id);

    // const deletedData = itaaatsuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });
    // const updatedData = deletedData?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = itaatsuTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = itaatsuTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });

    const updatedData = updateList(itaaatsuList, deletedId, newId);
    const sortedList = sortListByMasterData(updatedData, itaatsuTemp);

    setItaatsuData(itaatsuTemp);

    setItaaatsuList(sortedList);
    props.updatedItaatsuData(itaatsuTemp);

    props.updatedItaatsuList(sortedList);
  };
  const isValueOrderCorrect = (e, no, field) => {
    const temp = itaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleYousetsuSenchouChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // 板厚マスタ
  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleYousetsuSenchouChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, itaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addYousetsuSencho(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousetsuSenchou(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okYousetsuSenchoMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelYousetsuSenchoMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 板厚ボタン押下
  const mageSuuModal = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsItaatsu(true);
  };

  // 曲げ種類の板厚リスト値を変更した場合、
  const itaaatsuListDataChange = (event, rowKey, no) => {
    const copyData = { ...itaaatsuList };
    const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event;
    Object.entries(updatedData)?.map(([key, listItem]) => {
      if (parseInt(key) === parseInt(rowKey)) {
        listItem?.map((item) => {
          if (item.no === no) {
            item.value = setTimetoSec(event);
          }
        });
      }
    });
    setItaaatsuList(updatedData);
    props.updatedItaatsuList(updatedData);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              itaatsuData?.filter((i) => !i.isOutOfRange)?.length > 4
                ? '100%'
                : itaatsuData?.filter((i) => !i.isOutOfRange)?.length * 210 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol tbl-header">
              <Row justify="center" className="tbl-header">
                <Space>
                  曲げ種類 /
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={mageSuuModal} style={{ pointerEvents: 'auto' }}>
                        板厚
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>板厚</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {itaatsuData
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                  ～{item.value}t
                </td>
              ))}
          </tr>

          {Object.entries(itaaatsuList)
            ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
            ?.map(([key, groupItem]) => (
              <tr key={key} style={{ textAlign: 'center' }}>
                <td style={{ width: '196px' }} className="fixedCol tbl-header">
                  {getProcessDetailTypeName(parseInt(key), processMstRecord)}
                </td>
                {groupItem
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((col, index) => (
                    <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                      <Row align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{secondsToHms(col.value)}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={secondsToHms(col.value)}
                                onChange={(e) => itaaatsuListDataChange(e.target.value, parseInt(key), col.no)}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 16 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), '10min');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), '10min');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'min');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'min');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'sec');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'sec');
                                  itaaatsuListDataChange(editedTime, parseInt(key), col.no);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  ))}
              </tr>
            ))}
        </Table>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addMagesuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Bending;
