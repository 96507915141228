import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { RightCircleFilled, LeftCircleFilled, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { Table as RTable } from 'react-bootstrap';
// Content resize
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import ParentListDetail from './ParentListDetail';
import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';
import new_icon from '../../assets/images/new_icon.png';
import child_delete_icon from '../../assets/images/child_delete_icon.png';
import call_detail from '../../assets/images/call_detail.png';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import copy from '../../assets/images/copy.png';
import jisen_register from '../../assets/images/jisen_register.png';
import detail_confirm from '../../assets/images/detail_confirm.png';
import display_setting from '../../assets/images/display_setting.png';
import detail_display from '../../assets/images/detail_display.png';
import edit_list from '../../assets/images/edit_list.png';
import search_list from '../../assets/images/search_list.png';
import export_report from '../../assets/images/export_report.png';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import analyze from '../../assets/images/analyze.png';
import edit_info from '../../assets/images/edit_info.png';
import search from '../../assets/images/search.png';
import Sorting from '../common/Sorting';
import KouteibetsuInfo from './KouteibetsuInfo';
import {
  ResizableTitle,
  getAccessToken,
  getTenjikaiSampleData,
  pageSizeOptions,
  toDecimal,
  updateAccessToken,
  JPYs,
  secondsToHms,
  formatDate,
  productionManageCSVFileHeaders,
  formatDateString,
  todayTime,
  kouteiMasterTypes,
  getMaterialTypeById,
  getMaterialNameById,
  getMaterialThickById,
  calEstDataSheet5,
  getNameByValue,
  estimateInitInfo,
  getInitParamUpdateFlgInfos,
  updateEstimateInfoByReCal,
  getEstimateStatusFontColor,
  getEstimateStatusRowColor,
  convertServerExcelToHtml,
} from '../common/Common';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  commonDeleteFooter,
  authorizeError,
  error,
  exportComplete,
  sameDrawingNoContent,
  commonActionFooter,
  complete,
} from '../common/CommonModal';
import {
  createEstimateProductInfo,
  getAllIQ3EstimateInfo,
  getEstimateIq3Info,
  getEstimateProductInfo,
  updateEstimateProductInfo,
  getEstimateInfoById,
  getIsExistSameEstimate,
  getUserInfo,
  bulkEditEstimateProductInfos,
  updateUserSetting,
  GetParametersByCompId,
  getEstimateCardDetailImg,
} from '../common/CommonAPI';
import SearchAndGetCustomerData from '../common/SearchAndGetCustomerData';
import { Customer, amtEmptyStr, saveSameTypes, timeHmsStr } from '../common/Constant';
import {
  ClientType,
  CustomerFilterType,
  DataType,
  DisplayColumnsGroup,
  PrintTypes,
  ServiceClass,
  WorkType,
  WorkTypeGroup,
} from '../common/enums';
import { ErrorMessage } from '../common/Message';
import {
  getExistCompanySetting,
  getExcelDatabyUseType,
  getS3FileContent,
  getPurchaseCategoryInfo,
  getProcessInfo,
} from '../common/CommonAPI';
import { CSVLink } from 'react-csv';
import sort_up from '../../assets/images/sort_up.png';
import sort_down from '../../assets/images/sort_down.png';
import curt from '../../assets/images/curt.png';

dayjs.extend(customParseFormat);
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;
// 一覧に表示中の行カウント数
let displayTotalCount = 0;
const pageSizes = ['100', '200', '300', '500', '1000'];
const ParentList = forwardRef((props, ref) => {
  const [hasData, setHasData] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();

  const [selectedData, setSelectedData] = useState([]);

  const [hasCheckData, setCheckData] = useState(true);

  const [childTitle, setChildTitle] = useState('子部品確認');

  const [isCopy, setIsCopy] = useState(false);

  // 表示設定
  const [isDisplay, setIsDisplay] = useState(false); // ポップアップ表示／未表示
  const [value, setValue] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [chkData, setChkData] = useState([]); // ポップアップでチェックしている表示する項目

  // csv export
  const [csvExportData, setCSVExportData] = useState(0);
  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState([]); // 初期表示に表示する項目
  const [olddata, setOldData] = useState(0);
  const [drawingNoWidth, setDrawingNoWidth] = useState(140);

  // まとめて編集
  const [isUpdate, setIsUpdate] = useState(false);
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditItem, setBulkEditItem] = useState([]);
  const [drawingNoEdt, setDrawingNoEdt] = useState('');
  const [nameEdt, setNameEdt] = useState('');
  const [estimateStatusEdt, setEstimateStatusEdt] = useState('');
  const [memosEdt, setMemosEdt] = useState([]);
  const [labelsEdt, setLabelsEdt] = useState([]);
  const [datesEdt, setDatesEdt] = useState([]);
  const [memosValEdt, setMemosValEdt] = useState([]);
  const [labelsValEdt, setLabelsValEdt] = useState([]);
  const [datesValEdt, setDatesValEdt] = useState([]);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);
  // パラメータ更新モデル
  const [isCurtParam, setIsCurtParam] = useState(false);
  // パラメータ更新フラグ情報
  const [paramUpdateFlgInfos, setParamUpdateFlgInfos] = useState();
  // 更新確認メッセージ
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isCurtParamBulkEdit, setIsCurtParamBulkEdit] = useState(false);

  // 子部品確認
  const [isChildOpen, setIsChildOpen] = useState(false);

  // 加工確認
  const [isManufactureOpen, setIsManufactureOpen] = useState(false);

  const [selectedEstimateData, setSelectedEstimateData] = useState([]);

  // 削除
  const [isDelete, setIsDelete] = useState(false);

  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);

  // リスト出力
  const [isListOutputOpen, setIsListOutputOpen] = useState(false);

  // リスト出力
  const [isCSVOutput, setIsCSVOutput] = useState(false);

  // 事前登録
  const [preRegister, setPreRegister] = useState(false);

  // 絞込み設定
  const [isFilter, setIsFilter] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [inputVal, setInputVal] = useState([]);
  // 絞り込み設定のON・OFFチェックボックス値
  const [filterCheckedData, setFilterCheckedData] = useState([]);
  // 絞り込み設定の入力値
  const [filterInputData, setFilterInputData] = useState([]);

  //  全てクリア
  // const [isAllClear, setIsAllClear] = useState(false);

  const [isUpdateData, setIsUpdateData] = useState(false);

  // 絞込み破棄
  const [isDiscardData, setIsDiscardData] = useState(false);

  // add row
  const [rows, initRow] = useState([]);

  // 絞込み・お客様のチェックボックス値
  const [customerRdo, setCustomerRdo] = useState(2);
  const [customerRdoBk, setCustomerRdoBk] = useState(2);

  // Content Resize
  const [sizes, setSizes] = useState(['50%']);
  const [btnShowHide, setBtnShowHide] = useState(true);

  const [sortingData, setSortingData] = useState([]);

  const [datesData, setDatesData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [memoData, setMemoData] = useState([]);
  const [costData, setCostData] = useState([]);

  // 検索アリア
  const [customer, setCustomer] = useState('');
  const [imageNum, setImageNum] = useState('');
  const [name, setName] = useState('');
  const [keyword, setKeywords] = useState('');

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);

  const [clickedAction, setClickedAction] = useState(0);

  // 子部品確認画面のselected key
  const [childSelectedKey, setChildSelectedKey] = useState(0);
  const [updateItem, setUpdateItem] = useState([]);
  const [page, setPage] = useState(1);

  // マスタの情報
  const [orderOptions, setOrderOptions] = useState([]); // 案件状態
  const [companyInfo, setCompanyInfo] = useState([]); // 会社情報
  const [clientInfo, setClientInfo] = useState([]); // お客様情報
  const [userInfo, setUserInfo] = useState({}); // ログインユーザー情報
  const [staffInfo, setStaffInfo] = useState([]); // 担当者情報
  const [processInfo, setProcessInfo] = useState([]); // 工程マスタ情報

  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);

  //複写編集項目
  const [customerCode, setCustomerCode] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [estimateStatus, setEstimateStatus] = useState('');
  const [customerModal, setCustomerModal] = useState(false);

  // 並び替え項目
  const [sortingColumns, setSortingColumns] = useState([]);

  // リスト帳票
  const [excelListFormats, setExcelListFormats] = useState([]);
  const [selectedExeclListFormat, setSelectedExeclListFormat] = useState();
  const [excelIndividualFormats, setExcelIndividualFormats] = useState([]);
  const [selectedExeclIndividualFormat, setSelectedExeclIndividualFormat] = useState();
  const [selectedExcelListTypeFileId, setSelectedExcelListTypeFileId] = useState();

  const [shouldExportCSV, setShouldExportCSV] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);
  const [isListReport, setIsListReport] = useState(false);
  const [isIndividualReport, setIsIndividualReport] = useState(false);
  const [purchaseCategoryInfos, setPurchaseCategoryInfos] = useState([]);

  // 同一図番で保存
  const [isSaveSameDrawingNo, setIsSaveSameDrawingNo] = useState(false);
  const [saveSameDrawingNoFncName, setSaveSameDrawingNoFncName] = useState('');

  const navigate = useNavigate();

  const sortingRef = useRef();

  const csvFile = useRef();
  const [estimateProductsData, setEstimateProductsData] = useState([]);
  const [exportColumns, setExportColumn] = useState([]);
  const [modifiedChkColumns, setModifiedChkColumns] = useState([]);

  const [fileName, setFileName] = useState(`親部品見積_${formatDateString(todayTime())}.csv`);
  const productionManageCSVFile = useRef();
  const [estimateProductsProductionManageData, setEstimateProductsProductionManageData] = useState([]);
  const [productionManageCSVFileName, setProductionManageCSVFileName] = useState(
    `親部品見積(生産管理連携)_${formatDateString(todayTime())}.csv`
  );

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const [selectedIq3Data, setSelectedIq3Data] = useState([]);

  const [exportCSVDataList, setExportCSVDataList] = useState([]);
  const [exportCSVList, setExportCSVList] = useState([]);
  const rowdata = {
    drawingno: '',
    name: '',
    customercode: '',
    customername: '',
    lotno: 1,
    order: '受注',
  };

  const loginCompanayInfo = props?.companyInfo;
  // 費用
  const paramCostLst = loginCompanayInfo?.[0]?.additionalItemCosts?.filter((item) => item.isUsed && !item.isDeleted);
  const costLstData = paramCostLst?.length > 0 ? paramCostLst : [];

  // ラベル
  const paramLabelLst = loginCompanayInfo?.[0]?.additionalItemLabels?.filter((item) => item.isUsed && !item.isDeleted);
  const labelLstData = paramLabelLst?.length > 0 ? paramLabelLst : [];

  // メモ
  const paramMemoLst = loginCompanayInfo?.[0]?.additionalItemMemos?.filter((item) => item.isUsed && !item.isDeleted);
  const memoLstData = paramMemoLst?.length > 0 ? paramMemoLst : [];

  // 日付
  const paramDateLst = loginCompanayInfo?.[0]?.additionalItemDates?.filter((item) => item.isUsed && !item.isDeleted);
  const dateLstData = paramDateLst?.length > 0 ? paramDateLst : [];

  // 費用
  const hiyouColumns = costLstData
    ?.map((paramCost, index) => {
      return {
        id: paramCost?.id,
        title: paramCost.name,
        dataIndex: 'epCustomCosts',
        key: 'ep_custom_cost' + index, // IQX_WEBEST-240
        width: 100 + 'px',
        className: 'cm-a-right',
        render: (record) => {
          const targetCostLst = record?.find((item) => item?.additionalItemId === paramCost?.id);
          return targetCostLst ? (
            <> {JPYs.format(targetCostLst.isUseUnit ? targetCostLst.unitPrice : targetCostLst.totalPrice)}</>
          ) : (
            <> {JPYs.format(0)}</>
          );
        },
        sorter: (a, b) => {
          setSortNm('ep_custom_cost' + index); // IQX_WEBEST-240
        },
        sortIcon: ({ sortOrder }) => (
          <>
            <Image
              preview={false}
              src={sort_up}
              style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
            />
            <Image
              preview={false}
              src={sort_down}
              style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
            />
          </>
        ),
      };
    })
    ?.flat();
  const temphiyouColumns = hiyouColumns;

  // ラベル
  const labelColumns = labelLstData
    ?.map((paramLabel, index) => {
      return {
        id: paramLabel?.id,
        title: paramLabel.name,
        dataIndex: 'epCustomLabels',
        key: 'ep_custom_label' + index, // IQX_WEBEST-240
        width: 150 + 'px',
        render: (record) => {
          const targetLabelLst = record?.find((item) => item?.additionalItemId === paramLabel?.id);
          return targetLabelLst ? <> {targetLabelLst.label}</> : <></>;
        },
        sorter: (a, b) => {
          setSortNm('ep_custom_label' + index); // IQX_WEBEST-240
        },
        sortIcon: ({ sortOrder }) => (
          <>
            <Image
              preview={false}
              src={sort_up}
              style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
            />
            <Image
              preview={false}
              src={sort_down}
              style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
            />
          </>
        ),
      };
    })
    ?.flat();
  const templabelColumns = labelColumns;

  // メモ
  const memoColumns = memoLstData
    ?.map((paramMemo, index) => {
      return {
        id: paramMemo?.id,
        title: paramMemo.name,
        dataIndex: 'epCustomMemos',
        key: 'ep_custom_memo' + index, // IQX_WEBEST-240
        width: 100 + 'px',
        render: (record) => {
          const targetMemoLst = record?.find((item) => item?.additionalItemId === paramMemo?.id);
          return targetMemoLst ? <> {targetMemoLst.memo}</> : <></>;
        },
        sorter: (a, b) => {
          setSortNm('ep_custom_memo' + index); // IQX_WEBEST-240
        },
        sortIcon: ({ sortOrder }) => (
          <>
            <Image
              preview={false}
              src={sort_up}
              style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
            />
            <Image
              preview={false}
              src={sort_down}
              style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
            />
          </>
        ),
      };
    })
    ?.flat();
  const tempmemoColumns = memoColumns;

  // 日付
  const dateColumns = dateLstData
    ?.map((paramDate, index) => {
      return {
        id: paramDate?.id,
        title: paramDate.name,
        dataIndex: 'epCustomDates',
        key: 'ep_custom_date' + index, // IQX_WEBEST-240
        width: 100 + 'px',
        render: (record) => {
          const targetDateLst = record?.find((item) => item?.additionalItemId === paramDate?.id);
          return targetDateLst ? <> {formatDate(targetDateLst.date)}</> : <></>;
        },
        sorter: (a, b) => {
          setSortNm('ep_custom_date' + index); // IQX_WEBEST-240
        },
        sortIcon: ({ sortOrder }) => (
          <>
            <Image
              preview={false}
              src={sort_up}
              style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
            />
            <Image
              preview={false}
              src={sort_down}
              style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
            />
          </>
        ),
      };
    })
    ?.flat();
  const tempdateColumns = dateColumns;

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      //width: 140 + 'px',
      width: drawingNoWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isChildOpen) {
            setDrawingNoWidth(size.width);
          }
        },
      }),
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 150 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processSelect',
      width: 120 + 'px',
      render: (_, record) => record?.processSelect?.name,
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'partCount',
      width: 55 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partCount}</label>
        </div>
      ),
    },
    {
      id: '6',
      title: '材質',
      dataIndex: 'materialItems',
      width: 150 + 'px',
      render: (_, record) =>
        getMaterialTypeById(
          record?.materialTypeIq3Id,
          selectedData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3
        ),
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialItems',
      width: 150 + 'px',
      render: (_, record) =>
        getMaterialNameById(
          record?.materialIq3Id,
          selectedData?.estimateProducts?.calcParameters?.parameters?.materialIq3
        ),
    },
    {
      id: '8',
      title: '板厚',
      dataIndex: 'thickness',
      width: 60 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {getMaterialThickById(
              record?.thickness,
              selectedData?.estimateProducts?.calcParameters?.parameters?.materialIq3
            )}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: 'サイズX',
      dataIndex: 'partSizeX',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partSizeX}</label>
        </div>
      ),
    },
    {
      id: '10',
      title: 'サイズY',
      dataIndex: 'partSizeY',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partSizeY}</label>
        </div>
      ),
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.weight ? toDecimal(record?.weight, 2) : toDecimal(0, 2)}</label>
        </div>
      ),
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'totalSurfaceArea',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.totalSurfaceArea ? toDecimal(record?.totalSurfaceArea, 2) : toDecimal(0, 2)}
          </label>
        </div>
      ),
    },
    {
      id: '13',
      title: '材料原価（単価）',
      dataIndex: 'materialCostUnitPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.materialCostUnitPrice ? JPYs.format(Math.round(record?.materialCostUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '14',
      title: '材料原価（合計）',
      dataIndex: 'materialCostTotalPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.materialCostTotalPrice ? JPYs.format(Math.round(record?.materialCostTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '15',
      title: '製造原価（単価）',
      dataIndex: 'workCostUnitPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.workCostUnitPrice ? JPYs.format(Math.round(record?.workCostUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '16',
      title: '製造原価（合計）',
      dataIndex: 'workCostTotalPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.workCostTotalPrice ? JPYs.format(Math.round(record?.workCostTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '17',
      title: '購入品費（単価）',
      dataIndex: 'purchaseUnitPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.purchaseUnitPrice ? JPYs.format(Math.round(record?.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '18',
      title: '購入品費（合計）',
      dataIndex: 'purchaseTotalPrice',
      width: 150 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.purchaseTotalPrice ? JPYs.format(Math.round(record?.purchaseTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  const iq5Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: '鋼材',
      dataIndex: 'steel',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズ長さ',
      dataIndex: 'sizeLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const iq7Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: 'タイプ',
      dataIndex: 'type',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズX・直接',
      dataIndex: 'sizeXArea',
      width: 100 + 'px',
    },
    {
      id: '10',
      title: 'サイズY・長さ',
      dataIndex: 'sizeYLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: 'サイズZ',
      dataIndex: 'sizeZ',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '18',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const childType = [
    { id: 1, value: 'すべて', label: 'すべて' },
    { id: 2, value: '板金子部品', label: '板金子部品' },
    /*   { id: 3, value: '形鋼子部品', label: '形鋼子部品' }, */
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={iconWidth} src={export_report} style={{ marginLeft: '0px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: '個別帳票',
          key: 'parentReport',
        },
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
      ],
    },
  ];
  const listOutputMenuitems = [
    {
      label: (
        <Image
          preview={false}
          width={iconWidth}
          src={export_csv_icon}
          style={{ marginLeft: '0px', textDecoration: 'none' }}
        ></Image>
      ),
      key: 'listOutput',
      children: [
        {
          label: 'リストCSV出力',
          key: 'csvReport',
        },
        {
          label: 'リストCSV出力(生産管理連携)',
          key: 'csvAllReport',
        },
        /* {
          label: 'iQ見積アナライザ',
          key: 'iQReport',
        },
        {
          label: '明細出力',
          key: 'detailsReport',
        }, */
      ],
    },
  ];
  const infoConfirmOutputMenuitems = [
    {
      label: <Image preview={false} width={27} src={detail_display} style={{ marginLeft: '0px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: '子部品確認',
          key: 'childConfirm',
        },
        {
          label: '工程別合計',
          key: 'processConfirm',
        },
      ],
    },
  ];

  // IQX_WEBEST-240
  const [ascSortFlg, setAscSortFlg] = useState('DESC');
  const [sortNm, setSortNm] = useState('id');
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountBK, setTotalCountBK] = useState(0);
  const [filterData, setFilterData] = useState({
    customer: '',
    drawingNo: '',
    name: '',
    keyword: '',
  });
  const [filterSettingData, setFilterSettingData] = useState([]);
  const [editPageSize, setEditPageSize] = useState(100);
  const [editTotalCount, setEditTotalCount] = useState(0);
  const [editPage, setEditPage] = useState(1);
  const [editTblLoading, setEditTblLoading] = useState(false);
  const [epCustomInfoFlg, setEpCustomInfoFlg] = useState(false);

  // プロパティの更新処理
  const updatedDetails = async (updateData) => {
    if (updateData != null) {
      //更新処理を呼び出す
      let updatedData = await updateEstimateProductData(updateData);

      const data = [...initialTblItems];

      const index = data?.findIndex((item) => item?.estimateProducts?.id === updatedData?.estimateProducts?.id);
      const updatedItems = [...data];
      updatedItems[index] = updatedData;
      setInitialTableItems(updatedItems);
      displayTotalCount = updatedItems?.length; // リストの件数

      const updatedItems2 = [...initialTblItemsBk];
      updatedItems?.forEach((update) => {
        const index = updatedItems2?.findIndex((item) => item?.estimateProducts?.id === update?.estimateProducts?.id);
        updatedItems2[index] = update;
      });
      setInitialTableItemsBk(updatedItems2);
      setSelectedData(updatedData);
    }
  };

  // 親部品見積情報をDBに更新する
  async function updateEstimateProductData(updateData) {
    let ret = [];
    ret = await updateEstimateProductInfo(updateData, true, false);
    return ret;
  }

  const updateEditMode = (mode) => {
    setEditMode(mode);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(mode);
  };

  // 絞込設定・お客様項目のラジオボタン押下
  const customerFilterChange = (e) => {
    setCustomerRdo(e.target.value);
  };

  // 表示設定の表示件数を変更した場合、
  const changeDisplayCount = (e) => {
    setValue(e.target.value);
  };

  let checkedRow = [];
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  useEffect(() => {
    if (props.parentListClick || props?.isFromiQ3 || props?.isFromParent) getEstimateProductData();
  }, [props.parentListClick, props.isFromiQ3, props?.isFromParent]);

  // ログインしているユーザーの会社情報
  useEffect(() => {
    if (props.companyInfo === undefined) return;
    setCompanyInfo(props.companyInfo);
  }, [props.companyInfo]);

  // お客様情報
  useEffect(() => {
    if (props.clientInfo === undefined) return;
    setClientInfo(props.clientInfo);
  }, [props.clientInfo]);

  // ユーザー情報
  useEffect(() => {
    if (props.userInfo === undefined) return;
    setUserInfo(props.userInfo);
  }, [props.userInfo]);

  // 担当者情報
  useEffect(() => {
    if (props.staffInfo === undefined) return;
    setStaffInfo(props.staffInfo);
  }, [props.staffInfo]);

  // 工程マスタ情報
  useEffect(() => {
    if (props.processInfo === undefined) return;
    setProcessInfo(props.processInfo);
  }, [props.processInfo]);

  // 工程マスタ情報
  useEffect(() => {
    if (props.purchaseCategoryInfo === undefined) return;
    setPurchaseCategoryInfos(props.purchaseCategoryInfo);
  }, [props.purchaseCategoryInfo]);

  useEffect(() => {
    if (props.userSettingInfo !== undefined && props.userSettingInfo?.length > 0) {
      setShowEdtConfirmOK(props?.userSettingInfo?.[0]?.massages?.editOk);
      setShowEdtConfirmCancle(props?.userSettingInfo?.[0]?.massages?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.userSettingInfo]);

  // 案件状態情報
  useEffect(() => {
    if (props.orderOptions === undefined || props.orderOptions?.length === 0) return;
    setOrderOptions(props.orderOptions);
  }, [props.orderOptions]);

  useEffect(() => {
    if (exportCSVDataList?.length > 0 && isCSVOutput) {
      setIsCSVOutput(false);
      productionManageCSVFile?.current?.link?.click();
    }
  }, [exportCSVDataList]);

  useEffect(() => {
    if (exportCSVList?.length > 0 && isCSVOutput) {
      setIsCSVOutput(false);
      csvFile?.current?.link?.click();
    }
  }, [exportCSVList]);

  // 親部品見積リストの初期値
  const getEstimateProductData = async () => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを表示にする
    // ユーザー設定情報取得
    let userInfo = [];
    // if (props?.userInfo?.displaySettings === undefined) {
    userInfo = await getUserInfo();
    // } else {
    //   userInfo = props?.userInfo;
    // }
    let initDisplayColumns = getDisplayColumns();
    // 絞り込み設定の情報
    let userFilterSetting = userInfo?.filterSettings;
    let userDisplaySetting = userInfo?.displaySettings;
    // let filterInitDisplayColumns = initDisplayColumns?.filter((item) => item.id !== 1); // ID、画像の項目以外
    let filterInitDisplayColumns = initDisplayColumns;
    if (
      userFilterSetting &&
      (userFilterSetting?.checkedInfos?.length > 0 || userFilterSetting?.inputInfos?.length > 0)
    ) {
      let userCheckedInfos = userFilterSetting?.checkedInfos;
      let userInputInfos = userFilterSetting?.inputInfos;
      // 保存したデータがある場合、
      for (let i = 0; i < filterInitDisplayColumns?.length; i++) {
        let initCol = filterInitDisplayColumns[i];
        let isDefaultCol = initCol.displayColumnsGroup === DisplayColumnsGroup.Default ? true : false;

        let checkedItem = userCheckedInfos?.find((checked) =>
          isDefaultCol
            ? checked.id === initCol.id // デフォルト項目の場合、
            : checked.displayColumnsGroup === initCol.displayColumnsGroup && // 追加項目の場合、
              checked.additionalItemId === initCol.additionalItemId
        );

        let inputItem = userInputInfos?.find((checked) =>
          isDefaultCol
            ? checked.id === initCol.id // デフォルト項目の場合、
            : checked.displayColumnsGroup === initCol.displayColumnsGroup && // 追加項目の場合、
              checked.additionalItemId === initCol.additionalItemId
        );

        filterCheckedData?.push(
          checkedItem
            ? { ...checkedItem, id: initCol.id }
            : {
                id: initCol?.id,
                displayColumnsGroup: initCol?.displayColumnsGroup,
                additionalItemId: initCol?.additionalItemId,
                checked: false,
              }
        );
        filterInputData?.push(
          inputItem
            ? { ...inputItem, id: inputItem.id }
            : {
                id: initCol?.id,
                displayColumnsGroup: initCol?.displayColumnsGroup,
                additionalItemId: initCol?.additionalItemId,
                inputData: ['', '', ''],
              }
        );
      }
    } else {
      // 初期状態の場合、
      for (let i = 0; i < filterInitDisplayColumns?.length; i++) {
        filterCheckedData?.push({
          id: filterInitDisplayColumns[i]?.id,
          displayColumnsGroup: filterInitDisplayColumns[i]?.displayColumnsGroup,
          additionalItemId: filterInitDisplayColumns[i]?.additionalItemId
            ? filterInitDisplayColumns[i]?.additionalItemId
            : 0,
          checked: false,
        });
        filterInputData?.push({
          id: filterInitDisplayColumns[i]?.id,
          displayColumnsGroup: filterInitDisplayColumns[i]?.displayColumnsGroup,
          additionalItemId: filterInitDisplayColumns[i]?.additionalItemId
            ? filterInitDisplayColumns[i]?.additionalItemId
            : 0,
          inputData: ['', '', ''],
        });
      }
    }
    setFilterCheckedData(filterCheckedData);
    setCheckedState(filterCheckedData);
    setFilterInputData(filterInputData);
    setInputVal(filterInputData);

    let customerUsedType = 0;
    // お客様項目のチェックボックス値
    if (userFilterSetting && userFilterSetting?.customerUsedType) {
      customerUsedType = userFilterSetting?.customerUsedType;
      setCustomerRdo(customerUsedType);
    }

    const mergedArray = filterCheckedData?.map((item, index) => {
      return { ...filterInputData[index], check: item.checked };
    });
    const filterDatas = mergedArray?.filter((i) => i.check == true);
    setFilterSettingData(filterDatas);
    if (filterDatas?.length >= 0) {
      filterDatas?.map((item, index) => {
        item.inputData[2] = item.inputData[2] !== '' ? item.inputData[2].toString() : '';
      });
    }
    let userCustomInfoFlg = userDisplaySetting?.epCustomInfoFlg ? userDisplaySetting?.epCustomInfoFlg : false;
    // DBデータ取得
    const estimateDatas = await getEstimateProductInfo(
      0,
      pageSize,
      ascSortFlg,
      sortNm,
      filterData,
      filterDatas,
      customerUsedType,
      userCustomInfoFlg
    ); // IQX_WEBEST-240
    const estimateData = estimateDatas ? estimateDatas?.data : [];
    const estimateDataTotalCount = estimateDatas ? estimateDatas?.totalCount : 0;
    setTotalCount(estimateDataTotalCount);
    setTotalCountBK(estimateDataTotalCount);
    const listData = estimateData;
    const listDataLatestRowKey = listData?.length == 0 ? 0 : listData?.[0]?.estimateProducts?.id;
    let selectedData = listData?.find((item) => item.estimateProducts?.id === listDataLatestRowKey);
    displayTotalCount = listData?.length; // リストの件数

    // let estimateProductId = selectedData?.estimateProducts?.id;
    // let iq3Infos = await getEstimateIq3Info(estimateProductId);
    setInitialTableItems(listData);
    setInitialTableItemsBk(listData);
    setSelectedData(selectedData);
    setSelectedEstimateData(selectedData);
    setSelectedRowKeys([listDataLatestRowKey]);
    // setSelectedIq3Data(iq3Infos);

    initRow([...rows, rowdata]);
    if (props.isFromiQ3 === undefined || props.isFromiQ3 === false) navigate(':parentList');
    // 板金、親部品の追加呼出の場合、
    if (props?.isFromiQ3 || props?.isFromParent) {
      props.getSelectedEstimateData(selectedData, null, false);
    }
    // navigate(':parentList');
    setCSVExportData([listData]);

    // 表示設定の表示項目情報
    let sortColumns = initDisplayColumns; // 初期状態の場合、
    let chkCols = prepareChkDataColumns(sortColumns);
    setModifiedChkColumns([defaultIDColumns?.title, ...chkCols]);
    let initDispCustomColumns = getDisplayCustomColumns();
    // 表示設定の並び替え項目
    if (userDisplaySetting && userDisplaySetting?.sortColumns?.length > 0) {
      // 保存した並び替え項目より初期項目を得る
      sortColumns = userDisplaySetting?.sortColumns;
      sortColumns = sortColumns
        ?.map((col) => {
          if (col.displayColumnsGroup === DisplayColumnsGroup.Default) {
            // デフォルト項目の場合、
            return col;
          } else {
            // 追加項目の場合、マスタの名称を扱う
            let initColInfo = initDispCustomColumns?.find(
              (initCol) =>
                initCol.displayColumnsGroup === col.displayColumnsGroup &&
                initCol.additionalItemId === col.additionalItemId
            );
            return { ...col, id: initColInfo?.id, title: initColInfo?.title };
          }
        })
        ?.filter((col) => col.id !== undefined); // マスタより、利用しない・削除済みのデータは対象外
      setSortingColumns(sortColumns);
      setEpCustomInfoFlg(userCustomInfoFlg);
    } else {
      // 初期状態の場合、
      setSortingColumns(initDisplayColumns);
      let exportCols = prepareExportCol(initDisplayColumns);
      setEpCustomInfoFlg(false);
      setExportColumn(exportCols);
    }

    let displayColumns = initDisplayColumns; // 初期状態の場合、
    // 表示設定の表示項目
    if (userDisplaySetting && userDisplaySetting?.displayColumns?.length > 0) {
      // 保存した表示項目より初期項目を得る
      displayColumns = userDisplaySetting?.displayColumns;
      displayColumns = displayColumns
        ?.map((col) => {
          // マスタの名称を扱う
          let initColInfo = initDispCustomColumns?.find((initCol) =>
            col.displayColumnsGroup === DisplayColumnsGroup.Default // デフォルト項目の場合、
              ? initCol.id === col.id
              : initCol.displayColumnsGroup === col.displayColumnsGroup && // 追加項目の場合
                initCol.additionalItemId === col.additionalItemId
          );
          return { ...initColInfo };
        })
        ?.filter((col) => col.id !== undefined); // マスタより、利用しない・削除済みのデータは対象外
      // 追加項目マスタより、新規追加したデータがあるかどうかのチェック、
      let newAdditionItemColumns = initDispCustomColumns?.filter(
        (initCol) =>
          // 保存した表示項目リストにない場合、新規項目と判断する
          displayColumns?.findIndex(
            (dispCol) =>
              dispCol.displayColumnsGroup === initCol.displayColumnsGroup &&
              dispCol.additionalItemId === initCol.additionalItemId
          ) === -1
      );
      // 追加項目マスタより、新規データがある場合、
      newAdditionItemColumns?.map((newCol) => {
        // 並び替え項目に、新規データがあるかどうかのチェック、
        let isExistSortCol =
          sortColumns?.findIndex(
            (sortCol) =>
              newCol.displayColumnsGroup === sortCol.displayColumnsGroup &&
              newCol.additionalItemId === sortCol.additionalItemId
          ) > -1;
        if (!isExistSortCol) {
          // 無い場合、追加する
          sortColumns = [newCol, ...sortColumns];
          displayColumns = [newCol, ...displayColumns];
        }
      });
      //デフォルト項目追加
      displayColumns = [...initDisplayColumns, ...displayColumns];
    }

    // 部品リストの項目順番を準備
    let prepareChkData = displayColumns?.filter(
      (disCol) => sortColumns?.findIndex((sCol) => sCol.id === disCol.id) > -1
    );
    prepareChkData.sort((a, b) => {
      // 並び替え項目の順番とおり、備える
      const aIndex = sortColumns?.findIndex((sort) => sort.id === a.id);
      const bIndex = sortColumns?.findIndex((sort) => sort.id === b.id);
      return aIndex - bIndex;
    });
    setChkData(prepareChkData);

    setSortingColumns(sortColumns);
    let exportCols = prepareExportCol(prepareChkData);
    setExportColumn(exportCols);

    // 表示件数
    if (userDisplaySetting?.displayNum) {
      setPageSize(userDisplaySetting?.displayNum);
      setValue(pageSizeOptions?.find((option) => option.label === userDisplaySetting?.displayNum)?.value);
    }

    // 絞り込み
    setDatesData(selectedData?.epCustomDates);
    setLabelsData(selectedData?.epCustomLabels);
    setMemoData(selectedData?.epCustomMemos);
    setCostData(selectedData?.epCustomCosts);

    // filterBySettingInfo(listData, filterCheckedData, filterInputData, customerUsedType);

    if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
    if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(false); // Waitingダイアログを非表示にする
    if (props?.isFromParent && props?.parentLoading) props?.parentLoading(false); // Waitingダイアログを非表示にする
  };

  const fetchListData = async () => {
    try {
      const companySetting = props.companySettingInfo;
      if (companySetting !== undefined && companySetting?.length > 0) {
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting[0]?.excelFormats);
        const excelLstformats = formats[PrintTypes.list];

        if (excelLstformats !== undefined && excelLstformats?.length > 0) {
          setExcelListFormats(excelLstformats);
          const selectedExelLstFormat = excelLstformats?.find(
            (format) => format.id === companySetting[0]?.printOutputs?.[PrintTypes.list]?.formatsId
          );
          if (selectedExelLstFormat !== undefined) {
            setSelectedExeclListFormat(selectedExelLstFormat);
          } else {
            setSelectedExeclListFormat(excelLstformats[0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIndividualData = async () => {
    try {
      const companySetting = props.companySettingInfo;
      if (companySetting !== undefined && companySetting?.length > 0) {
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting[0]?.excelFormats);
        const excelIndividualformats = formats[PrintTypes.individual];
        if (excelIndividualformats !== undefined && excelIndividualformats?.length > 0) {
          const selectedExelIndividualFormat = formats[0]?.find(
            (format) => format.id === companySetting[0]?.printOutputs?.[PrintTypes.individual]?.formatsId
          );
          setExcelIndividualFormats(formats[PrintTypes.individual]);

          if (selectedExelIndividualFormat !== undefined) {
            setSelectedExeclIndividualFormat(selectedExelIndividualFormat);
          } else {
            setSelectedExeclIndividualFormat(formats[0][0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultIDColumns = {
    id: 1,
    title: 'ID',
    dataIndex: 'estimateProducts',
    dbNameAttr: 'id',
    key: 'id',
    className: 'cm-a-right',
    width: 55,
    render: (id, record, index) => {
      let pageBefore = page - 1;
      let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
      return totalCount - startPage - index; // IQX_WEBEST-240
    },
  };
  const defaultIDEditColumns = {
    id: 1,
    title: 'ID',
    dataIndex: 'estimateProducts',
    dbNameAttr: 'id',
    key: 'id',
    className: 'cm-a-right',
    width: 55,
    render: (id, record, index) => {
      let pageBefore = editPage - 1;
      let startPage = pageBefore < 0 ? 0 : editPageSize * pageBefore;
      return editTotalCount - startPage - index; // IQX_WEBEST-240
    },
  };

  const defaultColumns = [
    {
      id: 2,
      title: 'お客様コード',
      dbNameAttr: 'code',
      dataIndex: 'estimateProducts',
      key: 'client_code',
      width: 95 + 'px',
      render: (record) => record?.clientCode,
      sorter: (a, b) => {
        setSortNm('client_code');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 3,
      title: 'お客様',
      dbNameAttr: 'clientName',
      dataIndex: 'estimateProducts',
      key: 'client_name',
      width: 250 + 'px',
      render: (record) => record?.clientName,
      sorter: (a, b) => {
        setSortNm('client_name');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 4,
      title: '図番',
      dbNameAttr: 'drawingNo',
      dataIndex: 'estimateProducts',
      key: 'drawing_no',
      width: 200 + 'px',
      render: (record) => record?.drawingNo,
      sorter: (a, b) => {
        setSortNm('drawing_no');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 5,
      title: '名称',
      dbNameAttr: 'name',
      dataIndex: 'estimateProducts',
      key: 'name',
      width: 200 + 'px',
      render: (record) => record?.name,
      sorter: (a, b) => {
        setSortNm('name');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 6,
      title: '数量',
      dbNameAttr: 'quantity',
      dataIndex: 'estimateProducts',
      key: 'quantity',
      width: 55 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.quantity}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('quantity');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 7,
      title: '粗利益',
      dbNameAttr: 'grossTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'gross_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.grossTotalPrice != undefined ? JPYs.format(Math.round(record?.grossTotalPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('gross_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 8,
      title: '粗利率',
      dbNameAttr: 'grossRate',
      dataIndex: 'estimateProducts',
      key: 'gross_rate',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.grossRate != undefined
              ? record?.grossRate.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                }) + '%'
              : '0%'}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('gross_rate');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 9,
      title: '見積単価',
      dbNameAttr: 'estimateUnitPrice',
      dataIndex: 'estimateProducts',
      key: 'estimate_unit_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.estimateUnitPrice != undefined ? JPYs.format(Math.round(record?.estimateUnitPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('estimate_unit_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 10,
      title: '見積合計',
      dbNameAttr: 'estimateTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'estimate_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.estimateTotalPrice != undefined ? JPYs.format(Math.round(record?.estimateTotalPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('estimate_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 11,
      title: '材料原価',
      dbNameAttr: 'materialCostUnitPrice',
      dataIndex: 'estimateProducts',
      key: 'material_cost_unit_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.materialCostUnitPrice != undefined ? JPYs.format(Math.round(record?.materialCostUnitPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('material_cost_unit_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 12,
      title: '製造原価',
      dbNameAttr: 'manufacturingCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'manufacturing_cost_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.manufacturingCostTotalPrice != undefined
              ? JPYs.format(Math.round(record?.manufacturingCostTotalPrice))
              : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('manufacturing_cost_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 13,
      title: '段取原価',
      dbNameAttr: 'setupCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'setup_cost_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.setupCostTotalPrice != undefined ? JPYs.format(Math.round(record?.setupCostTotalPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('setup_cost_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 14,
      title: '加工原価',
      dbNameAttr: 'workCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'work_cost_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.workCostTotalPrice != undefined ? JPYs.format(Math.round(record?.workCostTotalPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('work_cost_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 15,
      title: '購入品費',
      dbNameAttr: 'purchaseTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'purchase_total_price',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.purchaseTotalPrice != undefined ? JPYs.format(Math.round(record?.purchaseTotalPrice)) : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('purchase_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 16,
      title: '管理費',
      dbNameAttr: 'managementFeeTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'management_fee_total_price',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.managementFeeTotalPrice != undefined
              ? JPYs.format(Math.round(record?.managementFeeTotalPrice))
              : JPYs.format(0)}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('management_fee_total_price');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 17,
      title: '見積番号',
      dbNameAttr: 'estimateNo',
      dataIndex: 'estimateProducts',
      key: 'estimate_no',
      width: 150 + 'px',
      render: (record) => record?.estimateNo,
      sorter: (a, b) => {
        setSortNm('estimate_no');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 18,
      title: '価格調整',
      dbNameAttr: 'otherProduct?.adjustment',
      dataIndex: 'estimateProducts',
      key: 'other_product?.adjustment',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.otherProduct?.adjustment != undefined
              ? JPYs.format(Math.round(record?.otherProduct?.adjustment))
              : 0}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('other_product?.adjustment');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 19,
      title: '作成者',
      dbNameAttr: 'creater',
      dataIndex: 'estimateProducts',
      key: 'creater',
      width: 100 + 'px',
      render: (record) => record?.creater,
      sorter: (a, b) => {
        setSortNm('creater');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 20,
      title: '最終更新者',
      dbNameAttr: 'modifier',
      dataIndex: 'estimateProducts',
      key: 'modifier',
      width: 100 + 'px',
      render: (record) => record?.modifier,
      sorter: (a, b) => {
        setSortNm('modifier');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 21,
      title: '作成日',
      dbNameAttr: 'created',
      dataIndex: 'estimateProducts',
      key: 'created',
      width: 100 + 'px',
      render: (record) => formatDate(record?.created),
      sorter: (a, b) => {
        setSortNm('created');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 22,
      title: '更新日',
      dbNameAttr: 'modified',
      dataIndex: 'estimateProducts',
      key: 'modified',
      width: 100 + 'px',
      render: (record) => formatDate(record?.modified),
      sorter: (a, b) => {
        setSortNm('modified');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 23,
      title: '総表面積(c㎡)',
      dbNameAttr: 'totalSurfaceArea',
      dataIndex: 'estimateProducts',
      key: 'total_surface_area',
      width: 100 + 'px',
      render: (record) => toDecimal(record?.totalSurfaceArea, 2),
      sorter: (a, b) => {
        setSortNm('total_surface_area');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },

    {
      id: 24,
      title: '受注形態',
      dbNameAttr: 'coeffOrdersName',
      dataIndex: 'estimateProducts',
      key: 'coeff_orders_name',
      width: 150 + 'px',
      render: (record) => record?.coeffOrdersName,
      sorter: (a, b) => {
        setSortNm('coeff_orders_name');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 25,
      title: '納期形態',
      dbNameAttr: 'coeffDeliveryDatesName',
      dataIndex: 'estimateProducts',
      key: 'coeff_delivery_dates_name',
      width: 150 + 'px',
      render: (record) => record?.coeffDeliveryDatesName,
      sorter: (a, b) => {
        setSortNm('coeff_delivery_dates_name');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    // {
    //   id: 26,
    //   title: '画像',
    //   dbNameAttr: 'img',
    //   dataIndex: 'estimateProducts',
    //   render: (record) => record?.coeffDeliveryDatesName,
    //   key: 'img',
    //   width: 100 + 'px',
    //   sorter: (a, b) => a.coeffDeliveryDatesName?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
    //   sortIcon: ({ sortOrder }) => (
    //     <>
    //       <Image
    //         preview={false}
    //         src={sort_up}
    //         style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
    //       />
    //       <Image
    //         preview={false}
    //         src={sort_down}
    //         style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
    //       />
    //     </>
    //   ),
    // }, // 親部品にイメージがないため、コメントアウト　2023/12/07
    {
      id: 27,
      title: '受注非受注',
      dbNameAttr: 'estimateStatus',
      dataIndex: 'estimateProducts',
      key: 'estimate_status',
      render: (record) => record?.estimateStatus,
      // render: (record) => (
      //   <span
      //     style={{
      //       color: getEstimateStatusFontColor(
      //         record?.calcParameters?.parameters?.estimateStatus,
      //         record?.estimateStatusId
      //       ),
      //     }}
      //   >
      //     {record?.estimateStatus}
      //   </span>
      // ),
      width: 150 + 'px',
      sorter: (a, b) => {
        setSortNm('estimate_status');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 28,
      title: 'サイズX',
      dbNameAttr: 'sizeX',
      dataIndex: 'estimateProducts',
      key: 'size_x',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeX, 2)}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('size_x');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 29,
      title: 'サイズY',
      dbNameAttr: 'sizeY',
      dataIndex: 'estimateProducts',
      key: 'size_y',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeY, 2)}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('size_y');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 30,
      title: 'サイズZ',
      dbNameAttr: 'sizeZ',
      dataIndex: 'estimateProducts',
      key: 'size_z',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeZ, 2)}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('size_z');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 31,
      title: '子部品総重量',
      dbNameAttr: 'totalWeightParts',
      dataIndex: 'estimateProducts',
      key: 'total_weight_parts',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalWeightParts}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('total_weight_parts');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 32,
      title: '板金重量',
      dbNameAttr: 'totalWeightSm',
      dataIndex: 'estimateProducts',
      key: 'total_weight_sm',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalWeightSm}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('total_weight_sm');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 33,
      title: '子部品種類',
      dbNameAttr: 'partTypesParts',
      dataIndex: 'estimateProducts',
      key: 'part_types_parts',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partTypesParts}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('part_types_parts');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 34,
      title: '板金種類',
      dbNameAttr: 'partTypesSm',
      dataIndex: 'estimateProducts',
      key: 'part_types_sm',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partTypesSm}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('part_types_sm');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 35,
      title: '子部品製作数',
      dbNameAttr: 'totalQuantityParts',
      dataIndex: 'estimateProducts',
      key: 'total_quantity_parts',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalQuantityParts}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('total_quantity_parts');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 36,
      title: '板金製作数',
      dbNameAttr: 'totalQuantitySm',
      dataIndex: 'estimateProducts',
      key: 'total_quantity_sm',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalQuantitySm}</label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('total_quantity_sm');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 37,
      title: '表面処理',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions[0]',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaSurface)}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('additions[0]');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 38,
      title: '組立',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions[1]',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaAssenble)}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('additions[1]');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 39,
      title: '検査',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions[2]',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaInspection)}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('additions[2]');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      id: 40,
      title: '梱包',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions[3]',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaPacking)}
          </label>
        </div>
      ),
      sorter: (a, b) => {
        setSortNm('additions[3]');
      },
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  // 表面処理の合計金額を取得する
  const getProcessTotalPrice = (additionsInfo, mstInfo, workType) => {
    let processId = getProcessRecordId(ServiceClass.Parent, workType, mstInfo);
    if (processId === undefined) return 0;
    let processInfo = additionsInfo?.find((addition) => addition.processId === processId);
    let price = processInfo?.dataType === DataType.Data ? processInfo?.totalDataPrice : processInfo?.totalEditPrice;
    return price ? JPYs.format(Math.round(price)) : JPYs.format(0);
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecordId = (classId, processTypeId, mstInfo) => {
    if (mstInfo === undefined) return;
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter((item) => item?.class === classId && item?.workType === processTypeId);
    return mstRecordInfo?.length > 0 ? mstRecordInfo[0]?.id : 0;
  };

  const getKouteiListData = (lists) => {
    const filteredKouteiResult = lists?.flatMap((item) => {
      const { processId, deviceId, isUsedForCalc, multiprocesses } = item;

      // Filter out multiprocesses where both processDetailGroup and processDetailTypeId are equal to 0
      const filteredMultiprocesses = multiprocesses?.filter(
        (process) => process.processDetailGroup !== 0 || process.processDetailTypeId !== 0
      );

      // Create an array of rows based on the filtered multiprocesses
      const rows = filteredMultiprocesses?.map((process) => ({
        processId,
        deviceId,
        isUsedForCalc,
        ...process, // Spread the process details
      }));

      // Include the item itself as a row if there are no filtered multiprocesses
      if (filteredMultiprocesses?.length === 0) {
        rows?.push({
          processId,
          deviceId,
          isUsedForCalc,
        });
      }

      return rows;
    });
    return filteredKouteiResult;
  };
  const updateKouteiCalData = (lists) => {
    const newArray = [];

    // lists?.forEach((item) => {
    //   const newItem = {
    //     no: item?.no,
    //     processId: item?.processId,
    //     dataType: item?.dataType,
    //     totalDandori: item?.totalDandori,
    //     totalDataPrice: item?.totalDataPrice,
    //     totalDataTime: item?.totalDataTime,
    //     totalEditPrice: item?.totalEditPrice,
    //     totalEditTime: item?.totalEditTime,
    //     totalSagyo: item?.totalSagyo,
    //     dataItems: item?.dataItems?.[0],

    //     editItems: item?.editItems?.[0],
    //   };

    //   newArray?.push(newItem);
    // });
    lists?.forEach((item) => {
      if (item?.dataItems?.length > 0) {
        item.dataItems?.forEach((data1Item, index) => {
          const newItem = {
            no: item.no,
            processId: item.processId,
            dataType: item.dataType,
            totalDandori: item.totalDandori,
            totalDataPrice: item.totalDataPrice,
            totalDataTime: item.totalDataTime,
            totalEditPrice: item.totalEditPrice,
            totalEditTime: item.totalEditTime,
            totalSagyo: item.totalSagyo,
            dataItems: item?.dataItems?.[index],

            editItems: item?.editItems?.[index],
          };

          newArray?.push(newItem);
        });
      } else {
        const newItem = {
          no: item?.no,
          processId: item.processId,
          dataType: item.dataType,
          totalDandori: item.totalDandori,
          totalDataPrice: item.totalDataPrice,
          totalDataTime: item.totalDataTime,
          totalEditPrice: item.totalEditPrice,
          totalEditTime: item.totalEditTime,
          totalSagyo: item.totalSagyo,
          dataItems: item.dataItems,

          editItems: item.editItems,
        };
        newArray?.push(newItem);
      }
    });
    return newArray;
  };

  const getProcessName = (processId) => {
    let processName = props.processInfo?.filter((i) => i.id === processId)[0]?.name;
    return processName;
  };
  const getProcessCode = (processId) => {
    let processCode = props.processInfo?.filter((i) => i.id === processId)[0]?.code;
    return processCode;
  };

  const getProcessDetailGroupCode = (processId, group) => {
    let process = props.processInfo?.filter((i) => i.id === processId)[0];
    if (process != undefined) {
      let groupCode = process.details.processDetailTypes?.find((type) => type.id === group);
      if (groupCode) {
        return groupCode.code;
      } else {
        return '';
      }
    }
  };
  const getProcessDetailGroupName = (processId, group) => {
    let process = props.processInfo?.filter((i) => i.id === processId)[0];
    if (process != undefined) {
      let groupCode = process.details.processDetailTypes?.find((type) => type.id === group);
      if (groupCode) {
        return groupCode.name;
      } else {
        return '';
      }
    }
  };

  let lastId = 0;

  // デフォルト項目のIDを生成
  const defaultColumnsList = defaultColumns?.map((item, index) => {
    return { ...item, id: index + 1, displayColumnsGroup: DisplayColumnsGroup.Default };
  });

  if (defaultColumnsList?.length > 0) lastId = defaultColumnsList?.findLast((item) => item)?.id;

  // デフォルト項目の最後IDより連続して、費用項目IDを生成
  const hiyouColumnsList = hiyouColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemCost,
      additionalItemId: item.id, // 費用マスタID
    };
  });

  if (hiyouColumnsList?.length > 0) lastId = hiyouColumnsList?.findLast((item) => item)?.id;

  // 費用項目の最後IDより連続して、ラベル項目IDを生成
  const labelColumnsList = labelColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemLabel,
      additionalItemId: item.id, // ラベルマスタID
    };
  });

  if (labelColumnsList?.length > 0) lastId = labelColumnsList?.findLast((item) => item)?.id;

  // ラベル項目の最後IDより連続して、メモ項目IDを生成
  const memoColumnsList = memoColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemMemo,
      additionalItemId: item.id, // メモマスタID
    };
  });

  if (memoColumnsList?.length > 0) lastId = memoColumnsList?.findLast((item) => item)?.id;

  // メモ項目の最後IDより連続して、日付項目IDを生成
  const dateColumnsList = dateColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemDate,
      additionalItemId: item.id, // 日付マスタID
    };
  });

  const columns = [
    ...defaultColumnsList,
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  // 表示設定項目
  const displaySettingItems = [
    ...defaultColumnsList,
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  const [updTblColumns, setUpdTblColumns] = useState([
    ...defaultColumnsList,
    ...temphiyouColumns,
    ...templabelColumns,
    ...tempmemoColumns,
    ...tempdateColumns,
  ]);

  // 絞り込み項目
  const filterSettingItems = [
    ...defaultColumnsList,
    // ...defaultColumnsList?.filter((item) => item.id !== 1), // ID、画像の項目以外
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  let tempColumnsArray = [];

  // 親部品見積一覧に表示する項目の準備
  const getDisplayColumns = () => {
    // 親部品見積一覧に表示する項目
    let displayColumns = [];
    displayColumns = [...defaultColumnsList];
    return displayColumns;
  };

  // 親部品見積一覧に表示する追加項目の準備
  const getDisplayCustomColumns = () => {
    // 親部品見積一覧に表示する項目
    let displayCustomColumns = [];
    displayCustomColumns = [...hiyouColumnsList, ...labelColumnsList, ...memoColumnsList, ...dateColumnsList];
    return displayCustomColumns;
  };

  // DBに保存している表示設定情報とマスタの情報を比較して、チェックボックスのON・OFFを判断する
  let displayColumnsIdList = sortingColumns
    ?.map((s) => {
      // デフォルト項目の場合、IDはそのまま扱う
      if (s?.displayColumnsGroup === DisplayColumnsGroup.Default) {
        return s.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemCost) {
        // 追加項目・費用の場合、マスタより生成したIDを扱う
        return hiyouColumnsList?.find((hiyou) => hiyou.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemLabel) {
        // 追加項目・ラベルの場合、マスタより生成したIDを扱う
        return labelColumnsList?.find((label) => label.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemMemo) {
        // 追加項目・メモの場合、マスタより生成したIDを扱う
        return memoColumnsList?.find((memo) => memo.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemDate) {
        // 追加項目・日付の場合、マスタより生成したIDを扱う
        return dateColumnsList?.find((date) => date.additionalItemId === s.additionalItemId)?.id;
      }
    })
    ?.filter((i) => i !== undefined); // マスタに削除されている情報は対象外

  const updateSortingData = (data) => {
    setSortingData(data);
  };
  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };
  const handlePageSizeChangeEdit = (current, pagesize) => {
    setEditPageSize(pagesize);
  };
  const handleTableChange = async (pagination, filters, sorter) => {
    if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromParent && props?.parentLoading) props?.parentLoading(true); // Waitingダイアログを非表示にする
    // IQX_WEBEST-240
    let pageBefore = pagination?.current - 1;
    let startPage = pageBefore < 0 ? 0 : pagination?.pageSize * pageBefore;
    // let currentPageRange = totalCount - startPage;
    // let pageSizeRange = currentPageRange > pagination.pageSize ? pagination.pageSize : currentPageRange;
    let ascSortFlg = 'DESC';
    if (sorter?.order === undefined) {
      ascSortFlg = '';
    } else if (sorter?.order === 'ascend') {
      ascSortFlg = 'ASC';
    } else if (sorter?.order === 'descend') {
      ascSortFlg = 'DESC';
    }
    setAscSortFlg(ascSortFlg);
    const estimateDatas = await getEstimateProductInfo(
      startPage,
      pagination?.pageSize,
      ascSortFlg,
      sorter?.columnKey,
      filterData,
      filterSettingData,
      2,
      epCustomInfoFlg
    );
    if (estimateDatas) {
      const estimateData = estimateDatas ? estimateDatas?.data : [];
      setInitialTableItems(estimateData);
      setCurrentPage(pagination?.current);
      if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
      if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(false); // Waitingダイアログを非表示にする
      if (props?.isFromParent && props?.parentLoading) props?.parentLoading(false); // Waitingダイアログを非表示にする
    }
  };

  // 親部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setClickedAction(1);
    setCurrentRecordKey(record?.estimateProducts?.id);
    setCurrentRecord(record);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setSelectedRowKeys(record?.estimateProducts?.id);
      setCSVExportData(record);
      setSelectedData(record);
      if (
        (props.isFromiQ3 !== undefined && props.isFromiQ3) ||
        (props.isFromParent !== undefined && props.isFromParent)
      ) {
        props.getSelectedEstimateData(record, null, false);
      }
      if (props.isFromiQ3 === undefined || props.isFromiQ3 === false) navigate(':parentList');
      // navigate(':parentList');
    }
  };

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record) => {
    // checkedRow = [...checked];
    const data = [...bulkEditSelectedRow];
    const checkedRecord = [...record];
    const bulkEditRows = [...bulkEditSelectedRowId];
    const checkedRows = [...checked];
    if (checkedRecord?.length > 0) {
      setBulkEditSelectedRow([...data, ...checkedRecord]);
      const updateCheckedRows = [...bulkEditRows, ...checkedRows];
      checkedRow = updateCheckedRows;
      setBulkEditSelectedRowId(updateCheckedRows);
      setChkRowsCount(updateCheckedRows?.length);
    }
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const getCurrentEditMode = () => {
    return editMode;
  };
  useImperativeHandle(ref, () => ({
    getCurrentEditMode: getCurrentEditMode,
  }));

  const onFinish = (values) => {};

  const contentResizeBtn = () => {
    setSizes(['100%']);
    setBtnShowHide(false);
  };
  const contentResizeBackwardBtn = () => {
    setSizes(['60%']);
    setBtnShowHide(true);
  };

  // keywordで検索
  const changeKeywords = (e) => {
    setKeywords(e.target.vaue);
  };

  // キーワードで検索する
  const getDataByFilter = (value, list) => {
    let searchBarText = value;
    let parentLstData = list;
    const parentLstFilterData = parentLstData?.filter(
      (item) =>
        item.estimateProducts.id.toString().indexOf(searchBarText) >= 0 ||
        props?.clientInfo
          ?.find((client) => client.id === item.estimateProducts.clientId)
          ?.code.toLowerCase()
          ?.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.grossTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.quantity.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.grossRate.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateUnitPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.materialCostUnitPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.manufacturingCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.setupCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.workCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.name.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateStatus.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.drawingNo.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.clientName.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateNo.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.creater.toLowerCase().indexOf(searchBarText.toLowerCase()) >= 0 ||
        item.estimateProducts.modifier.toLowerCase().indexOf(searchBarText.toLowerCase()) >= 0 ||
        formatDate(item.estimateProducts.created).toString().indexOf(searchBarText) >= 0 ||
        formatDate(item.estimateProducts.modified).toString().indexOf(searchBarText) >= 0 ||
        item.epCustomMemos?.filter((memoItem) => memoItem?.memo.toLowerCase().indexOf(searchBarText.toLowerCase()) >= 0)
          ?.length > 0 ||
        item.epCustomLabels?.filter(
          (labelItem) => labelItem?.label.toLowerCase().indexOf(searchBarText.toLowerCase()) >= 0
        )?.length > 0 ||
        item.epCustomCosts?.filter((costItem) => costItem?.unitPrice.toString().indexOf(searchBarText) >= 0)?.length >
          0 ||
        item.epCustomDates?.filter((dateItem) => formatDate(dateItem?.date).toString().indexOf(searchBarText) >= 0)
          ?.length > 0
    );
    setCurrentPage(1);
    return parentLstFilterData;
  };

  // お客様で検索
  const changeCustomer = (e) => {
    setCustomer(e.target.vaue);
  };
  // 図番で検索
  const changeDrawingNo = (e) => {
    setImageNum(e.target.vaue);
  };
  // 名称で検索
  const changeName = (e) => {
    setName(e.target.vaue);
  };

  //クイック検索
  const getParentsByFilter = async (e) => {
    if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromParent && props?.parentLoading) props?.parentLoading(true); // Waitingダイアログを非表示にする
    let value = e.target.value;
    let customerTxt =
      e.target.name == 'searchCustomer' ? value : document.getElementsByName('searchCustomer')?.[0]?.defaultValue;
    let imgTxt =
      e.target.name == 'searchDrawingNo' ? value : document.getElementsByName('searchDrawingNo')?.[0]?.defaultValue;
    let nameTxt = e.target.name == 'searchName' ? value : document.getElementsByName('searchName')?.[0]?.defaultValue;
    let keywordTxt =
      e.target.name == 'searchKeyword' ? value : document.getElementsByName('searchKeyword')?.[0]?.defaultValue;
    let parentLstFilterData = [];
    let filterData = { customer: customerTxt, drawingNo: imgTxt, name: nameTxt, keyword: keywordTxt };
    setFilterData(filterData);
    let pageBefore = page - 1;
    let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
    let currentPageRange = totalCount - startPage;
    // let pageSizeRange = currentPageRange > pageSize ? pageSize : currentPageRange;
    parentLstFilterData = await getEstimateProductInfo(
      startPage,
      pageSize,
      ascSortFlg,
      sortNm,
      filterData,
      filterSettingData,
      2,
      epCustomInfoFlg
    );
    if (parentLstFilterData) {
      const estimateData = parentLstFilterData ? parentLstFilterData?.data : [];
      const estimateDataTotalCount = parentLstFilterData ? parentLstFilterData?.totalCount : 0;
      setTotalCount(estimateDataTotalCount);
      setInitialTableItems(estimateData);
      if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
      if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(false); // Waitingダイアログを非表示にする
      if (props?.isFromParent && props?.parentLoading) props?.parentLoading(false); // Waitingダイアログを非表示にする
    }
    setPage(1);
    displayTotalCount = parentLstFilterData?.length; // リストの件数
  };

  const clearAllFilter = (e) => {
    setCustomer('');
    setImageNum('');
    setName('');
    setKeywords('');
    setFilterData({
      customer: '',
      drawingNo: '',
      name: '',
      keyword: '',
    });
    setFilterSettingData([]);
    setInitialTableItems(initialTblItemsBk);
    setTotalCount(totalCountBK);
    displayTotalCount = initialTblItemsBk?.length; // リストの件数
  };

  // 複写ボタンの押下
  const displayCopyConfirm = () => {
    setIsCopy(true);
    // setCustomerCode(props?.clientInfo?.find((client) => client.id === selectedData?.estimateProducts?.clientId)?.code);
    setDrawingNo(selectedData.estimateProducts.drawingNo);
    setParentEstimateName(selectedData.estimateProducts.name);
    setEstimateStatus(selectedData.estimateProducts.estimateStatus);
    setChildTitle('複写');
  };

  const getCustomerName = () => {
    setCustomerModal(true);
  };

  const selectedCustomerData = (selectedCustomerData) => {
    setCustomerCode(selectedCustomerData.name);
    setCustomerModal(false);
  };

  // 複写情報入力ボックスのOKボタン押下
  const copyOk = async () => {
    props?.loading(true);
    setIsCopy(false);
    const data = [...initialTblItems];
    let estimateProductId = selectedData?.estimateProducts?.id;
    let copyData = await getEstimateInfoById(estimateProductId);
    if (copyData && copyData?.length > 0) {
      copyData = copyData[0];
      copyData.estimateProducts.id = 0;
      copyData.estimateProducts.drawingNo = drawingNo;
      copyData.estimateProducts.name = parentEstimateName;
      copyData.estimateProducts.estimateStatus = estimateStatus;
      copyData.epCustomCosts = copyData?.epCustomCosts?.map((item) => ({ ...item, id: 0 }));
      copyData.epCustomDates = copyData?.epCustomDates?.map((item) => ({ ...item, id: 0 }));
      copyData.epCustomLabels = copyData?.epCustomLabels?.map((item) => ({ ...item, id: 0 }));
      copyData.epCustomMemos = copyData?.epCustomMemos?.map((item) => ({ ...item, id: 0 }));
      copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));
      // 同一図番があるか存在チェックする
      let companySetting = props?.companySettingInfo?.[0];
      if (!isSaveSameDrawingNo && !companySetting?.environments?.isUseSameName) {
        let estProducts = copyData.estimateProducts;
        let saveSameType = companySetting?.environments?.saveSameType;
        let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
        if (isExist) {
          setIsSaveSameDrawingNo(true);
          setSaveSameDrawingNoFncName('copyOk()');
          return;
        }
      } else {
        setIsSaveSameDrawingNo(false);
      }
      let copiedData = await createEstimateProductData(copyData);
      if (copiedData) {
        // IQX_WEBEST-240
        let pageBefore = page - 1;
        let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
        let currentPageRange = totalCount - startPage;
        const parentLstFilterData = await getEstimateProductInfo(
          startPage,
          pageSize,
          ascSortFlg,
          sortNm,
          filterData,
          filterSettingData,
          2,
          epCustomInfoFlg
        );
        if (parentLstFilterData) {
          setSelectedRowKeys(copiedData.estimateProducts.id);
          setInitialTableItems(parentLstFilterData.data);
          setInitialTableItemsBk(parentLstFilterData.data);
          displayTotalCount = parentLstFilterData.totalCount;
          setTotalCount(parentLstFilterData.totalCount);
          props?.loading(false);
        }
      }
    }
  };

  // 親部品見積情報をDBに新規登録する
  async function createEstimateProductData(createData) {
    let ret = [];
    ret = await createEstimateProductInfo(createData);
    return ret;
  }

  // 複写情報入力ボックスのCancelボタン押下
  const copyCancel = () => {
    setIsCopy(false);
  };

  const setCurrentPage = (value) => {
    setPage(value);
  };

  // まとめて編集
  const updateAll = () => {
    setClickedAction(4);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setDatesEdt(tempdateColumns);
      setLabelsEdt(templabelColumns);
      setMemosEdt(tempmemoColumns);
      setBulkEditSelectedRow([]);
      setBulkEditSelectedRowId([]);
      setChkRowsCount(0);
      setBulkEditItem(initialTblItems);
      setEditTotalCount(totalCount);
      setEditPage(1);
      setDatesValEdt([]); // 入力されない場合DBに更新しないためリセット
      setLabelsValEdt([]); // 入力されない場合DBに更新しないためリセット
      setMemosValEdt([]); // 入力されない場合DBに更新しないためリセット
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    }
  };
  // 子部品確認
  const childOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsChildOpen(true);
      setChildTitle('子部品確認');
    }
  };
  // 加工確認.工程別合計
  const manufactureOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setSelectedEstimateData(selectedData);
      setUpdateItem(selectedData);
      setIsManufactureOpen(true);
      setChildTitle('工程別合計');
    }
  };

  // CSV出力（生産管理連携）データ準備
  const prepareProductionManageExportData = (listData, iq3Data) => {
    let result = [];

    listData?.forEach((item) => {
      const {
        id,
        clientId,
        estimateNo,
        created,
        clientName,
        drawingNo,
        quantity,
        name,
        companyId,
        coeffDeliveryDatesId,
        coeffDeliveryDatesName,
        creater,
        modifier,
        estimateTotalPrice,
        managementFeeTotalPrice,
        purchaseTotalPrice,
        processSelect,
        costTotalPrice,
        grossTotalPrice,
        additions,
        calcParameters,
        purchases,
      } = item.estimateProducts;

      let iq3EstimateData = iq3Data?.filter((i) => i.estimateProductId === id);
      let flag = iq3EstimateData?.length === 1 ? 1 : 0;

      const clientCode = props?.clientInfo?.find((client) => client.id === clientId)?.code;
      const branchNum = props?.clientInfo?.find((client) => client.id === clientId)?.info.branchNum;
      const company = props?.companyInfo?.find((item) => item.id === companyId);
      const targetObject = company?.coeffDeliveryDates?.find((item) => item.id === coeffDeliveryDatesId);
      let coeffDeliveryCode = '';
      if (targetObject) {
        coeffDeliveryCode = targetObject.code;
      }

      // 工程選択
      const kouteiData = processSelect?.details?.filter((i) => i.isUsedForCalc);
      const filteredKouteiResult = getKouteiListData(kouteiData);
      const kouteiCalData = updateKouteiCalData(additions);

      const { epCustomCosts, epCustomDates, epCustomLabels, epCustomMemos } = item;

      result?.push({
        clientId: clientCode,
        estimateNo,
        created: formatDate(created),
        clientName,
        drawingNo,
        quantity,
        name,
        deliveryCode: coeffDeliveryCode,
        deliveryName: coeffDeliveryDatesName,
        creater,
        modifier,
        estimateTotalPrice: `￥${estimateTotalPrice}`,
        managementFeeTotalPrice: `￥${managementFeeTotalPrice}`,
        purchaseTotalPrice: `￥${purchaseTotalPrice}`,
        customerBranchNo: branchNum,
        // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
        costTotalPrice: `￥${costTotalPrice}`,
        grossTotalPrice: `￥${grossTotalPrice}`,
        branchNo: 0,
        singleItemFlag: flag,
      });
      if (filteredKouteiResult?.length > 0) {
        filteredKouteiResult?.forEach((koutei, index) => {
          result?.push({
            clientId: clientCode,
            estimateNo,
            created: formatDate(created),
            clientName,
            drawingNo,
            quantity,
            name,
            deliveryCode: coeffDeliveryCode,
            deliveryName: coeffDeliveryDatesName,
            creater,
            modifier,
            estimateTotalPrice: '',
            grossTotalPrice: '',
            managementFeeTotalPrice: '',
            purchaseTotalPrice: '',
            customerBranchNo: branchNum,
            branchNo: 0,
            singleItemFlag: flag,
            // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
            route: index + 1,
            processCode: getProcessCode(koutei.processId),
            processName: getProcessName(koutei.processId),
            workTypeGroupCode: getProcessDetailGroupCode(koutei.processId, koutei.processDetailTypeId),
            workTypeGroupName: getProcessDetailGroupName(koutei.processId, koutei.processDetailTypeId),
            singlePrepTime:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? kouteiCalData?.[index]?.dataItems?.dandoriTime || 0
                  : kouteiCalData?.[index]?.editItems?.dandoriTime || 0
                : '',
            prepTime:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? kouteiCalData?.[index]?.dataItems?.dandoriTime * quantity || 0
                  : kouteiCalData?.[index]?.editItems?.dandoriTime * quantity || 0
                : '',
            singleWorkTime:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? kouteiCalData?.[index]?.dataItems?.sagyoTime || 0
                  : kouteiCalData?.[index]?.editItems?.sagyoTime || 0
                : '',
            workTime:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? kouteiCalData?.[index]?.dataItems?.sagyoTime * quantity || 0
                  : kouteiCalData?.[index]?.editItems?.sagyoTime * quantity || 0
                : '',
            totalWorkTime:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? kouteiCalData?.[index]?.dataItems?.sagyoTime * quantity +
                      kouteiCalData?.[index]?.dataItems?.dandoriTime * quantity || 0
                  : kouteiCalData?.[index]?.editItems?.sagyoTime * quantity +
                      kouteiCalData?.[index]?.editItems?.dandoriTime * quantity || 0
                : '',
            totalCost:
              kouteiCalData?.length > 0
                ? kouteiCalData?.[index]?.dataType === 0
                  ? `￥${
                      kouteiCalData?.[index]?.dataItems?.sagyoAmt + kouteiCalData?.[index]?.dataItems?.dandoriAmt || 0
                    }`
                  : `￥${
                      kouteiCalData?.[index]?.editItems?.sagyoAmt + kouteiCalData?.[index]?.editItems?.dandoriAmt || 0
                    }`
                : '',
          });
        });
      }
      if (
        epCustomCosts?.length === 0 &&
        epCustomDates?.length === 0 &&
        epCustomLabels?.length === 0 &&
        epCustomMemos?.length === 0
      ) {
        result?.push({
          clientId: clientCode,
          estimateNo,
          created: formatDate(created),
          clientName,
          drawingNo,
          quantity,
          name,
          deliveryCode: coeffDeliveryCode,
          deliveryName: coeffDeliveryDatesName,
          creater,
          modifier,
          estimateTotalPrice: '',
          managementFeeTotalPrice: '',
          purchaseTotalPrice: '',
          customerBranchNo: branchNum,
          branchNo: 0,
          singleItemFlag: flag,
          // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
          grossTotalPrice: '',
        });
      } else {
        if (epCustomCosts?.length > 0) {
          epCustomCosts?.forEach((costItem) => {
            result?.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: costItem.label,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              branchNo: 0,
              singleItemFlag: flag,
              // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: '費用',
              customCode: costItem.additionalItemCode,
              customName: costItem.additionalItemName,
              totalCostAmount: `￥${costItem.totalPrice}`,
            });
          });
        }
        if (epCustomDates?.length > 0) {
          epCustomDates?.forEach((dateItem) => {
            result?.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: dateItem.date,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              branchNo: 0,
              singleItemFlag: flag,
              // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: '日付',
              customCode: dateItem.additionalItemCode,
              customName: dateItem.additionalItemName,
              customDate: formatDate(dateItem.date),
            });
          });
        }
        if (epCustomLabels?.length > 0) {
          epCustomLabels?.forEach((labelItem) => {
            result?.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: labelItem.label,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              branchNo: 0,
              singleItemFlag: flag,
              // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: 'ラベル',
              customCode: labelItem.additionalItemCode,
              customName: labelItem.additionalItemName,
              customText: labelItem.label,
            });
          });
        }
        if (epCustomMemos?.length > 0) {
          epCustomMemos?.forEach((memoItem) => {
            result?.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              memo: memoItem.memo,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              branchNo: 0,
              singleItemFlag: flag,
              // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: 'メモ',
              customCode: memoItem.additionalItemCode,
              customName: memoItem.additionalItemName,
              customText: memoItem.memo,
            });
          });
        }
      }
      if (purchases?.length > 0) {
        purchases?.forEach((purchase) => {
          result?.push({
            clientId: clientCode,
            estimateNo,
            created: formatDate(created),
            clientName,
            drawingNo,
            quantity: purchase?.totalRequired,
            name,
            deliveryCode: coeffDeliveryCode,
            deliveryName: coeffDeliveryDatesName,
            creater,
            modifier,
            estimateTotalPrice: '',
            grossTotalPrice: '',
            managementFeeTotalPrice: '',
            purchaseTotalPrice: '',
            customerBranchNo: branchNum,
            format: purchase?.name,
            totalCostAmount: purchase?.totalPrice,
            branchNo: 0,
            singleItemFlag: flag,
          });
        });
      }

      // 板金
      let processesData = calcParameters?.parameters?.process;
      let programId = processesData?.filter((proData) => proData.workType === WorkType.SmProgram)?.[0]?.id;
      let shearingId = processesData?.filter((proData) => proData.workType === WorkType.SmShearing)?.[0]?.id;
      iq3EstimateData?.forEach((iq3Item) => {
        const {
          name,
          drawingNo,
          materialName,
          partSizeX,
          partSizeY,
          materialTypeName,
          materialTypeIq3Id,
          purchase,
          processSelect,
          materialDetailPatterns,
          materialDetailSizes,
          additions,
          partCount,
        } = iq3Item;
        let productCode = calcParameters?.parameters?.materialTypeIq3?.filter((x) => x.id === materialTypeIq3Id)?.[0]
          ?.code;
        let thick = calcParameters?.parameters?.materialIq3?.filter(
          (item) => item.materialTypeIq3Id === materialTypeIq3Id
        )?.[0]?.info?.thick;
        let materialSurface = calcParameters?.parameters?.materialSurfaceIq3?.filter(
          (i) => i.materialTypeIq3Id === materialTypeIq3Id
        )?.[0]?.name;

        // 板金工程選択
        const iq3kouteiData = processSelect?.details?.filter((i) => i.isUsedForCalc);
        const filteredIQ3KouteiResult = getKouteiListData(iq3kouteiData);
        const iq3kouteiCalData = updateKouteiCalData(additions);

        let branchNum = 1;
        if (iq3kouteiCalData?.length > 0) {
          iq3kouteiCalData?.forEach((koutei, index) => {
            result?.push({
              format: partSizeX + '*' + partSizeY,
              materialName: materialName,
              surfaceProtectionName: materialSurface,
              productName: materialTypeName,
              thickness: thick,
              productCode: productCode,

              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo: drawingNo,
              quantity,
              name: name,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              branchNo: branchNum,
              singleItemFlag: flag,
              // materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              route: index + 1,
              processCode: getProcessCode(koutei.processId),
              processName: getProcessName(koutei.processId),
              workTypeGroupCode: getProcessDetailGroupCode(
                koutei.processId,
                koutei?.dataItems?.processDetailTypeNo
                  ? koutei?.dataItems?.processDetailTypeNo
                  : koutei?.dataItems?.processTypeId
              ),
              workTypeGroupName: getProcessDetailGroupName(
                koutei.processId,
                koutei?.dataItems?.processDetailTypeNo
                  ? koutei?.dataItems?.processDetailTypeNo
                  : koutei?.dataItems?.processTypeId
              ),
              // singlePrepTime:
              //   iq3kouteiCalData?.length > 0
              //     ? iq3kouteiCalData?.[index]?.dataType === 0
              //       ? iq3kouteiCalData?.[index]?.dataItems?.dandoriTime || 0
              //       : iq3kouteiCalData?.[index]?.editItems?.dandoriTime || 0
              //     : '',
              singlePrepTime:
                koutei?.dataType === 0 ? koutei?.dataItems?.dandoriTime || 0 : koutei?.editItems?.dandoriTime || 0,
              prepTime:
                koutei?.dataType === 0
                  ? koutei?.dataItems?.dandoriTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0
                  : koutei?.editItems?.dandoriTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0,
              singleWorkTime:
                koutei?.dataType === 0 ? koutei?.dataItems?.sagyoTime || 0 : koutei?.editItems?.sagyoTime || 0,
              workTime:
                koutei?.dataType === 0
                  ? koutei?.dataItems?.sagyoTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0
                  : koutei?.editItems?.sagyoTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0,
              totalWorkTime:
                koutei?.dataType === 0
                  ? koutei?.dataItems?.sagyoTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) +
                      koutei?.dataItems?.dandoriTime *
                        quantity *
                        (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0
                  : koutei?.editItems?.sagyoTime *
                      quantity *
                      (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) +
                      koutei?.editItems?.dandoriTime *
                        quantity *
                        (koutei.processId === programId || koutei.processId === shearingId ? 1 : partCount) || 0,
              totalCost:
                koutei?.dataType === 0
                  ? `￥${koutei?.dataItems?.sagyoAmt + koutei?.dataItems?.dandoriAmt || 0}`
                  : `￥${koutei?.editItems?.sagyoAmt + koutei?.editItems?.dandoriAmt || 0}`,
            });
          });
        }
        // 板金部品の材料行
        let materialSize = materialDetailSizes?.filter((i) => i.isUsed);
        let materialSizeX = 0;
        let materialSizeY = 0;
        let materialCost = 0;
        if (materialSize?.length > 0) {
          let material = materialDetailPatterns?.filter(
            (i) => i.materialSizeIq3Id === materialSize?.[0]?.materialSizeIq3Id && i.patternType === 1
          )?.[0];
          materialSizeX = material?.sizeX;
          materialSizeY = material?.sizeY;
          materialCost = material?.totalPrice;
        }
        result?.push({
          clientId: clientCode,
          estimateNo,
          created: formatDate(created),
          clientName,
          drawingNo: drawingNo,
          quantity,
          name: name,
          format: materialSizeX + '*' + materialSizeY,
          materialName: materialName,
          surfaceProtectionName: materialSurface,
          productName: materialTypeName,
          thickness: thick,
          productCode: productCode,
          deliveryCode: coeffDeliveryCode,
          deliveryName: coeffDeliveryDatesName,
          creater,
          modifier,
          estimateTotalPrice: '',
          managementFeeTotalPrice: '',
          purchaseTotalPrice: '',
          customerBranchNo: branchNum,
          materialCostUnitPrice: `￥${materialCost}`,
          grossTotalPrice: '',
          branchNo: 2,
          singleItemFlag: flag,
        });

        if (purchases?.length > 0) {
          purchases?.forEach((purchase) => {
            result?.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo: drawingNo,
              quantity: purchase?.totalRequired,
              name: name,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              format: purchase?.name,
              totalCostAmount: purchase?.totalPrice,
              materialName: materialName,
              surfaceProtectionName: materialSurface,
              productName: materialTypeName,
              thickness: thick,
              productCode: productCode,
              branchNo: 3,
            });
          });
        }
      });
    });

    setExportCSVDataList(result);
    return result;
  };

  // リストCSV出力のヘッダ
  const prepareExportCol = (tblCols) => {
    const updatedCols = tblCols?.map((item) => ({
      label: item.title,
      key: item.title,
    }));
    return updatedCols;
  };
  // 出力するヘッダ準備
  const prepareChkDataColumns = (cols) => {
    const updatedCols = cols?.map((item) => item.title);
    return updatedCols;
  };
  // 帳票出力
  const reportOutputChangeEvent = async (e) => {
    if (e.key == 'listReport') {
      fetchListData(); // Call the async function
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票');
      setIsListReport(true);
    } else if (e.key == 'parentReport') {
      let estimateProductId = selectedData?.estimateProducts?.id;
      let iq3Infos = await getEstimateIq3Info(estimateProductId);
      setSelectedIq3Data(iq3Infos);
      fetchIndividualData(); // Call the async function
      setIsReportOutputOpen(true);
      setChildTitle('親部品帳票');
      setIsIndividualReport(true);
    } else if (e.key == 'csvReport') {
      // 一括編集画面からの場合(IQX_WEBEST-177)
      if (isUpdate === true) {
        extractDataByExcelColumns(bulkEditSelectedRow, sortingColumns);
      } else {
        extractDataByExcelColumns(initialTblItems, sortingColumns);
      }
      setFileName(`親部品見積_${formatDateString(todayTime())}.csv`);
      setIsCSVOutput(true);
    } else if (e.key === 'csvAllReport') {
      let allIQ3Info = await getAllIQ3EstimateInfo();
      // 一括編集画面からの場合(IQX_WEBEST-177)
      if (isUpdate === true) {
        prepareProductionManageExportData(bulkEditSelectedRow, allIQ3Info);
      } else {
        prepareProductionManageExportData(initialTblItemsBk, allIQ3Info);
      }
      setProductionManageCSVFileName(`親部品見積(生産管理連携)_${formatDateString(todayTime())}.csv`);
      setIsCSVOutput(true);
    }
  };

  // 子部品確認・加工確認(工程別合計)
  const infoConfirmOutputMenuChangeEvent = async (e) => {
    // setOpenCommonTaiochuPopup(true);
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      const estimateProductId = selectedData?.estimateProducts.id;
      let iq3Infos = await getEstimateIq3Info(estimateProductId);
      selectedData.esIq3Info = iq3Infos;
      if (e.key == 'childConfirm') {
        setUpdateItem(selectedData);
        setIsChildOpen(true);
        setChildTitle('子部品確認');
      } else if (e.key == 'processConfirm') {
        setSelectedEstimateData(selectedData);
        setUpdateItem(selectedData);
        setIsManufactureOpen(true);
        setChildTitle('工程別合計');
      }
    }
  };
  const listOutputOk = () => {
    setIsListOutputOpen(false);
  };
  const listOutputCancel = () => {
    setIsListOutputOpen(false);
  };
  // 新規作成
  const createParent = () => {
    props?.updateNewModeByParent(true);
  };
  const goToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
    // props.getSelectedEstimateData(getNewParentInfo(initialTblItems?.length), null, true);
    navigate(':estimate.parent');
  };
  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const reportOutputOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票出力');
    }
  };

  //dataIndexによって情報取得
  const extractColumnData = (record, column) => {
    const dbNameAttr = column.dbNameAttr;
    const dataIndex = column.dataIndex;
    const nestedDataIndex = dataIndex.split('.');
    let value = record;

    nestedDataIndex?.forEach((index) => {
      if (value) {
        value = value[index];
      }
    });

    if (value === undefined) {
      return 0;
    }

    if (column.render) {
      return column.render(value, record);
      /* return column.render(value); */
    }

    return value;
  };

  //フォーマットによって画面に表示しいてるdata取得
  function extractDataByExcelColumns(data, excelColumnHeaders, columnList) {
    const arrayOfObjects = [];

    let initDisplayColumns = getDisplayColumns();
    if (epCustomInfoFlg) {
      initDisplayColumns = [
        ...initDisplayColumns,
        ...hiyouColumnsList,
        ...labelColumnsList,
        ...memoColumnsList,
        ...dateColumnsList,
      ];
    }
    if (columnList) {
      initDisplayColumns = columnList;
    }
    data?.forEach((record, index) => {
      const rowData = {};
      rowData['No.'] = index + 1;
      excelColumnHeaders?.forEach((header) => {
        // defaultColumns?.forEach((column) => {
        const column = initDisplayColumns?.find((col) => col.title === header.title);
        if (column) {
          const columnNameT = column.id + column.title;
          const columnName = column.title;
          const columnData = extractColumnData(record, column);
          let colVal = '';
          if (typeof columnData === 'object') {
            if (columnData?.props?.children?.props !== undefined) {
              // デフォルト項目の場合
              if (isNaN(Number(columnData?.props?.children?.props?.children))) {
                colVal = columnData?.props?.children?.props?.children?.replace('¥', '');
                colVal = colVal?.replace(/,/g, '');
                colVal = colVal?.replace('%', '');
              } else {
                colVal = columnData?.props?.children?.props?.children;
              }
              rowData[columnName] = colVal;
            } else if (Array.isArray(columnData?.props?.children)) {
              // 追加項目の場合
              if (isNaN(Number(columnData?.props?.children?.[1])) && column.dataIndex.includes('epCustomCosts')) {
                colVal = columnData?.props?.children?.[1]?.replace('¥', '');
                colVal = colVal?.replace(/,/g, '');
              } else {
                colVal = columnData?.props?.children?.[1];
              }
              rowData[columnName] = colVal;
            } else if (columnData?.props?.children !== undefined) {
              rowData[columnName] = columnData?.props?.children;
            } else if (columnData?.props?.checked !== undefined) {
              rowData[columnName] = 'true' /* columnData?.props?.checked */;
            }
          } else {
            rowData[columnName] = columnData;
          }
        } else {
          rowData[header.title] = null; // Handle columns not found in defaultColumns
        }
      });
      arrayOfObjects?.push(rowData);
    });
    setExportCSVList(arrayOfObjects);
    return arrayOfObjects;
  }

  //HTML Table作成
  //param (テーブル column, テーブル内容)
  function createHtmlTable(header, data) {
    // Create the table element
    let table = document.createElement('table');

    if (header !== '') {
      // Create the table header row
      let headerRow = document.createElement('tr');
      for (let columnHeader of header) {
        let th = document.createElement('th');
        th.textContent = columnHeader == 'No2.' ? 'No.' : columnHeader;
        headerRow.appendChild(th);
      }
      table.appendChild(headerRow);

      // Create table rows for each data entry
      for (let rowData of data) {
        let row = document.createElement('tr');
        for (let columnHeader of header) {
          let td = document.createElement('td');
          td.textContent = rowData[columnHeader];
          row.appendChild(td);
        }
        table.appendChild(row);
      }
    } else {
      for (let rowData of data) {
        let row = document.createElement('tr');
        for (let key in rowData) {
          let td = document.createElement('td');
          if (Object.hasOwnProperty.call(rowData, key)) {
            td.textContent = rowData[key];
          }
          row.appendChild(td);
        }
        table.appendChild(row);
      }
    }

    // Serialize the table to an HTML string
    let htmlString = table.outerHTML;

    return htmlString;
  }

  const XLSX = require('xlsx');
  const ExcelJS = require('exceljs');
  async function convertServerExcelToHtmls(estimateData = [], iq3Data = []) {
    try {
      let excelData;
      if (isListReport) {
        excelData = selectedExeclListFormat;
      } else if (isIndividualReport) {
        excelData = selectedExeclIndividualFormat;
      }

      if (isIndividualReport) {
        convertServerExcelToHtml(excelData, estimateData, iq3Data);
      } else if (isListReport) {
        const s3Stream = await getS3FileContent(excelData?.id);
        const workbookIndividual = new ExcelJS.Workbook();
        await workbookIndividual.xlsx.load(s3Stream);
        workbookIndividual?.eachSheet(function (worksheet, sheetId) {
          if (worksheet?.name?.includes('ORG10') || worksheet?.name?.includes('ORG11')) {
            let tbHeaders = sortingColumns;
            if (worksheet?.name?.includes('ORG11')) {
              tbHeaders = defaultColumnsList;
            }
            let tblContents = [];
            if (isUpdate === true) {
              tblContents = extractDataByExcelColumns(bulkEditSelectedRow, tbHeaders);
            } else {
              tblContents = extractDataByExcelColumns(initialTblItems, tbHeaders);
            }
            // ORG10データ記入 // ORG11データ記入
            const row1 = worksheet.getRow(1);
            row1.getCell(1).value = 'No.';
            for (let i = 0; i < tbHeaders?.length; i++) {
              let data = tbHeaders[i].title;
              let colNumber = i + 2;
              row1.getCell(colNumber).value = data;
            }
            row1.commit();
            settingORG10_11(tblContents, worksheet, 2);
          }
        });

        let uint8Array = await workbookIndividual.xlsx.writeBuffer();
        let blob = new Blob([uint8Array], { type: 'application/octet-binary' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let excelName = selectedExeclListFormat.title ? selectedExeclListFormat.title + '.xlsx' : 'list.xlsx';
        link.download = excelName;
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error('Error converting Excel to HTML:', error);
    }
  }

  const settingORG10_11 = (datas, worksheet, curRowIndex) => {
    const row1 = worksheet.getRow(1);
    let curRow = [];
    //不要データクリア
    const lastRow = worksheet.lastRow;
    const rows = worksheet.getRows(curRowIndex, lastRow.number);
    for (let r = 0; r < rows?.length; r++) {
      rows[r].values = [];
      rows[r].commit();
    }
    for (let i = 0; i < datas?.length; i++) {
      let data = datas[i];
      curRow = worksheet.getRow(i + curRowIndex);
      row1.eachCell(function (cell, colNumber) {
        if (cell.value == 'No.') {
          curRow.getCell(colNumber).value = i + 1;
        } else {
          curRow.getCell(colNumber).value = data[cell.value];
        }
      });
      curRow.commit();
    }
  };

  //Excel File出力
  const handleExportToExcel = (contents, title) => {
    const wb = XLSX.utils.book_new();

    contents?.forEach(({ sheetName, html }) => {
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = html;

      const table = tempContainer.querySelector('table');

      if (table) {
        const ws = XLSX.utils.table_to_sheet(tempContainer);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
    });
    XLSX.writeFile(wb, title);
  };

  const reportOutputOk = async () => {
    if (isUpdate === true && isIndividualReport) {
      // 一括編集画面からの場合(IQX_WEBEST-177)
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let estimateProductId = bulkEditSelectedRow[i]?.estimateProducts?.id;
        let iq3Infos = await getEstimateIq3Info(estimateProductId);
        convertServerExcelToHtmls(bulkEditSelectedRow[i], iq3Infos);
      }
    } else {
      let parentData = await getEstimateInfoById(selectedData?.estimateProducts?.id);
      if (parentData?.length > 0) {
        convertServerExcelToHtmls(parentData[0], parentData[0]?.esIq3Info);
      }
    }
    setIsReportOutputOpen(false);
    !isListReport || setIsListReport(false);
    !isIndividualReport || setIsIndividualReport(false);
  };

  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
    !isListReport || setIsListReport(false);
    !isIndividualReport || setIsIndividualReport(false);
  };

  // 同一図面で保存する確認メッセージのOkボタン押下
  const saveSameDrawingNoOk = async () => {
    await eval(saveSameDrawingNoFncName);
  };

  // 同一図面で保存する確認メッセージのCancelボタン押下
  const saveSameDrawingNoCancel = () => {
    setIsSaveSameDrawingNo(false);
  };

  // 事前登録
  const preRegistertation = () => {
    setClickedAction(3);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setPreRegister(true);
      setChildTitle('事前登録');
    }
  };

  const preRegisterModalClose = () => {
    setPreRegister(false);
  };

  const updateOk = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };

  const updateCancel = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };
  // 削除
  const deleteRow = () => {
    if (csvExportData?.length < 1) {
      alert('削除するデータを選択してください');
    } else {
      let deleteEst =
        props?.userSettingInfo?.[0]?.massages?.deleteEst == undefined
          ? true
          : props?.userSettingInfo?.[0]?.massages?.deleteEst;
      setIsDelete(deleteEst);
      if (!deleteEst) {
        deleteOk();
      }
      setChildTitle('削除');
    }
  };

  // 削除確認メッセージのOKボタン押下
  const deleteOk = async () => {
    if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを非表示にする
    setIsDelete(false);
    let deletedData = await deleteEstimateProductData(selectedData?.estimateProducts?.id);
    if (deletedData) {
      // IQX_WEBEST-240
      let pageBefore = page - 1;
      let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
      let currentPageRange = totalCount - startPage;
      const parentLstFilterData = await getEstimateProductInfo(
        startPage,
        pageSize,
        ascSortFlg,
        sortNm,
        filterData,
        filterSettingData,
        2,
        epCustomInfoFlg
      );
      if (parentLstFilterData) {
        setSelectedData(parentLstFilterData.data?.length > 0 ? parentLstFilterData.data[0] : []);
        setSelectedRowKeys(
          parentLstFilterData.data?.length > 0 ? parentLstFilterData.data[0]?.estimateProducts?.id : 0
        );
        setInitialTableItems(parentLstFilterData.data);
        setInitialTableItemsBk(parentLstFilterData.data);
        displayTotalCount = parentLstFilterData.totalCount;
        setTotalCount(parentLstFilterData.totalCount);
        if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
      }
    }
  };

  // 親部品見積情報をDBに削除する
  async function deleteEstimateProductData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setIsDelete(false);
  };
  // end_削除

  // 絞込設定、事前登録、表示設定、まとめて編集の破壊確認メッセージのOKボタン押下
  const discardOk = () => {
    if (isFilter == true) {
      setIsFilter(false);
      setCheckedState(filterCheckedData);
      setInputVal(filterInputData);
      // setIsAllClear(isFilterAllClear(filterCheckedData));
      setCustomerRdo(customerRdoBk);
    }
    if (preRegister == true) {
      setPreRegister(false);
    }
    if (isUpdate == true) {
      setIsUpdate(false);
    }
    if (isDisplay == true) {
      setSortingColumns(chkData);
      setIsDisplay(false);
      setSortingData(chkData);
      sortingRef.current.setItems(chkData);
      sortingRef.current.setCurIndex(0);
    }
    setIsDiscardData(false);
  };

  // 絞込設定、事前登録、表示設定、更新処理の破壊確認メッセージのCancelボタン押下
  const discardCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsDiscardData(false);
  };

  // 各項目のチェックON・OFFで全てクリアチェックを判断する
  // const isFilterAllClear = (data) => {
  //   return data?.findIndex((item) => item.checked) === -1;
  // };

  // 絞込設定の情報より、親部品リスト画面の情報を絞込み
  const filterBySettingInfo = async (listData, checkedState, inputVal, customerUsedType) => {
    if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(true); // Waitingダイアログを非表示にする
    if (props?.isFromParent && props?.parentLoading) props?.parentLoading(true); // Waitingダイアログを非表示にする
    // merge
    const mergedArray = checkedState?.map((item, index) => {
      return { ...inputVal[index], check: item.checked, displayColumnsGroup: item.displayColumnsGroup };
    });
    const filterDatas = mergedArray?.filter((i) => i.check == true);
    setFilterSettingData(filterDatas);
    if (filterDatas?.length >= 0) {
      filterDatas?.map((item, index) => {
        item.inputData[2] = item.inputData[2] !== '' ? item.inputData[2].toString() : '';
      });
      // DBから絞り込んだリスト設定
      // IQX_WEBEST-240
      let pageBefore = page - 1;
      let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
      let currentPageRange = totalCount - startPage;
      const parentLstFilterData = await getEstimateProductInfo(
        startPage,
        pageSize,
        ascSortFlg,
        sortNm,
        filterData,
        filterDatas,
        customerUsedType,
        epCustomInfoFlg
      );
      if (parentLstFilterData) {
        setInitialTableItems(parentLstFilterData.data);
        let initDisplayColumns = getDisplayColumns();
        if (epCustomInfoFlg) {
          initDisplayColumns = [
            ...initDisplayColumns,
            ...hiyouColumnsList,
            ...labelColumnsList,
            ...memoColumnsList,
            ...dateColumnsList,
          ];
        }
        let sortColumns = initDisplayColumns; // 初期状態の場合、
        let chkCols = prepareChkDataColumns(sortColumns);
        displayTotalCount = parentLstFilterData.totalCount;
        setTotalCount(parentLstFilterData.totalCount);
        if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
        if (props?.isFromiQ3 && props?.iq3Loading) props?.iq3Loading(false); // Waitingダイアログを非表示にする
        if (props?.isFromParent && props?.parentLoading) props?.parentLoading(false); // Waitingダイアログを非表示にする
      }
    }
  };

  // 表示設定、絞込設定、事前登録画面の更新確認メッセージのOKボタン押下
  const updateDataOk = async () => {
    // 絞込設定画面の場合、
    if (isFilter == true) {
      let updateData = { ...userInfo };
      updateData = {
        ...updateData,
        displaySettings: { ...updateData.displaySettings },
        filterSettings: {
          customerUsedType: customerRdo,
          checkedInfos: checkedState,
          inputInfos: inputVal,
        },
      };
      let updatedData = await updateUserInfo(updateData);
      let checkedInfos = updatedData?.filterSettings?.checkedInfos;
      let inputInfos = updatedData?.filterSettings?.inputInfos;
      setFilterCheckedData(checkedInfos);
      setCheckedState(checkedInfos);
      setFilterInputData(inputInfos);
      setInputVal(inputInfos);
      // setIsAllClear(isFilterAllClear(checkedInfos));
      setCustomerRdo(updatedData?.filterSettings?.customerUsedType);
      filterBySettingInfo(initialTblItemsBk, checkedState, inputVal, updatedData?.filterSettings?.customerUsedType);
      setIsFilter(false);
    }
    // 事前登録画面の場合、
    if (preRegister == true) {
      setPreRegister(false);
    }
    // 表示設定画面の場合、
    if (isDisplay == true) {
      if (value === 2) {
        // setPageSize(10); //本番の場合、200に変更
      } else if (value === 3) {
        // setPageSize(15); //本番の場合、300に変更
      } else if (value === 4) {
        // setPageSize(16); //本番の場合、500に変更
      } else if (value === 5) {
        // setPageSize(17); //本番の場合、1000に変更
      } else {
        // setPageSize(25); //本番の場合、100に変更
      }
      if (props?.parentListClick && props?.loading) props?.loading(true); // Waitingダイアログを非表示にする
      let pageSize = pageSizeOptions?.find((item) => item.value === value)?.label;

      sortingData?.map((i) => {
        var ret = columns?.find((col) => col.id === i.id);
        if (ret) tempColumnsArray?.push(ret);
      });

      let displayColumns;
      if (tempColumnsArray?.length > 0) {
        setChkData([...tempColumnsArray]);
        let exportCols = prepareExportCol([...tempColumnsArray]);
        setExportColumn(exportCols);
        displayColumns = [...tempColumnsArray];
      } else {
        setChkData(sortingData);
        let exportCols = prepareExportCol(sortingData);
        setExportColumn(exportCols);
        displayColumns = [...sortingData];
      }
      tempColumnsArray = [];
      setIsDisplay(false);
      setCheckData(false);
      sortingRef.current.setCurIndex(0);

      let updateData = { ...userInfo };
      updateData = {
        ...updateData,
        displaySettings: {
          displayNum: pageSize,
          // displayColumns: displayColumns,
          displayColumns: columns,
          sortColumns: sortingData,
          epCustomInfoFlg: epCustomInfoFlg,
        },
        filterSettings: { ...updateData?.filterSettings },
      };
      let updatedData = await updateUserInfo(updateData);

      setPageSize(updatedData?.displaySettings?.displayNum);

      // setSortingColumns(sortingData);
      setSortingColumns(updatedData?.displaySettings?.sortColumns);

      // IQX_WEBEST-240
      let currentPageSize = updatedData?.displaySettings?.displayNum;
      let pageBefore = page - 1;
      let startPage = pageBefore < 0 ? 0 : currentPageSize * pageBefore;
      const parentLstFilterData = await getEstimateProductInfo(
        startPage,
        currentPageSize,
        ascSortFlg,
        sortNm,
        filterData,
        filterSettingData,
        2,
        epCustomInfoFlg
      );
      if (parentLstFilterData) {
        setInitialTableItems(parentLstFilterData.data);
        displayTotalCount = parentLstFilterData.totalCount;
        setTotalCount(parentLstFilterData.totalCount);
        if (props?.parentListClick && props?.loading) props?.loading(false); // Waitingダイアログを非表示にする
      }
    }
    // まとめて編集
    if (isUpdate == true) {
      props?.loading(true);
      setIsUpdate(false);
      if (bulkEditSelectedRowId && bulkEditSelectedRowId?.length > 0) {
        let estimateStatus = '';
        let estimateStatusId = estimateStatusEdt === '' ? 0 : estimateStatusEdt;
        if (orderOptions) {
          estimateStatus = orderOptions?.filter((item) => item.id === estimateStatusId && !item.isDeleted)?.[0]?.name;
        }
        let updateEstimateInfo = {
          ...estimateInitInfo,
          estimateProducts: {
            ...estimateInitInfo.estimateProducts,
            drawingNo: drawingNoEdt,
            name: nameEdt,
            estimateStatusId: estimateStatusId,
            estimateStatus: estimateStatus ? estimateStatus : '',
          },
          // epCustomDates: datesValEdt,
          // epCustomLabels: labelsValEdt,
          // epCustomMemos: memosValEdt,
        };
        if (
          updateEstimateInfo.estimateProducts.drawingNo !== '' ||
          updateEstimateInfo.estimateProducts.name !== '' ||
          updateEstimateInfo.estimateProducts.estimateStatusId > 0 ||
          // updateEstimateInfo.epCustomDates?.length > 0 ||
          // updateEstimateInfo.epCustomLabels?.length > 0 ||
          // updateEstimateInfo.epCustomMemos?.length > 0 ||
          isCurtParamBulkEdit
        ) {
          updateEstimateInfo.epCustomDates = updateEstimateInfo?.epCustomDates?.map((dateInfo) => {
            return { ...dateInfo, date: dateInfo.date === undefined ? '' : dateInfo.date };
          });
          let pageBefore = page - 1;
          let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
          let updatedDatas = await bulkEditEstimateProductInfos(
            startPage,
            pageSize,
            ascSortFlg,
            sortNm,
            filterData,
            filterSettingData,
            2,
            epCustomInfoFlg,
            bulkEditSelectedRow,
            updateEstimateInfo,
            isCurtParamBulkEdit
          );
          if (updatedDatas) {
            if (updatedDatas?.data?.length > 0) {
              const updateSelectedDatas = updatedDatas?.data;
              const data = [...initialTblItems];
              let updateSelectedData = [];
              data?.map((item) => {
                const updateData = updateSelectedDatas?.filter(
                  (update) => update.estimateProducts?.id === item?.estimateProducts?.id
                );
                if (updateData?.length > 0) {
                  item = updateData[0];
                }
                updateSelectedData.push(item);
              });
              if (updateSelectedData?.length > 0) {
                setInitialTableItems(updateSelectedData);
                setInitialTableItemsBk(updateSelectedData);
                setSelectedData(updateSelectedData[0]);
                setSelectedEstimateData(updateSelectedData[0]);
                setSelectedRowKeys(updateSelectedData[0]?.estimateProducts.id);
                setIsCurtParamBulkEdit(false);
              }
            }
          }
        }
      }
      props?.loading(false);
    }
    setIsUpdateData(false);
  };

  const updateDataCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsUpdateData(false);
  };

  // ユーザー情報をDBに更新する
  async function updateUserInfo(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'User';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          displaySettings: updateData.displaySettings,
          filterSettings: updateData?.filterSettings,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data;
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 表示設定_start
  const displaySetting = () => {
    setClickedAction(5);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = sortingColumns;
    }
  };

  const displayOk = () => {
    sortingData?.map((i) => {
      var ret = columns?.find((col) => col.id === i.id);
      if (ret) tempColumnsArray?.push(ret);
    });
    setCheckData(false);
    if (tempColumnsArray?.length > 0) {
      setChkData([...tempColumnsArray]);
    } else {
      setChkData(sortingData);
    }
    tempColumnsArray = [];
    setIsDisplay(false);
    setSortingColumns(sortingData);
    sortingRef.current.setCurIndex(0);
  };

  // 表示設定画面のCloseボタン押下
  const displayCancel = () => {
    setSortingColumns(chkData);
    setIsDisplay(false);
    setSortingData(chkData);
    sortingRef.current.setItems(chkData);
    sortingRef.current.setCurIndex(0);
  };

  // 表示設定画面の表示項目チェックボックスにON・OFF押下
  const handleChkChange = (e) => {
    let allSelectedData = [];
    let id = e.target.id;
    let name = e.target.name;
    let tempData = [...sortingData];
    let dispData = [];

    // チェックボックスONである、全てのItem
    dispData = displaySettingItems?.filter((record) => {
      if (document.getElementsByName('chkitem' + record?.id)?.[0]?.checked === true) {
        return record;
      }
    });

    // チェックボックスONの場合、
    if (document.getElementsByName(name) && document.getElementsByName(name)?.[0]?.checked) {
      //　ONにした項目の情報を取得する
      let newItem = dispData?.find((item) => item.id === id);
      // 並び替えリストに追加
      tempData = [...tempData, newItem];
      let index = tempData?.findIndex((item) => item.id === id);
      // 最初の項目として移す
      allSelectedData = arrayMoveImmutable(tempData, index, 0);
    } else {
      // チェックボックスOFFの場合、
      let index = tempData?.findIndex((item) => item.id === id);
      // 並び替えリストから消去する
      tempData?.splice(index, 1);
      allSelectedData = tempData;
    }
    setSortingColumns(allSelectedData);
  };

  // IQX_WEBEST-254
  const handleCustomChkChange = (e) => {
    let allSelectedData = [];
    let tempData = [...sortingData];
    let dispData = [];
    let customChk = e.target.checked;
    setEpCustomInfoFlg(customChk);
    // 追加項目チェックオンオフにより追加項目チェック情報を追加・解除するため、追加項目チェック情報を取得
    dispData = displaySettingItems?.filter((record) => {
      if (record?.displayColumnsGroup !== 0) {
        if (
          document.getElementsByName('chkitem' + record?.id) &&
          document.getElementsByName('chkitem' + record?.id)?.[0]?.checked
        )
          return record;
      }
    });
    for (let i = 0; i < dispData?.length; i++) {
      let id = dispData[i].id;
      // 追加項目チェックオンの場合、追加項目のチェック情報をソートリストに追加
      if (customChk) {
        //　ONにした項目の情報を取得する
        let newItem = dispData?.find((item) => item.id === id);
        // 並び替えリストに追加
        tempData = [...tempData, newItem];
        let index = tempData?.findIndex((item) => item.id === id);
        // 最初の項目として移す
        allSelectedData = arrayMoveImmutable(tempData, index, 0);
      } else {
        // チェックボックスOFFの場合、
        let index = tempData?.findIndex((item) => item.id === id);
        // 並び替えリストから消去する
        tempData?.splice(index, 1);
        allSelectedData = tempData;
      }
    }
    if (allSelectedData?.length > 0) setSortingColumns(allSelectedData);
  };

  // 表示設定_end
  // 絞込み設定
  const filterSetting = () => {
    setClickedAction(6);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setCustomerRdoBk(customerRdo);
      setIsFilter(true);
    }
  };

  const updateData = () => {
    // setIsFilter(false);
    setIsUpdateData(true);
  };

  const discardData = () => {
    setIsDiscardData(true);
    // setIsFilter(false);
  };

  // 絞込設定画面のCloseボタン押下
  const cancelFilter = () => {
    setIsFilter(false);
    setCheckedState(filterCheckedData);
    setInputVal(filterInputData);
    setCustomerRdo(customerRdoBk);
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      setSelectedRowKeys(currentRecordKey);
      setCSVExportData(currentRecord);
      setSelectedData(currentRecord);
      navigate(':parentList');
    } else if (clickedAction == 3) {
      // 事前登録
      setPreRegister(true);
      setChildTitle('事前登録');
    } else if (clickedAction == 4) {
      // 一括編集
      setBulkEditSelectedRow([]);
      setBulkEditSelectedRowId([]);
      setChkRowsCount(0);
      setBulkEditItem(initialTblItems);
      setEditTotalCount(totalCount);
      setEditPage(page);
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    } else if (clickedAction == 5) {
      // 表示設定
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = sortingColumns;
    } else if (clickedAction == 6) {
      // 絞込み設定
      setIsFilter(true);
    }
  };

  const discardConfirmCancel = () => {
    setEditMode(true);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(true);
    setEditModeCancelConfirm(false);
  };

  // 絞込設定の全てクリア
  const clearAll = (e) => {
    // setIsAllClear(e.target.checked);
    let data = [...checkedState];
    // data = data?.map((item) => ({ ...item, checked: !e.target.checked }));
    data = data?.map((item) => ({ ...item, checked: false }));
    setCheckedState(data);
    setInputVal(filterInputData);
    setCustomerRdo(customerRdoBk);
  };

  // 絞込設定のチェックボックスの押下
  const handleCheckboxChange = (index, checked) => {
    const newState = checkedState ? JSON.parse(JSON.stringify([...checkedState])) : [];
    newState[index].checked = checked;
    setCheckedState(newState);
    // setIsAllClear(false);
  };

  // 絞込設定のテキストボックスに入力した場合、
  const handleInputVal = (index, e, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = e.target.value;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 絞込設定の選択ボックスを変更した場合、
  const handleSelectVal = (index, e, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = e;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 絞込設定の日付項目に値を変更した場合、
  const handleDateVal = (index, dateString, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = dateString;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 画面切り替え
  const discardOkConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
    setEditMode(false);
    if (clickedAction == 6) {
      // 子部品確認
      props.getSelectedEstimateData(updateItem, childSelectedKey);
      navigate(':estimate.iq3');
    }
  };

  const discardCancelConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
  };

  // お客様コードでお客様名称を取得
  function getCustomerNameByCode(code, Lists) {
    let retValue = '';
    if (code) {
      let info = Lists?.filter((info) => info.code === code);
      if (info?.length > 0) {
        retValue = info[0]?.name;
      }
    }
    return retValue;
  }

  const handleCancel = () => {
    setIsUpdate(false);
  };

  // CSV
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a?.dispatchEvent(clickEvt);
    a?.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ['Id,Name,DrawingNo,EstimateNo,Person In Charge'];
    if (csvExportData?.length > 0) {
      let usersCsv = csvExportData?.reduce((acc, user) => {
        const { id, customer, drawingNo, estimateNo, manager } = user;
        acc?.push([id, customer, drawingNo, estimateNo, manager]?.join(','));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv]?.join('\n'),
        fileName: '親部品リスト.csv',
        fileType: 'text/csv',
      });
    } else {
      alert('出力するデータを選択してください');
    }
  };
  // CSV end

  const childTypeChange = (e) => {
    if (e == 1) {
      document.getElementById('iq3Row').style.display = 'block';
      // document.getElementById('iq5Row').style.display = 'block';
      // document.getElementById('iq7Row').style.display = 'block';
    } else if (e == 2) {
      document.getElementById('iq3Row').style.display = 'block';
      // document.getElementById('iq5Row').style.display = 'none';
      // document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 3) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'block';
      document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 4) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'none';
      document.getElementById('iq7Row').style.display = 'block';
    }
  };

  const addRowTable = () => {
    const data = {
      drawingno: '',
      name: '',
      customercode: '',
      customername: '',
      lotno: 1,
      order: '受注',
    };
    initRow([...rows, data]);
  };

  const tableRowRemove = (index) => {
    if (rows?.length > 1) {
      const dataRow = [...rows];
      dataRow?.splice(index, 1);
      initRow(dataRow);
    }
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  const parentDataDisplayEvent = async (record) => {
    let estimateData = record;
    if (estimateData) {
      props?.loading(true);
      const estimateProductId = estimateData?.estimateProducts?.id;
      if (estimateProductId && estimateProductId > 0) {
        // IQX_WEBEST-5
        let esInfo = await getEstimateInfoById(estimateProductId);
        if (esInfo) {
          estimateData = esInfo[0];
          // IQX_WEBEST-207
          for (let i = 0; i < estimateData?.esIq3Info?.length; i++) {
            if (estimateData?.esIq3Info[i].imageId && estimateData?.esIq3Info[i].imageId != '') {
              let iq3CadImg = await getEstimateCardDetailImg(estimateData?.esIq3Info[i].id);
              if (iq3CadImg !== undefined) {
                estimateData.esIq3Info[i]['imageUrl'] = URL.createObjectURL(iq3CadImg);
              } else {
                estimateData.esIq3Info[i]['imageUrl'] = '';
              }
            }
          }
        }
      }
      props.getSelectedEstimateData(estimateData, null, false);
      props?.loading(false);
    }
  };

  // CURT.パラボタンを押した場合(IQX_WEBEST-177)
  const currentParamInfo = () => {
    setIsCurtParam(true);
    let paramUpdateFlgInfos = props?.userSettingInfo?.[0]?.parametersUpdateFlgInfos;
    if (
      paramUpdateFlgInfos?.isMaterialParam === undefined &&
      paramUpdateFlgInfos?.isProcessParam === undefined &&
      paramUpdateFlgInfos?.isPurchaseParam === undefined &&
      paramUpdateFlgInfos?.isOtherParam === undefined
    ) {
      setParamUpdateFlgInfos(JSON.parse(JSON.stringify(getInitParamUpdateFlgInfos)));
    } else {
      setParamUpdateFlgInfos(paramUpdateFlgInfos);
    }
  };

  // CURT.パラメータ読込確認のOKボタン押下(IQX_WEBEST-177)
  const curtParamOk = () => {
    setUpdateConfirm(true);
  };

  // CURT.パラメータ読込確認のCancelボタン押下(IQX_WEBEST-177)
  const curtParamCancel = () => {
    setIsCurtParam(false);
  };

  // 更新確認メッセージのOKボタン押下(IQX_WEBEST-177)
  const confirmOk = async () => {
    if (isCurtParam) {
      props?.loading(true); // Waitingダイアログを表示にする
      setIsCurtParam(false);
      setUpdateConfirm(false);
      if (props?.userSettingInfo && props.userSettingInfo?.length > 0) {
        props.userSettingInfo[0]['parametersUpdateFlgInfos'] = paramUpdateFlgInfos;
        let result = await updateUserSetting(props?.userSettingInfo[0], 3);
        if (result) {
          let msgStr = 'CURT.パラに以下の項目が更新されました。\n更新されたパラメータ情報により、再計算されます。\n';
          if (paramUpdateFlgInfos?.isMaterialParam) msgStr += '・材料系\n';
          if (paramUpdateFlgInfos?.isProcessParam) msgStr += '・加工系\n';
          if (paramUpdateFlgInfos?.isPurchaseParam) msgStr += '・購入品\n';
          if (paramUpdateFlgInfos?.isOtherParam) msgStr += '・その他全て（係数。カスタマイズ・・・）\n';
          complete(msgStr);
        }
        let flgInfos = paramUpdateFlgInfos;
        const data = [...initialTblItems];
        //　CURT.パラを更新するため、最新パラ取得
        let newParameters = await GetParametersByCompId();
        if (newParameters) {
          let curtParamBulkEditItems = [];
          for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
            let bulkEdit = bulkEditSelectedRow[i];
            // 再計算のため、計算に必要な情報を取得
            let updateData = await getEstimateInfoById(bulkEdit.estimateProducts?.id);
            if (updateData?.length > 0) {
              // CURT.パラ更新
              let updateCurData = updateEstimateCurtParm(
                updateData[0],
                flgInfos?.isMaterialParam,
                flgInfos?.isProcessParam,
                flgInfos?.isPurchaseParam,
                flgInfos?.isOtherParam,
                newParameters
              );
              // CURT.パラ情報で再計算
              let calCurData = updateEstimateInfoByReCal(updateCurData);
              if (calCurData) {
                const index = data?.findIndex(
                  (item) => item?.estimateProducts?.id === calCurData?.estimateProducts?.id
                );
                if (index >= 0) {
                  data[index] = calCurData;
                  curtParamBulkEditItems.push(calCurData);
                }
              }
            }
          }
          if (data?.length > 0 && curtParamBulkEditItems?.length > 0) {
            setBulkEditItem(data);
            setBulkEditSelectedRow(curtParamBulkEditItems);
            setIsCurtParamBulkEdit(true);
          }
        }
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 更新確認メッセージのCancelボタン押下(IQX_WEBEST-177)
  const confirmCancel = () => {
    setUpdateConfirm(false);
  };

  const updateEstimateCurtParm = (
    currentEstimateData,
    isMaterialParam = true,
    isProcessParam = true,
    isPurchaseParam = true,
    isOtherParam = true,
    newParameters
  ) => {
    let updateEstimateData = currentEstimateData;
    if (newParameters) {
      if (updateEstimateData != undefined && updateEstimateData?.estimateProducts) {
        if (isOtherParam) {
          // その他全て（係数、カスタマイズ・・・）
          updateEstimateData.estimateProducts.calcParameters.parameters.company = newParameters.company;
          updateEstimateData.estimateProducts.calcParameters.parameters.staff = newParameters.staff;
          updateEstimateData.estimateProducts.calcParameters.parameters.client = newParameters.client;
          updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = newParameters.estimateStatus;
          updateEstimateData.estimateProducts.calcParameters.settings.userSettings = newParameters.userSettings;
          updateEstimateData.estimateProducts.calcParameters.settings.companySettings = newParameters.companySettings;
        }
        if (isProcessParam) {
          // 加工系（工程マスター、装置設定、工程パターン）
          updateEstimateData.estimateProducts.calcParameters.parameters.process = newParameters.process;
          updateEstimateData.estimateProducts.calcParameters.parameters.processPattern = newParameters.processPattern;
          updateEstimateData.estimateProducts.calcParameters.parameters.device = newParameters.device;
          let processSelect = newParameters.processPattern?.filter(
            (item) => item.id === updateEstimateData.estimateProducts.processSelect?.id
          );
          if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
        }
        if (isPurchaseParam) {
          // 購入品
          updateEstimateData.estimateProducts.calcParameters.parameters.purchase = newParameters.purchase;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
            newParameters.purchaseCategory;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
            newParameters.purchaseAllocation;
        }
        if (isMaterialParam) {
          // 材料系（材質区分、材質名称、材料名称・重量単価）
          // 板金パラメータ情報
          updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = newParameters.materialIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 = newParameters.materialSizeIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
            newParameters.materialSurfaceIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialType = newParameters.materialType;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 = newParameters.materialTypeIq3;
        }
      }
    }
    return updateEstimateData;
  };

  const menuIconList = (
    <>
      <div
        style={{
          borderBottom: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: '#212529',
          backgroundColor: '#fafafc',
          // height: '3.8vh',
        }}
      >
        <Row style={{ marginLeft: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
            <Row style={{ marginTop: '1px', marginLeft: '10px' }}>
              <Col span={24}>
                <Space size="middle" style={{ columnGap: 30 }}>
                  {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="親部品" placement="top" overlayClassName="tooltip-text">
                        <a onClick={createParent}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={new_icon}
                            style={{
                              marginLeft: '0px',
                            }}
                          ></Image>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}
                  <Tooltip title="子部品確認・工程別合計" placement="top" overlayClassName="tooltip-text">
                    <Menu
                      onClick={infoConfirmOutputMenuChangeEvent}
                      mode="horizontal"
                      items={infoConfirmOutputMenuitems}
                      style={{
                        marginLeft: '-15px',
                        height: 0,
                        color: 'white',
                        alignItems: 'flex-end',
                      }}
                    />
                  </Tooltip>
                  {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={updateAll}>
                          <Image preview={false} width={28} src={edit_list} style={{ marginLeft: '5px' }}></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="複写" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={displayCopyConfirm}>
                          <Image preview={false} width={iconWidth} src={copy} style={{ marginLeft: '0px' }}></Image>
                        </a>
                      </Tooltip>

                      <Tooltip title="削除" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={deleteRow}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={child_delete_icon}
                            style={{ marginLeft: '0px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="表示設定" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={displaySetting}>
                          <Image preview={false} width={33} src={display_setting} style={{ marginLeft: '0px' }}></Image>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}

                  <Tooltip title="絞込設定" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={filterSetting}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={search_list}
                        style={{ marginLeft: '0px', marginTop: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>

                  {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="帳票出力" placement="top" overlayClassName="tooltip-text">
                        <Menu
                          onClick={reportOutputChangeEvent}
                          mode="horizontal"
                          items={reportOutputMenuitems}
                          style={{
                            marginLeft: '-15px',
                            height: 0,
                            color: 'white',
                            alignItems: 'flex-end',
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="CSV出力" placement="topLeft" overlayClassName="tooltip-text">
                        <Menu
                          onClick={reportOutputChangeEvent}
                          mode="horizontal"
                          items={listOutputMenuitems}
                          style={{
                            marginLeft: '-15px',
                            height: 0,
                            color: 'white',
                            textDecoration: 'none',
                            alignItems: 'flex-end',
                          }}
                        ></Menu>
                        <CSVLink
                          ref={csvFile}
                          data={exportCSVList}
                          filename={fileName}
                          headers={[
                            {
                              label: 'No.',
                              key: 'No.',
                            },
                            ...exportColumns,
                          ]}
                          style={{ display: 'none' }}
                        >
                          Export
                        </CSVLink>

                        <CSVLink
                          ref={productionManageCSVFile}
                          data={exportCSVDataList}
                          filename={productionManageCSVFileName}
                          headers={productionManageCSVFileHeaders}
                          style={{ display: 'none' }}
                        >
                          Export
                        </CSVLink>
                      </Tooltip>

                      {/* <Tooltip title="事前登録" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={preRegistertation}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={jisen_register}
                            style={{ marginLeft: '15px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={updateAll}>
                          <Image
                            preview={false}
                            width={28}
                            src={edit_list}
                            style={{ marginLeft: '15px', marginTop: '6px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="承認登録" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={commonTaiochuPopupOpen}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={jisen_register}
                            style={{ marginLeft: '15px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="アナライズ" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={commonTaiochuPopupOpen}>
                          <Image preview={false} width={iconWidth} src={analyze} style={{ marginLeft: '15px' }}></Image>
                        </a>
                      </Tooltip> */}
                    </>
                  ) : (
                    ''
                  )}
                </Space>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '38%' }}>
            <Row style={{ marginLeft: '10px' }}>
              {editMode ? (
                <Col span={editMode ? 20 : 0}>
                  <Input
                    className="editInfoTle"
                    value={'編集中'}
                    type="text"
                    prefix={
                      <Image
                        preview={false}
                        width={13}
                        src={edit_info}
                        style={{
                          transform: 'rotate(270deg)',
                          marginRight: '5px',
                        }}
                      />
                    }
                  />
                </Col>
              ) : (
                ''
              )}
              <Col span={editMode ? 4 : 5} offset={editMode ? 0 : 10}>
                <Space size="middle">
                  {btnShowHide ? (
                    <RightCircleFilled onClick={contentResizeBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  ) : (
                    <LeftCircleFilled onClick={contentResizeBackwardBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </>
  );

  const addRows = rows?.map((rowsData, index) => {
    const { drawingno, name, customercode, customername, lotno, order } = rowsData;
    return (
      <tr key={index}>
        <td style={{ padding: '3px' }}>
          <Input
            onChange={(event) => onValUpdate(index, event)}
            value={drawingno}
            name="drawingno"
            className="input-editable"
          ></Input>
        </td>
        <td style={{ padding: '3px' }}>
          <Input style={{ padding: 3, height: 30 }} type="text" value={name} name="name" className="input-editable" />
        </td>
        <td style={{ padding: '3px' }}>
          <Select
            placeholder="選択"
            style={{ fontSize: '13.5px' }}
            id="customerCode"
            className="yousetsu-select"
            name="customercode"
          >
            {clientInfo?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30 }}
            type="text"
            onChange={(event) => onValUpdate(index, event)}
            value={customername}
            className="input-editable"
            name="customername"
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30, textAlign: 'center' }}
            type="text"
            value={lotno}
            name="lotno"
            className="input-editable"
            onChange={(event) => onValUpdate(index, event)}
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          {/* <Select
            placeholder="選択"
            style={{ width: '100%' }}
            options={orderOptions?.map((option) => ({ label: option.value, value: option.label }))}
          /> */}
          <Select
            // className="iq3-detail-select"
            // value={isOrder}
            name="estimateStatus"
            id="estimateStatus"
            // onChange={(e) => {
            //   setIsOrder(e);
            //   detailsData['allowGaichu'] = e;
            // }}
            style={{ padding: 3, width: '100%' }}
          >
            {orderOptions?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </td>
        <td
          style={{ border: 'none', width: '7%', backgroundColor: 'white', verticalAlign: 'middle' }}
          className="borderless"
        >
          <Row justify="center">
            <PlusCircleFilled
              className="add-remove-icon"
              style={{ marginLeft: 5, marginRight: 5 }}
              onClick={addRowTable}
            />
            <CloseCircleFilled className="add-remove-icon" onClick={tableRowRemove} />
          </Row>
        </td>
      </tr>
    );
  });

  const filterClearBtn = (
    <>
      <div style={{ display: 'flex' }}>
        <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
        <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
      </div>
    </>
  );

  const editModalTitle = (
    <div
      style={{
        width: isUpdate ? 1790 : 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const childModalTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const reportModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const displaySettingModalTitle = (
    <div
      style={{
        width: 1400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>表示設定</p>
    </div>
  );

  const jisenRegisterModalTitle = (
    <div
      style={{
        width: 1030,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>事前登録リスト表示</p>
    </div>
  );

  const filterModalTitle = (
    <div
      style={{
        width: 1225,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>絞込設定</p>
    </div>
  );

  const sameDrawingNoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  // 子部品確認画面
  const confirmChildInfoContent = (
    <>
      {' '}
      <Row style={{ height: '25px' }}>
        <Space size="large">
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>お客様：{updateItem?.estimateProducts?.clientName}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>図番：{updateItem?.estimateProducts?.drawingNo}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>名称：{updateItem?.estimateProducts?.name}</p>
        </Space>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={1}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>種類</p>
        </Col>
        <Col span={2}>
          <Select
            style={{ width: '100%', height: '30px', fontSize: '10px', marginLeft: -12 }}
            onChange={childTypeChange}
            name="childType"
            defaultValue={childType[0]?.value}
          >
            {childType?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row id="iq3Row" style={{ marginTop: 5 }}>
        <p style={{ fontSize: '13.5px', marginBottom: 1, marginTop: 0, fontWeight: 'bold' }}>板金部品</p>
        <Table
          showSorterTooltip={false}
          columns={iq3Columns}
          rowKey={(record) => record.key}
          // dataSource={tenjikaiSampleData.parentList[26].iq3DataLst}
          dataSource={selectedData?.esIq3Info}
          scroll={{ y: '10vh', x: '10vw' }}
          className="child-detailTable"
          pagination={false}
          components={components}
          onRow={(record, index) => {
            return {
              onDoubleClick: () => {
                if (isFromiQ3) return;
                if (record != null) {
                  setChildSelectedKey(record?.estimateProducts?.id);
                  setClickedAction(6);
                  if (editMode) {
                    setConfirmScreenChangeModalOpen(true);
                  } else {
                    setEditMode(false);
                    props.getSelectedEstimateData(updateItem, record?.estimateProducts?.id, false);
                    navigate(':estimate.iq3');
                  }
                }
              },
            };
          }}
        />
      </Row>
    </>
  );

  const copyParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        選択データを複写します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          {/* お客様 */}
          {/*  <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">お客様</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98%' }}
                className="input-editable"
                name="customerCode"
                id="customerCode"
                type="text"
                onChange={(e) => {
                  setCustomerCode(e.target.value);
                }}
                value={customerCode}
                suffix={
                  <a onClick={getCustomerName}>
                    <Image preview={false} width={20} height={20} src={search} style={{ marginBottom: '5px' }} />
                  </a>
                }
              />
            </td>
          </tr> */}
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                ref={ref}
                name="drawingNo"
                id="drawingNo"
                value={drawingNo}
                type="text"
                onChange={(e) => {
                  setDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">受注非受注</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Select
                className="iq3-detail-select"
                value={estimateStatus}
                name="estimateStatus"
                id="estimateStatus"
                onChange={(e) => {
                  setEstimateStatus(e);
                  // detailsData.estimateProducts['estimateStatus'] = e;
                }}
                style={{ marginLeft: 5, width: '40.8%' }}
              >
                {orderOptions !== undefined ? (
                  orderOptions
                    ?.sort((a, b) => Number(a?.info?.sortNum) - Number(b?.info?.sortNum))
                    .map((option, index) => (
                      <Select.Option key={index} value={option.name}>
                        {option.name}
                      </Select.Option>
                    ))
                ) : (
                  <Select.Option />
                )}
              </Select>
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // 親部品リストの削除ボタン押下
  const deleteParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px' }}>
        {/* 選択したデータ”図番”、”名称”を削除します。<br></br>削除したデータは戻せません。 */}
        選択した見積データを削除します。<br></br>削除したデータは戻せません。
      </p>
    </>
  );

  // 事前登録
  const jisenRegisterContent = (
    <>
      <div style={{ marginTop: -25 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="mt-4" style={{ marginRight: 90 }}>
            <label
              class="input-group-text"
              for="inputGroupFile"
              style={{ color: '#005fab', height: 28, fontSize: '13.5px' }}
            >
              ファイル取込
            </label>
            <Input
              // type="file"
              id="inputGroupFile"
              style={{ display: 'none' }}
              // accept=".xlsx,.csv"
              onClick={commonTaiochuPopupOpen}
            />
          </Row>
        </Col>
      </div>

      <div className="overflow-auto registerModal" style={{ height: 600, overflowY: 'scroll', marginTop: 3 }}>
        <RTable style={{ marginTop: 5, width: '99%' }} className="">
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '17%' }}>図番</th>
              <th style={{ width: '17%' }}>名称</th>
              <th style={{ width: '17%' }}>お客様コード</th>
              <th style={{ width: '17%' }}>お客様名称</th>
              <th style={{ width: '7%' }}>数量</th>
              <th style={{ width: '17%' }}>受注非受注</th>
            </tr>
          </thead>
          <tbody>{addRows}</tbody>
        </RTable>
      </div>
      <div style={{ marginTop: 5 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -5 }}>
          <Row className="mt-4" style={{ marginRight: 0 }}>
            <Button onClick={updateData} className="mainButton" id="update">
              更新
            </Button>

            <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
              破棄
            </Button>
          </Row>
        </Col>
      </div>
    </>
  );

  // 一括編集
  const editAllContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: '13.5px', marginTop: 6 }}>{chkrowscount}件を選択中</p>
            <Tooltip title="CURT.パラ" placement="top" overlayClassName="tooltip-text">
              <a onClick={currentParamInfo}>
                <Image preview={false} width={35} src={curt} style={{ marginLeft: '10px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="帳票出力" placement="top" overlayClassName="tooltip-text">
              <Menu
                onClick={reportOutputChangeEvent}
                mode="horizontal"
                items={reportOutputMenuitems}
                style={{
                  marginLeft: '10px',
                  height: 0,
                  color: 'white',
                  marginTop: '-7px',
                }}
              />
            </Tooltip>
            <Tooltip title="CSV出力" placement="topLeft" overlayClassName="tooltip-text">
              <Menu
                onClick={reportOutputChangeEvent}
                mode="horizontal"
                items={listOutputMenuitems}
                style={{
                  marginLeft: '10px',
                  height: 0,
                  color: 'white',
                  textDecoration: 'none',
                  marginTop: '-7px',
                }}
              ></Menu>
              <CSVLink
                ref={csvFile}
                data={exportCSVList}
                filename={fileName}
                headers={[
                  {
                    label: 'No.',
                    key: 'No.',
                  },
                  ...exportColumns,
                ]}
                style={{ display: 'none' }}
              >
                Export
              </CSVLink>

              <CSVLink
                ref={productionManageCSVFile}
                data={exportCSVDataList}
                filename={productionManageCSVFileName}
                headers={productionManageCSVFileHeaders}
                style={{ display: 'none' }}
              >
                Export
              </CSVLink>
            </Tooltip>
          </div>

          <Table
            showSorterTooltip={false}
            columns={[defaultIDEditColumns, ...chkData]}
            rowKey={(record) => record?.estimateProducts?.id}
            dataSource={bulkEditItem}
            scroll={{ y: 250, x: '10vw' }}
            // rowClassName={(record) =>
            //   record?.estimateProducts?.id == bulkEditSelectedRowId ? 'active-row' : 'data-row'
            // }
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: editTotalCount,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              pageSize: editPageSize,
              pageSizeOptions: pageSizes,
              showSizeChanger: true,
              onShowSizeChange: handlePageSizeChangeEdit,
              defaultCurrent: 1,
              current: editPage,
              onChange: async (page, pageSize) => {
                setEditTblLoading(true);
                // IQX_WEBEST-240
                let pageBefore = page - 1;
                let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
                const estimateDatas = await getEstimateProductInfo(
                  startPage,
                  pageSize,
                  ascSortFlg,
                  sortNm,
                  filterData,
                  filterSettingData,
                  2,
                  epCustomInfoFlg
                );
                if (estimateDatas) {
                  const estimateData = estimateDatas ? estimateDatas?.data : [];
                  setBulkEditItem(estimateData);
                  setEditTblLoading(false);
                  setEditPage(page);
                }
              },
            }}
            loading={editTblLoading}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable className="propertiesTbl">
              <thead>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">図番</label>
                  </td>
                  <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-editable '}
                      ref={ref}
                      name="drawingNoEdt"
                      id="drawingNoEdt"
                      value={drawingNoEdt}
                      type="text"
                      onChange={(e) => {
                        setDrawingNoEdt(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">名称</label>
                  </td>
                  <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-editable '}
                      name="nameEdt"
                      id="nameEdt"
                      value={nameEdt}
                      type="text"
                      onChange={(e) => {
                        setNameEdt(e.target.value);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">受注非受注</label>
                  </td>
                  <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                    <Select
                      className="iq3-detail-select"
                      name="estimateStatusEdt"
                      id="estimateStatusEdt"
                      onChange={(e) => {
                        setEstimateStatusEdt(e);
                      }}
                      style={{ marginLeft: 5, width: '40.8%' }}
                    >
                      {orderOptions?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                {/** 01/26 一括編集に追加項目の編集が可能性がないため解除した */}
                {/* {datesEdt?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <DatePicker
                            name={'date' + item.additionalItemId}
                            id={'date' + item.additionalItemId}
                            style={{ padding: 5, marginLeft: 5, width: '40.8%' }}
                            className={'input-editable'}
                            value={item.date ? dayjs(item.date, 'YYYY-MM-DD') : ''}
                            onChange={(date, dateString) => {
                              if (dateString !== '') {
                                const val = JSON.parse(JSON.stringify(datesEdt));
                                val[index]['date'] = dateString;
                                setDatesEdt(val);
                                setDatesValEdt(val);
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {labelsEdt?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                            className={'input-editable'}
                            onChange={(event) => {
                              const val = JSON.parse(JSON.stringify(labelsEdt));
                              val[index]['label'] = event.target.value;
                              setLabelsEdt(val);
                              setLabelsValEdt(val);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {memosEdt?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                            className={'input-editable'}
                            onChange={(event) => {
                              const val = JSON.parse(JSON.stringify(memosEdt));
                              val[index]['memo'] = event.target.value;
                              setMemosEdt(val);
                              setMemosValEdt(val);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })} */}
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={updateData} style={{ marginRight: 5 }}>
          保存
        </Button>
        <Button className="cancelButton" onClick={discardData}>
          破棄
        </Button>
      </Row>
    </>
  );

  // 表示設定のコンテンツ
  const displaySettingContent = (
    <>
      <Row>
        <Col span={16}>
          <Row style={{ fontSize: '13.5px', marginTop: 5, fontWeight: '700' }}>表示件数 </Row>
          <Row style={{ marginTop: 5, marginLeft: 20, fontSize: '13.5px' }}>
            <Radio.Group onChange={changeDisplayCount} value={value}>
              {pageSizeOptions?.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Row>
        </Col>
      </Row>

      <Row>
        {/* 親部品基本情報 */}
        <Col span={8}>
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>表示項目</div>
          <Space align="start" direction="horizontal">
            {new Array(Math.floor(displaySettingItems?.length / 16) + 1).fill(null)?.map((_, cIndex) => (
              <Space align="start" direction="vertical">
                {new Array(16).fill(null)?.map((_, rIndex) => {
                  var index = cIndex * 16 + rIndex;
                  if (
                    index >= displaySettingItems?.length ||
                    !displaySettingItems[index] ||
                    displaySettingItems[index].displayColumnsGroup !== DisplayColumnsGroup.Default
                  )
                    return <></>;
                  return (
                    <>
                      <Checkbox
                        style={{ marginLeft: 10, marginRight: 10 }}
                        key={index}
                        type="checkbox"
                        name={'chkitem' + displaySettingItems[index]?.id}
                        id={displaySettingItems[index]?.id}
                        defaultChecked={
                          displaySettingItems[index]?.id ==
                          displayColumnsIdList?.filter((i) => {
                            if (i == displaySettingItems[index]?.id) {
                              return displaySettingItems[index]?.id;
                            }
                          })
                        }
                        checked={
                          displaySettingItems[index]?.id ==
                          displayColumnsIdList?.filter((i) => {
                            if (i == displaySettingItems[index]?.id) {
                              return displaySettingItems[index]?.id;
                            }
                          })
                        }
                        onChange={handleChkChange}
                      >
                        {displaySettingItems[index]?.title}
                      </Checkbox>
                    </>
                  );
                })}
              </Space>
            ))}
          </Space>
        </Col>
        {/* 追加項目情報 */}
        <Col span={7}>
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700', marginLeft: 33 }}>
            <Checkbox
              style={{ marginLeft: 10, marginRight: 10 }}
              type="checkbox"
              name={'customInfochkitem'}
              id={'customInfochkitem'}
              defaultChecked={false}
              checked={epCustomInfoFlg}
              onChange={handleCustomChkChange}
            >
              追加項目を表示する　※情報取得に時間を要します
            </Checkbox>
          </div>
          <div className={epCustomInfoFlg ? 'left-display' : 'displayCustomItem'}>
            <div style={{ height: 500, overflowY: 'scroll' }}>
              <Space align="start" direction="vertical">
                {new Array(Math.floor(displaySettingItems?.length) + 1).fill(null)?.map((_, cIndex) => {
                  var index = cIndex;
                  if (
                    index >= displaySettingItems?.length ||
                    !displaySettingItems[index] ||
                    displaySettingItems[index].displayColumnsGroup === DisplayColumnsGroup.Default
                  )
                    return <></>;
                  return (
                    <>
                      <Checkbox
                        style={{ marginLeft: 10, marginRight: 10 }}
                        key={index}
                        type="checkbox"
                        name={'chkitem' + displaySettingItems[index]?.id}
                        id={displaySettingItems[index]?.id}
                        defaultChecked={false}
                        checked={
                          displaySettingItems[index]?.id ==
                          displayColumnsIdList?.filter((i) => {
                            if (i == displaySettingItems[index]?.id && epCustomInfoFlg) {
                              return displaySettingItems[index]?.id;
                            }
                          })
                        }
                        onChange={handleChkChange}
                      >
                        {displaySettingItems[index]?.title}
                      </Checkbox>
                    </>
                  );
                })}
              </Space>
            </div>
          </div>
        </Col>
        {/* 並び替え項目 */}
        <Col span={9}>
          <div className="right-display">
            <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>
              ※選択されている表示項目及び並び替え項目
            </div>
            <Sorting items={sortingColumns} update={updateSortingData} sortingData={sortingData} ref={sortingRef} />
          </div>
        </Col>
      </Row>

      <Row className="mt-2" style={{ marginRight: 0, marginBottom: -10 }} justify="end">
        <Button onClick={updateData} className="mainButton" id="update">
          更新
        </Button>

        <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );

  // 絞込み設定
  const filterContent = (
    <>
      <div
        style={{
          fontSize: '13.5px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        className="shiborikomiTbl"
      >
        <RTable>
          <tr>
            {/* <td>{firstFilterCol}</td> */}

            <td style={{ verticalAlign: 'top' }}>
              {new Array(Math.floor(filterSettingItems?.length / 21) + 1).fill(null)?.map((_, cIndex) => (
                <Space align="start" direction="vertical" className="filterDataRows">
                  {new Array(21).fill(null)?.map((_, rIndex) => {
                    var index = cIndex * 21 + rIndex;
                    if (index >= filterSettingItems?.length || !filterSettingItems[index]) return <></>;
                    return (
                      <>
                        <Row key={index} style={{ width: 390 }} align="middle" className="mt-1">
                          {/* ラベル */}
                          <Col span={8}>
                            <Checkbox
                              style={{ marginLeft: 10, marginRight: 10 }}
                              key={index}
                              type="checkbox"
                              name="chkitem"
                              checked={checkedState[index]?.checked}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            >
                              {filterSettingItems[index]?.title}
                            </Checkbox>
                          </Col>
                          {filterSettingItems[index]?.id === 2 ? ( // お客様項目の場合、
                            <>
                              <Col span={13}>
                                <Radio.Group
                                  onChange={customerFilterChange}
                                  value={customerRdo}
                                  style={{ marginLeft: -25 }}
                                >
                                  <Row>
                                    <Radio value={CustomerFilterType.Select} style={{ marginBottom: 3 }}></Radio>
                                    <Select
                                      defaultValue="選択してください。"
                                      style={{ width: 212, fontSize: 11, marginBottom: 4 }}
                                      name="customer"
                                      disabled={customerRdo === CustomerFilterType.Text}
                                      value={
                                        inputVal?.[index]?.inputData?.[0]
                                          ? inputVal?.[index]?.inputData?.[0]
                                          : undefined
                                      }
                                      onChange={(e) => {
                                        handleSelectVal(index, e, 0, filterSettingItems[index]?.id);
                                      }}
                                    >
                                      {clientInfo?.map((client, index) => (
                                        <Select.Option key={index} value={client.name}>
                                          {client.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Row>
                                  <Row>
                                    <Radio value={CustomerFilterType.Text}></Radio>
                                    <Input
                                      style={{
                                        width: 212,
                                        pointerEvents: customerRdo === CustomerFilterType.Select ? 'none' : 'auto',
                                      }}
                                      className={
                                        customerRdo === CustomerFilterType.Select
                                          ? 'filter-input filter-input-range input-editable disabled'
                                          : 'filter-input filter-input-range input-editable'
                                      }
                                      value={inputVal?.[index]?.inputData?.[1]}
                                      onChange={(e) => handleInputVal(index, e, 1, filterSettingItems[index]?.id)}
                                    ></Input>
                                  </Row>
                                </Radio.Group>
                              </Col>
                            </>
                          ) : labelColumnsList?.findIndex(
                              (col) =>
                                col.additionalItemId === filterSettingItems[index]?.additionalItemId &&
                                col.displayColumnsGroup === filterSettingItems[index]?.displayColumnsGroup
                            ) > -1 || // 追加項目・ラベルの場合、
                            memoColumnsList?.findIndex(
                              (col) =>
                                col.additionalItemId === filterSettingItems[index]?.additionalItemId &&
                                col.displayColumnsGroup === filterSettingItems[index]?.displayColumnsGroup
                            ) > -1 || // 追加項目・メモの場合、
                            filterSettingItems[index]?.id === 3 || // 図番項目の場合、
                            filterSettingItems[index]?.id === 4 ? ( // 名称項目の場合、
                            <>
                              <Col span={13}>
                                <Input
                                  className="filter-input filter-input-range input-editable"
                                  value={inputVal?.[index]?.inputData?.[0]}
                                  onChange={(e) => handleInputVal(index, e, 0, filterSettingItems[index]?.id)}
                                ></Input>
                              </Col>
                            </>
                          ) : filterSettingItems[index]?.id === 18 || // 作成者項目の場合、
                            filterSettingItems[index]?.id === 19 || // 最終更新者項目の場合、
                            filterSettingItems[index]?.id === 23 || // 受注形態項目の場合、
                            filterSettingItems[index]?.id === 24 || // 納期形態項目の場合、
                            filterSettingItems[index]?.id === 25 ? ( // 受注非受注項目の場合、
                            <>
                              <Col span={16}>
                                <Select
                                  defaultValue="選択してください。"
                                  style={{ width: 212, fontSize: 11 }}
                                  name="customer"
                                  value={
                                    inputVal?.[index]?.inputData?.[0] ? inputVal?.[index]?.inputData?.[0] : undefined
                                  }
                                  onChange={(e) => {
                                    handleSelectVal(index, e, 0, filterSettingItems[index]?.id);
                                  }}
                                >
                                  {filterSettingItems[index]?.id === 18 || // 作成者項目の場合、
                                  filterSettingItems[index]?.id === 19 ? ( // 最終更新者項目の場合、
                                    staffInfo?.map((staff, index) => (
                                      <Select.Option key={index} value={staff.name}>
                                        {staff.name}
                                      </Select.Option>
                                    ))
                                  ) : filterSettingItems[index]?.id === 23 ? ( // 受注形態項目の場合、
                                    companyInfo?.[0]?.coeffOrders
                                      ?.filter((i) => !i.isDeleted && i.isUsed)
                                      ?.map((coeff, index) => (
                                        <Select.Option key={index} value={coeff.name}>
                                          {coeff.name}
                                        </Select.Option>
                                      ))
                                  ) : filterSettingItems[index]?.id === 24 ? ( // 納期形態項目の場合、
                                    companyInfo?.[0]?.coeffDeliveryDates
                                      ?.filter((i) => !i.isDeleted && i.isUsed)
                                      ?.map((date, index) => (
                                        <Select.Option key={index} value={date.name}>
                                          {date.name}
                                        </Select.Option>
                                      ))
                                  ) : filterSettingItems[index]?.id === 25 ? ( // 受注非受注項目の場合、
                                    orderOptions?.map((order, index) => (
                                      <Select.Option key={index} value={order.name}>
                                        {order.name}
                                      </Select.Option>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </Col>
                            </>
                          ) : (
                            <>
                              {/* 入力項目From */}
                              <Col span={6}>
                                {filterSettingItems?.length - dateColumns?.length <= index || // 追加項目・日付の場合、
                                filterSettingItems[index]?.id === 20 || // 作成日項目の場合、
                                filterSettingItems[index]?.id === 21 ? ( // 更新日項目の場合、
                                  <>
                                    <DatePicker
                                      format={'YYYY-MM-DD'}
                                      style={{ marginLeft: 0 }}
                                      value={
                                        inputVal?.[index]?.inputData?.[0]
                                          ? dayjs(inputVal?.[index]?.inputData?.[0], 'YYYY-MM-DD')
                                          : ''
                                      }
                                      onChange={(_, dateString) =>
                                        handleDateVal(index, dateString, 0, filterSettingItems[index]?.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      className="filter-input-range input-editable"
                                      value={inputVal?.[index]?.inputData?.[0]}
                                      onChange={(e) => handleInputVal(index, e, 0, filterSettingItems[index]?.id)}
                                    ></Input>
                                  </>
                                )}
                              </Col>
                              <Col span={1} verticalAlign="center">
                                ～
                              </Col>
                              {/* 入力項目To */}
                              <Col span={6}>
                                {filterSettingItems?.length - dateColumns?.length <= index || // 追加項目・日付の場合、
                                filterSettingItems[index]?.id === 20 || // 作成日項目の場合、
                                filterSettingItems[index]?.id === 21 ? ( // 更新日項目の場合、
                                  <>
                                    <DatePicker
                                      format={'YYYY-MM-DD'}
                                      style={{ marginLeft: 0 }}
                                      value={
                                        inputVal?.[index]?.inputData?.[1]
                                          ? dayjs(inputVal?.[index]?.inputData?.[1], 'YYYY-MM-DD')
                                          : ''
                                      }
                                      onChange={(_, dateString) =>
                                        handleDateVal(index, dateString, 1, filterSettingItems[index]?.id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      className="filter-input-range input-editable"
                                      value={inputVal?.[index]?.inputData?.[1]}
                                      onChange={(e) => handleInputVal(index, e, 1, filterSettingItems[index]?.id)}
                                    ></Input>
                                  </>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </Space>
              ))}
            </td>
            {/* <td>
              <div>
                {[1, 2, 3]?.map((num, index) => (
                  <Checkbox
                    key={num}
                    checked={checkedState[index]}
                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                  >
                    Checkbox {num}
                  </Checkbox>
                ))}
              </div>
            </td> */}
          </tr>
        </RTable>
      </div>

      <Row className="mt-1" style={{ marginBottom: -5 }} align="middle">
        <Col span={12}>
          {/* <Checkbox onChange={clearAllCheckbox} checked={isAllClear}>
            全てクリア
          </Checkbox> */}
          <Button onClick={clearAll} className="mainButton" id="update">
            全てクリア
          </Button>
        </Col>

        <Col span={5} style={{ textAlign: 'end' }} offset={7}>
          <Button onClick={updateData} className="mainButton" id="update">
            更新
          </Button>

          <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            破棄
          </Button>
        </Col>
      </Row>
    </>
  );

  const reportParentList = (
    <>
      <Row style={{ marginTop: 10 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          {isListOutputOpen
            ? 'CSV出力するフォーマットを選択してください'
            : '帳票出力するフォーマットを選択してください'}
        </p>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力フォーマット：</p>
        </Col>{' '}
        {isListOutputOpen ? (
          <Col span={17}>
            <Select style={{ width: 300, fontSize: 11 }} name="customer"></Select>
          </Col>
        ) : isListReport ? (
          <Col span={17}>
            {excelListFormats !== undefined && excelListFormats?.length > 0 ? (
              <Select
                style={{ width: 300, fontSize: 11 }}
                value={selectedExeclListFormat?.id}
                onChange={(e) => {
                  const selectedData = excelListFormats?.find((item) => item?.id === e);
                  if (selectedData !== undefined) {
                    setSelectedExcelListTypeFileId(selectedData.fileId);
                    setSelectedExeclListFormat(selectedData);
                  }
                }}
              >
                {excelListFormats?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.fileName}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Select style={{ width: 300, fontSize: 11 }} />
            )}
          </Col>
        ) : (
          <Col span={17}>
            {excelIndividualFormats !== undefined && excelIndividualFormats?.length > 0 ? (
              <Select
                style={{ width: 300, fontSize: 11 }}
                value={selectedExeclIndividualFormat?.id}
                onChange={(e) => {
                  const selectedData = excelIndividualFormats?.find((item) => item?.id === e);
                  if (selectedData !== undefined) {
                    setSelectedExeclIndividualFormat(selectedData);
                  }
                }}
              >
                {excelIndividualFormats?.map((item) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.fileName}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select style={{ width: 300, fontSize: 11 }} />
            )}
          </Col>
        )}
      </Row>
      {/* <Row style={{ marginTop: 10 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル：</p>
        </Col>
        <Col span={17}>
          <Input className="input-editable" style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px', marginLeft: '5px' }}>参照</Button>
          </Upload>
        </Col>
      </Row> */}
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={isListOutputOpen ? listOutputOk : reportOutputOk} className="mainButton" id="update">
            OK
          </Button>

          <Button
            onClick={isListOutputOpen ? listOutputCancel : reportOutputCancel}
            className="cancelButton"
            style={{ marginLeft: 10 }}
            id="discard"
          >
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  const curtParamModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>CURT.パラメータの読込確認</p>
    </div>
  );

  // CURT.パラメータの読込確認のフォーム
  const curtParamInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>CURT.パラに更新する項目を選択してください。</p>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isMaterialParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isMaterialParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>材料系（材質区分、材質名称、材料名称・重量単価）</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isProcessParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isProcessParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>加工系（工程マスター、装置設定、工程パターン）</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isPurchaseParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isPurchaseParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>購入品</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isOtherParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isOtherParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>その他全て（係数、カスタマイズ・・・）</label>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {htmlContent?.map((html, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: html.htmlTable }} />
      ))}
      <div style={{ overflow: 'hidden' }}>
        <Form className="components-table-demo-control-bar" form={form} onFinish={onFinish}>
          {/* メニューバー */}
          {menuIconList}
          {/* 一覧とプロパティ表示 */}
          <div
            style={{
              height: props.isFromiQ3 == undefined || props.isFromiQ3 == false ? '90.8vh' : '65.2vh',
              marginLeft: 10,
            }}
          >
            <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1} style={{ color: 'red' }}>
              <div style={{ marginTop: 7 }}>
                {/* 検索アリア */}
                <div style={{ display: 'flex', width: '89%' }}>
                  <Input
                    id="searchCustomer"
                    name="searchCustomer"
                    value={customer}
                    style={{ marginLeft: 0 }}
                    placeholder="お客様"
                    allowClear
                    onChange={changeCustomer}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchDrawingNo"
                    name="searchDrawingNo"
                    style={{ marginLeft: 5 }}
                    placeholder="図番"
                    value={imageNum}
                    allowClear
                    onChange={changeDrawingNo}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchName"
                    name="searchName"
                    style={{ marginLeft: 5 }}
                    placeholder="名称"
                    value={name}
                    allowClear
                    onChange={changeName}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchKeyword"
                    name="searchKeyword"
                    style={{ marginLeft: 5 }}
                    value={keyword}
                    placeholder="キーワードを入力"
                    allowClear
                    onChange={changeKeywords}
                    onPressEnter={getParentsByFilter}
                  />
                  <Button style={{ marginLeft: 15 }} className="filterClearButton" onClick={clearAllFilter}>
                    {filterClearBtn}
                  </Button>
                </div>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    className={
                      props.isFromiQ3 == undefined || props.isFromiQ3 == false ? 'tbParentList' : 'tbParentList_dialog'
                    }
                    columns={[defaultIDColumns, ...chkData]}
                    rowKey={(record) => record?.estimateProducts?.id}
                    rowClassName={(record) =>
                      record?.estimateProducts?.id == selectedRowKeys ? 'active-row' : 'data-row'
                    }
                    dataSource={initialTblItems}
                    scroll={{ y: 590, x: '10vw' }}
                    pagination={{
                      position: ['bottomLeft'],
                      total: totalCount,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      pageSize: pageSize,
                      pageSizeOptions: pageSizes,
                      showSizeChanger: true,
                      onShowSizeChange: handlePageSizeChange,
                      defaultCurrent: 1,
                      current: page,
                      onChange: async (page, pageSize) => {
                        setCurrentPage(page);
                      },
                    }}
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                        onDoubleClick: () => {
                          parentDataDisplayEvent(record);
                        },
                        // style: {
                        //   background:
                        //     record?.estimateProducts?.id == selectedRowKeys
                        //       ? '#fcfade' // 選択されている行の場合
                        //       : getEstimateStatusRowColor(
                        //           record?.estimateProducts?.calcParameters?.parameters?.estimateStatus,
                        //           record?.estimateProducts?.estimateStatusId
                        //         ), // 案件状態のリストカラーで表示
                        // },
                      };
                    }}
                    onChange={handleTableChange}
                  />
                </Row>
              </div>
              {/* プロパティ表示 */}
              <div>
                <Routes>
                  <Route
                    exact
                    path=":parentList"
                    element={
                      <ParentListDetail
                        editMode={editMode}
                        selectedData={selectedData}
                        onUpdateData={updatedDetails}
                        updateEditMode={updateEditMode}
                        orderOptions={orderOptions}
                        isFromiQ3={props.isFromiQ3}
                        isFromParent={props?.isFromParent}
                        companyInfo={companyInfo}
                        showEdtConfirmOK={showEdtConfirmOK}
                        companySettingInfo={props?.companySettingInfo}
                        showEdtConfirmCancle={showEdtConfirmCancle}
                      />
                    }
                  />
                </Routes>
              </div>
            </SplitPane>
          </div>
        </Form>
      </div>

      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardOkConfirm, discardCancelConfirm),
            null,
            400,
            discardOkConfirm,
            discardCancelConfirm,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            updateDataCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
      {/* 子部品確認 */}
      {isChildOpen
        ? commonModal(
            isChildOpen,
            childModalTitle,
            null,
            { height: 822 },
            1790,
            updateOk,
            updateCancel,
            confirmChildInfoContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 加工確認 */}
      {isManufactureOpen ? (
        <KouteibetsuInfo
          selectedEstimateData={selectedEstimateData}
          showProcessTotalAmount={isManufactureOpen}
          closeProcessTotalAmountDialog={updateCancel}
          quantity={selectedEstimateData?.estimateProducts?.quantity}
        />
      ) : (
        ''
      )}
      {/* 複写_Modal */}
      {isCopy
        ? commonModal(
            isCopy,
            commonModalTitle,
            commonFooter(copyOk, copyCancel),
            null,
            520,
            copyOk,
            copyCancel,
            copyParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 削除_Modal */}
      {isDelete
        ? commonModal(
            isDelete,
            editModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            520,
            deleteOk,
            deleteCancel,
            deleteParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 事前登録 */}
      {preRegister
        ? commonModal(
            preRegister,
            jisenRegisterModalTitle,
            null,
            null,
            1030,
            updateData,
            preRegisterModalClose,
            jisenRegisterContent,
            'registerModalStyle',
            null,
            true
          )
        : ''}
      {/* 一括編集 */}
      {isUpdate
        ? commonModal(
            isUpdate,
            editModalTitle,
            null,
            null,
            1790,
            updateData,
            updateCancel,
            editAllContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 表示設定 */}
      {isDisplay
        ? commonModal(
            isDisplay,
            displaySettingModalTitle,
            null,
            null,
            1400,
            updateData,
            displayCancel,
            displaySettingContent,
            null,
            null,
            true
          )
        : ''}
      {/* 絞込み設定 */}
      {isFilter
        ? commonModal(
            isFilter,
            filterModalTitle,
            null,
            null,
            1225,
            updateData,
            cancelFilter,
            filterContent,
            null,
            null,
            true
          )
        : ''}
      {/* リスト出力 */}
      {/* {isListOutputOpen
        ? commonModal(
            isListOutputOpen,
            reportModalTitle,
            null,
            { height: 160 },
            600,
            listOutputOk,
            listOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''} */}
      {/* 帳票出力・CSV出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 125 },
            600,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToCreateParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToCreateParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}
      {/** お客様情報検索ダイアログ表示 */}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={Customer}
          clientType={ClientType.Customer}
        />
      ) : (
        ''
      )}

      {/* 同一図番_Modal */}
      {isSaveSameDrawingNo
        ? commonModal(
            isSaveSameDrawingNo,
            sameDrawingNoModalTitle,
            commonFooter(saveSameDrawingNoOk, saveSameDrawingNoCancel),
            null,
            400,
            saveSameDrawingNoOk,
            saveSameDrawingNoCancel,
            sameDrawingNoContent(
              getNameByValue(props?.companySettingInfo?.[0]?.environments?.saveSameType, saveSameTypes)
            ),
            null,
            null,
            false
          )
        : ''}
      {/* CURT.パラメータ更新_Modal */}
      {isCurtParam
        ? commonModal(
            isCurtParam,
            curtParamModalTitle,
            commonActionFooter(curtParamOk, curtParamCancel, '更新', 'キャンセル'),
            null,
            520,
            curtParamOk,
            curtParamCancel,
            curtParamInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});
export default WithRouter(ParentList);
