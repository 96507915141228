/**
 * クラス名：iq3装置設定の曲げ補正時間画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import { commonModal } from '../../../common/CommonModal';
import {
  deleteListColData,
  updateListColData,
  sortListData,
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  setTimetoSec,
  secondsToHms,
  getListItemByCustomData,
  getInputBorderStyle,
  handleKeyPress,
  regexExp,
  toHalfWidth,
} from '../../../common/Common';
import { WorkType } from '../../../common/enums';

const Device_BendingCorrectionTime = forwardRef((props, ref) => {
  // 本数
  const [honsuuData, setHonsuuData] = useState([]);
  const [honsuuDataTemp, setHonsuuDataTemp] = useState([]);
  const [honsuuList, setHonsuuList] = useState([]);

  // 個数
  const [kosuuData, setKosuuData] = useState([]);
  const [kosuuDataTemp, setKosuuDataTemp] = useState([]);
  const [kosuuList, setKosuuList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isHonsuu, setIsHonsuu] = useState(false);
  const [isKosuu, setIsKosuu] = useState(false);

  const [seisanKosuuList, setSeisanKosuuList] = useState([]);

  const [isLength, setIsLength] = useState(false);
  const [isArea, setIsArea] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [category, setCategory] = useState('');

  const [weightData, setWeightData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  // 曲げ補正時間係数リスト
  const [detailsList, setDetailsList] = useState([]);
  // 加工補正時間係数リスト
  const [workCorrectionTimeList, setWorkCorrectionTimeList] = useState([]);

  // 製品サイズ係数
  // const [seihinSizeList, setSeihinSizeList] = useState([]);

  // マテリアルハンドリング時間
  // const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  // 加工時間
  // const [kakoujikanList, setKakoujikanList] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);

    // 本数
    if (props?.detailsData?.modifyTimeQtyThMaster) {
      setHonsuuData(props?.detailsData?.modifyTimeQtyThMaster);
      setHonsuuDataTemp(props?.detailsData?.modifyTimeQtyThMaster);
    }
    if (props?.detailsData?.modifyTimeItems) {
      setHonsuuList(props?.detailsData?.modifyTimeItems);
    }
    // 曲げ補正時間係数
    if (props?.detailsData?.modifyCoeffWeightThMaster) {
      setWeightData(props?.detailsData?.modifyCoeffWeightThMaster);
      setDetailsWeightTemp(props?.detailsData?.modifyCoeffWeightThMaster);
    }
    if (props?.detailsData?.modifyCoeffLenThMaster) {
      setLengthData(props?.detailsData?.modifyCoeffLenThMaster);
      setDetailsLengthTemp(props?.detailsData?.modifyCoeffLenThMaster);
    }
    if (props?.detailsData?.modifyCoeffItems) {
      setDetailsList(props?.detailsData?.modifyCoeffItems);
    }

    // 個数

    setKosuuData(props.kosuu);
    setKosuuDataTemp(props.kosuu);
    setKosuuList(props.iq3BendingKosuuList);

    // 生産個数係数
    setSeisanKosuuList(props.seisanKosuuList);

    if (props?.detailsData?.mtAreaThMaster) {
      setAreaData(props?.detailsData?.mtAreaThMaster);
      setDetailsAreaTemp(props?.detailsData?.mtAreaThMaster);
    }
    if (props?.detailsData?.mtWeightThMaster) {
      setWeightData(props?.detailsData?.mtWeightThMaster);
      setDetailsWeightTemp(props?.detailsData?.mtWeightThMaster);
    }
    if (props?.detailsData?.mtTimeCoeffItems) {
      setWorkCorrectionTimeList(props?.detailsData?.mtTimeCoeffItems);
    }
    setCategory(props.processName);
  }, [
    props.detailsData,
    props.seisanKosuuList,
    props.editMode,
    props.processName,
    props.workCorrectionTimeListData,
    props.kosuu,
    props.iq3BendingKosuuList,
  ]);
  const number = () => {
    setIsHonsuu(true);
  };

  const addHonsuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>本数</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const handleHonsuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(honsuuDataTemp));
    const updatedData = temp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    const previousValue = updatedData[updatedData?.length - 2].value;
    if (previousValue) {
      updatedData[updatedData?.length - 1].value = (parseInt(previousValue) + 1).toString();
      setHonsuuDataTemp(updatedData);
    } else {
      setHonsuuDataTemp(updatedData);
    }
  };

  const addHonsuu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = honsuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = honsuuDataTemp?.findIndex((item) => item.no === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: honsuuDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setHonsuuDataTemp(copyData);
    }
  };

  const deleteHonsuu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    const listTemp = JSON.parse(JSON.stringify(honsuuDataTemp));
    if (listTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setHonsuuDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  const addHonsuuMasterData = () => {
    const deletedId = [];

    honsuuData?.forEach((item1, index) => {
      const found = honsuuDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = honsuuDataTemp
      ?.filter((item) => !item.isOutOfRange && !honsuuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const afterDeleted = deleteListColData(honsuuList, deletedId);

    const updatedData = updateListColData(afterDeleted, newId);

    const sortedList = sortListData(updatedData, honsuuDataTemp);

    setIsHonsuu(false);
    setHonsuuData(honsuuDataTemp);

    setHonsuuList(sortedList);
    props.updateHonsuu(honsuuDataTemp);

    props.updateHonsuuList(sortedList);
  };

  const cancelMasterData = () => {
    if (isHonsuu === true) {
      setErrIndexVal([]);
      setErrorMessage('');
      setHonsuuDataTemp(honsuuData);
      setIsHonsuu(false);
    } else if (isWeight === true) {
      setErrIndexVal([]);
      setErrorMessage('');
      setDetailsWeightTemp(weightData);
      setIsWeight(false);
    } else if (isArea === true) {
      setErrIndexVal([]);
      setErrorMessage('');
      setDetailsAreaTemp(areaData);
      setIsArea(false);
    }
  };
  const isValueOrderCorrect = (e, no, field, tblName) => {
    let temp = [];
    if (tblName === 'honsuu') {
      temp = honsuuDataTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'length') {
      temp = detailsLengthTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'weight') {
      temp = detailsWeightTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'area') {
      temp = detailsAreaTemp?.filter((i) => i.isOutOfRange === false);
    }
    const insertIndex = temp?.findIndex((item) => item.no === no);

    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      if (tblName === 'honsuu') handleHonsuuChange(e, no, field);
      else if (tblName === 'area') handleAreaChange(e, no, field);
      else if (tblName === 'length') handleLengthChange(e, no, field);
      else if (tblName === 'weight') handleWeightChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addHonsuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {honsuuDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleHonsuuChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'honsuu')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'honsuu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, honsuuDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addHonsuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteHonsuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addHonsuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const changeHonsuu = (event, no) => {
    honsuuList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event;
      });
    setHonsuuList(honsuuList);
    props.updateHonsuuList(honsuuList);
  };

  const addLength = () => {
    setIsLength(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };
  const addArea = () => {
    setIsArea(true);
  };
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>mm</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addLengthRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsLengthTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsLengthTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsLengthTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const deleteWeight = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsWeightTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsWeightTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addWeightRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsWeightTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    } else if (isHonsuu === true) {
      setIsHonsuu(false);
    }
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const cancelLengthMaster = () => {
    setDetailsLengthTemp(lengthData);
    setIsLength(false);
  };
  const addLengthMaster = () => {
    const deletedId = [];

    lengthData?.forEach((item1, index) => {
      const found = detailsLengthTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsLengthTemp
      ?.filter((item) => !item.isOutOfRange && !lengthData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteData = detailsList?.map((item) => {
      return {
        ...item,
        lengthCoeffList: deleteListColData(item?.lengthCoeffList, deletedId),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item?.lengthCoeffList.push({
          no: key,
          value: 1,
          isOutOfRange: false,
        });
      });
      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortLstData = [...item?.lengthCoeffList];
      const sortedLstData = sortListData(sortLstData, detailsLengthTemp);
      return {
        ...item,
        lengthCoeffList: sortedLstData,
      };
    });
    setIsLength(false);
    setLengthData(detailsLengthTemp);

    setDetailsList(sortedList);
    props.updatedLength(detailsLengthTemp);

    props.updatedSizesList(sortedList);
  };

  const addWeightMaster = () => {
    const deletedId = [];

    weightData?.forEach((item1, index) => {
      const found = detailsWeightTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsWeightTemp
      ?.filter((item) => !item.isOutOfRange && !weightData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    if (category === WorkType.SmBending) {
      props.updatedWeight(detailsWeightTemp);
      const customItem = { weightId: 0, lengthCoeffList: getListItemByCustomData(lengthData) };

      const deleteData = deleteListColData(detailsList, deletedId, false, 'weightId');
      const updatedData = updateListColData(deleteData, newId, false, { ...customItem }, 'weightId');
      const result = sortListData(updatedData, detailsWeightTemp, false, 'weightId');

      // const updatedList = updateSizeList(detailsList, deletedId, newId);
      setDetailsList(result);
      props.updatedSizesList(result);
    } else {
      props.updatedWorkWeight(detailsWeightTemp);
      const customItem = { weightId: 0, areaCoeffList: getListItemByCustomData(areaData) };

      const deleteData = deleteListColData(workCorrectionTimeList, deletedId, false, 'weightId');
      const updatedData = updateListColData(deleteData, newId, false, { ...customItem }, 'weightId');
      const result = sortListData(updatedData, detailsWeightTemp, false, 'weightId');

      // const updatedList = updateSizeList(workCorrectionTimeList, deletedId, newId);
      setWorkCorrectionTimeList(result);
      props.updatedworkCorrectionTimeListData(result);
    }
  };
  const handleAreaChange = (event, id, field) => {
    const updatedData = detailsAreaTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };
  const addAreaRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsAreaTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };
  const deleteArea = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsAreaTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsAreaTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addAreaMaster = () => {
    const deletedId = [];

    areaData?.forEach((item1, index) => {
      const found = detailsAreaTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsAreaTemp
      ?.filter((item) => !item.isOutOfRange && !areaData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteData = workCorrectionTimeList?.map((item) => {
      return {
        ...item,
        areaCoeffList: deleteListColData(item.areaCoeffList, deletedId),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.areaCoeffList.push({
          no: key,
          value: 1,
          isOutOfRange: false,
        });
      });
      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortLstData = [...item.areaCoeffList];
      const sortedLstData = sortListData(sortLstData, detailsAreaTemp);
      return {
        ...item,
        areaCoeffList: sortedLstData,
      };
    });
    setIsArea(false);
    setAreaData(detailsAreaTemp);
    props.updatedArea(detailsAreaTemp);
    setWorkCorrectionTimeList(sortedList);
    props.updatedworkCorrectionTimeListData(sortedList);
  };

  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleAreaChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'area')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'area')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsAreaTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={addAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleLengthChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'length')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'length')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsLengthTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addLengthMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelLengthMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleWeightChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value', 'weight')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value', 'weight')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsWeightTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addWeightMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const listDataChange = (value, wId, no, tblName) => {
    if (tblName === 'magechou') {
      const copyData = [...detailsList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData?.map((list) => {
        if (list?.weightId === wId) {
          list?.lengthCoeffList?.map((data) => {
            if (data.no === no) {
              data.value = value;
              // data.value = parseInt(value);
            }
          });
        }
      });
      setDetailsList(updatedData);
      props.updatedSizesList(updatedData);
    } else {
      const copyData = [...workCorrectionTimeList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData?.map((list) => {
        if (list?.weightId === wId) {
          list?.areaCoeffList?.map((data) => {
            if (data.no === no) {
              // data.value = parseInt(value);
              data.value = value;
            }
          });
        }
      });
      setWorkCorrectionTimeList(updatedData);
      props.updatedworkCorrectionTimeListData(updatedData);
    }
  };
  const listDataChangeOnBlur = (value, wId, no, tblName) => {
    if (tblName === 'magechou') {
      const copyData = [...detailsList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData?.map((list) => {
        if (list?.weightId === wId) {
          list?.lengthCoeffList?.map((data) => {
            if (data.no === no) {
              data.value = parseFloat(value) > 0 ? value : 1;
            }
          });
        }
      });
      setDetailsList(updatedData);
      props.updatedSizesList(updatedData);
    } else {
      const copyData = [...workCorrectionTimeList];
      const updatedData = JSON.parse(JSON.stringify(copyData));
      updatedData?.map((list) => {
        if (list?.weightId === wId) {
          list?.areaCoeffList?.map((data) => {
            if (data.no === no) {
              data.value = parseFloat(value) > 0 ? value : 1;
            }
          });
        }
      });
      setWorkCorrectionTimeList(updatedData);
      props.updatedworkCorrectionTimeListData(updatedData);
    }
  };
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row className="table-container">
          <Table
            style={{
              width:
                honsuuData?.filter((subitem) => !subitem.isOutOfRange)?.length > 4
                  ? '100%'
                  : honsuuData?.filter((subitem) => !subitem.isOutOfRange)?.length * 210 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="fixedCol">
                <Row justify="center" className="tbl-header">
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={number} style={{ pointerEvents: 'auto' }}>
                        加工箇所数
                      </Button>
                    </>
                  ) : (
                    <>
                      <label> 加工箇所数</label>
                    </>
                  )}
                </Row>
              </td>

              {honsuuData
                ?.filter((subitem) => !subitem.isOutOfRange)
                ?.map((item, index) => (
                  <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                    {/* {honsuuData[honsuuData?.length - 1].no === item.no ? <>{item.value} ～</> : <>～{item.value}</>} */}
                    ～{item.value}
                  </td>
                ))}
            </tr>

            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '210px' }} className="fixedOneRowCol">
                補正時間
              </td>
              {honsuuList?.map((row, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Row align="middle">
                    {!editMode ? (
                      <>
                        <Col span={24}>
                          <label>{secondsToHms(row.value)}</label>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={10}>
                          <Input
                            value={secondsToHms(row.value)}
                            onChange={(e) => changeHonsuu(setTimetoSec(e.target.value), row.no)}
                          ></Input>
                        </Col>
                        <Col span={14}>
                          <CaretUpOutlined
                            style={{
                              fontSize: 16 + 'px',
                              marginLeft: 3,
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), '10min');

                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(row.value), '10min');
                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), 'min');
                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(row.value), 'min');
                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), 'sec');
                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: 'auto',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(secondsToHms(row.value)), 'sec');
                              changeHonsuu(setTimetoSec(editedTime), row.no);
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </td>
              ))}
            </tr>
          </Table>
        </Row>
        {category === WorkType.SmBending ? (
          <>
            <Row
              className="mt-3"
              style={{
                backgroundColor: '#1063aa',
                color: 'white',
                height: 30,
                width:
                  lengthData?.filter((i) => !i.isOutOfRange)?.length > 8
                    ? '100%'
                    : lengthData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
              }}
              align="middle"
            >
              <label style={{ marginLeft: 3 }}>加工補正時間係数</label>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    lengthData?.filter((subitem) => !subitem.isOutOfRange)?.length > 8
                      ? '100%'
                      : lengthData?.filter((subitem) => !subitem.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                              kg
                            </Button>
                            /
                            <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                              mm
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>kg / mm</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {lengthData
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}mm
                      </td>
                    ))}
                </tr>

                {detailsList?.map((row, index) => (
                  <tr key={index} style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                      {/* ～{row.max} */}
                      {weightData?.filter((wItem) => wItem.no === row?.weightId)?.[0]?.value}
                    </td>

                    {row?.lengthCoeffList?.map((col, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => listDataChange(e.target.value, row?.weightId, col.no, 'magechou')}
                          onBlur={(e) => listDataChangeOnBlur(e.target.value, row?.weightId, col.no, 'magechou')}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </>
        ) : (
          <>
            <Row
              className="mt-3"
              style={{
                backgroundColor: '#1063aa',
                color: 'white',
                height: 30,
                width:
                  areaData?.filter((i) => !i.isOutOfRange)?.length > 8
                    ? '100%'
                    : areaData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
              }}
              align="middle"
            >
              <label style={{ marginLeft: 3 }}>加工補正時間係数</label>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    areaData?.filter((i) => i.isOutOfRange === false)?.length > 8
                      ? '100%'
                      : areaData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                              kg
                            </Button>
                            /
                            <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                              cm<sup>2</sup>
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>
                              kg / cm<sup>2</sup>
                            </label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {areaData
                    ?.filter((subitem) => subitem.isOutOfRange === false)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}
                      </td>
                    ))}
                </tr>

                {workCorrectionTimeList?.map((row, index) => (
                  <tr key={index} style={{ textAlign: 'center' }}>
                    <td style={{ width: '196px' }} className="tbl-header fixedCol">
                      {weightData?.filter((wItem) => wItem.no === row?.weightId)?.[0]?.value}
                    </td>

                    {row?.areaCoeffList?.map((col, index) => (
                      <td key={index} style={{ width: '98px' }}>
                        <Input
                          value={col.value}
                          onChange={(e) => listDataChange(e.target.value, row?.weightId, col.no, 'baritori')}
                          onBlur={(e) => listDataChangeOnBlur(e.target.value, row?.weightId, col.no, 'baritori')}
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          className={editMode ? 'input-editable ' : 'input-non-editable'}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </Table>
            </Row>
          </>
        )}
      </div>
      {/* 本数 */}
      {isHonsuu
        ? commonModal(
            isHonsuu,
            addHonsuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addHonsuuContent,
            null,
            null,
            true
          )
        : ''}

      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_BendingCorrectionTime;
