export const ErrorMessage = (paramStr = '') => {
  return {
    E001: 'ユーザーIDを入力してください。',
    E002: 'パスワードを入力してください。',
    E003: '情報取得にエラーが発生しました。 \nシステムサポートまでご連絡ください。',
    E004: 'ユーザー情報取得に失敗しました。',
    E005: 'ユーザー名、もしくはパスワードを入力してください。',
    E006: 'トークンの認証エラーが発生しました。\n再ログインしてください。',
    E007: paramStr + 'を入力してください。',
    E008: paramStr + 'を正しい形式で入力してください。',
    E009: paramStr + 'は数字のみ入力してください。',
    E010: 'APIサーバーへの接続が失敗しました。\nシステムサポートまでご連絡ください。',
    E011: 'IDまたはパスワードが有効ではありません。',
    E012: '該当する情報は存在しません。再検索してください。',
    E013: '予期しないエラーが発生しました。',
    E014: paramStr + 'は数字及び小数点１桁で入力してください。',
    E015: paramStr + 'は最大桁数を超えています。',
    E016: paramStr + 'が不正です。',
    E017: paramStr + 'を選択してください。',
    E018: '該当データは他に利用している為、削除できません。',
    E019: '別のPCからログインされました。\n引き続き使用する場合は、再度ログインしてください。',
    E020: paramStr + 'は重複しています。',
    E021: '既にこのアカウントでログインされています。ログインしますか？\n※先にログインしているユーザーは使用できなくなります。',
    E022: paramStr + 'が存在しません。',
  };
};
