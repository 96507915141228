/**
 * クラス名：材質名称詳細画面
 * 説明：iQ3板金に使用の材質名称詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Select, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  formValidatorMode,
  formatDate,
  isDecimal,
  defaultValue,
  toDecimal,
  regexExp,
  toHalfWidth,
} from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialClassNameDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  const [materialTypeList, setMaterialTypeList] = useState([]);

  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let detailData = props.selectedData;
      detailData.info.gravity = toDecimal(detailData.info.gravity, 2);
      detailData.info.lossCoeff = toDecimal(detailData.info.lossCoeff, 2);
      let selectedData = JSON.parse(JSON.stringify({ ...detailData }));
      setOriginData({ ...props.selectedData });
      setSelectedRowData(selectedData);
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  useEffect(() => {
    setMaterialTypeList(props?.materialTypeList);
  }, [props.materialTypeList]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      // updatedData[objKey] = value;
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    /* setUpdateConfirm(true);
    setIsUpdate(true); */
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode, isDetail, materialClassNameLst) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質名称
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    /*  var nameDuplicate = materialClassNameLst?.map((item) => item.name === input?.value).every((match) => match);
    if (nameDuplicate && procMode !== formValidatorMode.BulkEdit) {
      // 重複
      message.push(<li>{ErrorMessage('材質名称').E020}</li>);
      input.classList.add('input-error');
      ret = false;
    } */

    // 材質区分名
    if (isDetail) {
      input = document.getElementsByName('materialRef')?.[0];
    } else {
      input = document.getElementsByName('materialTypeId')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.materialType && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質区分名').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 比重
    input = inputItems['gravity']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('比重').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('比重').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材料粗利係数
    input = inputItems['lossCoeff']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材料粗利係数').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('材料粗利係数').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      var materialClassNameLst = props.materialClassNameData?.filter((item) => item.id !== selectedRowData.id);
      if (!formValidation(inputItemRef, formValidatorMode.Edit, true, materialClassNameLst)) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };
      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData({ ...props.selectedData });
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (isCheckZenkaku) {
      if (name === 'gravity') {
        value = toDecimal(value, 2);
      } else {
        value = toDecimal(value === '' ? 1 : value, 2);
      }
    }
    editDetailInput(name, value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr> */}

              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={selectedRowData?.code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('code', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>
              {/* 材質区分名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質区分名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <div style={{ padding: '0 5px' }}>
                      <Select
                        style={{ marginLeft: 0, width: '99.6%' }}
                        onChange={(e) => {
                          editDetailInput('materialTypeId', e);
                        }}
                        placeholder="材質区分名"
                        id="materialRef"
                        name="materialRef"
                        value={selectedRowData?.materialTypeId}
                      >
                        {materialTypeList?.map((item, index) => (
                          <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getMaterilTypeName(selectedRowData?.materialTypeId)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 比重 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">比重</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="gravity"
                    id="gravity"
                    value={selectedRowData?.info?.gravity}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['gravity'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                  />
                </td>
              </tr>
              {/* 材料粗利係数 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料粗利係数</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="lossCoeff"
                    id="lossCoeff"
                    value={selectedRowData?.info?.lossCoeff}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['lossCoeff'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.created ? formatDate(selectedRowData?.created) : ''}
                  </label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  </label>
                </td>
              </tr>

              {/* 更新者 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{selectedRowData?.modifier}</label>
                </td>
              </tr>

              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="remarks"
                    id="remarks"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData?.info?.remarks}
                    type="text"
                    maxLength={2048}
                    onChange={(e) => editDetailInput('remarks', e.target.value)}
                  />
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isUsed"
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('isUsed', e.target.value);
                      }}
                      value={selectedRowData?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isUsed && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialClassNameDetail;
