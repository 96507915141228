/**
 * クラス名：装置設定の製品重量係数画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  defaultQuantiyData,
  deleteListColData,
  getInputBorderStyle,
  handleKeyPress,
  regexExp,
  sortListData,
  toDecimal,
  toHalfWidth,
  updateListColData,
} from '../../../common/Common';

const ProductSize = forwardRef((props, ref) => {
  const [juuryouMstData, setJuuryouMstData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isJuuryou, setIsJuuryou] = useState(false);

  const [detailsJuuryouTemp, setDetailsJuuryouTemp] = useState([]);

  const [detailsList, setDetailsList] = useState([]);
  const [errIndexVal, setErrIndexVal] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    if (props.detailsData != undefined) {
      if (props.detailsData?.prodWeightCoeffThItems) setDetailsList(props.detailsData?.prodWeightCoeffThItems);
      if (props.detailsData?.prodWeightCoeffThMaster) setJuuryouMstData(props.detailsData?.prodWeightCoeffThMaster);
      if (props.detailsData?.prodWeightCoeffThMaster && props.detailsData?.prodWeightCoeffThMaster?.length > 0) {
        setDetailsJuuryouTemp([...props.detailsData?.prodWeightCoeffThMaster]);
      } else {
        setDetailsJuuryouTemp(defaultQuantiyData);
      }
    }
    setEditMode(props.editMode);
  }, [props.detailsData, props.editMode]);

  // 重量ボタン押下
  const callJuuryouMstData = () => {
    setIsJuuryou(true);
  };

  // 重量マスタ一覧のヘッダー
  const addJuuryouModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  // 重量マスタ一覧の削除ボタン押下
  const deleteJuuryouRow = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    const listTemp = JSON.parse(JSON.stringify(detailsJuuryouTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsJuuryouTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 重量マスタ一覧の追加ボタン押下
  const addJuuryouRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsJuuryouTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsJuuryouTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsJuuryouTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsJuuryouTemp(copyData);
    }
  };

  // 重量マスタ一覧の「X」ボタン押下
  const closeAddModal = () => {
    if (isJuuryou === true) {
      setIsJuuryou(false);
    }
  };

  // 重量マスタ一覧の値を入力された場合、
  const handleJuuryouChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(detailsJuuryouTemp));
    const updatedData = temp
      ?.filter((i) => !i.isOutOfRange)
      ?.map((row) => {
        if (row.no === no) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    setDetailsJuuryouTemp(updatedData);
  };

  // 重量マスタ一覧のCancelボタン押下
  const cancelMasterData = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setDetailsJuuryouTemp(juuryouMstData);
    setIsJuuryou(false);
  };

  // 重量マスタ一覧のOKボタン押下
  const okKosuuMaster = () => {
    const deletedId = [];

    juuryouMstData?.forEach((item1, index) => {
      const found = detailsJuuryouTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsJuuryouTemp
      ?.filter((item) => !item.isOutOfRange && !juuryouMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deletedData = deleteListColData(detailsList, deletedId);

    const updatedData = updateListColData(deletedData, newId);

    const sortedData = sortListData(updatedData, detailsJuuryouTemp);
    setIsJuuryou(false);

    // 重量マスタ情報を更新
    setJuuryouMstData(detailsJuuryouTemp);
    props.updatedMstJuuryouData(detailsJuuryouTemp);

    // リスト情報を更新
    setDetailsList(sortedData);
    props.updatedJuuryouList(sortedData);
  };
  const isValueOrderCorrect = (e, no, field) => {
    const temp = detailsJuuryouTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleJuuryouChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };

  // 重量マスタ一覧
  const juuryouMstContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsJuuryouTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          value={item.value}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onChange={(e) => handleJuuryouChange(e, item.no, 'value')}
                          style={{
                            ...getInputBorderStyle(index, detailsJuuryouTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addJuuryouRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteJuuryouRow(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okKosuuMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 重量係数の値を入力された場合、
  const listDataChange = (event, no) => {
    detailsList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setDetailsList(detailsList);
    props.updatedJuuryouList(detailsList);
  };
  // 重量係数の値を'0'入力された場合、
  const listDataChangeOnBlur = (event, no) => {
    detailsList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = parseFloat(event.target.value) > 0 ? event.target.value : 1;
      });
    setDetailsList(detailsList);
    props.updatedJuuryouList(detailsList);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              juuryouMstData?.filter((i) => !i.isOutOfRange)?.length > 8
                ? '100%'
                : juuryouMstData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr className="tbl-header" style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={callJuuryouMstData} style={{ pointerEvents: 'auto' }}>
                        重量
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>重量</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {juuryouMstData
              ?.filter((item) => !item.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}kg
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {'重量係数'}
            </td>
            {detailsList
              ?.filter((item) => !item.isOutOfRange)
              ?.map((row, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={editMode ? row.value : toDecimal(row.value, 2)}
                    onChange={(e) => listDataChange(e, row.no)}
                    onBlur={(e) => listDataChangeOnBlur(e, row.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
          </tr>
        </Table>
      </div>

      {/* 重量 */}
      {isJuuryou
        ? commonModal(
            isJuuryou,
            addJuuryouModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            juuryouMstContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default ProductSize;
