/**
 * クラス名：クラウド用PMXファイル読み込むの親部品情報タブ
 * 説明：クラウドにあるPMXファイルを読み込んで子部品情報やファイル情報を表示・複数ファイル選択操作を行うJSである。
 * 作成者：ナンス
 * 作成日：2023/07/24
 * バージョン：1.0
 */
import { Button, Row, Input, Col, Image, Table, DatePicker, Tree, Space, Spin, Checkbox, Select } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';

import '../../assets/styles/common.css';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import {
  estimateInitInfo,
  formatDate,
  formatDateString,
  formatPMXDate,
  getDetails,
  getInitMaterialPatternsInfo,
  getInitMaterialSizeInfo,
  getProcessRecord,
  initAdditionalItemCost,
  initAdditionalItemDate,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  toDecimal,
  todayTime,
} from './Common';
import { commonModal } from './CommonModal';
import { GetParametersByCompId, getCardDetailImg, getCardInfo } from './CommonAPI';
import {
  ClientType,
  DataType,
  EstimateCreationType,
  PatternType,
  ServiceClass,
  SurfaceProtectionType,
  WorkType,
} from './enums';
import { byQuantities, dataTypeList } from './Constant';

const PMX_CloudFileDialog_Tab_Parent = React.forwardRef((props, ref) => {
  const [cloudPMXFileLst, setCloudPMXFileLst] = useState([]);
  const [cloudPMXFileLstBK, setCloudPMXFileLstBK] = useState([]);
  const [cloudPMXIQ3FileLst, setCloudPMXIQ3FileLst] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [iq3SelectedRowKeys, setIQ3SelectedRowKeys] = useState([]);

  const [searchFileNm, setSearchFileNm] = useState('');
  const [searchUpdatedStart, setSearchUpdatedStart] = useState('');
  const [searchUpdatedEnd, setSearchUpdatedEnd] = useState('');
  const [searchCustomer, setSearchCustomer] = useState('');
  const [searchDrawingNo, setSearchDrawingNo] = useState('');
  const [searchModifier, setSearchModifier] = useState('');
  const [isImageMaxDisplay, setIsImageMaxDisplay] = useState(false);

  const [isPMXCloudFileDialogOpen, setIsPMXCloudFileDialogOpen] = useState(props.pmxModal);

  const [page, setPage] = useState(1);

  // IQX_WEBEST-246
  const [dataType, setDataType] = useState(3); // デフォルト値をAllに設定
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = ['100', '200', '300', '500', '1000'];
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountBK, setTotalCountBK] = useState(0);

  useEffect(() => {
    setIsPMXCloudFileDialogOpen(props.pmxModal);
    if (props.cardData) {
      //親部品
      setSearchFileNm('');
      setSearchUpdatedStart('');
      setSearchUpdatedEnd('');
      setCloudPMXFileLst(props.cardData);
      setCloudPMXFileLstBK(props.cardData);
      setSelectedData(props.cardData[0]);
      setSelectedRow(props.cardData[0]?.id);
      // IQX_WEBEST-155
      getPmMXImg(props.cardData[0]?.details);
      setSearchCustomer('');
      setSearchDrawingNo('');
      setSearchModifier('');
      setTotalCount(props?.cardDataTotalCount);
      setTotalCountBK(props?.cardDataTotalCount);
      // setSelectedRowKeys([1]);
    }
  }, [props.cardData]);

  useEffect(() => {
    getDataByFilter(); // 絞り込む検索
  }, [searchFileNm, searchUpdatedStart, searchUpdatedEnd, searchCustomer, searchDrawingNo, searchModifier, dataType]);

  const getPmMXImg = async (details) => {
    for (let i = 0; i < details?.length; i++) {
      if (details[i]?.thumbPath !== undefined) {
        let iq3PmxImg = await getCardDetailImg(details[i]?.id);
        if (iq3PmxImg !== undefined) {
          details[i].thumbPath = URL.createObjectURL(iq3PmxImg);
        } else {
          details[i].thumbPath = '';
        }
      }
    }
    setCloudPMXIQ3FileLst(details);
  };

  let checkedRow = [];

  const onRowCheckboxSelect = (checked, record) => {
    const checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    // const selectedRow = record.slice(-1);
    // setSelectedData(selectedRow[0]);
    // // setCloudPMXIQ3FileLst(selectedRow[0]?.parents[0].children);
    // const data = [...selectedDatas];
    // data.push(record);
    // setSelectedDatas(data);
    // props.selectedParentRows(data);
  };

  const onRowSelect = async (record) => {
    setSelectedRow(record.id);
    setSelectedData(record);
    // IQX_WEBEST-155
    for (let i = 0; i < record?.details?.length; i++) {
      if (record?.details[i]?.thumbPath !== undefined) {
        let iq3PmxImg = await getCardDetailImg(record?.details[i]?.id);
        if (iq3PmxImg !== undefined) {
          record.details[i].thumbPath = URL.createObjectURL(iq3PmxImg);
        } else {
          record.details[i].thumbPath = '';
        }
      }
    }
    setCloudPMXIQ3FileLst(record?.details);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onRowCheckboxSelect,
  };

  // ファイル名で絞り込む
  const getFileNmByFilter = (value) => {
    searchFileNm === value ? getDataByFilter() : setSearchFileNm(value);
  };

  // 取引先で絞り込む
  const getCustomerByFilter = (value) => {
    searchCustomer === value ? getDataByFilter() : setSearchCustomer(value);
  };

  // 親図番で絞り込む
  const getDrawingNoByFilter = (value) => {
    searchDrawingNo === value ? getDataByFilter() : setSearchDrawingNo(value);
  };

  // 担当者で絞り込む
  const getModifierByFilter = (value) => {
    searchModifier === value ? getDataByFilter() : setSearchModifier(value);
  };

  // 日付で絞り込む
  const getDateByFilter = (name, dateString) => {
    if (name == 0) {
      searchUpdatedStart === dateString ? getDataByFilter() : setSearchUpdatedStart(dateString);
    } else {
      searchUpdatedEnd === dateString ? getDataByFilter() : setSearchUpdatedEnd(dateString);
    }
  };

  // データタイプで絞り込む
  const getDateTypeByFilter = (value) => {
    dataType === value ? getDataByFilter() : setDataType(value);
  };

  // 絞り込む
  const getDataByFilter = async () => {
    // DBから検索
    setLoading(true); // Waitingダイアログを表示にする
    // IQX_WEBEST-246
    let pageBefore = page - 1;
    let startPage = pageBefore < 0 ? 0 : pageSize * pageBefore;
    let cardData = await getCardInfo(
      pageSize,
      startPage,
      searchCustomer,
      searchFileNm,
      searchDrawingNo,
      searchModifier,
      searchUpdatedStart,
      searchUpdatedEnd,
      dataType
    );
    if (cardData) {
      const cardDatas = cardData ? cardData?.data : [];
      const updatedData = cardDatas?.map((item) => ({
        ...item,
        modified: formatPMXDate(item.modified),
      }));
      setCloudPMXFileLst(updatedData);
      const cardDataTotalCount = cardData?.totalCount ? cardData?.totalCount : 0;
      setTotalCount(cardDataTotalCount);
    }
    setLoading(false); // Waitingダイアログを表示にする
    setPage(1);
  };

  const clearAllFilter = (e) => {
    setSearchFileNm('');
    setSearchUpdatedStart('');
    setSearchUpdatedEnd('');
    setSearchCustomer('');
    setSearchDrawingNo('');
    setSearchModifier('');
    setDataType(3);
    setCloudPMXFileLst(cloudPMXFileLstBK);
    setTotalCount(totalCountBK);
    // setCloudPMXIQ3FileLst(cloudPMXFileLstBK?.length > 0 ? cloudPMXFileLstBK[0].parents[0].children : []);
  };

  useImperativeHandle(
    ref,
    () => ({
      getSelectedParent: () => {
        return selectedData;
      },
    }),
    [selectedData]
  );

  const cloudFileParentColumns = [
    {
      id: '1',
      title: '取引先',
      dataIndex: 'customer',
      width: 150 + 'px',
    },
    {
      id: '2',
      title: '名称',
      dataIndex: 'name',
      width: 150 + 'px',
    },
    {
      id: '3',
      title: '親図番',
      dataIndex: 'drawingNo',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '担当者',
      dataIndex: 'modifier',
      width: 100 + 'px',
    },
    {
      id: '2',
      title: '更新日時',
      dataIndex: 'modified',
      width: 100 + 'px',
    },
  ];
  const cloudFileParentDetailColumns = [
    {
      id: '1',
      title: 'サムネイル',
      dataIndex: 'thumbPath',
      width: 150 + 'px',
      render: (value) => <Image src={value} width={150} preview={isImageMaxDisplay} />,
    },
    {
      id: '2',
      title: '種別',
      dataIndex: 'type',
      width: 100 + 'px',
    },
    {
      id: '3',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 150 + 'px',
    },
    {
      id: '4',
      title: '材質',
      dataIndex: 'material',
      width: 150 + 'px',
    },
    {
      id: '5',
      title: '板厚',
      dataIndex: 'thickness',
      width: 80 + 'px',
    },
    {
      id: '6',
      title: '員数',
      dataIndex: 'quantity',
      width: 60 + 'px',
    },
  ];

  const closePMXModal = () => {
    setIsPMXCloudFileDialogOpen(false);
  };
  const childModalTitle = (
    <div
      style={{
        width: 1800,
        // backgroundColor: '#005fab',
        // // marginLeft: 0,
        // height: 36,
        // marginTop: -20,
        // borderTopLeftRadius: 7,
        // borderTopRightRadius: 7,
        // fontSize: 14,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>PMX取り込み</p>
    </div>
  );
  const addData = () => {};
  // 板金見積データ作成
  const getNewIQ3EstimateInfo = (lists, parameters, settings) => {
    let userSettingData = settings.userSettings;
    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    let kouteiPatternList = patternLst?.filter((item) => item.id === userSettingData?.[0]?.users?.iq3ProcessPatternId);
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);
    }

    const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
    let materialTypeList = materialType?.filter((item) => item.info.isUsed);

    const material = parameters?.materialIq3; // 材料マスタデータ
    let materialList = material?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
    let materialSurfaceList = materialSurface?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.SheetMetal);
    let blankIsUsedData = [];
    processMaster?.filter((i) => {
      if (
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
      ) {
        blankIsUsedData.push(i.id);
        // return i.id;
      }
    });
    const filteredList2 = kouteiPatternList?.[0]?.details?.filter((item2) =>
      blankIsUsedData.some((item1) => item1 === item2.processId)
    );

    const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

    // 工程入力
    let processSelectDetails = kouteiPatternList?.[0]?.details;

    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';

        let data = {
          id: index + 1,
          processId: item.processId,
          details: getDetails(workType, parameters, processSelectDetails),
        };

        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';
        let dataItems = [];
        if (item.multiprocesses?.length > 0) {
          for (let m = 0; m < item.multiprocesses?.length; m++) {
            let dataItem = {
              id: m + 1,
              processName: processMstInfo?.name,
              processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            };
            dataItems.push(dataItem);
          }
        }

        let addition = {
          no: index + 1,
          processId: item.processId,
          dataType: DataType.Data,
          dataItems: dataItems,
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
        };

        return addition;
      });
    }

    // 材料詳細パターン情報を準備する
    let materialDetailPatterns = [];

    // 材料詳細サイズリスト情報を準備する
    let materialDetailSizes = [];
    let materialSizeIq3Infos = parameters?.materialSizeIq3;
    if (materialSizeIq3Infos) {
      materialDetailSizes = materialSizeIq3Infos
        ?.filter((item) => item.info.isUsed && item.name !== 'Free')
        ?.map((item, index) => {
          let data = getInitMaterialSizeInfo(index, item);
          materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, item);
          return data;
        });
    }

    // ブランクの場合
    let blankProcess = getProcessRecord(
      ServiceClass.SheetMetal,
      [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
      processMstData
    );
    // 板金工程選択にブランクを使用している場合、
    let usedBlankProcess = processSelectDetails?.find(
      (selectItem) =>
        selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
    );
    // 使用している装置の情報を取得する
    let usedDeviceInfo = parameters?.device?.find((device) => device.id === usedBlankProcess?.deviceId);
    let grabbingX = 0;
    let grabbingY = 0;
    let pierWidthX = 0;
    let pierWidthY = 0;
    let worlRangeX = 0;
    let worlRangeY = 0;
    // 装置がある場合、
    if (usedDeviceInfo) {
      // 装置から材料配置情報
      let materialArrange = usedDeviceInfo?.details?.materialArrange;
      grabbingX = materialArrange?.graspAllowanceX;
      grabbingY = materialArrange?.graspAllowanceY;
      pierWidthX = materialArrange?.xWidth;
      pierWidthY = materialArrange?.yWidth;
      worlRangeX = materialArrange?.workRangeX;
      worlRangeY = materialArrange?.workRangeY;
    }

    let data = JSON.parse(JSON.stringify(lists));
    const updatedList = data?.map((i, index) => {
      if (i?.id === 0) {
        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'Part_' + formatDateString(todayTime());
        i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.materialName = materialList?.length > 0 ? materialList[0]?.name : '';
        i.materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        i.materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
        i.materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
        i.materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
        i.thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.purchases = [];
        i.additions = kouteiCalArr;
        i.materialDetailPatterns = materialDetailPatterns;
        i.materialDetailSizes = materialDetailSizes;
        i.weight = 0;
        i.totalSurfaceArea = 0;
        i.partSizeX = 0;
        i.partSizeY = 0;
        i.partCount = 1;
        i.grabbingX = grabbingX;
        i.grabbingY = grabbingY;
        i.pierWidthX = pierWidthX;
        i.pierWidthY = pierWidthY;
        i.worlRangeX = worlRangeX;
        i.worlRangeY = worlRangeY;
        i.workCostUnitPrice = 0;
        i.workCostTotalPrice = 0;
        i.costUnitPrice = 0;
        i.costTotalPrice = 0;
        i.surfaceTotalPrice = 0;
        i.surfaceUnitPrice = 0;
        i.purchaseUnitPrice = 0;
        i.purchaseTotalPrice = 0;
        i.materialCostUnitPrice = 0;
        i.materialCostTotalPrice = 0;
      }
      return i;
    });
    return updatedList;
  };
  // 新規見積データ作成
  const getNewEstimateInfo = (newParameters, iq3Count) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    let costLst = [];
    let labelLst = [];
    let memoLst = [];
    let dateLst = [];
    let clientInfo = newParameters.client;
    let companyInfo = newParameters.company;
    let processInfo = newParameters.process;
    let deviceMasterInfo = newParameters.device;
    let processPattern = newParameters.processPattern;
    let estimateStatus = newParameters.estimateStatus;
    let purchaseAllocation = newParameters.purchaseAllocation;
    let purchaseCategoryInfo = newParameters.purchaseCategory;
    let purchasesData = newParameters.purchase;
    let staffList = newParameters.staff;
    let selectedStaff = staffList?.filter((i) => i?.info?.isUsed);
    let userSettingData = newParameters.userSettings;
    let companySettingData = newParameters.companySettings;
    //板金パラメータ情報
    let materialIq3 = newParameters.materialIq3;
    let materialSizeIq3 = newParameters.materialSizeIq3;
    let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
    let materialType = newParameters.materialType;
    let materialTypeIq3 = newParameters.materialTypeIq3;

    let clientData = clientInfo?.filter((i) => !i.isDeleted && i?.info?.isUsed);
    let client = clientData?.length > 0 ? clientData[0] : {};
    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo[0].coeffOrders?.filter((i) => !i.isDeleted && i.isUsed);
      deliDateCoeffs = companyInfo[0].coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      purCoeffs = companyInfo[0].coeffPurchases?.filter((i) => !i.isDeleted && i.isUsed);
      mngCoeffs = companyInfo[0].coeffManagements?.filter((i) => !i.isDeleted && i.isUsed);

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }
    let kouteiPatternList = processPattern?.filter(
      (item) => item.id === userSettingData?.[0]?.users?.parentProcessPatternId
    );

    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = processPattern?.filter((item) => item.class === ServiceClass.Parent);
    }

    let parameters = {
      company: companyInfo,
      client: clientInfo,
      process: processInfo,
      device: deviceMasterInfo,
      processPattern: processPattern,
      estimateStatus: estimateStatus,
      purchaseAllocation: purchaseAllocation,
      purchaseCategory: purchaseCategoryInfo,
      purchase: purchasesData,
      staff: staffList,
      materialIq3: materialIq3,
      materialSizeIq3: materialSizeIq3,
      materialSurfaceIq3: materialSurfaceIq3,
      materialType: materialType,
      materialTypeIq3: materialTypeIq3,
    };

    let settings = {
      userSettings: userSettingData,
      companySettings: companySettingData,
    };

    //親部品工程積算情報設定
    let kouteiCalArr = [];
    let processSelectDetails = kouteiPatternList?.[0]?.details;
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processInfoFilter = processInfo?.filter((i) => i.id === item.processId)?.[0] || [];
        let dataItems = [];
        if (item.multiprocesses?.length > 0) {
          for (let m = 0; m < item.multiprocesses?.length; m++) {
            let dataItem = {
              id: m + 1,
              processName: processInfoFilter?.name,
              processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            };
            dataItems.push(dataItem);
          }
        }

        let addition = {
          no: index + 1,
          processId: item?.processId,
          dataType: DataType.Data,
          dataItems: dataItems,
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
        };
        return addition;
      });
    }

    // 板金
    let iq3Data = [...iq3EstimateInitInfo];

    let newIQ3Data = getNewIQ3EstimateInfo(iq3Data, parameters, settings);

    let newIQ3 = JSON.parse(JSON.stringify(newIQ3Data));
    const aggregatedObject = newIQ3?.reduce((result, currentItem) => {
      return { ...result, ...currentItem };
    }, {});
    const iq3ItemList = [];

    for (let i = 1; i <= iq3Count; i++) {
      let aggregatedObjectIQ3 = JSON.parse(JSON.stringify(aggregatedObject));
      //  CardDetailsの行により、板金データ作成
      iq3ItemList.push({ ...aggregatedObjectIQ3, no: i });
    }
    return {
      ...estimateInitInfo,
      estimateProducts: {
        ...estimateInitInfo.estimateProducts,
        modifierId:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.staffId
            : selectedStaff?.length > 0
            ? selectedStaff[0].id
            : '',
        // modifier: staffList?.length > 0 ? staffList[0].label : '',
        modifier:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.nickName
            : selectedStaff?.length > 0
            ? selectedStaff?.[0]?.name
            : '',
        drawingNo: 'Product-' + formatDateString(todayTime()),
        name: 'Product-' + formatDateString(todayTime()),
        clientId: client ? client?.id : 0,
        clientName: client ? client?.name : '',
        coeffOrdersId: orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : undefined,
        coeffOrdersName: orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '',
        coeffDeliveryDatesId: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : undefined,
        coeffDeliveryDatesName: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '',
        coeffPurchasesId: purCoeffs?.length > 0 ? purCoeffs?.[0].id : undefined,
        coeffPurchasesName: purCoeffs?.length > 0 ? purCoeffs?.[0].name : '',
        coeffManagementsId: mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : undefined,
        coeffManagementsName: mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '',
        createrId:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.staffId
            : selectedStaff?.length > 0
            ? selectedStaff?.[0].id
            : '',
        // creater: staffList?.length > 0 ? staffList[0].label : '',
        creater:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.nickName
            : selectedStaff?.length > 0
            ? selectedStaff?.[0]?.name
            : '',
        estimateNo: formatDateString(todayTime()),
        estimateStatusId: estimateStatus?.length > 0 ? estimateStatus?.[0]?.id : '',
        estimateStatus: estimateStatus?.length > 0 ? estimateStatus?.[0]?.name : '',
        otherProduct: {
          adjustment: 0,
          adjustmentMethod: '+',
          coeffClient: client
            ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff)
            : toDecimal(0, 2),
          coeffOrders: orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff) : 0.0,
          coeffDeliveryDates: deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff) : 0.0,
          coeffPurchases: purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff) : 0.0,
          coeffManagements: mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff) : 0.0,
        },
        processSelect: {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.modified : dayjs().format(),
        },
        processInput: {
          surfaceProcessInputInfo: [],
          assenbleProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            purchaseInstTime: 0,
            assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
            autoExtract: 1, // IQX_WEBEST-179
          },
          inspectionProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
          packingProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
        },
        additions: kouteiCalArr,
        purchases: [],
        byQuantities: JSON.parse(JSON.stringify(byQuantities)),
        calcParameters: {
          settings: settings,
          parameters: parameters,
        },
      },
      epCustomCosts: costLst,
      epCustomDates: dateLst,
      epCustomLabels: labelLst,
      epCustomMemos: memoLst,
      esIq3Info: iq3ItemList,
    };
  };
  const getPMXData = async () => {
    if (selectedData?.details?.length > 0) {
      props?.loading(true); // Waitingダイアログを表示にする
      setIsPMXCloudFileDialogOpen(false);

      // 見積データ作成
      let newParameters = await GetParametersByCompId();
      selectedData?.details?.sort((a, b) => a.id - b.id); // IDで昇順ソート
      let newEstimateInfo = getNewEstimateInfo(newParameters, selectedData?.details?.length);
      props.pmxProcessPattern(true, newEstimateInfo, selectedData);
    } else {
      alert('板金データありません！');
    }
  };

  const setCurrentPage = (value) => {
    setPage(value);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  const addPMXCloudFileContent = (
    <>
      <Spin
        // spinning={cloudPMXFileLst.length === 0 && cloudPMXFileLstBK.length === 0 ? true : false}
        spinning={loading}
        tip="Loading..."
        size="large"
      >
        <Row>
          {/* IQX_WEBEST-155 */}
          <Col span={20}>
            <Space size="small">
              <Input
                id="customerNm"
                placeholder="取引先"
                className={'input-editable'}
                value={searchCustomer}
                allowClear
                onChange={(e) => setSearchCustomer(e.target.value)}
                onPressEnter={(e) => getCustomerByFilter(e.target.value)}
              />
              <Input
                id="fileNm"
                placeholder="名称"
                className={'input-editable'}
                value={searchFileNm}
                allowClear
                onChange={(e) => setSearchFileNm(e.target.value)}
                onPressEnter={(e) => getFileNmByFilter(e.target.value)}
              />
              <Input
                id="drawingNo"
                placeholder="親図番"
                className={'input-editable'}
                value={searchDrawingNo}
                allowClear
                onChange={(e) => setSearchDrawingNo(e.target.value)}
                onPressEnter={(e) => getDrawingNoByFilter(e.target.value)}
              />
              <Input
                id="modifier"
                placeholder="担当者"
                className={'input-editable'}
                value={searchModifier}
                allowClear
                onChange={(e) => setSearchModifier(e.target.value)}
                onPressEnter={(e) => getModifierByFilter(e.target.value)}
              />

              <DatePicker
                placeholder="更新日時"
                style={{ padding: 5, width: 183 }}
                className={'input-editable'}
                onChange={(name, dateString) => getDateByFilter(0, dateString)}
                value={searchUpdatedStart != '' ? dayjs(searchUpdatedStart, 'YYYY-MM-DD') : ''}
              />
              <label style={{ paddingTop: 3 }}>～</label>
              <DatePicker
                placeholder="更新日時"
                style={{ padding: 5, width: 183 }}
                className={'input-editable'}
                onChange={(name, dateString) => getDateByFilter(1, dateString)}
                value={searchUpdatedEnd != '' ? dayjs(searchUpdatedEnd, 'YYYY-MM-DD') : ''}
              />
              <Select
                placeholder="All"
                style={{ fontSize: '13.5px', width: 90 }}
                id="dataType"
                name="dataType"
                value={dataType}
                onChange={(e) => {
                  getDateTypeByFilter(e);
                }}
              >
                {dataTypeList?.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button style={{ marginLeft: 0 }} className="filterClearButton" onClick={(e) => clearAllFilter()}>
              <div style={{ display: 'flex' }}>
                <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
                <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
              </div>
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={12}>
            <Table
              id="parentCloudFileTbl"
              showSorterTooltip={false}
              rowClassName={(record) => (record.id == selectedRow ? 'active-row' : 'data-row')}
              columns={cloudFileParentColumns}
              rowKey={(record) => record.id}
              dataSource={cloudPMXFileLst}
              className="parent-cloud-file-tb"
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    onRowSelect(record);
                  },
                };
              }}
              pagination={{
                position: ['bottomLeft'],
                total: totalCount,
                showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                pageSize: pageSize,
                pageSizeOptions: pageSizes,
                showSizeChanger: true,
                onShowSizeChange: handlePageSizeChange,
                defaultCurrent: 1,
                current: page,
                onChange: async (page, pageSize) => {
                  setCurrentPage(page);
                  setLoading(true); // Waitingダイアログを表示にする
                  // IQX_WEBEST-246
                  let pageBefore = page - 1;
                  let cardData = await getCardInfo(
                    pageSize,
                    pageBefore,
                    searchCustomer,
                    searchFileNm,
                    searchDrawingNo,
                    searchModifier,
                    searchUpdatedStart,
                    searchUpdatedEnd,
                    dataType
                  );
                  if (cardData) {
                    const cardDatas = cardData ? cardData?.data : [];
                    const updatedData = cardDatas?.map((item) => ({
                      ...item,
                      modified: formatPMXDate(item.modified),
                    }));
                    setCloudPMXFileLst(updatedData);
                  }
                  setLoading(false); // Waitingダイアログを表示にする
                },
              }}
              scroll={{ y: 'calc(100vh-220px)', x: '950' }}
            />
          </Col>
          <Col span={12}>
            <Table
              id="parentCloudFileTbl"
              showSorterTooltip={false}
              columns={cloudFileParentDetailColumns}
              dataSource={cloudPMXIQ3FileLst}
              className="parent-cloud-file-iq3-tb"
              pagination={false}
              scroll={{ y: 'calc(100vh-220px)' }}
            />
            <Checkbox
              checked={isImageMaxDisplay}
              onChange={(e) => {
                setIsImageMaxDisplay(e.target.checked);
              }}
            >
              <label style={{ fontWeight: 600 }}>サムネイル拡大表示</label>
            </Checkbox>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: cloudPMXFileLst.length > 0 ? -30 : 10 }}>
          <Space>
            <Button className="mainButton" onClick={getPMXData}>
              OK
            </Button>
            <Button className="cancelButton" onClick={closePMXModal}>
              キャンセル
            </Button>
          </Space>
        </Row>
      </Spin>
    </>
  );
  return (
    <>
      {isPMXCloudFileDialogOpen
        ? commonModal(
            isPMXCloudFileDialogOpen,
            childModalTitle,
            null,
            null,
            1800,
            addData,
            closePMXModal,
            addPMXCloudFileContent,
            null,
            // { left: 180, top: 50 },
            false
          )
        : ''}
    </>
  );
});

export default PMX_CloudFileDialog_Tab_Parent;
