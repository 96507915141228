/**
 * クラス名：板金のブランクの送り速度画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input } from 'antd';
import { handleKeyPress } from '../../../common/Common';

const Device_Blank_OkuriSokudo = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [forwardSpeed, setForwardSpeed] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    setForwardSpeed(props?.detailsData?.feedSpeed);
  }, [props.editMode, props.detailsData]);

  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Col style={{ width: '20%' }}>
          <Table>
            <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
              <tr style={{ textAlign: 'center', height: 30 }}>
                <th className="tbl-header">送り速度</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Input
                    value={forwardSpeed}
                    onChange={(e) => {
                      setForwardSpeed(e.target.value);
                      props.updateForwardSpeed(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
});

export default Device_Blank_OkuriSokudo;
