/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Layout, Image, Button, Row, Col, Space, Input, Spin, Modal } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Table as RTable } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Header from './Header';
import iQ3_create from '../assets/images/iQ3_create.png';
import iQ5_create from '../assets/images/iQ5_create.png';
import iQ7_create from '../assets/images/iQ7_create.png';
import logout from '../assets/images/logout.png';
import parameter from '../assets/images/parameter.png';
import oya_list from '../assets/images/oya_list.png';
import ParentList from '../views/parent/ParentList';
import MainParameter from '../views/parameter/MainParameter';
import MainEstimate from '../views/common/MainEstimate';
import estimate from '../assets/images/estimate.png';
import {
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  confirmScreenChangeWarning,
  sameDrawingNoContent,
} from '../views/common/CommonModal';
import {
  todayTime,
  formatDateString,
  isCustomer,
  toDecimal,
  estimateInitInfo,
  initAdditionalItemCost,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  initAdditionalItemDate,
  updateEstimateInfoByReCal,
  getNameByValue,
} from '../views/common/Common';
import {
  logOutSubmit,
  createEstimateProductInfo,
  updateEstimateProductInfo,
  GetParametersByCompId,
  getExistUserSetting,
  getEstimateIq3Info,
  getIsExistSameEstimate,
  getExistCompanySetting,
} from '../views/common/CommonAPI';
import UserSetting from '../views/setting/UserSetting';
import { ClientType, DataType, ParamCalType, ServiceClass, initDispRoutes, initShowDisps } from '../views/common/enums';
import { byQuantities, saveSameTypes } from '../views/common/Constant';

const { Content, Sider } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  const [paramType, setParamType] = useState('');
  const [paramSelected, setParamSelected] = useState('');
  const [partNm, setPartNm] = useState('parentList');
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 画面切替警告メッセージポップアップ
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [navigatePageName, setNavigatePageName] = useState('');
  // 切替する画面の情報
  const [event, setEvent] = useState('');
  const [pageName, setPageName] = useState('');
  const [partName, setPartName] = useState('');
  const [routeType, setRouteType] = useState('');
  const [estimateEditMode, setEstimateEditMode] = useState(false);
  const [parentListClick, setParentListClick] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  const [curPartNm, setCurPartNm] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [processInfo, setProcessInfo] = useState([]);
  const [companySettingInfo, setCompanySettingInfo] = useState([]);
  const [userSettingInfo, setUserSettingInfo] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [newMode, setNewMode] = useState(false);
  const [initDisplayRun, setInitDisplayRun] = useState(false);
  const [isPMXEvent, setIsPMXEvent] = useState(false);

  const paramRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const mainEstimateRef = useRef();
  const parentListRef = useRef();
  const menuContent = useRef(null);
  const userSettingRef = useRef();

  // 別名保存の入力項目
  const [estimateNo, setEstimateNo] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [isSaveByOtherName, setIsSaveByOtherName] = useState(false);

  // 活性、非活性タブ
  const [activeTab, setActiveTab] = useState('');

  const [switchExecuted, setSwitchExecuted] = useState(false);

  const [isFromLogin, setIsFromLogin] = useState(false);

  const fromLogin = location?.state?.fromLogin;

  // Waitingダイアログの表示
  const [loading, setLoading] = useState(false);

  // 新規作成フラグ
  const [createNew, setCreateNew] = useState(false);

  // パラメータ新旧計算モード
  const [isReCal, setIsRecal] = useState(false);

  // 材料詳細の再計算モード
  const [isReCalMaterial, setIsRecalMaterial] = useState(false);

  const [reCalBtnEvent, setReCalBtnEvent] = useState(false);

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品」
  const [parentActiveTabKey, setParentActiveTabKey] = useState('');

  const [isMenuClickByUserSetting, setIsMenuClickByUserSetting] = useState(false);

  const [isNewModeByParent, setIsNewModeByParent] = useState(false);

  const [selectedEstimateDataByParentLst, setSelectedEstimateDataByParentLst] = useState([]);

  // 同一図番で保存
  const [isSaveSameDrawingNo, setIsSaveSameDrawingNo] = useState(false);
  const [saveSameDrawingNoFncName, setSaveSameDrawingNoFncName] = useState('');

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品、材料詳細」
  const [iq3ActiveTabKey, setIq3ActiveTabKey] = useState('1');

  useEffect(() => {
    // 親部品リストのページリロードの場合
    if (location?.pathname === initDispRoutes[0]) {
      setParentListClick(true);
      navigate(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsFromLogin(fromLogin);
    if (fromLogin) {
      initDisplay();
    }
  }, [fromLogin]);

  const updatePMXEvent = () => {
    setIsPMXEvent(false);
  };

  const initDisplay = async () => {
    setLoading(true);
    await getBackendPrepareData([], true, true, true, true);
    switch (location.pathname) {
      case initDispRoutes[0]:
        setInitDisplayRun(true);
        setParentListClick(true);
        navigate(location.pathname);
        return (
          <ParentList
            ref={parentListRef}
            updateEstimateEditMode={updateEstimateEditMode}
            getSelectedEstimateData={getSelectedEstimateData}
            editMode={estimateEditMode}
            parentListClick={parentListClick}
            orderOptions={orderOptions}
            companyInfo={companyInfo}
            clientInfo={clientInfo}
            userInfo={userInfo}
            staffInfo={staffList}
            processInfo={processInfo}
            companySettingInfo={companySettingInfo}
            purchaseCategoryInfo={purchaseCategoryInfo}
            loading={updateLoading}
          />
        );
      case initDispRoutes[1]:
        setActiveTab('1');
        navigatePage('', ':estimate.parent', 'parent');
        break;
      case initDispRoutes[2]:
        setActiveTab('2');
        navigatePage('', ':estimate.iq3', 'iq3');
        break;

      default:
        return (
          <MainParameter
            paramType={paramType}
            paramNm={paramSelected}
            updateParamSelected={updateParamSelected}
            companySettingInfo={companySettingInfo}
            userSettingInfo={userSettingInfo}
            ref={paramRef}
          />
        );
    }
  };

  const getBackendPrepareData = async (
    currentEstimateData,
    isMaterialParam = true,
    isProcessParam = true,
    isPurchaseParam = true,
    isOtherParam = true
  ) => {
    let updateEstimateData = currentEstimateData;
    let newParameters = await GetParametersByCompId();
    if (newParameters) {
      if (isOtherParam) {
        getProjectStatusData(newParameters.estimateStatus);
        getLoginCompanyInfo(newParameters.company);
        getStaffListInfo(newParameters.staff);
        getItakusakiInfo(newParameters.client);
        getLoginUserInfo(newParameters.currentUserInfo);
        getCompanySettingInfo(newParameters.companySettings);
        getUserSettingInfo(newParameters.userSettings);
      }
      if (isProcessParam) getProcessMstInfo(newParameters.process);
      if (isPurchaseParam) getPurchaseCategoryInfo(newParameters.purchaseCategory);
      if (updateEstimateData != undefined && updateEstimateData?.estimateProducts) {
        if (isOtherParam) {
          // その他全て（係数、カスタマイズ・・・）
          updateEstimateData.estimateProducts.calcParameters.parameters.company = newParameters.company;
          updateEstimateData.estimateProducts.calcParameters.parameters.staff = newParameters.staff;
          updateEstimateData.estimateProducts.calcParameters.parameters.client = newParameters.client;
          updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = newParameters.estimateStatus;
          updateEstimateData.estimateProducts.calcParameters.settings.userSettings = newParameters.userSettings;
          updateEstimateData.estimateProducts.calcParameters.settings.companySettings = newParameters.companySettings;
        }
        if (isProcessParam) {
          // 加工系（工程マスター、装置設定、工程パターン）
          updateEstimateData.estimateProducts.calcParameters.parameters.process = newParameters.process;
          updateEstimateData.estimateProducts.calcParameters.parameters.processPattern = newParameters.processPattern;
          updateEstimateData.estimateProducts.calcParameters.parameters.device = newParameters.device;
          let processSelect = newParameters.processPattern?.filter(
            (item) => item.id === updateEstimateData.estimateProducts.processSelect?.id
          );
          if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
        }
        if (isPurchaseParam) {
          // 購入品
          updateEstimateData.estimateProducts.calcParameters.parameters.purchase = newParameters.purchase;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
            newParameters.purchaseCategory;
          updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
            newParameters.purchaseAllocation;
        }
        if (isMaterialParam) {
          // 材料系（材質区分、材質名称、材料名称・重量単価）
          // 板金パラメータ情報
          updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = newParameters.materialIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 = newParameters.materialSizeIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
            newParameters.materialSurfaceIq3;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialType = newParameters.materialType;
          updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 = newParameters.materialTypeIq3;
        }
        setSelectedEstimateData(updateEstimateData);
      }
    }
  };

  useEffect(() => {
    if (reCalBtnEvent) {
      setTimeout(() => {
        // IQX_WEBEST-171
        setLoading(false); // Waitingダイアログを非表示にする
        let calEstimateData = updateEstimateInfoByReCal(selectedEstimateData);
        //親部品の基本情報更新
        mainEstimateRef?.current
          ?.getParentEstimateRef()
          ?.current?.getParentEstimateDetailRef()
          ?.current?.updateParentEstimateDetail(calEstimateData);
        mainEstimateRef?.current?.updateEstimateInfo(calEstimateData);
        setSelectedEstimateData(calEstimateData);
        setReCalBtnEvent(false);
        setIsRecal(false);
      }, 100);
    }
  }, [reCalBtnEvent]);

  const settingParametersByReCal = async (
    mode,
    isMaterialParam = true,
    isProcessParam = true,
    isPurchaseParam = true,
    isOtherParam = true
  ) => {
    setLoading(true); // Waitingダイアログを表示にする
    let updateEstimateData = selectedEstimateData;
    if (updateEstimateData != undefined && updateEstimateData?.estimateProducts?.calcParameters) {
      if (mode == ParamCalType.NewParam) {
        await getBackendPrepareData(updateEstimateData, isMaterialParam, isProcessParam, isPurchaseParam, isOtherParam);
      } else {
        // パネルの再計算ボタンを押した場合、CURT.パラで再計算
        let oldParameters = updateEstimateData?.estimateProducts?.calcParameters?.parameters;
        let oldSettings = updateEstimateData?.estimateProducts?.calcParameters?.settings;
        if (oldParameters) {
          getProjectStatusData(oldParameters.estimateStatus);
          getLoginCompanyInfo(oldParameters.company);
          getStaffListInfo(oldParameters.staff);
          getItakusakiInfo(oldParameters.client);
          getProcessMstInfo(oldParameters.process);
          if (oldSettings) {
            getCompanySettingInfo(oldSettings?.companySettings);
            getUserSettingInfo(oldSettings?.userSettings);
          }
          getPurchaseCategoryInfo(oldParameters.purchaseCategory);
          if (updateEstimateData) {
            updateEstimateData.estimateProducts.calcParameters.parameters.company = oldParameters.company;
            updateEstimateData.estimateProducts.calcParameters.parameters.staff = oldParameters.staff;
            updateEstimateData.estimateProducts.calcParameters.parameters.client = oldParameters.client;
            updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = oldParameters.estimateStatus;
            updateEstimateData.estimateProducts.calcParameters.parameters.process = oldParameters.process;
            updateEstimateData.estimateProducts.calcParameters.parameters.device = oldParameters.device;
            let processSelect = oldParameters.processPattern?.filter(
              (item) => item.id === updateEstimateData.estimateProducts.processSelect[0]?.id
            );
            if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
            updateEstimateData.estimateProducts.calcParameters.parameters.purchase = oldParameters.purchase;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
              oldParameters.purchaseCategory;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
              oldParameters.purchaseAllocation;
            updateEstimateData.estimateProducts.calcParameters.settings.userSettings = oldSettings?.userSettings;
            updateEstimateData.estimateProducts.calcParameters.settings.companySettings = oldSettings?.companySettings;
            //板金パラメータ情報
            updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = oldParameters.materialIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 =
              oldParameters.materialSizeIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
              oldParameters.materialSurfaceIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialType = oldParameters.materialType;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 =
              oldParameters.materialTypeIq3;
            setSelectedEstimateData(updateEstimateData);
          }
        }
      }
      setIsRecal(true);
      setReCalBtnEvent(true);
    }
  };

  // 再計算フラグを更新
  const updateIsRecal = (result) => {
    setIsRecal(result);
  };

  // 材料詳細の再計算フラグを更新
  const updateIsRecalMaterial = (result) => {
    setIsRecalMaterial(result);
  };

  const getItakusakiInfo = (result) => {
    result = result?.filter((record) => isCustomer(record.clientType) && record.info.isUsed);
    if (result) setClientInfo(result?.length > 0 ? result : []);
  };

  // 案件状態データ
  const getProjectStatusData = (result) => {
    setOrderOptions(result?.filter((item) => item.info.isUsed));
  };

  // ログインしているユーザーの会社情報を取得する
  const getLoginCompanyInfo = (result) => {
    setCompanyInfo(result);
  };

  // 担当者情報を取得する
  const getStaffListInfo = (result) => {
    setStaffList(result?.filter((item) => item.info.isUsed));
  };

  // ユーザー情報を取得する
  const getLoginUserInfo = (result) => {
    setUserInfo(result);
  };

  // 工程マスタの情報を取得する
  const getProcessMstInfo = (result) => {
    setProcessInfo(result);
  };

  const getCompanySettingInfo = async (result) => {
    if (result === '') {
      setCompanySettingInfo(await getExistCompanySetting());
    } else {
      setCompanySettingInfo(result);
    }
  };

  const getUserSettingInfo = async (result) => {
    if (result === '') {
      setUserSettingInfo(await getExistUserSetting());
    } else {
      setUserSettingInfo(result);
    }
  };

  const getPurchaseCategoryInfo = (result) => {
    setPurchaseCategoryInfo(result);
  };

  const updateParamSelected = (param) => {
    setParamType(param);
    let textMenu;
    switch (param) {
      case '共通':
        textMenu = '・共通';
        break;
      case 'parent':
        textMenu = '・親部品';
        break;
      case 'iq3':
        textMenu = '・iQ3';
        break;
    }
    if (param === '共通') {
      setParamType('common');
    }
    let menus = menuContent?.current?.menu?.list?.children;
    let ul = menuContent?.current?.menu?.list;
    if (ul.classList.contains('paramMenu')) {
      ul.classList?.remove('paramMenu');
      ul.classList.add('paramMenu-active');
    }
    [...menus]?.forEach((menu) => {
      if (menu.innerText?.includes(textMenu)) {
        menu.classList.add('ant-menu-submenu-active');
        menu.classList.add('ant-menu-submenu-selected');
      } else {
        menu.classList?.remove('ant-menu-submenu-active');
        menu.classList?.remove('ant-menu-submenu-selected');
      }
    });
  };

  //　ログアウトボタンの押下
  const handleClick = async (e) => {
    e.preventDefault();
    const result = await logOutSubmit();
    if (result) {
      navigate('/login');
    }
  };

  // 画面切り替えする
  const discardConfirmOk = () => {
    setEstimateEditMode(false);
    setEditModeCancelConfirm(false);

    if (routeType === 1) {
      navigatePage(event, pageName, partName);
    } else if (routeType === 2) {
      paramChangeEvent(event);
    } else {
      handleClick(event);
    }
  };

  // 画面切り替えしない
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    // キャンセルボタン押下でスピナー画面を閉じる
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // 編集中の場合、画面切替警告メッセージポップアップを表示
  const isEditingMode = (e, type, page, part) => {
    setNavigatePageName('customer');
    setEvent(e);
    setRouteType(type);
    setPageName(page);
    setPartName(part);
    if (estimateEditMode || paramRef?.current?.getCurrentEditMode()) {
      let edtModeCancel =
        userSettingInfo[0]?.massages?.moveInEdit === undefined ? true : userSettingInfo[0]?.massages?.moveInEdit;
      setEditModeCancelConfirm(edtModeCancel);
      if (!edtModeCancel) {
        return false;
      }
      return true;
    }
    return false;
  };

  // 見積画面から、画面切り替えの場合
  const changeScreenEvent = async (e, page) => {
    setLoading(true); // Waitingダイアログを表示にする
    setNavigatePageName(page);
    await getUserSettingInfo('');
    await getCompanySettingInfo('');
    setIsMenuClickByUserSetting(false);
    setParentListClick(false);
    setIsNewModeByParent(false);
    if (location.pathname === '/home/:estimate.parent' || location.pathname === '/home/:estimate.iq3') {
      setNavigatePageName(page);
      if (page === 'parentList') {
        setParentListClick(true);
        setActiveTab('1');
        navigatePage(e, ':parentList', 'parentList');
      } else if (page === 'parent') {
        setLoading(false); // (IQX_WEBEST-222)メニューの見積データを選択中で、見積データを押下した場合、Waitingダイアログを非表示にする
        setActiveTab('1');
        navigatePage(e, ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        setLoading(false); // (IQX_WEBEST-222)メニューの見積データを選択中で、見積データを押下した場合、Waitingダイアログを非表示にする
        setActiveTab('2');
        navigatePage(e, ':estimate.iq3', 'iq3');
      } else {
        // setActiveTab('1');  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
        paramChangeEvent();
        setLoading(false); // Waitingダイアログを非表示にする
      }
    } else if (
      location.pathname.indexOf('/home/:common.') > -1 ||
      location.pathname.indexOf('/home/:parent.') > -1 ||
      location.pathname.indexOf('/home/:iq3.') > -1
    ) {
      setNavigatePageName(page);
      if (page === 'parentList') {
        if (!isEditingMode(e, 1, ':parentList', 'parentList')) {
          setParentListClick(true);
          setActiveTab('1');
          navigatePage(e, ':parentList', 'parentList');
        }
        // !isEditingMode(e, 1, ':parentList', 'parentList');
      } else if (page === 'parent') {
        if (!isEditingMode(e, 1, ':estimate.parent', 'parent')) {
          // setActiveTab('1');  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
          navigatePage(e, ':estimate.parent', 'parent');
        }
      } else if (page === 'sheetMetal') {
        if (!isEditingMode(e, 1, ':estimate.iq3', 'iq3')) {
          setActiveTab('2');
          navigatePage(e, ':estimate.iq3', 'iq3');
        }
      } else {
        !isEditingMode(e, 2, '', '');
        setLoading(false); // Waitingダイアログを非表示にする
      }
    } else if (location.pathname === '/home/:userSetting') {
      setIsMenuClickByUserSetting(false);
      //ユーザー設定データに変更があった場合のみ表示
      let diff = userSettingRef?.current?.isChangeSetting();
      if (diff) {
        if (Object.keys(diff).length > 0) {
          setActiveTab('1');
          setIsMenuClickByUserSetting(true);
          userSettingRef?.current?.updateSaveDiscardConfirmPopup();
        } else {
          if (page === 'parentList') {
            setParentListClick(true);
            setActiveTab('1');
            navigatePage(e, ':parentList', 'parentList');
          } else if (page === 'parent') {
            setActiveTab('1');
            navigatePage(e, ':estimate.parent', 'parent');
          } else if (page === 'sheetMetal') {
            setActiveTab('2');
            navigatePage(e, ':estimate.iq3', 'iq3');
          } else {
            setActiveTab('1');
            paramChangeEvent();
          }
        }
      } else {
        if (page === 'parentList') {
          setParentListClick(true);
          setActiveTab('1');
          navigatePage(e, ':parentList', 'parentList');
        } else if (page === 'parent') {
          setActiveTab('1');
          navigatePage(e, ':estimate.parent', 'parent');
        } else if (page === 'sheetMetal') {
          setActiveTab('2');
          navigatePage(e, ':estimate.iq3', 'iq3');
        } else {
          setActiveTab('1');
          paramChangeEvent();
        }
      }
    } else {
      if (page === 'parentList') {
        setLoading(false); // (IQX_WEBEST-222)メニューの親部品リストを選択中で、親部品リストを押下した場合、Waitingダイアログを非表示にする
        setParentListClick(true);
        setActiveTab('1');
        navigatePage(e, ':parentList', 'parentList');
      } else if (page === 'parent') {
        setActiveTab('1');
        navigatePage(e, ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        setActiveTab('2');
        navigatePage(e, ':estimate.iq3', 'iq3');
      } else {
        setActiveTab('1');
        paramChangeEvent();
      }
    }
  };

  const paramChangeEvent = async (e) => {
    setParamSelected('customer');
    await getUserSettingInfo('');
    await getCompanySettingInfo('');
    navigate(':common.customer');
    setIsMenuClickByUserSetting(false);
    setLoading(false); // Waitingダイアログを非表示にする
  };

  const navigatePage = async (e, pageNm, partNm) => {
    setParamType('');
    setParamSelected('');
    setIsMenuClickByUserSetting(false);
    if (partNm === 'parent' || partNm === 'iq3') {
      setCurPartNm(partNm);
      await goToParentScreen(pageNm, partNm);
    } else if (partNm === 'parentList') {
      await getBackendPrepareData([], true, true, true, true);
      setPartNm(partNm);
      navigate(pageNm);
    }
  };

  // サイドメニューの親部品作成ボタン押下で作成画面に遷移する
  const goToParentScreen = async (pageNm, partNm) => {
    // カレント見積がない場合、新規情報を設定して親部品画面に移動
    if (selectedEstimateData === undefined || selectedEstimateData?.length === 0 || isNewModeByParent) {
      let newParameters = await GetParametersByCompId();
      let newParentInfo = getNewParentInfo(partNm, newParameters);
      setSelectedEstimateData(newParentInfo);
      setNewMode(true);
      setCreateNew(true);
    }
    setPartNm(partNm);
    navigate(pageNm);
    // setParentActiveTabKey('1'); // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
  };

  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  const updateNewMode = (mode) => {
    setNewMode(mode);
  };

  const updateNewModeByParent = (mode) => {
    setParentListClick(false);
    // "新規作成"コマンドを開いた時にメッセージを表示
    //見積データに変更があった場合のみ表示
    setIsNewModeByParent(true);
    setSaveDiscardConfirmPopup(true);
  };

  // 親部品リストから呼び出した場合
  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setParentActiveTabKey('1');
    setParentListClick(false);
    setIsNewModeByParent(false);
    setActiveTab('1');
    setEstimateEditMode(estimateEditMode);
    //　呼び出した見積が現在開いている見積と同じかチェック
    if (estimateData?.estimateProducts?.id !== selectedEstimateData?.estimateProducts?.id) {
      //　他の見積データを開いたときのメッセージ表示
      setSaveDiscardConfirmPopup(true);
      setSelectedEstimateDataByParentLst(estimateData);
    } else {
      // 同じ見積データを開いた時にはメッセージを表示されず、現在見積に上書きして表示。
      setSelectedEstimateData(estimateData);
      setPartNm('parent');
      navigate(':estimate.parent');
      setParentActiveTabKey('1');
    }
  };

  const updateEstimateEditMode = (mode) => {
    setEstimateEditMode(mode);
  };

  const updateSelectedEstimateData = (result) => {
    setSelectedEstimateData(result);
  };

  const updateSelectedEstimateDataByPMX = (result) => {
    setSelectedEstimateData(result);
    setIsPMXEvent(true);
  };

  // Waitingダイアログを表示・非表示に更新する
  const updateLoading = (load) => {
    setLoading(load);
  };

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品」に更新する
  const updateParentActiveTabKey = (key) => {
    setParentActiveTabKey(key);
  };

  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応-START
  // アクティブなTabキー「親部品、板金」に更新する
  const updateActiveTabKey = (key) => {
    setActiveTab(key);
  };

  // アクティブなTabキー「基本情報、工程選択、工程入力、工程積算、購入品、材料詳細」に更新する
  const updateIq3ActiveTabKey = (key) => {
    setIq3ActiveTabKey(key);
  };
  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応-END

  // 親部品見積新規作成の初期値を作成する
  const getNewParentInfo = (partNm, newParameters) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    let costLst = [];
    let labelLst = [];
    let memoLst = [];
    let dateLst = [];
    let clientInfo = newParameters.client;
    let companyInfo = newParameters.company;
    let processInfo = newParameters.process;
    let deviceMasterInfo = newParameters.device;
    let processPattern = newParameters.processPattern;
    let estimateStatus = newParameters.estimateStatus;
    let purchaseAllocation = newParameters.purchaseAllocation;
    let purchaseCategoryInfo = newParameters.purchaseCategory;
    let purchasesData = newParameters.purchase;
    let staffList = newParameters.staff;
    let selectedStaff = staffList?.filter((i) => i?.info?.isUsed);
    let userSettingData = newParameters.userSettings;
    let companySettingData = newParameters.companySettings;
    //板金パラメータ情報
    let materialIq3 = newParameters.materialIq3;
    let materialSizeIq3 = newParameters.materialSizeIq3;
    let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
    let materialType = newParameters.materialType;
    let materialTypeIq3 = newParameters.materialTypeIq3;

    let clientData = clientInfo?.filter((i) => !i.isDeleted && i?.info?.isUsed);
    let client = clientData?.length > 0 ? clientData[0] : {};
    if (companyInfo?.length > 0) {
      // orderCoeffs = companyInfo[0].coeffOrders;
      orderCoeffs = companyInfo?.[0]?.coeffOrders?.filter((i) => !i.isDeleted && i.isUsed);
      deliDateCoeffs = companyInfo?.[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      purCoeffs = companyInfo?.[0]?.coeffPurchases?.filter((i) => !i.isDeleted && i.isUsed);
      mngCoeffs = companyInfo?.[0]?.coeffManagements?.filter((i) => !i.isDeleted && i.isUsed);

      costLst = companyInfo?.[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo?.[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo?.[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo?.[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }
    let kouteiPatternList = processPattern?.filter(
      (item) => item.id === userSettingData?.[0]?.users?.parentProcessPatternId
    );
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = processPattern?.filter((item) => item.class === ServiceClass.Parent);
    }
    let parameters = {
      company: companyInfo,
      client: clientInfo,
      process: processInfo,
      device: deviceMasterInfo,
      processPattern: processPattern,
      estimateStatus: estimateStatus,
      purchaseAllocation: purchaseAllocation,
      purchaseCategory: purchaseCategoryInfo,
      purchase: purchasesData,
      staff: staffList,
      materialIq3: materialIq3,
      materialSizeIq3: materialSizeIq3,
      materialSurfaceIq3: materialSurfaceIq3,
      materialType: materialType,
      materialTypeIq3: materialTypeIq3,
    };

    let settings = {
      userSettings: userSettingData,
      companySettings: companySettingData,
    };

    //親部品工程積算情報設定
    let kouteiCalArr = [];
    let processSelectDetails = kouteiPatternList?.[0]?.details;
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processInfoFilter = processInfo?.filter((i) => i.id === item.processId)?.[0] || [];
        let dataItems = [];
        if (item.multiprocesses?.length > 0) {
          for (let m = 0; m < item.multiprocesses?.length; m++) {
            let dataItem = {
              id: m + 1,
              processName: processInfoFilter?.name,
              processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            };
            dataItems.push(dataItem);
          }
        }

        let addition = {
          no: index + 1,
          processId: item?.processId,
          dataType: DataType.Data,
          dataItems: dataItems,
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
          editDataFlg: false,
        };
        return addition;
      });
    }

    return {
      ...estimateInitInfo,
      estimateProducts: {
        ...estimateInitInfo.estimateProducts,
        modifierId:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.staffId
            : selectedStaff?.length > 0
            ? selectedStaff?.[0]?.id
            : '',
        // modifier: staffList?.length > 0 ? staffList[0].label : '',
        modifier:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.nickName
            : selectedStaff?.length > 0
            ? selectedStaff?.[0]?.name
            : '',
        drawingNo: 'Product-' + formatDateString(todayTime()),
        name: 'Product-' + formatDateString(todayTime()),
        clientId: client ? client?.id : 0,
        clientName: client ? client?.name : '',
        clientCode: client ? client?.code : '',
        coeffOrdersId: orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : undefined,
        coeffOrdersName: orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '',
        coeffDeliveryDatesId: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : undefined,
        coeffDeliveryDatesName: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '',
        coeffPurchasesId: purCoeffs?.length > 0 ? purCoeffs?.[0].id : undefined,
        coeffPurchasesName: purCoeffs?.length > 0 ? purCoeffs?.[0].name : '',
        coeffManagementsId: mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : undefined,
        coeffManagementsName: mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '',
        createrId:
          userSettingData?.length > 0
            ? userSettingData?.[0]?.users?.staffId
            : selectedStaff?.length > 0
            ? selectedStaff?.[0]?.id
            : '',
        // creater: staffList?.length > 0 ? staffList[0].label : '',
        creater:
          userSettingData?.length > 0
            ? userSettingData[0]?.users?.nickName
            : selectedStaff?.length > 0
            ? selectedStaff[0]?.name
            : '',
        estimateNo: formatDateString(todayTime()),
        estimateStatusId: estimateStatus?.length > 0 ? estimateStatus?.[0].id : '',
        estimateStatus: estimateStatus?.length > 0 ? estimateStatus?.[0].name : '',
        otherProduct: {
          adjustment: 0,
          adjustmentMethod: '+',
          coeffClient: client
            ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff, 2)
            : toDecimal(0, 2),
          coeffOrders: orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff, 2) : toDecimal(0, 2),
          coeffDeliveryDates: deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff, 2) : toDecimal(0, 2),
          coeffPurchases: purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff, 2) : toDecimal(0, 2),
          coeffManagements: mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff, 2) : toDecimal(0, 2),
        },
        processSelect: {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList?.[0]?.modified : dayjs().format(),
        },
        processInput: {
          surfaceProcessInputInfo: [],
          assenbleProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            purchaseInstTime: 0,
            assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
            autoExtract: 1, // IQX_WEBEST-179
          },
          inspectionProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
          packingProcessInputInfo: {
            no: 1,
            calcType: 1,
            smPartsCount: 1,
            autoExtract: 1, // IQX_WEBEST-179
          },
        },
        additions: kouteiCalArr,
        purchases: [],
        byQuantities: JSON.parse(JSON.stringify(byQuantities)),
        calcParameters: {
          settings: settings,
          parameters: parameters,
        },
      },
      epCustomCosts: costLst,
      epCustomDates: dateLst,
      epCustomLabels: labelLst,
      epCustomMemos: memoLst,
    };
  };

  const cancelOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  const closeOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  // 画面切り替え、確認メッセージの保存ボタン押下
  const saveEstimateData = async () => {
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }

    // 親部品&板金
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = parentEstimateData?.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveEstimateData');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let createdData = await createEstimateProductInfo(parentEstimateData, false);
    if (createdData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ保存は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // Routeを変更する
  const onRouteChange = async () => {
    setLoading(true);
    if (isNewModeByParent) {
      // 新規コマンドの場合、初期データ作成と設定
      let newParameters = await GetParametersByCompId();
      let newParentInfo = getNewParentInfo(partNm, newParameters);
      setSelectedEstimateData(newParentInfo);
      setNewMode(true);
      setCreateNew(true);
      setActiveTab('1'); // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
    } else {
      // 親部品リストから呼び出した場合、選択したデータを設定
      if (selectedEstimateDataByParentLst) {
        setSelectedEstimateData(selectedEstimateDataByParentLst);
      }
    }
    setPartNm(partNm);
    navigate(':estimate.parent');
    setParentActiveTabKey('1');
  };

  const updateEstimateData = async () => {
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }
    // 親部品
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = parentEstimateData?.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('updateEstimateData');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let updatedData = await updateEstimateProductInfo(parentEstimateData, false);
    if (updatedData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ更新は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  const discardEstimateData = async (e) => {
    setSaveDiscardConfirmPopup(false);
    onRouteChange();
  };

  // 別名保存の入力フォーム
  const saveEstimateDataByOtherName = () => {
    setIsSaveByOtherName(true);
    // setDrawingNo(formatDateString(todayTime()));
    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
    const data = JSON.parse(JSON.stringify(parentEstimateData));
    setEstimateNo(data?.estimateProducts?.estimateNo);
    setDrawingNo(data?.estimateProducts?.drawingNo);
    setParentEstimateName(formatDateString(todayTime()));
  };

  // 別名保存入力ボックスのOKボタン押下
  const saveByOtherNameOk = async () => {
    // 親部品
    setIsSaveByOtherName(false);
    setSaveDiscardConfirmPopup(false);
    setLoading(true); // Waitingダイアログを表示にする
    let validation = mainEstimateRef?.current?.callFormValidation();
    if (!validation) {
      setLoading(false); // Waitingダイアログを非表示にする
      return;
    }

    let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
    const copyData = JSON.parse(JSON.stringify(parentEstimateData));

    copyData.estimateProducts = {
      ...copyData.estimateProducts,
      id: 0,
      estimateNo: estimateNo,
      drawingNo: drawingNo,
      name: parentEstimateName,
    };

    // 同一図番があるか存在チェックする
    if (!isSaveSameDrawingNo && !companySettingInfo?.[0]?.environments?.isUseSameName) {
      let estProducts = copyData.estimateProducts;
      let saveSameType = companySettingInfo?.[0]?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        setLoading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveByOtherNameOk');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
      return { ...cost, id: 0 };
    });
    copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
      return { ...date, id: 0 };
    });
    copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
      return { ...label, id: 0 };
    });
    copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
      return { ...memo, id: 0 };
    });

    copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));

    let createdData = await createEstimateProductInfo(copyData, false);

    if (createdData) {
      Modal.success({
        width: 465,
        className: 'modal-complete',
        title: '完了',
        content: (
          <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
            {'データ保存は正常に完了しました。'}
          </p>
        ),
        onOk: () => onRouteChange(),
      });
    }
    setLoading(false); // Waitingダイアログを非表示にする
  };

  // 別名保存入力ボックスのCancelボタン押下
  const saveByOtherNameCancel = () => {
    setIsSaveByOtherName(false);
  };

  // 同一図面で保存する確認メッセージのOkボタン押下
  const saveSameDrawingNoOk = async () => {
    await eval(saveSameDrawingNoFncName + '()');
  };

  // 同一図面で保存する確認メッセージのCancelボタン押下
  const saveSameDrawingNoCancel = () => {
    setIsSaveSameDrawingNo(false);
  };

  // 別名保存
  const saveByOtherNameInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        表示中の見積データを別名で保存します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                ref={ref}
                name="drawingNo"
                id="drawingNo"
                value={drawingNo}
                type="text"
                onChange={(e) => {
                  setDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">見積番号</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="estimateNo"
                id="estimateNo"
                value={estimateNo}
                type="text"
                onChange={(e) => {
                  setEstimateNo(e.target.value);
                }}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // 親部品から他のメニューを押下した場合、表示する確認メッセージ
  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          見積データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          {selectedEstimateData?.estimateProducts?.id === 0 ? (
            <>
              <Button className="mainButton" onClick={saveEstimateData}>
                保存
              </Button>
            </>
          ) : (
            <>
              <Space size={'middle'}>
                <Button className="mainButton" onClick={saveEstimateDataByOtherName}>
                  別名保存
                </Button>
                <Button className="mainButton" onClick={updateEstimateData}>
                  上書保存
                </Button>
              </Space>
            </>
          )}
        </Col>

        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={discardEstimateData}>
              {/* <CloseOutlined /> */}
              破棄
            </Button>
            <Button className="cancelButton" onClick={cancelOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const sameDrawingNoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const renderComponent = () => {
    if (
      location.pathname == '/home/:parentList' ||
      location.pathname == '/home/:estimate.parent' ||
      location.pathname == '/home/:estimate.iq3'
    ) {
      return (
        <>
          {/** 親部品リスト画面 */}
          <div style={{ display: location.pathname == '/home/:parentList' ? 'block' : 'none' }}>
            <ParentList
              ref={parentListRef}
              updateEstimateEditMode={updateEstimateEditMode}
              getSelectedEstimateData={getSelectedEstimateData}
              editMode={estimateEditMode}
              parentListClick={parentListClick}
              orderOptions={orderOptions}
              companyInfo={companyInfo}
              clientInfo={clientInfo}
              userInfo={userInfo}
              staffInfo={staffList}
              processInfo={processInfo}
              companySettingInfo={companySettingInfo}
              userSettingInfo={userSettingInfo}
              purchaseCategoryInfo={purchaseCategoryInfo}
              loading={updateLoading}
              updateParentActiveTabKey={updateParentActiveTabKey}
              updateNewModeByParent={updateNewModeByParent}
            />
          </div>
          {/**　親部品・板金見積画面 */}
          <div
            style={{
              display:
                location.pathname == '/home/:estimate.parent' || location.pathname == '/home/:estimate.iq3'
                  ? 'block'
                  : 'none',
            }}
          >
            <MainEstimate
              createNew={createNew}
              ref={mainEstimateRef}
              updateEstimateEditMode={updateEstimateEditMode}
              selectedEstimateData={selectedEstimateData}
              updateSelectedEstimateData={updateSelectedEstimateData}
              companyInfo={companyInfo}
              staffList={staffList}
              estimateStatusInfo={orderOptions}
              clientInfo={clientInfo}
              editMode={estimateEditMode}
              activeTab={activeTab}
              updateActiveTabKey={updateActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              newMode={newMode}
              updateNewMode={updateNewMode}
              settingParametersByReCal={settingParametersByReCal}
              userSettingInfo={userSettingInfo}
              companySettingInfo={companySettingInfo}
              loading={updateLoading}
              isReCal={isReCal}
              updateIsRecal={updateIsRecal}
              isReCalMaterial={isReCalMaterial}
              updateIsRecalMaterial={updateIsRecalMaterial}
              updateSelectedEstimateDataByPMX={updateSelectedEstimateDataByPMX}
              isPMXEvent={isPMXEvent}
              updatePMXEvent={updatePMXEvent}
              parentActiveTabKey={parentActiveTabKey}
              updateParentActiveTabKey={updateParentActiveTabKey}
              iq3ActiveTabKey={iq3ActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              updateIq3ActiveTabKey={updateIq3ActiveTabKey} // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
              navigatePage={navigatePage}
              updateNewModeByParent={updateNewModeByParent}
            />
          </div>
        </>
      );
    } else if (location.pathname == '/home/:userSetting') {
      return (
        <UserSetting
          ref={userSettingRef}
          navigatePageName={navigatePageName}
          isMenuClickByUserSetting={isMenuClickByUserSetting}
          navigatePage={navigatePage}
          paramChangeEvent={paramChangeEvent}
          loading={updateLoading}
        />
      );
    } else {
      return (
        <MainParameter
          paramType={paramType}
          paramNm={paramSelected}
          updateParamSelected={updateParamSelected}
          companySettingInfo={companySettingInfo}
          userSettingInfo={userSettingInfo}
          ref={paramRef}
          loading={updateLoading}
        />
      );
    }
  };

  return (
    <>
      <Spin spinning={loading} tip="Loading..." size="large">
        <Layout>
          {/* ヘッダー */}
          <Header />
          <Layout
            style={{
              background: 'white',
              left: 0,
            }}
            hasSider
          >
            {/* サイドメニュ */}
            <Sider
              style={{
                background: 'none',
                backgroundColor: '#fafafc',
                left: 0,
                bottom: 0,
                marginLeft: '0px',
                marginTop: '1px',
                borderRight: '1px solid #212529',
              }}
              width={75}
            >
              <div style={{ paddingLeft: '5px', display: 'flex', flexDirection: 'column', height: '94.5vh' }}>
                <div style={{ paddingTop: '0px' }}>
                  <a onClick={(e) => changeScreenEvent(e, 'parentList')}>
                    <Image preview={false} width={35} src={oya_list} style={{ marginLeft: '15px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color: location.pathname == '/home/:parentList' ? '#1677ff' : '#4a4747',
                        marginLeft: '-5px',
                        marginTop: '-5px',
                      }}
                    >
                      親部品リスト
                    </p>
                  </a>
                </div>

                <div>
                  <a onClick={(e) => changeScreenEvent(e, 'parent')}>
                    <Image preview={false} width={25} src={estimate} style={{ marginLeft: '18px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color: location.pathname === '/home/:estimate.parent' ? '#1677ff' : '#4a4747',
                        marginLeft: '-5px',
                      }}
                    >
                      見積データ
                    </p>
                  </a>
                </div>

                {/* <div>
                  <a onClick={(e) => changeScreenEvent(e, 'sheetMetal')}>
                    <Image preview={false} width={33} src={iQ3_create} style={{ marginLeft: '15px' }}></Image>
                    <p
                      style={{
                        color: partNm == 'iq3' && isMenuClick == false ? '#1677ff' : '#4a4747',
                        fontSize: '10.5px',
                        fontWeight: 'bold',
                        marginLeft: '12px',
                      }}
                    >
                      iQ3板金
                    </p>
                  </a>
                </div> */}

                {/* <div>
              <Image preview={false} width={33} src={iQ5_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq5' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ5形鋼
              </p>
            </div> */}

                {/* <div>
              <Image preview={false} width={33} src={iQ7_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq7' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ7機械
              </p>
            </div> */}

                <div style={{ height: '74vh' }}>
                  {/* <a onClick={(e) => (!isEditingMode(e, 2, '', '') ? paramChangeEvent(e) : '')}> */}
                  <a onClick={(e) => changeScreenEvent(e, 'customer')}>
                    <Image preview={false} width={30} src={parameter} style={{ marginLeft: '15px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color:
                          location.pathname.indexOf('/home/:common.') > -1 ||
                          location.pathname.indexOf('/home/:parent.') > -1 ||
                          location.pathname.indexOf('/home/:iq3.') > -1
                            ? '#1677ff'
                            : '#4a4747',
                        marginLeft: '-5px',
                      }}
                    >
                      パラメータ
                    </p>
                  </a>
                </div>

                <div>
                  <a onClick={(e) => (!isEditingMode(e, 3, '', '') ? handleClick(e) : '')}>
                    <Image preview={false} width={26} src={logout} style={{ marginLeft: '15px' }}></Image>
                    <p
                      className="menu menu-active-hover"
                      style={{
                        color: '#4a4747',
                        // fontSize: '10.5px',
                        // fontWeight: 'bold',
                        marginTop: 2,
                        marginLeft: '-5px',
                      }}
                    >
                      ログアウト
                    </p>
                  </a>
                </div>
              </div>
            </Sider>
            {/* コンテンツ */}
            <Layout style={{ background: 'white' }}>
              <Content style={{ overflow: 'initial' }}>{renderComponent()}</Content>
            </Layout>
          </Layout>
        </Layout>
      </Spin>
      {/** 画面切替警告メッセージポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {saveDiscardConfirmPopup
        ? commonModal(
            saveDiscardConfirmPopup,
            confirmScreenChangeWarning,
            null,
            null,
            500,
            discardConfirmOk,
            closeOk,
            saveDiscardConfirmContent,
            null,
            null,
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}

      {/* 別名保存_Modal */}
      {isSaveByOtherName
        ? commonModal(
            isSaveByOtherName,
            commonModalTitle,
            commonFooter(saveByOtherNameOk, saveByOtherNameCancel),
            null,
            520,
            saveByOtherNameOk,
            saveByOtherNameCancel,
            saveByOtherNameInfoContent,
            null,
            null,
            false
          )
        : ''}

      {/* 同一図番_Modal */}
      {isSaveSameDrawingNo
        ? commonModal(
            isSaveSameDrawingNo,
            sameDrawingNoModalTitle,
            commonFooter(saveSameDrawingNoOk, saveSameDrawingNoCancel),
            null,
            400,
            saveSameDrawingNoOk,
            saveSameDrawingNoCancel,
            sameDrawingNoContent(getNameByValue(companySettingInfo?.[0]?.environments?.saveSameType, saveSameTypes)),
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default MainLayout;
