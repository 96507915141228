/**
 * クラス名：板金の外形追抜条件画面
 * 作成者：チュー
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { commonModal } from '../../../common/CommonModal';
import { deleteListColData, updateListColData, sortListData, handleKeyPress } from '../../../common/Common';

const Device_Blank_ExtractCondition = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isRetainedMold, setIsRetainedMold] = useState(false);

  // 保有金型
  const [retainedMoldTemp, setRetainedMoldTemp] = useState([]);
  const [retainedMold, setRetainedMoldData] = useState([]);
  const [extractConditionList, setExtractConditionList] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);

    if (props?.detailsData?.odOvertakeMoldMaster) {
      setRetainedMoldData(props?.detailsData?.odOvertakeMoldMaster);
      setRetainedMoldTemp(props?.detailsData?.odOvertakeMoldMaster);
    }
    if (props?.detailsData?.odOvertakeItems) {
      setExtractConditionList(props?.detailsData?.odOvertakeItems);
    }
  }, [props.editMode, props.detailsData]);

  const addRetainedMoldModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>保有金型</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isRetainedMold === true) {
      setIsRetainedMold(false);
    }
  };
  const addRetainedMold = (id) => {
    const copyData = retainedMoldTemp.slice(); // Create a copy of the original array

    const insertIndex = retainedMoldTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: retainedMoldTemp?.length + 1,
        name: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setRetainedMoldTemp(copyData);
    }
  };
  const deleteRetainedMold = (id, e) => {
    if (retainedMoldTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = retainedMoldTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setRetainedMoldTemp(updatedData);
    }
  };

  const handleRetainedMoldChange = (event, id, field) => {
    const updatedData = retainedMoldTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setRetainedMoldTemp(updatedData);
  };

  const cancelMasterData = () => {
    setRetainedMoldTemp(retainedMold);
    setIsRetainedMold(false);
  };
  const addRetainedMoldMaster = () => {
    setIsRetainedMold(false);

    const deletedId = [];

    retainedMold?.forEach((item1, index) => {
      const found = retainedMoldTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = retainedMoldTemp
      ?.filter((item) => !item.isOutOfRange && !retainedMold.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deletedData = deleteListColData(extractConditionList, deletedId);

    const updatedData = updateListColData(deletedData, newId);

    const sortedList = sortListData(updatedData, retainedMoldTemp);

    setRetainedMoldData(retainedMoldTemp);
    props.updatedRetainedMold(retainedMoldTemp);

    setExtractConditionList(sortedList);
    props.updateExtractConditionList(sortedList);
  };

  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {retainedMoldTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.name}
                          onChange={(e) => handleRetainedMoldChange(e, item.no, 'name')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addRetainedMold(item.no)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteRetainedMold(item.no, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addRetainedMoldMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const retainedMoldModal = () => {
    setIsRetainedMold(true);
  };
  const extractConditionListDataChange = (event, no) => {
    extractConditionList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setExtractConditionList(extractConditionList);
    props.updateExtractConditionList(extractConditionList);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              retainedMold?.filter((i) => i.isOutOfRange === false)?.length > 8
                ? '100%'
                : retainedMold?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  <label>辺長</label>
                  <label>/</label>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={retainedMoldModal} style={{ pointerEvents: 'auto' }}>
                        保有金型
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>保有金型</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {retainedMold
              ?.filter((subitem) => subitem.isOutOfRange === false)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  {item.name}
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              加工辺長
            </td>
            {extractConditionList?.map((row, index) => (
              <td key={index} style={{ width: '98px' }}>
                <Input
                  value={row.value}
                  onChange={(e) => extractConditionListDataChange(e, row.no)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            ))}
          </tr>
        </Table>
      </div>

      {/* 保有金型 */}
      {isRetainedMold
        ? commonModal(
            isRetainedMold,
            addRetainedMoldModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_ExtractCondition;
