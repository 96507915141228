/**
 * クラス名：板金子部品工程選択
 * 説明：板金子部品工程選択ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';

import { initProcessSelect, patternPage } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';
import { getDeviceInfo, getProcessInfo, getProcessPatternInfo } from '../common/CommonAPI';
import { ProcessDetailGroup, ServiceClass, WorkType } from '../common/enums';

const page = patternPage[1];

const IQ3KouteiSentaku = forwardRef((props, ref) => {
  const [childPartNo, setChildPartNo] = useState(1);
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [selectedChildPartInfo, setSelectedChildPartInfo] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState([]);
  const [kouteiSentakuPatternList, setKouteiSentakuPatternList] = useState([]);
  const [iq3List, setIQ3List] = useState([]);

  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);

  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);

  const patternformRef = useRef();
  const currentRoute = location.pathname;

  let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;

  useEffect(() => {
    getProcesssPatternInfo();
    let iq3List = props.iQ3DataLst == undefined ? [] : props.iQ3DataLst;
    let iq3List_SelectBoxData = [];
    for (let i = 0; i < iq3List?.length; i++) {
      iq3List_SelectBoxData.push({ label: 'No.' + iq3List[i].no + '　' + iq3List[i].name, value: iq3List[i].no });
    }
    setIQ3List(iq3List_SelectBoxData);

    let curRowIndex = 1;
    let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
    for (let i = 0; i < props.selectedDataDetail?.esIq3Info?.length; i++) {
      if (props.selectedDataDetail?.esIq3Info[i]?.no === curRowNo) {
        setCurrentRecordIndex(i);
        curRowIndex = i;
      }
    }
  }, [
    props.selectedIQ3DataDetail,
    props.selectedDataDetail,
    props.type,
    props.currentRecordNo,
    props.activeTabKey,
    props?.isReCal,
  ]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  const getProcesssPatternInfo = async () => {
    let kouteiPatternList = [];
    let kouteiSentakuPatternNo = 0;
    // const patternLst = await getProcessPatternInfo(); // 工程マスタデータ

    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    setListData(kouteiPatternList);
    setKouteiSentakuPatternList(kouteiPatternList);

    // if (props?.selectedIQ3DataDetail && props?.selectedIQ3DataDetail?.processSelect) {
    //   kouteiSentakuPatternNo = props.selectedIQ3DataDetail.processSelect.id;
    // }

    // let targertProcessPatternInfo = kouteiPatternList?.filter((i) => i.id === kouteiSentakuPatternNo);
    // if (targertProcessPatternInfo?.length > 0) {
    //   setProcessSelectedData(targertProcessPatternInfo?.[0]);
    // } else {
    //   setProcessSelectedData(kouteiPatternList?.[0]);
    // }
    if (props.type === 'pmx') {
      if (props?.patternNo != 0) {
        const selectedPatternInfo = kouteiPatternList?.filter((item) => item.id == props.patternNo);
        setProcessSelectedData(selectedPatternInfo[0]);
        setKouteiSentakuPatternNo(selectedPatternInfo[0]?.id);
      } else {
        let esIq3Info = props?.selectedDataDetail?.esIq3Info;
        let processSelect = esIq3Info?.length > 0 ? esIq3Info[0]?.processSelect : undefined;
        if (processSelect) {
          setProcessSelectedData(processSelect);
          setKouteiSentakuPatternNo(processSelect.id);
        }
      }
    } else {
      let processSelect = props?.selectedIQ3DataDetail?.processSelect;
      setProcessSelectedData(processSelect);
      setKouteiSentakuPatternNo(processSelect?.id);
    }

    setDeviceMasterInfo(parameters?.device);
    setProcessMasterInfo(parameters?.process);
    setClientInfo(parameters?.client);
  };

  const updateKouteiSentakuInfo = (selectedProcessPattern, list) => {
    setKouteiSentakuPatternNo(selectedProcessPattern?.id);
    setProcessSelectedData(selectedProcessPattern);
    setKouteiSentakuPatternList(list);
    if (props.type === 'pmx') {
      props.updateSelectedPatternNo(selectedProcessPattern?.id);
      const updatedLists = props.selectedDataDetail?.esIq3Info?.map((item) => ({
        ...item,
        processSelect: selectedProcessPattern,
      }));
      props.selectedDataDetail.esIq3Info = updatedLists;

      // props.updatedSelectedData(updatedLists);
    }

    //マイン見積データに板金工程選択更新
    if (props.selectedDataDetail.esIq3Info[currentRecordIndex]) {
      let currentPattern = JSON.parse(JSON.stringify({ ...props.selectedDataDetail.esIq3Info[currentRecordIndex] }));
      let processSelect = initProcessSelect(selectedProcessPattern);
      currentPattern.processSelect.id = processSelect?.id;
      currentPattern.processSelect.name = processSelect?.name;
      currentPattern.processSelect.details = processSelect?.details;
      currentPattern.processSelect.modified = processSelect?.modified;

      props.selectedDataDetail.esIq3Info[currentRecordIndex] = currentPattern;

      // ブランクの場合
      let blankProcess = getProcessRecord(
        ServiceClass.SheetMetal,
        [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
        parameters?.process
      );
      // 板金工程選択にブランクを使用している場合、
      let usedBlankProcess = props.selectedDataDetail.esIq3Info[currentRecordIndex].processSelect.details?.find(
        (selectItem) =>
          selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
      );
      // 使用している装置の情報を取得する
      let usedDeviceInfo = parameters?.device?.find((device) => device.id === usedBlankProcess?.deviceId);

      // 装置がある場合、
      if (usedDeviceInfo) {
        // 装置から材料配置情報
        let materialArrange = usedDeviceInfo?.details?.materialArrange;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].grabbingX = materialArrange?.graspAllowanceX;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].grabbingY = materialArrange?.graspAllowanceY;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].pierWidthX = materialArrange?.xWidth;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].pierWidthY = materialArrange?.yWidth;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].worlRangeX = materialArrange?.workRangeX;
        props.selectedDataDetail.esIq3Info[currentRecordIndex].worlRangeY = materialArrange?.workRangeY;
      }
    }

    // 現在の板金データに工程選択情報更新
    if (props.type !== 'pmx') {
      props.updateProcessSelectOfSelectedData(selectedProcessPattern);
    }
  };

  const updateSelectedData = (selectedPart) => {
    props.updateSelectedData(selectedPart);
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecord = (classId, processTypes, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter(
      (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
    );
    return mstRecordInfo;
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
      getKouteiSentakuPatternList: () => {
        return kouteiSentakuPatternList;
      },
      getProcessSelectedData: () => {
        return processSelectedData;
      },
    }),
    [kouteiSentakuPatternNo, kouteiSentakuPatternList, processSelectedData]
  );

  return (
    <>
      <div style={{ paddingTop: 5 }}>
        <ProcessPatternForm
          ref={patternformRef}
          selectedData={processSelectedData}
          listData={listData}
          editMode={props.editMode}
          page={patternPage[1]}
          isParameter={false}
          updateKouteiSentakuInfo={updateKouteiSentakuInfo}
          kouteiSentakuEditMode={editMode}
          iq3PartList={iq3List}
          childPartNo={props.selectedIQ3DataDetail != undefined ? props.selectedIQ3DataDetail.no : 1}
          updateSelectedData={updateSelectedData}
          pmxMode={props.pmxMode}
          processMasterData={processMasterInfo}
          deviceMasterData={deviceMasterInfo}
          clientMasterData={clientInfo}
        />
      </div>
    </>
  );
});

export default IQ3KouteiSentaku;
