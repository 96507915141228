/**
 * クラス名：材料表面覧画面
 * 説明：iQ3板金に使用の材料表面一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Image, Radio, Space } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SplitPane from 'split-pane-react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import CurrencyInput from 'react-currency-input-field';

import MaterialSurfaceDetail from './MaterialSurfaceDetail';
import '../../../../assets/styles/common.css';
import 'split-pane-react/esm/themes/default.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  commonActionFooter,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  dataStateList,
  actionArr,
  getMaterialSurfaceStorage,
  unitTypes,
  formatDate,
  getMaterialTypeIQ3List,
  updateAccessToken,
  getAccessToken,
  formValidatorMode,
  defaultValue,
  JPYs,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDecimal,
  isDigit,
} from '../../../common/Common.js';
import { createMaterialSurfaceIq3CSV, getMaterialSurfaceIQ3Info } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import { pageSizes } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);

const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialSurfaceList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const csvExportFileName = `材料表面_${formatDateString(todayTime())}.csv`;

  const detailRef = useRef();
  const messageRef = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材料表面');
  const [paramType, setParamType] = useState('iq3');
  const [materialSurfaceData, setMaterialSurfaceData] = useState([]);
  const [tempMaterialSurface, setTempMaterialSurface] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeIQ3List, setMaterialTypeIQ3List] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [checkedRowId, setCheckedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();

  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // pagination
  const [pageSize, setPageSize] = useState(100);

  const inputItemRef = {};

  useEffect(() => {
    getMaterialTypeIQ3Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 材料表面保護マスタ情報を取得する
  const getMaterialTypeIQ3Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    const materialSurfaceDataSample = await getMaterialSurfaceIQ3Info();
    setMaterialSurfaceData(materialSurfaceDataSample);
    setTempMaterialSurface(materialSurfaceDataSample);
    setFilteredData(materialSurfaceDataSample);
    setSelectedRowId(materialSurfaceDataSample[0]?.id);
    setSelectedData(materialSurfaceDataSample[0]);
    setIsFilter(true);
    props.setParam(paramType, paramSelected);
    let materialTypeIQ3List = await getMaterialTypeIQ3List();
    setMaterialTypeIQ3List(materialTypeIQ3List);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
      sorter: (a, b) => a.code?.toUpperCase()?.localeCompare(b.code?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'name',
      title: '材料表面保護名',
      dataIndex: 'name',
      width: 150,
      sorter: (a, b) => a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'materialTypeName',
      title: '材質名称',
      dataIndex: 'materialTypeIq3Id',
      width: 150,
      render: (value) => getMaterilTypeIQ3Name(value),
      sorter: (a, b) =>
        getMaterilTypeIQ3Name(a.materialTypeIq3Id)
          ?.toUpperCase()
          ?.localeCompare(getMaterilTypeIQ3Name(b.materialTypeIq3Id)?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'class',
      title: '属性種別',
      dataIndex: 'class',
      width: 100,
      sorter: (a, b) => a.class?.toUpperCase()?.localeCompare(b.class?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'unit',
      title: '加算方法',
      dataIndex: 'info',
      width: 100,
      render: (info) => getUnitName(info.unit),
      sorter: (a, b) => getUnitName(a.info.unit)?.toUpperCase()?.localeCompare(getUnitName(b.info.unit)?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'price',
      title: '加算金額',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      render: (info) => JPYs.format(info.price),
      sorter: (a, b) => a.info.price - b.info.price,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isTwiceCut',
      title: 'レーザー2度切り',
      dataIndex: 'info',
      width: 120,
      render: (text, record) => {
        return (
          record.info.isTwiceCut && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />
        );
      },
      className: 'cm-a-center',
      sorter: (a, b) => (a.info.isTwiceCut ? 1 : 0) - (b.info.isTwiceCut ? 1 : 0),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'workSpeed',
      title: '加工速度(mm/分)',
      dataIndex: 'info',
      width: 150,
      className: 'cm-a-right',
      render: (info) => info.workSpeed,
      sorter: (a, b) => a.info.workSpeed - b.info.workSpeed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => a.modifier?.toUpperCase()?.localeCompare(b.modifier?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      width: 200,
      render: (info) => info.remarks,
      sorter: (a, b) => a.info.remarks?.toUpperCase()?.localeCompare(b.info.remarks?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  // 板金子部品材質名をIDで取得する
  const getMaterilTypeIQ3Name = (id) => {
    return materialTypeIQ3List?.filter((item) => item.value === id)?.[0]?.label;
  };

  // 加算方法の名を取得する
  const getUnitName = (unitNo) => {
    return unitTypes?.filter((item) => item.value === unitNo)?.[0]?.label;
  };

  // 入力ボックスは金額タイプの場合、他の項目CSSを参照にして利用
  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList)?.filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };
  // 材料名称検索
  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  // 新規作成の項目値を変更した場合、
  const onChangeInputForm = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    setTempMaterialSurface((prevData) => ({
      ...prevData,
      info: { ...prevData.info, workSpeed: value },
    }));
  };

  const addModalContent = (
    <div id="addNewMaterialName" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label> {tempMaterialSurface.no} </label>
            </td>
          </tr> */}
          {/* コード */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                name="code"
                id="code"
                value={tempMaterialSurface?.code}
                type="text"
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
                onChange={(e) => {
                  {
                    setTempMaterialSurface({ ...tempMaterialSurface, code: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材料表面保護名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                type="text"
                data-name="name"
                value={tempMaterialSurface?.name}
                onChange={(e) => {
                  {
                    setTempMaterialSurface({ ...tempMaterialSurface, name: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                showSearch
                className={'search-select'}
                style={{ width: '50%' }}
                id="materialTypeIq3Id"
                name="materialTypeIq3Id"
                defaultValue=""
                onChange={(e) => {
                  {
                    setTempMaterialSurface({ ...tempMaterialSurface, materialTypeIq3Id: e });
                  }
                }}
                filterOption={filterOption}
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.materialTypeIq3}
                </Select.Option>
                {materialTypeIQ3List?.map((item, index) => (
                  <Select.Option key={index} id={'materialTypeIq3Id' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">属性種別</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                className={'input-editable'}
                type="text"
                id="class"
                name="class"
                data-name="name"
                value={tempMaterialSurface?.class}
                onChange={(e) => {
                  {
                    setTempMaterialSurface({ ...tempMaterialSurface, class: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['class'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">加算方法</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '50%' }}
                id="unit"
                name="unit"
                defaultValue=""
                onChange={(e) => {
                  {
                    setTempMaterialSurface((prevData) => ({ ...prevData, info: { ...prevData.info, unit: e } }));
                  }
                }}
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.unitType}
                </Select.Option>
                {unitTypes?.map((item, index) => (
                  <Select.Option key={index} id={'unit' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">加算金額</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <CurrencyInput
                name="price"
                id="price"
                type="text"
                value={tempMaterialSurface?.info?.price}
                defaultValue={0}
                onValueChange={(e) => {
                  {
                    setTempMaterialSurface((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, price: e },
                    }));
                  }
                }}
                ref={(ref) => {
                  inputItemRef['price'] = ref;
                }}
                className={getAntInputBoxClassName('class')}
                prefix="¥"
                decimalsLimit={100}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">レーザー2度切り</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isTwiceCut"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialSurface((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, isTwiceCut: e.target.value },
                    }));
                  }
                }}
                value={tempMaterialSurface?.info?.isTwiceCut}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">
                ビニール加工速度
                <br />
                (mm/分)
              </label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialSurface?.info?.workSpeed}
                onBlur={(e) => onChangeInputForm(e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['workSpeed'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">作成日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">
                {tempMaterialSurface.created ? formatDate(tempMaterialSurface.created) : ''}
              </label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">更新日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">
                {tempMaterialSurface.modified ? formatDate(tempMaterialSurface.modified) : ''}
              </label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialSurface?.info?.remarks}
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                onChange={(e) => {
                  {
                    setTempMaterialSurface((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, remarks: e.target.value },
                    }));
                  }
                }}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialSurface((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, isUsed: e.target.value },
                    }));
                  }
                }}
                value={tempMaterialSurface?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const onSelectedRowIdChange = (checked, record, index) => {
    let selectedRowId = [...checked];
    setCheckedRowId(selectedRowId);
    setChkRowsCount(selectedRowId?.length);
  };

  const rowSelection = {
    //selectedRowId,
    onChange: onSelectedRowIdChange,
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '55vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
          />
        </Col>
        <Col span="11">
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable id="param_detail_tbl" className="propertiesTbl">
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">材質名称</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Select
                      className={'search-select'}
                      style={{ width: '35%' }}
                      id="materialTypeIq3Id"
                      name="materialTypeIq3Id"
                      defaultValue=""
                      onChange={(e) => {
                        {
                          setTempMaterialSurface({ ...tempMaterialSurface, materialTypeIq3Id: e });
                        }
                      }}
                    >
                      <Select.Option id="defaultValue" value="">
                        {defaultValue.materialTypeIq3}
                      </Select.Option>
                      {materialTypeIQ3List?.map((item, index) => (
                        <Select.Option key={index} id={'materialTypeIq3Id' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">属性種別</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={'input-editable'}
                      type="text"
                      data-name="name"
                      value={tempMaterialSurface?.class}
                      onChange={(e) => {
                        {
                          setTempMaterialSurface({ ...tempMaterialSurface, class: e.target.value });
                        }
                      }}
                      ref={(ref) => {
                        inputItemRef['class'] = ref;
                      }}
                      maxLength={50}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">加算方法</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Select
                      className={'search-select'}
                      style={{ width: '35%' }}
                      onChange={(e) => {
                        {
                          setTempMaterialSurface((prevData) => ({ ...prevData, info: { ...prevData.info, unit: e } }));
                        }
                      }}
                      id="unit"
                      name="unit"
                      defaultValue=""
                    >
                      <Select.Option id="defaultValue" value="">
                        {defaultValue.unitType}
                      </Select.Option>
                      {unitTypes?.map((item, index) => (
                        <Select.Option key={index} id={'unit' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">レーザー2度切り</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="isTwiceCut"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialSurface((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, isTwiceCut: e.target.value },
                          }));
                        }
                      }}
                      value={tempMaterialSurface?.info?.isTwiceCut}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">
                      ビニール加工速度
                      <br />
                      (mm/分)
                    </label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      data-name="name"
                      className={'input-editable'}
                      value={tempMaterialSurface?.info?.workSpeed}
                      onChange={(e) => {
                        {
                          setTempMaterialSurface((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, workSpeed: e.target.value },
                          }));
                        }
                      }}
                      ref={(ref) => {
                        inputItemRef['workSpeed'] = ref;
                      }}
                      maxLength={50}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      type="text"
                      data-name="name"
                      className={'input-editable'}
                      value={tempMaterialSurface?.info?.remarks}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      onChange={(e) => {
                        {
                          setTempMaterialSurface((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, remarks: e.target.value },
                          }));
                        }
                      }}
                      maxLength={2048}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="isUsed"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialSurface((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, isUsed: e.target.value },
                          }));
                        }
                      }}
                      value={tempMaterialSurface?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button className="cancelButton" onClick={groupEditCancel}>
          破棄
        </Button>
      </Row>
    </Form>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordId(record.id);
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq3.materialSurface');
      }
    } else {
      setSelectedRowId(record.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialSurface');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
  };

  const gpEditHistory = (obj) => {
    tempMaterialSurface['processSpeed'] = parseFloat(tempMaterialSurface['processSpeed']);

    if (
      obj.displayFlag !== tempMaterialSurface.displayFlag ||
      (tempMaterialSurface.materialName !== '' && obj.materialName !== tempMaterialSurface.materialName) ||
      (tempMaterialSurface.attributeType !== '' && obj.attributeType !== tempMaterialSurface.attributeType) ||
      (tempMaterialSurface.additionalMethod !== '' && obj.additionalMethod !== tempMaterialSurface.additionalMethod) ||
      (tempMaterialSurface.processSpeed !== 0 && obj.processSpeed !== tempMaterialSurface.processSpeed) ||
      (tempMaterialSurface.additionalAmount !== '' && obj.additionalAmount !== tempMaterialSurface.additionalAmount) ||
      (tempMaterialSurface.remark !== '' && obj.remark !== tempMaterialSurface.remark)
    ) {
      obj.modified = dayjs(dayjs()).format('YYYY-MM-DD');
    }
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else if (newObj.info[objKey] === '') {
      return oldObj.info[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey] || newObj.info[objKey] === oldObj.info[objKey]);
      return Object.keys(newObj?.info)?.filter((v) => v === objKey)?.length > 0 ? newObj.info[objKey] : newObj[objKey];
    }
  };

  const updConfirmOk = async (e) => {
    let stateData = JSON.parse(JSON.stringify(materialSurfaceData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdData = await createMaterialSurfaceIq3Data(tempMaterialSurface);
      stateData.push(createdData);
      setTmpFilteredData(stateData);
      setMaterialSurfaceData(stateData);
      resetAddModal(stateData?.length + 1);
      // onRowSelect(tempMaterialSurface);
      setSelectedRowId(createdData?.id);
      setSelectedData(createdData);
      // setItemNo(tempMaterialSurface.no);
      setVisibleAddNewModal(false);
      setCurrentPage(Math.ceil(stateData?.length / 25));
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (visibleGroupEditModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      if (checkedRowId?.length > 0) {
        const updatedData = stateData?.map((obj) => {
          if (checkedRowId?.includes(obj.id)) {
            // gpEditHistory(obj);
            return {
              ...obj,
              materialTypeIq3Id: gpEditCheck(tempMaterialSurface, obj, 'materialTypeIq3Id'),
              class: gpEditCheck(tempMaterialSurface, obj, 'class'),
              info: {
                ...obj.info,
                isUsed: gpEditCheck(tempMaterialSurface, obj, 'isUsed'),
                unit: gpEditCheck(tempMaterialSurface, obj, 'unit'),
                isTwiceCut: gpEditCheck(tempMaterialSurface, obj, 'isTwiceCut'),
                workSpeed: gpEditCheck(tempMaterialSurface, obj, 'workSpeed'),
                remarks: gpEditCheck(tempMaterialSurface, obj, 'remarks'),
              },
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        for (var i = 0; i <= updatedData?.length - 1; i++) {
          updatedData[i] = await updateMaterialSurfaceIq3Data(updatedData[i]);
        }
        setTmpFilteredData(updatedData);
        setMaterialSurfaceData(updatedData);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
      setVisibleGroupEditModal(false);
    }
    setIsFilter(true);
    /* setUpdateConfirm(false); */
  };

  // 材料表面保護マスタ情報をDBに新規登録する
  async function createMaterialSurfaceIq3Data(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          materialTypeIq3Id: createData.materialTypeIq3Id,
          class: createData.class,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(true);
  };

  const updConfirmCancel = () => {
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleGpEdit = () => {
    setTempMaterialSurface({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      class: '',
      info: {
        isUsed: '',
        unit: '',
        price: '',
        isTwiceCut: '',
        workSpeed: '',
        remarks: '',
      },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[1]);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit();
      }
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    setTempMaterialSurface({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      class: '',
      info: {
        isUsed: true,
        unit: '',
        price: 0,
        isTwiceCut: true,
        workSpeed: 4000,
        remarks: '',
      },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleAddNewModal(true);
    /*  setChildTitle('材質サイズ'); */
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    //setUpdateCancelConfirm(true);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
    /* setVisibleAddNewModal(false); */
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
      setVisibleDeleteModal(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteMaterialSurfaceIq3Data(selectedData.id);

    let data = [...materialSurfaceData];
    data = data?.filter((item) => item.id != deletedData.id);

    setTmpFilteredData(data);
    setMaterialSurfaceData(data);
    setFilteredData(data);
    setSelectedRowId(data?.length > 0 ? data[0].id : -1);
    setSelectedData(data?.length > 0 ? data[0] : []);
    setIsFilter(true);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 材料表面保護マスタ情報をDBに削除する
  async function deleteMaterialSurfaceIq3Data(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const resetAddModal = (count) => {
    setTempMaterialSurface({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      class: '',
      info: {
        isUsed: true,
        unit: '',
        price: '',
        isTwiceCut: true,
        workSpeed: 4000,
        remarks: '',
      },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, dataState, showFlag, tmpFilteredData, materialSurfaceData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
      /* setIsFilter(true);
      if (editMode) {
        setFilterKey(e);
        setDiscardOKAction(action.key);
        //updateEditMode(false);
        setEditMode(false);
        setEditModeCancelConfirm(true);
      } else {
        callMethod(action.methodName, e);
      } */
    }
  };

  // 無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialSurfaceData?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let materialFilterData = [...materialSurfaceData];
    let flag;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    }
    // 型番のキーワードで絞り込み
    if (mKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.code?.toString().indexOf(mKeyword) >= 0);
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData?.filter((item) => item.info.isUsed === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData?.filter((item) => item.info.unit === dataState);
    }

    /* let index = materialFilterData?.findIndex((item) => item.no === selectedRowId);

    if (index === -1) {
      if (editMode) {
        
        setEditModeCancelConfirm(true);
        materialFilterData?.length > 0 && setChangeRecordId(materialFilterData[materialFilterData?.length - 1].no);
        return;
      }
      // 検索結果がある場合、
      if (materialFilterData?.length > 0) {
        index = materialFilterData?.length - 1;
        setSelectedRowId(materialFilterData[index]?.no);
        setSelectedData(materialFilterData[index]);
      }
    }
    if (materialFilterData?.length > 0 && isFilter) {
    
      setSelectedRowId(materialFilterData[index].no);
      setSelectedData(materialFilterData[index]);
    } else if (materialFilterData?.length <= 0) {
      setSelectedData([]);
    } */
    setFilteredData(materialFilterData);
    setCurrentPage(1);
  };
  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...materialSurfaceData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateMaterialSurfaceIq3Data(updateData);
    var index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setMaterialSurfaceData(tmpData);
    setTmpFilteredData(tmpData);
    setIsFilter(false);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 材料表面保護マスタ情報をDBに更新する
  async function updateMaterialSurfaceIq3Data(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          materialTypeIq3Id: updateData.materialTypeIq3Id,
          class: updateData.class,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        getMaterilTypeIQ3Name(data.materialTypeIq3Id) +
        ',' +
        data.class +
        ',' +
        getUnitName(data.info.unit) +
        ',' +
        data.info.price +
        ',' +
        data.info.isTwiceCut +
        ',' +
        data.info.workSpeed +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.modifier +
        ',' +
        data.info.remarks +
        ',' +
        data.info.isUsed;
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          code: '',
          name: '',
          materialTypeIq3Id: '',
          class: '',
          info: {
            isUsed: true,
            unit: '',
            price: '',
            isTwiceCut: true,
            workSpeed: '',
            remarks: '',
          },
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        tempData.id = data?.[0];
        tempData.code = data?.[2];
        tempData.name = data?.[3];
        tempData.materialTypeIq3Id = materialTypeIQ3List?.find((item) => item.label === data?.[4])?.value;
        tempData.class = data?.[5];
        tempData.info.unit = unitTypes?.find((item) => item.label === data?.[6])?.value;
        tempData.info.price = data?.[7];
        tempData.info.isTwiceCut = data?.[8] ? JSON.parse(data?.[8]) : true;
        tempData.info.workSpeed = data?.[9];
        tempData.info.remarks = data?.[13];
        tempData.info.isUsed = data?.[14] ? JSON.parse(data?.[14]) : true;
        tempDatas.push(tempData);
      }
      createdDatas = await createMaterialSurfaceIq3CSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setMaterialSurfaceData(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedRowId(createdDatas?.[0]?.id);
          setSelectedData(createdDatas?.[0]);
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // コード
    let input = data[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('コード').E007}</li>);
      return false;
    }

    // 材料表面保護名
    input = data[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材料表面保護名').E007}</li>);
      return false;
    }

    // 材質名称
    input = data[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E007}</li>);
      return false;
    }
    if (input?.length > 0 && materialTypeIQ3List?.findIndex((item) => item.label === input) === -1) {
      // 不正な値
      messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E022}</li>);
      return false;
    }

    // 加算方法
    input = data[6];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('加算方法').E007}</li>);
      return false;
    }
    if (input?.length > 0 && unitTypes?.findIndex((item) => item.label === input) === -1) {
      // 不正な値
      messageRef?.current?.push(<li>{no + ErrorMessage('加算方法').E022}</li>);
      return false;
    }

    // 加算金額
    input = data[7];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('加算金額').E007}</li>);
      return false;
    }
    if (input?.length > 0 && !isDigit(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('加算金額').E009}</li>);
      return false;
    }

    // 加工速度(mm/分)
    input = data[9];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('加工速度(mm/分)').E007}</li>);
      return false;
    }
    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('加工速度(mm/分)').E009}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            <Space size="middle" className="search-bar">
              <Select
                style={{ width: 90, float: 'right' }}
                onChange={beforeSearch}
                placeholder="表示・非表示"
                id="showFlgDrop"
                name="showFlgDrop"
                value={showFlag}
              >
                {dataStateList?.map((item, index) => (
                  <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Space>
            <>
              {/* 一覧アリア */}
              <Row>
                <Table
                  showSorterTooltip={false}
                  columns={hasCheckData ? tableColumns : chkData}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '55vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record?.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    // defaultPageSize: 25,
                    defaultCurrent: 1,
                    // pageSize: 25,
                    current: currentPage,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizes,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className="param_tbListShow"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                />
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialSurface"
                element={
                  <MaterialSurfaceDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialSurfaceData?.find((element) => element.id === selectedRowId)}
                    itemNo={itemNo}
                    submitData={submitData}
                    materialTypeIQ3List={materialTypeIQ3List}
                    getMaterilTypeIQ3Name={getMaterilTypeIQ3Name}
                    getUnitName={getUnitName}
                    updateEditMode={updateEditMode}
                    getAntInputBoxClassName={getAntInputBoxClassName}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材料表面（選択したデータをまとめて編集）', 1790, -24),
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          true
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/* 更新確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 520,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit20" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm
        ? commonModal(
            updateCancelConfirm,
            confirmModalTitle,
            commonFooter(updCancelOK, updCancelCancel),
            null,
            400,
            updCancelOK,
            updCancelCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材料表面（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? confirmCreateContent : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialSurfaceList;
