import { Button, Row, Input, Col, Image, Space, Modal } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import calculator_white from '../../assets/images/calculator_white.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import KouteibetsuInfo from './KouteibetsuInfo';
import { commonTaiochuModal, exportComplete } from '../common/CommonModal';
import { JPYs, formatDateString, todayTime, updateEstimateInfoByReCal } from '../common/Common.js';
import { CSVLink } from 'react-csv';

const KouteiquantityBetsuBtn = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showProcessTotalAmount, setShowProcessTotalAmount] = useState(false);
  const [showQuantityComponent, setShowQuantityComponent] = useState(false);
  const [quantityItem, setQuantityItem] = useState([]);
  const [quantityItemMoto, setQuantityItemMoto] = useState([]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  // CSV出力
  const csvExportRef = useRef();
  const [fileName, setFileName] = useState(`数量別見積_${formatDateString(todayTime())}.csv`);

  const [suuryou, setSuuryou] = useState(1);

  useEffect(() => {
    if (showQuantityComponent == true) {
      initQuantityItem();
    }
  }, [showQuantityComponent]);

  useEffect(() => {
    if (props?.isReCal == true) {
      initQuantityItem();
    }
    setSuuryou(parseInt(props?.selectedEstimateData?.estimateProducts?.quantity));
  }, [props?.isReCal, props.selectedEstimateData, props?.selectedEstimateData?.estimateProducts?.quantity]);

  // useEffect(() => {
  //   if (props?.loading && !props?.isPMXEvent) {
  //     props?.loading(false); // Waitingダイアログを非表示にする
  //   }
  // }, [props?.isReCal, props.selectedEstimateData]);

  const initQuantityItem = () => {
    let quantityItemDatas = props.selectedEstimateData?.estimateProducts?.byQuantities;
    let calQuantityItems = [];
    let calQuantity = {};
    for (let i = 0; i < quantityItemDatas?.length; i++) {
      calQuantity = calEstimateByQuantity(quantityItemDatas[i].quantity, quantityItemDatas[i]);
      calQuantityItems.push(calQuantity);
    }
    setQuantityItem(calQuantityItems);
    setQuantityItemMoto(calQuantityItems);
    if (props.selectedEstimateData?.estimateProducts) {
      props.selectedEstimateData.estimateProducts.byQuantities = calQuantityItems;
    }
  };

  const calEstimateByQuantity = (quantity, quantityItem) => {
    //親部品の数量情報取得
    let estimate = JSON.parse(JSON.stringify(props.selectedEstimateData));
    estimate.estimateProducts.quantity = quantity;
    let estimateData = updateEstimateInfoByReCal(estimate);
    let estProduct = JSON.parse(JSON.stringify(estimateData.estimateProducts));

    quantityItem.quantity = Number(quantity);
    quantityItem.costUnitPrice = estProduct?.costUnitPrice;
    quantityItem.costTotalPrice = estProduct?.costTotalPrice;
    quantityItem.estimateUnitPrice = estProduct?.estimateUnitPrice;
    quantityItem.estimateTotalPrice = estProduct?.estimateTotalPrice;
    quantityItem.grossUnitPrice = estProduct.grossUnitPrice;
    quantityItem.grossTotalPrice = estProduct.grossTotalPrice;
    if (Number.isFinite(estProduct?.grossRate) || !isNaN(estProduct?.grossRate)) {
      quantityItem.grossRate = parseFloat(estProduct?.grossRate).toFixed(2);
    } else {
      quantityItem.grossRate = 0;
    }

    return quantityItem;
  };

  // 開発中ポップアップ呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  const addRowTable = () => {
    if (quantityItem?.length > 0) {
      const latestQuantity = quantityItem[quantityItem?.length - 1];
      const data = {
        quantityNo: 0,
        costUnitPrice: latestQuantity.costUnitPrice,
        costTotalPrice: 0,
        estimateUnitPrice: latestQuantity.estimateUnitPrice,
        estimateTotalPrice: 0,
        grossTotalPrice: 0,
        grossRate: 0,
      };
      let addArr = [...quantityItem, data];
      setQuantityItem(addArr);
      props.selectedEstimateData.estimateProducts.byQuantities = addArr;
      document.getElementById('quantityTbl')?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const tableRowRemove = (e, index) => {
    if (index > 0) {
      const dataRow = [...quantityItem];
      dataRow.splice(index, 1);
      setQuantityItem(dataRow);
      props.selectedEstimateData.estimateProducts.byQuantities = dataRow;
    }
  };

  const onChangeQuantity = (i, event) => {
    const { name, value } = event.target;
    const data = [...quantityItem];
    data[i][name] = Number(value);
    data[i] = calEstimateByQuantity(value, data[i]);
    setQuantityItem(data);
    props.selectedEstimateData.estimateProducts.byQuantities = data;
  };

  const onUpdate = () => {
    // 最新情報で再計算。
    // let datas = [];
    // const data = quantityItemMoto;
    // for (let i = 0; i < data?.length; i++) {
    //   datas.push(calEstimateByQuantity(data[i].quantityNo, data[i]));
    // }
    setQuantityItem(quantityItemMoto);
    props.selectedEstimateData.estimateProducts.byQuantities = quantityItemMoto;
  };

  const showProcessTotalAmountDialog = () => {
    setShowProcessTotalAmount(true);
    setShowQuantityComponent(false);
    // setSelectedEstimateData(props.selectedEstimateData?.estimateProducts);
    setSelectedEstimateData(props.selectedEstimateData);
  };

  const closeProcessTotalAmountDialog = () => {
    setShowProcessTotalAmount(false);
  };

  const confirmEstimateTbl1 = [
    ['図面番号', '名称', '取引先', '見積番号'],
    [
      props.selectedEstimateData?.estimateProducts?.drawingNo,
      props.selectedEstimateData?.estimateProducts?.name,
      props.selectedEstimateData?.estimateProducts?.clientName,
      props.selectedEstimateData?.estimateProducts?.estimateNo,
    ],
  ];
  const confirmEstimateTbl2 = [
    ['係数'],
    ['受注形態', '納期', '取引先', '管理費'],
    [
      props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffOrders,
      props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates,
      props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffClient,
      props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffManagements,
    ],
  ];
  const table3Headers = ['数量', '原価', '合計原価', '見積', '合計見積', '粗利益', '粗利益率'];

  const allData = [
    ...confirmEstimateTbl1,
    ...confirmEstimateTbl2,
    table3Headers,
    ...quantityItem?.map((item) => [
      item.quantity,
      JPYs.format(item.costUnitPrice),
      JPYs.format(item.costTotalPrice),
      JPYs.format(item.estimateUnitPrice),
      JPYs.format(item.estimateTotalPrice),
      JPYs.format(item.grossTotalPrice),
      `${item.grossRate}%`,
    ]),
  ];
  const showQuantityComponentDialog = () => {
    setShowProcessTotalAmount(false);
    setShowQuantityComponent(true);
    setSelectedEstimateData(props.selectedEstimateData?.estimateProducts);
  };

  const confirmCancel = () => {
    setShowProcessTotalAmount(false);
    setShowQuantityComponent(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      getQuantitiesInfo: () => {
        return quantityItem;
      },
      // getQuantitiesInfo: getQuantitiesInfo,
    }),
    [quantityItem]
  );

  const quantityTblTile = (
    <>
      <Row className="estimate-tab2-title-row" style={{ marginTop: 15 }}>
        <Col span={3} className="estimate-tab2-title-col">
          数量
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          原価
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計原価
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          見積
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          合計見積
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          粗利益
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          粗利益率
        </Col>
        <Col span={3}></Col>
      </Row>
    </>
  );

  const quantityComponent = (
    <>
      <div id="quantityTbl" style={{ overflowY: 'auto', height: '450px', width: '100%' }}>
        {quantityTblTile}
        {quantityItem?.map((item, index) => {
          const { quantity } = item;
          return (
            <Row key={index} className="estimate-tab2-row" id={'quantityRow' + index}>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                <Input
                  value={quantity}
                  style={{
                    height: '2.3vh',
                    textAlign: 'center',
                  }}
                  onChange={(event) => onChangeQuantity(index, event)}
                  name="quantityNo"
                ></Input>
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {JPYs.format(item.costUnitPrice)}
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {JPYs.format(item.costTotalPrice)}
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {JPYs.format(item.estimateUnitPrice)}
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {JPYs.format(item.estimateTotalPrice)}
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {JPYs.format(item.grossTotalPrice)}
              </Col>
              <Col span={3} className={suuryou === quantity ? 'estimate-tab2-col active-row' : 'estimate-tab2-col'}>
                {item.grossRate + '%'}
              </Col>
              <Col span={3} style={{ paddingLeft: '10px', display: quantityItem?.length === index + 1 ? '' : 'none' }}>
                <Space>
                  <Image
                    preview={false}
                    width={25}
                    src={child_add}
                    style={{ marginLeft: '0px' }}
                    onClick={addRowTable}
                  ></Image>
                  <Image
                    preview={false}
                    width={21}
                    src={child_delete}
                    style={{ marginBottom: '1px' }}
                    onClick={(e) => tableRowRemove(e, index)}
                  ></Image>
                </Space>
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );

  const quantityConfirmComponent = (
    <>
      <div style={{ display: 'flex' }}>
        <label style={{ marginTop: 1, fontSize: '13px' }}>数量別見積確認</label>
        <Image
          preview={false}
          width={22}
          height={22}
          src={calculator_white}
          style={{ marginLeft: '5px', marginBottom: '5px' }}
        />
      </div>
    </>
  );
  const exportCSVData = () => {
    setFileName(`数量別見積_${formatDateString(todayTime())}.csv`);

    csvExportRef.current.link.click();
  };

  return (
    <>
      <Button key="submit25" type="primary" onClick={showProcessTotalAmountDialog} className="processTotalButton">
        工程別合計
      </Button>
      <Button key="submit26" type="primary" onClick={showQuantityComponentDialog} className="processTotalButton">
        {quantityConfirmComponent}
      </Button>
      {showProcessTotalAmount ? (
        <KouteibetsuInfo
          selectedEstimateData={selectedEstimateData}
          showProcessTotalAmount={showProcessTotalAmount}
          closeProcessTotalAmountDialog={closeProcessTotalAmountDialog}
          quantity={props.getParentQuantity()}
        />
      ) : (
        ''
      )}
      <Modal
        maskClosable={false}
        title={
          <div
            style={{
              width: 950,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>数量別見積確認</p>
          </div>
        }
        width={950}
        open={showQuantityComponent}
        onOk={null}
        onCancel={confirmCancel}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'white', fontSize: '13.5px' }} />}
        bodyStyle={{ height: 630 }}
      >
        <div style={{ width: '100%' }}>
          <div>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Row id="totalRow" style={{ marginBottom: '7px' }}>
                <Button
                  key="submit"
                  type="primary"
                  className="editAllButton"
                  style={{ marginRight: '10px', width: 65, height: 30 }}
                  id="edit"
                  onClick={onUpdate}
                >
                  更新
                </Button>

                <Button key="submit27" type="primary" className="csvoutput" onClick={exportCSVData}>
                  CSV出力
                  <CSVLink ref={csvExportRef} data={allData} filename={fileName} style={{ display: 'none' }}></CSVLink>
                </Button>
              </Row>
            </Col>
          </div>
          <Row className="estimate-tab2-title-row">
            <Col span={6} className="estimate-tab2-title-col">
              図面番号
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              名称
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              取引先
            </Col>
            <Col span={6} className="estimate-tab2-title-col">
              見積番号
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.drawingNo}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.name}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.clientName}
            </Col>
            <Col span={6} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.estimateNo}
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={12} className="estimate-tab2-title-col">
              係数
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: '#e5e5e5', color: '#1063aa' }}>
              受注形態
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: '#e5e5e5', color: '#1063aa' }}>
              納期
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: '#e5e5e5', color: '#1063aa' }}>
              取引先
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: '#e5e5e5', color: '#1063aa' }}>
              管理費
            </Col>
          </Row>
          <Row className="estimate-tab2-title-row">
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffOrders}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffDeliveryDates}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffClient}
            </Col>
            <Col span={3} className="estimate-tab2-title-col" style={{ backgroundColor: 'white', color: 'black' }}>
              {props.selectedEstimateData?.estimateProducts?.otherProduct?.coeffManagements}
            </Col>
          </Row>
        </div>
        {quantityComponent}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
});

export default KouteiquantityBetsuBtn;
