import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Input, Radio, Checkbox, Select, Table as AntTable, Modal, Button, Image, Space } from 'antd';
import { CloseOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import { WorkType, WorkTypeGroup } from '../common/enums';
import { handleKeyPress, regexExp, toDecimal } from '../common/Common';

const RadioGroup = Radio.Group;

let yousetsu1Datas = [];
yousetsu1Datas.push({
  key: 1,
  yousetsuType: 'アルゴン',
  senchou: 0,
  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'off',
});
let yousetsu2Datas = [];
yousetsu2Datas.push({
  key: 1,
  otherYousetsuType: 'ナット溶接',
  otherSenchou: '-',
  count: '1',
  otherSenchouCnt: '',
  otherSenchouKaisu: '',
  otherSenchouYousetsushiage: 'off',
});
let initialYousetsu1 = [];
initialYousetsu1.push({
  key: 1,
  senchou: '',

  senchouKaisu: '',
  senchouYousetsushiage: 'on',

  karitsukeCnt: '',
  karitsukeKaisu: '',
  karitsukeYousetsushiage: 'on',
});
let initialYousetsu2 = [];
initialYousetsu2.push({
  key: 1,
  senchou: 100,

  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'on',
});

const IQ3KouteiInput_Yousetsu = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [weldingList, setWeldingList] = useState([]);
  const [subWeldingList, setSubWeldingList] = useState([]);

  const [yousetsuTypes, setYousetsuTypes] = useState([]);
  const [subYousetsuTypes, setSubYousetsuTypes] = useState([]);

  // 編集モード
  // const editMode = props.editMode;

  useEffect(() => {
    const processes = props.processMaster;
    const weldingDetails = processes?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingDetails?.length > 0) {
      let yousetsu = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmWelding && i.isUsed
      );
      setYousetsuTypes(yousetsu);
      let subYousetsu = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => (i.group === WorkTypeGroup.SmInstSize && i.isUsed) || (i.group === WorkTypeGroup.SmInstThick && i.isUsed)
      );
      setSubYousetsuTypes(subYousetsu);
    }
    let data = props.weldingInfo;

    if (data != undefined) {
      setWeldingList(data?.details?.[0]?.weldingList);

      setSubWeldingList(data?.details?.[0]?.subWeldingList);
    }
  }, [props.weldingInfo, props.processMaster]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addYousetsu1 = (id) => {
    const copyData = weldingList?.slice(); // Create a copy of the original array

    const insertIndex = weldingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = weldingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        yousetsuType: copyData[insertIndex].yousetsuType,
        yousetsuTypeAutoExtract: copyData[insertIndex].yousetsuType,
        senchou: copyData[insertIndex].senchou,
        senchouAutoExtract: copyData[insertIndex].senchou,
        senchouKaisu: copyData[insertIndex].senchouKaisu,
        senchouKaisuAutoExtract: copyData[insertIndex].senchouKaisu,
        senchouYousetsushiage: copyData[insertIndex].senchouYousetsushiage,
        senchouYousetsushiageAutoExtract: copyData[insertIndex].senchouYousetsushiage,

        karitsukeCnt: copyData[insertIndex].karitsukeCnt,
        karitsukeCntAutoExtract: copyData[insertIndex].karitsukeCnt,
        karitsukeKaisu: copyData[insertIndex].karitsukeKaisu,
        karitsukeKaisuAutoExtract: copyData[insertIndex].karitsukeKaisu,
        karitsukeYousetsushiage: copyData[insertIndex].karitsukeYousetsushiage,
        karitsukeYousetsushiageAutoExtract: copyData[insertIndex].karitsukeYousetsushiage,
        total1: copyData[insertIndex].total1,
        total2: copyData[insertIndex].total2,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeldingList(copyData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].weldingList = copyData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const deleteYousetsu1 = (id) => {
    if (weldingList?.length > 1) {
      const updatedData = weldingList?.filter((item) => item.id !== id);

      setWeldingList(updatedData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].weldingList = updatedData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const addYousetsu2 = (id) => {
    const copyData = subWeldingList?.slice(); // Create a copy of the original array

    const insertIndex = subWeldingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = subWeldingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        subWeldingType: copyData[insertIndex].subWeldingType,
        subWeldingTypeAutoExtract: copyData[insertIndex].subWeldingType,
        daimeter: copyData[insertIndex].daimeter,
        daimeterAutoExtract: copyData[insertIndex].daimeter,
        number: copyData[insertIndex].number,
        numberAutoExtract: copyData[insertIndex].number,
        count: copyData[insertIndex].count,
        countAutoExtract: copyData[insertIndex].count,
        yousetsushiage: copyData[insertIndex].yousetsushiage,
        total: copyData[insertIndex].total,
        subWeldingGroupType: copyData[insertIndex].subWeldingGroupType,
        subWeldingGroupTypeAutoExtract: copyData[insertIndex].subWeldingGroupType,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSubWeldingList(copyData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].subWeldingList = copyData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const deleteYousetsu2 = (id) => {
    if (subWeldingList?.length > 1) {
      const updatedData = subWeldingList?.filter((item) => item.id !== id);

      setSubWeldingList(updatedData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].subWeldingList = updatedData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const onYousetsu1ValUpdate = (id, event, types) => {
    const temp = JSON.parse(JSON.stringify(weldingList));
    let updatedData = [];
    if (types === 'yousetsuType') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
          if (types === 'senchou' || types === 'senchouKaisu') {
            // Calculate and update totalCount
            updatedRow.total1 = toDecimal(toDecimal(updatedRow.senchou, 2) * updatedRow.senchouKaisu, 2);
          } else if (types === 'karitsukeCnt' || types === 'karitsukeKaisu') {
            // Calculate and update totalCount
            updatedRow.total2 = updatedRow?.karitsukeCnt * updatedRow?.karitsukeKaisu;
          }

          return updatedRow;
        }
        return row;
      });
    }
    setWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].weldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsu1ValUpdateOnBlur = (id, event, types) => {
    const temp = JSON.parse(JSON.stringify(weldingList));
    let updatedData = [];

    updatedData = temp?.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [types]: toDecimal(event.target.value, 2) };

        return updatedRow;
      }
      return row;
    });

    setWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].weldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsu2ValUpdate = (id, event, types) => {
    // subWeldingType
    const temp = JSON.parse(JSON.stringify(subWeldingList));
    let updatedData = [];
    if (types === 'subWeldingType') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event };
          const getGroup = subYousetsuTypes?.filter((i) => i.id === event);
          updatedRow.subWeldingGroupType = getGroup ? getGroup[0].group : 0;
          return updatedRow;
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
          if (types === 'number' || types === 'count') {
            // Calculate and update totalCount
            updatedRow.total = updatedRow.number * updatedRow.count;
          }

          return updatedRow;
        }
        return row;
      });
    }
    setSubWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].subWeldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsu2ValUpdateOnBlur = (id, event, types) => {
    const temp = JSON.parse(JSON.stringify(subWeldingList));
    let updatedData = [];

    updatedData = temp?.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [types]: toDecimal(event.target.value, 2) };

        return updatedRow;
      }
      return row;
    });

    setSubWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].subWeldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsu1ValSelectUpdate = (i, event, name) => {
    if (name == 'yousetsuType') {
      const data = [...weldingList];
      data[i][name] = event;
      setWeldingList(data);
    } else {
      const data = [...subWeldingList];
      data[i][name] = event;
      setSubWeldingList(data);
    }
  };
  const gettypeName = (id) => {
    for (let i = 0; i < yousetsuTypes.length; i++) {
      if (yousetsuTypes[i].id === id) {
        return yousetsuTypes[i].name;
      }
    }
  };
  const getSubtypeName = (id) => {
    for (let i = 0; i < subYousetsuTypes.length; i++) {
      if (subYousetsuTypes[i].id === id) {
        return subYousetsuTypes[i].name;
      }
    }
  };

  const firstYousetsuComponent = (
    <>
      {weldingList?.map((item, index) => {
        const {
          id,
          yousetsuType,
          senchou,
          senchouKaisu,
          senchouYousetsushiage,
          karitsukeCnt,
          karitsukeKaisu,
          total1,
          total2,
          karitsukeYousetsushiage,
        } = item;
        return (
          <>
            <tr key={index}>
              <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {index + 1}
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {/* {editMode ? (
                  <> */}
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 10, width: '35%' }}>{gettypeName(item.yousetsuTypeAutoExtract)}</label>
                <Select
                  name="yousetsuType"
                  className="yousetsu-select"
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'yousetsuType')}
                  // defaultValue={yousetsuType[0].value}
                  value={yousetsuType}
                  style={{ width: '58%' }}
                >
                  {yousetsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* </>
                ) : (
                  <>{yousetsuType}</>
                )} */}
              </td>

              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>線長</td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{item.senchouAutoExtract}</label>
                <Input
                  name="senchou"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={senchou}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchou')}
                  onBlur={(e) => onYousetsu1ValUpdateOnBlur(id, e, 'senchou')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                ></Input>
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                  {item.senchouKaisuAutoExtract}
                </label>
                <Input
                  name="senchouKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={senchouKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchouKaisu')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, true);
                  }}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="senchouYousetsushiage"
                  defaultValue={0}
                  className="radioCustomerGpEdit input-editable"
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchouYousetsushiage')}
                  value={senchouYousetsushiage}
                >
                  <Radio value={0}>適用</Radio>
                  <Radio value={1}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className="input-non-editable"
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total1}
                ></Input>
              </td>

              {/* {editMode ? (
                <> */}
              <td
                style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                className="min"
                rowSpan={2}
              >
                <Row justify="center">
                  <PlusCircleFilled
                    className="add-remove-icon"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    // onClick={addYousetsu1}
                    onClick={() => addYousetsu1(id)}
                  />
                  <CloseCircleFilled className="add-remove-icon" onClick={() => deleteYousetsu1(id)} />
                </Row>
              </td>
              {/* </>
              ) : (
                <>
                  <td
                    rowSpan={2}
                    style={{ border: '1px', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                  ></td>
                </>
              )} */}
            </tr>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>仮付</td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                  {item.karitsukeCntAutoExtract}
                </label>
                <Input
                  name="karitsukeCnt"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={karitsukeCnt}
                  // onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeCnt')}
                  // onBlur={(e) => onYousetsu1ValUpdateOnBlur(id, e, 'karitsukeCnt')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, true);
                  }}
                ></Input>
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                  {item.karitsukeKaisuAutoExtract}
                </label>
                <Input
                  name="karitsukeKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={karitsukeKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeKaisu')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, true);
                  }}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="karitsukeYousetsushiage"
                  defaultValue={0}
                  className="radioCustomerGpEdit input-editable"
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeYousetsushiage')}
                  value={karitsukeYousetsushiage}
                >
                  <Radio value={0}>適用</Radio>
                  <Radio value={1}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total2}
                ></Input>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
  const secondYousetsuComponent = (
    <>
      {subWeldingList?.map((item, index) => {
        const { id, subWeldingType, daimeter, number, count, yousetsushiage, total, subWeldingGroupType } = item;
        return (
          <>
            <tr key={index}>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
              <td style={{ verticalAlign: 'middle' }}>
                {/* {editMode ? (
                  <> */}
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 10, width: '35%' }}>{getSubtypeName(item.subWeldingTypeAutoExtract)}</label>
                <Select
                  name="otherYousetsuType"
                  className="yousetsu-select"
                  // onChange={(e) => setShudousutaddoSel(e.target.value)}
                  // onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'otherYousetsuType')}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'subWeldingType')}
                  // style={{ fontSize: '13px' }}
                  // defaultValue={yousetsuType_tentsuke[0].value}
                  value={subWeldingType}
                  style={{ width: '58%' }}
                >
                  {subYousetsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </td>

              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{item.daimeterAutoExtract}</label>
                <Input
                  name="otherSenchou"
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={daimeter}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'daimeter')}
                  onBlur={(event) => onYousetsu2ValUpdateOnBlur(id, event, 'daimeter')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                ></Input>
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{item.numberAutoExtract}</label>
                <Input
                  name="count"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={number}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'number')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, true);
                  }}
                ></Input>
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{item.countAutoExtract}</label>
                <Input
                  name="otherSenchouKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                  value={count}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'count')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, true);
                  }}
                ></Input>
              </td>

              <td>
                <Input
                  name="otherSenchouCnt"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  value={total}
                ></Input>
              </td>

              <td
                style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                className="min"
              >
                <Row justify="center">
                  <PlusCircleFilled
                    className="add-remove-icon"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    // onClick={addYousetsu2}
                    onClick={() => addYousetsu2(id)}
                  />
                  <CloseCircleFilled className="add-remove-icon" onClick={(event) => deleteYousetsu2(id)} />
                </Row>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );

  return (
    <>
      {/* 溶接 */}

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput " style={{ width: '99%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '1%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '17.4%' }} className="tbl-header">
                溶接種類
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                種類/形状
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                長さ/箇所
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '7%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{weldingList?.length === 0 ? <></> : <>{firstYousetsuComponent}</>} </tbody>
        </Table>
      </Row>

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '92.3%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '1%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '17.4%' }} className="tbl-header">
                サプ溶接種類
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                径(mm)
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                回数
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{subWeldingList?.length === 0 ? <></> : <>{secondYousetsuComponent}</>} </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Yousetsu;
