/**
 * クラス名：板金のブランクの1ピアス加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Input } from 'antd';

import { handleKeyPress } from '../../../common/Common';

const Device_YousetsuShiage_DifficultyFactor = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [diffFactorCoeff, setDiffFactorCoeff] = useState([]);
  const [materialType, setMaterialType] = useState([]);
  const [filterKeys, setFilterKeys] = useState([]);
  useEffect(() => {
    setEditMode(props.editMode);

    setDiffFactorCoeff(props?.detailsData?.diffFactorCoeff);
    setMaterialType(props.materialTypes?.filter((item) => item.info.isUsed));
    const usedItems = props.materialTypes?.filter((item) => item.info.isUsed);

    // Extract the id values from the filtered items
    const usedIds = usedItems?.map((item) => item.id);

    setFilterKeys(usedIds);
  }, [props.editMode, props.detailsData, props.materialTypes]);

  const diffFactorCoeffChange = (event, key) => {
    const newList = { ...diffFactorCoeff };

    newList[key] = event.target.value;
    setDiffFactorCoeff(newList);
    props.updateDiffFactorCoeff(newList);
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              Object.keys(diffFactorCoeff)?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))?.length > 8
                ? '100%'
                : Object.keys(diffFactorCoeff)?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))?.length *
                    98 +
                  196 +
                  'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <label className="tbl-header">材質区分</label>
            </td>

            {materialType?.map((item, index) => (
              <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                {item.name}
              </td>
            ))}
          </tr>
          <tr style={{ textAlign: 'center' }}>
            <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
              難易度係数
            </td>
            {Object.keys(diffFactorCoeff)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <td key={key}>
                  <Input
                    value={diffFactorCoeff[key]}
                    onChange={(e) => diffFactorCoeffChange(e, key)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
          </tr>
        </Table>
      </div>
    </>
  );
});

export default Device_YousetsuShiage_DifficultyFactor;
