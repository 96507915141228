import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  toDecimal,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const IQ3KouteiCalculate_Yosetsu = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState(0);
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);

  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);

  const [subYousetsusArr, setSubYousetsuArr] = useState([]);
  const [yousetsusArr, setYousetsuArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [weldingAmt, setWeldingAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const [sizeCoef, setSizeCoef] = useState(0);
  const [diffCoeff, setDiffCoeff] = useState(0);
  const [partCount, setPartCount] = useState(0);
  const [thick, setThick] = useState(0);
  const [weldInput, setWeldInput] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setWeldingAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmWelding);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    let subDataTypeArr = [];
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      sagyoTimeSec += element.sagyoTime;
      if (!subDataTypeArr.includes(element?.processTypeId)) {
        subDataTypeArr.push(element?.processTypeId);
        dandoriAmtSum += element.dandoriAmt;
        dandoriTimeSec += element.dandoriTime;
      }
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.round(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let deviceMst = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);

    let weldingAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let weldingAdditionsEditData = weldingAdditions?.editItems;
    let editDataFlg = weldingAdditions?.editDataFlg;
    // 溶接工程入力情報取得
    let weldingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    setWeldInput(weldingProcessInput);

    let detailItems = [];
    let editDetailItems = [];
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == weldingList.yousetsuType
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: Number(weldingList.senchou), //溶接加工長
        karitsukeCnt: Number(weldingList.karitsukeCnt), //仮付
        senchouKaisu: Number(weldingList.senchouKaisu), //溶接加工長回数
        karitsukeKaisu: Number(weldingList.karitsukeKaisu), //仮付回数
        daimeter: 0, // 径
        number: 0, // 加工数
        count: 0, // 回数
        weldType: 0, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }

    //サブ溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.subWeldingList?.length; j++) {
      let subWeldingList = weldingProcessInput?.details?.[0]?.subWeldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == subWeldingList.subWeldingType
      )?.[0];
      let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
      detailItems.push({
        id: detailItemsId + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: 0, //溶接加工長
        karitsukeCnt: 0, //仮付
        senchouKaisu: 0, //溶接加工長回数
        karitsukeKaisu: 0, //仮付回数
        daimeter: subWeldingList.daimeter, // 径
        number: subWeldingList.number, // 加工数
        count: subWeldingList.count, // 回数
        weldType: 1, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }

    editDetailItems = [];
    let weldingAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: weldingAdditions?.dataType ? weldingAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: weldingAdditions?.editItems ? weldingAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    weldingAdditions = weldingAddition;

    if (weldingAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        // 数量
        let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
        setPartCount(partCnt);
        // 板厚
        let thickness = parameters?.materialIq3?.filter(
          (item) => item.id == props?.selectedIQ3DataDetail?.thickness
        )?.[0]?.info?.thick;
        setThick(thickness);
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の板厚により難易度係数を取得
        let diffCoef = getDiffCoef(deviceMst, thickness, materialTypeId);
        setDiffCoeff(diffCoef);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);
        setSizeCoef(sizeCoef);

        //溶接情報設定
        let mainWeldAdditions = orgData?.filter((item) => item.weldType == 0);
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          let dataItemByProcessInput = mainWeldAdditions[i];

          //重量と面積が０の場合、計算しない。
          if (weight === 0 && area === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          // 加工長
          let sagyoLen = Number(weldingProcessInputInfo?.senchou);
          let karitsukeCnt = Number(weldingProcessInputInfo?.karitsukeCnt);

          //加工長が０の場合、計算しない。
          if (sagyoLen === 0 && karitsukeCnt === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, weldingProcessInputInfo?.yousetsuType);
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTime : 0;
          // 段取時間の合計

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.yousetsuType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

          // 作業時間（溶接時間＋仮付時間）
          // 溶接線長により該当種類の加工速度を取得
          let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, weldingProcessInputInfo?.yousetsuType);

          // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
          let totalSagyoLen = sagyoLen * Number(weldingProcessInputInfo?.senchouKaisu);
          let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
            ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef * 60
            : 0;
          // 仮付時間
          let tempAttachTimeSecParm = getTempAttachTime(deviceMst, thickness, weldingProcessInputInfo?.yousetsuType);
          // 仮付時間   仮付時間＊仮付箇所＊仮付回数＊難易度係数＊サイズ係数
          let tempAttachTimeSec =
            tempAttachTimeSecParm * diffCoef * sizeCoef * weldingProcessInputInfo?.karitsukeKaisu * karitsukeCnt;
          // 作業時間（溶接時間＋仮付時間）
          dataItemByProcessInput.sagyoTime =
            sagyoTimeSec !== undefined && tempAttachTimeSec !== undefined ? sagyoTimeSec + tempAttachTimeSec : 0;
          totalSagyoTm += dataItemByProcessInput.sagyoTime;

          // 作業金額
          let mSagyoTimeMin = dataItemByProcessInput.sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        //サブ溶接情報設定
        let subWeldAdditions = orgData?.filter((item) => item.weldType == 1);

        for (let i = 0; i < weldingProcessInput?.details?.[0]?.subWeldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.subWeldingList[i];
          let dataItemByProcessInput = subWeldAdditions[i];

          // 加工長
          let subSagyoNo = Number(dataItemByProcessInput?.number);
          let subSagyoCnt = Number(dataItemByProcessInput?.count);

          //重量と面積が０の場合、計算しない。
          if (weight === 0 && area === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          // 径が０の場合、計算しない。
          let daimeter = Number(dataItemByProcessInput?.daimeter);
          if (daimeter === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, weldingProcessInputInfo?.subWeldingType);
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTimeSec : 0;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.subWeldingType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = Number.isFinite((mSetTimeMin * mSetHRate) / 60.0) ? (mSetTimeMin * mSetHRate) / 60.0 : 0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          // 作業時間（サブ溶接時間）
          let subWeldTm = getSubWebTm(
            deviceMst,
            thickness,
            weldingProcessInputInfo?.subWeldingType,
            weldingProcessInputInfo?.subWeldingGroupType,
            sizeCoef
          );
          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          if (subWeldTm * subSagyoNo * subSagyoCnt * diffCoef * sizeCoef !== undefined) {
            dataItemByProcessInput.sagyoTime = subWeldTm * subSagyoNo * subSagyoCnt * diffCoef * sizeCoef;
          } else {
            dataItemByProcessInput.sagyoTime = 0;
          }
          totalSagyoTm += dataItemByProcessInput.sagyoTime ? dataItemByProcessInput.sagyoTime : 0;

          // 作業金額
          let mSagyoTimeMin = Number.isFinite(dataItemByProcessInput.sagyoTime / 60)
            ? dataItemByProcessInput.sagyoTime / 60
            : 0;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = Number.isFinite((mSagyoTimeMin * mSagyoHRate) / 60.0)
            ? (mSagyoTimeMin * mSagyoHRate) / 60.0
            : 0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        // 段取合計
        let weldIdUnq = [];
        let weldIds = dataItemsByProcessInput?.map((item) => item.processTypeId);
        dataItemsByProcessInput?.forEach((e) => {
          if (!weldIdUnq.includes(e.processTypeId) && e.dandoriAmt > 0 && e.dandoriTime > 0) {
            weldIdUnq.push(e.processTypeId);
            totalDandoriTm += e.dandoriTime ? e.dandoriTime : 0;
            totalDandori += e.dandoriAmt ? e.dandoriAmt : 0;
            let index = weldIds.indexOf(e.processTypeId);
            if (dataItemsByProcessInput[index]) {
              dataItemsByProcessInput[index]['dandoriTime'] = e.dandoriTime;
              dataItemsByProcessInput[index]['dandoriAmt'] = e.dandoriAmt;
            }
          }
        });

        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        weldingAdditions.totalDandori.dataItemsAmt = totalDandori;
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        weldingAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);
        weldingAdditions.totalSagyo.dataItemsAmt = totalSagyo;

        setSagyoTimeSum(totalSagyoTm);
        weldingAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        setTotalTimeSum(totalTimeSum);
        weldingAdditions.totalDataTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        weldingAdditions.totalDataPrice = totalSum;

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);

        // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
        if (weldingAdditionsEditData?.length > 0 && editDataFlg) {
          toBEdit = JSON.parse(JSON.stringify(weldingAdditionsEditData));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      weldingAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0]?.totalSum);
        weldingAdditions.totalEditPrice = totals[0]?.totalSum;
        weldingAdditions.totalDandori.editItemsAmt = totals[0]?.totalSum;

        setTotalTime(totals[0]?.totalTimeSum);
        weldingAdditions.totalEditTime = totals[0]?.totalTimeSum;
        weldingAdditions.totalDandori.editItemsTime = totals[0]?.totalTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);

        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(weldingAdditions);
    }
    if (weldingAdditions?.dataType == DataType.Data) {
      setWeldingAmt(weldingAdditions.totalDataPrice);
    } else {
      setWeldingAmt(weldingAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(weldingAdditions, WorkType.SmWelding);
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  const yousetsu = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const calDandoriTime = (deviceMst, weight, area, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let deviceDandori = Object.entries(deviceMst.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    let dandoriTimeSec = deviceDandoriTm * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst, weldType) => {
    //段階時間
    let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;

    //装置の段階時間チャージ
    let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === weldType
    )?.[0];
    let mSetHRate = 0;
    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mSetHRate = item?.prep;
      }
    });

    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = dandoriAmt ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const calSagyoTime = (deviceMst, weight, area, processCount) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊検査数＊サイズ係数
    let dandoriTimeSec = processCount * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst, weldType) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
      ([key, data]) => parseInt(key) === weldType
    )?.[0];
    let mWorkHRate = 0;
    deviceCharges?.map((item, index) => {
      if (index > 0) {
        mWorkHRate = item?.work;
      }
    });
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = cSagyoAmtKonpo ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const getSagyoSpeed = (deviceMst, sencho, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    // 溶接線長
    let workSpeedThMaster = deviceMst?.details?.workSpeedThMaster?.filter(
      (item) => (item.value == sencho || item.value > sencho) && !item.isOutOfRange
    )?.[0];

    let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    workSpeedThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == workSpeedThMaster?.no && !item.isOutOfRange)?.[0]
          ?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getDiffCoef = (deviceMst, thickness, materialTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 溶接難易度
    let weldDifficultyCoeffThMaster = deviceMst?.details?.weldDifficultyCoeffThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];

    let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.weldDifficultyCoeffThItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeId
    )?.[0];

    // 溶接難易度
    let childPartsCoeff = 0;
    weldDifficultyCoeffThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter(
          (item) => parseInt(item.no) == weldDifficultyCoeffThMaster?.no && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
    childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
    return childPartsCoeff;
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    partsCoeff = partsCoeff ? partsCoeff : 0;
    return partsCoeff;
  };

  const getTempAttachTime = (deviceMst, thickness, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;

    let tempInstThMaster = deviceMst?.details?.tempInstThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];

    let tempInstThItems = Object.entries(deviceMst.details?.tempInstThItems)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];

    // 仮付け時間
    let tempAttachTime = 0;
    tempInstThItems?.map((item, index) => {
      if (index > 0) {
        tempAttachTime = item?.filter((item) => parseInt(item.no) == tempInstThMaster?.no && !item.isOutOfRange)?.[0]
          ?.value;
      }
    });
    tempAttachTime = tempAttachTime ? tempAttachTime : 0;
    return tempAttachTime;
  };

  const getSubWebTm = (deviceMst, thickness, weldTypeId, weldTypeGroupId, sizeCoef) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let tempInstThMaster = [];
    let tempInstThItems = [];
    if (weldTypeGroupId == WorkTypeGroup.SmInstThick) {
      tempInstThMaster = deviceMst?.details?.instThickThMaster?.filter(
        (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
      )?.[0];

      tempInstThItems = Object.entries(deviceMst.details?.instThickThItems)?.filter(
        ([key, data]) => parseInt(key) === weldTypeId
      )?.[0];
    } else {
      tempInstThMaster = deviceMst?.details?.instSizeThMaster?.filter(
        (item) => (item.value == sizeCoef || item.value > sizeCoef) && !item.isOutOfRange
      )?.[0];

      tempInstThItems = Object.entries(deviceMst.details?.instSizeThItems)?.filter(
        ([key, data]) => parseInt(key) === weldTypeId
      )?.[0];
    }

    // 仮付け時間
    let tempAttachTime = 0;
    tempInstThItems?.map((item, index) => {
      if (index > 0) {
        tempAttachTime = item?.filter((item) => parseInt(item.no) == tempInstThMaster?.no && !item.isOutOfRange)?.[0]
          ?.value;
      }
    });
    tempAttachTime = tempAttachTime ? tempAttachTime : 0;
    return tempAttachTime;
  };

  const getYousetsuTypes = () => {
    let subYousetsus = [];
    let yousetsus = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subYousetsus.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              yousetsus.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubYousetsuArr(subYousetsus);
    setYousetsuArr(yousetsus);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('yosetsuProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('yosetsuProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownYosetsuIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpYosetsuIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('yosetsuProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownYosetsuIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpYosetsuIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j][param] = toDecimal(editedData[j]?.[param], 2);
    setEditedData(newState);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(processArr)));
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);

    setTotalDandoriAmt(dandoriAmtSum ? dandoriAmtSum : 0);
    setTotalDandoriTime(dandoriTimeSum ? dandoriTimeSum : 0);

    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setWeldingAmt(updateEditedData.totalDataPrice);
    } else {
      setWeldingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmWelding);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }

    newState[index][objId] = Number(value);

    updateEditedData.editItems[index][objId] = newState[index][objId];

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'YousetsuDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'YousetsuDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(
              newState[index].dandoriAmt,
              timeType,
              timeAct,
              value,
              objId,
              newState?.[index]?.processTypeId
            );
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(
              newState[index].dandoriAmt,
              timeType,
              timeAct,
              value,
              objId,
              newState?.[index]?.processTypeId
            );
          }
          updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
        }
      }
    } else if (objId === 'sagyoTime') {
      //checkInputWithColor(false, '', index + 'yosetsuSagyoAmt', newState[index].sagyoAmt, 'green');
      if (document.getElementById(index + 'yosetsuSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'yosetsuSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].sagyoAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(
              newState[index].sagyoAmt,
              timeType,
              timeAct,
              value,
              objId,
              newState?.[index]?.processTypeId
            );
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(
              newState[index].sagyoAmt,
              timeType,
              timeAct,
              value,
              objId,
              newState?.[index]?.processTypeId
            );
          }
          updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          //checkInputWithColor(false, '', index + 'yosetsuSagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (
      objId === 'karitsukeKaisu' ||
      objId === 'senchou' ||
      objId === 'senchouKaisu' ||
      objId === 'karitsukeCnt'
    ) {
      // 作業時間（溶接時間＋仮付時間）
      // 溶接線長により該当種類の加工速度を取得
      let sagyoLen = Number(newState?.[index]?.senchou);
      let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, newState?.[index]?.processTypeId);

      // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
      let totalSagyoLen = sagyoLen * Number(newState?.[index]?.senchouKaisu);
      let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
        ? (totalSagyoLen / sagyoSpeed) * diffCoeff * sizeCoef * 60
        : 0;
      // 仮付時間
      let tempAttachTimeSecParm = getTempAttachTime(deviceMst, thick, newState?.[index]?.processTypeId);
      // 仮付時間（仮付時間＊仮付回数*仮付箇所＊難易度係数＊サイズ係数）
      let tempAttachTimeSec =
        tempAttachTimeSecParm *
        diffCoeff *
        sizeCoef *
        newState?.[index]?.karitsukeKaisu *
        newState?.[index]?.karitsukeCnt;
      // 作業時間（溶接時間＋仮付時間）
      newState[index].sagyoTime =
        sagyoTimeSec !== undefined && tempAttachTimeSec !== undefined ? sagyoTimeSec + tempAttachTimeSec : 0;
      // totalSagyoTm += dataItemByProcessInput.sagyoTime;

      if (document.getElementById(index + 'yosetsuSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'yosetsuSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          // 作業金額
          let mSagyoTimeMin = newState?.[index]?.sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === newState?.[index]?.processTypeId
          )?.[0];
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          newState[index].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
        }
      }
    } else if (objId === 'number' || objId === 'count') {
      // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
      // 作業時間（サブ溶接時間）
      let subWeldIndex = weldInput?.details?.[0]?.weldingList.length - index;
      let subWeldTm = getSubWebTm(
        deviceMst,
        thick,
        weldInput?.details?.[0]?.subWeldingList[subWeldIndex].subWeldingType,
        weldInput?.details?.[0]?.subWeldingList[subWeldIndex].subWeldingGroupType,
        sizeCoef
      );
      // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
      if (subWeldTm * newState[index]['number'] * newState[index]['count'] * diffCoeff * sizeCoef !== undefined) {
        newState[index].sagyoTime =
          subWeldTm * newState[index]['number'] * newState[index]['count'] * diffCoeff * sizeCoef;
      } else {
        newState[index].sagyoTime = 0;
      }

      if (document.getElementById(index + 'yosetsuSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'yosetsuSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          // 作業金額
          let mSagyoTimeMin = newState[index].sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;

          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldInput?.details?.[0]?.subWeldingList[subWeldIndex].subWeldingType
          )?.[0];

          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          newState[index].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += element.sagyoTime;
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += parseFloat(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime); */
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setWeldingAmt(updateEditedData.totalDataPrice);
    } else {
      setWeldingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmWelding);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType, weldType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst, weldType);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst, weldType);
    }

    return editedAmt;
  };

  const getSetupTmCmt = (type) => {
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{type}段取時間＝基本段取時間ｘ子部品サイズ係数ｘ段取個数係数</p>
      </div>
    );
  };

  const getSetupTotalTm = () => {
    let cmt = '段取時間＝';
    let subDataTypeArr = [];
    for (let j = 0; j < processArr?.length; j++) {
      if (!subDataTypeArr?.includes(processArr[j]?.processTypeId)) {
        subDataTypeArr?.push(processArr[j]?.processTypeId);
        cmt += processArr[j]?.processType + '段取時間+';
      }
    }
    cmt = cmt?.slice(0, -1);
    return (
      <div style={{ height: 40 }}>
        <p style={{ fontSize: 12, padding: 10 }}>{cmt}</p>
      </div>
    );
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yousetsu}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{weldingAmt ? JPYs.format(Math.round(weldingAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalAmtIn"
              style={{
                color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalTimeIn"
              style={{
                color: Math.round(totalTime) === Math.round(totalTimeSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm}>時間</Popover>
          </Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('YousetsuDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpYousetsuDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownYousetsuDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriAmtIn"
              style={{
                color: Math.round(totalDandoriAmt) === Math.round(dandoriAmtSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriTimeIn"
              style={{
                color: Math.round(totalDandoriTime) === Math.round(dandoriTimeSum) ? 'black' : '#4cc12f',
              }}
            >
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={getSetupTotalTm()}>時間</Popover>
          </Col>
        </Row>
        <div id={'divYousetsuDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            let unqArr = [];
            for (let j = 0; j < processArr?.length; j++) {
              if (!unqArr.includes(processArr[j]?.processTypeId)) {
                unqArr.push(processArr[j]?.processTypeId);
                let setupTmCmt = getSetupTmCmt(processArr[j]?.processType);
                arr.push(
                  <div>
                    {/* ○○_標準 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'YousetsuDandoriAmt'}>
                          {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                            ? JPYs.format(Math.round(processArr[j]?.dandoriAmt))
                            : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {
                          <label id={j + 'YousetsuDndoriTime'}>
                            {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                          </label>
                        }
                      </Col>

                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={j + 'YousetsuDandoriAmtIn'}
                          value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                          defaultValue={0}
                          style={{
                            border: 'none',
                            color:
                              processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                                ? 'black'
                                : checkShowHideIcon(j + 'YousetsuDandoriAmtCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          onValueChange={(e) => {
                            //checkRegex(e.target.value);
                            if (editedData[j]?.dandoriAmt != e) {
                              settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                              checkInputWithColor(false, '', j + 'YousetsuDandoriAmt', e, 'amt');
                            }
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={j + 'YousetsuDandoriAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                              id={j + 'YosetsuDandoriTimeIn'}
                              style={{
                                width: '100%',
                                color:
                                  processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                    ? handleShowHide(
                                        parseFloat(editedData[j]?.dandoriAmt),
                                        editedData[j]?.dandoriAmt,
                                        j + 'YosetsuDandoriAmtCu'
                                      )
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(e.target.value, j, 'YousetsudandoriAmt', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'YosetsuDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={2} className="formula-column formula-column-right-border">
                        <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                      </Col>
                      <Col span={2} className="formula-column">
                        <Popover content={setupTmCmt}>時間</Popover>
                      </Col>
                    </Row>
                  </div>
                );
              }
            }
            return arr;
          })()}
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('YousetsuSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpYousetsuSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />

              <CaretDownOutlined id={'arrowDownYousetsuSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label style={{ marginLeft: 5 }}> 作業金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoAmtIn"
              style={{
                color: Math.round(sagyoAmtSum) === Math.round(totalSagyoAmt) ? 'black' : '#4cc12f',
              }}
            >
              {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoTimeIn"
              style={{
                color: Math.round(sagyoTimeSum) === Math.round(totalSagyoTime) ? 'black' : '#4cc12f',
              }}
            >
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={1} className="formula-column"></Col>
        </Row>
        <div id={'divYousetsuSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let i = 0; i < editedData?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined
                          id={'arrowUpYosetsuIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2 }}
                          onClick={(e) => subpartDetail(i)}
                        />
                        <CaretDownOutlined
                          id={'arrowDownYosetsuIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                          onClick={(e) => subpartDetail(i)}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processType}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {/* ¥ */}
                      <label id={i + 'yosetsuSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'yosetsuSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[i]?.sagyoAmt != e) {
                            settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'yosetsuSagyoAmt', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt
                              ? 'black'
                              : checkShowHideIcon(i + 'yosetsuSagyoAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={i + 'yosetsuSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                            id={i + 'yosetsuSagyoTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                  ? 'black'
                                  : Number(editedData[i]?.senchou) !== Number(oldEditedData[i]?.senchou) ||
                                    Number(editedData[i]?.senchouKaisu) !== Number(oldEditedData[i]?.senchouKaisu) ||
                                    Number(editedData[i]?.karitsukeKaisu) !==
                                      Number(oldEditedData[i]?.karitsukeKaisu) ||
                                    Number(editedData[i]?.karitsukeCnt) !== Number(oldEditedData[i]?.karitsukeCnt) ||
                                    Number(editedData[i]?.number) !== Number(oldEditedData[i]?.number) ||
                                    Number(editedData[i]?.count) !== Number(oldEditedData[i]?.count)
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.workAmt_p2}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover
                        content={processArr[i]?.weldType === 0 ? helpCmt.workTm_yosetsu : helpCmt.workTm_subyosetsu}
                      >
                        時間
                      </Popover>
                    </Col>
                  </Row>
                  <div id={'yosetsuProcessIQ3' + i} style={{ display: 'none' }}>
                    <div>
                      {/* 作業金額/時間 */}
                      {processArr[i]?.weldType === 0 ? (
                        <div>
                          {/* 溶接加工長/加工回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                溶接加工長/加工回数
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="senchou">{processArr[i]?.senchou}</label>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'senchouKaisu'}>{processArr[i]?.senchouKaisu}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.senchou}
                                id={i + 'senchouIn'}
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    Number(editedData[i]?.senchou) === Number(processArr[i]?.senchou) ? 'black' : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'senchou', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.senchouKaisu}
                                id={i + 'senchouKaisuIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    Number(editedData[i]?.senchouKaisu) === processArr[i]?.senchouKaisu
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'senchouKaisu', '', '')}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={2} className="formula-column formula-column-right-border">
                              {/* <Popover content={helpCmt.workAmt_p2}>金額</Popover> */}
                            </Col>
                            <Col span={2} className="formula-column">
                              <Popover content={helpCmt.workTm_yosetsu_yosetsuLen}>時間</Popover>
                            </Col>
                          </Row>

                          {/* 仮付箇所/加工回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                仮付箇所/加工回数
                              </span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'karitsukeCnt'}>{processArr[i]?.karitsukeCnt}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'karitsukeKaisu'}>{processArr[i]?.karitsukeKaisu}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.karitsukeCnt}
                                id={i + 'karitsukeCntIn'}
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.karitsukeCnt) === processArr[i]?.karitsukeCnt
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'karitsukeCnt', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.karitsukeKaisu}
                                id={i + 'karitsukeKaisuIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    Number(editedData[i]?.karitsukeKaisu) === processArr[i]?.karitsukeKaisu
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'karitsukeKaisu', '', '')}
                              />
                            </Col>

                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={2} className="formula-column formula-column-right-border">
                              {/* <Popover content={helpCmt.workAmt_p2}>金額</Popover> */}
                            </Col>
                            <Col span={2} className="formula-column">
                              <Popover content={helpCmt.workTm_yosetsu_karisukeLen}>時間</Popover>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div>
                          {/*  加工数/回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                加工数/回数
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'number'}>{processArr[i]?.number}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'count'}>{processArr[i]?.count}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.number}
                                id={i + 'numberIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color: editedData[i]?.number === processArr[i]?.number ? 'black' : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'number', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.count}
                                id={i + 'countIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color: parseFloat(editedData[i]?.count) === processArr[i]?.count ? 'black' : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'count', '', '')}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          {/*  径(mm) */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                径(mm)
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proDiameter">{processArr[i]?.daimeter}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col"></Col>
                            <Col span={4} className="oyabuhin-detail-col">
                              <label id="proDiameter">{processArr[i]?.daimeter}</label>
                              {/*  <Input
                                value={editedData[i]?.daimeter}
                                id={i + 'daimeterIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.daimeter) === processArr[i]?.daimeter ? 'black' : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e, false);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'diameter', '', '')}
                                onBlur={(e) => {
                                  toTwoDecimal(i, 'daimeter');
                                }}
                              /> */}
                            </Col>
                            <Col span={4} className="input-col"></Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Yosetsu;
