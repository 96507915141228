import React, { useImperativeHandle, forwardRef, useState, useEffect, useRef } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Divider,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  Collapse,
  Tabs,
  Card,
  Carousel,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SplitPane, { Pane } from 'split-pane-react';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Route, Outlet, Routes, useLocation, useNavigate } from 'react-router-dom';

// Content resize
import '../../assets/styles/common.css';
import 'split-pane-react/esm/themes/default.css';
import WithRouter from '../../components/WithRouter';
import calculator from '../../assets/images/calculator.png';
import confirm_icon from '../../assets/images/confirm_icon.png';
import reload from '../../assets/images/reload_icon.png';
import { formatDate, toDecimal, JPYs } from '../common/Common';
import { ParamCalType } from '../../views/common/enums.js';
import { amtEmptyStr } from '../common/Constant';
import { commonModal, confirmDisplayOldNewParamContent } from '../common/CommonModal';
import { getIsEstimateNewParam } from '../common/CommonAPI';
import { async } from 'q';

const { Panel } = Collapse;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;
const materialNm = ['02_SPHC（黒皮）', '03_SPHC（酸洗）'];
const thickness = ['9.0', '0.5'];
const data = [];

const EstimateBasicInfo = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const [costPrice, setCostPrice] = useState([]);
  const [costPriceTotal, setCostPriceTotal] = useState([]);
  const [isReCalOldNewParam, setIsReCalOldNewParam] = useState(false);
  const [isMGrossProfitCoeff, setIsMGrossProfitCoeff] = useState(false);
  const { TextArea } = Input;

  useEffect(() => {
    updateEstimateInfo(props.estimateBasicInfo);
  }, [props.estimateBasicInfo, props.activePartMenu, props?.basicInfoUpdate, props.isReCal]);

  const updateEstimateInfo = (estimateInfo) => {
    //費用合計
    let costPrices = 0;
    let costPriceTotal = 0;
    let epCustomCosts = estimateInfo?.epCustomCosts;
    let quantity = estimateInfo?.estimateProducts?.quantity;
    for (let i = 0; i < epCustomCosts?.length; i++) {
      if (epCustomCosts[i]?.isUseUnit) {
        costPriceTotal += epCustomCosts[i]?.unitPrice * quantity; // IQX_WEBEST-235
      } else {
        costPriceTotal += epCustomCosts[i]?.totalPrice;
      }
    }
    costPrices = Number.isFinite(costPriceTotal / quantity) ? costPriceTotal / quantity : 0;
    setCostPrice(costPrices);
    setCostPriceTotal(costPriceTotal);
    setEstimateBasicInfo(estimateInfo == undefined ? [] : estimateInfo?.estimateProducts);
  };

  const onChange = () => {};

  useImperativeHandle(ref, () => ({
    updateEstimateInfo: updateEstimateInfo,
  }));

  // 再計算ボタン押下
  const onClickReCalEstimate = (e) => {
    props.settingParametersByReCal(
      // 選択した、パラメータタイプを更新して再計算する
      ParamCalType.OldParam,
      false,
      false,
      false,
      false
    ); // IQX_WEBEST-171
  };

  // 再計算しない
  const discardConfirmCancel = () => {
    setIsReCalOldNewParam(false);
  };

  // 新パラで再計算
  const calculateWithNewParam = async () => {
    setIsReCalOldNewParam(false);
    await props.settingParametersByReCal(ParamCalType.NewParam);
    props?.updateIsRecalMaterial(true);
  };

  // 旧パラで再計算
  const calculateWithOldParam = async () => {
    setIsReCalOldNewParam(false);
    await props.settingParametersByReCal(ParamCalType.OldParam);
  };

  // 材質の情報を得る
  const getMaterialTypeIq3 = (id) => {
    let parameters = props?.estimateBasicInfo?.estimateProducts?.calcParameters?.parameters;
    let info = parameters?.materialTypeIq3?.find((item) => item?.id === id);
    return info;
  };

  /* 画面切替警告メッセージのタイトル */
  const confirmScreenChangeTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image preview={false} width={20} src={confirm_icon} style={{ marginTop: 9, marginLeft: 7 }}></Image>
      <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>確認</p>
    </div>
  );

  /* 材料粗利係数のタイトル */
  const mGrossProfitCoeffTitle = (
    <div
      style={{
        width: 850,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>材料粗利係数</p>
    </div>
  );

  /* 材料粗利係数画面 */
  const mGrossProfitCoeffContent = (
    <>
      <div style={{ overflowY: 'auto', marginTop: 5, height: '400px' }}>
        <Row className="estimate-tab2-title-row">
          <Col style={{ width: '7%' }} className="estimate-tab2-title-col">
            No.
          </Col>
          <Col style={{ width: '30%' }} className="estimate-tab2-title-col">
            子部品図番
          </Col>
          <Col style={{ width: '30%' }} className="estimate-tab2-title-col">
            子部品名称
          </Col>
          <Col style={{ width: '20%' }} className="estimate-tab2-title-col">
            材質
          </Col>
          <Col style={{ width: '13%' }} className="estimate-tab2-title-col">
            材質粗利係数
          </Col>
        </Row>
        {props?.estimateBasicInfo?.esIq3Info?.map((iq3, index) => (
          <Row className="estimate-tab2-row">
            <Col style={{ width: '7%' }} className="cm-a-right estimate-tab2-col">
              {index + 1}
            </Col>
            <Col style={{ width: '30%' }} className="cm-a-left estimate-tab2-col">
              {iq3?.drawingNo}
            </Col>
            <Col style={{ width: '30%' }} className="cm-a-left estimate-tab2-col">
              {iq3?.name}
            </Col>
            <Col style={{ width: '20%' }} className="cm-a-left estimate-tab2-col">
              {getMaterialTypeIq3(iq3?.materialTypeIq3Id)?.name}
            </Col>
            <Col style={{ width: '13%' }} className="cm-a-right estimate-tab2-col">
              {getMaterialTypeIq3(iq3?.materialTypeIq3Id)?.info?.lossCoeff}
            </Col>
          </Row>
        ))}
      </div>
    </>
  );

  // 材料粗利係数のボタン押下
  const openMGrossProfitCoeff = () => {
    setIsMGrossProfitCoeff(true);
  };

  // 材料粗利係数画面を閉じる
  const closeMGrossProfitCoeff = () => {
    setIsMGrossProfitCoeff(false);
  };

  return (
    <>
      {/* 見積基本情報 */}
      <div
        style={{
          backgroundColor: '#fafafc',
          padding: 5,
        }}
      >
        {/** 基本情報 */}
        <Card hoverable={false}>
          <Row align="middle">
            <Col span={5}>
              <Tooltip title="再計算" placement="top" overlayClassName="tooltip-text">
                <a onClick={onClickReCalEstimate}>
                  <Image preview={false} src={reload} width={47} style={{ marginLeft: '7px', marginTop: 0 }}></Image>
                </a>
              </Tooltip>
            </Col>
            <Col span={19}>
              <p style={{ margin: 0, fontSize: '17px', paddingLeft: 0 }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.drawingNo}
              </p>
              <p style={{ margin: 0, fontSize: '16px', paddingLeft: 0 }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.name}
              </p>
              <p style={{ margin: 0, fontSize: '16px', paddingLeft: 0 }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.estimateNo}
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.estimateNm}
              </p>
            </Col>
          </Row> */}
          <Row
            style={{
              background: '#364d79',
              marginTop: '5px',
              borderRadius: '3px',
              padding: '3px',
            }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.clientName}</p>
            </Col>
          </Row>
          {/** 総重量*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>総重量</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.totalWeight != undefined
                        ? toDecimal(estimateBasicInfo?.totalWeight, 2) + 'kg'
                        : '0.00kg'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>総表面積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.totalSurfaceArea != undefined
                        ? toDecimal(estimateBasicInfo?.totalSurfaceArea, 2) + 'c㎡'
                        : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>体積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.sizeX != undefined ||
                      estimateBasicInfo?.sizeY != undefined ||
                      estimateBasicInfo?.sizeZ != undefined
                        ? toDecimal(
                            Number.isFinite(
                              (estimateBasicInfo?.sizeX * estimateBasicInfo?.sizeY * estimateBasicInfo?.sizeZ) / 1000
                            )
                              ? (estimateBasicInfo?.sizeX * estimateBasicInfo?.sizeY * estimateBasicInfo?.sizeZ) / 1000
                              : 0,
                            2
                          ) + 'c㎡'
                        : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/** 作成者*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>作成者</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.creater}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>作成日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{formatDate(estimateBasicInfo?.created)}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>最終更新日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{formatDate(estimateBasicInfo?.modified)}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 見積／原価／粗利・粗利率 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>
                {estimateBasicInfo?.quantity == undefined ? ' 数量　0' : ' 数量　' + estimateBasicInfo?.quantity}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>原価</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.costUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.costUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.costTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.costTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px', marginLeft: '10px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>見積</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.estimateUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.estimateUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.estimateTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.estimateTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.grossUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.grossUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.grossTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.round(estimateBasicInfo?.grossTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  height: '98px',
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '5px',
                  marginLeft: '10px',
                }}
              >
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利率</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '7px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '25px', fontWeight: '600', color: 'white' }}>
                      {estimateBasicInfo?.grossRate != undefined
                        ? parseFloat(estimateBasicInfo?.grossRate)?.toFixed(2) + '%'
                        : '0.00%'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 原価詳細 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Carousel afterChange={onChange} style={{ color: 'white' }}>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>材料原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {(estimateBasicInfo?.materialCostUnitPrice &&
                          Number(props.estimateBasicInfo?.estimateProducts?.quantity)
                            ? JPYs.format(
                                Math.round(
                                  estimateBasicInfo?.materialCostTotalPrice /
                                    Number(props.estimateBasicInfo?.estimateProducts?.quantity)
                                )
                              )
                            : amtEmptyStr) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.materialCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.round(estimateBasicInfo?.materialCostTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>製造原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.manufacturingCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(
                                Math.round(
                                  Number.isFinite(
                                    estimateBasicInfo?.manufacturingCostTotalPrice / estimateBasicInfo?.quantity
                                  )
                                    ? estimateBasicInfo?.manufacturingCostTotalPrice / estimateBasicInfo?.quantity
                                    : 0
                                )
                              ) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.manufacturingCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.round(estimateBasicInfo?.manufacturingCostTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>購入品</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.purchaseUnitPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.round(estimateBasicInfo?.purchaseUnitPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.purchaseTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.round(estimateBasicInfo?.purchaseTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>費用</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {costPrice == undefined ? amtEmptyStr : JPYs.format(Math.round(costPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {costPriceTotal == undefined ? amtEmptyStr : JPYs.format(Math.round(costPriceTotal)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>管理費</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.managementFeeTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(
                                Math.round(estimateBasicInfo?.managementFeeTotalPrice / estimateBasicInfo?.quantity)
                              ) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.managementFeeTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.round(estimateBasicInfo?.managementFeeTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}></Col>
              </Row>
            </div>
          </Carousel>
        </Card>
        {/** 係数 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>取引先係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffClient, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>受注形態</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffOrders, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>管理費係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffManagements, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>納期係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffDeliveryDates, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>購入品係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffPurchases, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ marginLeft: '5px' }}>
                <Row>
                  <Col span={24}>
                    <Button
                      key="grossBtn"
                      type="primary"
                      onClick={openMGrossProfitCoeff}
                      className="grossProfitCoeffBtn"
                    >
                      材料粗利係数
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** コメント */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24}>
              <p style={{ margin: 0, fontSize: '12px' }}>コメント</p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              {/* <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.comment}</p> */}
              <TextArea
                style={{
                  verticalAlign: 'Top',
                  fontSize: '12px',
                  height: '115px',
                  background: '#364d79',
                  borderRadius: '5px',
                  padding: '10px',
                  color: 'white',
                }}
                className={'input-non-editable'}
                name="comment"
                id="comment"
                value={estimateBasicInfo?.comment}
                type="text"
                rows={5}
              />
            </Col>
          </Row>
        </Card>

        {/** 新旧パラで計算のメッセージ表示 */}
        {isReCalOldNewParam
          ? commonModal(
              isReCalOldNewParam,
              confirmScreenChangeTitle,
              null,
              null,
              400,
              calculateWithNewParam,
              discardConfirmCancel,
              confirmDisplayOldNewParamContent(calculateWithNewParam, calculateWithOldParam, discardConfirmCancel),
              null,
              null,
              false
            )
          : ''}

        {/** 材料粗利係数のメッセージ表示 */}
        {isMGrossProfitCoeff
          ? commonModal(
              isMGrossProfitCoeff,
              mGrossProfitCoeffTitle,
              null,
              null,
              850,
              null,
              closeMGrossProfitCoeff,
              mGrossProfitCoeffContent,
              null,
              null,
              false
            )
          : ''}
      </div>
    </>
  );
});

export default EstimateBasicInfo;
