/**
 * クラス名：購入品パラメータ
 * 説明：購入品情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Row, Input, Col, Modal, Select, Space, Image } from 'antd';
import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'split-pane-react/esm/themes/default.css';
import '../../assets/styles/common.css';
import checked from '../../assets/images/checked.png';
import { StarOutlined, StarFilled } from '@ant-design/icons';

import { dataStateList, JPYs, formatDate } from './Common';
import { getPurchaseCategoryInfo } from './CommonAPI';

dayjs.extend(customParseFormat);

// const ddlTypes = [
//   { id: 1, key: 1, value: '自動スタッド', label: '自動スタッド' },
//   { id: 2, key: 2, value: 'ナット溶接', label: 'ナット溶接' },
//   { id: 3, key: 3, value: 'ボルト溶接', label: 'ボルト溶接' },
//   { id: 4, key: 4, value: 'カシメ', label: 'カシメ' },
//   { id: 5, key: 5, value: 'ボルト圧入', label: 'ボルト圧入' },
//   { id: 5, key: 6, value: 'ナット圧入', label: 'ナット圧入' },
// ];

// const ddlMaterials = [
//   { id: 1, key: 1, value: 'SPHC', label: 'SPHC' },
//   { id: 2, key: 2, value: 'SPCC', label: 'SPCC' },
//   { id: 3, key: 3, value: 'SECC', label: 'SECC' },
//   { id: 4, key: 4, value: 'SUS', label: 'SUS' },
//   { id: 5, key: 5, value: 'AL', label: 'AL' },
//   { id: 5, key: 6, value: 'CU', label: 'CU' },
// ];

// const purTypeList = (data = [...purchaseCategoryInfo]) => {
//   let list;
//   list = data?.map((item) => {
//     if (item.used === 1) {
//       return { value: item.no, label: item.name };
//     }
//   });
//   return list;
// };

// const autoPurchaseItemsArr = [];
// let sizeArr = [3, 5, 7];
// for (let i = 1; i <= 50; i++) {
//   for (let j = 1; j <= 3; j++) {
//     let randomType = ddlTypes[Math.floor(Math.random() * 6)];
//     let randomMaterial = ddlMaterials[Math.floor(Math.random() * 5)];
//     autoPurchaseItemsArr.push({
//       key: i,
//       id: i,
//       part: `品番 ${i}`,
//       autoType: randomType.label,
//       autoMaterial: randomMaterial.label,
//       autoSize: sizeArr[Math.floor(Math.random() * 3)],
//     });
//   }
// }

// const autoPurchasePriceArr = [];
// for (let i = 1; i <= 50; i++) {
//   autoPurchasePriceArr.push({
//     key: i,
//     id: i,
//     part: `品番 ${i}`,
//     lmtLower: 1,
//     lmtUpper: 100,
//     price: '￥200',
//   });
// }

const CommonPurchaseList = forwardRef((props, ref) => {
  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // 破壊ポップアップを表示させる処理タイプ
  document.body.style.overflowY = 'hidden';

  const [purchasesData, setPurchasesData] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [changeRecordKey, setChangeRecordKey] = useState(0);

  // const [purchaseTypeList, setPurchaseTypeList] = useState();
  const [dataState, setDataState] = useState(0);
  const [dataStatePurchaseType, setDataStatePurchaseType] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [supplierKeyword, setSupplierKeyWord] = useState('');

  // const [autoPurchaseItems, setAutoPurchaseItems] = useState(autoPurchaseItemsArr);
  // const [autoPurchasePrice, setAutoPurchasePrice] = useState(autoPurchasePriceArr);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [childTitle, setChildTitle] = useState('');

  // const [selectedAutoPurchase, setSelectedAutoPurchase] = useState([]);
  // const [selectedAutoPrice, setSelectedAutoPrice] = useState([]);

  const [tmpFilteredData, setTmpFilteredData] = useState();

  useEffect(() => {
    if (props.data && Object.keys(props.data)?.length > 0) {
      let dataSet = JSON.parse(JSON.stringify(props.data));

      let tempPurchasesData = [];
      let selectedPurchasesData = [];

      tempPurchasesData = dataSet;
      selectedPurchasesData = dataSet;

      setPurchasesData(tempPurchasesData);
      setFilteredData(tempPurchasesData);
      setSelectedRowKeys(tempPurchasesData?.[0]?.id);

      // let tempAutoPurchaseItems = [];
      // let tempAutoPurchasePrice = [];
      // autoPurchaseItems?.map((obj) =>
      //   obj.part === selectedPurchasesData[0].part ? tempAutoPurchaseItems.push(obj) : null
      // );
      // setSelectedAutoPurchase(tempAutoPurchaseItems);

      // autoPurchasePrice?.map((obj) =>
      //   obj.part === selectedPurchasesData[0].part ? tempAutoPurchasePrice.push(obj) : null
      // );
      // setSelectedAutoPrice(tempAutoPurchasePrice);
      setSelectedData(selectedPurchasesData?.[0]);
      props.getPurchaseData(selectedPurchasesData);
    } else {
      setPurchasesData([]);
    }
    // setDataState(1);
    getPurchaseCategoryData();
  }, [props.data]);

  // 購入品種別情報をDBから取得する
  async function getPurchaseCategoryData() {
    try {
      let purchaseTypeLst = await getPurchaseCategoryInfo();
      setPurchaseCategoryInfo(purchaseTypeLst);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, supplierKeyword, dataState, dataStatePurchaseType, tmpFilteredData]);

  /* useEffect(() => {
    // 絞り込み、
    props.getPurchaseData(selectedData);
  }, [selectedData]); */

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data?.find((item) => item?.id === id);
    return record ? record : ret;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedPurchasesData = [];
    if (record !== undefined) {
      selectedPurchasesData.push({
        // key: record.key,
        // id: record.id,
        // no: record.no,
        // display: record.display,
        // code: record.code,
        // purType: record.purType,
        // name: record.name,
        // model: record.model,
        // makerName: record.makerName,
        // supplier: record.supplier,
        // supplierCode: record.supplierCode,
        // unitPrice: record.unitPrice,
        // unitPriceHold: record.unitPriceHold,
        // remark: record.remark,
        // sortNo: record.sortNo,
        // created: record.created,
        // updated: record.updated,
        // processTime: record.processTime,
        // pricesByLot: record.pricesByLot,
        // autoPurchase: record.autoPurchase,
        // updatedHistory: record.updatedHistory,
        id: record.id,
        purchaseCategoryId: record.purchaseCategoryId,
        code: record.code,
        name: record.name,
        price: record.price,
        priceLots: record.priceLots,
        priceHistories: record.priceHistories,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });

      // let tempAutoPurchaseItems = [];
      // let tempAutoPurchasePrice = [];
      // autoPurchaseItems?.map((obj) =>
      //   obj.part === selectedPurchasesData[0].part ? tempAutoPurchaseItems.push(obj) : null
      // );
      // setSelectedAutoPurchase(tempAutoPurchaseItems);

      // autoPurchasePrice?.map((obj) =>
      //   obj.part === selectedPurchasesData[0].part ? tempAutoPurchasePrice.push(obj) : null
      // );

      setChangeRecordKey(record?.id);
      if (editMode) {
        setEditModeCancelConfirm(true);
      } else {
        setSelectedRowKeys(record?.id);
        // setSelectedAutoPrice(tempAutoPurchasePrice);
        setSelectedData(selectedPurchasesData[0]);
        props.getPurchaseData(selectedPurchasesData);
        /* navigate(':common.purchase'); */
      }
    } else {
      setSelectedRowKeys(-1);
      // setSelectedAutoPrice([]);
      setSelectedData([]);
      /*  navigate(':common.purchase'); */
    }
  };

  // 表示の押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 種類の押下
  const searchByDataStatePurchaseType = (value) => {
    dataStatePurchaseType === value ? getDataByFilter() : setDataStatePurchaseType(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
      case 'supplier':
        supplierKeyword === e.target.value ? getDataByFilter() : setSupplierKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (purchasesData?.length <= 0) {
      return;
    }
    let tKeyword = dataStatePurchaseType;
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let rKeyword = supplierKeyword;
    let flag;
    let currentData = [...purchasesData];
    let purchaseFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    }
    // 型番のキーワードで絞り込み
    if (mKeyword != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.info.model?.toString().indexOf(mKeyword) >= 0);
    }
    // 仕入先のキーワードで絞り込み
    if (rKeyword != '') {
      purchaseFilterData = purchaseFilterData?.filter(
        (item) => item.info.supplierName?.toString().indexOf(rKeyword) >= 0
      );
    }

    //  表示で絞り込み
    if (dataState === 1 || dataState === 2) {
      dataState === 1 ? (flag = true) : (flag = false);
      purchaseFilterData = purchaseFilterData?.filter((item) => item?.info?.isUsed === flag);
    }

    // 種別で絞り込み
    if (tKeyword > 0) {
      purchaseFilterData = purchaseFilterData?.filter((item) => item?.purchaseCategoryId === tKeyword);
    }
    // 一覧に表示するデータ */
    setFilteredData(purchaseFilterData);
  };

  const getPurTypeNameByNo = (id) => {
    let data = [];
    // if (detailRef.current) {
    //   data = detailRef.current?.purchaseTypeList;
    // } else {
    //   data = paramCommonPurchaseType;
    // }
    data = [...purchaseCategoryInfo];
    let record = data?.filter((item) => item.id === id);
    let name = record?.length > 0 ? record[0]?.name : '';
    return name;
  };

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50 + 'px',
     /*  sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend', */
      render: (text, record, index) => <span>{index + 1}</span>,
    },

    {
      id: '2',
      title: '引当用',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isAllocation ? <StarFilled /> : <StarOutlined />;
      },
      className: 'cm-a-center',
    },

    {
      id: '3',
      title: 'コード',
      dataIndex: 'code',
      width: 100 + 'px',
    },

    {
      id: '4',
      title: '種別',
      dataIndex: 'purchaseCategoryId',
      width: 100 + 'px',
      render: (value) => `${getPurTypeNameByNo(value)}`,
    },
    {
      id: '5',
      title: '名称',
      dataIndex: 'name',
      width: 150 + 'px',
    },
    {
      id: '6',
      title: '型番',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.model,
    },
    {
      id: '7',
      title: 'メーカー名',
      dataIndex: 'info',
      width: 120 + 'px',
      render: (info) => info.makerName,
    },
    {
      id: '8',
      title: '仕入先',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierName,
    },
    {
      id: '9',
      title: '仕入先コード',
      dataIndex: 'info',
      width: 150 + 'px',
      render: (info) => info.supplierCode,
    },
    {
      id: '10',
      title: '単価',
      dataIndex: 'price',
      width: 70 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
    },
    {
      id: '11',
      title: '単価保持',
      dataIndex: 'info',
      width: 95 + 'px',
      render: (index, item) => {
        return item.info.isKeepPrice === 1 ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },

    {
      id: '12',
      title: 'ソート番号',
      dataIndex: 'info',
      width: 100 + 'px',
      className: 'cm-a-right',
      sorter: (a, b) => a.sortNum - b.sortNum,
      render: (info) => info.sortNum,
    },
    {
      id: '13',
      title: '作成日',
      dataIndex: 'created',
      width: 100 + 'px',
      render: (created) => formatDate(created),
    },
    {
      id: '14',
      title: '更新日',
      dataIndex: 'modified',
      width: 100 + 'px',
      render: (modified) => formatDate(modified),
    },
    {
      id: '15',
      title: '取付時間(秒)',
      dataIndex: 'info',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (info) => info.processTime,
    },
    {
      id: '16',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
      width: 200 + 'px',
    },
    {
      id: '17',
      title: '表示',
      dataIndex: 'info',
      width: 100 + 'px',
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '18',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div>
        <div style={{ marginTop: 7 }}>
          {/*  クイック検索 */}
          {listShowHide ? (
            <>
              <Space size="middle">
                <Select
                  className="search-select-input"
                  onChange={searchByDataStatePurchaseType}
                  id="dataStatePurchaseType"
                  name="dataStatePurchaseType"
                  defaultValue={0}
                >
                  <Select.Option id={'dataStatePurchaseType0'} value={0}>
                    {'全ての種別'}
                  </Select.Option>
                  {purchaseCategoryInfo?.map((item, index) => (
                    <Select.Option key={index} id={'dataStatePurchaseType' + item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                <Input
                  className="input-editable search-select-input"
                  placeholder="名称"
                  allowClear
                  onPressEnter={searchByKeyword}
                  onBlur={searchByKeyword}
                  name="name"
                  id="name"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      searchByKeyword(e);
                    }
                  }}
                />
                <Input
                  className="input-editable search-select-input"
                  placeholder="型番"
                  allowClear
                  onPressEnter={searchByKeyword}
                  onBlur={searchByKeyword}
                  name="model"
                  id="model"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      searchByKeyword(e);
                    }
                  }}
                />
                <Input
                  className="input-editable search-select-input"
                  placeholder="仕入先"
                  allowClear
                  onPressEnter={searchByKeyword}
                  onBlur={searchByKeyword}
                  name="supplier"
                  id="supplier"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      searchByKeyword(e);
                    }
                  }}
                />
                <Select
                  className="search-select"
                  onChange={searchByDataState}
                  id="dataStateDrop"
                  name="dataStateDrop"
                  value={dataState}
                >
                  {dataStateList?.map((item, index) => (
                    <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 10 }}>
                <Table
                  columns={hasCheckData ? tableColumns : chkData}
                  dataSource={
                    filteredData !== undefined
                      ? filteredData
                          .slice()
                          .sort((a, b) => Number(a?.info?.sortNum) - Number(b?.info?.sortNum))
                          .map((item) => ({ ...item, key: item.id }))
                      : []
                  }
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record?.id == selectedRowKeys ? 'active-row' : 'data-row')}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record?.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    defaultPageSize: 25,
                    defaultCurrent: 1,
                    pageSize: 25,
                  }}
                  className="param_tbListShow commonPurchase"
                  style={{ height: '358px !important', maxHeight: '735px !important' }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                />
              </Row>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});

export default CommonPurchaseList;
