/**
 * クラス名：板金子部品見積
 * 説明：板金子部品のタブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Row, Input, Col, Space, Image, Tooltip, Tabs, Modal, Checkbox, Spin } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined, SettingOutlined, SettingFilled } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../assets/styles/common.css';
import '../../assets/styles/style.css';
import down_Arrow from '../../assets/images/down_Arrow.png';
import up_Arrow from '../../assets/images/up_Arrow.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import setting_icon from '../../assets/images/setting_icon.png';
import IQ3EstimateDetail from './IQ3EstimateDetail';
import KouteiSuryouBetsuBtn from '../parent/KouteiSuryouBetsuBtn';
import IQ3Purchase from './IQ3Purchase';
import IQ3KouteiCalculate from '../iq3/IQ3KouteiCalculate';
import IQ3KouteiInput from '../iq3/IQ3KouteiInput';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import IQ3MaterialDetail from './IQ3MaterialDetail';
import {
  commonTaiochuModal,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  commonDeleteFooter,
} from '../common/CommonModal';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Prev } from 'react-bootstrap/esm/PageItem';
import parent_call_detail from '../../assets/images/parent_call_detail.png';
import ParentList from '../parent/ParentList';
import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  formatDateString,
  todayTime,
  toDecimal,
  updateEstimateInfoByReCal,
  JPYs,
  getDetails,
  isInfinity,
  getInitMaterialSizeInfo,
  getInitMaterialPatternsInfo,
  reCalMaterial,
} from '../common/Common.js';
import ParentPurchase from '../parent/ParentPurchase';
import { GetParametersByCompId, getEstimateIq3Info } from '../common/CommonAPI.js';
import {
  ParamCalType,
  ClientType,
  WorkType,
  DataType,
  ServiceClass,
  SurfaceProtectionType,
  EstimateCreationType,
  PatternType,
  WorkTypeGroup,
} from '../common/enums.js';
import { amtEmptyStr, iq3PurchaseTitle } from '../common/Constant';

dayjs.extend(customParseFormat);

const IQ3Estimate = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
  const [isListOpen1, setIsListOpen1] = useState(false);
  const [isListClose1, setIsListClose1] = useState(false);
  const [isListOpenAll, setIsListOpenAll] = useState(true);
  const [selectedData, setSelectedData] = React.useState();
  const [modalTitleTxt, setMdalTitleTxt] = useState('確認');

  // 確認ダイアログ
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [discardMode, setDiscardMode] = useState(false);
  const [iQ3DataLst, setIQ3DataLst] = useState([]);
  // const [targetRowKey, setTargetRowKey] = useState([0]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordNo, setCurrentRecordNo] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [selectedTabKey, setSelectedTabKey] = useState('1');
  const [isSelectedTab, setIsSelectedTab] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [parentListDisplay, setParentListDisplay] = useState(false);
  const [selectedParentAddEstimate, setSelectedParentAddEstimate] = useState([]);

  const [isAddPart, setIsAddPart] = useState(false);

  const [materialLst, setMaterialLst] = useState([]);
  const [materialTypeLst, setMaterialTypeLst] = useState([]);
  const [materialThickLst, setMaterialThickLst] = useState([]);
  const [materialSurfaceLst, setMaterialSurfaceLst] = useState([]);
  const [materialSurface, setMaterialSurface] = useState([]);

  const [isSetting, setIsSetting] = useState(false);

  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientMasterInfo, setClientMasterInfo] = useState([]);
  const [discardModeKouteiCal, setDiscardModeKouteiCal] = useState(false);

  const [newParameters, setNewParameters] = useState();

  const [quantity, setQuantity] = useState(1);

  // Waitingダイアログの表示
  const [iq3Loading, setIq3Loading] = useState(true);

  // 材料詳細のタグを最初として入るかの判断
  const [isFirstTimeMaterialDetail, setIsFirstTimeMaterialDetail] = useState(true);

  const [iq3ActionForCal, setIq3ActionForCal] = useState(false);

  const [selectedRowNo, setSelectedRowNo] = useState(false);

  const [selectedProcessSelect, setSelectedProcessSelect] = useState([]);

  const [iq3PMXDatas, setIq3PMXDatas] = useState([]);
  const [currentRecordNoPMX, setCurrentRecordNoPMX] = useState(0);

  // 板金子部品プロパティ情報
  let iq3DetailRef = useRef();

  // 工程選択
  let iq3KouteiSentakuRef = useRef();

  // 工程計算
  let iq3KouteiCalRef = useRef();

  // 工程入力
  let iq3KouteiInputRef = useRef();

  // 購入品
  let purchaseRef = useRef();

  // 材料詳細
  let iq3MaterialDetailRef = useRef();

  const currentRoute = location.pathname;

  const estimateParameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

  useEffect(() => {}, [props.materialLst, props.materialTypeLst, props.materialSurfaceLst]);

  // iQ3板金タブを選択中で、子部品追加呼出で追加した時
  useEffect(() => {
    if (!isAddPart) {
      let iq3Infos = props.selectedEstimateData?.esIq3Info;
      if (iq3Infos === undefined) return;
      setIQ3DataLst(iq3Infos);
    }
  }, [props.selectedEstimateData?.esIq3Info]);

  //親部品・子部品タブ切替により呼び出す
  useEffect(() => {
    if (props.clickMenu !== '') {
      getAllEstimateIq3Info();
    }
    setIsSelectedTab(false);
  }, [props.activePartMenu, props.clickMenu]);

  //　新規呼出
  useEffect(() => {
    if (!props.type) {
      // 新規モードの場合、
      if (props.selectedEstimateData?.estimateProducts?.id == 0) {
        initIq3EstimateInfo(props.selectedEstimateData);
      } else {
        // 保存データを開くの場合
        if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
          setSelectedData(props.selectedEstimateData?.esIq3Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq3Info?.[0]?.no);
          let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
          // 材料リスト
          let materialList = parameters?.materialIq3;
          setMaterialLst(materialList);
          // 表面保護
          const materialSurfaceData = parameters?.materialSurfaceIq3;
          setMaterialSurfaceLst(materialSurfaceData);
          // 材質リスト
          setMaterialTypeLst(parameters?.materialTypeIq3);

          if (parameters?.process?.length > 0) {
            let processMstData = getProcessMstInfo(parameters?.process);
            setProcessMasterInfo(processMstData);

            if (parameters?.device?.length > 0) {
              let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
              setDeviceMasterInfo(deviceLst);
            }
          }
          if (parameters?.client?.length > 0) {
            setClientMasterInfo(parameters?.client);
          }
          if (props?.isReCal) {
            setActiveTabKey(selectedTabKey);
          } else {
            setActiveTabKey('1');
          }
        }
      }
    }
  }, [props.selectedEstimateData, props.type]);

  //子部品各タブ切替により呼び出す
  useEffect(() => {
    if (isSelectedTab) {
      getAllEstimateIq3Info();
    }
  }, [activeTabKey]);

  // 行追加・行削除・行選択・ソート切替により呼び出す
  useEffect(() => {
    if (iq3ActionForCal) {
      // 行追加・行削除・行選択・ソート切替により再計算
      getAllEstimateIq3Info();
      let tempIQ3List = [...iQ3DataLst];
      // 行追加の場合、選択行のリセット
      if (isAddPart) {
        if (iQ3DataLst != undefined && iQ3DataLst?.length > 0) {
          var lastRecord = tempIQ3List?.sort((a, b) => b.no - a.no)?.[0];
          setCurrentRecordNo(lastRecord?.no);
          setSelectedData(lastRecord);
          setIsAddPart(false);
        }
      } else {
        // 行削除・行選択・ソート切替の場合、選択行のリセット
        setCurrentRecordNo(selectedRowNo);
        let selectedData = tempIQ3List?.filter((item) => item.no === selectedRowNo);
        if (selectedData?.length > 0) {
          setSelectedData(selectedData?.[0]);
        }
      }
      setIq3ActionForCal(false);
    }
  }, [iq3ActionForCal]);

  useEffect(() => {
    if (iq3PMXDatas?.length > 0 && props?.isPMXEvent) {
      let curNo = currentRecordNoPMX;
      if (curNo <= iq3PMXDatas?.length) {
        let curData = iq3PMXDatas?.filter((item) => item.no === curNo);
        if (curData?.length > 0) {
          setSelectedData(curData?.[0]);
          setCurrentRecordNo(curData?.[0]?.no);
          setCurrentRecordNoPMX(curNo + 1);
        }
      } else {
        setIq3PMXDatas([]);
        if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
          setSelectedData(props.selectedEstimateData?.esIq3Info?.[0]);
          setCurrentRecordNo(props.selectedEstimateData?.esIq3Info?.[0]?.no);
        }
        props.updateCheckPMXOrNot();
      }
    }
  }, [currentRecordNoPMX]);

  useEffect(() => {
    if (iq3PMXDatas?.length === 0 && props?.isPMXEvent) {
      let calEstimateInfo = updateEstimateInfoByReCal(props.selectedEstimateData);
      if (calEstimateInfo) {
        // 一覧の情報を反映
        setIQ3DataLst(calEstimateInfo?.esIq3Info);
        setSelectedData(calEstimateInfo?.esIq3Info?.[0]);
        setCurrentRecordNo(calEstimateInfo?.esIq3Info?.[0]?.no);
        props.updateEstimateInfo(calEstimateInfo);
        props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
        props.selectedEstimateData.esIq3Info = calEstimateInfo?.esIq3Info;
        setSelectedEstimateData(calEstimateInfo);
      }
      props.updatePMXEvent();
      props?.loading(false); // PMXデータ読み取り、完了後Waitingダイアログを非表示にする
    }
  }, [iq3PMXDatas]);

  // IQX_WEBEST-195のパラメータ画面から遷移された場合、アクティブキーを保持するように対応
  useEffect(() => {
    setSelectedTabKey(props?.iq3ActiveTabKey);
    onChangeTab(props?.iq3ActiveTabKey);
    props?.updateIq3ActiveTabKey(props?.iq3ActiveTabKey);
  }, [props?.iq3ActiveTabKey]);

  const updateIQ3DataByBulkEdit = (bulkEditIQ3Infos) => {
    if (bulkEditIQ3Infos?.length > 0) {
      if (props.selectedEstimateData.esIq3Info) {
        let totalPartCount = bulkEditIQ3Infos?.reduce((total, item) => {
          return total + parseFloat(item.partCount);
        }, 0);
        if (props.selectedEstimateData.estimateProducts) {
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount =
            totalPartCount;
          // IQX_WEBEST-179
          props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract =
            totalPartCount;
          props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = totalPartCount;
        }
        setIQ3DataLst(bulkEditIQ3Infos);
        setCurrentRecordNo(bulkEditIQ3Infos[0]?.no);
        setSelectedData(bulkEditIQ3Infos[0]);
        setSelectedRowNo(bulkEditIQ3Infos[0]?.no);
        props.selectedEstimateData.esIq3Info = bulkEditIQ3Infos;
        setSelectedEstimateData(props.selectedEstimateData);
        setIq3ActionForCal(true);
      }
    }
  };

  // 板金部品見積もりの初期化
  const initIq3EstimateInfo = async (selectedEstimateData) => {
    if (selectedEstimateData === undefined) {
      return;
    }
    let iq3Infos = [];
    if (!props.type) {
      // 新規モードの場合、
      if (selectedEstimateData?.estimateProducts?.id == 0) {
        let iq3Data = [...iq3EstimateInitInfo];
        let newIQ3 =
          selectedEstimateData?.esIq3Info && selectedEstimateData?.esIq3Info?.length > 0 // 親部品タブを選択中で、子部品追加呼出で追加した時
            ? selectedEstimateData?.esIq3Info
            : getNewIQ3EstimateInfo(iq3Data);
        iq3Infos = newIQ3;
        setIQ3DataLst(newIQ3);
      } else {
        //　板金情報リストを取得
        let estimateId = selectedEstimateData?.estimateProducts?.id;
        if (estimateId) iq3Infos = await getEstimateIQ3Data(estimateId);
      }
      selectedEstimateData.esIq3Info = iq3Infos;
      // props.updatedSelectedEstimateDataTemp(selectedEstimateData);
    } else {
      let newIQ3 = selectedEstimateData?.esIq3Info;
      iq3Infos = newIQ3;
      setIQ3DataLst(iq3Infos);
    }
    let selectedData = iq3Infos?.length > 0 ? iq3Infos?.[0] : {};
    // setQuantity(props?.getParentQuantity()); // 入力した数量情報を得る
    setSelectedEstimateData(selectedEstimateData);
    setCurrentRecordNo(selectedData?.no ? selectedData?.no : 1);
    setSelectedData(selectedData);

    let parameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 材料リスト
    let materialList = parameters?.materialIq3;
    setMaterialLst(materialList);
    // 板厚リスト
    // let materialThickLst = materialList?.filter((item) => item.key == childPartDetail.materialNm);
    // setMaterialThickLst(materialThickLst);
    // 表面保護
    const materialSurfaceData = parameters?.materialSurfaceIq3;
    setMaterialSurfaceLst(materialSurfaceData);
    // 材質リスト
    setMaterialTypeLst(parameters?.materialTypeIq3);

    if (parameters?.process?.length > 0) {
      let processMstData = getProcessMstInfo(parameters?.process);
      setProcessMasterInfo(processMstData);

      if (parameters?.device?.length > 0) {
        let deviceLst = getDeviceMstInfo(parameters?.device, parameters?.process);
        setDeviceMasterInfo(deviceLst);
      }
    }
    if (parameters?.client?.length > 0) {
      setClientMasterInfo(parameters?.client);
    }
    if (props?.isReCal) {
      setActiveTabKey(selectedTabKey);
    } else {
      setActiveTabKey('1');
    }
  };

  // 材料マスタ情報
  const getProcessMstInfo = (process) => {
    let processMstData = process?.filter((process) => process?.class === ServiceClass.SheetMetal);
    return processMstData;
  };

  // 装置マスタ情報
  const getDeviceMstInfo = (device, process) => {
    let processMstData = getProcessMstInfo(process);
    let deviceLst = device?.filter((item) => processMstData?.filter((process) => process?.id === item?.processId));
    return deviceLst;
  };

  // 新規モードの場合、板金部品情報を初期化する
  const getNewIQ3EstimateInfo = (lists) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    let userSettingData = props.selectedEstimateData?.estimateProducts?.calcParameters?.settings?.userSettings;

    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    let kouteiPatternList = patternLst?.filter((item) => item.id === userSettingData?.[0]?.users?.iq3ProcessPatternId);
    if (kouteiPatternList?.length === 0) {
      kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);
    }

    const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
    let materialTypeList = materialType?.filter((item) => item.info.isUsed);

    const material = parameters?.materialIq3; // 材料マスタデータ
    let materialList = material?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
    let materialSurfaceList = materialSurface?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.SheetMetal);
    let blankIsUsedData = [];
    processMaster?.filter((i) => {
      if (
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
      ) {
        blankIsUsedData.push(i.id);
        // return i.id;
      }
    });
    const filteredList2 = kouteiPatternList?.[0]?.details?.filter((item2) =>
      blankIsUsedData.some((item1) => item1 === item2.processId)
    );

    const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

    // 工程入力
    let processSelectDetails = kouteiPatternList?.[0]?.details;

    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';

        let data = {
          id: index + 1,
          processId: item.processId,
          details: getDetails(workType, parameters, processSelectDetails),
          autoExtractXSize: 0,
          autoExtractYSize: 0,
        };

        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo.workType || '';
        let dataItems = [];
        if (item.multiprocesses?.length > 0) {
          for (let m = 0; m < item.multiprocesses?.length; m++) {
            let dataItem = {
              id: m + 1,
              processName: processMstInfo?.name,
              processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            };
            dataItems.push(dataItem);
          }
        }

        let addition = {
          no: item.processId,
          processId: item.processId,
          dataType: DataType.Data,
          dataItems: dataItems,
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
          editDataFlg: false,
        };

        return addition;
      });
    }

    // ブランクの場合
    let blankProcess = getProcessRecord(
      ServiceClass.SheetMetal,
      [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
      processMstData
    );
    // 板金工程選択にブランクを使用している場合、
    let usedBlankProcess = processSelectDetails?.find(
      (selectItem) =>
        selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
    );
    // 使用している装置の情報を取得する
    let usedDeviceInfo = parameters?.device?.find((device) => device.id === usedBlankProcess?.deviceId);
    let grabbingX = 0;
    let grabbingY = 0;
    let pierWidthX = 0;
    let pierWidthY = 0;
    let worlRangeX = 0;
    let worlRangeY = 0;
    // 装置がある場合、
    if (usedDeviceInfo) {
      // 装置から材料配置情報
      let materialArrange = usedDeviceInfo?.details?.materialArrange;
      grabbingX = materialArrange?.graspAllowanceX;
      grabbingY = materialArrange?.graspAllowanceY;
      pierWidthX = materialArrange?.xWidth;
      pierWidthY = materialArrange?.yWidth;
      worlRangeX = materialArrange?.workRangeX;
      worlRangeY = materialArrange?.workRangeY;
    }

    // 材料詳細パターン情報を準備する
    let materialDetailPatterns = [];

    // 材料詳細サイズリスト情報を準備する
    let materialDetailSizes = [];
    let materialSizeIq3Infos = parameters?.materialSizeIq3;

    const updatedList = lists?.map((i, index) => {
      if (i?.id === 0) {
        materialDetailSizes = [];
        let materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        if (materialSizeIq3Infos) {
          materialDetailSizes = materialSizeIq3Infos
            ?.filter(
              (item) =>
                item.info.isUsed &&
                item.name !== 'Free' &&
                item?.materialTypeIq3Id?.findIndex(
                  (id) => id === (i.materialTypeIq3Id === 0 ? materialTypeIq3Id : i.materialTypeIq3Id)
                ) > -1
            )
            ?.map((item, index) => {
              let data = getInitMaterialSizeInfo(index, item);
              let copyItem = JSON.parse(JSON.stringify(item));
              if (data?.sizeName === 'Other') {
                data = { ...data, isUsed: true };
                // /材料サイズX計算
                let sheetX = Number(0) + Number(pierWidthX) + Number(grabbingX);
                // /材料サイズY計算
                let sheetY = Number(0) + Number(pierWidthY) + Number(grabbingY);
                copyItem = { ...copyItem, info: { ...copyItem.info, sizeX: sheetX, sizeY: sheetY } };
              }
              materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, copyItem);
              return data;
            });
        }

        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'Part_' + formatDateString(todayTime());
        i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.materialName = materialList?.length > 0 ? materialList[0]?.name : '';
        i.materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        i.materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
        i.materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
        i.materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
        i.thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.purchases = [];
        i.additions = kouteiCalArr;
        i.materialDetailPatterns = materialDetailPatterns;
        i.materialDetailSizes = materialDetailSizes;
        i.weight = 0;
        i.totalSurfaceArea = 0;
        i.partSizeX = 0;
        i.partSizeY = 0;
        i.partCount = 1;
        i.grabbingX = grabbingX;
        i.grabbingY = grabbingY;
        i.pierWidthX = pierWidthX;
        i.pierWidthY = pierWidthY;
        i.worlRangeX = worlRangeX;
        i.worlRangeY = worlRangeY;
        i.workCostUnitPrice = 0;
        i.workCostTotalPrice = 0;
        i.costUnitPrice = 0;
        i.costTotalPrice = 0;
        i.surfaceTotalPrice = 0;
        i.surfaceUnitPrice = 0;
        i.purchaseUnitPrice = 0;
        i.purchaseTotalPrice = 0;
        i.materialCostUnitPrice = 0;
        i.materialCostTotalPrice = 0;
      }
      return i;
    });
    return updatedList;
  };

  useEffect(() => {
    if (isAddPart) {
      let no = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1].no : 0;
      scrollTo(no);
    }
  }, [iQ3DataLst]);

  // 工程マスタのレコードIDを取得する
  const getProcessRecord = (classId, processTypes, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter(
      (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
    );
    return mstRecordInfo;
  };

  // 板金部品見積もりを取得する
  const getEstimateIQ3Data = async (estimateProductId) => {
    let estimateIq3Infos = await getEstimateIq3Info(estimateProductId);
    if (estimateIq3Infos) {
      setIQ3DataLst(estimateIq3Infos);
    }
    return estimateIq3Infos;
  };

  // Scrollの移動
  const scrollTo = (rowKey) => {
    // Scrollの移動
    var activeRowElements = document.getElementById('iq3EstimateTbl').getElementsByClassName('ant-table-row');
    for (let i = 0; i < activeRowElements?.length; i++) {
      var targetElement = activeRowElements[i].dataset.rowKey == rowKey ? activeRowElements[i] : [];
      if (targetElement != '') {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  // 編集中破棄確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setDiscardModeKouteiCal(false);
    setEditModeCancelConfirm(false);
    props.updateMainEstimateEditMode(false);
    onChangeTab(selectedTabKey);
    props?.updateIq3ActiveTabKey(selectedTabKey);
    getAllEstimateIq3Info();
  };

  // 編集中破棄確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setSelectedRowNo(record?.no);
    setIq3ActionForCal(true);
    // setCurrentRecordNo(record.no);
    // setCurrentRecord(record);
    // setSelectedData(record);
    // setIq3ActionForCal(!iq3ActionForCal);
    // getAllEstimateIq3Info(record); // 行選択で入力中の情報を保持するようにする
  };

  const saveData = () => {
    // setDiscardMode(false);
    // setConfirmMsgOpen(true);
  };

  const updateData = () => {
    setDiscardMode(false);
    setConfirmMsgOpen(true);
  };

  const discardData = () => {
    setDiscardModeKouteiCal(false);
    setDiscardMode(true);
    setConfirmMsgOpen(true);
  };

  // 選択中であり、部品の各タブ情報をリストに反映するようにする
  const getAllEstimateIq3Info = () => {
    setConfirmMsgOpen(false);
    props.updateMainEstimateEditMode(false);

    let estimateData = { ...props.selectedEstimateData };
    let selectedIq3EstimateData = estimateData?.esIq3Info?.find((iq3) => iq3?.no === selectedData?.no);

    // 工程積算
    //工程パターンでチェック外した工程を工程積算から削除
    let orgAdditions = estimateData?.esIq3Info?.filter((item) => item.no === selectedIq3EstimateData?.no)?.[0]
      ?.additions;
    let updatedAdditions = [];
    for (let i = 0; i < selectedIq3EstimateData?.processSelect?.details?.length; i++) {
      let targetAddition = orgAdditions?.filter(
        (item) =>
          item.processId === selectedIq3EstimateData?.processSelect?.details[i].processId &&
          selectedIq3EstimateData?.processSelect?.details[i].isUsedForCalc
      );
      if (targetAddition?.length > 0) {
        //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
        if (targetAddition?.length > 1) {
          for (let o = 0; o < orgAdditions?.length; o++) {
            let customProc = estimateData?.estimateProducts?.calcParameters?.parameters?.process?.filter(
              (item) => item.id === orgAdditions[o]?.processId
            );
            let customType = customProc ? customProc[0]?.workType : 0;
            if (
              selectedIq3EstimateData?.processSelect?.details[i]?.multiprocesses?.[0]?.processDetailTypeId ===
                orgAdditions[o]?.dataItems?.[0]?.processDetailTypeNo &&
              selectedIq3EstimateData?.processSelect?.details[i]?.processId === orgAdditions[o]?.processId &&
              customType === WorkType.SmCustom
            ) {
              updatedAdditions?.push(orgAdditions?.[o]);
              break;
            }
          }
        } else {
          updatedAdditions?.push(targetAddition?.[0]);
        }
      }
    }
    if (selectedIq3EstimateData?.processSelect?.details?.length > 0) {
      //工程選択から外した場合、見積データに外した情報を更新するため、updatedAdditionsのNullチェックを削除
      selectedIq3EstimateData.additions = updatedAdditions;
    }

    // 材料詳細
    // if (iq3MaterialDetailRef?.current) {
    //   const materialDetailInfo = iq3MaterialDetailRef?.current?.getIQ3MaterialDetailInfo();
    //   let materialPatterns = materialDetailInfo?.materialDetailPatterns;
    //   let materialSizes = materialDetailInfo?.materialDetailSizes;

    //   if (activeTabKey !== '1' && materialDetailInfo) {
    //     if (selectedIq3EstimateData.materialIq3Id !== materialDetailInfo?.materialIq3Id)
    //       selectedIq3EstimateData.materialIq3Id = materialDetailInfo?.materialIq3Id;
    //     if (selectedIq3EstimateData.materialName !== materialDetailInfo?.materialName)
    //       selectedIq3EstimateData.materialName = materialDetailInfo?.materialName;
    //     if (selectedIq3EstimateData.materialTypeIq3Id !== materialDetailInfo?.materialTypeIq3Id)
    //       selectedIq3EstimateData.materialTypeIq3Id = materialDetailInfo?.materialTypeIq3Id;
    //     if (selectedIq3EstimateData.materialTypeName !== materialDetailInfo?.materialTypeName)
    //       selectedIq3EstimateData.materialTypeName = materialDetailInfo?.materialTypeName;
    //     if (selectedIq3EstimateData.materialSurfaceIq3Id !== materialDetailInfo?.materialSurfaceIq3Id)
    //       selectedIq3EstimateData.materialSurfaceIq3Id = materialDetailInfo?.materialSurfaceIq3Id;
    //     if (selectedIq3EstimateData.materialSurfaceName !== materialDetailInfo?.materialSurfaceName)
    //       selectedIq3EstimateData.materialSurfaceName = materialDetailInfo?.materialSurfaceName;
    //     if (selectedIq3EstimateData.thickness !== materialDetailInfo?.thickness)
    //       selectedIq3EstimateData.thickness = materialDetailInfo?.thickness;
    //   }
    //   if (materialDetailInfo) {
    //     let selectedMaterialSizeInfo;
    //     let materialTotalPrice = 0; // 材料原価合計
    //     let materialUnitPrice = 0; // // 材料原価
    //     if (materialSizes && materialSizes.length > 0) {
    //       selectedMaterialSizeInfo = materialSizes.find((item) => item.isUsed); // 採用されるサイズの情報
    //       if (selectedMaterialSizeInfo) {
    //         materialTotalPrice = selectedMaterialSizeInfo?.price; // 材料原価合計
    //         materialUnitPrice = materialTotalPrice / (selectedIq3EstimateData?.partCount * props.getParentQuantity()); // 材料原価/員数＊数量
    //       }
    //     }
    //     selectedIq3EstimateData.grabbingX = materialDetailInfo?.grabbingX;
    //     selectedIq3EstimateData.grabbingY = materialDetailInfo?.grabbingY;
    //     selectedIq3EstimateData.pierWidthX = materialDetailInfo?.pierWidthX;
    //     selectedIq3EstimateData.pierWidthY = materialDetailInfo?.pierWidthY;
    //     selectedIq3EstimateData.materialCostUnitPrice =
    //       materialUnitPrice && !isInfinity(materialUnitPrice) ? materialUnitPrice : 0; // 材料原価
    //     selectedIq3EstimateData.materialCostTotalPrice =
    //       materialTotalPrice && !isInfinity(materialTotalPrice) ? materialTotalPrice : 0; // 材料原価合計
    //     selectedIq3EstimateData.materialDetailPatterns = materialPatterns ? materialPatterns : [];
    //     selectedIq3EstimateData.materialDetailSizes = materialSizes ? materialSizes : [];
    //   }
    // }

    // 一覧の情報を反映するため、データ取得
    let updatedData = estimateData?.esIq3Info;
    let data = [...iQ3DataLst];
    if (data?.length > 0) {
      updatedData = data?.map((item) => {
        if (item.no === selectedIq3EstimateData?.no) {
          item = selectedIq3EstimateData;
        }
        return item;
      });
    }

    estimateData = { ...estimateData, esIq3Info: updatedData };
    if (estimateData) {
      //タブ切替で再計算
      let calEstimateInfo = updateEstimateInfoByReCal(estimateData);
      if (calEstimateInfo) {
        // 一覧の情報を反映
        setIQ3DataLst(calEstimateInfo?.esIq3Info);
        calEstimateInfo?.esIq3Info?.map((item) => {
          if (item.no === selectedIq3EstimateData?.no) {
            // 選択中の部品情報を反映
            setSelectedData(item);
          }
        });
        props.updateEstimateInfo(calEstimateInfo);
        props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
        props.selectedEstimateData.esIq3Info = calEstimateInfo?.esIq3Info;
        setSelectedEstimateData(calEstimateInfo);
      }
      return calEstimateInfo;
    }
  };

  const confirmOk = (e) => {
    getAllEstimateIq3Info();
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
    // props.childEditMode(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const partListCollapseChange = (key, e) => {
    if (key === 0) {
      if (isListClose1) {
        setIsListOpenAll(false);
        setIsListClose1(false);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '0 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '100 1 0px';
      } else {
        setIsListOpenAll(true);
        setIsListClose1(true);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '18 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '81 1 0px';
      }
    } else {
      if (isListOpen1) {
        setIsListOpenAll(true);
        setIsListOpen1(false);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '39.3215 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '60.6785 1 0px';
      } else {
        setIsListOpenAll(false);
        setIsListOpen1(true);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '18 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '81 1 0px';
      }
    }
  };

  const partListCollapseChange1 = (key, e) => {
    if (key === 0) {
      setIsListOpen1(false);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '0 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '100 1 0px';
    } else {
      setIsListClose1(false);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '39.3215 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '60.6785 1 0px';
    }
  };

  // 工程選択画面の工程リストを選択した場合、
  const updateSelectedData = (selectedPartNo) => {
    getAllEstimateIq3Info(); // タブの切り替えで入力中の情報を保持するようにする
    let selectedData = iQ3DataLst?.filter((item) => item.no == selectedPartNo);
    setCurrentRecordNo(selectedPartNo);
    setSelectedData(selectedData?.length > 0 ? selectedData[0] : iQ3DataLst[0]);
    scrollTo(selectedPartNo);
  };

  // 工程選択画面のイベント変更により呼出
  const updateProcessSelectOfSelectedData = (processSelect) => {
    setSelectedProcessSelect(processSelect);
    // 工程選択を変更した時、材料詳細も計算する
    isReCalMaterial(true);
  };

  // import PMX file
  const importPMXFile = (pmxData) => {
    setIQ3DataLst(pmxData);
    setCurrentRecordNo(0);
    setSelectedData(pmxData[0]);
    navigate(':estimate.iq3');
  };

  useImperativeHandle(ref, () => ({
    importPMXFile: importPMXFile,
    getIQ3EditMode: () => {
      return editMode;
    },
    getIQ3EstimateDetailInfo: getAllEstimateIq3Info,
    getParentEditMode: () => {
      return editMode;
    },
    getEstimateIq3Info: () => {
      return iQ3DataLst;
    },
    kihonFormValidation: iq3DetailRef?.current?.formValidation,
    // getIQ3EstimateData: getEstimateInfo,
    updateIq3PMXDatas: updateIq3PMXDatas,
    getIQ3ActiveTabKey: () => {
      return activeTabKey;
    },
    updateIQ3DataByBulkEdit: updateIQ3DataByBulkEdit,
    getNewIQ3EstimateInfo: getNewIQ3EstimateInfo,
  }));

  // 部品追加ボタンの押下
  const addPart = async () => {
    let iq3Data = [...iq3EstimateInitInfo];
    var copyPart = getNewIQ3EstimateInfo(JSON.parse(JSON.stringify(iq3Data)));
    if (copyPart?.length > 0) copyPart = copyPart[0];
    if (iQ3DataLst != undefined && iQ3DataLst?.length > 0) {
      let tempIQ3List = [...iQ3DataLst];
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        name: 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5),
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      props?.selectedEstimateData?.esIq3Info?.push(copyPart);
    } else {
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
      props?.selectedEstimateData?.esIq3Info?.push(copyPart);
    }
    setIsAddPart(true);
    setSelectedEstimateData(props.selectedEstimateData);
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    if (iQ3DataLst?.length <= 1) return;
    let deletePart =
      props?.userSettingInfo?.[0]?.massages?.deletePart == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.deletePart;
    setIsDelete(deletePart);
    if (!deletePart) {
      deleteOk();
    }
    setChildTitle('削除');
  };

  // 削除ポップアップのOKボタンの押下
  const deleteOk = () => {
    setIsDelete(false);
    let estimateData = { ...selectedEstimateData };
    let tempIQ3List = [...iQ3DataLst];
    tempIQ3List = tempIQ3List?.filter((item) => item.no !== currentRecordNo);
    tempIQ3List = tempIQ3List?.map((item, index) => {
      return { ...item, no: index + 1 };
    });
    setIQ3DataLst(tempIQ3List);
    setSelectedEstimateData((prev) => ({ ...prev, esIq3Info: tempIQ3List }));
    if (tempIQ3List?.length > 0) {
      setCurrentRecordNo(tempIQ3List[0]?.no);
      setSelectedData(tempIQ3List[0]);
      setSelectedRowNo(tempIQ3List[0]?.no);
    }
    if (props.selectedEstimateData.esIq3Info) {
      props.selectedEstimateData.esIq3Info = tempIQ3List;
    }
    setIq3ActionForCal(true);
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
  };

  // 削除ポップアップのCancelボタンの押下
  const deleteCancel = () => {
    setIsDelete(false);
  };

  const calculateChildPartCount = () => {
    let childpart = 0;
    if (props?.selectedEstimateData?.esIq3Info?.length > 0) {
      childpart = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
        return total + parseInt(item.partCount);
      }, 0);
    }
    if (props?.selectedEstimateData?.estimateProducts?.processInput != undefined) {
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.smPartsCount = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.smPartsCount = childpart;
      // IQX_WEBEST-179
      props.selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo.autoExtract = childpart;
      props.selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo.autoExtract = childpart;
    }
  };
  const calculateWeightAreaForPaSurface = () => {
    let totalWeight = 0;
    let totalArea = 0;
    totalWeight = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.weight);
    }, 0);
    totalArea = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.totalSurfaceArea);
    }, 0);
    if (totalWeight === 0 && totalArea === 0) {
    } else {
      if (props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.length > 0) {
        let parentSurfaceData =
          props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.map((paSurface) => {
            return {
              ...paSurface,
              areaUnitPrice: parseFloat(toDecimal(totalArea, 2)),
              weightUnitPrice: parseFloat(toDecimal(totalWeight, 2)),

              areaAutoExtract: parseFloat(toDecimal(totalArea, 2)),
              weightAutoExtract: parseFloat(toDecimal(totalWeight, 2)),
              totalArea: parseFloat(toDecimal(totalArea * paSurface?.areaCount, 2)),
              totalWeight: parseFloat(toDecimal(totalWeight * paSurface?.weightCount, 2)),
            };
          });
        props.selectedEstimateData.estimateProducts.processInput.surfaceProcessInputInfo = parentSurfaceData;
      }
    }
  };

  // 上方向ボタンの押下
  const upRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no - 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setSelectedRowNo(record[0]?.no);
      scrollTo(record[0]?.no);
    }
    setIq3ActionForCal(true);
  };

  // 下方向ボタンの押下
  const downRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no + 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setSelectedRowNo(record[0]?.no);
      scrollTo(record[0]?.no);
    }
    setIq3ActionForCal(true);
  };

  //　追加呼出ボタンの押下
  const displayAddParentInfo = async () => {
    let newParameters = await GetParametersByCompId();
    newParameters.estimateStatus = newParameters?.estimateStatus?.filter((item) => item.info.isUsed);
    newParameters.staff = newParameters?.staff?.filter((item) => item.info.isUsed);
    newParameters.client = newParameters?.client?.filter((item) => item.info.isUsed);
    setNewParameters(newParameters);
    setParentListDisplay(true);
    setIq3Loading(true); // Waitingダイアログを表示にする
  };

  // 追加呼出ダイアログのOKボタン押下
  const addPartFromParentInfoOk = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    setParentListDisplay(false);
    if (selectedParentAddEstimate !== undefined && selectedParentAddEstimate?.estimateProducts?.id) {
      let estimateProductId = selectedParentAddEstimate?.estimateProducts?.id;
      const addIQ3Lst = await getEstimateIQ3Data(estimateProductId);
      var lastRecord = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1] : [];
      let no = lastRecord != [] ? lastRecord?.no + 1 : 1;
      const addNewIQ3Lst = [];
      for (let i = 0; i < addIQ3Lst?.length; i++) {
        var iq3Data = { ...addIQ3Lst[i] };
        iq3Data.id = 0;
        iq3Data.no = no;
        addNewIQ3Lst.push(iq3Data);
        no++;
      }
      setIQ3DataLst([...iQ3DataLst, ...addNewIQ3Lst]);
      setSelectedEstimateData({
        ...selectedEstimateData,
        esIq3Info: [...selectedEstimateData?.esIq3Info, ...addNewIQ3Lst],
      });
      if (props.selectedEstimateData.esIq3Info) {
        props.selectedEstimateData.esIq3Info = [...props.selectedEstimateData.esIq3Info, ...addNewIQ3Lst];
      }
    }
    props?.loading(false); // Waitingダイアログを非表示にする
    // IQX_WEBEST-179
    calculateChildPartCount();
    calculateWeightAreaForPaSurface();
  };

  // 追加呼出ダイアログのCancelボタン押下
  const addPartFromParentInfoCancel = () => {
    setParentListDisplay(false);
  };

  // 追加呼出画面に行を選択した場合、
  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedParentAddEstimate(estimateData);
  };

  // タブの切り替え
  const onChange = (key) => {
    setSelectedTabKey(key);
    setDiscardModeKouteiCal(false);
    onChangeTab(key);
    props?.updateIq3ActiveTabKey(key);
    setIsSelectedTab(true);
  };

  // タブの切り替え
  const onChangeTab = (key) => {
    setActiveTabKey(key);
    // getAllEstimateIq3Info();
  };

  const settingOpen = () => {
    setIsSetting(true);
  };

  const settingOK = () => {
    setIsSetting(false);
  };
  const settingCancel = () => {
    setIsSetting(false);
  };

  // 材料詳細の最初として入るかの判断を更新する
  const updateFirstTimeMaterialDetail = (value) => {
    setIsFirstTimeMaterialDetail(value);
  };

  const updateIq3PMXDatas = (value) => {
    setIq3PMXDatas(value);
    setCurrentRecordNoPMX(1);
  };

  const menuIconList = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '60%',
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          height: 20,
        }}
      >
        <Row style={{ marginTop: '0px', marginLeft: '0', width: '-webkit-fill-available' }}>
          <Col span={2}>
            <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  setIq3ActionForCal(true);
                  addPart();
                }}
              >
                <Image preview={false} width={27} src={child_add} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  // getAllEstimateIq3Info(); // 行Upで入力中の情報を保持するようにする
                  deletePart();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={child_delete}
                  style={{ marginLeft: '20px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
          </Col>
          <Col span={2}>
            <Tooltip title="追加呼出" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={displayAddParentInfo}>
                <Image preview={false} width={25} src={parent_call_detail} style={{ marginLeft: '20px' }}></Image>
              </a>
            </Tooltip>
          </Col>
          <Col span={20}>
            <Tooltip title="Up" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  // getAllEstimateIq3Info(); // 行Upで入力中の情報を保持するようにする
                  upRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={up_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="Down" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  // getAllEstimateIq3Info(); // 行Downで入力中の情報を保持するようにする
                  downRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={down_Arrow}
                  style={{ marginLeft: '20px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '39%' }}>
        {/* <a onClick={settingOpen}>
          <Image preview={false} width={23} src={setting_icon} style={{ marginBottom: '1px' }}></Image>
        </a> */}
      </div>
    </div>
  );

  const closeComponent = (
    <>
      <CaretUpOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(0, e)}
      />
      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(0, e)}>閉じる</a>
      </p>
    </>
  );

  const openComponent = (
    <>
      <CaretDownOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(1, e)}
      />

      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(1, e)}>開く</a>
      </p>
    </>
  );

  const closeComponent1 = (
    <>
      <CaretUpOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange1(0, e)}
      />
      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange1(0, e)}>閉じる</a>
      </p>
    </>
  );

  const openComponent1 = (
    <>
      <CaretDownOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange1(1, e)}
      />

      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange1(1, e)}>開く</a>
      </p>
    </>
  );

  const childPartListHeader = (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Space size="small">
          <p
            style={{
              fontSize: '13px',
              fontWeight: 'bold',
              paddingTop: 3,
              margin: 0,
              paddingLeft: 5,
              color: 'white',
            }}
          >
            部品リスト
          </p>
          {isListOpenAll ? closeComponent : openComponent}
          {isListOpen1 ? closeComponent1 : ''}
          {isListClose1 ? openComponent1 : ''}

          <div style={{ display: 'flex', marginLeft: '70px', marginTop: '1.3px' }}>
            {' '}
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 3,
                paddingRight: 3,
                color: 'white',
                border: 'solid',
                borderWidth: 1,
                borderColor: 'white',
                paddingTop: 3,
              }}
            >
              選択中
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                paddingLeft: 10,
                margin: 0,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined && selectedData.no != undefined ? 'No.' + selectedData.no : 'No.0'}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.drawingNo : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.name : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.partCount : 0}
            </p>
          </div>
        </Space>
      </div>
    </>
  );

  const modalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const parentListModalTitle = (
    <div
      style={{
        width: 1670,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 33,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 5, paddingLeft: 18, color: 'white' }}>追加呼出</p>
    </div>
  );

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      className: 'cm-a-right',
      width: 50 + 'px',
      // render: (value, array, index) => index + 1,
    },
    {
      id: '2',
      title: '採用',
      dataIndex: 'creationType',
      width: 60 + 'px',
      align: 'center',
      render: (value) => (value === EstimateCreationType.Auto ? '自動' : '手動'),
    },
    {
      id: '3',
      title: '画像',
      dataIndex: 'imageUrl', // IQX_WEBEST-207
      width: 140 + 'px',
      align: 'center',
      render: (value) => (
        <img className={value === undefined || value === '' ? 'pmx-no-img' : 'pmx-img'} src={value} alt="" />
      ), // IQX_WEBEST-247
    },
    {
      id: '4',
      title: ['工程パターン', <br />, '子部品図番', <br />, '子部品名称'],
      dataIndex: 'processSelect',
      width: 220 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>
          <label style={{ fontSize: '13px' }}>{record?.processSelect?.name}</label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.drawingNo}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 2 }} value={record.imgNo} /> */}
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.name}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 3 }} value={record.partNm} /> */}
          <br />
        </div>
      ),
    },
    {
      id: '5',
      title: '員数(製造数)',
      dataIndex: 'partCount',
      width: 80 + 'px',
      align: 'center',
      render: (partCount) =>
        partCount + '(' + partCount * props?.selectedEstimateData?.estimateProducts?.quantity + ')',
    },
    {
      id: '6',
      title: ['材質', <br />, '材料', <br />, '板厚', <br />, '表面保護'],
      dataIndex: 'materialItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) =>
        estimateParameters ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px' }}>
              {getMaterialNameById(record.materialIq3Id, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialThickById(record.thickness, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialSurfaceById(record.materialSurfaceIq3Id, estimateParameters?.materialSurfaceIq3)}
            </label>
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: '7',
      title: ['重量', <br />, '表面積', <br />, '表面処理費'],
      dataIndex: 'amountItems',
      width: 100 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.weight ? toDecimal(record.weight, 2) : toDecimal(0, 2)}
            kg
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.totalSurfaceArea != undefined ? toDecimal(record.totalSurfaceArea, 2) : toDecimal(0, 2)}
            cm<sup>2</sup>
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.surfaceTotalPrice ? JPYs.format(Math.round(record.surfaceTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '8',
      title: ['材料原価', <br />, '製造原価', <br />, '購入品費'],
      dataIndex: 'costItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialCostUnitPrice ? JPYs.format(Math.round(record.materialCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.workCostUnitPrice ? JPYs.format(Math.round(record.workCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.purchaseUnitPrice ? JPYs.format(Math.round(record.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'costUnitPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costUnitPrice ? JPYs.format(Math.round(record.costUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'costTotalPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costTotalPrice ? JPYs.format(Math.round(record.costTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
    },
    {
      key: '2',
      label: `工程選択`,
    },
    {
      key: '3',
      label: `工程入力`,
    },
    {
      key: '4',
      label: `工程積算`,
    },
    {
      key: '5',
      label: (
        <Tooltip title={iq3PurchaseTitle} placement="top" overlayClassName="tooltip-text">
          購入品
        </Tooltip>
      ),
    },
    {
      key: '6',
      label: `材料詳細`,
    },
  ];

  const settingModalTitle = (
    <div
      style={{
        width: 450,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <p style={{ marginLeft: 7, paddingTop: 8, color: 'white' }}>設定</p>
    </div>
  );

  // 設定コンテンツ
  const settingContent = (
    <>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>空送線を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>プランク加工の下穴を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>外形線を1本化処理して計算する</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>同一線上の曲げ線をまとめる</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={settingOK} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={settingCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // タブ切り替えで、材料詳細の情報を再計算するかどうかのチェック
  const isReCalMaterial = (isChangedProcessSelect = false) => {
    let selectedIq3Data = { ...selectedData };
    if (isChangedProcessSelect) {
      selectedIq3Data = props?.selectedEstimateData?.esIq3Info?.find((iq3) => iq3?.no === selectedData?.no);
      setSelectedData(selectedIq3Data);
    }
    let oldSelectedEstimateData = iq3MaterialDetailRef?.current?.getSelectedEstimateData();
    if (props?.selectedEstimateData?.estimateProducts?.id === 0 && oldSelectedEstimateData === undefined)
      return reCalMaterial.oneRecordCal;
    if (props?.selectedEstimateData?.estimateProducts?.id !== 0 && oldSelectedEstimateData === undefined)
      return reCalMaterial.noCal;
    let oldSelectedData = oldSelectedEstimateData?.esIq3Info?.find((iq3) => iq3.no === selectedIq3Data?.no);
    if (props?.selectedEstimateData?.estimateProducts?.id === 0 && oldSelectedData === undefined)
      return reCalMaterial.noCal;
    if (props?.selectedEstimateData?.estimateProducts?.id !== 0 && oldSelectedData === undefined)
      return reCalMaterial.noCal;
    let oldEstiamteQuantity = oldSelectedEstimateData?.estimateProducts?.quantity;
    let estiamteQuantity = props?.selectedEstimateData?.estimateProducts?.quantity;
    oldEstiamteQuantity = oldEstiamteQuantity ? oldEstiamteQuantity : 0;
    estiamteQuantity = estiamteQuantity ? estiamteQuantity : 0;
    if (
      oldSelectedData &&
      (Number(oldSelectedData?.partCount) !== Number(selectedIq3Data?.partCount) ||
        Number(oldSelectedData?.materialIq3Id) !== Number(selectedIq3Data?.materialIq3Id) ||
        Number(oldSelectedData?.materialTypeIq3Id) !== Number(selectedIq3Data?.materialTypeIq3Id) ||
        Number(oldSelectedData?.materialSurfaceIq3Id) !== Number(selectedIq3Data?.materialSurfaceIq3Id) ||
        Number(oldSelectedData?.thickness) !== Number(selectedIq3Data?.thickness) ||
        Number(oldSelectedData?.partSizeX) !== Number(selectedIq3Data?.partSizeX) ||
        Number(oldSelectedData?.partSizeY) !== Number(selectedIq3Data?.partSizeY) ||
        Number(oldSelectedData?.grabbingX) !== Number(selectedIq3Data?.grabbingX) ||
        Number(oldSelectedData?.grabbingY) !== Number(selectedIq3Data?.grabbingY) ||
        Number(oldSelectedData?.pierWidthX) !== Number(selectedIq3Data?.pierWidthX) ||
        Number(oldSelectedData?.pierWidthY) !== Number(selectedIq3Data?.pierWidthY) ||
        Number(oldSelectedData?.worlRangeX) !== Number(selectedIq3Data?.worlRangeX) ||
        Number(oldSelectedData?.worlRangeY) !== Number(selectedIq3Data?.worlRangeY))
    ) {
      return reCalMaterial.oneRecordCal; // 選択中の板金子部品のみ材料を再計算
    } else if (
      Number(estiamteQuantity) !== Number(oldEstiamteQuantity) || // 数量を変更した場合、
      props?.isReCalMaterial || // 再計算の場合、
      (iq3PMXDatas?.length === 0 && props?.type) // PMXデータの取り込み完了した場合、
    ) {
      if (props?.isReCalMaterial) {
        props?.updateIsRecalMaterial(false);
      }
      return reCalMaterial.allRecordCal; // 板金子部品全部の材料を再計算
    }
    return reCalMaterial.noCal; // 計算しない
  };

  // Waitingダイアログを表示・非表示に更新する
  const updateLoading = (load) => {
    setIq3Loading(load);
  };

  return (
    <>
      <div>
        <Form>
          {/* 一覧とプロパティ表示 */}{' '}
          <div
            style={{
              height: '85.9vh',
            }}
          >
            <Row id="iq3-rows" style={{ marginLeft: '5px', width: '99.5%' }} className="oyabuhin oyabuhin-row">
              <Col span={24}>{childPartListHeader}</Col>
            </Row>
            {menuIconList}
            <PanelGroup direction="vertical">
              <Panel id="iq3-list-panel-id" collapsible={true} defaultSize={39.3} maxSize={89}>
                <div id="iq3-list-div" className="iq3-list-div">
                  <Table
                    id="iq3EstimateTbl"
                    showSorterTooltip={false}
                    rowClassName={(record) => (record.no == currentRecordNo ? 'active-row' : 'data-row')}
                    columns={iq3Columns}
                    rowKey={(record) => record.no}
                    dataSource={iQ3DataLst}
                    className="iq3-list-tb"
                    // rowSelection={rowSelection}
                    pagination={false}
                    scroll={{ y: 'calc(100vh-220px)', x: '950' }}
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          // getAllEstimateIq3Info(); // 行選択で入力中の情報を保持するようにする
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
              <Panel id="iq3-detail-panel-id" className="iq3-detail-panel" collapsible={true} defaultSize={60.7}>
                <div className="iq3-detail-div">
                  <Tabs
                    id="iq3-detail-tab"
                    className="iq3-detail-tab"
                    type="card"
                    activeKey={activeTabKey}
                    defaultActiveKey="1"
                    items={tabItems}
                    onChange={onChange}
                  />
                  {/* 基本情報 */}
                  <div style={{ display: activeTabKey == '1' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3EstimateDetail
                      isListOpen={isListOpenAll}
                      selectedIQ3DataDetail={selectedData}
                      ref={iq3DetailRef}
                      materialLst={estimateParameters?.materialIq3}
                      materialTypeLst={estimateParameters?.materialTypeIq3}
                      materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                      isReCal={props?.isReCal}
                      selectedEstimateData={props.selectedEstimateData}
                      currentRecordNo={currentRecordNo}
                      activeTabKey={activeTabKey}
                      partMenuChange={props.activePartMenu}
                    />
                  </div>
                  {/* 工程選択 */}
                  <div style={{ display: activeTabKey == '2' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiSentaku
                      type={'list'}
                      ref={iq3KouteiSentakuRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      // selectedEstimateData={props.selectedEstimateData}
                      selectedDataDetail={props.selectedEstimateData}
                      iQ3DataLst={iQ3DataLst}
                      pmxMode={false}
                      updateSelectedData={updateSelectedData}
                      processMasterData={processMasterInfo}
                      deviceMasterData={deviceMasterInfo}
                      clientMasterData={clientMasterInfo}
                      currentRecordNo={currentRecordNo}
                      activeTabKey={activeTabKey}
                      isReCal={props?.isReCal}
                      updateProcessSelectOfSelectedData={updateProcessSelectOfSelectedData}
                    />
                  </div>
                  {/* 工程入力 */}
                  <div style={{ display: activeTabKey == '3' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiInput
                      ref={iq3KouteiInputRef}
                      processMasterData={processMasterInfo}
                      // editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      materialList={materialLst}
                      currentRecordNo={currentRecordNo}
                      activeTabKey={activeTabKey}
                      selectedEstimateData={props.selectedEstimateData}
                      isReCal={props?.isReCal}
                    />
                  </div>
                  {/* 工程積算 */}
                  <div style={{ display: activeTabKey == '4' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiCalculate
                      ref={iq3KouteiCalRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      deviceMasterInfo={deviceMasterInfo}
                      processMasterData={processMasterInfo}
                      selectedEstimateData={props.selectedEstimateData}
                      activeTabKey={activeTabKey}
                      currentRecordNo={currentRecordNo}
                      iq3ActionForCal={iq3ActionForCal}
                      isReCal={props?.isReCal}
                      activePartMenu={props?.activePartMenu}
                      selectedProcessSelect={selectedProcessSelect}
                    />
                  </div>
                  {/* 購入品 */}
                  <div style={{ display: activeTabKey == '5' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3Purchase
                      ref={purchaseRef}
                      selectedIQ3DataDetail={selectedData}
                      selectedEstimateData={props.selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                      // editMode={editMode}
                      isChildPart={true}
                      activeTabKey={activeTabKey}
                      currentRecordNo={currentRecordNo}
                      isReCal={props?.isReCal}
                    />
                  </div>
                  {/* 材料詳細 */}
                  <div style={{ display: activeTabKey == '6' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3MaterialDetail
                      ref={iq3MaterialDetailRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      selectedEstimateData={props.selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                      materialLst={estimateParameters?.materialIq3}
                      materialTypeLst={estimateParameters?.materialTypeIq3}
                      materialSurfaceLst={estimateParameters?.materialSurfaceIq3}
                      processMasterData={getProcessMstInfo(estimateParameters?.process)}
                      deviceMasterData={getDeviceMstInfo(estimateParameters?.device, estimateParameters?.process)}
                      isReCalMaterial={isReCalMaterial()}
                      activeTabKey={activeTabKey}
                      // 新規子部品+材料詳細のタグ押下+初回押下
                      isFirstTimeMaterialDetail={
                        selectedData && selectedData?.id === 0 && activeTabKey === '6' && isFirstTimeMaterialDetail
                          ? true
                          : false
                      }
                      updateFirstTimeMaterialDetail={updateFirstTimeMaterialDetail}
                      currentRecordNo={currentRecordNo}
                    />
                  </div>
                  {/* 工程別合計 */}
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '6px',
                      position: 'absolute',
                      top: '-36px',
                      right: 0,
                    }}
                  >
                    <KouteiSuryouBetsuBtn
                      selectedEstimateData={props?.selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                    />
                  </div>
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
            </PanelGroup>
          </div>
        </Form>

        {/** 開発中ポップアップ */}
        {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
        {/** 編集中破棄確認ポップアップ */}
        {editModeCancelConfirm
          ? commonModal(
              editModeCancelConfirm,
              confirmScreenChangeTitle,
              commonFooter(discardConfirmOk, discardConfirmCancel),
              null,
              400,
              discardConfirmOk,
              discardConfirmCancel,
              confirmScreenChangeContent,
              null,
              null,
              false
            )
          : ''}
        {/** 更新 / 破棄の確認メッセージ */}
        {confirmMsgOpen
          ? commonModal(
              confirmMsgOpen,
              modalTitle,
              commonFooter(confirmOk, confirmCancel),
              null,
              400,
              confirmOk,
              confirmCancel,
              discardMode ? confirmDiscardContent : confirmUpdateContent,
              null,
              null,
              false
            )
          : ''}

        {/* 削除 */}
        {isDelete
          ? commonModal(
              isDelete,
              confirmModalTitle,
              commonDeleteFooter(deleteOk, deleteCancel),
              null,
              400,
              deleteOk,
              deleteCancel,
              /*  editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent, */
              <p style={{ fontSize: '13.5px' }}>
                選択中の子部品を削除します。
                <br></br>削除したデータは戻せませんが、よろしいですか？
              </p>,
              null,
              null,
              false
            )
          : ''}

        {/* 親部品リストダイアログ */}
        {parentListDisplay ? (
          <Modal
            maskClosable={false}
            open={parentListDisplay}
            title={parentListModalTitle}
            footer={null}
            bodyStyle={{ height: '71.7vh', marginTop: -5, marginLeft: -23 }}
            width={1670}
            onOk={addPartFromParentInfoOk}
            onCancel={addPartFromParentInfoCancel}
            closeIcon={<CloseOutlined style={{ color: 'white' }} />}
            className={null}
            style={{ top: 190, left: 50 }}
            centered={false}
          >
            <Spin spinning={iq3Loading} tip="Loading..." size="large">
              <div style={{ borderBottom: '1px solid #6c757d', width: '101.2%' }}>
                <ParentList
                  getSelectedEstimateData={getSelectedEstimateData}
                  editMode={false}
                  parentListClick={false}
                  isFromiQ3={true}
                  orderOptions={newParameters?.estimateStatus}
                  companyInfo={newParameters?.company}
                  clientInfo={newParameters?.client}
                  userInfo={newParameters?.currentUserInfo}
                  staffInfo={newParameters?.staff}
                  processInfo={newParameters?.process}
                  iq3Loading={updateLoading}
                />
              </div>
              <Row
                justify="end"
                style={{
                  marginBottom: 0,
                  marginTop: 7,
                }}
              >
                <Button className="mainButton" onClick={addPartFromParentInfoOk} style={{ marginRight: 5 }}>
                  追加
                </Button>
                <Button className="cancelButton" onClick={addPartFromParentInfoCancel}>
                  閉じる
                </Button>
              </Row>
            </Spin>
          </Modal>
        ) : (
          ''
        )}
        {/* 設定操作 */}
        {isSetting
          ? commonModal(
              isSetting,
              settingModalTitle,
              null,
              { height: 123 },
              450,
              settingOK,
              settingCancel,
              settingContent,
              'updateModalStyle',
              null,
              false
            )
          : ''}
      </div>
    </>
  );
});

export default IQ3Estimate;
