/**
 * クラス名：板金子部品工程入力
 * 説明：板金子部品工程入力ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import IQ3KouteiInput_Program from './IQ3KouteiInput_Program';
import IQ3KouteiInput_Blank from './IQ3KouteiInput_Blank';
import IQ3KouteiInput_Bending from './IQ3KouteiInput_Bending';
import IQ3KouteiInput_Baritori from './IQ3KouteiInput_Baritori';
import IQ3KouteiInput_Yousetsu from './IQ3KouteiInput_Yousetsu';
import IQ3KouteiInput_Hyoumenshori from './IQ3KouteiInput_Hyoumenshori';
import IQ3KouteiInput_2Jikakou from './IQ3KouteiInput_2Jikakou';
import IQ3KouteiInput_Shearing from './IQ3KouteiInput_Shearing';
import IQ3KouteiInput_Kensa from './IQ3KouteiInput_Kensa';
import { WorkType } from '../common/enums';
import { getMaterialTypeById, handleKeyPress, regexExp, toDecimal } from '../common/Common';

const IQ3KouteiInput = forwardRef((props, ref) => {
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isShirring, setIsShirring] = useState(false);
  const [isKensa, setIsKensa] = useState(false);
  const [isProgram, setIsProgram] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isBending, setIsBending] = useState(false);
  const [isAuto, setIsAuto] = useState(false);
  const [isPlank, setIsPlank] = useState(false);
  const [isSecondProcess, setIsSecondProcess] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const [programValues, setProgramValues] = useState([]);
  const [rectangleTbl, setRectangleTbl] = useState([]);

  const [hyoumenshoriTbl, setHyoumenshori] = useState([]);

  // カット回数
  const [numOfCuts, setNumOfCuts] = useState('1');
  // 検査回数
  const [numOfKensa, setNumOfKensa] = useState('1');

  const [displayProgram, setDisplayProgram] = useState(false);
  const [displayBlank, setDisplayBlank] = useState(false);
  const [displaySecondaryWork, setDisplaySecondaryWork] = useState(false);
  const [displayBending, setDisplayBending] = useState(false);
  const [displayDeburring, setDisplayDeburring] = useState(false);
  const [displayWelding, setDisplayWelding] = useState(false);
  const [displaySurface, setDisplaySurface] = useState(false);
  const [displayShearing, setDisplayShearing] = useState(false);
  const [displayInspection, setDisplayInspection] = useState(false);

  const [processInputInfo, setProcessInputInfo] = useState([]);

  const [blankInfo, setBlankInfo] = useState([]);
  const [secondaryWorkInfo, setSecondaryWorkInfo] = useState([]);
  const [bendingInfo, setBendingInfo] = useState([]);
  const [manualDeburringInfo, setManualDeburringInfo] = useState([]);

  const [autoDeburringInfo, setAutoDeburringInfo] = useState([]);
  const [weldingInfo, setWeldingInfo] = useState([]);
  const [surfaceInfo, setSurfaceInfo] = useState([]);
  const [shearingInfo, setShearingInfo] = useState([]);
  const [inspectionInfo, setInspectionInfo] = useState([]);

  const [surfaceData, setSurfaceData] = useState([]);
  const [materialId, setMaterialId] = useState('');
  const [materialType, setMaterialTypeName] = useState('');
  const [thickness, setThickness] = useState(0);
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [weight, setWeight] = useState(0);
  const [area, setArea] = useState(0);
  const [gravity, setGravity] = useState(0);

  const [iq3RecordIndex, setIQ3RecordIndex] = useState(0);
  const [programSizeLimit, setProgramSizeLimit] = useState(1);

  const [blankProcessesId, setBlankProcessesId] = useState([]);

  // IQX_WEBEST-179
  const [autoExtractXSize, setAutoExtractXSize] = useState(0);
  const [autoExtractYSize, setAutoExtractYSize] = useState(0);

  useEffect(() => {
    let curRowIndex = 1;

    if (props.selectedIQ3DataDetail != undefined && props.selectedEstimateData != undefined) {
      let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
      for (let i = 0; i < props.selectedEstimateData?.esIq3Info?.length; i++) {
        if (props.selectedEstimateData?.esIq3Info[i]?.no === curRowNo) {
          setIQ3RecordIndex(i);
          curRowIndex = i;
        }
      }

      const surfaceDetails = props.processMasterData?.filter((i) => i.workType === WorkType.SmSurface);
      setSurfaceData(surfaceDetails);

      let surfaceProcessId = 0;
      if (surfaceDetails?.length > 0) {
        surfaceProcessId = surfaceDetails?.[0].id;
      }
      let surface = [];
      surface = props.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === surfaceProcessId);

      let surfaceDataFromSelect = [];
      if (surface?.length > 0) {
        surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
          (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
        );
      }
      // 材質名称リスト
      let materialTypeLst =
        props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3?.filter(
          (i) => i?.info?.isUsed
        );
      let materialType = getMaterialTypeById(props?.selectedIQ3DataDetail?.materialTypeIq3Id, materialTypeLst);

      setMaterialTypeName(materialType);

      // 材料名称リスト
      let materialLst = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq3?.filter(
        (i) => i?.info?.isUsed
      );
      let material = getMaterialTypeById(props?.selectedIQ3DataDetail?.materialIq3Id, materialLst);

      setMaterialId(material);
      let gravityVal = 0;
      let thicknessVal = 0;
      let xsize = props?.selectedIQ3DataDetail?.partSizeX ? toDecimal(props?.selectedIQ3DataDetail?.partSizeX, 2) : 0;
      let ysize = props?.selectedIQ3DataDetail?.partSizeY ? toDecimal(props?.selectedIQ3DataDetail?.partSizeY, 2) : 0;
      setSizeX(xsize);
      setSizeY(ysize);

      const thickId = props?.selectedIQ3DataDetail?.materialIq3Id;
      const thicknessInfo = props?.materialList?.find((item) => item.id === thickId);

      if (thicknessInfo != undefined) {
        setThickness(thicknessInfo?.info?.thick);
        thicknessVal = thicknessInfo?.info?.thick;
        gravityVal = thicknessInfo?.info?.gravity;
        setGravity(gravityVal);
      }
      // let recSize = calculateWeightArea(thicknessVal, xsize, ysize, gravityVal, curRowIndex);
      // IQX_WEBEST-84(PMXと新規の場合、面積と重量を再計算しないようにする)
      let recSize = [props?.selectedIQ3DataDetail?.weight, props?.selectedIQ3DataDetail?.totalSurfaceArea];
      setWeight(props?.selectedIQ3DataDetail?.weight);
      setArea(props?.selectedIQ3DataDetail?.totalSurfaceArea);

      let processInput = props?.selectedIQ3DataDetail?.processInput;

      setProcessInputInfo(processInput);
      processInput?.map((item, index) => {
        if (index === 0) {
        }
        setAutoExtractXSize(item.autoExtractXSize ? toDecimal(item.autoExtractXSize, 2) : 0.0);
        setAutoExtractYSize(item.autoExtractYSize ? toDecimal(item.autoExtractYSize, 2) : 0.0);
        const processMstInfo = props?.processMasterData?.filter((i) => i.id === item.processId)?.[0] || [];

        const workType = processMstInfo?.workType || '';

        if (processMstInfo.workType === WorkType.SmProgram) {
          setProgramValues(item);
        } else if (
          processMstInfo.workType === WorkType.SmBlankCombi ||
          processMstInfo.workType === WorkType.SmBlankLaser ||
          processMstInfo.workType === WorkType.SmBlankPunch
        ) {
          let matchItem = props.selectedIQ3DataDetail?.processSelect?.details?.find(
            (i) => i.processId === item.processId
          );

          if (matchItem) {
            const isUsed = matchItem.isUsedForCalc;
            if (isUsed === true) {
              setBlankInfo(item);
            }
          }
        } else if (processMstInfo.workType === WorkType.SmSecondaryWork) {
          setSecondaryWorkInfo(item);
        } else if (workType === WorkType.SmBending) {
          setBendingInfo(item);
        } else if (workType === WorkType.SmDeburringAuto) {
          setAutoDeburringInfo(item);
        } else if (workType === WorkType.SmDeburringManual) {
          setManualDeburringInfo(item);
        } else if (workType === WorkType.SmWelding) {
          setWeldingInfo(item);
        } else if (workType === WorkType.SmSurface) {
          // IQX_WEBEST-226
          const surfaceDeviceDetails =
            props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.device?.filter(
              (i) => i.id === surface?.[0]?.deviceId
            )?.[0]?.details;
          const result = surfaceDataFromSelect?.map((item1) => {
            const matchingData = item.details?.find((item2) => item2.no === item1.processNo);
            const unit = surfaceDeviceDetails?.charges[item1?.processDetailTypeId]?.unit; // IQX_WEBEST-226
            if (matchingData) {
              return {
                ...matchingData,
                calcType: unit !== undefined ? unit : 1, // IQX_WEBEST-226
                processDetailWorkTypeGroup: item1?.processDetailGroup,
                processDetailTypeNo: item1?.processDetailTypeId,
              };
            }
            // else {
            return {
              no: item1?.processNo,
              calcType: unit !== undefined ? unit : 1, // IQX_WEBEST-226,
              processDetailWorkTypeGroup: item1?.processDetailGroup,
              processDetailTypeNo: item1?.processDetailTypeId,
              areaUnitPrice: toDecimal(recSize?.[1], 2),
              weightUnitPrice: toDecimal(recSize?.[0], 2),
              weightCount: 1,
              weightCountAutoExtract: 1,
              areaCount: 1,
              areaCountAutoExtract: 1,
              areaAutoExtract: 0,
              weightAutoExtract: 0,
              totalArea: toDecimal(recSize?.[1], 2) * 1,
              totalWeight: toDecimal(recSize?.[0], 2) * 1,
            };
            // }
          });
          item.details = result;
          setSurfaceInfo(item);
        } else if (workType === WorkType.SmShearing) {
          setShearingInfo(item);
        } else if (workType === WorkType.SmInspection) {
          setInspectionInfo(item);
        }
      });

      checkDisplayPanel();
      // calculateWeightAreaForPaSurface();
    }
    const blankProcessesId = [];
    props?.processMasterData?.filter((i) => {
      if (
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
      ) {
        blankProcessesId.push(i.id);
      }
    });
    setBlankProcessesId(blankProcessesId);
  }, [props.selectedIQ3DataDetail, props.processMasterData, props.activeTabKey, props.selectedEstimateData]);

  const calculateWeightAreaForPaSurface = () => {
    let totalWeight = 0;
    let totalArea = 0;
    totalWeight = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.weight);
    }, 0);
    totalArea = props?.selectedEstimateData?.esIq3Info?.reduce((total, item) => {
      return total + parseFloat(item.totalSurfaceArea);
    }, 0);
    if (totalWeight === 0 && totalArea === 0) {
    } else {
      if (props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.length > 0) {
        let parentSurfaceData =
          props?.selectedEstimateData?.estimateProducts?.processInput?.surfaceProcessInputInfo?.map((paSurface) => {
            return {
              ...paSurface,
              areaUnitPrice: parseFloat(toDecimal(totalArea, 2)),
              weightUnitPrice: parseFloat(toDecimal(totalWeight, 2)),

              areaAutoExtract: parseFloat(toDecimal(totalArea, 2)),
              weightAutoExtract: parseFloat(toDecimal(totalWeight, 2)),
              totalArea: parseFloat(toDecimal(totalArea * paSurface?.areaCount, 2)),
              totalWeight: parseFloat(toDecimal(totalWeight * paSurface?.weightCount, 2)),
            };
          });
        props.selectedEstimateData.estimateProducts.processInput.surfaceProcessInputInfo = parentSurfaceData;
      }
    }
  };
  // 重量計算
  const calculateWeightArea = (thicknessVal, xsize, ysize, gravityVal, curRowIndex) => {
    let weightVal = ((thicknessVal * parseInt(xsize) * parseInt(ysize)) / 1000000) * gravityVal;
    setWeight(weightVal);
    let areaVal = (toDecimal(xsize, 2) * toDecimal(ysize, 2)) / 100;

    setArea(areaVal);
    if (props.selectedEstimateData.esIq3Info[curRowIndex]) {
      props.selectedEstimateData.esIq3Info[curRowIndex].weight = weightVal;
      props.selectedEstimateData.esIq3Info[curRowIndex].totalSurfaceArea = areaVal;
    }
    calculateWeightAreaForPaSurface();

    return [weightVal, areaVal];
  };
  const checkDisplayPanel = () => {
    // プログラム
    let programData = props.processMasterData?.filter((i) => i.workType === WorkType.SmProgram);
    if (programData && programData?.length > 0) {
      let programId = programData?.[0].id;
      let program = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === programId);

      if (program != undefined) {
        setDisplayProgram(program?.[0]?.isUsedForCalc);
        // プログラムの要素数のため、装置データ取得
        let deviceId = program?.[0]?.deviceId;
        if (deviceId) {
          let deviceData = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.device?.filter(
            (i) => i.id === deviceId
          );
          setProgramSizeLimit(deviceData?.[0]?.details?.shapeGrouping);
        }
      }
    }
    // ブランク
    let blankData = props.processMasterData?.filter(
      (i) =>
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
    );
    if (blankData && blankData?.length > 0) {
      const filteredList2 = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item2) =>
        blankData.some((item1) => item1.id === item2.processId)
      );

      const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

      setDisplayBlank(isAnyTrue);
    }
    // 2次加工
    let secondaryWorkData = props.processMasterData?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secondaryWorkData && secondaryWorkData?.length > 0) {
      let secondaryWorkId = secondaryWorkData?.[0].id;
      let secordaryWork = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === secondaryWorkId
      );
      if (secordaryWork != undefined) {
        setDisplaySecondaryWork(secordaryWork?.[0]?.isUsedForCalc);
      }
    }
    // ベンディング
    let bendingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingData && bendingData?.length > 0) {
      let bendingId = bendingData?.[0].id;
      let bending = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === bendingId);
      if (bending != undefined) {
        setDisplayBending(bending?.[0]?.isUsedForCalc);
      }
    }
    // バリ取り
    let deburringData = props.processMasterData?.filter(
      (i) => i.workType === WorkType.SmDeburringAuto || i.workType === WorkType.SmDeburringManual
    );
    if (deburringData && deburringData?.length > 0) {
      const filteredList2 = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item2) =>
        deburringData?.some((item1) => item1.id === item2.processId)
      );

      const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

      setDisplayDeburring(isAnyTrue);
    }
    // 溶接
    let weldingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingData && weldingData?.length > 0) {
      let weldingId = weldingData?.[0].id;
      let welding = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === weldingId);
      if (welding != undefined) {
        setDisplayWelding(welding?.[0]?.isUsedForCalc);
      }
    }
    // 表面処理
    let surfaceData = props.processMasterData?.filter((i) => i.workType === WorkType.SmSurface);
    if (surfaceData && surfaceData?.length > 0) {
      let surfaceId = surfaceData?.[0].id;
      let surface = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === surfaceId);
      if (surface != undefined) {
        setDisplaySurface(surface?.[0]?.isUsedForCalc);
      }
    }
    // シャーリング
    let shearingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmShearing);
    if (shearingData && shearingData?.length > 0) {
      let shearingId = shearingData?.[0].id;
      let shearing = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === shearingId);
      if (shearing != undefined) {
        setDisplayShearing(shearing?.[0]?.isUsedForCalc);
      }
    }
    // 検査
    let inspectionData = props.processMasterData?.filter((i) => i.workType === WorkType.SmInspection);
    if (inspectionData && inspectionData?.length > 0) {
      let inspectionId = inspectionData?.[0].id;
      let inspection = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === inspectionId
      );
      if (inspection != undefined) {
        setDisplayInspection(inspection?.[0]?.isUsedForCalc);
      }
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };

  const plank = () => {
    if (isPlank == true) {
      setIsPlank(false);
    } else {
      setIsPlank(true);
    }
  };

  const hyoumenShori = () => {
    if (isHyoumenShori === true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const shirring = () => {
    if (isShirring === true) {
      setIsShirring(false);
    } else {
      setIsShirring(true);
    }
  };
  const kensa = () => {
    if (isKensa === true) {
      setIsKensa(false);
    } else {
      setIsKensa(true);
    }
  };

  const program = () => {
    if (isProgram == true) {
      setIsProgram(false);
    } else {
      setIsProgram(true);
    }
  };
  const secondProcess = () => {
    if (isSecondProcess === true) {
      setIsSecondProcess(false);
    } else {
      setIsSecondProcess(true);
    }
  };

  const bending = () => {
    if (isBending == true) {
      setIsBending(false);
    } else {
      setIsBending(true);
    }
  };
  const auto = () => {
    if (isAuto == true) {
      setIsAuto(false);
    } else {
      setIsAuto(true);
    }
  };
  const manual = () => {
    if (isManual == true) {
      setIsManual(false);
    } else {
      setIsManual(true);
    }
  };

  // IQX_WEBEST-199
  const calculateProgramCount = (result, sizeX, sizeY, sizeLimit, resultSecondaryWork, resultBending) => {
    let totalCount = 0;

    if (Object.keys(result).length !== 0) {
      // IQX_WEBEST-199
      const typeSumObj = {};
      let blankList = result?.details?.[0]?.quantityList?.filter((i) => parseInt(i.perimeter) !== 0);
      blankList?.forEach((item) => {
        // プランクの種類を取得
        const type = item.types;
        // 同一種類を取得
        const isSameTypes = blankList?.filter((item) => item.types === type);
        let sameTypeItem = {
          id: item.id,
          types: type,
          count: (typeSumObj[type]?.count || 0) + parseInt(item.count, 10), // 同一種類の場合、同一数を加える
          sameType: isSameTypes?.length > 1 ? true : false,
        };
        sameTypeItem.count = sameTypeItem.count ? sameTypeItem.count : 0;
        typeSumObj[type] = sameTypeItem;
      });
      Object.values(typeSumObj).forEach((type) => {
        if (type.sameType) {
          // 同一形状の場合、同一サイズの上限値より多いの場合、同一サイズの上限値を採用。
          totalCount += type.count > sizeLimit ? sizeLimit : type.count;
        } else {
          totalCount += type.count;
        }
      });
    }
    // IQX_WEBEST-199
    if (resultSecondaryWork && Object.keys(resultSecondaryWork).length !== 0) {
      const typeSumObjSecondary = {};
      let secondaryWorkList = resultSecondaryWork?.details?.filter((i) => parseInt(i.diameter) !== 0);
      secondaryWorkList?.forEach((item) => {
        // ２次加工の種類を取得
        const type = item.types;
        // 同一種類を取得
        const isSameTypes = secondaryWorkList?.filter((item) => item.types === type);
        let sameTypeItem = {
          id: item.id,
          types: type,
          count: (typeSumObjSecondary[type]?.count || 0) + parseInt(item.count, 10), // 同一種類の場合、同一数を加える
          sameType: isSameTypes?.length > 1 ? true : false,
        };
        sameTypeItem.count = sameTypeItem.count ? sameTypeItem.count : 0;
        typeSumObjSecondary[type] = sameTypeItem;
      });
      Object.values(typeSumObjSecondary).forEach((type) => {
        if (type.sameType) {
          // 同一形状の場合、同一サイズの上限値より多いの場合、同一サイズの上限値を採用。
          totalCount += type.count > sizeLimit ? sizeLimit : type.count;
        } else {
          totalCount += type.count;
        }
      });
    }
    // IQX_WEBEST-199
    if (resultBending && Object.keys(resultBending).length !== 0) {
      let typeSumBending = {};
      let bendingList = resultBending?.details?.[0]?.bendingList?.filter((i) => parseInt(i.bendLength) !== 0);
      bendingList?.forEach((item) => {
        // ベンディングの種類を取得
        const type = item.types;
        // 同一種類を取得
        const isSameTypes = bendingList?.filter((item) => item.types === type);
        let sameTypeItem = {
          id: item.id,
          types: type,
          count: (typeSumBending[type]?.count || 0) + parseInt(item.lines, 10), // 同一種類の場合、同一数を加える
          sameType: isSameTypes?.length > 1 ? true : false,
        };
        sameTypeItem.count = sameTypeItem.count ? sameTypeItem.count : 0;
        typeSumBending[type] = sameTypeItem;
      });
      Object.values(typeSumBending).forEach((type) => {
        if (type.sameType) {
          // 同一形状の場合、同一サイズの上限値より多いの場合、同一サイズの上限値を採用。
          totalCount += type.count > sizeLimit ? sizeLimit : type.count;
        } else {
          totalCount += type.count;
        }
      });
    }

    if (sizeX != 0 && sizeY != 0) {
      programValues.details[0].elements = 4 + totalCount;
      programValues.details[0].elementsAuto = 4 + totalCount;
    } else {
      programValues.details[0].elements = 0 + totalCount;
      programValues.details[0].elementsAuto = 0 + totalCount;
    }
    setProgramValues(programValues);
  };

  const updateProcessData = (result) => {
    const updatedLists = [...processInputInfo];
    const indexToUpdate = updatedLists?.findIndex(
      (item) => item.id === result.id && item.processId === result.processId
    );

    if (indexToUpdate !== -1) {
      updatedLists[indexToUpdate].details = result?.details;
    }
    setProcessInputInfo(updatedLists);
    props.selectedEstimateData.esIq3Info[iq3RecordIndex].processInput = updatedLists;

    const processMstInfo = props?.processMasterData?.filter((i) => i.id === result.processId)[0] || [];

    const workType = processMstInfo.workType || '';

    if (
      workType === WorkType.SmBlankCombi ||
      workType === WorkType.SmBlankLaser ||
      workType === WorkType.SmBlankPunch
    ) {
      let updateList = [...updatedLists];

      blankProcessesId.forEach((id) => {
        updateList = updateList.map((list) => {
          if (id === list.processId) {
            return {
              ...list,
              details: result?.details,
            };
          } else {
            return list;
          }
        });
      });
      setProcessInputInfo(updateList);
      props.selectedEstimateData.esIq3Info[iq3RecordIndex].processInput = updateList;

      setBlankInfo(result);
      let updateMenualDeburringInfo = { ...manualDeburringInfo };
      updateMenualDeburringInfo.details[1].length = result?.details?.[0]?.dimensionData?.innerDia; // IQX_WEBEST-80
      updateMenualDeburringInfo.details[0].length = result?.details?.[0]?.dimensionData?.outerDia;
      setManualDeburringInfo(updateMenualDeburringInfo);

      // 2次加工追加により要素数に加算する（IQX_WEBEST-199）
      let secondaryWork = {};
      if (displaySecondaryWork) {
        secondaryWork = secondaryWorkInfo;
      }
      // 曲げ線追加により要素数に加算する（IQX_WEBEST-199）
      let bending = {};
      if (displayBending) {
        bending = bendingInfo;
      }
      if (displayBlank) {
        calculateProgramCount(result, sizeX, sizeY, programSizeLimit, secondaryWork, bending);
      } else {
        calculateProgramCount({}, sizeX, sizeY, programSizeLimit, secondaryWork, bending);
      }
    } else if (workType === WorkType.SmSecondaryWork) {
      setSecondaryWorkInfo(result);

      // プランク種類数により要素数に加算する（IQX_WEBEST-199）
      let blank = {};
      if (displayBlank) {
        blank = blankInfo;
      }
      // 曲げ線追加により要素数に加算する（IQX_WEBEST-199）
      let bending = {};
      if (displayBending) {
        bending = bendingInfo;
      }
      calculateProgramCount(blank, sizeX, sizeY, programSizeLimit, result, bending);
    } else if (workType === WorkType.SmBending) {
      setBendingInfo(result);

      // プランク種類数により要素数に加算する（IQX_WEBEST-199）
      let blank = {};
      if (displayBlank) {
        blank = blankInfo;
      }
      // 2次加工追加により要素数に加算する（IQX_WEBEST-199）
      let secondaryWork = {};
      if (displaySecondaryWork) {
        secondaryWork = secondaryWorkInfo;
      }
      calculateProgramCount(blank, sizeX, sizeY, programSizeLimit, secondaryWork, result);
    }
  };

  const changeSize = (e, size) => {
    const inputValue = e.target.value;

    if (!regexExp.test(inputValue)) {
      let val = toDecimal(inputValue, 2) || 0;

      if (size === 'x') {
        setSizeX(inputValue);
        props.selectedEstimateData.esIq3Info[iq3RecordIndex].partSizeX = inputValue;
        let recSize = calculateWeightArea(thickness, val, sizeY, gravity, iq3RecordIndex);

        const updatedJsonObject = { ...surfaceInfo };
        updatedJsonObject?.details?.forEach((detail) => {
          detail.areaUnitPrice = toDecimal(recSize?.[1], 2);
          detail.weightUnitPrice = toDecimal(recSize?.[0], 2);
          detail.totalArea = detail.areaUnitPrice;
          detail.totalWeight = detail.weightUnitPrice;
        });
        setSurfaceInfo(updatedJsonObject);

        const updateDeburringInfo = { ...autoDeburringInfo };
        updateDeburringInfo?.details?.forEach((detail) => {
          detail.area = toDecimal(recSize?.[1], 2);
          detail.areaAutoExtract = toDecimal(recSize?.[1], 2);
          detail.weight = toDecimal(recSize?.[0], 2);
          detail.weightAutoExtract = toDecimal(recSize?.[0], 2);
        });
        setAutoDeburringInfo(updateDeburringInfo);

        // サイズにより、ブランクの外径周長(mm)と自動抽出(外径周長)
        let outerDia = (parseFloat(val) + parseFloat(sizeY)) * 2;
        const updatedBlankInfo = { ...blankInfo };
        updatedBlankInfo?.details?.forEach((detail) => {
          detail.dimensionData.outerDia = outerDia?.toFixed(2);
          detail.dimensionData.outerAutoExtract = outerDia?.toFixed(2);
        });
        setBlankInfo(updatedBlankInfo);

        let updateList = [...processInputInfo];

        blankProcessesId.forEach((id) => {
          updateList = updateList.map((list) => {
            if (id === list.processId) {
              return {
                ...list,
                details: updatedBlankInfo?.details,
              };
            } else {
              return list;
            }
          });
        });
        setProcessInputInfo(updateList);
        props.selectedEstimateData.esIq3Info[iq3RecordIndex].processInput = updateList;

        const updateMenualDeburringInfo = { ...manualDeburringInfo };
        updateMenualDeburringInfo.details[0].length = updatedBlankInfo?.details?.[0]?.dimensionData?.outerAutoExtract;
        updateMenualDeburringInfo.details[1].length = updatedBlankInfo?.details?.[0]?.dimensionData?.innerAutoExtract;
        setManualDeburringInfo(updateMenualDeburringInfo);

        // 2次加工追加により要素数に加算する（IQX_WEBEST-199）
        let secondaryWork = {};
        if (displaySecondaryWork) {
          secondaryWork = { ...secondaryWorkInfo };
        }
        // 曲げ線追加により要素数に加算する（IQX_WEBEST-199）
        let bending = {};
        if (displayBending) {
          bending = { ...bendingInfo };
        }

        // プランク表示
        if (displayBlank) {
          calculateProgramCount(updatedBlankInfo, val, sizeY, programSizeLimit, secondaryWork, bending);
        } else {
          calculateProgramCount({}, val, sizeY, programSizeLimit, secondaryWork, bending);
        }
      } else {
        setSizeY(inputValue);
        props.selectedEstimateData.esIq3Info[iq3RecordIndex].partSizeY = inputValue;
        let recSize = calculateWeightArea(thickness, sizeX, val, gravity, iq3RecordIndex);

        const updatedJsonObject = { ...surfaceInfo };
        updatedJsonObject?.details?.forEach((detail) => {
          detail.areaUnitPrice = toDecimal(recSize?.[1], 2);
          detail.weightUnitPrice = toDecimal(recSize?.[0], 2);
          detail.totalArea = detail.areaUnitPrice;
          detail.totalWeight = detail.weightUnitPrice;
        });
        setSurfaceInfo(updatedJsonObject);
        // サイズにより、ブランクの外径周長(mm)と自動抽出(外径周長)
        let outerDia = (parseFloat(val) + parseFloat(sizeX)) * 2;
        const updatedBlankInfo = { ...blankInfo };
        updatedBlankInfo?.details?.forEach((detail) => {
          detail.dimensionData.outerDia = outerDia?.toFixed(2);
          detail.dimensionData.outerAutoExtract = outerDia?.toFixed(2);
        });
        setBlankInfo(updatedBlankInfo);

        let updateList = [...processInputInfo];

        blankProcessesId.forEach((id) => {
          updateList = updateList.map((list) => {
            if (id === list.processId) {
              return {
                ...list,
                details: updatedBlankInfo?.details,
              };
            } else {
              return list;
            }
          });
        });
        setProcessInputInfo(updateList);
        props.selectedEstimateData.esIq3Info[iq3RecordIndex].processInput = updateList;

        const updateDeburringInfo = { ...autoDeburringInfo };
        updateDeburringInfo?.details?.forEach((detail) => {
          detail.area = toDecimal(recSize?.[1], 2);
          detail.areaAutoExtract = toDecimal(recSize?.[1], 2);
          detail.weight = toDecimal(recSize?.[0], 2);
          detail.weightAutoExtract = toDecimal(recSize?.[0], 2);
        });
        setAutoDeburringInfo(updateDeburringInfo);

        const updateMenualDeburringInfo = { ...manualDeburringInfo };
        updateMenualDeburringInfo.details[0].length = updatedBlankInfo?.details?.[0]?.dimensionData?.outerAutoExtract;
        updateMenualDeburringInfo.details[1].length = updatedBlankInfo?.details?.[0]?.dimensionData?.innerAutoExtract;
        setManualDeburringInfo(updateMenualDeburringInfo);

        // 2次加工追加により要素数に加算する（IQX_WEBEST-199）
        let secondaryWork = {};
        if (displaySecondaryWork) {
          secondaryWork = { ...secondaryWorkInfo };
        }
        // 曲げ線追加により要素数に加算する（IQX_WEBEST-199）
        let bending = {};
        if (displayBending) {
          bending = { ...bendingInfo };
        }

        if (displayBlank) {
          calculateProgramCount(updatedBlankInfo, sizeX, val, programSizeLimit, secondaryWork, bending);
        } else {
          calculateProgramCount({}, sizeX, val, programSizeLimit, secondaryWork, bending);
        }
      }
    }
  };
  const changeSizeBlur = (e, size) => {
    let val = toDecimal(e.target.value, 2);
    if (size === 'x') {
      setSizeX(val);
    } else {
      setSizeY(val);
    }
  };
  useImperativeHandle(
    ref,
    () => ({
      getKouteiInputInfo: () => {
        return processInputInfo;
      },
      // getKouteiInputInfo: updateProcessData,
      getRectangleXSize: () => {
        return sizeX;
      },
      getRectangleYSize: () => {
        return sizeY;
      },
      getWeightValue: () => {
        return weight;
      },
      getAreaValue: () => {
        return area;
      },
    }),
    [processInputInfo, sizeX, sizeY]
  );
  const changeDetails = (event, name) => {
    const temp = JSON.parse(JSON.stringify(rectangleTbl));
    if (name === 'materialName' || name === 'quality' || name === 'thick') {
      const updatedData = temp?.map((row) => {
        return { ...row, [name]: event };
      });
      setRectangleTbl(updatedData);
    } else {
      const updatedData = temp?.map((row) => {
        return { ...row, [name]: event.target.value };
      });
      setRectangleTbl(updatedData);
    }
  };

  return (
    <>
      <Row className="registerModal sticky-style" style={{ paddingTop: '5px' }}>
        <Table className="kouteiinput" style={{ marginLeft: '5px', width: '99%', marginRight: '22px' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr>
              <td colSpan={3} className="tbl-header">
                材料
              </td>
              <td colSpan={4} className="tbl-header">
                矩形サイズ
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-light" style={{ textAlign: 'center' }}>
              <td style={{ width: '20%' }}>材料名称</td>
              <td style={{ width: '12%' }}>材質</td>
              <td style={{ width: '7%' }}>板厚</td>
              <td style={{ width: '19%' }}>Xサイズ</td>
              <td style={{ width: '19%' }}>Yサイズ</td>
              <td style={{ width: '11%' }}>重量(kg)</td>
              <td style={{ width: '15%' }}>
                表面積(cm<sup>2</sup>)
              </td>
            </tr>
            {/* {rectangleTbl?.map((i, index) => (
              <> */}
            <tr>
              <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                {materialId}
                {/* <Select
                      value={i.materialName}
                      onChange={(e) => changeDetails(e, 'materialName')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                    >
                    
                      {types?.map((option, index) => (
                        <Select.Option key={index} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select> */}
                {/* {materialId} */}
              </td>

              <td style={{ verticalAlign: 'middle' }}>
                {materialType}
                {/* <Select
                  value={i.quality}
                  onChange={(e) => changeDetails(e, 'quality')}
                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                >
                  <Select.Option value={i.quality}>{i.quality}</Select.Option>
                </Select> */}
              </td>

              <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                {thickness}
                {/* <Select value={i.thick} onChange={(e) => changeDetails(e, 'thick')} style={{ width: '100%' }}>
                  {itaatsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select> */}
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{autoExtractXSize}</label>
                <Input
                  className="input-editable"
                  value={sizeX}
                  onChange={(e) => changeSize(e, 'x')}
                  onBlur={(e) => changeSizeBlur(e, 'x')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  style={{ textAlign: 'center', width: '58%', marginLeft: 9 }}
                ></Input>
              </td>
              <td>
                {/** IQX_WEBEST-179 */}
                <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{autoExtractYSize}</label>
                <Input
                  className="input-editable"
                  value={sizeY}
                  onChange={(e) => changeSize(e, 'y')}
                  onBlur={(e) => changeSizeBlur(e, 'y')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  // onKeyPress={(e) => handleKeyPressTest(e)}
                  style={{ textAlign: 'center', width: '58%', marginLeft: 9 }}
                ></Input>
              </td>
              <td>
                <Input
                  className="input-non-editable "
                  style={{ textAlign: 'center' }}
                  // value={toDecimal(weight, 2)}
                  value={toDecimal(weight, 2)}
                ></Input>
              </td>
              <td>
                <Input
                  className="input-non-editable "
                  style={{ textAlign: 'center' }}
                  value={toDecimal(area, 2)}
                ></Input>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <div className="kouteical timecharge" style={{ zIndex: 2 }}>
        {/* プログラム */}
        {displayProgram ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={program}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isProgram ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>プログラム</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isProgram ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Program
                programVal={programValues}
                blankInfo={blankInfo}
                sizeX={sizeX}
                sizeY={sizeY}
                updateProgram={updateProcessData}
                secondaryWorkInfo={secondaryWorkInfo}
                bendingInfo={bendingInfo}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* ブランク */}
        {displayBlank ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={plank}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isPlank ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>ブランク</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isPlank ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Blank blankInfo={blankInfo} updateBlank={updateProcessData} />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 2次加工 */}
        {displaySecondaryWork ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={secondProcess}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isSecondProcess ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>2次加工</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isSecondProcess ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_2Jikakou
                secondaryWorkInfo={secondaryWorkInfo}
                updateSecondaryWork={updateProcessData}
                processMaster={props.processMasterData}
              ></IQ3KouteiInput_2Jikakou>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* ベンディング */}
        {displayBending ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bending}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isBending ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>ベンディング</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isBending ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Bending
                bendingInfo={bendingInfo}
                updateBending={updateProcessData}
                processMaster={props.processMasterData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* バリ取り */}
        {displayDeburring ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={manual}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isManual ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>バリ取</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isManual ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Baritori
                blankInfo={blankInfo}
                manualDeburringInfo={manualDeburringInfo}
                autoDeburringInfo={autoDeburringInfo}
                updateDeburring={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 自動バリ */}
        {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={auto}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isAuto ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>自動バリ</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isAuto ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Auto editMode={props.editMode} autoData={autoTbl} />
        </div> */}

        {/* 溶接 */}
        {displayWelding ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isWelding ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>溶接</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Yousetsu
                weldingInfo={weldingInfo}
                updateWelding={updateProcessData}
                processMaster={props.processMasterData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 表面処理 */}
        {displaySurface ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isHyoumenShori ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>表面処理</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Hyoumenshori
                type={'iq3'}
                kouteiInputInfo={hyoumenshoriTbl}
                surfaceInfo={surfaceInfo}
                surfaceData={surfaceData}
                updateSurface={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* シャーリング */}
        {displayShearing ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={shirring}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isShirring ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>シャーリング</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isShirring ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Shearing
                numOfCuts={numOfCuts}
                shearingInfo={shearingInfo}
                updateShearing={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 検査 */}
        {displayInspection ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kensa}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isKensa ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>検査</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isKensa ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Kensa
                numOfKensa={numOfKensa}
                inspectionInfo={inspectionInfo}
                updateInspection={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

export default IQ3KouteiInput;
