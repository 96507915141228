/**
 * クラス名：材料サイズ一覧画面
 * 説明：iQ3板金に使用の材料サイズ一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Image, Radio, Space } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import SplitPane from 'split-pane-react';
import { StatusCodes } from 'http-status-codes';
import lodash from 'lodash';

import '../../../../assets/styles/common.css';
import MaterialSizeDetail from './MaterialSizeDetail';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  confirmUpdateContent,
  commonActionFooter,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  dataStateList,
  actionArr,
  getMaterialSizeStorage,
  formatDate,
  updateAccessToken,
  getAccessToken,
  formValidatorMode,
  getMaterialTypeList,
  getMaterialTypeIQ3List,
  defaultValue,
  regexExp,
  toHalfWidth,
  formatDateString,
  todayTime,
  isDecimal,
} from '../../../common/Common.js';
import { createMaterialSizeIq3CSV, getMaterialSizeIQ3Info } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import { pageSizes } from '../../../common/Constant.js';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialSizeList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const csvExportFileName = `材料サイズ_${formatDateString(todayTime())}.csv`;

  const detailRef = useRef();
  const messageRef = useRef([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材質サイズ');
  const [paramType, setParamType] = useState('iq3');
  const [materialSizeData, setMaterialSizeData] = useState([]);
  const [tempMaterialSize, setTempMaterialSize] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [materialTypeIq3List, setMaterialTypeIq3List] = useState([]);
  const [mstMaterialTypeIq3List, setMstMaterialTypeIq3List] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [checkedRowId, setCheckedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();

  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [selectedAutoPurchase, setSelectedAutoPurchase] = useState([]);
  const [selectedAutoPrice, setSelectedAutoPrice] = useState([]);

  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  // Content Resize
  const [sizes, setSizes] = useState(['60%']);
  const [tmpFilteredData, setTmpFilteredData] = useState();
  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // pagination
  const [pageSize, setPageSize] = useState(100);

  const inputItemRef = {};

  // 材質区分、材質名称を変更した場合、
  const onChangeMultipleSelect = (value, list, currentList, isMaterialType = true) => {
    let inputValue = value;
    let index = inputValue?.findIndex((item) => item === 0); // ALL
    let inputList = index > -1 ? inputValue?.filter((item) => item !== 0) : inputValue;
    inputList = inputList?.sort((a, b) => a - b);
    let idList = list?.map((item) => item.value);
    let oldIndex = undefined;
    if (isMaterialType) {
      oldIndex = currentList?.materialTypeId?.findIndex((item) => item === 0);
    } else {
      oldIndex = currentList?.materialTypeIq3Id?.findIndex((item) => item === 0);
    }
    if (oldIndex > -1 && index === -1) {
      inputList = [];
    } else if ((oldIndex === -1 && index > -1) || lodash.isEqual(inputList, idList)) {
      inputList = [0, ...idList];
    }
    return inputList;
  };

  // 材質区分を変更した場合、
  const handleMaterialTypeChange = (value) => {
    let inputList = onChangeMultipleSelect(value, materialTypeList, tempMaterialSize);
    let materialTypeIq3List = mstMaterialTypeIq3List?.filter(
      (iq3Item) => inputList?.findIndex((id) => id === iq3Item.materialTypeId) > -1
    );
    setMaterialTypeIq3List(materialTypeIq3List);
    setTempMaterialSize((prevData) => ({
      ...prevData,
      materialTypeId: inputList,
      materialTypeIq3Id: [],
    }));
  };

  // 材質名称を変更した場合、
  const handleMaterialTypeIq3Change = (value) => {
    let inputList = onChangeMultipleSelect(value, materialTypeIq3List, tempMaterialSize, false);
    setTempMaterialSize((prevData) => ({
      ...prevData,
      materialTypeIq3Id: inputList,
    }));
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: 150,
      sorter: (a, b) => a.name?.toUpperCase()?.localeCompare(b.name?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'sizeX',
      title: 'サイズX',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      render: (info) => info.sizeX,
      sorter: (a, b) => a.info.sizeX - b.info.sizeX,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'sizeY',
      title: 'サイズY',
      dataIndex: 'info',
      width: 100,
      className: 'cm-a-right',
      render: (info) => info.sizeY,
      sorter: (a, b) => a.info.sizeY - b.info.sizeY,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'materialTypeId',
      title: '材質区分',
      dataIndex: 'materialTypeId',
      width: 100,
      className: 'cm-a-center',
      render: (value) =>
        value?.map((id, index) => (index > 0 ? ',' : '') + (id === 0 ? 'ALL' : getMaterialTypeName(id))),
      sorter: (a, b) =>
        getMaterialTypeName(a.materialTypeId)
          ?.toUpperCase()
          ?.localeCompare(getMaterialTypeName(b.materialTypeId)?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'materialTypeIq3Id',
      title: '材質名称',
      dataIndex: 'materialTypeIq3Id',
      width: 100,
      className: 'cm-a-right',
      render: (value) =>
        value?.map((id, index) => (index > 0 ? ',' : '') + (id === 0 ? 'ALL' : getMaterialTypeIq3Name(id))),
      sorter: (a, b) =>
        getMaterialTypeIq3Name(a.materialTypeIq3Id)
          ?.toUpperCase()
          ?.localeCompare(getMaterialTypeIq3Name(b.materialTypeIq3Id)?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
      sorter: (a, b) => a.created?.toUpperCase()?.localeCompare(b.created?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
      sorter: (a, b) => a.modified?.toUpperCase()?.localeCompare(b.modified?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => a.modifier?.toUpperCase()?.localeCompare(b.modifier?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      width: 200,
      render: (info) => info.remarks,
      sorter: (a, b) => a.info.remarks?.toUpperCase()?.localeCompare(b.info.remarks?.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'isUsed',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  useEffect(() => {
    getMaterialSizeIQ3Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 板金子部品材料サイズマスタ情報を取得する
  const getMaterialSizeIQ3Data = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    const materialSizeData = await getMaterialSizeIQ3Info();
    setMaterialSizeData(materialSizeData);
    setTempMaterialSize(materialSizeData);
    setFilteredData(materialSizeData);
    if (materialSizeData?.length > 0) {
      setSelectedRowId(materialSizeData[0]?.id);
      setSelectedData(materialSizeData[0]);
    }
    setIsFilter(true);
    props.setParam(paramType, paramSelected);
    let materialTypeList = await getMaterialTypeList();
    setMaterialTypeList(materialTypeList);
    let mstMaterialTypeIq3List = await getMaterialTypeIQ3List();
    setMstMaterialTypeIq3List(mstMaterialTypeIq3List);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 材質区分名をIDで取得する
  const getMaterialTypeName = (materialTypeId) => {
    return materialTypeList?.filter((item) => item.value === materialTypeId)?.[0]?.label;
  };

  // 材質名称をIDで取得する
  const getMaterialTypeIq3Name = (materialTypeIq3Id) => {
    return mstMaterialTypeIq3List?.filter((item) => item.value === materialTypeIq3Id)?.[0]?.label;
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const onSelectedRowIdChange = (checked, record, index) => {
    let selectedRowId = [...checked];
    setCheckedRowId(selectedRowId);
    setChkRowsCount(selectedRowId?.length);
  };

  const rowSelection = {
    //selectedRowId,
    onChange: onSelectedRowIdChange,
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '35vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
          />
        </Col>
        <Col span="11">
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable id="param_detail_tbl" className="propertiesTbl">
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">サイズX</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={'input-editable'}
                      type="text"
                      data-name="sizeX"
                      value={tempMaterialSize?.info?.sizeX}
                      onChange={(e) => {
                        {
                          setTempMaterialSize((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, sizeX: e.target.value },
                          }));
                        }
                      }}
                      ref={(ref) => {
                        inputItemRef['sizeX'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">サイズY</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      className={'input-editable'}
                      type="text"
                      data-name="sizeY"
                      value={tempMaterialSize?.info?.sizeY}
                      onChange={(e) => {
                        {
                          setTempMaterialSize((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, sizeY: e.target.value },
                          }));
                        }
                      }}
                      ref={(ref) => {
                        inputItemRef['sizeY'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      type="text"
                      data-name="remarks"
                      className={'input-editable'}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      value={tempMaterialSize?.info?.remarks}
                      onChange={(e) => {
                        {
                          setTempMaterialSize((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, remarks: e.target.value },
                          }));
                        }
                      }}
                      maxLength={2048}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="isUsed"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        {
                          setTempMaterialSize((prevData) => ({
                            ...prevData,
                            info: { ...prevData.info, isUsed: e.target.value },
                          }));
                        }
                      }}
                      value={tempMaterialSize?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button className="cancelButton" onClick={groupEditCancel}>
          破棄
        </Button>
      </Row>
    </Form>
  );

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    if (name === 'sizeX') {
      setTempMaterialSize((prevData) => ({
        ...prevData,
        info: { ...prevData.info, sizeX: value },
      }));
    } else {
      setTempMaterialSize((prevData) => ({
        ...prevData,
        info: { ...prevData.info, sizeY: value },
      }));
    }
  };

  const addModalContent = (
    <div id="addNewMaterialName" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label> {tempMaterialSize.no} </label>
            </td>
          </tr> */}

          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialSize?.name}
                onChange={(e) => {
                  {
                    setTempMaterialSize({ ...tempMaterialSize, name: e.target.value });
                  }
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">サイズX</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                name="sizeX"
                data-name="sizeX"
                className={'input-editable'}
                value={tempMaterialSize?.info?.sizeX}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['sizeX'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">サイズY</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                name="sizeY"
                data-name="sizeY"
                className={'input-editable'}
                value={tempMaterialSize?.info?.sizeY}
                onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                ref={(ref) => {
                  inputItemRef['sizeY'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質区分</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                mode="multiple"
                size="large"
                placeholder={defaultValue.materialType}
                className={'mulitple-select'}
                style={{ width: '98%' }}
                value={tempMaterialSize?.materialTypeId}
                onChange={handleMaterialTypeChange}
                name="materialType"
              >
                <Select.Option key={0} id="materialTypeId" value={0}>
                  {'ALL'}
                </Select.Option>
                {materialTypeList?.map((item, index) => (
                  <Select.Option key={index + 1} id={'materialTypeId' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                mode="multiple"
                size="large"
                placeholder={defaultValue.materialTypeIq3}
                className={'mulitple-select'}
                style={{ width: '98%' }}
                value={tempMaterialSize?.materialTypeIq3Id}
                onChange={handleMaterialTypeIq3Change}
                name="materialTypeIq3"
              >
                {materialTypeIq3List?.length > 0 ? (
                  <Select.Option key={0} id="materialTypeIq3Id" value={0}>
                    {'ALL'}
                  </Select.Option>
                ) : (
                  <></>
                )}
                {materialTypeIq3List?.map((item, index) => (
                  <Select.Option key={index + 1} id={'materialTypeIq3Id' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">作成日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">{tempMaterialSize.created}</label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">更新日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">{tempMaterialSize.modified}</label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                className={'input-editable'}
                type="text"
                data-name="remarks"
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                value={tempMaterialSize?.info?.remarks}
                maxLength={2048}
                onChange={(e) => {
                  {
                    setTempMaterialSize((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, remarks: e.target.value },
                    }));
                  }
                }}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialSize((prevData) => ({
                      ...prevData,
                      info: { ...prevData.info, isUsed: e.target.value },
                    }));
                  }
                }}
                value={tempMaterialSize?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordId(record?.id);
    setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq3.materialSurface');
      }
    } else {
      setSelectedRowId(record?.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialSize');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setUpdateCancelConfirm(false);
  };

  const handleCancel = (e) => {
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
  };

  // const gpEditHistory = (obj) => {
  //   let numKey = ['sizeX', 'sizeY'];
  //   for (let key in numKey) {
  //     tempMaterialSize[key] = parseFloat(tempMaterialSize[key]);
  //   }
  //   if (
  //     obj.info.isUsed !== tempMaterialSize.info.isUsed ||
  //     (tempMaterialSize.info.sizeX !== 0 && obj.info.sizeX !== tempMaterialSize.info.sizeX) ||
  //     (tempMaterialSize.info.sizeY !== 0 && obj.info.sizeY !== tempMaterialSize.info.sizeY) ||
  //     (tempMaterialSize.info.remarks !== '' && obj.info.remarks !== tempMaterialSize.info.remarks)
  //   ) {
  //     obj.modified = dayjs(dayjs()).format('YYYY-MM-DD');
  //   }
  // };

  /* const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey]);
      return newObj[objKey];
    }
  }; */

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else if (newObj.info[objKey] === '') {
      return oldObj.info[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey] || newObj.info[objKey] === oldObj.info[objKey]);
      return Object.keys(newObj?.info)?.filter((v) => v === objKey)?.length > 0 ? newObj.info[objKey] : newObj[objKey];
    }
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialSizeData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let createdData = await createMaterialSizeIq3Data(tempMaterialSize);
      stateData.push(createdData);
      setTmpFilteredData(stateData);
      setMaterialSizeData(stateData);
      resetAddModal(stateData?.length + 1);
      //onRowSelect(tempMaterialSize);
      setSelectedRowId(createdData?.id);
      setSelectedData(createdData);
      setItemNo(stateData?.length);
      setCurrentPage(Math.ceil(stateData?.length / 25));
      setVisibleAddNewModal(false);
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (visibleGroupEditModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      if (checkedRowId?.length > 0) {
        const updatedData = stateData?.map((obj) => {
          if (checkedRowId?.includes(obj.id)) {
            // gpEditHistory(obj);
            return {
              ...obj,
              info: {
                ...obj.info,
                isUsed: gpEditCheck(tempMaterialSize, obj, 'isUsed'),
                sizeX: gpEditCheck(tempMaterialSize, obj, 'sizeX'),
                sizeY: gpEditCheck(tempMaterialSize, obj, 'sizeY'),
                remarks: gpEditCheck(tempMaterialSize, obj, 'remarks'),
              },
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        for (var i = 0; i <= updatedData?.length - 1; i++) {
          updatedData[i] = await updateMaterialSizeIq3Data(updatedData[i]);
        }
        setTmpFilteredData(updatedData);
        setMaterialSizeData(updatedData);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
      setVisibleGroupEditModal(false);
    }
    setIsFilter(true);
  };

  // 板金子部品材料サイズマスタ情報をDBに新規登録する
  async function createMaterialSizeIq3Data(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          name: createData.name,
          materialTypeId: createData.materialTypeId,
          materialTypeIq3Id: createData.materialTypeIq3Id,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
      setChkRowsCount(0);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleGpEdit = () => {
    setTempMaterialSize({
      info: {
        isUsed: '',
        sizeX: '',
        sizeY: '',
        remarks: '',
      },
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        handleAddFormSubmit();
      }
    } else {
      handleAddFormSubmit();
    }
  };

  // 新規作成ボタンの押下
  const handleAddFormSubmit = () => {
    let materialTypeId = materialTypeList?.map((item) => item.value);
    let materialTypeIq3List = mstMaterialTypeIq3List?.filter(
      (iq3Item) => materialTypeId?.findIndex((id) => id === iq3Item.value) > -1
    );
    setMaterialTypeIq3List(materialTypeIq3List);
    let materialTypeIq3Id = materialTypeIq3List?.map((item) => item.value);
    setTempMaterialSize({
      name: '',
      materialTypeId: [0, ...materialTypeId],
      materialTypeIq3Id: [0, ...materialTypeIq3Id],
      info: { isUsed: true, sizeX: 0, sizeY: 0, remarks: '' },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setVisibleAddNewModal(true);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    //setUpdateCancelConfirm(true);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    props?.loading(true); // Waitingダイアログを表示にする
    let deletedData = await deleteMaterialSizeIq3Data(selectedData?.id);

    let data = [...filteredData];
    data = data?.filter((item) => item.id != deletedData?.id);

    setTmpFilteredData(data);
    setMaterialSizeData(data);
    setFilteredData(data);
    setIsFilter(true);
    setSelectedRowId(data?.length > 0 ? data[0]?.id : -1);
    setSelectedData(data?.length > 0 ? data[0] : []);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 板金子部品材料サイズマスタ情報をDBに削除する
  async function deleteMaterialSizeIq3Data(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const deleteSelectedRow = (index, e, id) => {
    if (id === 'btnAutoPurchase') {
      setSelectedAutoPurchase(selectedAutoPurchase?.filter((v, i) => i !== index));
    } else if (id === 'btnAutoPrice') {
      setSelectedAutoPrice(selectedAutoPrice?.filter((v, i) => i !== index));
    }
  };

  const resetAddModal = (count) => {
    setTempMaterialSize({
      info: {
        isUsed: true,
        sizeX: 0,
        sizeY: 0,
        remarks: '',
      },
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, modelKeyword, dataState, showFlag, tmpFilteredData, materialSizeData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else if (discardOKAction.key === actionArr[6]?.key) {
        // CSV入力
        onClickCSVImport();
      } else if (discardOKAction.key === actionArr[7]?.key) {
        // CSV出力
        onClickCSVExport();
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
        case 'number':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
      case 'model':
        modelKeyword === e.target.value ? getDataByFilter() : setModelKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialSizeData?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let currentData = [...materialSizeData];
    let flag;
    let materialFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.materialName?.toString().indexOf(nKeyword) >= 0);
    }
    // 型番のキーワードで絞り込み
    if (mKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.gravity?.toString().indexOf(mKeyword) >= 0);
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData?.filter((item) => item.info.isUsed === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData?.filter((item) => item.materialSize === dataState);
    }

    /* let index = materialFilterData?.findIndex((item) => item.no === selectedRowId);

    if (index === -1) {
      if (editMode) {
     
        setEditModeCancelConfirm(true);
        materialFilterData?.length > 0 && setChangeRecordId(materialFilterData[materialFilterData?.length - 1].no);
        return;
      }
      // 検索結果がある場合、
      if (materialFilterData?.length > 0) {
        index = materialFilterData?.length - 1;
        setSelectedRowId(materialFilterData[index]?.no);
        setSelectedData(materialFilterData[index]);
      }
    }
    if (materialFilterData?.length > 0 && isFilter) {
     
      setSelectedRowId(materialFilterData[index].no);
      setSelectedData(materialFilterData[index]);
    } else if (materialFilterData?.length <= 0) {
      setSelectedData([]);
    } */
    setFilteredData(materialFilterData);
    setCurrentPage(1);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...materialSizeData];
    var updatedInfo = [];
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateMaterialSizeIq3Data(updateData);

    var index = tmpData?.findIndex((item) => item.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    setMaterialSizeData(tmpData);
    setTmpFilteredData(tmpData);
    setIsFilter(false);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 板金子部品材料サイズマスタ情報をDBに更新する
  async function updateMaterialSizeIq3Data(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          name: updateData.name,
          materialTypeId: updateData.materialTypeId,
          materialTypeIq3Id: updateData.materialTypeIq3Id,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      let mTypeStr = '';
      let mTypeIq3Str = '';
      data.materialTypeId?.map((id) => {
        if (mTypeStr.length > 0) mTypeStr += ' ';
        mTypeStr += id === 0 ? 'ALL' : getMaterialTypeName(id);
      });
      data.materialTypeIq3Id?.map((id) => {
        if (mTypeIq3Str.length > 0) mTypeIq3Str += ' ';
        mTypeIq3Str += id === 0 ? 'ALL' : getMaterialTypeIq3Name(id);
      });
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        data.name +
        ',' +
        data.info.sizeX +
        ',' +
        data.info.sizeY +
        ',' +
        mTypeStr +
        ',' +
        mTypeIq3Str +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.modifier +
        ',' +
        data.info.remarks +
        ',' +
        data.info.isUsed;
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      let mTypeId = materialTypeList?.map((item) => item.value);
      let materialTypeIq3List = mstMaterialTypeIq3List?.filter(
        (iq3Item) => mTypeId?.findIndex((id) => id === iq3Item.materialTypeId) > -1
      );
      let mTypeIq3Id = materialTypeIq3List?.map((item) => item.value);
      let materialTypeId = [0, ...mTypeId];
      let materialTypeIq3Id = [0, ...mTypeIq3Id];
      for (let i = 1; i < dataList.length; i++) {
        let tempData = {
          name: '',
          materialTypeId: [],
          materialTypeIq3Id: [],
          info: { isUsed: true, sizeX: 0, sizeY: 0, remarks: '' },
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        tempData.id = data?.[0];
        tempData.name = data?.[2];
        tempData.info.sizeX = parseInt(data?.[3]);
        tempData.info.sizeY = parseInt(data?.[4]);

        let mTypeInput = data?.[5]?.split(' ');
        let mTypeIq3Input = data?.[6]?.split(' ');
        if (
          mTypeInput?.findIndex((item) => item === 'ALL') > -1 &&
          mTypeIq3Input?.findIndex((item) => item === 'ALL') > -1
        ) {
          tempData.materialTypeId = materialTypeId;
          tempData.materialTypeIq3Id = materialTypeIq3Id;
        } else if (mTypeInput?.findIndex((item) => item === 'ALL') === -1) {
          let mTypeIds = [];
          let mTypeIq3Ids = [];
          mTypeInput?.map((mType) => {
            mTypeIds.push(materialTypeList?.find((item) => item.label === mType)?.value);
          });
          let materialTypeIq3List = mstMaterialTypeIq3List?.filter(
            (iq3Item) => mTypeIds?.findIndex((id) => id === iq3Item.materialTypeId) > -1
          );
          if (mTypeIq3Input?.findIndex((item) => item === 'ALL') > -1) {
            mTypeIq3Ids = [0, ...materialTypeIq3List?.map((item) => item.value)];
          } else {
            mTypeIq3Input?.map((mTypeIq3) => {
              mTypeIq3Ids.push(materialTypeIq3List?.find((item) => item.label === mTypeIq3)?.value);
            });
          }
          tempData.materialTypeId = [...mTypeIds];
          tempData.materialTypeIq3Id = [...mTypeIq3Ids];
        }

        tempData.info.remarks = data?.[10];
        tempData.info.isUsed = data?.[11] ? JSON.parse(data?.[11]) : true;
        tempDatas.push(tempData);
      }
      createdDatas = await createMaterialSizeIq3CSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setMaterialSizeData(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedRowId(createdDatas?.[0]?.id);
          setSelectedData(createdDatas?.[0]);
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // 名称
    let input = data?.[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('名称').E007}</li>);
      return false;
    }

    // サイズX
    input = data?.[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズX').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズX').E014}</li>);
      return false;
    }

    // サイズY
    input = data?.[4];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズY').E007}</li>);
      return false;
    }

    if (input?.length > 0 && !isDecimal(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('サイズY').E014}</li>);
      return false;
    }

    // 材質区分名
    input = data?.[5];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質区分').E017}</li>);
      return false;
    }
    if (input?.length > 0) {
      let ret = true;
      input = input?.split(' ');
      for (var i = 0; i < input.length; i++) {
        // 材質区分が存在しない場合
        if (input[i] !== 'ALL' && materialTypeList?.findIndex((item) => item.label === input[i]) === -1) {
          messageRef?.current?.push(<li>{no + ErrorMessage('材質区分').E022}</li>);
          ret = false;
          break;
        }
      }
      if (!ret) return false;
    }

    // 材質名称
    input = data?.[6];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E017}</li>);
      return false;
    }
    if (input?.length > 0) {
      let mTypeId = [];
      data?.[5]
        ?.split(' ')
        ?.filter((mType) => mType !== 'ALL')
        ?.map((mType) => {
          mTypeId.push(materialTypeList?.find((item) => item.label === mType)?.value);
        });
      let materialTypeIq3List = mstMaterialTypeIq3List?.filter(
        (iq3Item) => mTypeId?.findIndex((id) => id === iq3Item.materialTypeId) > -1
      );

      let ret = true;
      input = input?.split(' ');
      for (var i = 0; i < input.length; i++) {
        // 材質区分が存在しない場合
        if (input[i] !== 'ALL' && materialTypeIq3List?.findIndex((item) => item.label === input[i]) === -1) {
          messageRef?.current?.push(<li>{no + ErrorMessage('材質名称').E022}</li>);
          ret = false;
          break;
        }
      }
      if (!ret) return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            <Space size="middle" className="search-bar">
              <Select
                style={{ width: 90, float: 'right' }}
                onChange={beforeSearch}
                placeholder="表示・非表示"
                id="showFlgDrop"
                name="showFlgDrop"
                value={showFlag}
              >
                {dataStateList?.map((item, index) => (
                  <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Space>
            <>
              {/* 一覧アリア */}
              <Row>
                <Table
                  showSorterTooltip={false}
                  columns={hasCheckData ? tableColumns : chkData}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record?.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    // defaultPageSize: 25,
                    defaultCurrent: 1,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizes,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className="param_tbListShow"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                />
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialSize"
                element={
                  <MaterialSizeDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialSizeData?.find((element) => element.id === selectedRowId)}
                    materialTypeList={materialTypeList}
                    mstMaterialTypeIq3List={mstMaterialTypeIq3List}
                    itemNo={itemNo}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    getMaterialTypeName={getMaterialTypeName}
                    getMaterialTypeIq3Name={getMaterialTypeIq3Name}
                    onChangeMultipleSelect={onChangeMultipleSelect}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}

      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質サイズ（選択したデータをまとめて編集）', 1790, -24),
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          true
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm
        ? commonModal(
            updateCancelConfirm,
            confirmModalTitle,
            commonFooter(updCancelOK, updCancelCancel),
            null,
            400,
            updCancelOK,
            updCancelCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質サイズ（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialSizeList;
