/**
 * 説明：Enum値定義
 * 作成者：ケイ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

// 取引先種別
export const ClientType = Object.freeze({
  Customer: 0x10, // 顧客
  Supplier: 0x100, // 仕入れ先
  Contractor: 0x1000, // 外注先
});

// 材質区分
export const MaterialCategory = Object.freeze({
  Iron: 0, // 鉄
  Stainless: 1, // ステンレス
  Aluminum: 2, // アルミ
  Copper: 3, // 銅
  Zinc: 4, // 亜鉛
  Other: 99, // その他
});

// 材料表面加算単位
export const MaterialSurfaceBillingUnit = Object.freeze({
  Weight: 0, // 重量
  SquareMeter: 1, // 面積(平米)
  Work: 2, //　加工
});

// サービス分類
export const ServiceClass = Object.freeze({
  Parent: 0, // 親部品
  SheetMetal: 1, // 板金子部品
  Beams: 2, // 形鋼
  Machining: 3, // 機械加工
});

// 工程種別
export const WorkType = Object.freeze({
  // ＜親部品＞
  PaWelding: 100, // 溶接
  PaWeldFinish: 200, // 溶接仕上
  PaSurface: 300, // 表面処理
  PaAssenble: 400, // 組立
  PaInspection: 500, // 検査
  PaPacking: 600, // 梱包
  PaCustom: 700, // 追加工程（カスタム）
  // ＜板金子部品＞
  SmProgram: 10100, // プログラム
  SmBlankLaser: 10210, // ブランク（レーザー）
  SmBlankPunch: 10220, // ブランク（パンチ）
  SmBlankCombi: 10230, // ブランク（複合機）
  SmSecondaryWork: 10300, // 2次加工
  SmBending: 10400, // ベンディング
  SmDeburringAuto: 10510, // バリ取（自動）
  SmDeburringManual: 10520, // バリ取（手動）
  SmWelding: 10600, // 溶接
  SmWeldFinish: 10700, // 溶接仕上
  SmSurface: 10800, // 表面処理
  SmShearing: 10900, // シャーリング
  SmInspection: 11000, // 検査
  SmCustom: 11100, // 追加工程（カスタム）
});

// 工程種別グループ
export const WorkTypeGroup = Object.freeze({
  None: 0, // グループ無し
  // 親部品
  PaWelding: 110, // 溶接種類
  PaInstThick: 120, // 取付種類（板厚判断）
  PaInstSize: 130, // 取付種類（サイズ判断）
  PaWorkGeneral: 310, // 処理全般
  PaPlating: 320, // メッキ種類
  PaCoating: 330, // 塗装種類
  // 板金子部品
  SmWorkGeneral: 10510, // 処理全般
  SmPlating: 10520, // メッキ方法
  SmCoating: 10530, // 塗装種類
  SmWelding: 10710, // 溶接種類
  SmInstThick: 10720, // 取付種類（板厚判断）
  SmInstSize: 10730, // 取付種類（サイズ判断）
  SmSecondaryWorkSize: 10310, // 2次加工種類（サイズ判断）
  SmSecondaryWorkMillimeter: 10320, // 2次加工種類（ミリメートル判断）
});

// 段取係数種類
export const PrepCoeffType = Object.freeze({
  ChildQty: 1, // 子部品数
  ChildTypeQty: 2, // 子部品種類数
});

// 工程種別グループ名
export const ProcessDetailGroup = Object.freeze({
  /// <summary>
  /// 無し
  /// </summary>
  None: 0,
  /// <summary>
  /// 処理全般
  /// </summary>
  General: 1,
  /// <summary>
  /// メッキ
  /// </summary>
  Plating: 2,
  /// <summary>
  /// 片面塗装
  /// </summary>
  OneSide: 3,
  /// <summary>
  /// 両面塗装
  /// </summary>
  DoubleSide: 4,
});
// 加工種類
export const ProcessType = Object.freeze({
  InternalWork: 1, // 内加工
  ExternalWork: 2, // 外加工
});

export const initShowDisps = Object.freeze({
  0: '親部品リスト', //親部品リスト
  1: '新規作成_親部品', //新規作成_親部品
  // 2: '新規作成_板金子部品', //新規作成_板金子部品
});

export const initDispRoutes = Object.freeze({
  0: '/home/:parentList', //親部品リスト
  1: '/home/:estimate.parent', //新規作成_親部品
  // 2: '/home/:estimate.iq3', //新規作成_板金子部品
});

export const languages = Object.freeze({
  日本語: 0,
});

export const timeZones = Object.freeze({
  JP: 0,
});

export const UserRoleToString = {
  SuperUser: 'ZF管理者',
  Administrator: '管理者',
  User: '一般ユーザ',
};
// データと編集の切り分け
export const DataType = Object.freeze({
  Data: 0, // データ
  Edit: 1, // 編集
});

export const RoundUpMethods = Object.freeze({
  切り上げ: 0,
  切り捨て: 1,
  四捨五入: 2,
});

export const CurrencySymbols = Object.freeze({
  Yen: 0,
});

//　表示設定画面の項目種類
export const DisplayColumnsGroup = Object.freeze({
  Default: 0,
  AdditionalItemCost: 1,
  AdditionalItemLabel: 2,
  AdditionalItemMemo: 3,
  AdditionalItemDate: 4,
});

export const PrintTypes = Object.freeze({
  //個別帳票
  individual: 0,
  //リスト帳票
  list: 1,
});

// 絞込設定のお客様
export const CustomerFilterType = Object.freeze({
  //選択ボックス値
  Select: 1,
  //テキストボックス値
  Text: 2,
});

// 旧パラと新パラの切り分け
export const ParamCalType = Object.freeze({
  OldParam: 0, // 旧パラ
  NewParam: 1, // 新パラ
});

// 表面保護タイプ
export const SurfaceProtectionType = Object.freeze({
  OneSideVinyl: 1, // 片面ビニール
  DoubleSideVinyl: 2, // 両面ビニール
});

// パターンタイプ
export const PatternType = Object.freeze({
  Pattern1: 1, // パターン1
  Pattern2: 2, // パターン2
});

// 採用タイプ
export const EstimateCreationType = Object.freeze({
  Auto: 1, // 自動「CAD、PMX」
  Manual: 2, // 手動「工程入力、工程積算、材料詳細」
});

// PMX取り込み
export const CreatePMXData = Object.freeze({
  SaveAndNew: 1, // 編集中見積り保存して、新規見積
  New: 2, // 新規見積
  Update: 3, // 編集中見積に追加
});

// 同一名の保存を許可するタイプ
export const SaveSameType = Object.freeze({
  EstimateNo: 1, // 見積番号
  DrawingNo: 2, // 図番
  Name: 3, // 名称
});
