/**
 * クラス名：案件状態パラメータ
 * 説明：案件状態情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import 'split-pane-react/esm/themes/default.css';
import SplitPane, { Pane } from 'split-pane-react';
import { StatusCodes } from 'http-status-codes';

import ProjectStatusDetails from './ProjectStatusDetails';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  error,
  authorizeError,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  TextArea,
  dataStateList,
  today,
  formatDate,
  formValidatorMode,
  updateAccessToken,
  getAccessToken,
  regexExp,
  toHalfWidth,
  discardChange,
  formatDateString,
  todayTime,
  isDigit,
} from '../../../common/Common';
import { createEstimateStatusCSV, getEstimateStatusData } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import { pageSizes } from '../../../common/Constant';
import sort_up from '../../../../assets/images/sort_up.png';
import sort_down from '../../../../assets/images/sort_down.png';

dayjs.extend(customParseFormat);
const iconWidth = 30;
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';

const ProjectStatusParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
    getCsvExportFileName: csvExportFileName,
    getCsvExportData: getCsvExportData,
    submitCsvImportData: submitCsvImportData,
  }));

  const csvExportFileName = `案件状態_${formatDateString(todayTime())}.csv`;

  const inputItemRef = {};
  const messageRef = useRef([]);

  let loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  let token = localStorage?.getItem('iQxWeb_AccessToken');
  let companyId = loginUser?.claims?.iQxWebEstimateClaim2;

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // デフォルトページの表示件数
  const defaultPageSize = 25;

  document.body.style.overflowY = 'hidden';

  const [paramSelected, setParamSelected] = useState('project');
  const [paramType, setParamType] = useState('common');
  const [projectStatusData, setProjectStatusData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState(0);

  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [isUsed, setIsUsed] = useState();
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState(today);
  const [modifier, setModifier] = useState('');

  const [dataState, setDataState] = useState(0);
  const [codeKeyword, setCodeKeyWord] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');

  const [dropParamTemp, setDropParamTemp] = useState([]);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [leftCol, setLeftCol] = useState(10);
  const [rightCol, setRightCol] = useState(13);
  const [middlecol, setMiddleCol] = useState(1);
  const [infoShowHide, setInfoShowHide] = useState(true);
  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [tempProjectStatusData, setTempProjectStatusData] = useState([]);

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false); // 編集中破棄確認ポップアップの表示・非表示

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);

  const [tmpFilteredData, setTmpFilteredData] = useState();
  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  // 現在のページ番号
  const [currentPage, setCurrentPage] = useState(1);
  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  useEffect(() => {
    getProjectStatusData();
  }, [paramType, paramSelected]);

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [codeKeyword, nameKeyword, dataState, tmpFilteredData]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          setSelectedData(getSelectedData(projectStatusData, changeRecordId));
          setSelectedRowId(changeRecordId);
          break;
        case discardChange[1]?.no:
          // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          setSelectedData(getSelectedData(projectStatusData, selectedRowId));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
        case discardChange[4]?.no: // CSV入力
          onClickCSVImport();
          break;
        case discardChange[5]?.no: // CSV出力
          onClickCSVExport();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 案件状態情報をDBから取得する
  async function getProjectStatusData() {
    props?.loading(true); // Waitingダイアログを表示にする
    let projectStatusData = [];
    let selectedData = [];
    projectStatusData = await getEstimateStatusData();
    if (projectStatusData?.length > 0) selectedData = projectStatusData[0];
    setProjectStatusData(projectStatusData);
    setFilteredData(projectStatusData);
    setSelectedRowId(selectedData?.id);
    setSelectedData(selectedData);
    navigate(':common.project');
    setParamSelected('project');
    setParamType('common');
    setDataState(1);
    props.setParam(paramType, paramSelected);
    props?.loading(false); // Waitingダイアログを非表示にする
  }

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 55,
      render: (id, record, index) => {
        return (currentPage - 1) * defaultPageSize + (index + 1);
      },
      className: 'cm-a-right',
    },

    {
      id: '2',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
      sorter: (a, b) => a.code.toUpperCase()?.localeCompare(b.code.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'code',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 150,
      sorter: (a, b) => a.name.toUpperCase()?.localeCompare(b.name.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'name',
    },
    {
      id: '4',
      title: 'ソート番号',
      dataIndex: 'info',
      render: (info) => info.sortNum,
      width: 95,
      className: 'cm-a-right',
      sorter: (a, b) => a.info.sortNum - b.info.sortNum,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'sortNum',
    },
    {
      id: '5',
      title: '作成日',
      dataIndex: 'created',
      render: (created) => formatDate(created),
      width: 100,
      sorter: (a, b) => a.created.toUpperCase()?.localeCompare(b.created.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'created',
    },
    {
      id: '6',
      title: '更新日',
      dataIndex: 'modified',
      render: (modified) => formatDate(modified),
      width: 100,
      sorter: (a, b) => a.modified.toUpperCase()?.localeCompare(b.modified.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modified',
    },
    {
      id: '7',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
      sorter: (a, b) => a.modifier.toUpperCase()?.localeCompare(b.modifier.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'modifier',
    },
    {
      id: '8',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
      width: 200,
      sorter: (a, b) => a.remarks.toUpperCase()?.localeCompare(b.remarks.toUpperCase()),
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'remarks',
    },
    {
      id: '9',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            // key={item['key']}
            id={item.info.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
      sorter: (a, b) => a.info.isUsed - b.info.isUsed,
      sortIcon: ({ sortOrder }) => (
        <>
          <Image
            preview={false}
            src={sort_up}
            style={{ display: sortOrder === 'ascend' ? 'block' : 'none', width: '15px' }}
          />
          <Image
            preview={false}
            src={sort_down}
            style={{ display: sortOrder === 'descend' ? 'block' : 'none', width: '15px' }}
          />
        </>
      ),
      key: 'isUsed',
    },
    {
      id: '10',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // ソート番号を変更した場合、
  const onChangeSortNum = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    return value;
  };

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              ref={(ref) => {
                inputItemRef['code'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 名称 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名称</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              ref={(ref) => {
                inputItemRef['name'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* ソート番号 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">ソート番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="sortNum"
              id="sortNum"
              value={sortNum}
              type="text"
              ref={(ref) => {
                inputItemRef['sortNum'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onBlur={(e) => setSortNum(onChangeSortNum(e.target.value))}
              onPressEnter={(e) => setSortNum(onChangeSortNum(e.target.value))}
              onChange={(e) => setSortNum(onChangeSortNum(e.target.value, false))}
            />
          </td>
        </tr>
        {/* 作成日 更新日 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>

            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modified}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新者</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modifier}</label>
              </td>
            </tr>
          </>
        )}
        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              maxLength={2048}
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>
        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              id="isUsed"
              name="isUsed"
              defaultValue={1}
              value={isUsed}
              className="radioCustomerGpEdit"
              onChange={(e) => setIsUsed(e.target.value)}
            >
              <Radio value={true}>オン</Radio>
              <Radio value={false}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
      </thead>
    </RTable>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data?.find((item) => item.id === id);
    return record ? record : ret;
  };

  // 最大ソート番号を得る
  const getMaxSortNum = () => {
    let sortedList = projectStatusData.sort((a, b) => b.info.sortNum - a.info.sortNum);
    return sortedList?.length > 0 ? sortedList[0].info.sortNum + 1 : 1;
  };

  // Idで降順する
  const descOrderById = (data) => {
    let sortedList = data.sort((a, b) => b.id - a.id);
    return sortedList;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let projectStatusData = [];
    if (record !== undefined) {
      projectStatusData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });
      setChangeRecordId(record.id);
      if (editMode) {
        setDiscardChangeMode(discardChange[0]);
        let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
        setEditModeCancelConfirm(moveInEdit);
        if (!moveInEdit) {
          setSelectedRowId(record.id);
          setSelectedData(projectStatusData[0]);
          navigate(':common.project');
        }
      } else {
        setSelectedRowId(record.id);
        setSelectedData(projectStatusData[0]);
        navigate(':common.project');
      }
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.project');
    }
  };

  const onRowSelectWithoutEditMode = (record) => {
    let projectStatusData = [];
    if (record !== undefined) {
      projectStatusData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });
      setChangeRecordId(record.id);
      setSelectedRowId(record.id);
      setSelectedData(projectStatusData[0]);

      const projectStatusDataLst = [...filteredData];
      if (projectStatusDataLst?.length > 0) {
        setCurrentPage(Math.ceil(projectStatusDataLst?.length / 25));
      } else {
        setCurrentPage(1);
      }

      navigate(':common.project');
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.project');
    }
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
    // setEditModeCancelConfirm(moveInEdit);
    if (!moveInEdit) {
      setIsDelete(false);
      deleteOk();
    } else {
      setIsDelete(true);
    }
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
    if (isDelete || !moveInEdit) {
      setIsDelete(false);
      setEditMode(false);
      props?.loading(true); // Waitingダイアログを表示にする
      let selectedId = selectedRowId;
      let deletedData = await deleteProjectStatusData(selectedId);

      let filterdData = [...filteredData];
      filterdData = filterdData?.filter((item) => item.id !== deletedData.id);
      setFilteredData(filterdData);

      let currentData = [...projectStatusData];
      currentData = currentData?.filter((item) => item.id !== deletedData.id);
      setCurrentPage(1);
      onRowSelectWithoutEditMode(
        currentData !== undefined && currentData?.length > 0 ? currentData[currentData?.length - 1] : []
      );
      setProjectStatusData(currentData);
      setTmpFilteredData(currentData);
      setCurrentPage(1);
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 案件状態情報をDBに削除する
  async function deleteProjectStatusData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const deleteSelectedRow = (index, e, id) => {};

  const resetInputInfo = () => {
    setCode('');
    setName('');
    setIsUsed();
    setRemarks('');
    setSortNum('');
    setCreated('');
    setModified(today);
    setModifier('');
  };

  // 表示の押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    if (e.target.name === 'code') {
      codeKeyword === e.target.value ? getDataByFilter() : setCodeKeyWord(e.target.value);
    } else {
      nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (projectStatusData?.length <= 0) {
      return;
    }
    let cKeyword = codeKeyword;
    let nKeyword = nameKeyword;
    let flag;
    let currentData = [...projectStatusData];
    let projectStatusFilterData = currentData;

    // 名称のキーワードで絞り込み
    if (cKeyword != '') {
      projectStatusFilterData = projectStatusFilterData?.filter((item) => item.code?.toString().indexOf(cKeyword) >= 0);
    }
    // 名称のキーワードで絞り込み
    if (nKeyword != '') {
      projectStatusFilterData = projectStatusFilterData?.filter(
        (item) => item.name?.toLowerCase().indexOf(nKeyword) >= 0
      );
    }

    //  表示で絞り込み
    if (dataState === 1 || dataState === 2) {
      dataState === 1 ? (flag = true) : (flag = false);
      projectStatusFilterData = projectStatusFilterData?.filter((item) => item.info.isUsed === flag);
    }

    /* let index = projectStatusFilterData?.findIndex((item) => item.id === selectedRowId);
    // 検索した結果に、選択中行のIdが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (projectStatusFilterData?.length > 0) {
        index = 0;
        setSelectedRowId(projectStatusFilterData[index]?.id);
      }
    }
    projectStatusFilterData?.length > 0
      ? setSelectedData(projectStatusFilterData[index]) // 検索結果がある場合、
      : setSelectedData(projectStatusFilterData); // 検索結果が無い場合、 */
    // 一覧に表示するデータ
    setFilteredData(projectStatusFilterData);
    setCurrentPage(1);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isCreateNew) {
      setCreateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create)) {
        return;
      }
      props?.loading(true); // Waitingダイアログを表示にする
      let lastIndex = tempProjectStatusData?.length - 1;
      let tempData = tempProjectStatusData[lastIndex];
      let createdData = await createProjectStatusData(tempData);
      setProjectStatusData((prevData) => descOrderById([...prevData, createdData]));
      setTmpFilteredData(createdData);
      setFilteredData((filteredData) => descOrderById([...filteredData, createdData]));
      onRowSelectWithoutEditMode(createdData);
      setIsCreateNew(false);
      props?.loading(false); // Waitingダイアログを非表示にする
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      props?.loading(true); // Waitingダイアログを表示にする
      // 選択された全ての対象行を更新、
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let item = bulkEditSelectedRow[i];
        if (code) item.code = code;
        if (name) item.name = name;
        if (isUsed === true || isUsed === false) item.info.isUsed = isUsed;
        if (remarks) item.info.remarks = remarks;
        if (sortNum) item.info.sortNum = sortNum;
        item.modified = today;
        await submitData(item);
      }
      props?.loading(false); // Waitingダイアログを非表示にする
    }
    resetInputInfo();
  };

  // 案件状態情報をDBに新規登録する
  async function createProjectStatusData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var updatedInfo = [];
    var tmpData = [];
    var index;
    props?.loading(true); // Waitingダイアログを表示にする
    updatedInfo = await updateProjectStatusData(updateData);
    tmpData = [...projectStatusData];
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setProjectStatusData(tmpData);
    setTmpFilteredData(tmpData);

    tmpData = [...filteredData];
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setFilteredData(tmpData);

    // 詳細情報を再表示
    if (updatedInfo.id === selectedRowId) detailRef.current?.displayDetailInfo(updatedInfo);
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 案件状態情報をDBに更新する
  async function updateProjectStatusData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setCreated(today);
    setModified(today);
    setIsCreateNew(true);
    setIsUsed(true);
    setSortNum(getMaxSortNum());
    setChildTitle('案件状態（新規作成）');
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    setCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle && isCreateNew) {
      setIsCreateNew(false);
    }
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempProjectStatusData = [];
    let info = { isUsed: isUsed, remarks: remarks, sortNum: sortNum };
    tempProjectStatusData.push({
      code: code,
      name: name,
      info: info,
      created: today,
      modified: today,
    });
    setTempProjectStatusData(tempProjectStatusData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 430,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );

  // 新規作成-終了

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record, index) => {
    let checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow?.length);
    navigate(':common.project');
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('案件状態（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: defaultPageSize,
              defaultCurrent: 1,
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // CSVヘッダの情報
  const getCSVHeaderInfo = () => {
    let str = '';
    columns?.map((col) => (str.length === 0 ? (str += col.title) : (str += ',' + col.title))); // ヘッダの情報
    str = 'ID,' + str;
    return str;
  };

  // CSV出力情報
  const getCsvExportData = () => {
    let str = '';
    str = getCSVHeaderInfo();
    filteredData.map((data, index) => {
      // CSV内容
      str +=
        '\n' +
        data.id +
        ',' +
        (index + 1) +
        ',' +
        data.code +
        ',' +
        data.name +
        ',' +
        data.info.sortNum +
        ',' +
        formatDate(data.created) +
        ',' +
        formatDate(data.modified) +
        ',' +
        data.modifier +
        ',' +
        data.info.remarks +
        ',' +
        data.info.isUsed;
    });
    return str;
  };

  // CSV入力情報
  const submitCsvImportData = async (dataList) => {
    let createdDatas = [];
    let failedDatas = [];
    messageRef.current = [];
    let tempDatas = [];
    props?.loading(true); // Waitingダイアログを表示にする
    // ヘッダの情報
    if (JSON.stringify(dataList[0]) !== JSON.stringify(getCSVHeaderInfo())) {
      // 不正なヘッダの情報
      messageRef?.current?.push(<li>{ErrorMessage('1行目').E016}</li>);
    } else {
      for (let i = 1; i < dataList.length; i++) {
        let info = { isUsed: true, remarks: '', sortNum: '' };
        let tempData = {
          code: '',
          name: '',
          info: info,
          created: today,
          modified: today,
        };
        let data = dataList[i].split(',');
        if (data && data?.[0].length === 0) continue;
        if (!csvImportDataValidation(i + 1, data)) {
          failedDatas.push(dataList[i]);
          continue;
        }
        tempData.id = data?.[0];
        tempData.code = data?.[2];
        tempData.name = data?.[3];
        tempData.info.sortNum = data?.[4];
        tempData.info.remarks = data?.[8];
        tempData.info.isUsed = data?.[9] ? JSON.parse(data?.[9]) : true;
        tempDatas.push(tempData);
      }
      createdDatas = await createEstimateStatusCSV(tempDatas);
      if (createdDatas) {
        if (createdDatas !== undefined && createdDatas?.length > 0) {
          setProjectStatusData(createdDatas);
          setFilteredData(createdDatas?.filter((item) => item.info.isUsed === true));
          setSelectedRowId(createdDatas?.[0]?.id);
          setSelectedData(createdDatas?.[0]);
          // onRowSelectWithoutEditMode(createdDatas?.[0]);
        }
      }
    }
    Modal.success({
      width: 500,
      className: 'modal-complete csv-modal-complete',
      title: '完了',
      content: (
        <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>
          {'CSV入力のデータ保存は完了しました。正常：' + tempDatas?.length + '件、異常：' + failedDatas?.length + '件'}
          {messageRef.current?.length > 0 ? (
            <>
              <br></br>
              {'下記の内容をご確認ください。'}
              <br></br>
              <ul>{messageRef.current}</ul>
            </>
          ) : (
            <></>
          )}
        </p>
      ),
    });
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // CSV入力情報チェック
  const csvImportDataValidation = (lineNo, data) => {
    // CSV入力情報チェック
    let no = lineNo + '行目の';

    // コード
    let input = data[2];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('コード').E007}</li>);
      return false;
    }

    // 名称
    input = data[3];
    if (input?.length <= 0) {
      // 必須
      messageRef?.current?.push(<li>{no + ErrorMessage('名称').E007}</li>);
      return false;
    }

    // ソート番号
    input = data[4];
    if (input?.length > 0 && !isDigit(input)) {
      messageRef?.current?.push(<li>{no + ErrorMessage('ソート番号').E009}</li>);
      return false;
    }

    return true;
  };

  // CSV入力ボタンを押下した場合、
  const onClickCSVImport = () => {
    props?.onClickCSVImport();
  };

  // CSV出力ボタンを押下した場合、
  const onClickCSVExport = () => {
    props?.onClickCSVExport();
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Input
                    className="input-editable search-select-input"
                    placeholder="コード"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="code"
                    id="code"
                    onChange={(e) => {
                      if (e.target.value === '') {
                        searchByKeyword(e);
                      }
                    }}
                  />
                  <Input
                    className="input-editable search-select-input"
                    placeholder="名称"
                    allowClear
                    onPressEnter={searchByKeyword}
                    onBlur={searchByKeyword}
                    name="name"
                    id="name"
                    onChange={(e) => {
                      if (document.getElementsByClassName('ant-input-clear-icon-hidden')) {
                        searchByKeyword(e);
                      }
                    }}
                  />
                  <Select
                    className="search-select"
                    onChange={searchByDataState}
                    id="dataStateDrop"
                    name="dataStateDrop"
                    value={dataState}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record) => (record.id == selectedRowId ? 'active-row' : 'data-row')}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record?.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      // defaultPageSize: defaultPageSize,
                      defaultCurrent: 1,
                      // pageSize: defaultPageSize,
                      current: currentPage,
                      pageSize: pageSize,
                      pageSizeOptions: pageSizes,
                      showSizeChanger: true,
                      onShowSizeChange: handlePageSizeChange,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":project"
                element={
                  <ProjectStatusDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    submitData={submitData}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    updateEditMode={updateEditMode}
                    onChangeSortNum={onChangeSortNum}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* XML呼出 */}
      {/* <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ProjectStatusParamList;
