import { SaveSameType, WorkType } from './enums';

export const parentKouteiSentaku = {
  id: 15,
  name: '',
  details: [
    {
      deviceId: 25,
      processId: 1,
      isUsedForCalc: true,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 1,
          processDetailTypeId: 1,
          processDetailTypeName: '',
        },
        {
          processNo: 2,
          processDetailGroup: 2,
          processDetailTypeId: 2,
          processDetailTypeName: '',
        },
        {
          processNo: 3,
          processDetailGroup: 3,
          processDetailTypeId: 10,
          processDetailTypeName: '',
        },
        {
          processNo: 4,
          processDetailGroup: 4,
          processDetailTypeId: 10,
          processDetailTypeName: '',
        },
      ],
    },
    {
      deviceId: 26,
      processId: 2,
      isUsedForCalc: false,
      multiprocesses: [],
    },
    {
      deviceId: 27,
      processId: 3,
      isUsedForCalc: false,
      multiprocesses: [],
    },
    {
      deviceId: 28,
      processId: 4,
      isUsedForCalc: false,
      multiprocesses: [],
    },
    {
      deviceId: 29,
      processId: 5,
      isUsedForCalc: false,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 0,
          processDetailTypeId: 1,
          processDetailTypeName: '',
        },
      ],
    },
    {
      deviceId: 12,
      processId: 6,
      isUsedForCalc: false,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 0,
          processDetailTypeId: 2,
          processDetailTypeName: '',
        },
      ],
    },
    {
      deviceId: 0,
      processId: 7,
      isUsedForCalc: false,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 0,
          processDetailTypeId: 3,
          processDetailTypeName: '',
        },
      ],
    },
    {
      deviceId: 0,
      processId: 8,
      isUsedForCalc: false,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 0,
          processDetailTypeId: 4,
          processDetailTypeName: '',
        },
      ],
    },
    {
      deviceId: 0,
      processId: 9,
      isUsedForCalc: false,
      multiprocesses: [
        {
          processNo: 1,
          processDetailGroup: 0,
          processDetailTypeId: 5,
          processDetailTypeName: '',
        },
      ],
    },
  ],
};

// parameter
export const paramCommonPurchase = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `00001`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3× 6`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    isAllocation: true,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 2,
    id: 2,
    no: 2,
    isAllocation: true,
    code: `00002`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3× 8`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 3,
    id: 3,
    no: 3,
    isAllocation: true,
    code: `00003`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×10`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 10,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 10, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 4,
    id: 4,
    no: 4,
    isAllocation: true,
    code: `00004`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×12`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 5,
    id: 5,
    no: 5,
    isAllocation: true,
    code: `00005`,
    purType: 1,
    name: `オネジスタッド`,
    model: `OST-M3×15`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 60,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 6,
    id: 6,
    no: 6,
    isAllocation: true,
    code: `00006`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×18`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 7,
    id: 7,
    no: 7,
    isAllocation: true,
    code: `00007`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×20`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 8,
    id: 8,
    no: 8,
    code: `00008`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×25`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 9,
    id: 9,
    no: 9,
    code: `00009`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×30`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 15, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 10,
    id: 10,
    no: 10,
    code: `00010`,
    purType: 2,
    name: `オネジスタッド`,
    model: `OST-M3×35`,
    makerName: null,
    supplier: null,
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：50個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 40,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 11,
    id: 11,
    no: 11,
    code: `00011`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×  6`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 12,
    id: 12,
    no: 12,
    code: `00012`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×  8`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 13,
    id: 13,
    no: 13,
    code: `00013`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×10`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 14,
    id: 14,
    no: 14,
    code: `00014`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×12`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 15,
    id: 15,
    no: 15,
    code: `00015`,
    purType: 3,
    name: `オネジスタッド`,
    model: `OST-M4×15`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 30,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 16,
    id: 16,
    no: 16,
    code: `00016`,
    purType: 4,
    name: `オネジスタッド`,
    model: `OST-M4×20`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 17,
    id: 17,
    no: 17,
    code: `00017`,
    purType: 4,
    name: `オネジスタッド`,
    model: `OST-M4×25`,
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: `購入数量：100個`,
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 18,
    id: 18,
    no: 18,
    code: '00018',
    purType: 4,
    name: 'オネジスタッド',
    model: 'OST-M4×30',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 15,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 100, updated: '2020/04/22' },
      { price: 201, updated: '2021/05/10' },
      { price: 15, updated: '2022/05/26' },
    ],
  },
  {
    key: 19,
    id: 19,
    no: 19,
    code: '00019',
    purType: 4,
    name: 'オネジスタッド',
    model: 'OST-M4×35',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 20,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 20, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
  {
    key: 20,
    id: 20,
    no: 20,
    code: '00020',
    purType: 5,
    name: 'オネジスタッド',
    model: 'OST-M4×40',
    makerName: '',
    supplier: '',
    supplierCode: '',
    unitPriceHold: 0,
    unitPrice: 20,
    remark: '購入数量：50個',
    sortNo: 0,
    display: 0,
    created: '2022/05/26',
    updated: '2022/05/26',
    processTime: 10,
    pricesByLot: [
      { minNo: 1, maxNo: 100, price: 90 },
      { minNo: 101, maxNo: 200, price: 80 },
      { minNo: 201, maxNo: 500, price: 75 },
    ],
    autoPurchase: [
      { type: 1, material: 2, size: 90 },
      { type: 3, material: 4, size: 80 },
      { type: 5, material: 6, size: 75 },
    ],
    updatedHistory: [
      { price: 20, updated: '2020/04/22' },
      // { price: 201, updated: '2021/05/10' },
      // { price: 500, updated: '2022/05/26' },
    ],
  },
];

export const paramCommonPurchaseType = [
  { no: 1, used: 1, name: 'スタッド' },
  { no: 2, used: 1, name: 'ナット' },
  { no: 3, used: 1, name: 'スペーサー' },
  { no: 4, used: 1, name: 'ソケット' },
  { no: 5, used: 1, name: 'その他' },
];

export const paramCommonOrderCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `試作`,
    coefficient: `2.0`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `単発`,
    coefficient: `1.5`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `ロット`,
    coefficient: `1`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `月産`,
    coefficient: `0.9`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `その他`,
    coefficient: `0.8`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
  {
    key: 6,
    id: 6,
    no: 6,
    code: `005`,
    name: `大口(年間契約)`,
    coefficient: `0.7`,
    remarks: ``,
    sortNo: 0,
    display: 1,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
  },
];

export const paramCommonDeliTimeCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `特急`,
    coefficient: `2.0`,
    estimateDays: `~2`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `急行`,
    coefficient: `1.2`,
    estimateDays: `~4`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `普通`,
    coefficient: `1.0`,
    estimateDays: `~9`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `鈍行`,
    coefficient: `0.9`,
    estimateDays: `~29`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `寝台`,
    coefficient: `1.1`,
    estimateDays: `30~`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
];

export const paramCommonPurchaseCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `普通`,
    coefficient: `1.5`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `高コスト`,
    coefficient: `1.25`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `低コスト`,
    coefficient: `0.8`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
];

export const paramCommonMaintainCoeff = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `普通`,
    coefficient: `1.10`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `特殊管理`,
    coefficient: `1.75`,
    remarks: ``,
    sortNo: 0,
    display: 0,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
];

export const paramCommonCost = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `諸経費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    isUsedTanka: true,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `特別費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `調査費`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    display: 1,
    code: `004`,
    name: `カスタマイズ費用`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 1,
    data: 5000,
    isUsedTanka: true,
  },
];

export const paramCommonLabel = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `製造部管理ラベル`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `社内仕分けラベル`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `カスタマイズラベル`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 2,
  },
];

export const paramCommonMemo = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `注意事項`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `見積備考`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `コメント`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    display: 1,
    code: `004`,
    name: `カスタマイズメモ`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 3,
  },
];

export const paramCommonDate = [
  {
    key: 1,
    id: 1,
    no: 1,
    display: 1,
    code: `001`,
    name: `社内納期`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    display: 1,
    code: `002`,
    name: `回答日`,
    remarks: ``,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    display: 1,
    code: `003`,
    name: `カスタマイズ日付`,
    remarks: `名称は自由に変更できます。`,
    created: `2002-05-10`,
    updated: `2002-05-10`,
    tableNo: 4,
  },
];

export const paramCommonProjectStatus = [
  {
    key: 1,
    id: 1,
    no: 1,
    code: `001`,
    name: `無効`,
    remark: ``,
    sortNo: 10,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 2,
    id: 2,
    no: 2,
    code: `002`,
    name: `保留`,
    remark: ``,
    sortNo: 9,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 3,
    id: 3,
    no: 3,
    code: `003`,
    name: `非受注`,
    remark: ``,
    sortNo: 8,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 4,
    id: 4,
    no: 4,
    code: `004`,
    name: `受注値引き`,
    remark: ``,
    sortNo: 7,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 5,
    id: 5,
    no: 5,
    code: `005`,
    name: `受注`,
    remark: ``,
    sortNo: 6,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 6,
    id: 6,
    no: 6,
    code: `006`,
    name: `未回答`,
    remark: ``,
    sortNo: 5,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 7,
    id: 7,
    no: 7,
    code: `007`,
    name: `承認済`,
    remark: ``,
    sortNo: 4,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 8,
    id: 8,
    no: 8,
    code: `008`,
    name: `承認待`,
    remark: ``,
    sortNo: 3,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 9,
    id: 9,
    no: 9,
    code: `009`,
    name: `再見積指示`,
    remark: ``,
    sortNo: 2,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
  {
    key: 10,
    id: 10,
    no: 10,
    code: `010`,
    name: `作成中`,
    remark: ``,
    sortNo: 1,
    display: 0,
    created: `2015/06/06`,
    updated: `2015/06/06`,
  },
];

const yosetsuDetailType1 = [
  'アルゴン',
  '半自動',
  '溶接線01',
  '溶接線02',
  '溶接線03',
  'YAGハンディ',
  'YAGロボット',
  'ロウ付',
  '開先取り',
  'Vカット',
];

const yosetsuDetailType2 = ['点付', 'プラグ溶接', '定置スポット', 'テーブルスポット'];

const yosetsuDetailType3 = ['手動スタッド', '自動スタッド', 'ナット溶接', 'ボルト溶接'];

const yosetsuFinishDetailType = ['バフ', '酸洗い', 'サンダー'];

const hyomenDetailType1 = [
  'ユニクロメッキ',
  'クロメート',
  'ニッケルメッキ',
  'クロームメッキ',
  '亜鉛メッキ',
  'メッキ01',
  'メッキ02',
  'メッキ03',
];

const hyomenDetailType2 = [
  'メラミン塗装',
  'アクリル塗装',
  'ウレタン塗装',
  '粉体塗装',
  'カチオン塗装',
  'プライマー',
  '塗装01',
  '塗装02',
];

const hyomenDetailType3 = ['脱脂'];

const blank = ['レーザー', 'パンチ', '複合機'];

const bending = [
  '曲げ_V',
  '曲げ_R',
  '曲げ_FR',
  '曲げ_3R',
  '曲げ_HM',
  '曲げ_Z',
  '曲げ_AMA',
  '曲げ_01',
  '曲げ_02',
  '曲げ_03',
];

const bari = ['手動', '自動'];

const kumitateDetailType = ['購入品取付時間', '重量'];

const kensaKonpoDetailType = ['子部品点数', '子部品種類数', '重量'];

const nijikako = ['バーリング', 'タップ', 'サラ', 'カシメ', 'ボルト圧入', 'ナット圧入', '成形型穴', '多工程穴'];

const iq3yosetsuDetailType2 = [
  '点付',
  'プラグ溶接',
  '定置スポット',
  'テーブルスポット',
  '手動スタッド',
  '自動スタッド',
  'ナット溶接',
  'ボルト溶接',
  '取付01',
  '取付02',
];

const iq3YosetsuFinish = ['バフ', '酸洗い', 'サンダー', 'Vカット', '開先'];

const additionalDatakouteiMaster = ['仮付', 'ネスティング', '水張検査'];

const additionalDatakouteiMaster_iq3 = ['仮付', 'ネスティング', '水張検査'];

const releaseDate = '2023-10-01';

export const detailArrCreate = (arrDetailType) => {
  let detailArr = [];
  arrDetailType?.forEach((element, index) => {
    let detailValue = {
      key: index,
      processDetailNo: index + 1,
      displayFlg: true,
      detailCode: (index + 1).toString().padStart(3, '0'),
      detailType: arrDetailType[index],
      // detailGroup: index < 6 ? '追加工程グループ①' : index < 12 ? '追加工程グループ②' : '追加工程グループ③',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      useFlg: false,
    };
    detailArr.push(detailValue);
  });
  return detailArr;
};

export const kouteiMasterData = [
  {
    key: 0,
    No: 1,
    display: true,
    type: '親部品',
    category: '標準',
    code: '001',
    processName: '溶接',
    processType: '溶接',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: detailArrCreate(yosetsuDetailType1),
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType2),
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType3),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 1,
    No: 2,
    display: true,
    type: '親部品',
    category: '標準',
    code: '002',
    processName: '溶接仕上',
    processType: '溶接仕上',
    remark: '',
    created: releaseDate,
    modified: releaseDate,
    numberOfProcess: 10,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: detailArrCreate(yosetsuFinishDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 2,
    No: 3,
    display: true,
    type: '親部品',
    category: '標準',
    code: '003',
    processName: '表面処理',
    processType: '表面処理',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    numberOfProcess: 6,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: detailArrCreate(hyomenDetailType3),
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: detailArrCreate(hyomenDetailType1),
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: detailArrCreate(hyomenDetailType2),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 3,
    No: 4,
    display: true,
    type: '親部品',
    category: '標準',
    code: '004',
    processName: '組立',
    processType: '組立',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kumitateDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 4,
    No: 5,
    display: true,
    type: '親部品',
    category: '標準',
    code: '005',
    processName: '検査',
    processType: '検査',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 5,
    No: 6,
    display: true,
    type: '親部品',
    category: '標準',
    code: '006',
    processName: '梱包',
    processType: '梱包',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 6,
    No: 7,
    display: true,
    type: '親部品',
    category: '追加',
    code: '007',
    processName: '追加工程①',
    processType: 'カスタム',
    created: '2023-10-01',
    modified: '2023-07-06',
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: [
          {
            key: 0,
            processDetailNo: 1,
            displayFlg: true,
            detailCode: '001',
            detailType: '仮付',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 1,
            processDetailNo: 2,
            displayFlg: true,
            detailCode: '002',
            detailType: 'ネスティング',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 2,
            processDetailNo: 3,
            displayFlg: true,
            detailCode: '003',
            detailType: '水張検査',
            created: '2023-10-01',
            modified: '2023-10-01',
            remark: '',
            useFlg: false,
          },
          {
            key: 4,
            processDetailNo: 4,
            displayFlg: true,
            detailCode: '004',
            detailType: '社内工程_4',
            created: '2023-07-06',
            modified: '2023-07-06',
            remark: '',
          },
          {
            key: 5,
            processDetailNo: 5,
            displayFlg: true,
            detailCode: '005',
            detailType: '社内工程_5',
            created: '2023-07-06',
            modified: '2023-07-06',
            remark: '',
          },
        ],
        defaultRow: 0,
      },
    ],
  },
  {
    key: 7,
    No: 8,
    display: true,
    type: '親部品',
    category: '追加',
    code: '008',
    processName: '追加工程②',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 8,
    No: 9,
    display: true,
    type: '親部品',
    category: '追加',
    code: '009',
    processName: '追加工程③',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 9,
    No: 10,
    display: true,
    type: '親部品',
    category: '追加',
    code: '010',
    processName: '追加工程④',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 10,
    No: 11,
    display: true,
    type: '親部品',
    category: '追加',
    code: '011',
    processName: '追加工程⑤',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 11,
    No: 12,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '012',
    processName: 'プログラム',
    processType: 'プログラム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [],
  },
  {
    key: 12,
    No: 13,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '013',
    processName: 'ブランク',
    processType: 'ブランク',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: detailArrCreate(blank),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 13,
    No: 14,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '014',
    processName: 'ベンディング',
    processType: 'ベンディング',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '曲げ種類',
        processDetailValue: detailArrCreate(bending),
        defaultRow: 10,
      },
    ],
  },
  {
    key: 14,
    No: 15,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '015',
    processName: 'バリ取',
    processType: 'バリ取',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '加工方法',
        processDetailValue: detailArrCreate(bari),
        defaultRow: 2,
      },
    ],
  },
  {
    key: 15,
    No: 16,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '016',
    processName: '表面処理',
    processType: '表面処理',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    numberOfProcess: 4,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '処理全般種類',
        processDetailValue: detailArrCreate(hyomenDetailType3),
        defaultRow: 1,
      },
      {
        key: 1,
        processDetailTitle: 'メッキ種類',
        processDetailValue: detailArrCreate(hyomenDetailType1),
        defaultRow: 8,
      },
      {
        key: 2,
        processDetailTitle: '塗装種類',
        processDetailValue: detailArrCreate(hyomenDetailType2),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 16,
    No: 17,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '017',
    processName: '2次加工',
    processType: '2次加工',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '2次加工種類',
        processDetailValue: detailArrCreate(nijikako),
        defaultRow: 8,
      },
    ],
  },
  {
    key: 17,
    No: 18,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '018',
    processName: '溶接',
    processType: '溶接',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接種類',
        processDetailValue: detailArrCreate(yosetsuDetailType1),
        defaultRow: 10,
      },
      {
        key: 1,
        processDetailTitle: '取付種類（板厚判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType2),
        defaultRow: 5,
      },
      {
        key: 2,
        processDetailTitle: '取付種類（サイズ判断）',
        processDetailValue: detailArrCreate(yosetsuDetailType3),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 18,
    No: 19,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '019',
    processName: '溶接仕上',
    processType: '溶接仕上',
    remark: '',
    created: releaseDate,
    modified: releaseDate,
    numberOfProcess: 4,
    processDetail: [
      {
        key: 0,
        processDetailTitle: '溶接仕上種類',
        processDetailValue: detailArrCreate(iq3YosetsuFinish),
        defaultRow: 5,
      },
    ],
  },
  {
    key: 19,
    No: 20,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '020',
    processName: 'シャーリング',
    processType: 'シャーリング',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [],
  },
  {
    key: 20,
    No: 21,
    display: true,
    type: 'iQ3',
    category: '標準',
    code: '021',
    processName: '検査',
    processType: '検査',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '計算方法',
        processDetailValue: detailArrCreate(kensaKonpoDetailType),
        defaultRow: 3,
      },
    ],
  },
  {
    key: 21,
    No: 22,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '022',
    processName: '追加工程①',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 22,
    No: 23,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '023',
    processName: '追加工程②',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 23,
    No: 24,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '024',
    processName: '追加工程③',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 24,
    No: 25,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '025',
    processName: '追加工程④',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
  {
    key: 25,
    No: 26,
    display: true,
    type: 'iQ3',
    category: '追加',
    code: '026',
    processName: '追加工程⑤',
    processType: 'カスタム',
    created: releaseDate,
    modified: releaseDate,
    remark: '',
    processDetail: [
      {
        key: 0,
        processDetailTitle: '追加工程種類',
        processDetailValue: detailArrCreate(additionalDatakouteiMaster_iq3),
        defaultRow: 0,
      },
    ],
  },
];

export const ItakuSaki = '委託先';
export const Supplier = '仕入先';
export const Customer = 'お客様';

export const purchaseCategoryDefaultValue = '種別を選んでください。';

export const sameSizePatternValue = [
  { label: '4個', value: 0 },
  { label: '5個以上', value: 1 },
];
export const IQ3MaterialDetailData = [
  {
    id: 0,
    size: 0,
    quantity: 165,
    price: 13671,
    yield: 43.9,
    pattern1: 43.9,
    p1PlacementNum: 1,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 43.9,
    maxProcessNum: 1,
  },
  {
    id: 1,
    size: 1,
    quantity: 3,
    price: 9112,
    yield: 65.8,
    pattern1: 67.0,
    p1PlacementNum: 56,
    pattern2: 63.4,
    p2PlacementNum: 53,
    maxYield: 67.0,
    maxProcessNum: 56,
  },
  {
    id: 2,
    size: 2,
    quantity: 2,
    price: 10800,
    yield: 55.5,
    pattern1: 74.0,
    p1PlacementNum: 110,
    pattern2: 37.0,
    p2PlacementNum: 55,
    maxYield: 74.0,
    maxProcessNum: 110,
  },
  {
    id: 3,
    size: 3,
    quantity: 1,
    price: 8440,
    yield: 71.0,
    pattern1: 71.0,
    p1PlacementNum: 165,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 78.4,
    maxProcessNum: 182,
  },
  {
    id: 4,
    size: 4,
    quantity: 3,
    price: 10902,
    yield: 55.0,
    pattern1: 72.0,
    p1PlacementNum: 72,
    pattern2: 21.0,
    p2PlacementNum: 21,
    maxYield: 21.0,
    maxProcessNum: 72,
  },
  {
    id: 5,
    size: 5,
    quantity: 1,
    price: 14536,
    yield: 41.3,
    pattern1: 41.3,
    p1PlacementNum: 165,
    pattern2: 0,
    p2PlacementNum: 0,
    maxYield: 76.5,
    maxProcessNum: 306,
  },
  {
    id: 6,
    size: 6,
    quantity: 2,
    price: 11356,
    yield: 52.8,
    pattern1: 70.4,
    p1PlacementNum: 110,
    pattern2: 35.2,
    p2PlacementNum: 55,
    maxYield: 70.4,
    maxProcessNum: 1104,
  },
  {
    id: 7,
    size: 7,
    quantity: 2,
    price: 11356,
    yield: 52.8,
    pattern1: 70.4,
    p1PlacementNum: 110,
    pattern2: 35.2,
    p2PlacementNum: 55,
    maxYield: 70.4,
    maxProcessNum: 1104,
  },
  {
    id: 8,
    size: 8,
    quantity: 2,
    price: 11356,
    yield: 52.8,
    pattern1: 70.4,
    p1PlacementNum: 110,
    pattern2: 35.2,
    p2PlacementNum: 55,
    maxYield: 70.4,
    maxProcessNum: 1104,
  },
];

export const iq3InitData = {
  key: 0,
  no: 1,
  adopt: `自動`,
  image: null,
  partCnt: 0,
  processNm: ``,
  partNm: ``,
  imgNo: ``,
  material: '',
  thickness: '',
  materialNm: '',
  weight: 0,
  surfaceArea: 0,
  materialCost: 0,
  processCost: 0,
  purchaseCost: 0,
  costPrice: 0,
  estimatedAmount: 0,
  totalCostPrice: 0,
  totalEstimatedAmount: 0,
  costProfit: 0.0,
  estimateProfit: 0.0,
  profitRate: '0',
  iq3KouteiSentakuPatternNo: 0,
  iq3KouteiInputData: [],
  iq3KouteiCalInfoData: [],
  iq3KouteiCalInfoEditData: [],
  iq3PurchaseData: [],
};
export const processInputSurfaceData = [
  {
    no: 1,
    calcType: 1,
    processDetailWorkTypeGroup: 0,
    processDetailTypeNo: 0,
    areaUnitPrice: 0,
    weightUnitPrice: 0,
    weightCount: 1,
    areaCount: 1,
    areaAutoExtract: 0,
    weightAutoExtract: 0,
    totalArea: 0,
    totalWeight: 0,
  },
];
export const processInputKumitateData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  purchaseInstTime: 0,
  assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
  autoExtract: 1, // IQX_WEBEST-179
};
export const processInputKensaData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  autoExtract: 1, // IQX_WEBEST-179
};
export const processInputKonpouData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  autoExtract: 1, // IQX_WEBEST-179
};

export const timeHmsStr = '00:00:00';
export const amtEmptyStr = '¥0';
//数量別見積初期値
export const byQuantities = [
  {
    quantity: 1,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 2,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 3,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 4,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 5,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 10,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 20,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 30,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 40,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 50,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
  {
    quantity: 100,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
  },
];

export const iq3KouteiInputData = [
  {
    id: 1,
    processId: 0,
    workType: WorkType.SmProgram,
    detail: { elements: 0, elementsAuto: 0 },
  },
  {
    id: 2,
    processId: 0,
    workType: WorkType.SmBlankCombi,
    detail: {
      dimensionData: {
        outerDia: 0,
        outerAutoExtract: 0,
        innerDia: 0,
        innerAutoExtract: 0,
        airFeedDistance: 0,
        airFeedAutoExtract: 0,
      },
      quantityList: [{ id: 1, types: 1, xsize: 0, ysize: 0, perimeter: 0, count: 0 }],
    },
  },
  {
    id: 3,
    processId: 0,
    workType: WorkType.SmSecondaryWork,
    detail: { quantityList: [{ id: 1, types: 1, xsize: 0, ysize: 0, perimeter: 0, count: '1', blankFlag: false }] },
  },
  {
    id: 4,
    processId: 0,
    workType: WorkType.SmBending,
    detail: {
      bendingData: { maxBendLength: 0, maxBendAutoExtract: 0 },
      bendingList: [
        {
          id: 1,
          types: 1,
          bendLength: 0,
          lines: 1,
          bendCount: 1,
        },
      ],
    },
  },
  {
    id: 5,
    processId: 0,
    workType: WorkType.SmDeburringAuto,
    detail: {},
  },
  {
    id: 6,
    processId: 0,
    workType: WorkType.SmWelding,
    detail: {},
  },
  {
    id: 7,
    processId: 0,
    workType: WorkType.SmSurface,
    detail: {},
  },
  {
    id: 8,
    processId: 0,
    workType: WorkType.SmShearing,
    detail: {},
  },
  {
    id: 9,
    processId: 0,
    workType: WorkType.SmInspection,
    detail: {},
  },
];
export const testData1 = {
  name: null,
  area: 71043.34468017519,
  airDistance: 1953.4000244140625,
  elements: 25,
  etchingLength: 0,
  slitLength: 0,
  boundaryBoxSize: { width: 379.0999755859375, height: 187.4000244140625 },
  contourLength: {
    totalLength: 1997.9274978637695,
    outerLength: 1632.369981765747,
    innerLength: 365.55751609802246,
    holes: [
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1991.703, y: 632.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1757.703, y: 632.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1640.803, y: 595.093 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1730.803, y: 595.093 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1991.703, y: 553.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1757.703, y: 553.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1976.703, y: 541.193 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1772.703, y: 541.193 },
        length: 13.194689512252808,
      },
      { name: 'Other', xl: 0, yl: 0, r: 0, number: 1, bp: { isEmpty: true, x: 0, y: 0 }, length: 260 },
    ],
  },
  bendInfo: [
    { name: 'V', sp: { isEmpty: true, x: 0, y: 0 }, ep: { isEmpty: true, x: 0, y: 0 }, width: 0, number: 1, amount: 1 },
    { name: 'V', sp: { isEmpty: true, x: 0, y: 0 }, ep: { isEmpty: true, x: 0, y: 0 }, width: 0, number: 1, amount: 1 },
    { name: 'V', sp: { isEmpty: true, x: 0, y: 0 }, ep: { isEmpty: true, x: 0, y: 0 }, width: 0, number: 1, amount: 1 },
    { name: 'V', sp: { isEmpty: true, x: 0, y: 0 }, ep: { isEmpty: true, x: 0, y: 0 }, width: 0, number: 1, amount: 1 },
  ],
  weldInfo: [{ weldType: 'アルコン', length: 1, number: 1 }],
  subWeldInfo: [{ name: 'WE3', number: 1, cp: 2, xl: 11 }],
  turringTappings: [
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1991.703, y: 632.593 }, number: 1 },
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1640.803, y: 595.093 }, number: 1 },
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1730.803, y: 595.093 }, number: 1 },
  ],
  pierces: [],
};
export const testData2 = {
  name: null,
  area: 71043.34468017519,
  airDistance: 1953.4000244140625,
  elements: 35,
  etchingLength: 0,
  slitLength: 0,
  boundaryBoxSize: { width: 379.0999755859375, height: 187.4000244140625 },
  contourLength: {
    totalLength: 1997.9274978637695,
    outerLength: 1632.369981765747,
    innerLength: 365.55751609802246,
    holes: [
      {
        name: 'SQ',
        xl: 4.2,
        yl: 0,
        r: 10,
        number: 1,
        bp: { isEmpty: false, x: 1991.703, y: 632.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 10,
        number: 1,
        bp: { isEmpty: false, x: 1757.703, y: 632.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1640.803, y: 595.093 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1730.803, y: 595.093 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1991.703, y: 553.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1757.703, y: 553.593 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1976.703, y: 541.193 },
        length: 13.194689512252808,
      },
      {
        name: 'RO',
        xl: 4.2,
        yl: 0,
        r: 0,
        number: 1,
        bp: { isEmpty: false, x: 1772.703, y: 541.193 },
        length: 13.194689512252808,
      },
      { name: 'Other', xl: 0, yl: 0, r: 0, number: 1, bp: { isEmpty: true, x: 0, y: 0 }, length: 260 },
    ],
  },
  bendInfo: [
    {
      name: 'V',
      sp: { isEmpty: true, x: 0, y: 0 },
      ep: { isEmpty: true, x: 0, y: 0 },
      width: 10,
      number: 1,
      amount: 1,
    },
    {
      name: 'R',
      sp: { isEmpty: true, x: 0, y: 0 },
      ep: { isEmpty: true, x: 0, y: 0 },
      width: 20,
      number: 1,
      amount: 1,
    },
    {
      name: 'FR',
      sp: { isEmpty: true, x: 0, y: 0 },
      ep: { isEmpty: true, x: 0, y: 0 },
      width: 30,
      number: 1,
      amount: 1,
    },
    {
      name: 'V',
      sp: { isEmpty: true, x: 0, y: 0 },
      ep: { isEmpty: true, x: 0, y: 0 },
      width: 40,
      number: 1,
      amount: 1,
    },
  ],
  weldInfo: [{ weldType: 'アルコン', length: 1, number: 1 }],
  subWeldInfo: [
    { name: 'WE4', number: 1, cp: 2, xl: 11 },
    { name: 'WE2', number: 1, cp: 2, xl: 11 },
  ],
  turringTappings: [
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1991.703, y: 632.593 }, number: 1 },
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1640.803, y: 595.093 }, number: 1 },
    { name: 'TP', size: 5, cp: { isEmpty: false, x: 1730.803, y: 595.093 }, number: 1 },
  ],
  pierces: [],
};

export const BlankTypes = [
  { id: 1, value: 'SQ', label: '角' },
  { id: 2, value: 'OB', label: '長丸' },
  { id: 3, value: 'RE', label: '長角' },
  { id: 4, value: 'RR', label: 'R付角' },
  { id: 5, value: 'CP', label: 'センタポンチ' },
  { id: 6, value: 'SD', label: 'シングルD' },
  { id: 7, value: 'DD', label: 'ダブルD' },
  { id: 8, value: 'RO', label: '丸' },
];

export const Types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];

// ベンディング種類
export const BendingTypes = [
  { id: 1, value: 'V' },
  { id: 2, value: 'R' },
  { id: 3, value: 'FR' },
  { id: 4, value: '3R' },
  { id: 5, value: 'HM' },
  { id: 6, value: 'Z' },
  { id: 7, value: 'AMA' },
  { id: 8, value: 'B1' },
  { id: 9, value: 'B2' },
  { id: 10, value: 'B3' },
];

// サプ溶接種類
export const SubWeldTypes = [
  { id: 11, value: 'WE1' },
  { id: 12, value: 'WE2' },
  { id: 13, value: 'WE3' },
  { id: 14, value: 'WE4' },
  { id: 15, value: 'WE5' },
  { id: 16, value: 'WE6' },
  { id: 17, value: 'WE7' },
  { id: 18, value: 'WE8' },
];

export const FRBend = 'FR';

// 2次加工種類
export const SecondaryWorkTypes = [
  { id: 1, value: 'BR' },
  { id: 2, value: 'TP' },
  { id: 3, value: 'COUNTERSINK' },
  { id: 4, value: 'OTHER' },
  { id: 5, value: 'HL1' },
  { id: 6, value: 'HL2' },
  { id: 7, value: 'HL3' },
  { id: 8, value: 'HL4' },
];

export const DataButton = 'リセット';

export const parentPurchaseTitle = (
  <p>
    親部品の購入品は数量によってのみ<br></br>合計金額が変更され、子部品の員数には影響されません
  </p>
);
export const iq3PurchaseTitle = (
  <p>
    子部品に紐づく購入品の為、<br></br>子部品員数、数量によって合計金額が変更されます
  </p>
);

// pagination
export const pageSizes = ['100', '200', '300', '500', '1000'];

// 同一名の保存を許可するタイプ
export const saveSameTypes = [
  {
    value: SaveSameType.EstimateNo,
    label: '見積番号',
  },
  {
    value: SaveSameType.DrawingNo,
    label: '図番',
  },
  {
    value: SaveSameType.Name,
    label: '名称',
  },
];

export const dataTypeList = [
  { id: 1, value: 3, name: 'All' }, // 全て
  { id: 2, value: 1, name: '3Dあり' }, // AuDi
  { id: 3, value: 2, name: '3Dなし' }, // 2DCad
];

export const helpCmt = {
  procTotalAmt: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計金額＝段取金額＋加工金額</p>
    </div>
  ),
  procTotalAmt_laser: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計金額＝段取金額＋加工金額+2工程穴加工金額+ジョイントバラシ金額</p>
    </div>
  ),
  procTotalAmt_shiage: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計金額＝段取金額＋仕上金額</p>
    </div>
  ),

  procTotalTm: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計時間＝段取時間＋加工時間</p>
    </div>
  ),
  procTotalTm_laser: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計時間＝段取時間＋加工時間+2工程穴加工時間+ジョイントバラシ加工時間</p>
    </div>
  ),
  procTotalTm_shiage: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>合計時間＝段取時間＋仕上時間</p>
    </div>
  ),

  setupTotalAmt: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取金額の合計</p>
    </div>
  ),

  setupTotalTm: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間の合計</p>
    </div>
  ),

  setupAmt_p1: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取金額＝段取時間(秒) x 段取時間チャージ/3600(秒)</p>
    </div>
  ),

  setupTm_p1: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>基本段取時間</p>
    </div>
  ),
  setupTm_p2: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間=基本段取時間ｘ段取係数1ｘ段取係数2</p>
    </div>
  ),
  setupTm_p3: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間=初期段取時間</p>
    </div>
  ),
  setupTm_p4: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間=基本段取時間x段取個数係数</p>
    </div>
  ),
  setupTm_p5: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間=基本段取時間x段取個数係数＋金型段取時間</p>
    </div>
  ),
  setupTm_p6: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>段取時間＝基本段取時間ｘ子部品サイズ係数ｘ段取個数係数</p>
    </div>
  ),
  setupTm_p7: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>プログラム作成時間</p>
    </div>
  ),
  setupTm_p8: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>曲長段取時間</p>
    </div>
  ),

  workTotalAmt: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額の合計</p>
    </div>
  ),
  workTotalTm: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間の合計</p>
    </div>
  ),
  workTotalTm_kumitate: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        加工時間=部品数の組立時間+【購入品名1】の組立時間＋【購入品名2】の組立時間…
      </p>
    </div>
  ),
  workTotalTm_laser: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        加工時間＝外周切断時間＋内周切断時間＋空送時間＋ピアス加工時間＋ケガキ加工時間
      </p>
    </div>
  ),
  workTotalTm_punch: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝パンチ加工時間＋空送時間＋ケガキ加工時間</p>
    </div>
  ),
  workTotalTm_combi: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        加工時間=レーザー加工時間＋パンチ加工時間＋空送時間＋2工程穴加工時間+ジョイントバラシ加工時間
      </p>
    </div>
  ),

  workAmt_p1: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額=面積ｘ面積チャージ</p>
    </div>
  ),
  workAmt_p2: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額=加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_outerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝外周切断時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_innerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝内周切断時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_airDistance: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝空送時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_piercing: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝ピアス加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_scribing: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝ケガキ加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_twoStepHole: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝2工程穴加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_joint: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工金額＝ジョイントバラシ加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_punch: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>パンチ加工金額＝パンチ加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),
  workAmt_laser: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>レーザー加工金額＝加工時間(秒) x 加工時間チャージ/3600(秒)</p>
    </div>
  ),

  workTm_p1: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>板金部品の面積の合計</p>
    </div>
  ),
  workTm_p2: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間=1部品当たりの作業時間ｘ部品数ｘ重量係数</p>
    </div>
  ),
  workTm_p3: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>【購入品名1】の組立時間ｘ購入品の数量</p>
    </div>
  ),
  workTm_p4: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間=1要素当たりの作業時間ｘ要素数ｘ作業時間係数</p>
    </div>
  ),
  workTm_outerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>外周切断時間＝外周切断長×サイズ係数/(加工速度×加工速度係数×ガス係数)</p>
    </div>
  ),
  workTm_innerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>内周切断時間＝内周切断長×サイズ係数/(加工速度×加工速度係数×ガス係数)</p>
    </div>
  ),
  workTm_airDistance: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>空送時間＝空送距離/送り速度</p>
    </div>
  ),
  workTm_piercing: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>ピアス加工時間＝1ピアス加工時間×箇所数×ガス係数</p>
    </div>
  ),
  workTm_scribing: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>ケガキ加工時間＝ケガキ長/ケガキ加工速度</p>
    </div>
  ),
  workTm_twoStepHole: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>2工程穴加工時間＝2工程穴加工時間ｘ箇所数</p>
    </div>
  ),
  workTm_joint: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>ジョイントバラシ加工時間＝ジョイントバラシ加工時間ｘ箇所数</p>
    </div>
  ),
  workTm_punch: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>パンチ加工時間＝1パンチ加工時間ｘパンチ数ｘサイズ係数</p>
    </div>
  ),
  workTm_laser: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        レーザー加工時間＝外周切断時間＋内周切断時間＋ピアス加工時間＋ケガキ加工時間
      </p>
    </div>
  ),
  workTm_2ji: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        加工時間＝(1つ当たりの加工時間ｘ個数ｘサイズ係数＋補正時間ｘ加工補正時間係数)ｘ生産個数係数
      </p>
    </div>
  ),
  workTm_bending: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        加工時間＝((1つ当たりの加工時間ｘ個数ｘ曲げ長さ係数ｘサイズ係数)＋(加工補正時間ｘ加工補正時間係数))ｘ生産個数係数"
      </p>
    </div>
  ),
  workTm_bari_auto: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝加工時間ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
  workTm_bari_manual: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝外周加工時間 + 内周加工時間</p>
    </div>
  ),
  workTm_bari_outerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        外周加工時間＝外周加工長×サイズ係数ｘ生産個数係数/(加工速度×加工速度係数)＋加工時間ｘ加工補正時間係数
      </p>
    </div>
  ),
  workTm_bari_innerDia: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>
        内周加工時間＝内周加工長×サイズ係数ｘ生産個数係数/(加工速度×加工速度係数)＋加工時間ｘ加工補正時間係数
      </p>
    </div>
  ),
  workTm_shirring: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝１カット時間 ｘ(カット数)ｘサイズ係数</p>
    </div>
  ),
  workTm_kensa: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝１検査時間ｘ(検査数)ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
  workTm_yosetsu: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝溶接時間+仮付時間</p>
    </div>
  ),
  workTm_subyosetsu: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>加工時間＝サブ溶接時間ｘ数量ｘ難易度係数ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
  workTm_yosetsu_yosetsuLen: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>溶接時間＝（加工長/加工速度）ｘ難易度係数ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
  workTm_yosetsu_karisukeLen: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>仮付時間＝仮付時間ｘ箇所数ｘ難易度係数ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
  workTm_shiage: (
    <div style={{ height: 40 }}>
      <p style={{ fontSize: 12, padding: 10 }}>仕上時間＝仕上長ｘ仕上速度ｘ難易度係数ｘサイズ係数ｘ生産個数係数</p>
    </div>
  ),
};
