/**
 * クラス名：装置設定の加工速度画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  getInputBorderStyle,
  handleKeyPress,
  regexExp,
  sortListByMasterData,
  toHalfWidth,
  updateList,
} from '../../../common/Common';
import { WorkType } from '../../../common/enums';

const MachineSpeed = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  // 溶接線長Modal
  const [isYousetsuSenCho, setIsYousetsuSenchou] = useState(false);

  const [processName, setProcessName] = useState('');

  // 溶接
  const [detailsWeldlineTemp, setDetailWeldlineTemp] = useState([]);
  const [detailsWeldLine, setDetailsWeldLine] = useState([]);
  const [weldLineList, setWeldLineList] = useState([]);

  // 溶接仕上
  const [detailsSenchou, setDetailsSenchou] = useState([]);
  const [detailsSenchouTemp, setDetailsSenchouTemp] = useState([]);
  const [senchouList, setSenchouList] = useState([]);

  const [addSenchouMaster, setAddSenchouMaster] = useState([]);
  const [kouteiTypeList, setKouteiTypeList] = useState([]);

  const [filterKeys, setFilterKeys] = useState([]);
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    setProcessName(props.processName);
    //  溶接
    setDetailsWeldLine(props.detailsWeldLine);
    setDetailWeldlineTemp(props.detailsWeldLine);
    // setWeldLineList(props.weldLineLst);
    setWeldLineList(props.senchouList);

    // 溶接仕上
    if (props?.detailsData?.workSpeedThMaster) {
      setDetailsSenchou(props?.detailsData?.workSpeedThMaster);
      setDetailsSenchouTemp(props?.detailsData?.workSpeedThMaster);
    }
    // setDetailsSenchou(props.detailsWeldLine);
    // setDetailsSenchouTemp(props.detailsWeldLine);
    if (props?.detailsData?.workSpeedThItems) {
      setSenchouList(props?.detailsData?.workSpeedThItems);
    }
    // setSenchouList(props.senchouList);

    setKouteiTypeList(props.processTypes);
    setAddSenchouMaster(props.detailsWeldLine);
    const usedIds = props?.processMstRecord?.details?.processDetailTypes
      ?.filter((item) => item.isUsed)
      ?.map((item) => item.id);
    setFilterKeys(usedIds);
  }, [
    props.processName,
    props.editMode,
    props.detailsData,
    props.detailsWeldLine,
    props.processMstRecord,
    props.weldLineLst,
    props.senchouList,
    props.processTypes,
  ]);

  const addYousetsuSenchoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>溶接線長</p>
    </div>
  );

  const addData = () => {};

  const closeAddModal = () => {
    if (isYousetsuSenCho === true) {
      setIsYousetsuSenchou(false);
    }
  };

  const deleteYosetsushiageSenchou = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsSenchouTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsSenchouTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsSenchouTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addYosetsushiageSenchou = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsSenchouTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsSenchouTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsSenchouTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsSenchouTemp(copyData);
      setAddSenchouMaster(copyData);
    }
  };

  const handleYousetsuSenchouChange = (event, id, field) => {
    const updatedData = detailsSenchouTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    // setDetailWeldlineTemp(updatedData);
    setDetailsSenchouTemp(updatedData);
    // setAddSenchouMaster(updatedData);
  };

  const cancelYousetsuShiageSenchoMaster = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setDetailsSenchouTemp(detailsSenchou);
    setIsYousetsuSenchou(false);
  };

  const addYousetsuShiageSenchoMaster = () => {
    setIsYousetsuSenchou(false);

    const deletedId = [];

    detailsSenchou?.forEach((item1, index) => {
      const found = detailsSenchouTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsSenchouTemp
      ?.filter((item) => !item.isOutOfRange && !detailsSenchou.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedData = updateList(senchouList, deletedId, newId);

    const sortedList = sortListByMasterData(updatedData, detailsSenchouTemp);

    setDetailsSenchou(detailsSenchouTemp);
    setSenchouList(sortedList);
    props.updatedSenchouList(sortedList);
    props.updatedYousetsuSenchou(detailsSenchouTemp);
  };
  const isValueOrderCorrect = (e, no, field) => {
    const temp = detailsSenchouTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleYousetsuSenchouChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsSenchouTemp
                  ?.filter((i) => i.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleYousetsuSenchouChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          style={{
                            ...getInputBorderStyle(index, detailsSenchouTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            // onClick={() =>
                            //   processName === '溶接' ? addYousetsuSencho(item.no) : addYosetsushiageSenchou(item.no)
                            // }
                            onClick={() => addYosetsushiageSenchou(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            // onClick={(e) =>
                            //   processName === '溶接'
                            //     ? deleteYousetsuSenchou(item.no, e)
                            //     : deleteYosetsushiageSenchou(item.no, e)
                            // }
                            onClick={(e) => deleteYosetsushiageSenchou(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          // onClick={processName === '溶接' ? addYousetsuSenchoMaster : addYousetsuShiageSenchoMaster}
          onClick={addYousetsuShiageSenchoMaster}
        >
          OK
        </Button>
        <Button
          className="cancelButton"
          style={{ marginLeft: 10 }}
          id="discard"
          // onClick={processName === '溶接' ? cancelYousetsuSenchoMaster : cancelYousetsuShiageSenchoMaster}
          onClick={cancelYousetsuShiageSenchoMaster}
        >
          キャンセル
        </Button>
      </Row>
    </>
  );

  const yousetsuSenchouModal = () => {
    setIsYousetsuSenchou(true);
  };

  const listDataChange = (event, key, no) => {
    const newList = { ...senchouList };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);
    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event; // Assuming value is numeric
      setSenchouList(newList);
      props.updatedSenchouList(newList);
    }
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Row>
          <Table
            style={{
              width:
                detailsSenchou?.filter((i) => i.isOutOfRange === false)?.length > 8
                  ? '100%'
                  : detailsSenchou?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center' }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    {processName === WorkType.PaWelding ? <label>溶接種類</label> : <label>仕上種類</label>}/
                    {editMode ? (
                      <>
                        <Button
                          className="cancleButton"
                          onClick={yousetsuSenchouModal}
                          style={{ pointerEvents: 'auto' }}
                        >
                          溶接線長
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>溶接線長</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {detailsSenchou
                ?.filter((i) => i.isOutOfRange === false)
                ?.map((item, index) => (
                  <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    ～{item.value}mm
                  </td>
                ))}
            </tr>

            {Object.keys(senchouList)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <tr key={key} style={{ textAlign: 'center' }}>
                  <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                    {kouteiTypeList?.find((item) => item.id.toString() === key)?.name}
                  </td>
                  {senchouList[key]?.map((item, index) => (
                    <td key={index}>
                      <Input
                        value={item.value}
                        onChange={(e) => listDataChange(e.target.value, key, item.no)}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
          </Table>
        </Row>
      </div>

      {/* 溶接線長 */}
      {isYousetsuSenCho
        ? commonModal(
            isYousetsuSenCho,
            addYousetsuSenchoModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default MachineSpeed;
