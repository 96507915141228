import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input, Radio } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { MaterialSurfaceBillingUnit, ProcessDetailGroup, WorkTypeGroup } from '../common/enums';
import { ProcessDetailGroups, handleKeyPress, regexExp, toDecimal } from '../common/Common';

//全ての子部品の面積合計
const childAquareMeter = 150.25;
// 重量の場合、親部品の重量を設定
const weight = 8.681;
const IQ3KouteiInput_Hyoumenshori = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  const [hyoumenshoriValues, setHyoumenshoriValues] = useState([]);
  const [surfaceWorkType, setSurfaceWorkType] = useState([]);
  const [surfaceWorkTypeList, setSurfaceWorkTypeList] = useState([]);

  const [surfaceProcessTypeVal0Lst, setSurfaceProcessTypeVal0Lst] = useState([{ id: 0, name: 'ー' }]);
  const [surfaceProcessTypeVal1Lst, setSurfaceProcessTypeVal1Lst] = useState([]);
  const [surfaceProcessTypeVal2Lst, setSurfaceProcessTypeVal2Lst] = useState([]);
  const [surfaceProcessTypeVal3Lst, setSurfaceProcessTypeVal3Lst] = useState([]);

  const [processDetailsType, setProcessDetailsType] = useState([]);
  // 編集モード
  // const editMode = props.editMode;
  useEffect(() => {
    if (props.type === '親部品') {
      const surfaceDetailsData = props?.kouteiInputInfo?.surfaceProcessInputInfo;
      setHyoumenshoriValues(surfaceDetailsData);
    } else {
      let data = props?.surfaceInfo;
      if (data != undefined) {
        setHyoumenshoriValues(data?.details);
      }
    }

    setSurfaceWorkType(ProcessDetailGroups);

    const processDetailTypes = props?.surfaceData?.[0]?.details?.processDetailTypes || [];
    setProcessDetailsType(processDetailTypes);

    let processTypeVal1Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaWorkGeneral);
    setSurfaceProcessTypeVal1Lst(processTypeVal1Lst);

    const processTypeVal2Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaPlating);
    setSurfaceProcessTypeVal2Lst(processTypeVal2Lst);

    const processTypeVal3Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaCoating);
    setSurfaceProcessTypeVal3Lst(processTypeVal3Lst);
  }, [props.hyoumenShoriVal, props.surfaceData, props.kouteiInputInfo, props.type]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const addHyoumenshori = (id) => {
    const copyData = hyoumenshoriValues.slice(); // Create a copy of the original array

    const insertIndex = hyoumenshoriValues?.findIndex((item) => item.no === id); // Find the clicked index of the item
    const largestId = hyoumenshoriValues?.reduce((maxId, item) => {
      return item.no > maxId ? item.no : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        no: largestId + 1,
        calcType: 1,
        areaUnitPrice: 0,
        weightUnitPrice: 0,
        processDetailWorkTypeGroup: 0,
        processDetailTypeNo: 0,
        weightCount: 0,
        areaCount: 0,
        areaAutoExtract: hyoumenshoriValues?.[0]?.areaAutoExtract,
        weightAutoExtract: hyoumenshoriValues?.[0]?.weightAutoExtract,
        totalArea: 0,
        totalWeight: 0,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setHyoumenshoriValues(copyData);

      props.updatedSurfaceProcessInput(copyData);
    }
  };
  const deleteHyoumenshori = (id) => {
    if (hyoumenshoriValues?.length > 1) {
      const surfaceList = hyoumenshoriValues?.filter((j) => {
        return ![id]?.includes(j.no);
      });
      setHyoumenshoriValues(surfaceList);
      props.updatedSurfaceProcessInput(surfaceList);
    }
  };
  const changeDetails = (event, id, types, calMethod) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    let updatedData;
    if (types === 'calcType') {
      if (calMethod === 'area') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.SquareMeter };
          }
          return row;
        });
      } else if (calMethod === 'weight') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.Weight };
          }
          return row;
        });
      }
    } else {
      updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [types]: !regexExp.test(event.target.value) ? event.target.value : 0 };
          if (types === 'areaUnitPrice' || types === 'areaCount') {
            // Calculate and update totalCount
            updatedRow.totalArea = toDecimal(toDecimal(updatedRow?.areaUnitPrice, 2) * updatedRow?.areaCount, 2);
          } else if (types === 'weightUnitPrice' || types === 'weightCount') {
            // Calculate and update totalCount
            updatedRow.totalWeight = toDecimal(toDecimal(updatedRow?.weightUnitPrice, 2) * updatedRow?.weightCount, 2);
          }

          return updatedRow;
        }
        return row;
      });
    }
    setHyoumenshoriValues(updatedData);
    if (props.type === '親部品') {
      props.updatedSurfaceProcessInput(updatedData);
    } else {
      const newSurfaceInfo = { ...props.surfaceInfo };
      newSurfaceInfo.details = updatedData;
      props.updateSurface(newSurfaceInfo);
    }
  };
  const blurDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    let updatedData;

    updatedData = temp?.map((row) => {
      if (row.no === id) {
        const updatedRow = { ...row, [types]: toDecimal(event.target.value, 2) };

        return updatedRow;
      }
      return row;
    });

    setHyoumenshoriValues(updatedData);
    if (props.type === '親部品') {
      props.updatedSurfaceProcessInput(updatedData);
    } else {
      const newSurfaceInfo = { ...props.surfaceInfo };
      newSurfaceInfo.details = updatedData;
      props.updateSurface(newSurfaceInfo);
    }
  };
  return (
    <>
      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '87%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '1%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '17.4%' }} className="tbl-header">
                計算方法
              </th>
              <th style={{ width: '19.3%' }} colSpan={2} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '19.3%' }} className="tbl-header">
                面積/重量
              </th>
              {/* <th style={{ width: '13%', display: 'none' }} className="tbl-header">
                自動抽出
              </th> */}
              <th style={{ width: '19.3%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>
            {hyoumenshoriValues?.map((i, index) => (
              <>
                <tr key={index} style={{ verticalAlign: 'middle' }}>
                  <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {index + 1}
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', pointerEvents: 'none' }}>
                    <Radio
                      checked={
                        i.calcType === MaterialSurfaceBillingUnit.SquareMeter ||
                        i.calcType === MaterialSurfaceBillingUnit.Work
                      } // IQX_WEBEST-226
                      // onChange={(e) => changeDetails(e, i.no, 'calcType', 'area')}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    >
                      面積
                    </Radio>
                  </td>
                  <td rowSpan={2} style={{ verticalAlign: 'middle', width: '9.5%' }}>
                    <label>
                      {surfaceWorkType?.filter((item) => item.value === i.processDetailWorkTypeGroup)?.[0]?.label}
                    </label>
                  </td>

                  <td rowSpan={2} style={{ width: '9.5%' }}>
                    <label>{processDetailsType?.filter((item) => item.id === i.processDetailTypeNo)?.[0]?.name}</label>
                  </td>
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.areaAutoExtract}</label>
                    <Input
                      className="input-editable"
                      // value={toDecimal(i.areaUnitPrice, 2)}
                      value={i.areaUnitPrice}
                      onChange={(e) => changeDetails(e, i.no, 'areaUnitPrice')}
                      onBlur={(e) => blurDetails(e, i.no, 'areaUnitPrice')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.areaAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'areaAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.areaCountAutoExtract}</label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.areaCount}
                      onChange={(e) => changeDetails(e, i.no, 'areaCount')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <label style={{ marginRight: '5px' }}>{toDecimal(i.totalArea, 2)}</label>
                  </td>
                </tr>
                <tr style={{ verticalAlign: 'middle' }}>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center', pointerEvents: 'none' }}>
                    {/* <Radio checked={false}>重量</Radio> */}
                    <Radio
                      checked={i.calcType === MaterialSurfaceBillingUnit.Weight}
                      // onChange={(e) => changeDetails(e, i.no, 'calcType', 'weight')}
                    >
                      重量
                    </Radio>
                  </td>

                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>{i.weightAutoExtract}</label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.weightUnitPrice}
                      onChange={(e) => changeDetails(e, i.no, 'weightUnitPrice')}
                      onBlur={(e) => blurDetails(e, i.no, 'weightUnitPrice')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.weightAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'weightAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                  <td>
                    {/** IQX_WEBEST-179 */}
                    <label style={{ marginLeft: 0, width: '35%', textAlign: 'center' }}>
                      {i.weightCountAutoExtract}
                    </label>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.weightCount}
                      onChange={(e) => changeDetails(e, i.no, 'weightCount')}
                      style={{ height: 32, textAlign: 'center', width: '58%', marginLeft: 9 }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <label style={{ marginRight: '5px' }}>{toDecimal(i.totalWeight, 2)}</label>
                    {/* <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.totalWeight}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input> */}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Hyoumenshori;
