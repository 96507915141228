/**
 * クラス名：装置設定の生産個数係数画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  defaultQuantiyData,
  deleteListColData,
  getInputBorderStyle,
  handleKeyPress,
  regexExp,
  sortListData,
  toDecimal,
  toHalfWidth,
  updateListColData,
} from '../../../common/Common';

const Quantity = forwardRef((props, ref) => {
  const [kosuuMstData, setKosuuMstData] = useState([]);
  const [kosuuDataTemp, setKosuuDataTemp] = useState([]);
  const [kosuuList, setKosuuList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isKosuu, setIsKosuu] = useState(false);
  // 入力チェック
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');

    if (props.detailsData != undefined) {
      if (props.detailsData?.productionQtyCoeffThItems) {
        setKosuuList(props.detailsData?.productionQtyCoeffThItems);
        // const updatedLists = props.detailsData?.productionQtyCoeffThItems?.map((item) => ({
        //   ...item,
        //   value: toDecimal(item.value, 2),
        // }));
        // setKosuuList(updatedLists);
      }

      if (props.detailsData?.productionQtyCoeffThMaster) setKosuuMstData(props.detailsData?.productionQtyCoeffThMaster);
      if (
        props.detailsData?.productionQtyCoeffThMaster &&
        [...props.detailsData?.productionQtyCoeffThMaster]?.length > 0
      ) {
        setKosuuDataTemp(props.detailsData?.productionQtyCoeffThMaster);
      } else {
        setKosuuDataTemp(defaultQuantiyData);
      }
    }
    setEditMode(props.editMode);
  }, [props.detailsData, props.editMode]);

  // 個数ボタン押下
  const callKosuuMstData = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsKosuu(true);
  };

  // 個数マスタ一覧のヘッダー
  const addKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>個数</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  // 個数マスタ一覧の値を入力された場合、
  const handleKosuuChange = (event, id, field, index) => {
    const temp = JSON.parse(JSON.stringify(kosuuDataTemp));
    const updatedData = temp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });

    setKosuuDataTemp(updatedData);
  };

  const isValueOrderCorrect = (e, no, field) => {
    const temp = kosuuDataTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    // IQX_WEBEST-220の対応-START
    if (regexExp.test(temp[insertIndex].value)) {
      // 全角の場合、
      temp[insertIndex].value = toHalfWidth(temp[insertIndex].value); // 半角へ変換
      e.target.value = temp[insertIndex].value;
      handleKosuuChange(e, no, field);
    }
    if (isNaN(parseFloat(temp[insertIndex].value))) {
      setErrIndexVal([...errIndexVal, insertIndex]);
      setErrorMessage('数字値を入力してください！');
      return;
    }
    // IQX_WEBEST-220の対応-END

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // 個数マスタ一覧の追加ボタン押下
  const addKosuu = (no, index) => {
    inputRef.current[index].focus();
    const copyData = kosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = kosuuDataTemp?.findIndex((item) => item.no === no); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: kosuuDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setKosuuDataTemp(copyData);
    }
  };
  // const getInputBorderStyle = (index) => {
  //   const item = kosuuDataTemp?.filter((item) => !item.isOutOfRange)[index];

  //   if (item.value === 0 && item.isOutOfRange === false) {
  //     return { border: '1px solid red' };
  //   } else {
  //     return { border: '' };
  //   }
  // };

  // 個数マスタ一覧の削除ボタン押下
  const deleteKosuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    const listTemp = JSON.parse(JSON.stringify(kosuuDataTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });

      setKosuuDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 個数マスタ一覧のOKボタン押下
  const okKosuuMasterData = () => {
    const deletedId = [];

    kosuuMstData?.forEach((item1, index) => {
      const found = kosuuDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = kosuuDataTemp
      ?.filter((item) => !item.isOutOfRange && !kosuuMstData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteSeisanKosuuList = deleteListColData(kosuuList, deletedId);

    const updateSeisanKosuuList = updateListColData(deleteSeisanKosuuList, newId);

    const sortSeisanKosuuList = sortListData(updateSeisanKosuuList, kosuuDataTemp);

    setIsKosuu(false);
    setKosuuDataTemp(kosuuDataTemp);
    props.updatedKosuu(kosuuDataTemp);

    setKosuuList(sortSeisanKosuuList);
    props.updatedKosuuList(sortSeisanKosuuList);
  };

  // 個数マスタ一覧のCancelボタン押下
  const cancelMasterData = () => {
    setKosuuDataTemp(kosuuMstData);
    setIsKosuu(false);
  };

  // 個数マスタ一覧の「X」ボタン押下
  const closeAddModal = () => {
    if (isKosuu === true) {
      setIsKosuu(false);
    }
  };

  // 個数マスタ一覧
  const addKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {kosuuDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        {/* {errIndexVal} */}
                        <Input
                          value={item.value}
                          onChange={(e) => handleKosuuChange(e, item.no, 'value')}
                          onBlur={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          onPressEnter={(e) => isValueOrderCorrect(e, item.no, 'value')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, kosuuDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          id={'kosuu' + index + 1}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addKosuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteKosuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okKosuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 生産個数係数を入力された場合、
  const changeKosuu = (event, no) => {
    const newValue = event.target.value;
    kosuuList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = newValue;
      });
    setKosuuList(kosuuList);
    props.updatedKosuuList(kosuuList);
  };
  // 生産個数係数を’０’入力された場合、
  const changeKosuuOnBlur = (event, no) => {
    kosuuList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = parseFloat(event.target.value) > 0 ? event.target.value : 1;
      });
    setKosuuList(kosuuList);
    props.updatedKosuuList(kosuuList);
  };

  return (
    <>
      <div className="qty-table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              kosuuMstData?.filter((i) => i.isOutOfRange === false)?.length > 7
                ? '100%'
                : kosuuMstData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                {editMode ? (
                  <>
                    <Button className="cancleButton" onClick={callKosuuMstData} style={{ pointerEvents: 'auto' }}>
                      個数
                    </Button>
                  </>
                ) : (
                  <>
                    <label>個数</label>
                  </>
                )}
              </Row>
            </td>

            {kosuuMstData
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  {/* {kosuuMstData[kosuuMstData?.length - 1].no === item.no ? (
                    <>{item.value}個 ～</>
                  ) : (
                    <>～{item.value}個</>
                  )} */}
                  ～{item.value}個
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {'生産個数係数'}
            </td>
            {kosuuList?.map((row, index) => (
              <td key={index} style={{ width: '98px' }}>
                <Input
                  value={editMode ? row.value : toDecimal(row.value, 2)}
                  onChange={(e) => changeKosuu(e, row.no)}
                  onBlur={(e) => changeKosuuOnBlur(e, row.no)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            ))}
          </tr>
        </Table>
      </div>
      {/* 個数 */}
      {isKosuu
        ? commonModal(
            isKosuu,
            addKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addKosuuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Quantity;
