/**
 * クラス名：お客様情報選択ダイアログ
 * 説明：お客様情報選択ダイアログであり、共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Input, Row, Table, Button, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';

import { commonModal } from '../common/CommonModal';
import { getClientInfo } from '../common/CommonAPI';
import { ClientType } from '../common/enums';
import { ResizableTitle, isContractor, isCustomer, isSupplier } from '../common/Common';

const SearchAndGetCustomerData = React.forwardRef((props, ref) => {
  const [selectedCustomerRowKeys, setSelectedCustomerRowKeys] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState();
  const [initialCustomerData, setInitialCustomerData] = useState([]); // 初期表示に表示する項目
  const [initialCustomerDataBk, setInitialCustomerDataBk] = useState([]);
  const [customerModal, setCustomerModal] = useState(props.customerModal);
  //　テーブル列Resizable用変数
  const [nameWidth, setNameWidth] = useState(150);
  const [prefectureWidth, setPrefectureWidth] = useState(150);
  const [addressWidth, setAddressWidth] = useState(150);

  // 検索項目
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [tantou, setTantou] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [address, setAddress] = useState('');

  const token = localStorage?.getItem('iQxWeb_AccessToken');
  // 画面の初期設定
  useEffect(() => {
    getClientInfoAPI();
    setCustomerModal(props.customerModal);
  }, [props.customerModal]);

  // 選択中の情報
  useEffect(() => {
    if (selectedCustomerData === undefined) return;
    let data = JSON.parse(JSON.stringify(selectedCustomerData));
    setName(data?.name);
    setTantou(data?.info?.contactParson);
    setTel(data?.info?.tel);
    setPrefecture(data?.info?.prefecture);
    setAddress(data?.info?.address);
  }, [selectedCustomerData]);

  const getClientInfoAPI = async () => {
    let result = null;
    if (props.selectedEstimateData == undefined || props.selectedEstimateData?.id == 0) {
      result = await getClientInfo();
    } else {
      result = props.selectedEstimateData.calcParameters.parameters.client;
    }

    if (props.clientType === ClientType.Customer) {
      result = result?.filter((record) => isCustomer(record.clientType) && record.info.isUsed === true);
    } else if (props.clientType === ClientType.Supplier) {
      result = result?.filter((record) => isSupplier(record.clientType) && record.info.isUsed === true);
    } else if (props.clientType === ClientType.Contractor) {
      result = result?.filter((record) => isContractor(record.clientType) && record.info.isUsed === true);
    }
    setInitialCustomerData(result);
    setInitialCustomerDataBk(result);
    setSelectedCustomerRowKeys(props?.selectedEstimateData?.clientId);
    // setSelectedCustomerRowKeys(record.id);
    let record = result?.find((item) => item.id === props?.selectedEstimateData?.clientId);
    setSelectedCustomerData(record);
    // setClientId(basicInfo.clientId);
    // setClientName(basicInfo.clientName);
  };

  const addCustomerData = () => {
    setSelectedCustomerRowKeys('1');
  };

  const closeCustomerModal = () => {
    setCustomerModal(false);
    props?.closeCustomerModal();
  };

  const onCustomerRowSelect = (record) => {
    setSelectedCustomerRowKeys(record.id);
    setSelectedCustomerData(record);
  };
  const getCustomerData = () => {
    props.selectedCustomerData(selectedCustomerData);
  };

  const filterCustomer = (e, name) => {
    let customerData = initialCustomerDataBk;

    if (name === 'companyname') {
      if (e.target.value) {
        const deviceFilter = customerData?.filter((item) => {
          return item.name?.includes(e.target.value);
        });

        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tantou') {
      if (e.target.value) {
        const deviceFilter = customerData?.filter((item) => {
          return item.info.contactParson?.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'tel') {
      if (e.target.value) {
        const deviceFilter = customerData?.filter((item) => {
          return item.info.tel?.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'prefecture') {
      if (e.target.value) {
        const deviceFilter = customerData?.filter((item) => {
          return item.info.prefecture?.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    } else if (name === 'address') {
      if (e.target.value) {
        const deviceFilter = customerData?.filter((item) => {
          return item.info.address?.includes(e.target.value);
        });
        setInitialCustomerData(deviceFilter);
      } else {
        setInitialCustomerData(initialCustomerDataBk);
      }
    }
  };

  const columns = [
    {
      id: '1',
      title: '名称',
      dataIndex: 'name',
      width: nameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          setNameWidth(size.width);
        },
      }),
    },
    {
      id: '2',
      title: 'Tel',
      dataIndex: 'info',
      width: 125,
      render: (info) => info.tel,
    },
    {
      id: '3',
      title: '担当者',
      dataIndex: 'info',
      width: 150,
      render: (info) => info.contactParson,
    },
    {
      id: '4',
      title: '都道府県',
      dataIndex: 'info',
      width: prefectureWidth,
      render: (info) => info.prefecture,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          setPrefectureWidth(size.width);
        },
      }),
    },
    {
      id: '5',
      title: '住所',
      dataIndex: 'info',
      width: addressWidth,
      render: (info) => info.address,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          setAddressWidth(size.width);
        },
      }),
    },
    // {
    //   id: '6',
    //   title: 'No',
    //   dataIndex: 'no',
    //   hidden: true,
    // },
  ];

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };
  const tableColumns = columns?.map((item) => ({
    ...item,
  }));
  const result = tableColumns?.map((column, index) => {
    if (column.title === '名称') {
      return {
        ...column,
        title: (
          <div key={index} style={{ textAlign: 'center' }}>
            <div>名称</div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              onPressEnter={(e) => filterCustomer(e, 'companyname')}
            ></Input>
          </div>
        ),
      };
    } else if (column.title === 'Tel') {
      return {
        ...column,
        title: (
          <div key={index} style={{ textAlign: 'center' }}>
            <div>Tel</div>
            <Input
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              key={index}
              onPressEnter={(e) => filterCustomer(e, 'tel')}
            ></Input>
          </div>
        ),
      };
    } else if (column.title === '担当者') {
      return {
        ...column,
        title: (
          <div key={index} style={{ textAlign: 'center' }}>
            <div>担当者</div>
            <Input
              value={tantou}
              onChange={(e) => setTantou(e.target.value)}
              key={index}
              onPressEnter={(e) => filterCustomer(e, 'tantou')}
            ></Input>
          </div>
        ),
      };
    } else if (column.title === '都道府県') {
      return {
        ...column,
        title: (
          <div key={index} style={{ textAlign: 'center' }}>
            <div>都道府県</div>
            <Input
              value={prefecture}
              onChange={(e) => setPrefecture(e.target.value)}
              onPressEnter={(e) => filterCustomer(e, 'prefecture')}
            ></Input>
          </div>
        ),
      };
    } else if (column.title === '住所') {
      return {
        ...column,
        title: (
          <div key={index} style={{ textAlign: 'center' }}>
            <div>住所</div>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onPressEnter={(e) => filterCustomer(e, 'address')}
            ></Input>
          </div>
        ),
      };
    }
    return column;
  });

  // タイトル
  const addCustomerModalTitle = (
    <div
      style={{
        width: 900,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{props.title}</p>
    </div>
  );
  /* 取引先情報一覧 */
  const addCustomerContent = (
    <>
      <Row>
        <Table
          className="tblCustomer"
          components={components}
          columns={result}
          dataSource={initialCustomerData}
          rowClassName={(record) => (record.id == selectedCustomerRowKeys ? 'active-row' : 'data-row')}
          scroll={{ y: 590, x: '10vw' }}
          pagination={{
            position: ['bottomLeft'],
            total: (record) => record?.length,
            showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
            defaultPageSize: 25, // 本番値確認必要
            defaultCurrent: 1,
          }}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                onCustomerRowSelect(record);
              },
            };
          }}
        ></Table>
      </Row>
      <Row justify="end" style={{ marginTop: -30 }}>
        <Button className="mainButton" onClick={getCustomerData}>
          OK
        </Button>
      </Row>
    </>
  );

  {
    /* 取引先検索 */
  }
  return (
    <>
      {customerModal
        ? commonModal(
            customerModal,
            addCustomerModalTitle,
            null,
            null,
            900,
            addCustomerData,
            closeCustomerModal,
            addCustomerContent,
            null,
            { left: 180, top: 50 },
            false
          )
        : ''}
    </>
  );
});

export default SearchAndGetCustomerData;
