import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  ProcessDetailGroups,
  toDecimal,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ProcessDetailGroup, ServiceClass, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton, helpCmt } from '../common/Constant';

const zeroArr = [
  {
    id: 0,
    processName: '',
    processType: '',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    formula: '段階時間チャージ x 段階時間 x 係数',
    subDiameter: 0,
    subCount: 0,
    subTime: 0,
    subFinish: '',
    inputData: 0,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
    naibukensaAmt: 0,
    naibukensaTime: '00:00:00',
  },
];

const IQ3KouteiCalculate_Surface = forwardRef((props, ref) => {
  const [isSurfacedetail, setISurfacedetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalSagyoAmt, setTotalSagyoAmt] = useState(0);
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState(0);
  const [totalDandoriTime, setTotalDandoriTime] = useState(0);

  const [totalSum, setTotalSum] = useState(0);
  const [totalTimeSum, setTotalTimeSum] = useState(0);
  const [sagyoAmtSum, setSagyoAmtSum] = useState(0);
  const [sagyoTimeSum, setSagyoTimeSum] = useState(0);
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState(0);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();

  const [handelingAmt, setHandelingAmt] = useState();
  const [handelingTime, setHandelingTime] = useState();
  const [kensaAmt, setKensaAmt] = useState();
  const [kensaTime, setKensaTime] = useState();

  const [subSurfacesArr, setSubSurfaceArr] = useState([]);
  const [surfacesArr, setSurfaceArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [surfaceAmt, setSurfaceAmt] = useState(0);
  const [inputData, setInputData] = useState([]);
  const [surfaceAdditionsData, setSurfaceAdditionsData] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setSurfaceAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmSurface);
  };

  const settingEditData = (arr, propsOrgData) => {
    let editDataArr = [];

    for (let i = 0; i < arr?.length; i++) {
      let subArr = arr[i];
      editDataArr.push({
        id: i,
        dandoriAmt: subArr.dandoriAmt,
        dandoriTime: subArr.dandoriTime,
        sagyoAmt: subArr.sagyoAmt,
        sagyoTime: subArr.sagyoTime,
        processDetailTypeNo: propsOrgData[i]?.processDetailTypeNo,
        sumAmt: subArr.dandoriAmt + subArr.sagyoAmt,
        sumTime: subArr.dandoriTime + subArr.sagyoTime,
        calMethod: subArr.calMethod,
        calInput: subArr.calInput,
        processDetailWorkTypeGroup: propsOrgData[i]?.processDetailWorkTypeGroup,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.round(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    setDandoriAmtSum(0);
    setDandoriTimeSum(0);
    setSagyoAmtSum(0);
    setTotalTimeSum(0);
    setTotalSum(0);
    setTotalAmt(0);
    setTotalTime(0);
    setTotalSagyoAmt(0);
    setTotalSagyoTime(0);
    setTotalDandoriAmt(0);
    setTotalDandoriTime(0);
    setSurfaceAmt(0);

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      // processMst = parameters?.process?.filter((item) => item?.class === ServiceClass.SheetMetal);
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSurface
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);
    let iq3SurfaceAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    setSurfaceAdditionsData(iq3SurfaceAdditions);
    // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
    let iq3SurfaceAdditionsEditData = iq3SurfaceAdditions?.editItems;
    let editDataFlg = iq3SurfaceAdditions?.editDataFlg;
    // 表面工程入力情報取得
    let surfaceProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    setInputData(surfaceProcessInput);

    let detailItems = [];
    let editDetailItems = [];
    for (let j = 0; j < surfaceProcessInput?.details?.length; j++) {
      let calType = '面積';
      if (surfaceProcessInput?.details[j].calcType == MaterialSurfaceBillingUnit.SquareMeter) {
        calType = '面積';
      } else if (surfaceProcessInput?.details[j].calcType == MaterialSurfaceBillingUnit.Work) {
        calType = '時間';
      } else if (surfaceProcessInput?.details[j].calcType == MaterialSurfaceBillingUnit.Weight) {
        calType = '重量';
      }
      detailItems.push({
        id: j + 1,
        processName: '表面処理(No' + (j + 1) + ')',
        processDetailTypeNo: surfaceProcessInput?.details[j].processDetailTypeNo,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        calMethod: calType,
        calInput:
          surfaceProcessInput?.details[j].calcType == MaterialSurfaceBillingUnit.SquareMeter ||
          surfaceProcessInput?.details[j].calcType == MaterialSurfaceBillingUnit.Work
            ? Number(surfaceProcessInput?.details[j].areaUnitPrice)
            : Number(surfaceProcessInput?.details[j].weightUnitPrice),
        processDetailWorkTypeGroup: surfaceProcessInput?.details[j].processDetailWorkTypeGroup,
        formula: '',
      });
    }

    editDetailItems = [];
    let iq3SurfaceAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: iq3SurfaceAdditions?.dataType ? iq3SurfaceAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: iq3SurfaceAdditions?.editItems ? iq3SurfaceAdditions.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: editDataFlg,
    };
    iq3SurfaceAdditions = iq3SurfaceAddition;

    //データ設定
    if (iq3SurfaceAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(iq3SurfaceAdditions?.dataItems));
      setOrgData(JSON.parse(JSON.stringify(iq3SurfaceAdditions?.dataItems)));

      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalDandoriTm = 0;
        let totalSagyoTm = 0;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;

        // 面積
        let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
        for (let i = 0; i < orgData?.length; i++) {
          let hyoumenInfo = orgData[i];
          /*  let detailProcessInput = surfaceProcessInput?.details?.filter(
            (item) =>
              item.processDetailTypeNo == hyoumenInfo.processDetailTypeNo &&
              item.processDetailWorkTypeGroup == hyoumenInfo.processDetailWorkTypeGroup
          )[0]; */
          let detailProcessInput = surfaceProcessInput?.details?.[i];
          let calInput =
            detailProcessInput.calcType == MaterialSurfaceBillingUnit.SquareMeter
              ? detailProcessInput.areaUnitPrice
              : detailProcessInput.weightUnitPrice;
          calInput = Number(calInput);
          orgData[i].calInput = calInput ? calInput : 0;
          iq3SurfaceAdditions.dataItems[i].calInput = calInput;

          let calMethod = hyoumenInfo?.calMethod; // チャージ金額が時間ではなく面積にチャージ金額を使用している対応
          orgData[i].calMethod = calMethod;
          iq3SurfaceAdditions.dataItems[i].calMethod = calMethod;
          let processType = parseInt(hyoumenInfo.processDetailTypeNo);

          // 処理選択名称設定
          orgData[i].processDetailWorkTypeGroup = ProcessDetailGroups?.filter(
            (item) => item.value == orgData[i].processDetailWorkTypeGroup
          )[0].label;

          orgData[i].processDetailTypeNo = processMst.details?.processDetailTypes?.filter(
            (item) => item.id == processType
          )[0]?.name;

          //表面/重量が0の場合、段取と加工を計算しない。
          if (calInput === 0) continue;

          /** 段取金額/時間 */
          // 段取時間設定（基本段取時間	＊	サイズ係数）
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, processType);
          iq3SurfaceAdditions.dataItems[i].dandoriTime = dandoriTimeSec;
          let dandoriTime = dandoriTimeSec;
          orgData[i].dandoriTime = dandoriTime ? dandoriTime : 0;
          totalDandoriTm += dandoriTime;
          // 段取金額設定（段取時間＊時間チャージ）
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst, processType);
          dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          orgData[i].dandoriAmt = dandoriAmt;
          iq3SurfaceAdditions.dataItems[i].dandoriAmt = dandoriAmt;
          totalDandori += dandoriAmt;

          /** 　加工金額/時間　*/
          // IQX_WEBEST-65
          let shoriDetail =
            ProcessDetailGroup.DoubleSide == iq3SurfaceAdditions?.dataItems[i]?.processDetailWorkTypeGroup ? 2 : 1;
          // 加工時間設定（加工時間	＊	重量係数）
          let sagyoTime = 0;
          if (calMethod === '面積' || calMethod === '時間') {
            let sagyoTimeSec = calSagyoTime(calInput, deviceMst, weight, processType);
            sagyoTimeSec = sagyoTimeSec ? sagyoTimeSec : 0;
            iq3SurfaceAdditions.dataItems[i].sagyoTime = sagyoTimeSec;
            sagyoTime = sagyoTimeSec;
          }
          orgData[i].sagyoTime = sagyoTime;
          totalSagyoTm += sagyoTime;
          // 加工金額設定
          //装置の面積・重量時間チャージ // IQX_WEBEST-226
          let mWorkHRate = 0;
          let sagyoAmt = 0;
          if (calMethod == '面積' || calMethod == '時間') {
            sagyoAmt = calSagyoAmt(sagyoTime, deviceMst, calMethod, shoriDetail, processType);
          } else if (calMethod == '重量') {
            mWorkHRate = deviceMst?.details?.charges[processType]?.weight;
            sagyoAmt = calInput * mWorkHRate * shoriDetail; //重量＊重量チャージ
          }
          orgData[i].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          iq3SurfaceAdditions.dataItems[i].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          totalSagyo += sagyoAmt;
        }
        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        iq3SurfaceAdditions.totalDandori.dataItemsAmt = totalDandori;
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        iq3SurfaceAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);
        iq3SurfaceAdditions.totalSagyo.dataItemsAmt = totalSagyo;
        // 作業時間の合計
        setSagyoTimeSum(totalSagyoTm);
        iq3SurfaceAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        setTotalTimeSum(totalTimeSum);
        iq3SurfaceAdditions.totalDataTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        iq3SurfaceAdditions.totalDataPrice = totalSum;
      }
      setProcessArr(orgData);

      // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
      if (iq3SurfaceAdditionsEditData?.length > 0 && editDataFlg) {
        toBEdit = JSON.parse(JSON.stringify(iq3SurfaceAdditionsEditData));
      } else {
        toBEdit = JSON.parse(JSON.stringify(orgData));
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit != undefined ? settingEditData(toBEdit, iq3SurfaceAdditions.dataItems) : [];
      iq3SurfaceAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        setOldEditedData(editData);

        totals = calculateTotals(editData);
        setTotalAmt(totals[0]?.totalSum);
        iq3SurfaceAdditions.totalEditPrice = totals[0]?.totalSum;
        iq3SurfaceAdditions.totalDandori.editItemsAmt = totals[0]?.dandoriAmtSum;
        iq3SurfaceAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;
        setTotalTime(totals[0]?.totalTimeSum);
        iq3SurfaceAdditions.totalEditTime = totals[0]?.totalTimeSum;
        iq3SurfaceAdditions.totalDandori.editItemsTime = totals[0]?.dandoriTimeSum;
        iq3SurfaceAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);
        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(iq3SurfaceAdditions);
    }
    if (iq3SurfaceAdditions?.dataType == DataType.Data) {
      setSurfaceAmt(iq3SurfaceAdditions.totalDataPrice);
    } else {
      setSurfaceAmt(iq3SurfaceAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(iq3SurfaceAdditions, WorkType.SmSurface);
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const surface = () => {
    if (isSurfacedetail == true) {
      setISurfacedetail(false);
    } else {
      setISurfacedetail(true);
    }
  };

  const getSurfaceTypes = () => {
    let subSurfaces = [];
    let surfaces = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subSurfaces.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              surfaces.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubSurfaceArr(subSurfaces);
    setSurfaceArr(surfaces);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('surfaceProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('surfaceProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownSurfaceIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpSurfaceIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('surfaceProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownSurfaceIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpSurfaceIQ3' + i).style.display = 'block';
    }
  };

  const calSagyoTime = (area, deviceMst, weight, processType) => {
    // 作業時間
    // 重量係数
    let prepCoeffThWeights = deviceMst?.details?.prodWeightCoeffThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    let prepCoeffThWeightNo =
      prepCoeffThWeights?.length > 0
        ? prepCoeffThWeights?.[0]?.no
        : deviceMst?.details?.prodWeightCoeffThMaster?.[0]?.no;
    let prepCoeffThWeight =
      prepCoeffThWeightNo == 0
        ? 0
        : deviceMst?.details?.prodWeightCoeffThItems?.filter(
            (item) => item.no == prepCoeffThWeightNo && !item.isOutOfRange
          )[0]?.value;
    // 加工時間
    let sagyoTime = 0;
    let areaThMaster = deviceMst?.details?.areaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let workTimeThItems = [];
    if (deviceMst?.details?.workTimeThItems) {
      workTimeThItems = Object.entries(deviceMst?.details?.workTimeThItems)?.filter(
        ([key, data]) => parseInt(key) === processType
      )?.[0];
    }
    workTimeThItems?.map((item, index) => {
      if (index > 0) {
        sagyoTime = item?.filter(
          (item) => (item.no == areaThMaster?.[0]?.no || item.no > areaThMaster?.[0]?.no) && !item.isOutOfRange
        )?.[0]?.value;
      }
    });
    sagyoTime = sagyoTime ? sagyoTime : 0;
    // 作業時間＊重量係数
    let cSagyoTimeSec = sagyoTime * prepCoeffThWeight;
    cSagyoTimeSec = cSagyoTimeSec ? cSagyoTimeSec : 0;
    return cSagyoTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeSec, deviceMst, calMethod, shoriDetail, processType) => {
    //装置の作業時間チャージ
    let mWorkHRate = 0;
    if (calMethod == '面積') {
      mWorkHRate = deviceMst?.details?.charges[processType]?.squareMeter;
    } else if (calMethod == '時間') {
      mWorkHRate = deviceMst?.details?.charges[processType]?.work;
    }
    // 作業時間
    let mWorkHTime = cSagyoTimeSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //　加工時間＊時間チャージ
    let cSagyoAmt = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmt = Number.isFinite(cSagyoAmt) ? cSagyoAmt * shoriDetail : 0;
    return cSagyoAmt;
  };

  const calDandoriTime = (deviceMst, weight, area, processType) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster?.[0]?.no)
        : deviceMst?.details?.childPartsCoeff?.[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0].no && !item?.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepSecs[processType] * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst, processType) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges?.[processType]?.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const toTwoDecimal = (j, param) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    newState[j][param] = toDecimal(editedData[j]?.[param], 2);
    setEditedData(newState);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = () => {
    updateEditedData.editItems = JSON.parse(JSON.stringify(updateEditedData?.dataItems));
    updateEditedData.editDataFlg = false;
    setEditedData(JSON.parse(JSON.stringify(updateEditedData?.dataItems)));
    setTotalDandoriAmt(dandoriAmtSum ? dandoriAmtSum : 0);
    setTotalDandoriTime(dandoriTimeSum ? dandoriTimeSum : 0);
    setTotalSagyoAmt(sagyoAmtSum ? sagyoAmtSum : 0);
    setTotalSagyoTime(sagyoTimeSum ? sagyoTimeSum : 0);
    setTotalAmt(totalSum ? totalSum : 0);
    setTotalTime(totalTimeSum ? totalTimeSum : 0);
    updateEditedData.totalEditPrice = totalSum ? totalSum : 0;
    updateEditedData.totalEditTime = totalTimeSum ? totalTimeSum : 0;
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    if (updateEditedData.dataType == DataType.Data) {
      setSurfaceAmt(updateEditedData.totalDataPrice);
    } else {
      setSurfaceAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmSurface);
  };

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    updateEditedData.editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    if (value === undefined) {
      value = 0;
    } else {
      if (objId !== 'calInput') {
        value = value ? Number(value) : 0;
      }
    }
    let oldValue = editedData[index][objId];

    // if (objId === 'calInput') {
    //   newState[index][objId] = value;
    // } else {
    //   newState[index][objId] = Number(value);
    // }

    if (objId === 'dandoriTime') {
      newState[index][objId] = value;
      if (document.getElementById(index + 'SurfaceDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'SurfaceDandoriAmtCu').style.display;
        if (showHideCu === 'none') {
          if (value === 0) {
            newState[index].dandoriAmt = 0;
          } else if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(
              newState[index].dandoriAmt,
              timeType,
              timeAct,
              value,
              orgData[index].processDetailTypeNo
            );
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(
              newState[index].dandoriAmt,
              timeType,
              timeAct,
              value,
              orgData[index].processDetailTypeNo
            );
          }
          // checkInputWithColor(false, '', index + 'dandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
      updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
      updateEditedData.editItems[index].dandoriTime = value;
    } else if (objId === 'calInput') {
      newState[index][objId] = value;
      if (document.getElementById(index + 'HyomenSagyoAmtCu')?.style?.display !== undefined) {
        let showHideCu = document.getElementById(index + 'HyomenSagyoAmtCu')?.style?.display;
        if (showHideCu === 'none') {
          /** 　加工金額/時間　*/
          let calMethod = newState[index].calMethod;
          // IQX_WEBEST-65
          let shori = newState[index].processDetailWorkTypeGroup;
          let shoriDetail = ProcessDetailGroup.DoubleSide == shori ? 2 : 1;
          let mWorkHRate = 0;
          let sagyoAmt = 0;
          if (calMethod === '面積' || calMethod === '時間') {
            // 基本情報の総重量
            let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
            let sagyoTimeSec = calSagyoTime(value, deviceMst, weight, orgData[index]?.processDetailTypeNo);
            sagyoTimeSec = sagyoTimeSec ? sagyoTimeSec : 0;
            newState[index]['sagyoTime'] = sagyoTimeSec;
            updateEditedData.editItems[index].sagyoTime = sagyoTimeSec;
            sagyoAmt = calSagyoAmt(
              sagyoTimeSec,
              deviceMst,
              calMethod,
              shoriDetail,
              orgData[index]?.processDetailTypeNo
            ); //加工時間＊時間チャージ
          } else {
            // 重量の場合
            mWorkHRate = deviceMst?.details?.charges[processType]?.weight;
            sagyoAmt = value * mWorkHRate * shoriDetail; //重量＊重量チャージ
          }
          // 加工金額設定
          sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          newState[index]['sagyoAmt'] = sagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
          updateEditedData.editItems[index].calInput = value;
        }
      }
    } else if (objId === 'dandoriAmt') {
      updateEditedData.editItems[index].dandoriAmt = value;
    } else if (objId === 'sagyoTime') {
      if (orgData[index]?.calMethod === '面積' || orgData[index]?.calMethod === '時間') {
        newState[index][objId] = value;
        if (document.getElementById(index + 'HyomenSagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'HyomenSagyoAmtCu').style.display;
          if (showHideCu === 'block') {
            //checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'red');
          } else {
            if (value === 0) {
              newState[index].sagyoAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newState[index].sagyoAmt = changeAmtwithSagyoTime(
                newState[index].sagyoAmt,
                timeType,
                timeAct,
                value,
                index
              );
            } else {
              newState[index].sagyoAmt = changeAmtwithSagyoTime(
                newState[index].sagyoAmt,
                timeType,
                timeAct,
                value,
                index
              );
            }
            // checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'green');
          }
        }
        updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
        updateEditedData.editItems[index].sagyoTime = value;
      }
    } else if (objId === 'sagyoAmt') {
      newState[index][objId] = value;
      updateEditedData.editItems[index].sagyoAmt = value;
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += element.sagyoAmt ? Number(element.sagyoAmt) : 0;
      newDandoriSum += element.dandoriAmt ? Number(element.dandoriAmt) : 0;
      newSagyoTimeSec += element.sagyoTime ? element.sagyoTime : 0;
      newDandoriTimeSec += element.dandoriTime ? element.dandoriTime : 0;
    });

    newSum = Number(newSagyoSum) + Number(newDandoriSum);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalDandori.editItemsTime = newTotalTimeSum;

    setEditedData(newState);
    setSurfaceAmt(
      updateEditedData.dataType == DataType.Data ? updateEditedData.totalDataPrice : updateEditedData.totalEditPrice
    );
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmSurface);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processDetailTypeNo) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let totalMin = totalSec / 60;
    let intAmt = checkStr(amt);

    //装置の段階時間チャージ
    let mSetTimeMin = totalSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges[processDetailTypeNo]?.prep;

    editedAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
    editedAmt = Number.isFinite(editedAmt) ? editedAmt : 0;
    return editedAmt;
  };

  const changeAmtwithSagyoTime = (amt, typeTime, action, timeVal, index) => {
    let editedAmt;
    let totalMin = Number.isFinite(Number(timeVal) / 60) ? Number(timeVal) / 60 : 0;
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      let mWorkHRate = 0;
      //装置の加工時間チャージ
      if (orgData[index]?.calMethod === '面積') {
        mWorkHRate = deviceMst?.details?.charges[orgData[index]?.processDetailTypeNo]?.squareMeter;
      } else if (orgData[index]?.calMethod === '時間') {
        mWorkHRate = deviceMst?.details?.charges[orgData[index]?.processDetailTypeNo]?.work;
      }
      editedAmt = Number.isFinite((totalMin * mWorkHRate) / 60.0) ? (totalMin * mWorkHRate) / 60.0 : 0; //加工時間＊時間チャージ
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={surface}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isSurfacedetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{surfaceAmt ? JPYs.format(Math.round(surfaceAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isSurfacedetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
              <Checkbox
                key={kouteiCalculateSelectedArr[1].key}
                value={kouteiCalculateSelectedArr[1].value}
                disabled={!props?.editMode}
                onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
                checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
                style={{ marginRight: 6 }}
              ></Checkbox>
              <label>{kouteiCalculateSelectedArr[1].value}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
              <Button onClick={settingDataItems} className="dataButton" id="dataBtn1">
                {DataButton}
              </Button>
            </div>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalAmtIn"
              className="resetColor"
              style={{ color: Math.round(totalAmt) === Math.round(totalSum) ? 'black' : '#4cc12f' }}
            >
              {totalAmt ? JPYs.format(Math.round(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalTimeIn"
              className="resetColor"
              style={{ color: totalTime === totalTimeSum ? 'black' : '#4cc12f' }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.procTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.procTotalTm}>時間</Popover>
          </Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('SurfaceDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpSurfaceDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownSurfaceDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.round(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriAmtIn"
              className="resetColor"
              style={{ color: Math.round(dandoriAmtSum) === Math.round(totalDandoriAmt) ? 'black' : '#4cc12f' }}
            >
              {totalDandoriAmt ? JPYs.format(Math.round(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriTimeIn"
              className="resetColor"
              style={{ color: dandoriTimeSum === totalDandoriTime ? 'black' : '#4cc12f' }}
            >
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.setupTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.setupTotalTm}>時間</Popover>
          </Col>
        </Row>
        <div id={'divSurfaceDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr?.length; j++) {
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processArr[j]?.processDetailTypeNo}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'SurfaceDandoriAmt'}>
                        {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                          ? JPYs.format(Math.round(processArr[j]?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'SurfaceDndoriTime'}>
                          {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'SurfaceDandoriAmtIn'}
                        value={editedData[j]?.dandoriAmt ? Math.round(editedData[j]?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            Math.round(processArr[j]?.dandoriAmt) === Math.round(editedData[j]?.dandoriAmt)
                              ? 'black'
                              : checkShowHideIcon(j + 'SurfaceDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[j]?.dandoriAmt != e) {
                            let value = e ? e : 0;
                            settingNewDandoriAmtInArr(value, j, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'SurfaceDandoriAmt', value, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'SurfaceDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                            id={j + 'SurfaceDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                  ? handleShowHide(
                                      Number(editedData[j]?.dandoriAmt),
                                      processArr[j]?.dandoriAmt,
                                      j + 'SurfaceDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 'SurfacedandoriAmt', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'SurfaceDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={2} className="formula-column formula-column-right-border">
                      <Popover content={helpCmt.setupAmt_p1}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column">
                      <Popover content={helpCmt.setupTm_p1}>時間</Popover>
                    </Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('SurfaceSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpSurfaceSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />

              <CaretDownOutlined id={'arrowDownSurfaceSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.round(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoAmtIn"
              className="resetColor"
              style={{ color: Math.round(totalSagyoAmt) === Math.round(sagyoAmtSum) ? 'black' : '#4cc12f' }}
            >
              {totalSagyoAmt ? JPYs.format(Math.round(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={2} className="formula-column formula-column-right-border">
            <Popover content={helpCmt.workTotalAmt}>金額</Popover>
          </Col>
          <Col span={2} className="formula-column">
            <Popover content={helpCmt.workTotalTm}>時間</Popover>
          </Col>
        </Row>
        <div id={'divSurfaceSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < editedData?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined id={'arrowUpSurfaceIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownSurfaceIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <label id={i + 'surfaceSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.round(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <label id={i + 'surfaceSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      {/* ¥ */}
                      <label id={i + 'surfaceSagyoAmt'}>
                        {editedData[i]?.sagyoAmt ? JPYs.format(Math.round(editedData[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <label id={i + 'surfaceSagyoTime'}>
                        {editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                    <Col
                      span={2}
                      className="formula-column formula-column-right-border"
                      onClick={(e) => subpartDetail(i)}
                    >
                      <Popover content={helpCmt.workAmt_p1}>金額</Popover>
                    </Col>
                    <Col span={2} className="formula-column" onClick={(e) => subpartDetail(i)}>
                      <Popover content={helpCmt.workTm_p1}>時間</Popover>
                    </Col>
                  </Row>
                  <div id={'surfaceProcessIQ3' + i} style={{ display: 'none' }}>
                    <div>
                      <div>
                        {/* 作業金額/時間 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <div style={{ display: 'flex' }}>
                              <span className="ddl_2_noImg" style={{ marginLeft: 45 }}>
                                作業金額/時間
                              </span>
                            </div>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'HyomenSagyoAmt'}>
                              {processArr[i]?.sagyoAmt && processArr[i]?.sagyoAmt > 0
                                ? JPYs.format(Math.round(processArr[i]?.sagyoAmt))
                                : amtEmptyStr}
                            </label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            {
                              <label id={i + 'HyomenSagyoTime'}>
                                {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                              </label>
                            }
                          </Col>

                          <Col span={4} className="input-col">
                            <CurrencyInput
                              id={i + 'HyomenSagyoAmtIn'}
                              value={editedData[i]?.sagyoAmt ? Math.round(editedData[i]?.sagyoAmt) : 0}
                              defaultValue={0}
                              style={{
                                border: 'none',
                                color:
                                  Math.round(processArr[i]?.sagyoAmt) === Math.round(editedData[i]?.sagyoAmt)
                                    ? 'black'
                                    : checkShowHideIcon(i + 'HyomenSagyoAmtCu') === 'none'
                                    ? '#4cc12f'
                                    : 'red',
                              }}
                              onValueChange={(e) => {
                                //checkRegex(e.target.value);
                                if (editedData[i]?.sagyoAmt != e) {
                                  settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                                  checkInputWithColor(false, '', i + 'HyomenSagyoAmt', e, 'amt');
                                }
                              }}
                              className={
                                props?.editMode
                                  ? 'input-editable currency resetColor'
                                  : 'input-non-editable currency resetColor'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                            />
                            <div id={i + 'HyomenSagyoAmtCu'} style={{ display: 'none' }}>
                              <div className="currencyIcon">
                                <BsUsbC style={{ fontSize: '25' }} />
                                <BsX style={{ margin: '-8px 0 0 16px' }} />
                                <span class="tooltiptext">
                                  金額に変更がありましたので、以降時間と連携して自動計算されない。
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                                  id={i + 'HyomenSagyoTimeIn'}
                                  style={{
                                    width: '100%',
                                    color:
                                      processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                        ? handleShowHide(
                                            Number(editedData[i]?.sagyoAmt),
                                            processArr[i]?.sagyoAmt,
                                            i + 'HyomenSagyoAmtCu'
                                          )
                                        : 'red',
                                  }}
                                  className={
                                    props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                  }
                                  onChange={(e) => {
                                    settingNewDandoriAmtInArr(e.target.value, i, 'HyomenSagyoAmt', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {props?.editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.sagyoTime,
                                          '10min',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.sagyoTime,
                                          '10min',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.sagyoTime,
                                          'min',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.sagyoTime,
                                          'min',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.sagyoTime,
                                          'sec',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.sagyoTime,
                                          'sec',
                                          i + 'HyomenSagyoTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>
                        {/* 計算法 /入力 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg" style={{ marginLeft: 45 }}>
                              計算法 /入力
                            </span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'calMethod'}>{processArr[i]?.calMethod}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'calInput'}>{processArr[i]?.calInput}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'calMethodIn'}>{editedData[i]?.calMethod}</label>
                          </Col>

                          <Col span={4} className="input-col">
                            <Input
                              value={editedData[i]?.calInput}
                              id={i + 'calInputIn'}
                              min={0}
                              className={editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color:
                                  String(editedData[i]?.calInput) === String(processArr[i]?.calInput) ? 'black' : 'red',
                              }}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'calInput', '', '')}
                              onBlur={(e) => {
                                toTwoDecimal(i, 'calInput');
                              }}
                            />
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>

                        {/*  処理選択 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg" style={{ marginLeft: 45 }}>
                              処理選択
                            </span>
                          </Col>

                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'shori'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'processType'}>{processArr[i]?.processDetailTypeNo}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'shoriIn'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'processTypeIn'}>{processArr[i]?.processDetailTypeNo}</label>
                          </Col>

                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            <span style={{ marginLeft: '5px' }}>工程内検査金額/時間</span>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="SurfaceKensaAmt">{JPYs.format(kensaAmt)}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="SurfaceKensaTime">{kensaTime}</label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id="SurfaceKensaAmtIn"
              value={editedData[0]?.kensaAmt}
              defaultValue={0}
              onValueChange={(e) => {
                if (editedData[0]?.kensaAmt != e) {
                  settingNewDandoriAmtInArr(e, 0, 'kensaAmt', '', '');
                  checkInputWithColor(false,'','SurfaceKensaAmt', e, 'amt');
                }
              }}
              style={{
                border: 'none',
                color:
                  Number(editedData[0]?.kensaAmt) === kensaAmt
                    ? 'black'
                    : checkShowHideIcon('SurfaceKensaAmtCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id="SurfaceKensaAmtCu" style={{ display: 'none' }} className="currencyReset">
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={editedData[0]?.kensaTime}
                  id="SurfaceKensaTimeIn"
                  style={{
                    width: '100%',
                    color:
                      editedData[0]?.kensaTime === kensaTime
                        ? handleShowHide(Number(kensaAmt), Number(editedData[0]?.kensaAmt), 'SurfaceKensaAmtCu')
                        : 'red',
                  }}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  onChange={(e) => {
                    settingNewDandoriAmtInArr(e.target.value, 0, 'kensaTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, '10min', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, '10min', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'min', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'min', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'sec', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'sec', 'surfaceKensaTime');
                        settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row> */}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Surface;
