/**
 * クラス名：マイン見積
 * 説明：親部品見積・子部品見積纏めJSファイルや共通操作ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import {
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Image,
  Tooltip,
  Space,
  Tabs,
  Menu,
  Checkbox,
  Steps,
  Select,
  Empty,
  Spin,
  Table,
} from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined, CloseSquareFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Table as RTable } from 'react-bootstrap';
import lodash from 'lodash';

import '../../assets/styles/common.css';
import close from '../../assets/images/close.png';
import save from '../../assets/images/save.png';
import pc_save from '../../assets/images/pc_save.png';
import pmx_import from '../../assets/images/pmx_import.png';
import logo from '../../assets/images/logo.ico';
import export_report from '../../assets/images/export_report.png';
import edit_info from '../../assets/images/edit_info.png';
import IQ3Estimate from '../iq3/IQ3Estimate';
import ParentEstimate from '../parent/ParentEstimate';
import EstimateBasicInfo from '../parent/EstimateBasicInfo';
import ParentKouteiSentaku from '../parent/ParentKouteiSentaku';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import {
  formatDateString,
  todayTime,
  updateEstimateInfoByReCal,
  convertServerExcelToHtml,
  formatPMXDate,
  toDecimal,
  getInitParamUpdateFlgInfos,
  getNameByValue,
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  JPYs,
  patternPage,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  toHalfWidth,
  regexExp,
  isDigit,
  handleKeyPress,
} from '../common/Common';
import {
  commonTaiochuModal,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  confirmOverwriteSaveContent,
  confirmSaveContent,
  confirmDiscardContent,
  confirmScreenChangeWarning,
  confirmContent,
  dataConfirmOK,
  sameDrawingNoContent,
  commonActionFooter,
  confirmUpdateContent,
  complete,
} from '../common/CommonModal';
import confirm_icon from '../../assets/images/confirm_icon.png';
import {
  createEstimateProductInfo,
  getEstimateIq3Info,
  getExcelDatabyUseType,
  updateEstimateProductInfo,
  getCardInfo,
  getCardDetailImg,
  GetParametersByCompId,
  getIsExistSameEstimate,
  updateUserSetting,
  getExistCompanySetting,
} from '../common/CommonAPI';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import PMX_CloudFileDialog_Tab_Parent from './PMX_CloudFileDialog_Tab_Parent';
import PMX_CloudFileDialog_Tab_IQ3 from './PMX_CloudFileDialog_Tab_IQ3';
import parent_call_detail from '../../assets/images/parent_call_detail.png';
import ParentList from '../parent/ParentList';
import {
  WorkType,
  ServiceClass,
  PrintTypes,
  CreatePMXData,
  WorkTypeGroup,
  ParamCalType,
  EstimateCreationType,
} from './enums';
import {
  BendingTypes,
  BlankTypes,
  FRBend,
  SecondaryWorkTypes,
  SubWeldTypes,
  Types,
  amtEmptyStr,
  byQuantities,
  saveSameTypes,
} from './Constant';
import new_icon from '../../assets/images/new_icon.png';
import curt from '../../assets/images/curt.png';
import edit_list from '../../assets/images/edit_list.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';

dayjs.extend(customParseFormat);
const iconWidth = 32;

const MainEstimate = React.forwardRef((props, ref) => {
  const [activePartMenu, setActivePartMenu] = useState('1');
  // PMX工程選択ダイアログ表示
  const [isPMXProcessPatternOpen, setIsPMXProcessPatternOpen] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isPMXComplete, setIsPMXComplete] = useState(false);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [selectedEstimateDataTemp, setSelectedEstimateDataTemp] = useState([]);
  const [selectediq3Data, setSelectediq3Data] = useState([]);
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [clickAddNewModal, setClickAddNewModal] = useState(false);
  const [clickPMX, setClickPMX] = useState(false);
  const [pMXFileName, setPMXFileName] = useState('zerofour-02.STEP.SB-E_WELD_v2023-1-7.PMX');
  const [clickMenu, setClickMenu] = useState(''); //親部品、板金のタブイベントか判断するため、空白を初期設定
  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);
  const [isListOutputOpen, setIsListOutputOpen] = useState(false);
  const [childTitle, setChildTitle] = useState('');
  // pc保存
  const [isEstimateDataSaveConfirm, setIsEstimateDataSaveConfirm] = useState(false);
  const [isLoadPMXProcess, setIsLoadPMXProcess] = useState(false);
  const [isCreatePMX, setIsCreatePMX] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [pmxProcessType, setPmxProcessType] = useState('');
  const [patternList, setPatternList] = useState([]);
  const [parentPatternNo, setParentPatternNo] = useState(1);
  const [childPatternNo, setChildPatternNo] = useState(1);
  const [currentPMXMenu, setCurrentPMXMenu] = useState(false);
  const [iq3PatternList, setIQ3PatternList] = useState([]);
  const [pmxTabKey, setPMXTabKey] = useState('1');
  const [isPMXCloudFileDialogOpen, setIsPMXCloudFileDialogOpen] = useState(false);
  const [selectedParentDatas, setSelectedParentDatas] = useState([]);
  const [selectedIQ3Datas, setSelectedIQ3Datas] = useState([]);
  const [parentListDisplay, setParentListDisplay] = useState(false);
  const [selectedParentAddEstimate, setSelectedParentAddEstimate] = useState([]);

  const [checkPMXOrNot, setCheckPMXOrNot] = useState(false);
  // 別名保存・上書き保存
  const saveMenuChildItems = [
    { key: 'otherNmSave', label: '別名保存' },
    { key: 'overwrite', label: '上書き保存' },
  ];
  // iq3Data確認
  const [iq3DataConfirm, setIq3DataConfirm] = useState(false);
  // 新規保存
  const [createConfirm, setCreateConfirm] = useState(false);
  // 破棄
  const [discardConfirm, setDiscardConfirm] = useState(false);
  const [selectedSaveMenuKey, setSelectedSaveMenuKey] = useState('');
  // 別名保存の入力項目
  const [estimateNo, setEstimateNo] = useState('');
  const [inputDrawingNo, setInputDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [isSaveByOtherName, setIsSaveByOtherName] = useState(false);
  // 上書き保存
  const [isSaveByOverwrite, setIsSaveByOverwrite] = useState(false);
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [navigateTabName, setNavigateTabName] = useState('');
  const [yobidashiClk, setYobidashiClk] = useState(false);
  const [calClk, setCalClk] = useState(false);
  const [saveClk, setSaveClk] = useState(false);
  const [pcInClk, setPcInClk] = useState(false);
  const [pcOutClk, setPcOutClk] = useState(false);
  const [reportClk, setReportClk] = useState(false);
  const [csvClk, setCsvClk] = useState(false);
  const [remarkClk, setRemarkClk] = useState(false);
  const [updateParentPatternList, setUpdateParentPatternList] = useState([]);
  const [updateIQ3PatternList, setUpdateIQ3PatternList] = useState([]);
  const [pmxSelectedEstimateData, setPMXSelectedEstimateData] = useState([]);

  //帳票出力
  const [excelIndividualFormats, setExcelIndividualFormats] = useState([]);
  const [selectedExeclIndividualFormat, setSelectedExeclIndividualFormat] = useState();

  // PMX取り込み
  const [selectedPMXEstimateData, setSelectedPMXEstimateData] = useState([]);
  const [selectedPMXEstimateDataTemp, setSelectedPMXEstimateDataTemp] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);

  // カード情報
  const [cardInfo, setCardInfo] = useState([]);
  // カード情報
  const [cardInfoCount, setCardInfoCount] = useState(0);

  // 新パラメータ情報
  const [newParameters, setNewParameters] = useState();

  // Waitingダイアログの表示
  const [parentLoading, setParentLoading] = useState(true);

  // パネル更新フラグ
  const [basicInfoUpdate, setBasicInfoUpdate] = useState(false);

  // チェック板金
  const [noIq3, setNoIq3] = useState(false);

  // 同一図番で保存
  const [isSaveSameDrawingNo, setIsSaveSameDrawingNo] = useState(false);
  const [saveSameDrawingNoFncName, setSaveSameDrawingNoFncName] = useState('');

  // パラメータ更新モデル
  const [isCurtParam, setIsCurtParam] = useState(false);

  // パラメータ更新フラグ情報
  const [paramUpdateFlgInfos, setParamUpdateFlgInfos] = useState();

  // 更新確認メッセージ
  const [updateConfirm, setUpdateConfirm] = useState(false);

  // まとめて編集
  const [isUpdate, setIsUpdate] = useState(false);
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditItem, setBulkEditItem] = useState([]);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);
  const [bulkIQ3DataLst, setBulkIQ3DataLst] = useState([]);
  const [activeBulkEditTabKey, setActiveBulkEditTabKey] = useState([]);
  const [partCount, setPartCount] = useState('');
  const [materialTypeId, setMaterialTypeId] = useState('');
  const [thickness, setThickness] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [materialSurfaceId, setMaterialSurfaceId] = useState([]);
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  // file dialog
  const inputFile = useRef();
  const iQ3EstimateRef = useRef();
  const parentEstimateRef = useRef();
  const estimateBasicInfoRef = useRef();
  const inputFile_pcInput = useRef(null);
  const inputFile_pcOutput = useRef(null);
  const pmxCloudParentRef = useRef();
  const pmxCloudIQ3Ref = useRef();
  const iQ3KouteiSentakuRef = useRef();
  const parentKouteiSentakuRef = useRef();

  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
  const estimateParameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

  // 材料名称リスト
  const materialLst = estimateParameters?.materialIq3?.filter((i) => i?.info?.isUsed);

  // 材質名称リスト
  const materialTypeLst = estimateParameters?.materialTypeIq3?.filter((i) => i?.info?.isUsed);

  // 材料表面保護
  const materialSurfaceLst = estimateParameters?.materialSurfaceIq3?.filter((i) => i?.info?.isUsed);

  const patternformRef = useRef();

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      className: 'cm-a-right',
      width: 50 + 'px',
      // render: (value, array, index) => index + 1,
    },
    {
      id: '2',
      title: '採用',
      dataIndex: 'creationType',
      width: 60 + 'px',
      align: 'center',
      render: (value) => (value === EstimateCreationType.Auto ? '自動' : '手動'),
    },
    {
      id: '3',
      title: '画像',
      dataIndex: 'imageUrl',
      width: 140 + 'px',
      align: 'center',
      render: (value) => <img src={value} alt="" className="pmx-img" />, // IQX_WEBEST-247
    },
    {
      id: '4',
      title: ['工程パターン', <br />, '子部品図番', <br />, '子部品名称'],
      dataIndex: 'processSelect',
      width: 220 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>
          <label style={{ fontSize: '13px' }}>{record?.processSelect?.name}</label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.drawingNo}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 2 }} value={record.imgNo} /> */}
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.name}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 3 }} value={record.partNm} /> */}
          <br />
        </div>
      ),
    },
    {
      id: '5',
      title: '員数(製造数)',
      dataIndex: 'partCount',
      width: 80 + 'px',
      align: 'center',
      render: (partCount) =>
        partCount + '(' + partCount * props?.selectedEstimateData?.estimateProducts?.quantity + ')',
    },
    {
      id: '6',
      title: ['材質', <br />, '材料', <br />, '板厚', <br />, '表面保護'],
      dataIndex: 'materialItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) =>
        estimateParameters ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px' }}>
              {getMaterialNameById(record.materialIq3Id, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialThickById(record.thickness, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialSurfaceById(record.materialSurfaceIq3Id, estimateParameters?.materialSurfaceIq3)}
            </label>
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: '7',
      title: ['重量', <br />, '表面積', <br />, '表面処理費'],
      dataIndex: 'amountItems',
      width: 100 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.weight ? toDecimal(record.weight, 2) : toDecimal(0, 2)}
            kg
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.totalSurfaceArea != undefined ? toDecimal(record.totalSurfaceArea, 2) : toDecimal(0, 2)}
            cm<sup>2</sup>
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.surfaceTotalPrice ? JPYs.format(Math.round(record.surfaceTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '8',
      title: ['材料原価', <br />, '製造原価', <br />, '購入品費'],
      dataIndex: 'costItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialCostUnitPrice ? JPYs.format(Math.round(record.materialCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.workCostUnitPrice ? JPYs.format(Math.round(record.workCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.purchaseUnitPrice ? JPYs.format(Math.round(record.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'costUnitPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costUnitPrice ? JPYs.format(Math.round(record.costUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'costTotalPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costTotalPrice ? JPYs.format(Math.round(record.costTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  useEffect(() => {
    // 親部品のページリロードの場合、初期値の再設定（IQX_WEBEST-79）
    if (props.selectedEstimateData?.estimateProducts === undefined && location?.pathname === '/home/:estimate.parent') {
      props?.navigatePage('', ':estimate.parent', 'parent');
      setActivePartMenu('1');
      props?.updateActiveTabKey('1');
    } else if (
      props.selectedEstimateData?.estimateProducts === undefined &&
      location?.pathname === '/home/:estimate.iq3'
    ) {
      props?.navigatePage('', ':estimate.iq3', 'iq3');
      setActivePartMenu('2');
      props?.updateActiveTabKey('2');
    } else {
      if (location?.pathname === '/home/:estimate.iq3' || location?.pathname === '/home/:estimate.parent') {
        props?.loading(false); // Waitingダイアログを非表示にする
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    //破棄用見積情報保持
    if (selectedEstimateDataTemp?.estimateProducts === undefined || props.createNew) {
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(props.selectedEstimateData)));
    }
    if (location?.pathname === '/home/:estimate.iq3' || location?.pathname === '/home/:estimate.parent') {
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  }, [props.selectedEstimateData, props.createNew]);

  useEffect(() => {
    let partMenu = props.activeTab ? props.activeTab : '1';
    setActivePartMenu(partMenu); // （IQX_WEBEST-79）
    props?.updateActiveTabKey(partMenu);
    if (location.pathname === '/home/:estimate.iq3') {
      setActivePartMenu('2');
      props?.updateActiveTabKey('2');
    }
    setSelectedEstimateData(props.selectedEstimateData);
    // setCheckPMXOrNot(!props?.createNew);
  }, [props.selectedEstimateData, props.createNew]);

  useEffect(() => {
    let partMenu = props.activeTab ? props.activeTab : '1';
    setActivePartMenu(partMenu); // （IQX_WEBEST-79）
    props?.updateActiveTabKey(partMenu);
  }, [props.activeTab]);

  //編集モード切替により呼び出す
  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  useEffect(() => {
    if (props?.isPMXEvent) {
      iQ3EstimateRef?.current?.updateIq3PMXDatas(props?.selectedEstimateData?.esIq3Info);
    }
  }, [props?.isPMXEvent]);

  //板金データにより元見積データを更新
  const updatedSelectedEstimateDataTemp = (data) => {
    if (selectedEstimateDataTemp?.esIq3Info == undefined) {
      // let updateSelectedInfo = selectedEstimateDataTemp;
      // updateSelectedInfo.esIq3Info = data?.esIq3Info;
      // setSelectedEstimateDataTemp(data);
    }
  };

  // PMX取り込みコンテンツから選択した場合、
  const openPMXFileDialog = async (e, type, clickCon) => {
    props?.loading(true); // Waitingダイアログを表示にする
    setIsCreatePMX(clickCon);
    setIsLoadPMXProcess(false);
    setPmxProcessType(type);
    if (currentPMXMenu == 'local') {
      setPmxProcessType(type);
      inputFile.current.click();
    } else {
      // const tenjikaiSampleData = getTenjikaiSampleData();
      // const pmxList = tenjikaiSampleData.parentList_PMX_1;
      // setPMXSelectedEstimateData(pmxList[0]);
      // setPMXTabKey('1');
      if (clickCon === CreatePMXData.SaveAndNew) {
        if (selectedEstimateData?.estimateProducts?.id === 0) {
          estimateNewDataSaveEvent();
        } else {
          saveByOverwriteOk();
        }
      }
      setIsPMXCloudFileDialogOpen(true);
      // IQX_WEBEST-246
      let cardData = await getCardInfo(100, 0, '', '', '', '', '', '', 3);
      if (cardData) {
        const cardDatas = cardData?.data;
        const updatedData = cardDatas?.map((item) => ({
          ...item,
          modified: formatPMXDate(item.modified),
        }));
        setCardInfo(updatedData);
        const updatedDataTotalCount = cardData?.totalCount ? cardData?.totalCount : 0;
        setCardInfoCount(updatedDataTotalCount);
      }
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const openFileDialog_pcInput = () => {
    setPcInClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const openFileDialog_pcOutput = () => {
    setPcOutClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const openPMXProcessPatternDialog = (e) => {
    if (currentPMXMenu == 'local') {
      let targetFileNm = e.target.files[0].name;
      setPMXFileName(targetFileNm);
    }
    setIsPMXProcessPatternOpen(true);
    //親部品工程パターンリスト
    let kouteiPatternList =
      localStorage.getItem('parent_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('parent_processPattern'))
        : [];
    setPatternList(kouteiPatternList);
    //板金品工程パターンリスト
    let iq3kouteiPatternList =
      localStorage.getItem('iq3_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('iq3_processPattern'))
        : [];
    setIQ3PatternList(iq3kouteiPatternList);
    setCurrentStep(1);
  };

  const openPMXProcessPatternModal = (res, estimateData, selectedCardData) => {
    if (isCreatePMX === CreatePMXData.Update) {
      setIsNext(true);
      setCurrentStep(2);
    } else {
      setIsNext(false);
      setCurrentStep(1);
    }

    setIsPMXProcessPatternOpen(res);
    // ＊＊＊
    let parameters = estimateData?.estimateProducts?.calcParameters?.parameters;
    let settings = estimateData?.estimateProducts?.calcParameters?.settings;
    let userSettingData = settings?.userSettings;
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    let parentKouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Parent);
    let iq3KouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    // ***
    //親部品工程パターンリスト
    // let kouteiPatternList =
    //   localStorage.getItem('parent_processPattern') != undefined
    //     ? JSON.parse(localStorage.getItem('parent_processPattern'))
    //     : [];
    setPatternList(parentKouteiPatternList);
    //板金品工程パターンリスト

    // let iq3kouteiPatternList =
    //   localStorage.getItem('iq3_processPattern') != undefined
    //     ? JSON.parse(localStorage.getItem('iq3_processPattern'))
    //     : [];

    let initParentKouteiPatternList = patternLst?.filter(
      (item) => item.id === userSettingData?.[0]?.users?.parentProcessPatternId
    );
    let initIq3KouteiPatternList = patternLst?.filter(
      (item) => item.id === userSettingData?.[0]?.users?.iq3ProcessPatternId
    );
    let parentPatternId =
      initParentKouteiPatternList?.length > 0 ? initParentKouteiPatternList[0].id : parentKouteiPatternList?.[0]?.id;
    let childPatternId =
      initIq3KouteiPatternList?.length > 0 ? initIq3KouteiPatternList[0].id : iq3KouteiPatternList?.[0]?.id;
    setIQ3PatternList(iq3KouteiPatternList);
    setChildPatternNo(childPatternId);
    setParentPatternNo(parentPatternId);
    // setCurrentStep(1);
    setSelectedPMXEstimateData(estimateData);
    setSelectedCardData(selectedCardData);
    let iq3CADData = selectedCardData?.details?.filter((i) => i.type !== 'SheetMetal');
    if (iq3CADData?.length > 0) {
      setNoIq3(true);
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // PMXメニューイベント
  const pmxMenuChangeEvent = async (e) => {
    setClickPMX(true);
    setCurrentPMXMenu(e.key);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      if (e.key === 'cloud') {
        setCurrentStep(1);
        setIsLoadPMXProcess(true);
      }
    }
  };

  const pmxTabChange = (key) => {
    setPMXTabKey(key);
    if (key == '1') {
    } else if (key == '2') {
    }
  };

  const getSelectedParentRows = (selectedRows) => {
    setSelectedParentDatas(selectedRows);
  };
  const getSelectedIQ3Rows = (selectedRows) => {
    setSelectedIQ3Datas(selectedRows);
  };

  const pmxLocalProcessCancel = () => {
    setIsLoadPMXProcess(false);
    setIsPMXCloudFileDialogOpen(false);
  };

  const fetchIndividualData = async () => {
    try {
      let companySetting = await getExistCompanySetting(); // IQX_WEBEST-237
      if (companySetting !== undefined) {
        if (!Array.isArray(companySetting)) {
          companySetting = [companySetting];
        }
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting?.[0]?.excelFormats);
        const excelIndividualformats = formats[PrintTypes.individual];
        if (excelIndividualformats !== undefined && excelIndividualformats?.length > 0) {
          const selectedExelIndividualFormat = formats[0]?.find(
            (format) => format.id === companySetting?.printOutputs?.[PrintTypes.individual]?.formatsId
          );
          setExcelIndividualFormats(formats[PrintTypes.individual]);

          if (selectedExelIndividualFormat !== undefined) {
            setSelectedExeclIndividualFormat(selectedExelIndividualFormat);
          } else {
            setSelectedExeclIndividualFormat(formats[0][0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateEstimateInfo = (estimateInfo) => {
    setBasicInfoUpdate(!basicInfoUpdate);
    setSelectedEstimateData(estimateInfo);
  };

  const updateParentEstimateInfo = (estimateInfo) => {
    parentEstimateRef.current.updateEstimateInfo(estimateInfo);
  };

  const getCurrentIQ3 = () => {
    let currentIq3Data = iQ3EstimateRef?.current?.getEstimateIq3Info();
    return currentIq3Data;
  };

  const getParentQuantity = () => {
    return props?.selectedEstimateData?.estimateProducts?.quantity; //入力した数量情報を取得
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const discardConfirmOk = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    props.updateEstimateEditMode(false);

    if (clickPMX) {
      setClickPMX(false);
      setIsLoadPMXProcess(true);
    } else if (yobidashiClk) {
      // 子部品追加呼出
      setParentListDisplay(true);
      setYobidashiClk(false);
      setParentLoading(true); // Waitingダイアログを表示にする
    } else if (calClk) {
      setIsEstimateDataSaveConfirm(true);
      setChildTitle('確認');
      setCalClk(false);
    } else if (saveClk) {
      // setIsEstimateDataSaveConfirm(true);
      if (selectedSaveMenuKey === saveMenuChildItems[0].key) {
        setIsSaveByOtherName(true);
        // setInputDrawingNo(formatDateString(todayTime()));
        let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
        const data = JSON.parse(JSON.stringify(currentEstimateData));
        setEstimateNo(data?.estimateProducts?.estimateNo);
        setInputDrawingNo(data?.estimateProducts?.drawingNo);
        setParentEstimateName(formatDateString(todayTime()));
      } else {
        setIsSaveByOverwrite(true);
      }
      setChildTitle('確認');
      setSaveClk(false);
      parentEstimateRef?.current?.discardConfirmOk();
    } else if (pcInClk) {
      inputFile_pcInput.current.click();
      setPcInClk(false);
    } else if (pcOutClk) {
      inputFile_pcOutput.current.click();
      setPcOutClk(false);
    } else if (reportClk) {
      setIsReportOutputOpen(true);
      setReportClk(false);
    } else if (csvClk) {
      setIsReportOutputOpen(true);
      setCsvClk(false);
    } else if (remarkClk) {
    } else {
    }

    if (clickMenu === '1') {
      setActivePartMenu('1');
      navigate(':estimate.parent');
      props?.updateActiveTabKey('1');
    } else if (clickMenu === '2') {
      setActivePartMenu('2');
      navigate(':estimate.iq3');
      props?.updateActiveTabKey('2');
    }
  };

  const discardConfirmCancel = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setClickPMX(false);
    // setClickAddNewModal(false);
  };
  const updateMainEstimateEditMode = (mode) => {
    setEditMode(mode);
    props.updateEstimateEditMode(mode);
  };

  const partMenuChange = (e) => {
    setClickMenu(e);
    props.updateSelectedEstimateData(props.selectedEstimateData);
    setSelectedEstimateData(props.selectedEstimateData);

    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmScreenChangeModalOpen(false);
      // setActivePartMenu(e);
      if (e === '1') {
        setNavigateTabName('parent');

        navigate(':estimate.parent');
        setActivePartMenu(e);
        // setSaveDiscardConfirmPopup(true);
      } else if (e === '2') {
        setNavigateTabName('sheetMetal');
        // setSaveDiscardConfirmPopup(true);
        setActivePartMenu(e);
        navigate(':estimate.iq3');
      }
      props?.updateActiveTabKey(e);
    }
  };

  const updateClickMenu = () => {
    setClickMenu('');
  };

  // 帳票出力
  const reportOutputChangeEvent = (e) => {
    // if (e.key == 'listReport') {
    //   setReportClk(true);
    //   setChildTitle('リスト帳票');
    // } else if (e.key == 'parentReport') {
    //   setIsReportOutputOpen(true);
    //   setChildTitle('親部品帳票');
    // } else if (e.key == 'pcSave') {
    //   setPcInClk(true);
    // } else if (e.key == 'pcLoad') {
    //   setPcOutClk(true);
    // } else if (e.key == 'csvReport') {
    //   setCsvClk(true);
    //   setChildTitle('CSV出力');
    // }
    setChildTitle('帳票');
    fetchIndividualData();
    setIsReportOutputOpen(true);
    // if (editMode) {
    //   setConfirmScreenChangeModalOpen(true);
    // } else {
    //   setConfirmDisplayDataSaveModalOpen(true);
    // }
  };

  // 再計算
  const calEstimateDataEvent = (e) => {
    setCalClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const estimateNewDataSaveEvent = () => {
    setNavigateTabName('');
    //setCreateConfirm(true);
    setCreateConfirm(props?.userSettingInfo?.[0]?.massages?.editOk);
    if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
      createOk();
    }
  };
  // 別名保存・上書き保存
  const estimateDataSaveEvent = async (e) => {
    setSaveClk(true);
    setSelectedSaveMenuKey(e.key);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
      return;
    }
    if (e.key === saveMenuChildItems[0].key) {
      setIsSaveByOtherName(true);
      /* setIsSaveByOtherName(props?.userSettingInfo?.[0]?.massages?.editOk);
      if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
        saveByOtherNameOk();
      } */
      // setInputDrawingNo(formatDateString(todayTime()));
      let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
      const data = JSON.parse(JSON.stringify(currentEstimateData));
      setEstimateNo(data?.estimateProducts?.estimateNo);
      setInputDrawingNo(data?.estimateProducts?.drawingNo);
      setParentEstimateName(formatDateString(todayTime()));
    } else {
      //setIsSaveByOverwrite(true);
      setIsSaveByOverwrite(props?.userSettingInfo?.[0]?.massages?.editOk);
      if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
        saveByOverwriteOk();
      }
    }

    // setIsEstimateDataSaveConfirm(true);
    setChildTitle('確認');
  };

  const childModalTitle = (
    <div
      style={{
        width: 1490,
        backgroundColor: '#005fab',
        // marginLeft: isPMXCloudFileDialogOpen ? -10 : -24,
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>PMX取り込み</p>
    </div>
  );
  const updateOk = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    setIsPMXCloudFileDialogOpen(false);
  };

  const updateCancel = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    setIsPMXCloudFileDialogOpen(false);
  };
  const cancelPMXImport = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
  };
  const nextProcessPattern = () => {
    setIsNext(true);
    setCurrentStep(2);
  };
  // ブランクの種類.形状により、データ設定
  const getSize = (id, size) => {
    return id === 3 || id === 4 ? toDecimal(size || 0, 2) : toDecimal(0, 2);
  };
  const getDiameter = (id, xl) => {
    return id <= 2 ? toDecimal(parseFloat(xl) || 0, 2) : toDecimal(0, 2);
  };

  // PMX取り込みの完了ボタン押下
  const completePMX = () => {
    setIq3DataConfirm(noIq3);
    if (!noIq3) {
      completePMXData();
    }
  };
  const completePMXData = async () => {
    setIsPMXProcessPatternOpen(false);
    setIq3DataConfirm(noIq3);
    props?.loading(true); // Waitingダイアログを表示にする
    let processMst = selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.process;
    let bendingTypes = [];
    let weldingTypes = [];
    let subWeldingTypes = [];
    let secWorkTypes = [];
    const bendingDetails = processMst?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingDetails?.length > 0) {
      bendingTypes = bendingDetails[0]?.details?.processDetailTypes?.filter((i) => i.isUsed);
    }
    const weldingDetails = processMst?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingDetails?.length > 0) {
      weldingTypes = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmWelding && i.isUsed
      );

      subWeldingTypes = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => (i.group === WorkTypeGroup.SmInstSize && i.isUsed) || (i.group === WorkTypeGroup.SmInstThick && i.isUsed)
      );
    }
    const secWorkDetails = processMst?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secWorkDetails?.length > 0) {
      secWorkTypes = secWorkDetails[0].details?.processDetailTypes?.filter(
        (i) =>
          (i.group === WorkTypeGroup.SmSecondaryWorkSize && i.isUsed) ||
          (i.group === WorkTypeGroup.SmSecondaryWorkMillimeter && i.isUsed)
      );
    }

    for (let i = 0; i < selectedPMXEstimateData?.esIq3Info?.length; i++) {
      let cardMainDataDetails = selectedCardData?.details?.[i];
      let cardDetails = cardMainDataDetails?.processingInfo ? JSON.parse(cardMainDataDetails?.processingInfo) : {};

      // 基本情報設定
      if (cardMainDataDetails?.thumbPath !== undefined) {
        let iq3PmxImg = await getCardDetailImg(cardMainDataDetails?.id);
        if (iq3PmxImg !== undefined) {
          // IQX_WEBEST-207
          selectedPMXEstimateData.esIq3Info[i].imageId = cardMainDataDetails?.id
            ? (cardMainDataDetails?.id).toString()
            : '';
          selectedPMXEstimateData.esIq3Info[i]['imageUrl'] = URL.createObjectURL(iq3PmxImg);
        } else {
          selectedPMXEstimateData.esIq3Info[i].imageId = '';
        }
      }

      selectedPMXEstimateData.esIq3Info[i].partCount = cardMainDataDetails?.quantity;
      selectedPMXEstimateData.esIq3Info[i].drawingNo = cardMainDataDetails?.drawingNo;
      selectedPMXEstimateData.esIq3Info[i].name = cardMainDataDetails?.name;
      let pmxMaterialNm = cardMainDataDetails?.material;
      // 材料リスト
      let materialList = selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq3;
      let materialSurfaceLst =
        selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
      let materialNm = materialList?.filter((item) => item.name === pmxMaterialNm && item.info.isUsed);

      let materialTypeList = selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3;
      let materialSurfaceList =
        selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
      let gravity = 0.0;
      // 材料情報設定
      selectedPMXEstimateData.esIq3Info[i].materialIq3Id =
        materialNm?.length > 0 ? materialNm?.[0]?.id : selectedPMXEstimateData.esIq3Info[i].materialIq3Id;
      selectedPMXEstimateData.esIq3Info[i].materialName =
        materialNm?.length > 0 ? materialNm?.[0]?.name : selectedPMXEstimateData.esIq3Info[i].materialName;

      selectedPMXEstimateData.esIq3Info[i].materialTypeIq3Id =
        materialNm?.length > 0
          ? materialNm?.[0]?.materialTypeIq3Id
          : selectedPMXEstimateData.esIq3Info[i].materialTypeIq3Id;
      let materialType = materialTypeList?.filter(
        (mType) => mType.id === selectedPMXEstimateData.esIq3Info[i].materialTypeIq3Id
      );
      selectedPMXEstimateData.esIq3Info[i].materialTypeName =
        materialType?.length > 0 ? materialType?.[0]?.name : selectedPMXEstimateData?.esIq3Info?.[i]?.materialTypeName;

      selectedPMXEstimateData.esIq3Info[i].thickness =
        materialNm?.length > 0 ? materialNm?.[0]?.id : materialList?.[0]?.id; // IQX_WEBEST-68
      gravity = materialNm?.length > 0 ? materialNm?.[0]?.info?.gravity : materialList?.[0]?.info?.gravity;
      let materialSurface = materialSurfaceLst?.filter(
        (item) => item.materialTypeIq3Id === materialNm?.[0]?.materialTypeIq3Id && item.info.isUsed
      );
      selectedPMXEstimateData.esIq3Info[i].materialSurfaceIq3Id =
        materialSurface?.length > 0 ? materialSurface[0]?.id : materialSurfaceLst?.[0]?.id;
      selectedPMXEstimateData.esIq3Info[i].materialSurfaceName =
        materialSurface?.length > 0 ? materialSurface[0]?.name : materialSurfaceLst?.[0]?.name;

      //　面積・重量設定
      //重量(kg) = 板厚(mm) X Xサイズ(mm) X Yサイズ(mm) ÷ 1000000 X 比重(g/㎤)
      let sizeX = cardDetails?.boundaryBoxSize?.width
        ? toDecimal(cardDetails?.boundaryBoxSize?.width, 2)
        : toDecimal(0, 2);
      let sizeY = cardDetails?.boundaryBoxSize?.height
        ? toDecimal(cardDetails?.boundaryBoxSize?.height, 2)
        : toDecimal(0, 2);

      const thicknessInfo = materialList?.find(
        (item) => item.materialTypeIq3Id === selectedPMXEstimateData?.esIq3Info?.[i]?.materialTypeIq3Id
      );
      let thicknessVal = 0;
      if (thicknessInfo != undefined) {
        thicknessVal = parseFloat(thicknessInfo?.info?.thick);
      }
      let weight = ((thicknessVal * sizeX * sizeY) / 1000000) * gravity;
      let reCalArea = Number.isFinite((sizeX * sizeY) / 100) ? (sizeX * sizeY) / 100 : toDecimal(0, 2);
      // IQX_WEBEST-84(Cardの面積(mm)をcmに変換)
      let cardDetailsArea = Number.isFinite(cardDetails?.area / 100) ? cardDetails?.area / 100 : reCalArea;
      cardDetailsArea = cardDetailsArea ? toDecimal(cardDetailsArea, 2) : toDecimal(0, 2);

      selectedPMXEstimateData.esIq3Info[i].partSizeX = sizeX;
      selectedPMXEstimateData.esIq3Info[i].partSizeY = sizeY;
      selectedPMXEstimateData.esIq3Info[i].totalSurfaceArea = cardDetailsArea;
      if (sizeX && sizeY && weight) {
        selectedPMXEstimateData.esIq3Info[i].weight = Number.isFinite(weight) ? weight : toDecimal(0, 2);
      }

      //板金子部品より親部品の重量と面積データ更新
      if (selectedPMXEstimateData?.esIq3Info[i]?.weight) {
        selectedPMXEstimateData.estimateProducts.totalWeight += selectedPMXEstimateData?.esIq3Info[i]?.weight
          ? selectedPMXEstimateData?.esIq3Info[i]?.weight
          : 0;
      }
      if (selectedPMXEstimateData?.esIq3Info[i]?.totalSurfaceArea) {
        selectedPMXEstimateData.estimateProducts.totalSurfaceArea += selectedPMXEstimateData?.esIq3Info[i]
          ?.totalSurfaceArea
          ? Number(selectedPMXEstimateData?.esIq3Info[i]?.totalSurfaceArea)
          : 0;
      }

      //工程入力情報設定
      let updateProcessInputs = [];
      let processInputs = JSON.parse(JSON.stringify(selectedPMXEstimateData?.esIq3Info[i]?.processInput));
      for (let j = 0; j < processInputs?.length; j++) {
        let curWorkType = processMst?.filter((item) => item.id == processInputs[j].processId)?.[0]?.workType;
        // IQX_WEBEST-179
        processInputs[j].autoExtractXSize = sizeX ? Number(sizeX) : 0;
        processInputs[j].autoExtractYSize = sizeY ? Number(sizeY) : 0;
        if (curWorkType === WorkType.SmProgram) {
          let programProcessInput = processInputs[j];
          programProcessInput.details[0].elements = cardDetails?.elements;
          programProcessInput.details[0].elementsAuto = cardDetails?.elements; // IQX_WEBEST-179
          updateProcessInputs.push(programProcessInput);
        } else if (
          curWorkType === WorkType.SmBlankCombi ||
          curWorkType === WorkType.SmBlankLaser ||
          curWorkType === WorkType.SmBlankPunch
        ) {
          let blankProcessInput = processInputs[j];
          blankProcessInput.details[0].dimensionData.outerDia = cardDetails?.contourLength?.outerLength
            ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
            : toDecimal(0, 2);
          blankProcessInput.details[0].dimensionData.innerDia = cardDetails?.contourLength?.innerLength
            ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
            : toDecimal(0, 2);
          blankProcessInput.details[0].dimensionData.airFeedDistance = cardDetails?.airDistance
            ? toDecimal(cardDetails?.airDistance, 2)
            : toDecimal(0, 2);
          blankProcessInput.details[0].dimensionData.etchingLength = cardDetails?.etchingLength
            ? toDecimal(cardDetails?.etchingLength, 2)
            : toDecimal(0, 2);
          // IQX_WEBEST-179
          blankProcessInput.details[0].dimensionData.outerAutoExtract = cardDetails?.contourLength?.outerLength
            ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
            : toDecimal(0, 2);
          blankProcessInput.details[0].dimensionData.innerAutoExtract = cardDetails?.contourLength?.innerLength
            ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
            : toDecimal(0, 2);
          blankProcessInput.details[0].dimensionData.airFeedAutoExtract = cardDetails?.airDistance
            ? toDecimal(cardDetails?.airDistance, 2)
            : toDecimal(0, 2);

          let qtyLen = blankProcessInput.details[0].quantityList?.length;
          if (cardDetails?.contourLength?.holes?.length > 0) {
            blankProcessInput.details[0].quantityList.shift();
            for (let k = 0; k < cardDetails?.contourLength?.holes?.length; k++) {
              let typeId = Types?.filter((item) => item.value === 'その他')?.[0]?.id;
              let holdType = BlankTypes?.filter(
                (item) => item.value === cardDetails?.contourLength?.holes?.[k]?.name
              )?.[0]?.label;
              let blanktypes = Types?.filter((item) => item.value === holdType)?.[0];
              if (blanktypes) {
                typeId = blanktypes?.id;
              }
              const hold = cardDetails?.contourLength?.holes?.[k];
              const newListRow = {
                id: k + 1 + qtyLen,
                types: typeId,
                typesAutoExtract: typeId,
                diameter: getDiameter(typeId, hold?.xl),
                diameterAutoExtract: getDiameter(typeId, hold?.xl),
                xsize: getSize(typeId, hold?.xl),
                xsizeAutoExtract: getSize(typeId, hold?.xl),
                ysize: getSize(typeId, hold?.yl),
                ysizeAutoExtract: getSize(typeId, hold?.yl),
                perimeter: hold?.length && hold?.number ? toDecimal(hold?.length / hold?.number, 2) : toDecimal(0, 2),
                perimeterAutoExtract:
                  hold?.length && hold?.number ? toDecimal(hold?.length / hold?.number, 2) : toDecimal(0, 2),
                count: hold?.number,
                countAutoExtract: hold?.number,
              };
              blankProcessInput.details[0].quantityList?.push(newListRow);
            }
          }
          updateProcessInputs.push(blankProcessInput);
        } else if (curWorkType === WorkType.SmBending) {
          let bendProcessInput = processInputs[j];
          const maxBendLength = cardDetails?.bendInfo
            ? cardDetails?.bendInfo?.reduce((max, item) => {
                return item.width > max ? item.width : max;
              }, 0)
            : 0; // IQX_WEBEST-84
          bendProcessInput.details[0].bendingData.maxBendLength = maxBendLength
            ? toDecimal(maxBendLength, 2)
            : toDecimal(0, 2);
          // IQX_WEBEST-179
          bendProcessInput.details[0].bendingData.maxBendAutoExtract = maxBendLength
            ? toDecimal(maxBendLength, 2)
            : toDecimal(0, 2);
          let bendLen = bendProcessInput.details[0].bendingList?.length;
          if (cardDetails?.bendInfo?.length > 0) {
            bendProcessInput?.details[0]?.bendingList?.shift();
            for (let k = 0; k < cardDetails?.bendInfo?.length; k++) {
              const bend = cardDetails?.bendInfo[k];

              let typeId = bendingTypes?.[0]?.id;

              let bendId = BendingTypes?.filter((itemType) => itemType.value === bend?.name)?.[0]?.id;
              let result = bendingTypes?.filter((subWeldItem) => subWeldItem.id === bendId)?.[0];

              if (result) {
                typeId = result?.id;
              }

              const newListRow = {
                id: k + 1 + bendLen,
                types: typeId,
                typesAutoExtract: typeId,
                bendLength: bend?.width ? toDecimal(bend?.width, 2) : toDecimal(0, 2),
                bendLengthAutoExtract: bend?.width ? toDecimal(bend?.width, 2) : toDecimal(0, 2),
                lines: bend?.number, // 曲げ回数（曲げ線数）// IQX_WEBEST-210
                linesAutoExtract: bend?.number, // 曲げ回数（曲げ線数）// IQX_WEBEST-210
                bendCount: bend?.name === FRBend ? bend?.amount : 1, // FR曲げ回数
                bendCountAutoExtract: bend?.name === FRBend ? bend?.amount : 1, // FR曲げ回数
              };
              bendProcessInput?.details[0]?.bendingList?.push(newListRow);
            }
          }

          updateProcessInputs.push(bendProcessInput);
        } else if (curWorkType === WorkType.SmWelding) {
          let weldProcessInput = processInputs?.[j];

          let weldLen = weldProcessInput?.details?.[0].weldingList?.length;
          if (cardDetails?.weldInfo?.length > 0) {
            weldProcessInput?.details?.[0]?.weldingList?.shift();
            for (let k = 0; k < cardDetails?.weldInfo?.length; k++) {
              const weld = cardDetails?.weldInfo?.[k];
              let typeId = weldingTypes?.[0]?.id;
              let weldId = weld?.weldType + 1;
              const result = weldingTypes?.find((weldItem) => {
                return weldItem.id === weldId && weldItem.isUsed;
              });
              if (result) {
                typeId = result?.id;
              }

              const newListRow = {
                id: k + 1 + weldLen,
                yousetsuType: typeId,
                yousetsuTypeAutoExtract: typeId,
                senchou: weld?.length ? toDecimal(weld?.length, 2) : toDecimal(0, 2), //連続/タップ溶接の長さ // IQX_WEBEST-193
                senchouAutoExtract: weld?.length ? toDecimal(weld?.length, 2) : toDecimal(0, 2), //連続/タップ溶接の長さ // IQX_WEBEST-193
                senchouKaisu: weld?.number ? weld?.number : 1, // 連続/タップ回数 // IQX_WEBEST-193
                senchouKaisuAutoExtract: weld?.number ? weld?.number : 1, // 連続/タップ回数 // IQX_WEBEST-193
                senchouYousetsushiage: 0,
                senchouYousetsushiageAutoExtract: 0,
                total1: weld?.length && weld?.number ? toDecimal(weld?.length * weld?.number, 2) : toDecimal(0, 2), // IQX_WEBEST-193

                karitsukeCnt: weld?.tackLocations ? parseInt(weld?.tackLocations) : 0, // 仮付箇所数 // IQX_WEBEST-193
                karitsukeCntAutoExtract: weld?.tackLocations ? parseInt(weld?.tackLocations) : 0, // 仮付箇所数 // IQX_WEBEST-193
                karitsukeKaisu: weld?.tackNumber ? parseInt(weld?.tackNumber) : 1, // 仮付回数 // IQX_WEBEST-193
                karitsukeKaisuAutoExtract: weld?.tackNumber ? parseInt(weld?.tackNumber) : 1, // 仮付回数 // IQX_WEBEST-193
                karitsukeYousetsushiage: 0,
                karitsukeYousetsushiageAutoExtract: 0,
                total2: weld?.tackLocations && weld?.tackNumber ? parseInt(weld?.tackLocations * weld?.tackNumber) : 0, // IQX_WEBEST-193
              };
              weldProcessInput?.details[0]?.weldingList?.push(newListRow);
            }
          }
          // サプ溶接種類
          let subWeldLen = weldProcessInput?.details?.[0]?.subWeldingList?.length;
          if (cardDetails?.subWeldInfo?.length > 0) {
            weldProcessInput?.details?.[0]?.subWeldingList?.shift();
            for (let k = 0; k < cardDetails?.subWeldInfo?.length; k++) {
              const subWeld = cardDetails?.subWeldInfo?.[k];
              let typeId = subWeldingTypes?.[0]?.id;
              let typeGroup = subWeldingTypes?.[0]?.group;
              // ***
              let subWeldType = SubWeldTypes?.filter(
                (itemTyp) => itemTyp.value === cardDetails?.subWeldInfo?.[k]?.name
              )?.[0]?.id;
              let result = subWeldingTypes?.filter((subWeldItem) => subWeldItem.id == subWeldType)?.[0];

              if (result) {
                typeId = result?.id;
                typeGroup = result?.group;
              }

              const newListRow = {
                id: k + 1 + subWeldLen,
                subWeldingType: typeId,
                subWeldingTypeAutoExtract: typeId,
                daimeter: subWeld?.xl ? toDecimal(subWeld?.xl, 2) : toDecimal(0, 2),
                daimeterAutoExtract: subWeld?.xl ? toDecimal(subWeld?.xl, 2) : toDecimal(0, 2),
                number: subWeld?.number ? toDecimal(subWeld?.number, 2) : toDecimal(0, 2),
                numberAutoExtract: subWeld?.number ? toDecimal(subWeld?.number, 2) : toDecimal(0, 2),
                count: 1,
                countAutoExtract: 1,
                yousetsushiage: 1,
                total: subWeld?.xl && subWeld?.number ? toDecimal(subWeld?.xl * subWeld?.number, 2) : toDecimal(0, 2), // IQX_WEBEST-208
                subWeldingGroupType: typeGroup,
                subWeldingGroupTypeAutoExtract: typeGroup,
              };
              weldProcessInput.details[0].subWeldingList.push(newListRow);
            }
          }

          updateProcessInputs.push(weldProcessInput);
        } else if (curWorkType === WorkType.SmSecondaryWork) {
          let secWorkProcessInput = processInputs[j];
          let secWorkLen = secWorkProcessInput?.details?.length;
          if (cardDetails?.burringTappings?.length > 0) {
            secWorkProcessInput?.details?.shift();
            for (let k = 0; k < cardDetails?.burringTappings?.length; k++) {
              const secWork = cardDetails?.burringTappings?.[k];
              let typeId = secWorkTypes?.[0]?.id;
              let typeGroup = secWorkTypes?.[0]?.group;
              // ***
              let secWorkType = SecondaryWorkTypes?.filter(
                (itemType) => itemType.value === cardDetails?.burringTappings?.[k]?.name
              )?.[0]?.id;
              let result = secWorkTypes?.filter((subWeldItem) => subWeldItem.id == secWorkType)?.[0];

              if (result) {
                typeId = result?.id;
                typeGroup = result?.group;
              }

              const newListRow = {
                id: k + 1 + secWorkLen,
                types: typeId,
                typesAutoExtract: typeId,
                diameter: secWork?.size ? toDecimal(secWork?.size, 2) : toDecimal(0, 2),
                diameterAutoExtract: secWork?.size ? toDecimal(secWork?.size, 2) : toDecimal(0, 2),
                xsize: secWork?.cp?.x ? toDecimal(secWork?.cp?.x, 2) : toDecimal(0, 2),
                xsizeAutoExtract: secWork?.cp?.x ? toDecimal(secWork?.cp?.x, 2) : toDecimal(0, 2),
                ysize: secWork?.cp?.y ? toDecimal(secWork?.cp?.y, 2) : toDecimal(0, 2),
                ysizeAutoExtract: secWork?.cp?.y ? toDecimal(secWork?.cp?.y, 2) : toDecimal(0, 2),
                perimeter: toDecimal(0, 2),
                perimeterAutoExtract: toDecimal(0, 2),
                count: secWork?.number ? Number(secWork?.number) : 1, // IQX_WEBEST-209
                countAutoExtract: secWork?.number ? Number(secWork?.number) : 1, // IQX_WEBEST-209
                blankFlag: false,
                blankFlagAutoExtract: false,
                workTypeGroup: typeGroup,
                workTypeGroupAutoExtract: typeGroup,
              };
              secWorkProcessInput.details.push(newListRow);
            }
          }
          updateProcessInputs.push(secWorkProcessInput);
        } else if (curWorkType === WorkType.SmDeburringAuto) {
          let autoDeburProcessInput = processInputs[j];
          autoDeburProcessInput.details[0].area = cardDetailsArea;
          autoDeburProcessInput.details[0].areaAutoExtract = cardDetailsArea; // IQX_WEBEST-179
          // if (weight) {
          autoDeburProcessInput.details[0].weight = Number.isFinite(weight) ? toDecimal(weight, 2) : toDecimal(0, 2);
          autoDeburProcessInput.details[0].weightAutoExtract = Number.isFinite(weight)
            ? toDecimal(weight, 2)
            : toDecimal(0, 2); // IQX_WEBEST-179
          // }
          updateProcessInputs.push(autoDeburProcessInput);
        } else if (curWorkType === WorkType.SmDeburringManual) {
          let manualDeburProcessInput = processInputs[j];
          manualDeburProcessInput.details[0].length = cardDetails?.contourLength?.outerLength
            ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
            : toDecimal(0, 2);
          manualDeburProcessInput.details[0].autoExtract = cardDetails?.contourLength?.outerLength
            ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
            : toDecimal(0, 2); // IQX_WEBEST-179
          manualDeburProcessInput.details[1].length = cardDetails?.contourLength?.innerLength
            ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
            : toDecimal(0, 2);
          manualDeburProcessInput.details[1].autoExtract = cardDetails?.contourLength?.innerLength
            ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
            : toDecimal(0, 2); // IQX_WEBEST-179
          updateProcessInputs.push(manualDeburProcessInput);
        } else if (curWorkType === WorkType.SmSurface) {
          let surfaceProcessInput = processInputs[j];
          if (surfaceProcessInput?.details) {
            surfaceProcessInput.details[0].areaUnitPrice = cardDetailsArea;
            surfaceProcessInput.details[0].areaAutoExtract = cardDetailsArea; // IQX_WEBEST-179
            surfaceProcessInput.details[0].totalArea = cardDetailsArea * 1;
            surfaceProcessInput.details[0].weightUnitPrice = Number.isFinite(weight)
              ? toDecimal(weight, 2)
              : toDecimal(0, 2);
            surfaceProcessInput.details[0].weightAutoExtract = Number.isFinite(weight)
              ? toDecimal(weight, 2)
              : toDecimal(0, 2); // IQX_WEBEST-179
            surfaceProcessInput.details[0].totalWeight = Number.isFinite(weight)
              ? toDecimal(weight, 2) * 1
              : toDecimal(0, 2);
          }

          updateProcessInputs.push(surfaceProcessInput);
        } else if (curWorkType === WorkType.SmShearing) {
          let shearingProcessInput = processInputs[j];
          shearingProcessInput.details[0].numOfCuts = 0;
          shearingProcessInput.details[0].autoExtract = 0; // IQX_WEBEST-179
          updateProcessInputs.push(shearingProcessInput);
        } else if (curWorkType === WorkType.SmInspection) {
          let inspectionProcessInput = processInputs[j];
          updateProcessInputs.push(inspectionProcessInput);
        }
      }
      //updateProcessInputs
      selectedPMXEstimateData.esIq3Info[i].processInput = updateProcessInputs;
    }
    setIsPMXComplete(true);
    setCheckPMXOrNot(true);
    let totalWeight = 0;
    let totalArea = 0;
    let totalPartCount = 0;
    if (isCreatePMX === CreatePMXData.Update) {
      if (props.selectedEstimateData.esIq3Info?.length > 0) {
        const listData = selectedPMXEstimateData?.esIq3Info;
        const updatedInfoArray = listData?.map((item, index) => ({
          ...item,
          no: props.selectedEstimateData?.esIq3Info?.length + 1 + index,
        }));

        setSelectediq3Data([...selectediq3Data, ...updatedInfoArray]);

        let iq3List = [...props.selectedEstimateData?.esIq3Info, ...updatedInfoArray];
        totalWeight = iq3List?.reduce((total, item) => {
          return total + parseFloat(item.weight);
        }, 0);
        totalArea = iq3List?.reduce((total, item) => {
          return total + parseFloat(item.totalSurfaceArea);
        }, 0);
        // IQX_WEBEST-68
        totalPartCount = iq3List?.reduce((total, item) => {
          return total + parseFloat(item.partCount);
        }, 0);
        // IQX_WEBEST-68
        let assenbleProcessInput = selectedEstimateData?.estimateProducts?.processInput?.assenbleProcessInputInfo;
        let inspectionProcessInput = selectedEstimateData?.estimateProducts?.processInput?.inspectionProcessInputInfo;
        let packingProcessInput = selectedEstimateData?.estimateProducts?.processInput?.packingProcessInputInfo;
        if (assenbleProcessInput) {
          assenbleProcessInput.smPartsCount = totalPartCount;
          assenbleProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
          selectedEstimateData.estimateProducts.processInput.assenbleProcessInputInfo = assenbleProcessInput;
        }
        if (inspectionProcessInput) {
          inspectionProcessInput.smPartsCount = totalPartCount;
          inspectionProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
          selectedEstimateData.estimateProducts.processInput.inspectionProcessInputInfo = inspectionProcessInput;
        }
        if (packingProcessInput) {
          packingProcessInput.smPartsCount = totalPartCount;
          packingProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
          selectedEstimateData.estimateProducts.processInput.packingProcessInputInfo = packingProcessInput;
        }

        let updatedEstimateData = {
          ...selectedEstimateData,
          estimateProducts: {
            ...selectedEstimateData?.estimateProducts,
            totalWeight: totalWeight,
            totalSurfaceArea: totalArea,
          },
          esIq3Info: [...selectedEstimateData?.esIq3Info, ...updatedInfoArray],
        };
        setSelectedEstimateData(updatedEstimateData);
        props.selectedEstimateData.estimateProducts.totalWeight = totalWeight;
        props.selectedEstimateData.estimateProducts.totalSurfaceArea = totalArea;
        props.selectedEstimateData.esIq3Info = [...props.selectedEstimateData?.esIq3Info, ...updatedInfoArray];
        props.updateSelectedEstimateDataByPMX(updatedEstimateData);
      } else {
        setSelectediq3Data([...selectediq3Data, ...selectedPMXEstimateData.esIq3Info]);
        setSelectedEstimateData({
          ...selectedEstimateData,
          esIq3Info: [...selectedEstimateData?.esIq3Info, ...selectedPMXEstimateData.esIq3Info],
        });
        props.selectedEstimateData.esIq3Info = [
          ...props.selectedEstimateData?.esIq3Info,
          ...selectedPMXEstimateData.esIq3Info,
        ];
        props.updateSelectedEstimateDataByPMX(selectedPMXEstimateData);
      }
    } else {
      // IQX_WEBEST-68
      let iq3List = selectedPMXEstimateData.esIq3Info;
      totalWeight = iq3List?.reduce((total, item) => {
        return total + parseFloat(item.weight);
      }, 0);
      totalArea = iq3List?.reduce((total, item) => {
        return total + parseFloat(item.totalSurfaceArea);
      }, 0);
      totalPartCount = iq3List?.reduce((total, item) => {
        return total + parseFloat(item.partCount);
      }, 0);
      let assenbleProcessInput = selectedPMXEstimateData?.estimateProducts?.processInput?.assenbleProcessInputInfo;
      let inspectionProcessInput = selectedPMXEstimateData?.estimateProducts?.processInput?.inspectionProcessInputInfo;
      let packingProcessInput = selectedPMXEstimateData?.estimateProducts?.processInput?.packingProcessInputInfo;
      if (assenbleProcessInput) {
        assenbleProcessInput.smPartsCount = totalPartCount;
        assenbleProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
        selectedPMXEstimateData.estimateProducts.processInput.assenbleProcessInputInfo = assenbleProcessInput;
      }
      if (inspectionProcessInput) {
        inspectionProcessInput.smPartsCount = totalPartCount;
        inspectionProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
        selectedPMXEstimateData.estimateProducts.processInput.inspectionProcessInputInfo = inspectionProcessInput;
      }
      if (packingProcessInput) {
        packingProcessInput.smPartsCount = totalPartCount;
        packingProcessInput.autoExtract = totalPartCount; // IQX_WEBEST-179
        selectedPMXEstimateData.estimateProducts.processInput.packingProcessInputInfo = packingProcessInput;
      }
      setSelectedEstimateData({ ...selectedPMXEstimateData });
      props.updateSelectedEstimateDataByPMX(selectedPMXEstimateData);
    }
    // props?.loading(false); // PMXデータ読み取り、完了後Waitingダイアログを非表示にする
    setIq3DataConfirm(false);
  };

  const backProcessPattern = () => {
    setIsNext(false);
    setCurrentStep(1);
  };

  const nextIQ3FileLoad = () => {
    if (pmxTabKey == '1') {
      setPMXTabKey('2');
    }
  };
  const cloudPMXFileLoadOk = () => {
    setIsPMXCloudFileDialogOpen(false);
    openPMXProcessPatternDialog();
  };
  const cloudPMXFileLoadCancel = () => {
    setIsPMXCloudFileDialogOpen(false);
  };
  const onChangeProcessPatternInfo = (type, key) => {
    // const pmxCloudSelectedData = pmxCloudParentRef?.current?.getSelectedParent();
    const data = { ...selectedPMXEstimateData };

    // const data = { ...pmxSelectedEstimateData };
    if (type == 0) {
      setParentPatternNo(key);
      const selectedPatternInfo = patternList?.filter((item) => item.id == key);
      // data.esIq3Info[0].processSelect = selectedPatternInfo[0];
      // setSelectedPMXEstimateData(data);
      data.estimateProducts.processSelect = selectedPatternInfo[0];

      setSelectedPMXEstimateData(data);

      // data.kouteiSentakuPatternNo = key;
      // setSelectedEstimateData(data);
    } else {
      setChildPatternNo(key);
      const selectedPatternInfo = iq3PatternList?.filter((item) => item.id == key);
      // data.esIq3Info[0].processSelect = selectedPatternInfo[0];
      const updatedLists = data?.esIq3Info?.map((item) => ({
        ...item,
        processSelect: selectedPatternInfo[0],
      }));
      data.esIq3Info = updatedLists;

      setSelectedPMXEstimateData(data);
      // props.updateSelectedEstimateData(selectedPMXEstimateData);

      // data.iq3DataLst[0].iq3KouteiSentakuPatternNo = key;
      // setSelectedEstimateData(data);
    }

    // setPatternNo(id);
    // let patternList = props.listData;
    // const selectedPatternInfo = patternList?.filter((item) => item.id == id);
    // setSelectedPatternInfo(selectedPatternInfo[0]);
    // if (selectedPatternInfo?.length > 0) {
    //   if (props.isParameter) {
    //     // 選択したパターンキーより一覧の選択行を変更
    //     props.updateSelectedData(selectedPatternInfo[0]);
    //   } else {
    //     // 選択したパターンキーより親部品工程選択・子部品工程選択の選択パターン番号を変更
    //     props.updateKouteiSentakuInfo(selectedPatternInfo[0], patternList);
    //   }
    // }
  };
  const reportOutputOk = async () => {
    let estimateProductId = props.selectedEstimateData?.estimateProducts?.id;
    let iq3Infos = await getEstimateIq3Info(estimateProductId);

    convertServerExcelToHtml(
      selectedExeclIndividualFormat,
      props.selectedEstimateData,
      props.selectedEstimateData.esIq3Info
    );
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
    setIsListOutputOpen(false);
  };
  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
    setIsListOutputOpen(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      getSelectedEstimateData: () => {
        return selectedEstimateData;
      },
      getParentEstimateData: getParentEstimate,
      getEstimateIq3Data: getEstimateIq3,
      callFormValidation: formValidation,
      updateEstimateInfo: updateEstimateInfo,
      getParentEstimateRef: () => {
        return parentEstimateRef;
      },
      getPMXEstimateInof: () => {
        return selectedPMXEstimateData;
      },
      getSelectedEstimateDataOrg: () => {
        return selectedEstimateDataTemp;
      },
      isChangedEstimateInfo: () => {
        return isChangedEstimateInfo();
      },
    }),
    [selectedEstimateData, editMode, selectedEstimateDataTemp]
  );

  const formValidation = () => {
    let validation = parentEstimateRef?.current?.kihonFormValidation();
    if (validation) {
      validation = iQ3EstimateRef?.current?.kihonFormValidation();
    }
    return validation;
  };

  // 見積情報に変更があるかチェック
  const isChangedEstimateInfo = () => {
    let currentEstInfo = props?.selectedEstimateData;
    let oldEstInfo = selectedEstimateDataTemp;
    let currentEstProductInfo = currentEstInfo?.estimateProducts;
    let oldEstProductInfo = oldEstInfo?.estimateProducts;
    let currentEstIq3Info = currentEstInfo?.esIq3Info;
    let oldEstIq3Info = oldEstInfo?.esIq3Info;

    // 追加項目・費用
    let isEqual = lodash.isEqual(oldEstInfo?.epCustomCosts, currentEstInfo?.epCustomCosts);
    if (!isEqual) return true;
    // 追加項目・日付
    isEqual = lodash.isEqual(oldEstInfo?.epCustomDates, currentEstInfo?.epCustomDates);
    if (!isEqual) return true;
    // 追加項目・ラベル
    isEqual = lodash.isEqual(oldEstInfo?.epCustomLabels, currentEstInfo?.epCustomLabels);
    if (!isEqual) return true;
    // 追加項目・メモ
    isEqual = lodash.isEqual(oldEstInfo?.epCustomMemos, currentEstInfo?.epCustomMemos);
    if (!isEqual) return true;
    // 工程選択
    isEqual = lodash.isEqual(oldEstProductInfo?.processSelect, currentEstProductInfo?.processSelect);
    if (!isEqual) return true;
    // 工程入力
    isEqual = lodash.isEqual(oldEstProductInfo?.processInput, currentEstProductInfo?.processInput);
    if (!isEqual) return true;
    // 購入品
    isEqual = lodash.isEqual(oldEstProductInfo?.purchases, currentEstProductInfo?.purchases);
    if (!isEqual) return true;
    // 係数項目
    isEqual = lodash.isEqualWith(
      oldEstProductInfo?.otherProduct,
      currentEstProductInfo?.otherProduct,
      isEqualOtherProduct
    );
    if (!isEqual) return true;
    // その他情報
    isEqual = lodash.isEqual(oldEstProductInfo?.info, currentEstProductInfo?.info);
    if (!isEqual) return true;
    // 工程積算
    isEqual = lodash.isEqualWith(oldEstProductInfo?.additions, currentEstProductInfo?.additions, isEqualAdditions);
    if (!isEqual) return true;
    // // 添付ファイル
    // isEqual = lodash.isEqual(oldEstProductInfo?.attachments, currentEstProductInfo?.attachments);
    // if (!isEqual) return true;
    // 数量別見積確認情報
    isEqual = lodash.isEqual(oldEstProductInfo?.byQuantities, currentEstProductInfo?.byQuantities);
    if (!isEqual) return true;
    // 基本情報
    isEqual = lodash.isEqual(oldEstProductInfo, currentEstProductInfo);
    if (!isEqual) return true;

    for (var i = 0; i < currentEstIq3Info?.length; i++) {
      let currentIq3Info = currentEstIq3Info?.[i];
      let oldIq3Info = oldEstIq3Info?.[i];
      isEqual = lodash.isEqual(oldIq3Info?.additions, currentIq3Info?.additions, isEqualAdditions);
      if (!isEqual) break;
      // 板金、その他情報
      isEqual = lodash.isEqual(oldIq3Info?.info, currentIq3Info?.info);
      if (!isEqual) break;
      // 板金、材料詳細のサイズ情報
      isEqual = lodash.isEqual(oldIq3Info?.materialDetailSizes, currentIq3Info?.materialDetailSizes);
      if (!isEqual) break;
      // 板金、材料詳細のパターン情報
      isEqual = lodash.isEqual(oldIq3Info?.materialDetailPatterns, currentIq3Info?.materialDetailPatterns);
      if (!isEqual) break;
      // 板金、工程選択
      isEqual = lodash.isEqual(oldIq3Info?.processSelect, currentIq3Info?.processSelect);
      if (!isEqual) break;
      // 板金、工程入力
      isEqual = lodash.isEqual(oldIq3Info?.processInput, currentIq3Info?.processInput);
      if (!isEqual) break;
      // 板金、購入品
      isEqual = lodash.isEqual(oldIq3Info?.purchases, currentIq3Info?.purchases);
      if (!isEqual) break;
    }
    if (!isEqual) return true;
    // 板金見積情報
    isEqual = lodash.isEqual(oldEstIq3Info, currentEstIq3Info);
    return !isEqual;
  };

  // 係数項目
  const isEqualOtherProduct = (objValue, othValue) => {
    if (Number(objValue?.adjustment) !== Number(othValue?.adjustment)) return false;
    if (objValue?.adjustmentMethod !== othValue?.adjustmentMethod) return false;
    if (Number(objValue?.coeffClient) !== Number(othValue?.coeffClient)) return false;
    if (Number(objValue?.coeffOrders) !== Number(othValue?.coeffOrders)) return false;
    if (Number(objValue?.coeffPurchases) !== Number(othValue?.coeffPurchases)) return false;
    if (Number(objValue?.coeffManagements) !== Number(othValue?.coeffManagements)) return false;
    if (Number(objValue?.coeffDeliveryDates) !== Number(othValue?.coeffDeliveryDates)) return false;
    return true;
  };

  // 工程積算項目
  const isEqualAdditions = (objValue, othValue) => {
    objValue?.sort((a, b) => a?.processId - b?.processId);
    othValue?.sort((a, b) => a?.processId - b?.processId);
    let isEqual = false;
    isEqual = lodash.isEqual(objValue, othValue);
    return isEqual;
  };

  const getParentEstimate = () => {
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;
    // currentEstimateData.estimateProducts.calcParameters.parameters = oldParameters;
    return currentEstimateData;
  };

  const getEstimateIq3 = () => {
    let currentEstimateIq3Data = iQ3EstimateRef?.current?.getEstimateIq3Info();
    return currentEstimateIq3Data;
  };

  // 新規作成ボタンを押した場合(IQX_WEBEST-172)
  const createNewParentInfo = () => {
    props?.updateNewModeByParent(true);
  };

  // CURT.パラボタンを押した場合(IQX_WEBEST-171)
  const currentParamInfo = () => {
    setIsCurtParam(true);
    let paramUpdateFlgInfos = props?.userSettingInfo?.[0]?.parametersUpdateFlgInfos;
    if (
      paramUpdateFlgInfos?.isMaterialParam === undefined &&
      paramUpdateFlgInfos?.isProcessParam === undefined &&
      paramUpdateFlgInfos?.isPurchaseParam === undefined &&
      paramUpdateFlgInfos?.isOtherParam === undefined
    ) {
      setParamUpdateFlgInfos(JSON.parse(JSON.stringify(getInitParamUpdateFlgInfos)));
    } else {
      setParamUpdateFlgInfos(paramUpdateFlgInfos);
    }
  };

  //　追加呼出ボタンの押下
  const displayAddParentInfo = async () => {
    let newParameters = await GetParametersByCompId();
    newParameters.estimateStatus = newParameters?.estimateStatus?.filter((item) => item.info.isUsed);
    newParameters.staff = newParameters?.staff?.filter((item) => item.info.isUsed);
    newParameters.client = newParameters?.client?.filter((item) => item.info.isUsed);
    setNewParameters(newParameters);
    setYobidashiClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
      return;
    }
    // 子部品追加呼出
    setParentListDisplay(true);
    setParentLoading(true); // Waitingダイアログを表示にする
  };

  // 追加呼出ダイアログのOKボタン押下
  const selectParentInfoOk = async () => {
    setParentListDisplay(false);
    props?.loading(true); // Waitingダイアログを表示にする
    if (selectedParentAddEstimate !== undefined && selectedParentAddEstimate?.estimateProducts?.id) {
      let estimateProductId = selectedParentAddEstimate?.estimateProducts?.id;
      const addIQ3Lst = await getEstimateIq3Info(estimateProductId);
      let selectIq3Data = JSON.parse(JSON.stringify(selectedEstimateData?.esIq3Info));
      var lastRecord = selectIq3Data?.length > 0 ? selectIq3Data[selectIq3Data?.length - 1] : undefined;
      let no = lastRecord ? lastRecord?.no + 1 : 1;
      const addNewIQ3Lst = [];
      for (let i = 0; i < addIQ3Lst?.length; i++) {
        var iq3Data = { ...addIQ3Lst[i] };
        iq3Data.id = 0;
        iq3Data.no = no;
        addNewIQ3Lst.push(iq3Data);
        no++;
      }
      setSelectediq3Data([...selectediq3Data, ...addNewIQ3Lst]);
      setSelectedEstimateData({
        ...selectedEstimateData,
        esIq3Info: [...selectedEstimateData?.esIq3Info, ...addNewIQ3Lst],
      });
      props.selectedEstimateData.esIq3Info = [...props.selectedEstimateData?.esIq3Info, ...addNewIQ3Lst];
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 追加呼出ダイアログのCancelボタン押下
  const selectParentInfoCancel = () => {
    setParentListDisplay(false);
  };

  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedParentAddEstimate(estimateData);
  };

  const updateSelectedPatternNo = (id) => {
    setChildPatternNo(id);
  };
  const updateSelectedParentPatternNo = (id) => {
    setParentPatternNo(id);
  };
  const updatePatternList = (selectedProcessPattern, list, type) => {
    const data = { ...pmxSelectedEstimateData };

    if (type === 'iQ3') {
      data.iq3DataLst[0].iq3KouteiSentakuPatternNo[0].id = selectedProcessPattern.id;
      setPMXSelectedEstimateData(data);
      setUpdateIQ3PatternList(list);
    } else {
      data.kouteiSentakuPatternNo = selectedProcessPattern.id;
      setPMXSelectedEstimateData(data);
      setUpdateParentPatternList(list);
    }
  };

  const updatePMXEvent = () => {
    props.updatePMXEvent();
  };

  const updateCheckPMXOrNot = () => {
    setCheckPMXOrNot(false);
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <Button
          key="parent-tab"
          type="primary"
          className={activePartMenu == '1' ? 'estimate-tab-btn-active first-btn' : 'estimate-tab-btn first-btn'}
        >
          親部品
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button
          key="iq3-tab"
          type="primary"
          className={activePartMenu == '2' ? 'estimate-tab-btn-active last-btn' : 'estimate-tab-btn last-btn'}
        >
          iQ3板金
        </Button>
      ),
    },
    // {
    //   key: '3',
    //   label: `iQ5形鋼`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
    // ,
    // {
    //   key: '4',
    //   label: `iQ7機械`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
  ];

  // 保存ボタン
  const saveMenuItems = [
    {
      label: <Image preview={false} width={33} src={save} style={{ marginRight: '6px' }}></Image>,
      key: 'save',
      children: [
        {
          label: saveMenuChildItems[0]?.label,
          key: saveMenuChildItems[0]?.key,
        },
        {
          label: saveMenuChildItems[1]?.label,
          key: saveMenuChildItems[1]?.key,
        },
      ],
    },
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={30} src={export_report} style={{ marginLeft: '25px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
        {
          label: '親部品帳票',
          key: 'parentReport',
        },
      ],
    },
  ];
  const listOutputMenuitems = [
    {
      label: (
        <Image
          preview={false}
          width={iconWidth}
          src={export_csv_icon}
          style={{ marginLeft: '30px', textDecoration: 'none' }}
        ></Image>
      ),
      key: 'listOutput',
      children: [
        {
          label: 'CSV出力(表示)',
          key: 'csvReport',
        },
        {
          label: 'CSV出力(全て)',
          key: 'csvAllReport',
        },
        {
          label: 'iQ見積アナライザ',
          key: 'iQReport',
        },
        {
          label: '明細出力',
          key: 'detailsReport',
        },
      ],
    },
  ];

  const pmxMenuItems = [
    {
      label: (
        <Image preview={false} width={28} src={pmx_import} style={{ marginLeft: '0px', marginTop: '0px' }}></Image>
      ),
      key: 'save',
      children: [
        {
          label: 'クラウド',
          key: 'cloud',
        },
        // {
        //   label: 'ローカル',
        //   key: 'local',
        // },
      ],
    },
  ];

  const pcSaveMenuitems = [
    {
      label: <Image preview={false} width={28} src={pc_save} style={{ marginLeft: '10px' }}></Image>,
      key: 'pcSave',
      children: [
        {
          label: 'PC保存',
          key: 'pcSave',
        },
        {
          label: 'PC呼出',
          key: 'pcLoad',
        },
      ],
    },
  ];

  const reportModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image
        preview={false}
        width={20}
        src={confirm_icon}
        style={{ marginTop: 9, marginLeft: 7, display: isEstimateDataSaveConfirm ? '' : 'none' }}
      ></Image>
      <p style={{ marginLeft: 7, paddingTop: 8, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const pmxConfirmModalTitle = (
    <div
      style={{
        width: 300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image preview={false} width={20} src={logo} style={{ marginTop: 9, marginLeft: 7 }}></Image>
      <p style={{ marginLeft: 10, paddingTop: 8, color: 'white' }}>PMX取り込み</p>
    </div>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const sameDrawingNoModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const curtParamModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>CURT.パラメータの読込確認</p>
    </div>
  );

  /* 確認メッセージのタイトル */
  const confirmModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  // 帳票出力
  const reportParentList = (
    <>
      <Row style={{ marginTop: 10 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          {isListOutputOpen
            ? 'CSV出力するフォーマットを選択してください'
            : '帳票出力するフォーマットを選択してください'}
        </p>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力フォーマット：</p>
        </Col>
        <Col span={17}>
          {excelIndividualFormats !== undefined && excelIndividualFormats?.length > 0 ? (
            <Select
              style={{ width: 300, fontSize: 11 }}
              value={selectedExeclIndividualFormat?.id}
              onChange={(e) => {
                const selectedData = excelIndividualFormats?.find((item) => item?.id === e);
                if (selectedData !== undefined) {
                  setSelectedExeclIndividualFormat(selectedData);
                }
              }}
            >
              {excelIndividualFormats?.map((item) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.fileName}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select style={{ width: 300, fontSize: 11 }} />
          )}
        </Col>
      </Row>
      {/* <Row style={{ marginTop: 10 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル：</p>
        </Col>
        <Col span={17}>
          <Input className="input-editable" style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px', marginLeft: '5px' }}>参照</Button>
          </Upload>
        </Col>
      </Row> */}
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // PC保存
  const estimateDataSaveContent = (
    <>
      <Row style={{ marginTop: 0 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          パラメータが更新されています。最新を使用する項目を選択して下さい
        </p>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>材料費</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>装置のチャージ金額/係数</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>購入品</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            再計算
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // PMX取り込みコンテンツ
  const pmxConfirmContent = (
    <>
      <Row style={{ marginTop: 10 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '編集中見積り保存して、新規見積', CreatePMXData.SaveAndNew)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          編集中見積り保存して、新規見積
        </a>
      </Row>
      <Row style={{ marginTop: 7 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '新規見積', CreatePMXData.New)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          新規見積
        </a>
      </Row>
      <Row style={{ marginTop: 7 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '編集中見積に追加', CreatePMXData.Update)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          編集中見積に追加
        </a>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 15,
        }}
      >
        <Button className="cancelButton" onClick={pmxLocalProcessCancel}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 別名保存
  const saveByOtherNameInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        表示中の見積データを別名で保存します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                // ref={ref}
                name="inputDrawingNo"
                id="inputDrawingNo"
                value={inputDrawingNo}
                type="text"
                onChange={(e) => {
                  setInputDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">見積番号</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="estimateNo"
                id="estimateNo"
                value={estimateNo}
                type="text"
                onChange={(e) => {
                  setEstimateNo(e.target.value);
                }}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // CURT.パラメータの読込確認のフォーム
  const curtParamInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>CURT.パラに更新する項目を選択してください。</p>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isMaterialParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isMaterialParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>材料系（材質区分、材質名称、材料名称・重量単価）</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isProcessParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isProcessParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>加工系（工程マスター、装置設定、工程パターン）</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isPurchaseParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isPurchaseParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>購入品</label>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col span={2}>
          <Checkbox
            checked={paramUpdateFlgInfos?.isOtherParam}
            onChange={(e) => {
              setParamUpdateFlgInfos((prevData) => {
                return { ...prevData, isOtherParam: e.target.checked };
              });
            }}
          />
        </Col>
        <Col>
          <label>その他全て（係数、カスタマイズ・・・）</label>
        </Col>
      </Row>
    </>
  );

  // 別名保存入力ボックスのOKボタン押下
  const saveByOtherNameOk = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    setIsSaveByOtherName(false);
    // if (activePartMenu === '1') {
    if (!formValidation()) {
      props?.loading(false); // Waitingダイアログを非表示にする
      return;
    }

    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;
    const copyData = JSON.parse(JSON.stringify(currentEstimateData));

    copyData.estimateProducts = {
      ...copyData.estimateProducts,
      id: 0,
      estimateNo: estimateNo,
      drawingNo: inputDrawingNo,
      name: parentEstimateName,
    };

    // 同一図番があるか存在チェックする
    let companySetting = props?.companySettingInfo?.[0];
    if (!isSaveSameDrawingNo && !companySetting?.environments?.isUseSameName) {
      let estProducts = copyData.estimateProducts;
      let saveSameType = companySetting?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        props?.loading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveByOtherNameOk');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
      return { ...cost, id: 0 };
    });
    copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
      return { ...date, id: 0 };
    });
    copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
      return { ...label, id: 0 };
    });
    copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
      return { ...memo, id: 0 };
    });

    copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));

    let createdData = await createEstimateProductInfo(copyData);
    if (createdData && createdData?.estimateProducts !== undefined && createdData?.estimateProducts?.id !== undefined) {
      //データ保存成功の場合
      props.selectedEstimateData.estimateProducts.id = createdData?.estimateProducts?.id;
      copyData.estimateProducts.id = createdData?.estimateProducts?.id;
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(copyData)));
      setSelectedEstimateData(copyData);
    } else {
      //データ保存失敗の場合
      props.selectedEstimateData.estimateProducts.id = 0;
      copyData.estimateProducts.id = 0;
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(copyData)));
      setSelectedEstimateData(copyData);
    }

    setSaveDiscardConfirmPopup(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // } else if (navigateTabName === 'parent') {
    //   navigate(':estimate.parent');
    //   setActivePartMenu('1');
    // }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 別名保存入力ボックスのCancelボタン押下
  const saveByOtherNameCancel = () => {
    setIsSaveByOtherName(false);
  };

  // 同一図面で保存する確認メッセージのOkボタン押下
  const saveSameDrawingNoOk = async () => {
    await eval(saveSameDrawingNoFncName + '()');
  };

  // 同一図面で保存する確認メッセージのCancelボタン押下
  const saveSameDrawingNoCancel = () => {
    setIsSaveSameDrawingNo(false);
  };

  // 上書き保存のOKボタン押下
  const saveByOverwriteOk = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    setIsSaveByOverwrite(false);
    // if (activePartMenu === '1') {
    if (!formValidation()) {
      props?.loading(false); // Waitingダイアログを非表示にする
      return;
    }
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();

    // 同一図番があるか存在チェックする
    let companySetting = props?.companySettingInfo?.[0];
    if (!isSaveSameDrawingNo && !companySetting?.environments?.isUseSameName) {
      let estProducts = currentEstimateData?.estimateProducts;
      let saveSameType = companySetting?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        props?.loading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('saveByOverwriteOk');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;

    let updatedData = await updateEstimateProductInfo(currentEstimateData);
    if (updatedData) {
      // setSelectedEstimateDataTemp(currentEstimateData);
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(currentEstimateData)));
      setSelectedEstimateData(currentEstimateData);
    }

    // await getEstiamteIQ3Data(updatedData?.estimateProducts?.id);
    // } else {
    // }
    setSaveDiscardConfirmPopup(false);
    props.updateNewMode(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // } else if (navigateTabName === 'parent') {
    //   navigate(':estimate.parent');
    //   setActivePartMenu('1');
    // }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  // 上書き保存のCancelボタン押下
  const saveByOverwriteCancel = () => {
    setIsSaveByOverwrite(false);
  };
  //新規保存
  const createOk = async () => {
    props?.loading(true); // Waitingダイアログを表示にする
    setCreateConfirm(false);
    // if (activePartMenu == '1') {
    // 親部品見積のデータ保存
    let parentValidation = parentEstimateRef?.current?.kihonFormValidation();
    if (!parentValidation) {
      props?.loading(false); // Waitingダイアログを非表示にする
      return;
    }
    let iq3Validation = iQ3EstimateRef?.current?.kihonFormValidation();
    if (!iq3Validation) {
      props?.loading(false); // Waitingダイアログを非表示にする
      return;
    }

    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();

    // 同一図番があるか存在チェックする
    let companySetting = props?.companySettingInfo?.[0];
    if (!isSaveSameDrawingNo && !companySetting?.environments?.isUseSameName) {
      let estProducts = currentEstimateData?.estimateProducts;
      let saveSameType = companySetting?.environments?.saveSameType;
      let isExist = await getIsExistSameEstimate(estProducts, saveSameType);
      if (isExist) {
        props?.loading(false); // Waitingダイアログを非表示にする
        setSaveSameDrawingNoFncName('createOk');
        setIsSaveSameDrawingNo(true);
        return;
      }
    } else {
      setIsSaveSameDrawingNo(false);
    }

    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;

    let createdData = await createEstimateProductInfo(currentEstimateData);
    if (createdData && createdData?.estimateProducts !== undefined && createdData?.estimateProducts?.id !== undefined) {
      //データ保存成功の場合
      props.selectedEstimateData.estimateProducts.id = createdData?.estimateProducts?.id;
      currentEstimateData.estimateProducts.id = createdData?.estimateProducts?.id;
      setSelectedEstimateData(currentEstimateData);
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(currentEstimateData)));
    } else {
      //データ保存失敗の場合
      props.selectedEstimateData.estimateProducts.id = 0;
      currentEstimateData.estimateProducts.id = 0;
      setSelectedEstimateData(currentEstimateData);
      setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(currentEstimateData)));
    }
    // await getEstiamteIQ3Data(createdData?.estimateProducts?.id);
    setCreateConfirm(false);
    setSaveDiscardConfirmPopup(false);
    props.updateNewMode(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // }
    // } else {
    //   // 板金の見積データ保存
    //   if (navigateTabName === 'parent') {
    //     navigate(':estimate.parent');
    //     setActivePartMenu('1');
    //   }
    // }
    props?.loading(false); // Waitingダイアログを非表示にする
  };
  // 新規保存のCancelボタン押下
  const createCancel = () => {
    setCreateConfirm(false);
  };

  // 破壊確認メッセージのOKボタン押下
  const discardOk = () => {
    props?.loading(true); // Waitingダイアログを表示にする
    const data = JSON.parse(JSON.stringify(selectedEstimateDataTemp));
    if (data) {
      let calEstimateData = updateEstimateInfoByReCal(data);
      updateEstimateInfo(calEstimateData);
      props.updateSelectedEstimateData(calEstimateData);
    }
    setDiscardConfirm(false);
    setSaveDiscardConfirmPopup(false);
    if (navigateTabName === 'sheetMetal') {
      navigate(':estimate.iq3');
      setActivePartMenu('2');
      props?.updateActiveTabKey('2');
    } else if (navigateTabName === 'parent') {
      navigate(':estimate.parent');
      setActivePartMenu('1');
      props?.updateActiveTabKey('1');
    }
    props?.loading(false); // Waitingダイアログを非表示にする
  };

  const discardCancel = () => {
    setDiscardConfirm(false);
  };

  // CURT.パラメータ読込確認のOKボタン押下
  const curtParamOk = () => {
    setUpdateConfirm(true);
  };

  // CURT.パラメータ読込確認のCancelボタン押下
  const curtParamCancel = () => {
    setIsCurtParam(false);
  };

  // 更新確認メッセージのOKボタン押下
  const confirmOk = async () => {
    if (isCurtParam) {
      props?.loading(true); // Waitingダイアログを表示にする
      setIsCurtParam(false);
      setUpdateConfirm(false);
      if (props?.userSettingInfo && props.userSettingInfo?.length > 0) {
        props.userSettingInfo[0]['parametersUpdateFlgInfos'] = paramUpdateFlgInfos;
        let result = await updateUserSetting(props?.userSettingInfo[0], 3);
        if (result) {
          let msgStr = 'CURT.パラに以下の項目が更新されました。\n更新されたパラメータ情報により、再計算されます。\n';
          if (paramUpdateFlgInfos?.isMaterialParam) msgStr += '・材料系\n';
          if (paramUpdateFlgInfos?.isProcessParam) msgStr += '・加工系\n';
          if (paramUpdateFlgInfos?.isPurchaseParam) msgStr += '・購入品\n';
          if (paramUpdateFlgInfos?.isOtherParam) msgStr += '・その他全て（係数。カスタマイズ・・・）\n';
          complete(msgStr);
        }
        let flgInfos = paramUpdateFlgInfos;
        props?.settingParametersByReCal(
          // 選択した、パラメータタイプを更新して再計算する
          ParamCalType.NewParam,
          flgInfos?.isMaterialParam,
          flgInfos?.isProcessParam,
          flgInfos?.isPurchaseParam,
          flgInfos?.isOtherParam
        );
      }
      // IQX_WEBEST-214
      props?.loading(false); // Waitingダイアログを非表示にする
    }
  };

  // 更新確認メッセージのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
  };

  const pmxTabItems = [
    {
      key: '1',
      label: `親部品`,
      children: <PMX_CloudFileDialog_Tab_Parent selectedParentRows={getSelectedParentRows} ref={pmxCloudParentRef} />,
    },
    {
      key: '2',
      label: `板金`,
      children: <PMX_CloudFileDialog_Tab_IQ3 selectedIQ3Rows={getSelectedIQ3Rows} ref={pmxCloudIQ3Ref} />,
    },
  ];

  const parentListModalTitle = (
    <div
      style={{
        width: 1670,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 33,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 5, paddingLeft: 18, color: 'white' }}>追加呼出</p>
    </div>
  );
  const estimateDataDiscardEvent = () => {
    //setDiscardConfirm(true);

    let editCancel =
      props?.userSettingInfo?.[0]?.massages?.editCancel == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.editCancel;
    setDiscardConfirm(editCancel);
    if (!editCancel) {
      discardOk();
    }
  };

  const closeOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  // 別名保存の入力フォーム
  const saveEstimateDataByOtherName = () => {
    setIsSaveByOtherName(true);
    // setInputDrawingNo(formatDateString(todayTime()));
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    const data = JSON.parse(JSON.stringify(currentEstimateData));
    setEstimateNo(data?.estimateProducts?.estimateNo);
    setInputDrawingNo(data?.estimateProducts?.drawingNo);
    setParentEstimateName(formatDateString(todayTime()));
  };

  const cancelOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  // まとめて編集(IQX_WEBEST-177)
  const updateAll = () => {
    setIsUpdate(true);
    setMaterialTypeId('');
    setThickness('');
    setMaterialSurfaceId('');
    setPartCount('');
    setBulkIQ3DataLst(selectedEstimateData?.esIq3Info);
    const patternLst = estimateParameters?.processPattern;
    let kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);
    setListData(kouteiPatternList);
    setProcessSelectedData(kouteiPatternList[0]);
    setChkRowsCount(0);
    setBulkEditSelectedRowId([]);
    setBulkEditSelectedRow([]);
    // タブの初期表示
    let iq3ActiveTabKey = iQ3EstimateRef?.current?.getIQ3ActiveTabKey();
    if (iq3ActiveTabKey === '2') {
      setActiveBulkEditTabKey('2');
    } else {
      setActiveBulkEditTabKey('1');
    }
    setChildTitle('選択したデータをまとめて編集');
  };

  // 一括編集-開始
  let checkedRow = [];
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow?.length);
  };

  const updateData = () => {
    setIsUpdate(false);
    const data = [...bulkIQ3DataLst];
    for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
      const index = data?.findIndex((item) => item?.no === bulkEditSelectedRow[i]?.no);
      if (index >= 0 && data[index]) {
        //基本情報更新
        if (partCount !== '') {
          data[index].partCount = partCount;
        }
        if (materialTypeId !== '') {
          let materilTypeNm = getMaterialTypeById(materialTypeId, materialTypeLst);
          data[index].materialTypeName = materilTypeNm;
          data[index].materialTypeIq3Id = materialTypeId;
        }
        if (thickness !== '') {
          data[index].thickness = thickness;
          data[index].materialName = getMaterialNameById(thickness, materialLst);
          data[index].materialIq3Id = thickness;
        }
        if (materialSurfaceId !== '') {
          data[index].materialSurfaceId = materialSurfaceId;
          data[index].materialSurfaceName = getMaterialSurfaceById(materialSurfaceId, materialSurfaceLst);
          data[index].materialSurfaceIq3Id = materialSurfaceId;
        }
        // 工程選択情報更新
        if (Object.keys(processSelectedData).length > 0) {
          data[index].processSelect = processSelectedData;
        }
      }
    }
    //CURT.iq3リストに更新
    setBulkIQ3DataLst(data);
    iQ3EstimateRef?.current?.updateIQ3DataByBulkEdit(data);
  };

  const discardData = () => {
    setIsUpdate(false);
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  // タブの切り替え
  const onChangeBulkEditTab = (key) => {
    setActiveBulkEditTabKey(key);
  };

  const updateKouteiSentakuInfo = (selectedProcessPattern, list) => {
    setProcessSelectedData(selectedProcessPattern);
  };

  // 部品追加ボタンの押下
  const addPart = () => {
    let iq3Data = [...iq3EstimateInitInfo];
    var copyPart = iQ3EstimateRef?.current?.getNewIQ3EstimateInfo(JSON.parse(JSON.stringify(iq3Data)));
    if (copyPart?.length > 0) copyPart = copyPart[0];
    let tempIQ3List = [...bulkIQ3DataLst];
    if (bulkIQ3DataLst != undefined && bulkIQ3DataLst?.length > 0) {
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        name: 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5),
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
    } else {
      copyPart = {
        ...copyPart,
        no: lastRecord?.no + 1,
        materialCostUnitPrice: 0,
        materialCostTotalPrice: 0,
        costUnitPrice: 0,
      };
    }
    if (tempIQ3List?.length > 0) {
      tempIQ3List?.push(copyPart);
      tempIQ3List?.sort((a, b) => a?.no - b?.no);
      setBulkIQ3DataLst(tempIQ3List);
    }
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    if (bulkIQ3DataLst?.length <= 1) return;
    let tempIQ3List = [...bulkIQ3DataLst];
    for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
      tempIQ3List = tempIQ3List?.filter((item) => item.no !== bulkEditSelectedRow[i].no);
      tempIQ3List = tempIQ3List?.map((item, index) => {
        return { ...item, no: index + 1 };
      });
    }
    if (bulkEditSelectedRow?.length > 0) {
      tempIQ3List?.sort((a, b) => a?.no - b?.no);
      if (tempIQ3List?.length > 0) setBulkIQ3DataLst(tempIQ3List);
      setBulkEditSelectedRow([]);
      setBulkEditSelectedRowId([]);
    }
  };

  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          見積データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          {selectedEstimateData?.estimateProducts?.id === 0 ? (
            <>
              <Button className="mainButton" onClick={createOk}>
                保存
              </Button>
            </>
          ) : (
            <>
              <Space size={'middle'}>
                <Button className="mainButton" onClick={saveEstimateDataByOtherName}>
                  別名保存
                </Button>
                <Button className="mainButton" onClick={saveByOverwriteOk}>
                  上書保存
                </Button>
              </Space>
            </>
          )}
        </Col>

        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={discardOk}>
              破棄
            </Button>
            <Button className="cancelButton" onClick={cancelOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );

  const editModalTitle = (
    <div
      style={{
        width: isUpdate ? 1790 : 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );
  const bulkEditTabItems = [
    {
      key: '1',
      label: `基本情報`,
    },
    {
      key: '2',
      label: `工程選択`,
    },
  ];

  // 一括編集画面で員数を変更した場合、
  const onChangePartCount = (value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
      if (!isDigit(value)) value = '';
    }
    setPartCount(value);
  };

  // 一括編集
  const editAllContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={11}>
          <div style={{ display: 'flex' }}>
            <p style={{ fontSize: '13.5px', fontWeight: 'bold', marginTop: 6 }}>{chkrowscount}件を選択中</p>
            <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  addPart();
                }}
              >
                <Image preview={false} width={27} src={child_add} style={{ marginLeft: '10px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  deletePart();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={child_delete}
                  style={{ marginLeft: '25px', marginTop: '2px' }}
                ></Image>
              </a>
            </Tooltip>
          </div>
          <Table
            id="iq3EstimateTbl"
            showSorterTooltip={false}
            rowClassName={(record) => (record.no == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            columns={iq3Columns}
            rowKey={(record) => record.no}
            dataSource={bulkIQ3DataLst}
            className="parent-detail-EditTable"
            pagination={false}
            scroll={{ y: 250, x: '10vw' }}
            rowSelection={bulkEditRowSelect}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={13}>
          <div
            style={{
              marginTop: 40,
              marginLeft: 10,
            }}
          >
            {/* 工程選択警告文章 */}
            <div
              style={{
                display: 'flex',
              }}
            >
              <Tabs
                id="iq3-detail-tab"
                className="iq3-detail-tab iq3-detail-tab-bulkedit"
                type="card"
                activeKey={activeBulkEditTabKey}
                defaultActiveKey="1"
                items={bulkEditTabItems}
                onChange={onChangeBulkEditTab}
              />
              <Checkbox
                className="bulkCheckbox"
                style={{
                  display: activeBulkEditTabKey == '2' ? 'block' : 'none',
                  marginTop: '3px',
                }}
                checked
              ></Checkbox>
              <p
                style={{
                  marginLeft: '5px',
                  fontSize: '13.5px',
                  fontWeight: 'bold',
                  display: activeBulkEditTabKey == '2' ? 'block' : 'none',
                }}
              >
                変更していない項目も一括で変更する
              </p>
            </div>
            {/* 基本情報 */}
            <div
              style={{ display: activeBulkEditTabKey == '1' ? 'block' : 'none', marginTop: 10 }}
              className="iq3-tab-div"
            >
              <RTable className="propertiesTbl">
                <thead>
                  <tr>
                    <td className="detail-tbl-val-title">
                      <label className="detail-tbl-val-title-label">員数</label>
                    </td>
                    <td className="detail-tbl-val">
                      <Input
                        style={{ padding: 5, marginLeft: 5, width: '35.5%' }}
                        // className={editMode ? 'input-editable ' : 'input-non-editable '}
                        className="input-editable"
                        name="partCount"
                        value={partCount}
                        onKeyPress={(e) => {
                          handleKeyPress(e, true);
                        }}
                        // ref={(ref) => {
                        //   if (ref) inputItemRef['partCount'] = ref;
                        // }}
                        onPressEnter={(e) => onChangePartCount(e.target.value)}
                        onBlur={(e) => onChangePartCount(e.target.value)}
                        onChange={(e) => onChangePartCount(e.target.value, false)}
                        // disabled
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-tbl-val-title">
                      <label className="detail-tbl-val-title-label">材質</label>
                    </td>
                    <td className="detail-tbl-val">
                      <Select
                        showSearch
                        className="iq3-detail-select"
                        name="materialTypeId"
                        value={materialTypeId ? materialTypeId : ''}
                        onChange={(e) => {
                          setMaterialTypeId(e);
                        }}
                        filterOption={filterOption}
                        style={{ marginLeft: 5, width: '98.2%' }}
                      >
                        {materialTypeLst
                          ?.filter(
                            (item) => materialLst?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
                          )
                          ?.map((option, index) => (
                            <Select.Option key={index} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="detail-tbl-val-title">
                      <label className="detail-tbl-val-title-label">材料名称</label>
                    </td>
                    <td className="detail-tbl-val">
                      <Select
                        className="iq3-detail-select"
                        name="materialId"
                        value={materialId ? materialId : ''}
                        onChange={(e) => {
                          setMaterialId(e);
                          setThickness(e);
                        }}
                        style={{ marginLeft: 5, width: '98.2%' }}
                      >
                        {materialLst
                          ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                          ?.map((option, index) => (
                            <Select.Option key={index} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </td>
                  </tr> */}
                  <tr>
                    <td className="detail-tbl-val-title">
                      <label className="detail-tbl-val-title-label">板厚</label>
                    </td>
                    <td className="detail-tbl-val">
                      {/* {editMode ? ( */}
                      <Select
                        name="thickness"
                        className="iq3-detail-select"
                        value={thickness ? thickness : ''}
                        onChange={(e) => {
                          setThickness(e);
                          setMaterialId(e);
                        }}
                        style={{ marginLeft: 5, width: '35.5%' }}
                      >
                        {materialLst
                          ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                          ?.map((option, index) => (
                            <Select.Option key={index} value={option.id}>
                              {option.info.thick}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={getMaterialThickById(thickness, materialNmLst)}
                    />
                  )} */}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-tbl-val-title">
                      <label className="detail-tbl-val-title-label">表面保護</label>
                    </td>
                    <td className="detail-tbl-val">
                      {/* {editMode ? ( */}
                      <Select
                        name="materialSurface"
                        className="iq3-detail-select"
                        value={materialSurfaceId ? materialSurfaceId : ''}
                        onChange={(e) => {
                          setMaterialSurfaceId(e);
                        }}
                        style={{ marginLeft: 5, width: '35.5%' }}
                      >
                        {materialSurfaceLst
                          ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                          ?.map((option, index) => (
                            <Select.Option key={index} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </td>
                  </tr>
                </thead>
              </RTable>
            </div>
            {/* 工程選択 */}
            <div
              style={{
                display: activeBulkEditTabKey == '2' ? 'block' : 'none',
                marginTop: 10,
                height: 710,
                overflowY: 'auto',
              }}
              className="iq3-tab-div"
            >
              <ProcessPatternForm
                ref={patternformRef}
                selectedData={processSelectedData}
                listData={listData}
                editMode={true}
                page={patternPage[1]}
                isParameter={false}
                updateKouteiSentakuInfo={updateKouteiSentakuInfo}
                kouteiSentakuEditMode={true}
                pmxMode={true}
                processMasterData={estimateParameters?.process}
                deviceMasterData={estimateParameters?.device}
                clientMasterData={estimateParameters?.client}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={updateData} style={{ marginRight: 5 }}>
          OK
        </Button>
        <Button className="cancelButton" onClick={discardData}>
          破棄
        </Button>
      </Row>
    </>
  );

  // Waitingダイアログを表示・非表示に更新する
  const updateLoading = (load) => {
    setParentLoading(load);
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Form>
        {/** 共通操作 */}
        <div
          style={{
            borderBottom: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#212529',
            backgroundColor: '#fafafc',
          }}
        >
          <Row style={{ marginLeft: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
              <Row style={{ marginTop: '1px', marginLeft: '0' }}>
                <Col span={9}>
                  <Space size="middle" style={{ columnGap: 35 }}>
                    {/** IQX_WEBEST-172 */}
                    <Tooltip title="親部品" placement="top" overlayClassName="tooltip-text">
                      <a onClick={createNewParentInfo}>
                        <Image preview={false} width={30} src={new_icon} style={{ marginLeft: '10px' }}></Image>
                      </a>
                    </Tooltip>
                    <Tooltip title="CURT.パラ" placement="top" overlayClassName="tooltip-text">
                      <a onClick={currentParamInfo}>
                        <Image preview={false} width={35} src={curt} style={{ marginLeft: '5px' }}></Image>
                      </a>
                    </Tooltip>
                    <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                      <a onClick={updateAll}>
                        <Image preview={false} width={28} src={edit_list} style={{ marginLeft: '5px' }}></Image>
                      </a>
                    </Tooltip>
                    <Tooltip title="子部品追加呼出" placement="top" overlayClassName="tooltip-text">
                      <a onClick={displayAddParentInfo}>
                        <Image
                          preview={false}
                          width={30}
                          src={parent_call_detail}
                          style={{ marginLeft: '5px' }}
                        ></Image>
                      </a>
                    </Tooltip>

                    {/* <Tooltip title="再計算" placement="top" overlayClassName="tooltip-text">
                      <a onClick={calEstimateDataEvent}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={calculator}
                          style={{ marginLeft: '5px', marginTop: '2px' }}
                        ></Image>
                      </a>
                    </Tooltip> */}

                    {/*ファーストリリースで不要*/}
                    {/* <Tooltip title="PC保存・PC呼出" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={pcSaveMenuitems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcOutput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcInput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip> */}
                    <Tooltip title="PMX取込" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={pmxMenuChangeEvent}
                        mode="horizontal"
                        items={pmxMenuItems}
                        style={{
                          marginLeft: '-15px',
                          height: 0,
                          color: 'white',
                          alignItems: 'flex-end',
                        }}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        accept=".PMX"
                        onChange={(e) => openPMXProcessPatternDialog(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip>
                    <Tooltip title="帳票" placement="top" overlayClassName="tooltip-text">
                      <a onClick={reportOutputChangeEvent}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={export_report}
                          style={{ marginLeft: '0px', marginTop: '0px' }}
                        ></Image>
                      </a>
                    </Tooltip>

                    {/* <Tooltip title="帳票" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={reportOutputMenuitems}
                        style={{
                          marginLeft: '-15px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                    </Tooltip> */}
                    {/*ファーストリリースで不要*/}
                    {/* <Tooltip title="CSV出力" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={listOutputMenuitems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                          textDecoration: 'none',
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="備考価格" placement="top" overlayClassName="tooltip-text">
                      <a onClick={commonTaiochuPopupOpen}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={reference_price}
                          style={{ marginLeft: '30px', marginTop: '0px' }}
                        ></Image>
                      </a>
                    </Tooltip> */}
                  </Space>
                </Col>
              </Row>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '39%' }}>
              <Row align="middle">
                {/* <Col span={8}> */}
                <Space size="middle">
                  {selectedEstimateData?.estimateProducts?.id === 0 ||
                  selectedEstimateData?.estimateProducts === undefined ? (
                    <>
                      <Tooltip title="保存" placement="top" overlayClassName="tooltip-text">
                        <a onClick={estimateNewDataSaveEvent}>
                          <Image preview={false} width={33} src={save} style={{ marginRight: '5px' }}></Image>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="保存" placement="topLeft" overlayClassName="tooltip-text">
                        <Menu
                          onClick={(e) => estimateDataSaveEvent(e, 'update')}
                          mode="horizontal"
                          items={saveMenuItems}
                          style={{
                            marginLeft: '-17px',
                            marginRight: '5px',
                            height: 0,
                            color: 'white',
                            alignItems: 'flex-end',
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                  {/* </Col> */}
                  {/* <Col span={7}> */}
                  {selectedEstimateData?.estimateProducts?.id !== 0 ? (
                    <Input
                      className="editInfoTle"
                      value={'編集'}
                      type="text"
                      prefix={
                        <Image
                          preview={false}
                          width={13}
                          src={edit_info}
                          style={{
                            transform: 'rotate(270deg)',
                            marginRight: '5px',
                          }}
                        />
                      }
                    />
                  ) : (
                    <Input className="editInfoTle" value={'新規'} type="text" />
                  )}
                  <Tooltip title="破棄" placement="top" overlayClassName="tooltip-text">
                    <a onClick={estimateDataDiscardEvent}>
                      <Image preview={false} width={22} src={close}></Image>
                    </a>
                  </Tooltip>
                </Space>
              </Row>
            </div>
          </Row>
        </div>
        {/** 親部品・板金子部品・形鋼子部品 */}
        <div>
          <Row>
            <Col span={19} style={{ borderRight: '1px solid #212529' }}>
              <Tabs
                className="estimate-tab"
                type="card"
                defaultActiveKey="1"
                activeKey={activePartMenu}
                items={tabItems}
                onChange={partMenuChange}
              />
              <div style={{ display: activePartMenu == '1' ? 'block' : 'none', marginTop: 7 }}>
                <ParentEstimate
                  editMode={editMode}
                  ref={parentEstimateRef}
                  // selectedEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  selectedEstimateData={props.selectedEstimateData}
                  // selectedTestEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  updateMainEstimateEditMode={updateMainEstimateEditMode}
                  activePartMenu={activePartMenu}
                  updateEstimateInfo={updateEstimateInfo}
                  isReCal={props.isReCal}
                  updateEstimateInfoByReCal={updateEstimateInfoByReCal}
                  newMode={props.newMode}
                  getParentQuantity={getParentQuantity}
                  loading={props?.loading} // Waitingダイアログ
                  clickMenu={clickMenu} // 親部品.板金tab切り替え
                  updateClickMenu={updateClickMenu}
                  isPMXEvent={props?.isPMXEvent}
                  parentActiveTabKey={props?.parentActiveTabKey}
                  updateParentActiveTabKey={props?.updateParentActiveTabKey}
                />
              </div>
              <div style={{ display: activePartMenu == '2' ? 'block' : 'none', marginTop: 7 }}>
                <IQ3Estimate
                  type={checkPMXOrNot}
                  editMode={editMode}
                  ref={iQ3EstimateRef}
                  updateEstimateInfo={updateEstimateInfo}
                  // iq3Data={selectediq3Data}
                  // selectedEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  selectedEstimateData={props.selectedEstimateData}
                  updateMainEstimateEditMode={updateMainEstimateEditMode}
                  // targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
                  activePartMenu={activePartMenu}
                  userSettingInfo={props.userSettingInfo}
                  getParentQuantity={getParentQuantity}
                  updatedSelectedEstimateDataTemp={updatedSelectedEstimateDataTemp}
                  loading={props?.loading} // Waitingダイアログ
                  clickMenu={clickMenu}
                  isReCal={props.isReCal}
                  updateIsRecal={props.updateIsRecal}
                  isReCalMaterial={props?.isReCalMaterial}
                  updateIsRecalMaterial={props.updateIsRecalMaterial}
                  isPMXEvent={props?.isPMXEvent}
                  updatePMXEvent={updatePMXEvent}
                  updateCheckPMXOrNot={updateCheckPMXOrNot}
                  iq3ActiveTabKey={props?.iq3ActiveTabKey}
                  updateIq3ActiveTabKey={props?.updateIq3ActiveTabKey}
                />
              </div>
            </Col>
            <Col span={5}>
              {/* 見積基本情報 */}
              <EstimateBasicInfo
                estimateBasicInfo={props.selectedEstimateData}
                ref={estimateBasicInfoRef}
                getCurrentIQ3={getCurrentIQ3}
                settingParametersByReCal={props.settingParametersByReCal}
                activePartMenu={activePartMenu}
                loading={props?.loading} // Waitingダイアログ
                basicInfoUpdate={basicInfoUpdate}
                updateIsRecalMaterial={props.updateIsRecalMaterial}
                isReCal={props.isReCal}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {/* PMX用工程選択画面　*/}
      <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isPMXProcessPatternOpen}
        onOk={updateOk}
        centered={true}
        onCancel={updateCancel}
        width={1490}
        className="updateModalStyle"
        bodyStyle={{ marginTop: '0px' }}
        // style={{ marginTop: '-15px' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[
          <Button
            key="back"
            onClick={backProcessPattern}
            className={isCreatePMX === CreatePMXData.Update || currentStep === 1 ? 'disabledButton' : 'cancelButton'}
            // style={{ pointerEvents: isCreatePMX === CreatePMXData.Update ? 'none' : 'auto' }}
          >
            {'< 戻る(B)'}
          </Button>,
          <Button
            key="next"
            onClick={nextProcessPattern}
            className={isCreatePMX === CreatePMXData.Update || currentStep === 2 ? 'disabledButton' : 'cancelButton'}
          >
            {'次へ(N) >'}
          </Button>,
          <Button key="ok" onClick={completePMX} className="mainButton">
            {'完了(F)'}
          </Button>,
          <Button key="cancel" onClick={cancelPMXImport} className="cancelButton">
            {'キャンセル'}
          </Button>,
        ]}
      >
        <Steps
          type="navigation"
          size="small"
          className="site-navigation-steps"
          current={currentStep}
          items={[
            {
              title: '',
            },
            {
              title: '親部品工程選択',
            },
            {
              title: '板金工程選択',
            },
            {
              title: '読込',
            },
            {
              title: '',
            },
          ]}
        />
        <Row style={{ marginBottom: '20px' }}>
          <Col span={14}>
            <Row style={{ marginTop: 0 }}>
              <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
                【指定した編集中の見積データに対しての動作】
              </p>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={24}>
                <ul class="pmxType">
                  <li>{pmxProcessType}</li>
                </ul>
                {/* <Radio.Group value={pmxProcessType}>
                  <Space direction="vertical">
                    <Radio value={0}>編集中の見積を保存して新規見積を作成する</Radio>
                    <Radio value={1}>新規見積を作成する</Radio>
                    <Radio value={2}>編集中の見積に追加する</Radio>
                  </Space>
                </Radio.Group> */}
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row style={{ height: 42, width: '98%' }}>
              <Col span={8} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
                親部品工程パターン
              </Col>
              <Col
                span={16}
                style={{
                  verticalAlign: 'middle',
                  padding: 5,
                  borderTop: '1px solid rgb(181, 178, 174)',
                  borderRight: '1px solid rgb(181, 178, 174)',
                }}
              >
                <Select
                  style={{
                    width: '90%',
                    marginLeft: 20,
                    pointerEvents: isCreatePMX === CreatePMXData.Update ? 'none' : 'auto',
                  }}
                  id="pattern"
                  name="pattern"
                  // value={parentPatternNo}
                  value={parentPatternNo != 0 ? parentPatternNo : Empty}
                  onChange={(e) => onChangeProcessPatternInfo(0, e)}
                  className={'input-editable'}
                  options={patternList?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              </Col>
            </Row>
            <Row style={{ height: 42, width: '98%', borderTop: '1px solid rgb(181, 178, 174)' }}>
              <Col span={8} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
                板金工程パターン
              </Col>
              <Col
                span={16}
                style={{
                  verticalAlign: 'middle',
                  padding: 5,
                  borderBottom: '1px solid rgb(181, 178, 174)',
                  borderRight: '1px solid rgb(181, 178, 174)',
                }}
              >
                <Select
                  style={{ width: '90%', marginLeft: 20 }}
                  id="childPatternNo"
                  name="childPatternNo"
                  // value={childPatternNo}
                  value={childPatternNo != 0 ? childPatternNo : Empty}
                  onChange={(e) => onChangeProcessPatternInfo(1, e)}
                  className={'input-editable'}
                  options={iq3PatternList?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="overflow-auto kouteical timecharge" style={{ height: '60vh', overflow: 'hidden' }}>
          {isNext == false ? (
            <ParentKouteiSentaku
              type={'pmx'}
              isNewPMXOrNot={isCreatePMX}
              editMode={true}
              patternNo={parentPatternNo}
              updateSelectedParentPatternNo={updateSelectedParentPatternNo}
              // selectedDataDetail={selectedEstimateData}
              selectedDataDetail={selectedPMXEstimateData}
              pmxMode={true}
              ref={parentKouteiSentakuRef}
              updatePatternList={updatePatternList}
            />
          ) : (
            <IQ3KouteiSentaku
              type={'pmx'}
              patternNo={childPatternNo}
              updateSelectedPatternNo={updateSelectedPatternNo}
              editMode={true}
              selectedDataDetail={selectedPMXEstimateData}
              selectedCardData={selectedCardData}
              // selectedEstimateData={selectedPMXEstimateData}
              selectedIQ3DataDetail={
                selectediq3Data != undefined && selectediq3Data?.length > 0 ? selectediq3Data[0] : []
              }
              pmxMode={true}
              iQ3DataLst={selectediq3Data}
              ref={iQ3KouteiSentakuRef}
              updatePatternList={updatePatternList}
              // updatedSelectedData={updatedSelectedData}
            />
          )}
        </div>
      </Modal>

      {/* PMX読込クラウド画面　*/}
      {/* <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isPMXCloudFileDialogOpen}
        onOk={updateOk}
        onCancel={updateCancel}
        width={1490}
        className="pmxCloudFileModal"
        // bodyStyle={{ height: 750, marginTop: '0px' }}
        style={{ marginTop: '-15px' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[
          // <Button key="back" onClick={nextIQ3FileLoad} className="mainButton">
          //   {'続けて選択'}
          // </Button>,
          <Button key="next" type="primary" onClick={cloudPMXFileLoadOk} className="mainButton">
            {'OK'}
          </Button>,
          <Button key="cancel" onClick={cloudPMXFileLoadCancel} className="cancelButton">
            {'キャンセル'}
          </Button>,
        ]}
      >
         <Tabs
          className="pmx-cloud-file-tab"
          type="card"
          defaultActiveKey="1"
          activeKey={pmxTabKey}
          items={pmxTabItems}
          onChange={pmxTabChange}
        /> 
        <PMX_CloudFileDialog_Tab_Parent
          selectedParentRows={getSelectedParentRows}
          ref={pmxCloudParentRef}
          cardData={cardInfo}
        />
      </Modal> */}
      {isPMXCloudFileDialogOpen ? (
        <PMX_CloudFileDialog_Tab_Parent
          pmxModal={isPMXCloudFileDialogOpen}
          selectedParentRows={getSelectedParentRows}
          ref={pmxCloudParentRef}
          cardData={cardInfo}
          cardDataTotalCount={cardInfoCount}
          pmxProcessPattern={openPMXProcessPatternModal}
          loading={props?.loading}
        />
      ) : (
        ''
      )}
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/* 帳票出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 120 },
            600,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/* PC保存確認 */}
      {isEstimateDataSaveConfirm
        ? commonModal(
            isEstimateDataSaveConfirm,
            reportModalTitle,
            null,
            { height: 120 },
            550,
            reportOutputOk,
            reportOutputCancel,
            estimateDataSaveContent,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDisplayDataSaveContent(discardConfirmOk, discardConfirmCancel),
            null,
            null,
            false
          )
        : ''}
      {/* PMX取り込み確認 */}
      {isLoadPMXProcess
        ? commonModal(
            isLoadPMXProcess,
            pmxConfirmModalTitle,
            null,
            { height: 120 },
            300,
            null,
            pmxLocalProcessCancel,
            pmxConfirmContent,
            'updateModalStyle',
            { left: -300, top: 100 },
            false
          )
        : ''}
      {/* 親部品リストダイアログ */}
      {parentListDisplay ? (
        <Modal
          maskClosable={false}
          open={parentListDisplay}
          title={parentListModalTitle}
          footer={null}
          bodyStyle={{ height: '71.7vh', marginTop: -5, marginLeft: -23 }}
          width={1670}
          onOk={selectParentInfoOk}
          onCancel={selectParentInfoCancel}
          closeIcon={<CloseOutlined style={{ color: 'white' }} />}
          className={null}
          style={{ top: 110, left: 50 }}
          centered={false}
        >
          <Spin spinning={parentLoading} tip="Loading..." size="large">
            <div style={{ borderBottom: '1px solid #6c757d', width: '101.2%' }}>
              <ParentList
                getSelectedEstimateData={getSelectedEstimateData}
                editMode={false}
                parentListClick={false}
                isFromiQ3={true}
                isFromParent={true}
                orderOptions={newParameters?.estimateStatus}
                companyInfo={newParameters?.company}
                clientInfo={newParameters?.client}
                userInfo={newParameters?.currentUserInfo}
                staffInfo={newParameters?.staff}
                processInfo={newParameters?.process}
                parentLoading={updateLoading}
              />
            </div>
            <Row
              justify="end"
              style={{
                marginBottom: 0,
                marginTop: 7,
              }}
            >
              <Button className="mainButton" onClick={selectParentInfoOk} style={{ marginRight: 5 }}>
                追加
              </Button>
              <Button className="cancelButton" onClick={selectParentInfoCancel}>
                閉じる
              </Button>
            </Row>
          </Spin>
        </Modal>
      ) : (
        ''
      )}

      {/* 別名保存_Modal */}
      {isSaveByOtherName
        ? commonModal(
            isSaveByOtherName,
            commonModalTitle,
            commonFooter(saveByOtherNameOk, saveByOtherNameCancel),
            null,
            520,
            saveByOtherNameOk,
            saveByOtherNameCancel,
            saveByOtherNameInfoContent,
            null,
            null,
            false
          )
        : ''}

      {/* 上書き保存_Modal */}
      {isSaveByOverwrite
        ? commonModal(
            isSaveByOverwrite,
            confirmModalTitle,
            commonFooter(saveByOverwriteOk, saveByOverwriteCancel),
            null,
            400,
            saveByOverwriteOk,
            saveByOverwriteCancel,
            confirmOverwriteSaveContent,
            null,
            null,
            null,
            false
          )
        : ''}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(createOk, createCancel),
            null,
            400,
            createOk,
            createCancel,
            confirmSaveContent,
            null,
            null,
            null,
            false
          )
        : ''}

      {discardConfirm
        ? commonModal(
            discardConfirm,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            null,
            false
          )
        : ''}
      {saveDiscardConfirmPopup
        ? commonModal(
            saveDiscardConfirmPopup,
            confirmScreenChangeWarning,
            null,
            null,
            500,
            discardConfirmOk,
            closeOk,
            saveDiscardConfirmContent,
            null,
            null,
            false
          )
        : ''}

      {iq3DataConfirm
        ? commonModal(
            iq3DataConfirm,
            confirmModalTitle,
            dataConfirmOK(completePMXData),
            null,
            400,
            completePMXData,
            null,
            confirmContent,
            null,
            null,
            null,
            false
          )
        : ''}

      {/* 同一図番_Modal */}
      {isSaveSameDrawingNo
        ? commonModal(
            isSaveSameDrawingNo,
            sameDrawingNoModalTitle,
            commonFooter(saveSameDrawingNoOk, saveSameDrawingNoCancel),
            null,
            400,
            saveSameDrawingNoOk,
            saveSameDrawingNoCancel,
            sameDrawingNoContent(
              getNameByValue(props?.companySettingInfo?.[0]?.environments?.saveSameType, saveSameTypes)
            ),
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/* パラメータ更新_Modal */}
      {isCurtParam
        ? commonModal(
            isCurtParam,
            curtParamModalTitle,
            commonActionFooter(curtParamOk, curtParamCancel, '更新', 'キャンセル'),
            null,
            520,
            curtParamOk,
            curtParamCancel,
            curtParamInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 一括編集 */}
      {isUpdate
        ? commonModal(
            isUpdate,
            editModalTitle,
            null,
            null,
            1790,
            updateData,
            discardData,
            editAllContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
    </div>
  );
});

export default MainEstimate;
