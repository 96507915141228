import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
  kouteiCalculateSelectedArr,
  handleKeyPress,
  handleShowHide,
} from '../common/Common.js';
import { WorkType, DataType } from '../common/enums';
import { amtEmptyStr, timeHmsStr, DataButton } from '../common/Constant';

const IQ3KouteiCalculate_AdditionalProcess = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [totalArr, setTotalArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [updateEditedDataBk, setUpdateEditedDataBk] = useState([]);

  const handleChangeCheckData = (e, i) => {
    setSelectedDataFlg(e);
    const editedData = { ...updateEditedData };
    editedData[i].dataType = e;
    setUpdateEditedData(editedData);
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmCustom);
  };

  // IQX_WEBEST-16 データ表示に戻す
  const settingDataItems = (indexi) => {
    let orgProcessArr = JSON.parse(JSON.stringify(processArr));
    editedData[indexi] = orgProcessArr[indexi];
    const cuDivs = document.querySelectorAll('div[id*="Cu"]');

    cuDivs.forEach((div) => {
      div.style.display = 'none';
    });

    const data = [...updateEditedData];
    data[indexi].editItems = updateEditedDataBk[indexi]?.dataItems;
    data[indexi].totalEditPrice = updateEditedDataBk[indexi]?.totalDataPrice;
    data[indexi].totalEditTime = updateEditedDataBk[indexi]?.totalDataTime;
    data[indexi].totalDandori.editItemsAmt = updateEditedDataBk[indexi].totalDandori.dataItemsAmt;
    data[indexi].totalDandori.editItemsTime = updateEditedDataBk[indexi].totalDandori.dataItemsTime;
    data[indexi].totalSagyo.editItemsAmt = updateEditedDataBk[indexi].totalSagyo.dataItemsAmt;
    data[indexi].totalSagyo.editItemsTime = updateEditedDataBk[indexi].totalSagyo.dataItemsTime;
    setUpdateEditedData(data);
    props?.updateIQ3KouteiCal(data, WorkType.SmCustom);
  };

  const handleCollpse = (id) => {
    if (document.getElementById('div' + id) !== null) {
      let showHide = document.getElementById('div' + id)?.style.display;
      if (showHide == 'block') {
        document.getElementById('div' + id).style.display = 'none';
        document.getElementById('arrowUp' + id).style.display = 'none';
        document.getElementById('arrowDown' + id).style.display = 'block';
      } else {
        document.getElementById('div' + id).style.display = 'block';
        document.getElementById('arrowUp' + id).style.display = 'block';
        document.getElementById('arrowDown' + id).style.display = 'none';
      }
    }
  };

  useEffect(() => {
    let customOrg = [];
    let customEdit = [];
    let updateEditedDatas = [];
    let deviceMst = [];
    let processMst = [];
    let patternInfo = [];

    // 基本情報の総重量
    let weight = props?.selectedEstimateData?.estimateProducts?.totalWeight;
    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 工程パターンから計算する工程を取得
    let usedCalProcessSelect = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.isUsedForCalc)
      : [];
    let patternInfoFilter = [];
    let processMstFilter = [];

    //データ設定
    if (usedCalProcessSelect?.length > 0) {
      for (let j = 0; j < usedCalProcessSelect?.length; j++) {
        let customInfo = undefined;
        // 追加工程マスター取得
        processMstFilter = parameters?.process
          ? parameters?.process?.filter((item) => item.id === usedCalProcessSelect[j].processId)[0]
          : [];
        if (processMstFilter == undefined) continue;
        //追加工程検索
        if (processMstFilter?.workType !== WorkType.SmCustom) continue;
        patternInfoFilter = usedCalProcessSelect[j];
        let propsCustoms = JSON.parse(JSON.stringify(props?.selectedIQ3DataDetail?.additions));
        //旧追加工程積算データ
        for (let i = 0; i < propsCustoms?.length; i++) {
          if (
            patternInfoFilter.processId == propsCustoms[i]?.processId &&
            patternInfoFilter.multiprocesses[0]?.processDetailTypeId ==
              propsCustoms[i]?.dataItems[0]?.processDetailTypeNo
          ) {
            customInfo = propsCustoms[i];
            break;
          }
        }
        // 削除された工程をもう一度設定した場合
        if (customInfo == undefined) {
          let detailItems = [
            {
              id: 1,
              processName: processMstFilter?.name,
              processDetailTypeNo: patternInfoFilter.multiprocesses[0]?.processDetailTypeId,
              dandoriAmt: 0,
              dandoriTime: 0,
              sagyoAmt: 0,
              sagyoTime: 0,
              processDetailWorkTypeGroup: patternInfoFilter.multiprocesses[0]?.processDetailGroup,
              kasho: 1,
              isCommission: false,
              supplier: '',
              supplierCode: '',
              formula: '',
            },
          ];
          let lastRecord = updateEditedDatas?.sort((a, b) => b.no - a.no)?.[0];
          customInfo = {
            no: processMstFilter?.id,
            processId: processMstFilter.id,
            dataType: DataType.Data,
            dataItems: detailItems,
            editItems: detailItems,
            totalDandori: {
              dataItemsAmt: 0,
              dataItemsTime: 0,
              editItemsAmt: 0,
              editItemsTime: 0,
            },
            totalSagyo: {
              dataItemsAmt: 0,
              dataItemsTime: 0,
              editItemsAmt: 0,
              editItemsTime: 0,
            },
            totalDataPrice: 0,
            totalDataTime: 0,
            totalEditPrice: 0,
            totalEditTime: 0,
          };
        }

        let deviceMstFilter = parameters?.device
          ? parameters?.device?.filter(
              (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
            )
          : []; //テスト用ID;
        if (deviceMstFilter) {
          processMst.push(processMstFilter ? processMstFilter : []);
          deviceMst.push(deviceMstFilter ? deviceMstFilter[0] : []);
          patternInfo.push(patternInfoFilter);

          let orgData = JSON.parse(JSON.stringify(customInfo?.dataItems));
          let editData = [];
          if (processMstFilter != undefined && orgData?.length > 0) {
            //1個の部品数係数
            let partCnt = 1;
            let dandoriTimeSec = 0;
            let cSagyoTimeSec = 0;
            // 重量
            let weight = props?.selectedIQ3DataDetail?.weight;
            // 面積
            let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);

            //総重量が0、子部品数が0の場合、段取と加工を計算しない。
            if (weight === 0 && area === 0) {
              orgData[0].dandoriTime = 0;
              orgData[0].dandoriAmt = 0;
              orgData[0].sagyoTime = 0;
              orgData[0].sagyoAmt = 0;
            } else {
              /** 段取金額/時間 */
              // 段取時間設定
              //　基本段取時間＊サイズ係数
              dandoriTimeSec = calDandoriTime(deviceMstFilter[0], weight, area);
              orgData[0].dandoriTime = dandoriTimeSec ? dandoriTimeSec : 0;

              // 段取金額設定
              //段階時間＊時間チャージ
              let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMstFilter[0]);
              orgData[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;

              // 1箇所の作業時間
              let mWorkHTime = deviceMstFilter[0]?.details?.workBasicTime;

              /** 作業金額/時間 */
              // 作業時間
              // 1箇所の作業時間＊箇所＊サイズ係数
              let kasho = orgData[0].kasho;
              cSagyoTimeSec = deviceMstFilter[0]?.details?.isManualInput
                ? 0
                : calSagyoTime(deviceMstFilter[0], weight, area, kasho);
              // let cSagyoTime = secondsToHms(cSagyoTimeSec);
              orgData[0].sagyoTime = cSagyoTimeSec ? cSagyoTimeSec : 0;

              // 作業金額
              //　作業時間＊時間チャージ

              let cSagyoAmt = 0;
              cSagyoAmt = deviceMstFilter[0]?.details?.isManualInput
                ? Number(deviceMstFilter[0]?.details?.initPrice)
                : calSagyoAmt(cSagyoTimeSec, deviceMstFilter[0]);
              orgData[0].sagyoAmt = cSagyoAmt ? cSagyoAmt : 0;
            }
            // 委託フラグ
            if (deviceMstFilter[0]?.details?.isCommission !== undefined) {
              orgData[0].isCommission = deviceMstFilter[0]?.details?.isCommission;
            }

            // お客様情報表示
            let clientId = deviceMstFilter[0]?.details?.clientId;
            let clientInfo = parameters?.client?.filter((item) => item.id == clientId)[0];
            if (clientInfo?.name) {
              orgData[0].supplier = clientInfo?.name;
            }
            if (clientInfo?.code) {
              orgData[0].supplierCode = clientInfo?.code;
            }

            customOrg.push(orgData);

            // IQX_WEBEST-16 工程積算タブで編集データのチェックがOFFだと、手入力で入力した内容がリセットされてしまう
            if (customInfo?.editItems?.length > 0 && customInfo?.editDataFlg) {
              editData = JSON.parse(JSON.stringify(customInfo?.editItems));
            } else {
              editData = JSON.parse(JSON.stringify(orgData));
            }
            customEdit.push(editData);

            let customAdditions = {
              no: customInfo.no,
              processId: customInfo?.processId,
              dataType: customInfo?.dataType,
              dataItems: orgData,
              editItems: editData,
              totalDandori: {
                dataItemsAmt: orgData[0]?.dandoriAmt,
                dataItemsTime: dandoriTimeSec,
                editItemsAmt: editData[0]?.dandoriAmt,
                editItemsTime: editData[0]?.dandoriTime,
              },
              totalSagyo: {
                dataItemsAmt: orgData[0]?.sagyoAmt,
                dataItemsTime: cSagyoTimeSec,
                editItemsAmt: editData[0]?.sagyoAmt,
                editItemsTime: editData[0]?.sagyoTime,
              },
              totalDataPrice: orgData[0]?.dandoriAmt + orgData[0]?.sagyoAmt,
              totalDataTime: dandoriTimeSec + cSagyoTimeSec,
              totalEditPrice: editData[0]?.dandoriAmt + editData[0]?.sagyoAmt,
              totalEditTime: editData[0]?.dandoriTime + editData[0]?.sagyoTime,
              editDataFlg: customInfo?.editDataFlg,
            };
            updateEditedDatas.push(customAdditions);
          }
        }
      }
      setDeviceMst(deviceMst);
      setProcessMst(processMst);
      setPatternInfo(patternInfo);
      setUpdateEditedData(updateEditedDatas);
      setUpdateEditedDataBk(JSON.parse(JSON.stringify(updateEditedDatas)));
      setProcessArr(customOrg);
      setEditedData(customEdit);
      setOldEditedData(customEdit);
      props?.updateIQ3KouteiCal(updateEditedDatas, WorkType.SmCustom);
    }
  }, [
    props?.activeTabKey,
    props?.isReCalIQ3,
    props?.activePartMenu,
    props?.iq3ActionForCal,
    props?.selectedProcessSelect,
    props?.selectedIQ3DataDetail,
  ]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };

  const setCustomDisplay = (display) => {
    for (let i = 0; i < processArr?.length; i++) {
      let id = 'AdditionalProcessIQ3' + i;
      if (document.getElementById('div' + id) !== null) {
        let showHide = document.getElementById('div' + id)?.style.display;
        if (!display) {
          document.getElementById('div' + id).style.display = 'none';
          document.getElementById('arrowUp' + id).style.display = 'none';
          document.getElementById('arrowDown' + id).style.display = 'block';
        } else {
          document.getElementById('div' + id).style.display = 'block';
          document.getElementById('arrowUp' + id).style.display = 'block';
          document.getElementById('arrowDown' + id).style.display = 'none';
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    getKouteiCalInfoCustom: () => {
      return updateEditedData;
    },
    setCustomDisplay: setCustomDisplay,
  }));

  const settingNewDandoriAmtInArr = (value, indexi, indexj, objId, timeType, timeAct) => {
    let ij = String(indexi) + String(indexj);
    updateEditedData[indexi].editDataFlg = true; // IQX_WEBEST-16 編集データを保持フラグOn
    let editStateData = editedData[indexi];
    let newStateProcessDetail = [...editStateData];
    setOldEditedData(JSON.parse(JSON.stringify(newStateProcessDetail)));
    let oldValue = newStateProcessDetail[indexj][objId];
    if (value === undefined) {
      value = 0;
    } else {
      value = value ? Number(value) : 0;
    }
    newStateProcessDetail[indexj][objId] = value;

    // 基本情報の総重量
    let weight = props?.selectedEstimateData?.estimateProducts?.totalWeight;

    if (objId === 'dandoriTime') {
        if (document.getElementById(ij + 'AdditionalDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(ij + 'AdditionalDandoriAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newStateProcessDetail[indexj].dandoriAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newStateProcessDetail[indexj].dandoriAmt = changeAmtwithTime(
                newStateProcessDetail[indexj].dandoriAmt,
                timeType,
                timeAct,
                value,
                'dandori',
                indexi
              );
            } else {
              newStateProcessDetail[indexj].dandoriAmt = changeAmtwithTime(
                newStateProcessDetail[indexj].dandoriAmt,
                timeType,
                timeAct,
                value,
                'dandori',
                indexi
              );
            }
            checkInputWithColor(
              false,
              '',
              ij + 'AdditionalDandoriAmt',
              newStateProcessDetail[indexj].dandoriAmt,
              'green'
            );
          }
        }
    } else if (objId === 'sagyoTime') {
      if (deviceMst[indexi]?.details?.isManualInput) {
        newStateProcessDetail[indexj][objId] = 0;
      } else {
        if (document.getElementById(ij + 'AdditionalsagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(ij + 'AdditionalsagyoAmtCu').style.display;
          if (showHideCu === 'none') {
            if (value === 0) {
              newStateProcessDetail[indexj].sagyoAmt = 0;
            } else if (timeAct === 'input') {
              let timeType = value - oldValue;
              newStateProcessDetail[indexj].sagyoAmt = changeAmtwithTime(
                newStateProcessDetail[indexj].sagyoAmt,
                timeType,
                timeAct,
                value,
                'sagyo',
                indexi
              );
            } else {
              newStateProcessDetail[indexj].sagyoAmt = changeAmtwithTime(
                newStateProcessDetail[indexj].sagyoAmt,
                timeType,
                timeAct,
                value,
                'sagyo',
                indexi
              );
            }
            checkInputWithColor(false, '', ij + 'AdditionalsagyoAmt', newStateProcessDetail[indexj].sagyoAmt, 'green');
          }
        }
      }
    } else if (objId === 'kasho') {
      if (deviceMst[indexi]?.details?.isManualInput) {
        newStateProcessDetail[indexj][objId] = oldValue;
      } else {
        let kasho = value;
        newStateProcessDetail[0].kasho = value;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = Number(props?.selectedIQ3DataDetail?.totalSurfaceArea);
        // 1箇所の作業時間
        let mWorkHTime = deviceMst[indexi].details?.workBasicTime;

        /** 作業金額/時間 */
        // 作業時間
        // 1箇所の作業時間＊箇所＊重量係数
        let cSagyoTimeKonpoSec = calSagyoTime(deviceMst[indexi], weight, area, kasho);
        // let cSagyoTimeKonpo = secondsToHms(cSagyoTimeKonpoSec);
        newStateProcessDetail[0].sagyoTime = cSagyoTimeKonpoSec;

        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmtKonpo = deviceMst[indexi]?.details?.isManualInput
          ? Number(deviceMst[indexi]?.details?.initPrice)
          : calSagyoAmt(cSagyoTimeKonpoSec, deviceMst[indexi]);
        //let cSagyoAmtKonpo = calSagyoAmt(cSagyoTimeKonpoSec, deviceMst[indexi]);
        newStateProcessDetail[0].sagyoAmt = cSagyoAmtKonpo;
      }
    }

    // editStateData = settingEditData(newStateProcessDetail);
    editedData[indexi] = newStateProcessDetail;
    updateEditedData[indexi].editItems = editStateData;
    updateEditedData[indexi].totalEditPrice = editStateData[0].dandoriAmt + editStateData[0].sagyoAmt;
    updateEditedData[indexi].totalEditTime = editStateData[0].dandoriTime + editStateData[0].sagyoTime;
    updateEditedData[indexi].totalDandori.editItemsAmt = editStateData[0].dandoriAmt;
    updateEditedData[indexi].totalDandori.editItemsTime = editStateData[0].dandoriTime;
    updateEditedData[indexi].totalSagyo.editItemsAmt = editStateData[0].sagyoAmt;
    updateEditedData[indexi].totalSagyo.editItemsTime = editStateData[0].sagyoTime;
    //undefinedチェック
    updateEditedData[indexi].totalEditPrice = updateEditedData[indexi].totalEditPrice
      ? updateEditedData[indexi].totalEditPrice
      : 0;
    updateEditedData[indexi].totalEditTime = updateEditedData[indexi].totalEditTime
      ? updateEditedData[indexi].totalEditTime
      : 0;
    updateEditedData[indexi].totalDandori.editItemsAmt = updateEditedData[indexi].totalDandori.editItemsAmt
      ? updateEditedData[indexi].totalDandori.editItemsAmt
      : 0;
    updateEditedData[indexi].totalDandori.editItemsTime = updateEditedData[indexi].totalDandori.editItemsTime
      ? updateEditedData[indexi].totalDandori.editItemsTime
      : 0;
    updateEditedData[indexi].totalSagyo.editItemsAmt = updateEditedData[indexi].totalSagyo.editItemsAmt
      ? updateEditedData[indexi].totalSagyo.editItemsAmt
      : 0;
    updateEditedData[indexi].totalSagyo.editItemsTime = updateEditedData[indexi].totalSagyo.editItemsTime
      ? updateEditedData[indexi].totalSagyo.editItemsTime
      : 0;
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmCustom);
  };

  const calSagyoTime = (deviceMst, weight, area, kasho) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊箇所＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.workBasicTime * kasho * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    if (deviceMst == undefined) return 0;
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst?.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    cSagyoAmtKonpo = cSagyoAmtKonpo ? cSagyoAmtKonpo : 0;
    return cSagyoAmtKonpo;
  };

  const calDandoriTime = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    if (deviceMst == undefined) return 0;
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    dandoriAmt = dandoriAmt ? dandoriAmt : 0;
    return dandoriAmt;
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType, indexi) => {
    let editedAmt;
    let totalSec = timeVal;
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      if (processType == 'sagyo') {
        // 作業金額
        //　加工時間＊時間チャージ
        // editedAmt = calSagyoAmt(totalSec, deviceMst[indexi]);
        editedAmt = deviceMst[indexi]?.details?.isManualInput
          ? Number(deviceMst[indexi]?.details?.initPrice)
          : calSagyoAmt(totalSec, deviceMst[indexi]);
      } else {
        // 段取金額
        //　段取時間＊時間チャージ
        editedAmt = calDandoriAmt(totalSec, deviceMst[indexi]);
      }
    }
    editedAmt = editedAmt ? editedAmt : 0;
    return editedAmt;
  };

  return (
    <div>
      {(() => {
        const tsuikaKoutei = [];
        /* 追加工程 */
        for (let i = 0; i < processArr?.length; i++) {
          let processDetailOr = processArr[i];
          let processDetail = editedData[i];
          let totalSum =
            updateEditedData[i]?.dataType == 0
              ? updateEditedData[i]?.totalDataPrice
              : updateEditedData[i]?.totalEditPrice;
          for (let j = 0; j < processDetailOr?.length; j++) {
            if (processDetailOr !== undefined) {
              tsuikaKoutei.push(
                <div className="kouteiCalculateDiv">
                  {/* 追加工程① */}
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin oyabuhin-row  "
                    onClick={(e) => handleCollpse('AdditionalProcessIQ3' + i)}
                  >
                    <Col span={4} className="oyabuhin-col">
                      <div style={{ display: 'flex' }}>
                        <CaretUpOutlined
                          id={'arrowUpAdditionalProcessIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <CaretDownOutlined
                          id={'arrowDownAdditionalProcessIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'block' }}
                        />
                        <span>{processDetailOr[j]?.processName}</span>
                      </div>
                    </Col>
                    <Col span={6} className="oyabuhin-col">
                      <div>{totalSum ? JPYs.format(Math.round(totalSum)) : amtEmptyStr}</div>
                    </Col>
                    <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
                      <div>
                        <label style={{ marginLeft: 5 }}>{deviceMst[i]?.name}</label>
                      </div>
                    </Col>
                  </Row>
                  {/* 追加工程 */}
                  <div id={'divAdditionalProcessIQ3' + i} style={{ display: 'none' }}>
                    {/* 工程詳細 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        工程詳細
                      </Col>
                      <Col span={6} className="oyabuhin-detail-col">
                        <Checkbox
                          key={kouteiCalculateSelectedArr[0].key}
                          value={kouteiCalculateSelectedArr[0].value}
                          checked={updateEditedData[i]?.dataType == DataType.Data}
                          disabled={!props?.editMode}
                          style={{ marginRight: 6 }}
                          onChange={(e) =>
                            props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key, i)
                          }
                        ></Checkbox>
                        <label>{kouteiCalculateSelectedArr[0].value}</label>
                      </Col>
                      <Col span={8} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex', justifyContent: 'left', width: '60%' }}>
                          <Checkbox
                            key={kouteiCalculateSelectedArr[1].key}
                            value={kouteiCalculateSelectedArr[1].value}
                            disabled={!props?.editMode}
                            onChange={(e) =>
                              props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key, i)
                            }
                            checked={updateEditedData[i]?.dataType == DataType.Edit}
                            style={{ marginRight: 6 }}
                          ></Checkbox>
                          <label>{kouteiCalculateSelectedArr[1].value}</label>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', width: '40%' }}>
                          <Button onClick={(e) => settingDataItems(i)} className="dataButton" id="dataBtn1">
                            {DataButton}
                          </Button>
                        </div>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col">
                        単位
                      </Col>
                      <Col span={5} className="formula-column">
                        計算式
                      </Col>
                    </Row>
                    {/* データ,編集データ */}
                    {(() => {
                      const arr = [];
                      let autoSubTotalAmtOr =
                        Number(processDetailOr[j]?.dandoriAmt) + Number(processDetailOr[j]?.sagyoAmt);
                      let autoSubTotalTimeOr = processDetailOr[j]?.dandoriTime + processDetailOr[j]?.sagyoTime;

                      let autoSubTotalAmt = Number(processDetail[j]?.dandoriAmt) + Number(processDetail[j]?.sagyoAmt);
                      let autoSubTotalTime = processDetail[j]?.dandoriTime + processDetail[j]?.sagyoTime;
                      let ij = String(i) + String(j);
                      arr.push(
                        <div>
                          {/* ○○_標準 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <div style={{ display: 'flex' }}>
                                <span style={{ marginLeft: '5px' }}>{processDetailOr[j]?.processName}</span>
                              </div>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={ij + 'autoSubTotalAmt'}>
                                {autoSubTotalAmtOr ? JPYs.format(Math.round(autoSubTotalAmtOr)) : amtEmptyStr}
                              </label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              {
                                <label id={ij + 'autoSubTotalTime'}>
                                  {autoSubTotalTimeOr ? secondsToHms(autoSubTotalTimeOr) : timeHmsStr}
                                </label>
                              }
                            </Col>
                            <Col span={4} className="oyabuhin-detail-col">
                              <label
                                id={ij + 'autoSubTotalAmtIn'}
                                style={{
                                  color: autoSubTotalAmtOr === autoSubTotalAmt ? 'black' : '#4cc12f',
                                }}
                              >
                                {autoSubTotalAmt ? JPYs.format(Math.round(autoSubTotalAmt)) : amtEmptyStr}
                              </label>
                            </Col>
                            <Col span={4} className="oyabuhin-detail-col">
                              <label
                                id={ij + 'autoSubTotalTimeIn'}
                                style={{
                                  color: autoSubTotalTimeOr === autoSubTotalTime ? 'black' : '#4cc12f',
                                }}
                              >
                                {autoSubTotalTime ? secondsToHms(autoSubTotalTime) : timeHmsStr}
                              </label>
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          <div id={'AdditionalProcess' + ij} style={{ display: 'block' }}>
                            <div>
                              {/* 段取金額/時間 */}
                              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                                <Col span={4} className="oyabuhin-detail-col">
                                  <span className="ddl_2_noImg" style={{ marginLeft: 5 }}>
                                    段取金額/時間
                                  </span>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'AdditionalDandoriAmt'}>
                                    {processDetailOr[j]?.dandoriAmt
                                      ? JPYs.format(Math.round(processDetailOr[j]?.dandoriAmt))
                                      : amtEmptyStr}
                                  </label>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'AdditionalDandoriTime'}>
                                    {processDetailOr[j]?.dandoriTime
                                      ? secondsToHms(processDetailOr[j]?.dandoriTime)
                                      : timeHmsStr}
                                  </label>
                                </Col>
                                <Col span={4} className="input-col">
                                  <CurrencyInput
                                    id={ij + 'AdditionalDandoriAmtIn'}
                                    value={Math.round(processDetail[j]?.dandoriAmt)}
                                    defaultValue={0}
                                    style={{
                                      border: 'none',
                                      color:
                                        processDetailOr[j]?.dandoriAmt === processDetail[j]?.dandoriAmt
                                          ? 'black'
                                          : checkShowHideIcon(ij + 'AdditionalDandoriAmtCu') === 'none'
                                          ? '#4cc12f'
                                          : 'red',
                                    }}
                                    onValueChange={(e) => {
                                      //checkRegex(e.target.value);
                                      if (processDetail[j]?.dandoriAmt != e) {
                                        settingNewDandoriAmtInArr(e, i, j, 'dandoriAmt', '', '');
                                        checkInputWithColor(false, '', ij + 'AdditionalDandoriAmt', e, 'amt');
                                      }
                                    }}
                                    className={
                                      editMode
                                        ? 'input-editable currency resetColor'
                                        : 'input-non-editable currency resetColor'
                                    }
                                    prefix="¥"
                                    decimalsLimit={100}
                                  />
                                  <div id={ij + 'AdditionalDandoriAmtCu'} style={{ display: 'none' }}>
                                    <div className="currencyIcon">
                                      <BsUsbC style={{ fontSize: '25' }} />
                                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                                      <span class="tooltiptext">
                                        金額に変更がありましたので、以降時間と連携して自動計算されない。
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={4} className="input-col">
                                  <Row style={{ width: '100%' }}>
                                    <Col className="timer-input-col">
                                      <Input
                                        value={
                                          processDetail[j]?.dandoriTime
                                            ? secondsToHms(processDetail[j]?.dandoriTime)
                                            : timeHmsStr
                                        }
                                        id={ij + 'AdditionalDandoriTimeIn'}
                                        style={{
                                          width: '100%',
                                          color:
                                            processDetailOr[j]?.dandoriTime === processDetail[j]?.dandoriTime
                                              ? handleShowHide(
                                                  Number(processDetail[j]?.dandoriAmt),
                                                  processDetailOr[j]?.dandoriAmt,
                                                  ij + 'AdditionalDandoriAmtCu'
                                                )
                                              : 'red',
                                        }}
                                        className={
                                          editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                        }
                                        onChange={(e) => {
                                          settingNewDandoriAmtInArr(
                                            e.target.value,
                                            i,
                                            j,
                                            'dandoriTime',
                                            'input',
                                            'input'
                                          );
                                        }}
                                      ></Input>
                                    </Col>
                                    <Col className="timer-button-col">
                                      {editMode ? (
                                        <>
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                '10min',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(
                                                editedTime,
                                                i,
                                                j,
                                                'dandoriTime',
                                                '10min',
                                                'inc'
                                              );
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                '10min',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(
                                                editedTime,
                                                i,
                                                j,
                                                'dandoriTime',
                                                '10min',
                                                'dec'
                                              );
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                'min',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'dandoriTime', 'min', 'inc');
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                'min',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'dandoriTime', 'min', 'dec');
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                'sec',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'dandoriTime', 'sec', 'inc');
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.dandoriTime,
                                                'sec',
                                                ij + 'AdditionalDandoriTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'dandoriTime', 'sec', 'dec');
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={1} className="oyabuhin-detail-col"></Col>
                                <Col span={5} className="formula-column"></Col>
                              </Row>

                              {/* 加工金額/時間 */}
                              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                                <Col span={4} className="oyabuhin-detail-col">
                                  <span style={{ marginLeft: 5 }}>加工金額/時間</span>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'AdditionalsagyoAmt'}>
                                    {processDetailOr[j]?.sagyoAmt
                                      ? JPYs.format(Math.round(processDetailOr[j]?.sagyoAmt))
                                      : amtEmptyStr}
                                  </label>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'AdditionalsagyoTime'}>
                                    {processDetailOr[j]?.sagyoTime
                                      ? secondsToHms(processDetailOr[j]?.sagyoTime)
                                      : timeHmsStr}
                                  </label>
                                </Col>
                                <Col span={4} className="input-col">
                                  <CurrencyInput
                                    id={ij + 'AdditionalsagyoAmtIn'}
                                    value={Math.round(processDetail[j]?.sagyoAmt)}
                                    defaultValue={0}
                                    onValueChange={(e) => {
                                      //checkRegex(e.target.value);
                                      if (processDetail[j]?.sagyoAmt != e) {
                                        settingNewDandoriAmtInArr(e, i, j, 'sagyoAmt', '', '');
                                        checkInputWithColor(false, '', ij + 'AdditionalsagyoAmt', e, 'amt');
                                      }
                                    }}
                                    style={{
                                      border: 'none',
                                      color:
                                        Number(processDetail[j]?.sagyoAmt) === Number(processDetailOr[j]?.sagyoAmt)
                                          ? 'black'
                                          : checkShowHideIcon(ij + 'AdditionalsagyoAmtCu') === 'none'
                                          ? '#4cc12f'
                                          : 'red',
                                    }}
                                    className={
                                      editMode
                                        ? 'input-editable currency resetColor'
                                        : 'input-non-editable currency resetColor'
                                    }
                                    prefix="¥"
                                    decimalsLimit={100}
                                  />
                                  <div id={ij + 'AdditionalsagyoAmtCu'} style={{ display: 'none' }}>
                                    <div className="currencyIcon">
                                      <BsUsbC style={{ fontSize: '25' }} />
                                      <BsX style={{ margin: '-8px 0 0 16px' }} />
                                      <span class="tooltiptext">
                                        金額に変更がありましたので、以降時間と連携して自動計算されない。
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={4} className="input-col">
                                  <Row style={{ width: '100%' }}>
                                    <Col className="timer-input-col">
                                      <Input
                                        value={
                                          processDetail[j]?.sagyoTime
                                            ? secondsToHms(processDetail[j]?.sagyoTime)
                                            : timeHmsStr
                                        }
                                        id={ij + 'AdditionalsagyoTimeIn'}
                                        style={{
                                          width: '100%',
                                          color:
                                            processDetail[j]?.sagyoTime === processDetailOr[j]?.sagyoTime
                                              ? handleShowHide(
                                                  Number(processDetail[j]?.sagyoAmt),
                                                  Number(processDetailOr[j]?.sagyoAmt),
                                                  ij + 'AdditionalsagyoAmtCu'
                                                )
                                              : oldEditedData[i]?.kasho === processDetail[i]?.kasho
                                              ? 'red'
                                              : '#4cc12f',
                                        }}
                                        className={
                                          editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                        }
                                        onChange={(e) => {
                                          //disableTimer('test');
                                          settingNewDandoriAmtInArr(
                                            e.target.value,
                                            i,
                                            j,
                                            'sagyoTime',
                                            'input',
                                            'input'
                                          );
                                        }}
                                      ></Input>
                                    </Col>
                                    <Col className="timer-button-col">
                                      {editMode ? (
                                        <>
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                '10min',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', '10min', 'inc');
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                '10min',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', '10min', 'dec');
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                'min',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', 'min', 'inc');
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                'min',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', 'min', 'dec');
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelIncreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                'sec',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', 'sec', 'inc');
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{ fontSize: 17 + 'px', color: '#000' }}
                                            onClick={() => {
                                              let editedTime = handelDecreaseTime(
                                                processDetail[j]?.sagyoTime,
                                                'sec',
                                                ij + 'AdditionalsagyoTime'
                                              );
                                              settingNewDandoriAmtInArr(editedTime, i, j, 'sagyoTime', 'sec', 'dec');
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={1} className="oyabuhin-detail-col"></Col>
                                <Col span={5} className="formula-column"></Col>
                              </Row>

                              {/* 箇所/委託 */}
                              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                                <Col span={4} className="oyabuhin-detail-col">
                                  <span className="ddl_2_noImg" style={{ marginLeft: 5 }}>
                                    箇所/委託
                                  </span>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'kasho'}>{processDetailOr[j]?.kasho}</label>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  {/* <label id="itaku">{processDetailOr[j]?.isCommission}</label> */}
                                  <Checkbox
                                    id={ij + 'itaku'}
                                    value={processDetailOr[j]?.isCommission}
                                    checked={processDetailOr[j]?.isCommission === true}
                                    disabled
                                    style={{ marginRight: 6 }}
                                  ></Checkbox>
                                </Col>

                                <Col span={4} className="input-col">
                                  <Input
                                    value={processDetail[j]?.kasho}
                                    id={ij + 'kashoIn'}
                                    min={0}
                                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                    controls={false}
                                    style={{
                                      width: '45%',
                                      border: 'none',
                                      color: processDetail[j]?.kasho === processDetailOr[j]?.kasho ? 'black' : 'red',
                                    }}
                                    onKeyPress={(e) => {
                                      handleKeyPress(e);
                                    }}
                                    onChange={(e) =>
                                      settingNewDandoriAmtInArr(
                                        e.target.value ? parseInt(e.target.value) : 0,
                                        i,
                                        j,
                                        'kasho',
                                        '',
                                        ''
                                      )
                                    }
                                  />
                                </Col>

                                <Col span={4} className="input-col">
                                  <Checkbox
                                    id={ij + 'itakuIn'}
                                    value={processDetail[j]?.isCommission}
                                    disabled
                                    // disabled={!props?.editMode}
                                    // onChange={(e) => settingNewDandoriAmtInArr(!e.target.value, i, j, 'itaku', '', '')}
                                    checked={processDetail[j]?.isCommission == true}
                                    style={{ marginRight: 6 }}
                                    className={'input-non-editable'}
                                  ></Checkbox>
                                </Col>
                                <Col span={1} className="oyabuhin-detail-col"></Col>
                                <Col span={5} className="formula-column"></Col>
                              </Row>

                              {/* 取引先/コード​ */}
                              <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                                <Col span={4} className="oyabuhin-detail-col">
                                  <span className="ddl_2_noImg" style={{ marginLeft: 5 }}>
                                    取引先/コード​
                                  </span>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'supplier'}>{processDetailOr[j]?.supplier}</label>
                                </Col>
                                <Col span={3} className="oyabuhin-detail-col">
                                  <label id={ij + 'supplierCode'}>{processDetailOr[j]?.supplierCode}</label>
                                </Col>

                                <Col span={4} className="input-col">
                                  <Input
                                    value={processDetail[j]?.supplier}
                                    id={ij + 'supplierIn'}
                                    min={0}
                                    className={'input-non-editable'}
                                    controls={false}
                                    style={{
                                      width: '45%',
                                      border: 'none',
                                      color:
                                        processDetail[j]?.supplier === processDetailOr[j]?.supplier ? 'black' : 'red',
                                    }}
                                    onChange={(e) => {
                                      settingNewDandoriAmtInArr(e.target.value, i, j, 'supplier', '', '');
                                    }}
                                  />
                                </Col>

                                <Col span={4} className="input-col">
                                  <Input
                                    value={processDetail[j]?.supplierCode}
                                    id={ij + 'supplierCodeIn'}
                                    min={0}
                                    className={'input-non-editable'}
                                    controls={false}
                                    style={{
                                      width: '45%',
                                      border: 'none',
                                      color:
                                        processDetail[j]?.supplierCode === processDetailOr[j]?.supplierCode
                                          ? 'black'
                                          : 'red',
                                    }}
                                    onChange={(e) => {
                                      settingNewDandoriAmtInArr(e.target.value, i, j, 'supplierCode', '', '');
                                    }}
                                  />
                                </Col>
                                <Col span={1} className="oyabuhin-detail-col"></Col>
                                <Col span={5} className="formula-column"></Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      );
                      return arr;
                    })()}
                  </div>
                </div>
              );
            }
          }
        }
        return tsuikaKoutei;
      })()}
    </div>
  );
});

export default IQ3KouteiCalculate_AdditionalProcess;
