/**
 * クラス名：板金の仕上有無画面
 * 作成者：チュー
 * 作成日：2023/07/14
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Checkbox } from 'antd';
import { Table } from 'react-bootstrap';

const Device_ShiageFlag = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [yousetsuTypes, setYousetsuTypes] = useState([]);
  const [shiageFlagList, setShiageFlagList] = useState([]);

  const [filterKeys, setFilterKeys] = useState([]);

  useEffect(() => {
    setEditMode(props.editMode);
    setYousetsuTypes(
      props.yousetsuTypes?.details.processDetailTypes?.filter((item) => item.group === 10710 && item.isUsed === true)
    );
    setShiageFlagList(props.detailsData?.difficultyCoeff);
    const usedIds = props?.yousetsuTypes?.details?.processDetailTypes
      ?.filter((item) => item.isUsed)
      ?.map((item) => item.id);
    setFilterKeys(usedIds);
  }, [props.detailsData, props.editMode, props.yousetsuTypes]);

  const shiageFlagListChange = (event, key) => {
    const newList = { ...shiageFlagList };

    newList[key] = event.target.checked;
    setShiageFlagList(newList);
    props.updateShiageFlagList(newList);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              Object.keys(shiageFlagList)?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))?.length > 8
                ? '100%'
                : Object.keys(shiageFlagList)?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))?.length *
                    98 +
                  196 +
                  'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <label className="tbl-header">溶接種類</label>
            </td>

            {yousetsuTypes?.map((item, index) => (
              <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                {item.name}
              </td>
            ))}
          </tr>
          <tr style={{ textAlign: 'center' }}>
            <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
              難易度係数
            </td>
            {Object.keys(shiageFlagList)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <td key={key}>
                  <Checkbox
                    style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    checked={shiageFlagList[key]}
                    onChange={(event) => shiageFlagListChange(event, key)}
                  ></Checkbox>
                </td>
              ))}
          </tr>
        </Table>
      </div>
    </>
  );
});

export default Device_ShiageFlag;
