/**
 * クラス名：材料名称詳細画面
 * 説明：iQ3板金に使用の材料名称詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Button, Radio, Image, Select, InputNumber, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import checkedImg from '../../../../assets/images/checked.png';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import {
  JPYs,
  defaultValue,
  formValidatorMode,
  formatDate,
  getZaishittsuNameDDL,
  isDecimal,
  regexExp,
  toHalfWidth,
} from '../../../common/Common.js';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialNameDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [materialTypeIQ3List, setMaterialTypeIQ3List] = useState([]);

  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify(props.selectedData));
      setOriginData(props.selectedData);
      setSelectedRowData(selectedData);
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    setOriginData(selectedRowData);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  useEffect(() => {
    setMaterialTypeIQ3List(props?.materialTypeIQ3List);
  }, [props.materialTypeIQ3List]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      const updatedData = { ...prevData, info: { ...prevData.info } };
      setKeyValue(updatedData, objKey, value);
      if (objKey === 'materialTypeIq3Id') {
        setKeyValue(updatedData, 'gravity', materialTypeIQ3List?.filter((item) => item.value === value)?.[0]?.gravity);
      }
      return updatedData;
    });
  };

  const setKeyValue = (data, key, value) => {
    Object.keys(data?.info)?.filter((v) => v === key)?.length > 0 ? (data.info[key] = value) : (data[key] = value);
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    /*   setUpdateConfirm(true);
    setIsUpdate(true); */

    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode, isDetail = true) => {
    let ret = true;
    let message = [];
    // コード
    let input = inputItems['code']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材料名
    input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材料名').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質名称
    if (isDetail) {
      input = document.getElementsByName('materialTypeIq3Id')?.[0];
    } else {
      input = document.getElementsByName('materialTypeIq3Id')?.[0];
    }
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText === defaultValue.materialTypeIq3 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('材質名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 板厚
    input = inputItems['thick']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('板厚').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('板厚').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 比重
    input = inputItems['gravity']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('比重').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('比重').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 重量単価
    input = inputItems['price'];
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('重量単価').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = (e) => {
    let updateData = [];
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      updateData = { ...selectedRowData };
      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData(originData);
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };
  const filterOption = (input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  // 項目値を変更した場合、
  const onChangeInputForm = (name, value, isCheckZenkaku = true) => {
    if (isCheckZenkaku && regexExp.test(value)) {
      // 全角の場合、
      value = toHalfWidth(value); // 半角へ変換
    }
    editDetailInput(name, value);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr> */}

              {/* コード */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">コード</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="code"
                    id="code"
                    value={selectedRowData?.code}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['code'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('code', e.target.value)}
                  />
                </td>
              </tr>

              {/* 材料名 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材料名</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>

              {/* 材質名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">材質名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <Select
                      showSearch
                      style={{ marginLeft: 5, width: '98.2%' }}
                      onChange={(e) => {
                        editDetailInput('materialTypeIq3Id', e);
                      }}
                      filterOption={filterOption}
                      placeholder="材質名称"
                      id="materialTypeIq3Id"
                      name="materialTypeIq3Id"
                      value={selectedRowData?.materialTypeIq3Id}
                      defaultValue={''}
                    >
                      <Select.Option id="allOption" value={''}>
                        {'材質名称を選択してください。'}
                      </Select.Option>
                      {materialTypeIQ3List?.map((item, index) => (
                        <Select.Option key={index} id={'materialTypeIq3Id' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label className="param_detail-title-label">
                      {props?.getMaterilTypeIQ3Name(selectedRowData?.materialTypeIq3Id)}
                    </label>
                  )}
                </td>
              </tr>
              {/* 板厚 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">板厚</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="thick"
                    id="thick"
                    value={selectedRowData?.info?.thick}
                    type="text"
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                    ref={(ref) => {
                      inputItemRef['thick'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* 比重 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">比重</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="gravity"
                    id="gravity"
                    value={selectedRowData?.info?.gravity}
                    type="text"
                    onBlur={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onPressEnter={(e) => onChangeInputForm(e.target.name, e.target.value)}
                    onChange={(e) => onChangeInputForm(e.target.name, e.target.value, false)}
                    ref={(ref) => {
                      inputItemRef['gravity'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>
              {/* 重量単価 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">重量単価</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <CurrencyInput
                    name="unitPrice"
                    id="unitPrice"
                    type="text"
                    data-name="name"
                    className={props?.getAntInputBoxClassName('remarks', editMode)}
                    value={selectedRowData?.info?.unitPrice}
                    onValueChange={(e) => editDetailInput('unitPrice', e)}
                    prefix="¥"
                    decimalsLimit={100}
                    ref={(ref) => {
                      inputItemRef['unitPrice'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.created ? formatDate(selectedRowData?.created) : ''}
                  </label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  </label>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>{selectedRowData?.modifier}</label>
                </td>
              </tr>
              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    type="text"
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    data-name="name"
                    name="remarks"
                    id="remarks"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData?.info?.remarks}
                    onChange={(e) => editDetailInput('remarks', e.target.value)}
                    maxLength={2048}
                  />
                </td>
              </tr>

              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isUsed"
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('isUsed', e.target.value);
                      }}
                      value={selectedRowData?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isUsed && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div className="updHistoryDiv" style={{ maxHeight: '292px', overflowY: 'auto' }}>
            <Table className="updHistory">
              <thead
                style={{
                  backgroundColor: '#fafafa',
                  color: '#1063aa',
                  height: 20,
                  position: 'sticky',
                  top: 0,
                }}
              >
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '50%' }}>更新日</th>
                  <th style={{ width: '50%' }}>重量単価</th>
                </tr>
              </thead>

              <tbody style={{ textAlign: 'center' }}>
                {(() => {
                  const tblRow = [];
                  let updHistory = { ...selectedRowData }?.priceHistories || [];

                  for (let j = 0; j < updHistory?.length; j++) {
                    tblRow.push(
                      <tr>
                        <td style={{ width: '50%' }}>
                          <label>{updHistory[j]?.modified ? formatDate(updHistory[j]?.modified) : ''}</label>
                        </td>
                        <td className="cm-a-right" style={{ width: '50%' }}>
                          <label>{JPYs.format(updHistory[j]?.unitPrice)}</label>
                        </td>
                      </tr>
                    );
                  }
                  return tblRow;
                })()}
              </tbody>
            </Table>
          </div>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialNameDetail;
