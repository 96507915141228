import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Input, Radio, Checkbox, Select, Table as AntdTBL, Modal, Button, Space, Form } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import { Table } from 'react-bootstrap';

import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDeleteContent,
  error,
} from '../common/CommonModal';
import { getPurchaseInfo, getPurchaseCategoryInfo } from '../common/CommonAPI';
import { JPYs, isDigit, secondsToHms, toCheckZenkaku } from '../common/Common';
import { WorkType } from '../common/enums';
import { ErrorMessage } from '../common/Message';
import { amtEmptyStr } from '../common/Constant';

const suryou = 1;

const IQ3Purchase = forwardRef((props, ref) => {
  const [isEditPurchase, setIsEditPurchase] = useState(false);
  const [isMasterAdd, setIsMasterAdd] = useState(false);
  // const [openPurchaseDetail, setOpenPurchaseDetail] = useState(true);
  const [openChildPurchaseDetail, setOpenChildPurchaseDetail] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [childSelectedRowKeys, setChildSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [modalTitle, setModalTitle] = useState([false]);
  const [purchaseKey, setPurchaseKey] = useState(1);
  const [purchaseCategory, setPurchaseCategory] = useState(0);
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [required, setRequired] = useState('');
  const [supplier, setSupplier] = useState('');
  const [maker, setMaker] = useState('');
  const [keepPrice, setKeepPrice] = useState(false);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [purchaseRemark, setPurchaseRemark] = useState('');
  const [unitPriceTotal, setUnitPriceTotal] = useState(0);
  const [purchaseLst, setPurchaseLst] = useState([]);
  const [purchaseLstBK, setPurchaseLstBK] = useState([]);

  const [suryoubetsuPrice, setSuryoubetsuPrice] = useState(0);
  const [suryoubetsuTotalPrice, setSuryoubetsuTotalPrice] = useState(0);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [masterPurchaseLst, setMasterPurchaseLst] = useState([]);
  const [masterPurchaseLstTemp, setMasterPurchaseLstTemp] = useState([]);
  const [isDelete, setIsDelete] = useState(false);

  const [purchaseCategoryInfos, setPurchaseCategoryInfos] = useState([]);
  const [purchaseCategoryId, setPurchaseCategoryId] = useState(0);
  const [toritsukeTotalTime, setToritsukeTotalTime] = useState();

  const [filterCategory, setFilterCategory] = useState(0);
  const [filterName, setFilterName] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterMaker, setFilterMaker] = useState('');
  const [filterSupplier, setFilterSupplier] = useState('');

  const [noOfMembers, setNoOfMembers] = useState(0);
  const [quantity, setQuantity] = useState(0);
  // const [purchaseUnitPrice,setPurchaseUnitPrice] = useState(0);

  const [currentRecordIndex, setCurrentRecordIndex] = useState(1);

  const [selectedRow, setSelectedRow] = useState(0);

  const [isPurchaseAutoAdd, setIsPurchaseAutoAdd] = useState(false);
  const [isPurchaseAutoDelete, setIsPurchaseAutoDelete] = useState(false);

  useEffect(() => {
    if (props.selectedEstimateData != undefined) {
      let purchaseLsts = [];
      let selectedIQ3DataDetail = [];
      let curRowIndex = 1;
      let curRowNo = props?.currentRecordNo ? props?.currentRecordNo : 1;
      for (let i = 0; i < props.selectedEstimateData?.esIq3Info?.length; i++) {
        if (props.selectedEstimateData?.esIq3Info[i]?.no === curRowNo) {
          setCurrentRecordIndex(i);
          curRowIndex = i;
          selectedIQ3DataDetail = props.selectedEstimateData?.esIq3Info[i];
          purchaseLsts = selectedIQ3DataDetail?.purchases;
        }
      }
      let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters
        ? JSON.parse(JSON.stringify(props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters))
        : [];
      const purchases = parameters?.purchase?.filter((i) => i?.info?.isUsed);
      setMasterPurchaseLst(purchases);
      setMasterPurchaseLstTemp(purchases);
      setPurchaseCategoryInfos(parameters?.purchaseCategory);

      const qty = props.selectedEstimateData?.estimateProducts
        ? props.selectedEstimateData?.estimateProducts?.quantity
        : 0;
      setQuantity(qty);

      setNoOfMembers(selectedIQ3DataDetail?.partCount);
      const members = selectedIQ3DataDetail?.partCount ? selectedIQ3DataDetail?.partCount : 0;

      if (purchaseLsts?.length > 0) {
        const modifiedData = purchaseLsts?.map((item, index) => ({
          ...item,
          price: item.isKeepPrice ? item?.unitPrice * item?.required : item?.unitPrice, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
          totalPrice: item.isKeepPrice ? item?.unitPrice * item?.required * members * qty : item?.unitPrice, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価 // IQX_WEBEST-256
          totalRequired: item?.required * members * qty,
          totalProcessTime: item?.processTime * members * qty,
          totalProcessTime: item?.processTime * members * qty,
        }));
        let purchases = modifiedData?.filter((item) => !item.isDeleted);
        setSelectedRow(purchases?.length > 0 ? purchases?.[0]?.purchaseId : 0);
        setSelectedData(purchases?.length > 0 ? purchases?.[0] : []);
        setPurchaseLst(modifiedData);
        setPurchaseLstBK(modifiedData);
        calculateTotal(modifiedData, curRowIndex);
        if (props.selectedEstimateData?.esIq3Info?.[curRowIndex]) {
          props.selectedEstimateData.esIq3Info[curRowIndex].purchases = modifiedData;
        }
      } else {
        setPurchaseLst([]);
        setPurchaseLstBK([]);
        setPurchaseAmount(0);
        setUnitPriceTotal(0);
        setSuryoubetsuPrice(0);
        setSuryoubetsuTotalPrice(0);
        setToritsukeTotalTime('00:00:00');
        calculateTotal([], curRowIndex);
        if (props.selectedEstimateData?.esIq3Info?.[curRowIndex]) {
          props.selectedEstimateData.esIq3Info[curRowIndex].purchases = [];
        }
      }
      setPurchaseCategoryId(0);
    }
  }, [
    props.selectedIQ3DataDetail,
    props.selectedEstimateData,
    props.getParentQuantity,
    props.activeTabKey,
    props.currentRecordNo,
    props.selectedEstimateData?.estimateProducts?.quantity,
  ]);

  const openChildPurchase = () => {
    if (openChildPurchaseDetail == true) {
      setOpenChildPurchaseDetail(false);
    } else {
      setOpenChildPurchaseDetail(true);
    }
  };
  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  const onRowSelect = (record) => {
    setSelectedRow(record.purchaseId);
    setSelectedData(record);
  };
  const onChildRowSelect = (record) => {
    setChildSelectedRowKeys(record.id);
    setSelectedData(record);
  };
  const onRowCheckboxSelect = (checked, record) => {
    const checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };
  const onRdoChange = (e) => {
    setVersion(e.target.value);
  };

  const addNew = () => {
    setPurchaseCategory(0);
    setName('');
    setModel('');
    setUnitPrice('');
    setRequired('');
    setSupplier('');
    setMaker('');
    setPurchaseRemark('');
    setKeepPrice(false);
    setIsMasterAdd(false);
    setIsEditPurchase(false);
    setModalTitle('追加');
    setAddModal(true);
  };
  const editPurchaseInfo = () => {
    if (selectedData != undefined) {
      setPurchaseKey(selectedData.purchaseId);
      setPurchaseCategory(selectedData.purchaseCategoryId);
      setName(selectedData.name);
      setModel(selectedData.model);
      setUnitPrice(selectedData.unitPrice);
      setRequired(selectedData.required);
      setSupplier(selectedData.supplierName);
      setMaker(selectedData.makerName);
      setPurchaseRemark(selectedData.remarks);
      // setPurchaseAmount(selectedData.purchaseAmount);
      setKeepPrice(selectedData.isKeepPrice);
    } else {
      setPurchaseKey('');
      setPurchaseCategory('');
      setName('');
      setModel('');
      setUnitPrice('');
      setRequired('');
      setSupplier('');
      setMaker('');
      setPurchaseRemark('');
      // setPurchaseAmount('');
      setKeepPrice(false);
    }
    setAddModal(true);
    setIsMasterAdd(false);
    setIsEditPurchase(true);
    setModalTitle('編集');
  };
  const addFromMaster = () => {
    // getPurchaseData();
    setIsMasterAdd(true);
    setModalTitle('購入品マスターから追加');
    setAddModal(false);
  };

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;

    // 単価
    input = document.getElementsByName('childPurUnitPrice')?.[0];
    input?.classList?.remove('input-error');
    if (input?.value?.length === 0) {
      // 必須
      message.push(<li>{ErrorMessage('単価').E007}</li>);
      input?.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('単価').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 必要数
    input = document.getElementsByName('childPurRequired')?.[0];
    input?.classList?.remove('input-error');
    if (input?.value?.length === 0) {
      // 必須
      message.push(<li>{ErrorMessage('必要数').E007}</li>);
      input?.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('必要数').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  const addOK = () => {
    const nextId = Math.max(...purchaseLstBK?.map((item) => item.purchaseId), 0) + 1;
    if (isMasterAdd) {
      // master add
      const filteredData = masterPurchaseLst?.filter((item) => selectedRowKeys?.includes(item.id));

      const modifiedData = filteredData?.map((item, index) => ({
        purchaseId: nextId + index,
        name: item.name,
        model: item.info.model,
        unitPrice: item.price,
        unitPriceByQuantity: 0,
        required: 1,
        price: item.info.isKeepPrice ? item.price * 1 : item.price, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
        totalPrice: item.info.isKeepPrice ? item.price * 1 * noOfMembers * quantity : item.price, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価
        // priceByQuantity: 0,
        totalRequired: 1 * noOfMembers * quantity,
        isKeepPrice: item.info.isKeepPrice,
        purchaseCategoryId: item.purchaseCategoryId,
        processTime: item.info.processTime,
        totalProcessTime: item.info.processTime * noOfMembers * quantity,
        supplierCode: item.info.supplierCode,
        supplierName: item.info.supplierName,
        makerName: item.info.makerName,
        remarks: item.info.remarks,
        purchaseMasterId: item.id,
        isDeleted: false,
        purchaseAllocationId: item.purchaseAllocationId,
      }));
      setPurchaseLst([...purchaseLst, ...modifiedData]);
      setPurchaseLstBK([...purchaseLstBK, ...modifiedData]);
      calculateTotal([...purchaseLstBK, ...modifiedData], currentRecordIndex);
      const purchaseData = [...purchaseLst, ...modifiedData];
      const purchases = purchaseData?.filter((item) => !item.isDeleted);
      setSelectedRow(purchases?.length > 0 ? purchases[0].purchaseId : 0);
      setSelectedData(purchases?.length > 0 ? purchases[0] : []);
      if (props.selectedIQ3DataDetail != undefined) {
        props.selectedIQ3DataDetail.purchases = [...purchaseLstBK, ...modifiedData];
      }
      if (props.selectedEstimateData.esIq3Info[currentRecordIndex]) {
        props.selectedEstimateData.esIq3Info[currentRecordIndex].purchases = [...purchaseLstBK, ...modifiedData];
      }
    } else {
      if (!formValidation()) return;
      const addNewPurchase = {
        purchaseId: nextId,
        name: name,
        model: model,
        unitPrice: unitPrice,
        unitPriceByQuantity: 0,
        required: required,
        price: keepPrice ? unitPrice * required : unitPrice, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
        totalPrice: keepPrice ? unitPrice * required * noOfMembers * quantity : unitPrice, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価
        // priceByQuantity: 0,
        totalRequired: required * noOfMembers * quantity,
        isKeepPrice: keepPrice,
        purchaseCategoryId: parseInt(purchaseCategory),
        processTime: 0,
        totalProcessTime: 0,
        supplierCode: '',
        supplierName: supplier,
        makerName: maker,
        remarks: purchaseRemark,
        purchaseMasterId: 0,
        isDeleted: false,
        purchaseAllocationId: 0,
      };
      let addPurArr = [...purchaseLst, addNewPurchase];

      setPurchaseLst(addPurArr);
      setPurchaseLstBK([...purchaseLstBK, addNewPurchase]);
      calculateTotal([...purchaseLstBK, addNewPurchase], currentRecordIndex);
      const purchases = addPurArr?.filter((item) => !item.isDeleted);
      setSelectedRow(purchases?.length > 0 ? purchases[0].purchaseId : 0);
      setSelectedData(purchases?.length > 0 ? purchases[0] : []);
      if (props.selectedIQ3DataDetail != undefined) {
        props.selectedIQ3DataDetail.purchases = [...purchaseLstBK, addNewPurchase];
      }
      if (props.selectedEstimateData.esIq3Info[currentRecordIndex]) {
        props.selectedEstimateData.esIq3Info[currentRecordIndex].purchases = [...purchaseLstBK, addNewPurchase];
      }
    }
    setAddModal(false);
    setIsMasterAdd(false);
  };
  const addCancel = () => {
    setAddModal(false);
    setIsMasterAdd(false);
    let input = '';
    input = document.getElementsByName('childPurUnitPrice')?.[0];
    input?.classList?.remove('input-error');
    input = document.getElementsByName('childPurRequired')?.[0];
    input?.classList?.remove('input-error');
  };
  const getPriceForUpperThVal = (upperThVal, data) => {
    for (let i = 0; i < data?.length; i++) {
      const currentItem = data[i];
      if (upperThVal >= currentItem.upperTh) {
        // Check if it's the last item or the next upperTh is greater than upperThVal
        if (i === data?.length - 1 || upperThVal < data[i + 1].upperTh) {
          return currentItem.price;
        }
      }
    }

    // If no match is found, return a default value or handle it as needed
    return 0;
  };
  const updatePurchase = () => {
    const masterData = masterPurchaseLst?.filter((item) => selectedData.purchaseMasterId === item.id);

    let getPriceLots = [];
    let unitPriceByQty;
    if (masterData?.length > 0) {
      getPriceLots = masterData[0].priceLots;
      unitPriceByQty = getPriceForUpperThVal(required, getPriceLots);
    } else {
      unitPriceByQty = 0;
    }

    let editArr = [...purchaseLstBK];
    editArr = editArr?.map((item) =>
      item.purchaseId === purchaseKey
        ? {
            ...item,
            unitPrice: unitPrice,
            required: required,
            unitPriceByQuantity: unitPriceByQty,
            price:
              unitPriceByQty > 0
                ? keepPrice
                  ? unitPriceByQty * required
                  : unitPriceByQty
                : keepPrice
                ? unitPrice * required
                : unitPrice, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
            totalPrice:
              unitPriceByQty > 0
                ? keepPrice
                  ? unitPriceByQty * required * noOfMembers * quantity
                  : unitPriceByQty
                : keepPrice
                ? unitPrice * required * noOfMembers * quantity
                : unitPrice, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価
            totalRequired: required * noOfMembers * quantity,
            isKeepPrice: keepPrice,
          }
        : item
    );
    let updateSelectedData = editArr?.filter((item) => item.purchaseId === selectedData?.purchaseId && !item.isDeleted);
    if (updateSelectedData?.length > 0) setSelectedData(updateSelectedData[0]);
    setPurchaseLstBK(editArr);
    calculateTotal(editArr, currentRecordIndex);
    filterUpdatedDataByCategory(purchaseCategoryId, editArr);
    if (props.selectedIQ3DataDetail != undefined) {
      props.selectedIQ3DataDetail.purchases = editArr;
    }
    if (props.selectedEstimateData.esIq3Info[currentRecordIndex]) {
      props.selectedEstimateData.esIq3Info[currentRecordIndex].purchases = editArr;
    }

    setAddModal(false);
    setIsMasterAdd(false);
  };
  const deleteConfirm = (e) => {
    setIsDelete(true);
  };
  const deletePurchase = () => {
    // let dataRow = [...purchaseLst];
    let dataRow = [...purchaseLstBK];
    // const updatedData = dataRow?.filter((item) => selectedData.purchaseId != item.purchaseId);
    const updatedData = dataRow?.map((item) => {
      if (item.purchaseId === selectedData.purchaseId) {
        return { ...item, isDeleted: true };
      }
      return item;
    });

    // Update id values
    // updatedData?.forEach((item, index) => {
    //   item.purchaseId = index + 1;
    // });

    setPurchaseLst(updatedData);

    setPurchaseLstBK(updatedData);
    calculateTotal(updatedData, currentRecordIndex);
    const purchases = updatedData?.filter((item) => !item.isDeleted);

    setSelectedRow(purchases?.length > 0 ? purchases[0].purchaseId : 0);
    setSelectedData(purchases?.length > 0 ? purchases[0] : []);
    setIsDelete(false);
    filterUpdatedDataByCategory(purchaseCategoryId, updatedData);
    if (props.selectedIQ3DataDetail != undefined) {
      props.selectedIQ3DataDetail.purchases = updatedData;
    }
    if (props.selectedEstimateData.esIq3Info[currentRecordIndex]) {
      props.selectedEstimateData.esIq3Info[currentRecordIndex].purchases = updatedData;
    }
  };
  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };
  // const clearPurchase = () => {
  //   // setPurchaseLst(purchaseLstArr);
  //   setEditMode(false);
  // };
  const addNewModalTitle = (
    <div
      style={{
        width: isMasterAdd ? '104.8%' : '109.8%',
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitle}</p>
    </div>
  );

  useImperativeHandle(
    ref,
    () => ({
      setEditModeChange: setEditModeChange,
      // clearPurchase: clearPurchase,
      getIQ3PurchaseInfo: () => {
        return purchaseLstBK;
      },
    }),
    [purchaseLstBK]
  );
  // コードで名称を取得
  function getNameById(id) {
    let retValue = '';
    if (id) {
      let info = purchaseCategoryInfos?.filter((info) => info.id === id);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  const purchaseColumns = [
    // {
    //   id: '1',
    //   title: '購入品マスタId',
    //   dataIndex: 'purchaseId',
    //   width: 180 + 'px',
    //   hidden: true,
    // },
    {
      id: '1',
      title: '種別',
      dataIndex: 'purchaseCategoryId',
      width: 180 + 'px',
      render: (_, record) =>
        purchaseLst?.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>{getNameById(record.purchaseCategoryId)}</label>
        ) : null,
    },
    {
      id: '2',
      title: '型番',
      dataIndex: 'model',
      width: 120 + 'px',
    },
    {
      id: '3',
      title: '品名',
      dataIndex: 'name',
      width: 200 + 'px',
    },

    {
      id: '4',
      title: '単価',
      dataIndex: 'unitPrice',
      width: 100 + 'px',
      className: 'cm-a-right',
      // render: (_, record) =>
      //   purchaseLst?.length >= 1 ? (
      //     <label style={{ fontSize: '13px', color: record.totalRequiredNum >= 50 ? '#A5A5A5' : '#4A4747' }}>
      //       {record.unitPrice == undefined || record.unitPrice == '' ? '' : '¥' + record.unitPrice}
      //     </label>
      //   ) : null,
      render: (text, record) => (
        <label
          style={{
            color: record.unitPriceByQuantity > 0 ? '#A5A5A5' : '#4A4747',
            display: 'block',
          }}
        >
          {text ? JPYs.format(text) : amtEmptyStr}
        </label>
      ),
    },
    // {
    //   id: '5',
    //   title: '単価保持',
    //   dataIndex: 'unitPriceSave',
    //   width: 70 + 'px',
    //   className: 'cm-a-center',
    //   render: (_, record) =>
    //     purchaseLst?.length >= 1 ? (
    //       <Checkbox style={{ marginLeft: '5px' }} disabled={true} checked={record.unitPriceSave} />
    //     ) : null,
    // },
    {
      id: '5',
      title: '数量別単価',
      dataIndex: 'unitPriceByQuantity',
      width: 100 + 'px',
      className: 'cm-a-right',
      // className: (record) => (record.unitPriceByQuantity > 0 ? 'cm-a-right' : 'test cm-a-right'),

      render: (text, record) => (
        <label
          style={{
            color: record.unitPriceByQuantity > 0 ? '#4A4747' : '#A5A5A5',
            display: 'block',
          }}
        >
          {text}
        </label>
      ),
    },
    {
      id: '6',
      title: '必要数',
      dataIndex: 'required',
      width: 80 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '7',
      title: '金額',
      dataIndex: 'price',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst?.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>
            {/* {record.price == undefined || record.price == '' ? '' : '¥' + record.price} */}
            {record.price ? JPYs.format(record.price) : amtEmptyStr}
          </label>
        ) : null,
    },
    // {
    //   id: '9',
    //   title: '合計必要数',
    //   dataIndex: 'totalRequiredNum',
    //   width: 80 + 'px',
    //   className: 'cm-a-right',
    // },
    {
      id: '8',
      title: '合計必要数',
      dataIndex: 'totalRequired',
      width: 120 + 'px',
    },
    {
      id: '9',
      title: '合計金額',
      dataIndex: 'totalPrice',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst?.length >= 1 ? (
          // <label style={{ fontSize: '13px', color: record.totalRequiredNum >= 50 ? '#A5A5A5' : '#4A4747' }}>
          <label style={{ fontSize: '13px' }}>
            {/* {record.totalPrice == undefined || record.totalPrice == '' ? '' : '¥' + record.totalPrice} */}
            {record.totalPrice ? JPYs.format(record.totalPrice) : amtEmptyStr}
          </label>
        ) : null,
    },
    // {
    // id: '9',
    // title: '数量別金額',
    // dataIndex: 'priceByQuantity',
    // width: 100 + 'px',
    // className: 'cm-a-right',
    // render: (_, record) =>
    //   purchaseLst?.length >= 1 ? (
    //     <label style={{ fontSize: '13px' }}>
    //       {record.priceByQuantity == undefined || record.priceByQuantity == ''
    //         ? ''
    //         : '¥' + record.priceByQuantity}
    //     </label>
    //   ) : null,
    // },
    // {
    //   id: '12',
    //   title: '取付時間',
    //   dataIndex: 'processTime',
    //   width: 100 + 'px',
    //   className: 'cm-a-center',
    //   // render: (_, record) =>
    //   //   purchaseLst?.length >= 1 ? (
    //   //     <label style={{ fontSize: '13px' }}>
    //   //       {record.suryoubetsuTotalPrice == undefined || record.suryoubetsuTotalPrice == ''
    //   //         ? ''
    //   //         : '¥' + record.suryoubetsuTotalPrice}
    //   //     </label>
    //   //   ) : null,
    // },
    // {
    //   id: '13',
    //   title: '合計取付時間',
    //   dataIndex: 'toritsukeTotalTime',
    //   width: 100 + 'px',
    //   className: 'cm-a-center',
    //   // render: (_, record) =>
    //   //   purchaseLst?.length >= 1 ? (
    //   //     <label style={{ fontSize: '13px' }}>
    //   //       {record.suryoubetsuTotalPrice == undefined || record.suryoubetsuTotalPrice == ''
    //   //         ? ''
    //   //         : '¥' + record.suryoubetsuTotalPrice}
    //   //     </label>
    //   //   ) : null,
    // },
    {
      id: '10',
      title: '単価保持',
      dataIndex: 'isKeepPrice',
      width: 120 + 'px',
      render: (_, record) =>
        purchaseLst?.length >= 1 ? (
          <Checkbox style={{ marginLeft: '5px' }} disabled={true} checked={record.isKeepPrice} />
        ) : null,
    }, // IQX_WEBEST-166 単価優先を非表示する->IQX_WEBEST-229
    {
      id: '11',
      title: '取付時間',
      dataIndex: 'processTime',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
        </div>
      ),
    },
    {
      id: '12',
      title: '合計取付時間',
      dataIndex: 'totalProcessTime',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
        </div>
      ),
    },
    {
      id: '13',
      title: '仕入先コード',
      dataIndex: 'supplierCode',
      width: 150 + 'px',
    },
    {
      id: '14',
      title: '仕入先名',
      dataIndex: 'supplierName',
      width: 150 + 'px',
    },
    {
      id: '15',
      title: 'メーカー',
      dataIndex: 'makerName',
      width: 150 + 'px',
    },

    {
      id: '16',
      title: '備考',
      dataIndex: 'remarks',
      width: 200 + 'px',
    },
    // {
    //   id: '17',
    //   title: '',
    //   dataIndex: 'id',
    //   width: 0 + '%',
    //   hidden: true,
    // },
  ];
  // const childPurchaseColumns = [
  //   {
  //     id: '1',
  //     title: '品名',
  //     dataIndex: 'name',
  //     // sorter: (a, b) => a.id - b.id,
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '2',
  //     title: '型番',
  //     dataIndex: 'model',
  //     width: 65 + '%',
  //   },
  //   {
  //     id: '3',
  //     title: '単価',
  //     dataIndex: 'unitPrice',
  //     width: 60 + '%',
  //     render: (_, record) =>
  //       purchaseLst?.length >= 1 ? <label style={{ fontSize: '13px' }}>{'¥' + record.unitPrice}</label> : null,
  //   },
  //   {
  //     id: '4',
  //     title: '必要数',
  //     dataIndex: 'required',
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '5',
  //     title: '金額',
  //     dataIndex: 'purchaseAmount',
  //     width: 60 + '%',
  //     render: (_, record) =>
  //       purchaseLst?.length >= 1 ? <label style={{ fontSize: '13px' }}>{'¥' + record.purchaseAmount}</label> : null,
  //   },
  //   {
  //     id: '6',
  //     title: '合計必要数',
  //     dataIndex: 'totalRequiredNum',
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '7',
  //     title: '合計金額',
  //     dataIndex: 'totalPurchaseAmount',
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '8',
  //     title: '仕入先',
  //     dataIndex: 'supplier',
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '9',
  //     title: 'メーカー',
  //     dataIndex: 'maker',
  //     width: 60 + '%',
  //   },
  //   {
  //     id: '10',
  //     title: '備考',
  //     dataIndex: 'purchaseRemark',
  //     width: 65 + '%',
  //   },
  // ];
  const masterPurchaseColumns = [
    {
      id: '1',
      title: '型番',
      dataIndex: 'info',
      render: (info) => info.model,
    },
    {
      id: '2',
      title: '品名',
      dataIndex: 'name',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      id: '3',
      title: '単価',
      dataIndex: 'price',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst?.length >= 1 ? (
          <label style={{ fontSize: '13px' }}>{record.price ? JPYs.format(record.price) : amtEmptyStr}</label>
        ) : null,
    },
    {
      id: '4',
      title: '必要数',
      dataIndex: 'required',
    },

    {
      id: '5',
      title: '仕入先',
      dataIndex: 'info',
      render: (info) => info.supplierName,
    },
    {
      id: '6',
      title: 'メーカー',
      dataIndex: 'info',
      render: (info) => info.makerName,
    },
    {
      id: '7',
      title: '金額',
      dataIndex: 'purchaseAmount',
      className: 'cm-a-right',
      render: (_, record) =>
        purchaseLst?.length >= 1 && record?.purchaseAmount !== undefined ? (
          <label style={{ fontSize: '13px' }}>
            {record?.purchaseAmount ? JPYs.format(record?.purchaseAmount) : amtEmptyStr}
          </label>
        ) : null,
    },
    {
      id: '8',
      title: '備考',
      dataIndex: 'info',
      render: (info) => info.remarks,
    },
    // {
    //   id: '9',
    //   title: 'No',
    //   dataIndex: 'no',
    //   sorter: (a, b) => a.id - b.id,
    //   sortOrder: 'ascend',
    //   className: 'cm-a-right',
    //   hidden: true,
    // },
  ];
  const clearData = () => {
    setFilterCategory(0);
    setFilterModel('');
    setFilterName('');
    setFilterMaker('');
    setFilterSupplier('');
    setMasterPurchaseLst(masterPurchaseLstTemp);
  };
  const filterData = () => {
    const currentData = [...masterPurchaseLstTemp];
    let purchaseFilterData = currentData;
    if (filterCategory > 0) {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.purchaseCategoryId === filterCategory);
    }
    if (filterName != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.name?.includes(filterName));
    }
    if (filterModel != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.info.model?.includes(filterModel));
    }
    if (filterMaker != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.info.makerName?.includes(filterMaker));
    }
    if (filterSupplier != '') {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.info.supplierName?.includes(filterSupplier));
    }

    setMasterPurchaseLst(purchaseFilterData);
  };
  const addMasterContent = (
    <>
      <Form name="AddForm" autoComplete="off">
        <div id="addNewCustomer" style={{ marginTop: 15, display: 'flex' }}>
          <Table className="table table-bordered">
            <thead>
              <tr className="detail-tbl-row">
                <td className="detail-tbl-val-title">種別</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Select
                    style={{ width: '100%', paddingLeft: 3 }}
                    id="filterCategory"
                    name="filterCategory"
                    defaultValue={0}
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e)}
                  >
                    <Select.Option value={0}>{'ALL'}</Select.Option>
                    {purchaseCategoryInfos
                      ?.filter((i) => i?.info?.isUsed)
                      ?.map((item, index) => (
                        <Select.Option key={index} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="detail-tbl-val-title">品名</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="filterName"
                    type="text"
                    id="filterName"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">型番</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="filterModel"
                    type="text"
                    id="filterModel"
                    value={filterModel}
                    onChange={(e) => setFilterModel(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">メーカー</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="filterMaker"
                    type="text"
                    id="filterMaker"
                    value={filterMaker}
                    onChange={(e) => setFilterMaker(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">仕入先</td>
                <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
                  <Input
                    name="filterSupplier"
                    type="text"
                    id="filterSupplier"
                    value={filterSupplier}
                    onChange={(e) => setFilterSupplier(e.target.value)}
                    style={{ padding: 3 }}
                  />
                </td>
              </tr>
            </thead>
          </Table>
          <div style={{ marginLeft: '10px', marginTop: '79px', width: '8%' }}>
            <Button key="submit28" onClick={filterData} className="mainButton">
              絞込
            </Button>
            <Button key="submit29" onClick={clearData} className="cancelButton" style={{ marginTop: '5px' }}>
              クリア
            </Button>
          </div>
        </div>
        <div style={{ marginTop: '25px' }}>
          <Row>
            <AntdTBL
              className="tbPurchaseListShow"
              columns={masterPurchaseColumns}
              dataSource={
                masterPurchaseLst !== undefined
                  ? masterPurchaseLst
                      .slice()
                      .sort((a, b) => Number(a?.info?.sortNum) - Number(b?.info?.sortNum))
                      .map((item) => ({ ...item, key: item.id }))
                  : []
              }
              rowKey={(record) => record.id}
              // rowClassName={(record) => (record.id == childSelectedRowKeys ? 'active-row' : 'data-row')}
              scroll={{ y: 500, x: '10vw' }}
              pagination={false}
              rowSelection={rowSelection}
              // onRow={(record, index) => {
              //   return {
              //     onClick: (event) => {
              //       onChildRowSelect(record);
              //     },
              //   };
              // }}
            />
          </Row>
        </div>
      </Form>
    </>
  );

  const purchaseAutoAddOK = () => {
    setIsPurchaseAutoAdd(false);
    let purchaseAutoAddArr = [];
    // 自動引当情報取得
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters
      ? JSON.parse(JSON.stringify(props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters))
      : [];
    let purchaseAllocation = parameters?.purchaseAllocation;
    let targetPurchaseAllocation = [];
    // 現在指定している材質名称により材質ID取得
    let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;
    // 自動引当購入品情報の材質区分と一致するため、材質IDより材質区分ID取得
    let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item?.id === materialTypeIq3Id)?.[0]
      ?.materialTypeId;
    materialTypeId = materialTypeId ? materialTypeId : 0;
    // 追加ID
    let nextId = Math.max(...purchaseLstBK?.map((item) => item.purchaseId), 0) + 1;
    //　自動引当対象とする溶接工程情報を取得
    let weldProcessId = parameters?.process?.filter((item) => item.workType === WorkType.SmWelding)?.[0]?.id;
    let weldProcessAdditionInfo = props.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId === weldProcessId
    );
    if (weldProcessAdditionInfo?.length > 0) {
      // 1:サブ溶接データを絞り込む
      let subWeldAdditionInfo = weldProcessAdditionInfo[0]?.dataItems?.filter((item) => item.weldType == 1);
      for (let i = 0; i < subWeldAdditionInfo?.length; i++) {
        let subWeldData = subWeldAdditionInfo[i];
        // 加工種類
        let processTypeId = subWeldData?.processTypeId;
        // 加工サイズ
        let processTypeSize = subWeldData?.daimeter ? Number(subWeldData?.daimeter) : 1;
        // 現在指定している材質名称、加工種類、加工サイズに引き当てる購入品を取得
        let targetPurchaseAllocation = purchaseAllocation?.filter(
          (item) =>
            item.processId === weldProcessAdditionInfo[0]?.processId &&
            item.materialTypeId === materialTypeId &&
            item.processTypeNo === processTypeId &&
            item.size === processTypeSize
        );
        if (targetPurchaseAllocation?.length > 0) {
          let addPurchaseInfo = parameters?.purchase?.filter(
            (item) => item.id === targetPurchaseAllocation[0]?.purchaseId
          )?.[0];
          if (addPurchaseInfo) {
            let addWeldInfo = {
              purchaseId: nextId++,
              name: addPurchaseInfo.name,
              model: addPurchaseInfo.info.model,
              unitPrice: addPurchaseInfo.price,
              unitPriceByQuantity: 0,
              required: 1,
              price: addPurchaseInfo.info.isKeepPrice ? addPurchaseInfo.price * 1 : addPurchaseInfo.price, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
              totalPrice: addPurchaseInfo.info.isKeepPrice
                ? addPurchaseInfo.price * 1 * noOfMembers * quantity
                : addPurchaseInfo.price, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価
              totalRequired: 1 * noOfMembers * quantity,
              isKeepPrice: addPurchaseInfo.info.isKeepPrice,
              purchaseCategoryId: addPurchaseInfo.purchaseCategoryId,
              processTime: addPurchaseInfo.info.processTime,
              totalProcessTime: addPurchaseInfo.info.processTime * noOfMembers * quantity,
              supplierCode: addPurchaseInfo.info.supplierCode,
              supplierName: addPurchaseInfo.info.supplierName,
              makerName: addPurchaseInfo.info.makerName,
              remarks: addPurchaseInfo.info.remarks,
              purchaseMasterId: addPurchaseInfo.id,
              isDeleted: false,
              purchaseAllocationId: targetPurchaseAllocation[0].id ? targetPurchaseAllocation[0].id : 0,
            };
            purchaseAutoAddArr.push(addWeldInfo);
          }
        }
      }
    }
    //　自動引当対象とする2次加工工程情報を取得
    let secondaryWorkProcessId = parameters?.process?.filter((item) => item.workType === WorkType.SmSecondaryWork)?.[0]
      ?.id;
    let secondaryWorkAdditionInfo = props.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId === secondaryWorkProcessId
    );
    if (secondaryWorkAdditionInfo?.length > 0) {
      let secondaryWorkAdditionDataItems = secondaryWorkAdditionInfo[0]?.dataItems;
      for (let i = 0; i < secondaryWorkAdditionDataItems?.length; i++) {
        let secondaryWorkData = secondaryWorkAdditionDataItems[i];
        // 加工種類
        let processTypeId = secondaryWorkData?.processTypeId;
        // 加工サイズ
        let processTypeSize = secondaryWorkData?.diameter ? Number(secondaryWorkData?.diameter) : 1;
        // 現在指定している材質名称、加工種類、加工サイズに引き当てる購入品を取得
        let targetPurchaseAllocation = purchaseAllocation?.filter(
          (item) =>
            item.processId === secondaryWorkAdditionInfo[0]?.processId &&
            item.materialTypeId === materialTypeId &&
            item.processTypeNo === processTypeId &&
            item.size === processTypeSize
        );
        if (targetPurchaseAllocation?.length > 0) {
          let addPurchaseInfo = parameters?.purchase?.filter(
            (item) => item.id === targetPurchaseAllocation[0]?.purchaseId
          )?.[0];
          if (addPurchaseInfo) {
            let addSecondaryWorkInfo = {
              purchaseId: nextId++,
              name: addPurchaseInfo.name,
              model: addPurchaseInfo.info.model,
              unitPrice: addPurchaseInfo.price,
              unitPriceByQuantity: 0,
              required: 1,
              price: addPurchaseInfo.info.isKeepPrice ? addPurchaseInfo.price * 1 : addPurchaseInfo.price, // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
              totalPrice: addPurchaseInfo.info.isKeepPrice
                ? addPurchaseInfo.price * 1 * noOfMembers * quantity
                : addPurchaseInfo.price, // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価
              totalRequired: 1 * noOfMembers * quantity,
              isKeepPrice: addPurchaseInfo.info.isKeepPrice,
              purchaseCategoryId: addPurchaseInfo.purchaseCategoryId,
              processTime: addPurchaseInfo.info.processTime,
              totalProcessTime: addPurchaseInfo.info.processTime * noOfMembers * quantity,
              supplierCode: addPurchaseInfo.info.supplierCode,
              supplierName: addPurchaseInfo.info.supplierName,
              makerName: addPurchaseInfo.info.makerName,
              remarks: addPurchaseInfo.info.remarks,
              purchaseMasterId: addPurchaseInfo.id,
              isDeleted: false,
              purchaseAllocationId: targetPurchaseAllocation[0].id ? targetPurchaseAllocation[0].id : 0,
            };
            purchaseAutoAddArr.push(addSecondaryWorkInfo);
          }
        }
      }
    }
    let purchases = [];
    let updatedData = [];
    let purchaseData = [];
    if (isPurchaseAutoDelete) {
      let purchaseLst = [...purchaseLstBK];
      // 前回利用した自動引当データを削除する
      updatedData = purchaseLst?.map((item) => {
        if (item.purchaseAllocationId > 0) {
          return { ...item, isDeleted: true };
        }
        return item;
      });
      // 前回利用した自動引当データを削除してから購入品自動引当情報を追加。
      purchaseData = [...updatedData, ...purchaseAutoAddArr];
    } else {
      // 前回利用した自動引当データを削除しないで購入品自動引当情報を追加。
      updatedData = [...purchaseLstBK];
      purchaseData = [...purchaseLst, ...purchaseAutoAddArr];
    }
    if (purchaseData) {
      purchases = purchaseData?.filter((item) => !item.isDeleted);
      setPurchaseLst(purchases);
      setPurchaseLstBK(purchases);
      calculateTotal(purchases, currentRecordIndex);
      setSelectedRow(purchases?.length > 0 ? purchases[0].purchaseId : 0);
      setSelectedData(purchases?.length > 0 ? purchases[0] : []);
      if (props.selectedIQ3DataDetail != undefined) {
        props.selectedIQ3DataDetail.purchases = purchases;
      }
      if (props.selectedEstimateData.esIq3Info[currentRecordIndex]) {
        props.selectedEstimateData.esIq3Info[currentRecordIndex].purchases = purchases;
      }
    }
  };

  const purchaseAutoAddCancel = () => {
    setIsPurchaseAutoAdd(false);
  };

  // 購入品自動引当
  const purchaseAutoAddContent = (
    <>
      <Row style={{ marginTop: '0px' }}>
        <Col span={24}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
            現在指定している材質名称、加工種類、加工サイズで購入品の自動引当を
            <br />
            実行します。
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
            事前に自動引当で追加されている購入品を削除してからを実行しますか？
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={8}>
          <Checkbox
            value={isPurchaseAutoDelete}
            checked={isPurchaseAutoDelete}
            onChange={(e) => {
              setIsPurchaseAutoDelete(!isPurchaseAutoDelete);
            }}
          >
            削除する
          </Checkbox>
        </Col>
        <Col span={16} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={purchaseAutoAddOK} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={purchaseAutoAddCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  const filterByCategory = (e) => {
    setPurchaseCategoryId(e);
    let currentData = [...purchaseLstBK];
    let purchaseFilterData = currentData;
    if (e > 0) {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.purchaseCategoryId === e);
    }
    setPurchaseLst(purchaseFilterData);
  };
  const filterUpdatedDataByCategory = (id, listData) => {
    setPurchaseCategoryId(id);
    let currentData = [...listData];
    let purchaseFilterData = currentData;
    if (id > 0) {
      purchaseFilterData = purchaseFilterData?.filter((item) => item.purchaseCategoryId === id);
    }
    setPurchaseLst(purchaseFilterData);
  };
  const calculateTotal = (listData, currentRecordIndex) => {
    const totalToritsukiTime = listData
      ?.filter((i) => !i.isDeleted)
      ?.reduce((acc, item) => acc + item.totalProcessTime, 0);
    setToritsukeTotalTime(secondsToHms(totalToritsukiTime));
    const unitPriceList = listData?.filter((i) => i.unitPriceByQuantity == 0 && i.isDeleted === false);
    const unitPriceByQtyList = listData?.filter((i) => i.unitPriceByQuantity != 0 && i.isDeleted === false);
    let price = 0;
    let totalPrice = 0;
    let suuryoubetsuPrice = 0;
    let suuryoubetsuTotalPrice = 0;
    if (unitPriceList?.length > 0) {
      price = unitPriceList?.reduce((acc, item) => Number(acc) + Number(item.price), 0);
      totalPrice = unitPriceList?.reduce((acc, item) => Number(acc) + Number(item.totalPrice), 0);

      setUnitPriceTotal(totalPrice);
      setPurchaseAmount(price);
    } else {
      setUnitPriceTotal(0);
      setPurchaseAmount(0);
    }
    if (unitPriceByQtyList?.length > 0) {
      suuryoubetsuPrice = unitPriceByQtyList?.reduce((acc, item) => Number(acc) + Number(item.price), 0);
      suuryoubetsuTotalPrice = unitPriceByQtyList?.reduce((acc, item) => Number(acc) + Number(item.totalPrice), 0);
      setSuryoubetsuTotalPrice(suuryoubetsuTotalPrice);
      setSuryoubetsuPrice(suuryoubetsuPrice);
    } else {
      setSuryoubetsuTotalPrice(0);
      setSuryoubetsuPrice(0);
    }
    if (
      props.selectedEstimateData.esIq3Info !== undefined &&
      props.selectedEstimateData.esIq3Info[currentRecordIndex]
    ) {
      props.selectedEstimateData.esIq3Info[currentRecordIndex].purchaseUnitPrice = price + suuryoubetsuPrice;
      props.selectedEstimateData.esIq3Info[currentRecordIndex].purchaseTotalPrice = totalPrice + suuryoubetsuTotalPrice;
    }
  };
  return (
    <>
      <div className="overflow-auto" style={{ height: 'auto' }}>
        <Row style={{ color: 'black' }}>
          <Col span={12}>
            <label style={{ marginLeft: 15, color: purchaseAmount == 0 ? '#A5A5A5' : '#4A4747' }}>
              {props.isChildPart ? '子部品購入品　金額 ' : ' 購入品　金額'} ：
              {purchaseAmount ? JPYs.format(purchaseAmount) : amtEmptyStr}　合計金額：
              {unitPriceTotal ? JPYs.format(unitPriceTotal) : amtEmptyStr} （
              {props.isChildPart ? '員数x数量：' : '製品数量：'}
              {noOfMembers * quantity}）
            </label>
          </Col>
          <Col span={12}>
            <label style={{ color: suryoubetsuPrice == 0 ? '#A5A5A5' : '#4A4747' }}>
              数量別購入品　金額：{suryoubetsuPrice ? JPYs.format(suryoubetsuPrice) : amtEmptyStr}　合計金額：
              {suryoubetsuTotalPrice ? JPYs.format(suryoubetsuTotalPrice) : amtEmptyStr}
            </label>
          </Col>
        </Row>
        <Row style={{ color: 'black', marginTop: '5px' }}>
          <Col span={19}>
            <Space>
              <Button
                key="submit30"
                type="primary"
                onClick={addFromMaster}
                className="editAllButton"
                style={{ width: 110 }}
                id="masterAdd"
              >
                マスターから追加
              </Button>
              <Button
                key="submit31"
                type="primary"
                onClick={addNew}
                className="editAllButton"
                style={{ width: 60 }}
                id="add"
              >
                追加
              </Button>
              <Button
                key="submit32"
                type="primary"
                onClick={editPurchaseInfo}
                // className="editAllButton"
                className={purchaseLst?.length > 0 ? 'editAllButton' : 'disabledEditButton'}
                style={{ width: 60, marginLeft: '80px' }}
                id="editPurchase"
              >
                編集
              </Button>
              <Button
                key="autoPurchase"
                type="primary"
                onClick={() => {
                  setModalTitle('購入品自動引当');
                  setIsPurchaseAutoAdd(true);
                  setIsPurchaseAutoDelete(true);
                }}
                className="editAllButton"
                style={{ width: 80, marginLeft: '60px' }}
                id="autoPurchase"
              >
                自動引当
              </Button>
            </Space>
          </Col>
          <Col span={5}>
            <Button
              key="submit33"
              type="primary"
              onClick={deleteConfirm}
              className={purchaseLst?.length > 0 ? 'editAllButton' : 'disabledEditButton'}
              style={{ width: 60, float: 'right' }}
              id="delete"
            >
              削除
            </Button>
          </Col>
        </Row>
        {/* 購入品情報 */}
        <Row style={{ color: 'black', marginTop: '5px' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col">
            <Row>
              <Col span={3}>
                <label style={{ marginLeft: 0 }}>子部品の購入品情報</label>
              </Col>
              <Col span={6}>
                <label style={{ marginLeft: 20 }}>種別：</label>

                <Select
                  style={{ width: '70%', marginTop: -10, paddingLeft: 3 }}
                  id="purchaseCategoryId"
                  name="purchaseCategoryId"
                  defaultValue={0}
                  value={purchaseCategoryId}
                  onChange={filterByCategory}
                >
                  <Select.Option id={'purchaseCategoryId0'} value={0}>
                    {'ALL'}
                  </Select.Option>
                  {purchaseCategoryInfos
                    ?.filter((i) => i?.info?.isUsed)
                    ?.map((item, index) => (
                      <Select.Option key={index} id={'purchaseCategoryId' + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
              <Col span={6}>
                <label style={{ marginLeft: 30 }}>取付時間合計：</label>
                <label style={{ marginLeft: 0 }}>{toritsukeTotalTime}</label>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ margin: '10px', height: 'auto' }}>
          <Row>
            <AntdTBL
              className="tbPurchaseListShow"
              columns={purchaseColumns}
              rowKey={(record) => record.purchaseId}
              // rowClassName={(record) => (record.purchaseId == selectedRowKeys ? 'active-row' : 'data-row')}
              rowClassName={(record) =>
                record.purchaseId == selectedRow && !record.isDeleted ? 'active-row' : 'data-row'
              }
              dataSource={purchaseLst?.filter((item) => !item.isDeleted)}
              // dataSource={purchaseLstBK}
              scroll={{ y: 500, x: '10vw' }}
              pagination={false}
              onRow={(record, index) => {
                return {
                  onClick: (event) => {
                    onRowSelect(record);
                  },
                };
              }}
            />
          </Row>
        </div>
      </div>
      {/* 追加・編集 */}
      <Modal
        maskClosable={false}
        title={addNewModalTitle}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        open={addModal}
        width={555}
        onOk={addOK}
        onCancel={addCancel}
        footer={[
          <Button
            key="submit34"
            onClick={updatePurchase}
            className="mainButton"
            style={{ display: isEditPurchase ? '' : 'none' }}
          >
            更新
          </Button>,
          <Button
            key="submit35"
            onClick={addOK}
            className="mainButton"
            style={{ display: isEditPurchase ? 'none' : '' }}
          >
            追加
          </Button>,
          <Button key="back" onClick={addCancel} className="cancelButton">
            破棄
          </Button>,
        ]}
      >
        <Form name="AddForm" autoComplete="off">
          <div id="addNewCustomer" style={{ marginTop: 15 }}>
            <Table className="propertiesTbl">
              <thead>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">種別</label>
                  </td>
                  <td style={{ width: '80%' }} className="detail-tbl-val">
                    {/* <Input
                      name="name"
                      type="text"
                      id="purchaseCategory"
                      value={purchaseCategory}
                      onChange={(e) => setPurchaseCategory(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    /> */}
                    <Select
                      style={{ width: '98%', paddingLeft: 5, pointerEvents: isEditPurchase ? 'none' : 'auto' }}
                      id="purchaseCategory"
                      name="purchaseCategory"
                      defaultValue={0}
                      value={purchaseCategory}
                      onChange={(e) => setPurchaseCategory(e)}
                    >
                      <Select.Option value={0}>{'ALL'}</Select.Option>
                      {purchaseCategoryInfos
                        ?.filter((i) => i?.info?.isUsed)
                        ?.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">品名</label>
                  </td>
                  <td style={{ width: '80%' }} className="detail-tbl-val">
                    <Input
                      name="name"
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">型番</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="model"
                      type="text"
                      id="model"
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">単価</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="childPurUnitPrice"
                      type="text"
                      id="childPurUnitPrice"
                      value={unitPrice}
                      onPressEnter={(e) => setUnitPrice(toCheckZenkaku(Number(e.target.value)))}
                      onBlur={(e) => setUnitPrice(toCheckZenkaku(Number(e.target.value)))}
                      onChange={(e) => setUnitPrice(Number(e.target.value))}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className="input-editable"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">必要数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="childPurRequired"
                      type="text"
                      id="childPurRequired"
                      value={required}
                      onPressEnter={(e) => setRequired(toCheckZenkaku(Number(e.target.value)))}
                      onBlur={(e) => setRequired(toCheckZenkaku(Number(e.target.value)))}
                      onChange={(e) => setRequired(Number(e.target.value))}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className="input-editable"
                    />
                  </td>
                </tr>
                <tr style={{ display: 'none' }}>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">合計必要数</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="required"
                      type="text"
                      id="required"
                      value={required}
                      onChange={(e) => setRequired(Number(e.target.value))}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
                {/** IQX_WEBEST-166 単価優先を非表示する->IQX_WEBEST-229 */}
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">単価保持</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Checkbox
                      style={{ padding: 5, marginLeft: 5 }}
                      checked={keepPrice}
                      onChange={(e) => setKeepPrice(e.target.checked)}
                    ></Checkbox>
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">仕入先</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="supplier"
                      type="text"
                      id="supplier"
                      value={supplier}
                      onChange={(e) => setSupplier(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      // className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                      className="input-non-editable"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">メーカー</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="maker"
                      type="text"
                      id="maker"
                      value={maker}
                      onChange={(e) => setMaker(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      // className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                      className="input-non-editable"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }} className="detail-tbl-val">
                    <Input
                      name="purchaseRemark"
                      type="text"
                      id="purchaseRemark"
                      value={purchaseRemark}
                      onChange={(e) => setPurchaseRemark(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97.5%' }}
                      className={isEditPurchase ? 'input-non-editable' : 'input-editable'}
                    />
                  </td>
                </tr>
              </thead>
            </Table>
          </div>
        </Form>
      </Modal>

      {/* マスターから追加 */}
      {isMasterAdd
        ? commonModal(
            isMasterAdd,
            addNewModalTitle,
            commonFooter(addOK, addCancel),
            null,
            1085,
            addOK,
            addCancel,
            addMasterContent,
            null,
            null,
            false
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonFooter(deletePurchase, deleteCancel),
            null,
            400,
            deletePurchase,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/** 自動引当 */}
      {isPurchaseAutoAdd
        ? commonModal(
            isPurchaseAutoAdd,
            addNewModalTitle,
            null,
            { height: 125 },
            540,
            purchaseAutoAddOK,
            purchaseAutoAddCancel,
            purchaseAutoAddContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default IQ3Purchase;
